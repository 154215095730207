/*eslint-disable curly */
/*eslint-disable no-nested-ternary */
/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";

import '../../crm.css';
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import FormGroup from '@mui/material/FormGroup';
import { IconButton } from "@material-ui/core";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import { FormHelperText } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { DateRangePicker } from "@material-ui/pickers";

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { getCountries, getCurrencies, getDestinations, getOrganisations, postTrip } from "../../Functions/TripFunction";
import GenericField from "../../utils/GenericField";
import { useSnackbar } from "notistack";
import { patchUser } from "../../Functions/UserFunctions";
import { SetUserList } from "../../../../../Actions/User";
import { SetSelectedUser } from "../../../../../Actions/Crm";

const defaultTraveler = {
    first_name: "Voyageur",
    last_name: "Adulte 1",
    birth_date: "1993-05-31",
    is_contact_traveler: false,
    is_lead_traveler: false,
    show_more: false,
    rank: "Adulte 1",
    room: 0
};

const CrmUserDetailTripFormDialog = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    const [trip, setTrip] = useState({
        currency: null,
        name: undefined,
        is_honeymoon: false,
        accounting_reference: undefined,
        bulk_trip: false,
        event_site: undefined,
        data: {
            budget: undefined,
            destination: null,
            start_date: null,
            end_date: null,
            trip_group: undefined,
            for_user: undefined,
            traveler_group: [[]]
        },
        target_client: null,
        user_to: undefined,
        user_ta: undefined
    });
    const [travelers, setTravelers] = useState([{ ...defaultTraveler }]);
    const [contactTraveler, setContactTraveler] = useState(null);
    const [show_more_contact_traveler, setShowMoreContactTraveler] = useState(false);
    const [contact_is_traveler, setContactIsTraveler] = useState(true);
    const [contact_traveler_index, setContactTravelerIndex] = useState(0);
    const [clickLoading, setClickLoading] = useState(false);

    const handleClose = () => {
        setTrip({
            currency: null,
            name: undefined,
            is_honeymoon: false,
            accounting_reference: undefined,
            bulk_trip: false,
            event_site: undefined,
            data: {
                budget: undefined,
                destination: null,
                start_date: null,
                end_date: null,
                trip_group: undefined,
                for_user: undefined,
                traveler_group: [[]]
            },
            target_client: null,
            user_to: undefined,
            user_ta: undefined
        });
        setTravelers([{ ...defaultTraveler }]);
        setContactTraveler({ ...selected_user, postal_code: selected_user.zip_code, address: selected_user.street });
        props.handleClose();
    };

    useEffect(() => {
        if (selected_user) {
            setContactTraveler({ ...selected_user, postal_code: selected_user.zip_code, address: selected_user.street });
            if (travelers.length) {
                const travelers_c = JSON.parse(JSON.stringify(travelers));
                travelers_c[0] = { ...defaultTraveler, ...selected_user, postal_code: selected_user.zip_code, address: selected_user.street };
                setTravelers(travelers_c);
                console.log('travelers_c', travelers_c);
            }
        }
    }, [selected_user]);

    const updateTrip = (field, value) => {
        const trip_c = { ...trip };
        const fields = field?.split('.');
        switch (fields.length) {
            case 1:
                trip_c[fields[0]] = value;
                break;
            case 2:
                trip_c[fields[0]][fields[1]] = value;
                break;
            case 3:
                trip_c[fields[0]][fields[1]][fields[2]] = value;
                break;
            default:
                break;
        }
        //handle required fields manually here
        if (["currency", "target_client", "data.destination", "data.start_date", "data.end_date"].includes(field) && !value) {
            trip_c[field + "_error"] = `ce champ ne peut être vide`;
        } else {
            trip_c[field + "_error"] = null;
        }
        setTrip(trip_c);
    };

    const updateContactTraveler = (field, value, skipAdditionaCall) => {
        const c_trav_c = { ...contactTraveler };
        const fields = field?.split('.');
        switch (fields.length) {
            case 1:
                c_trav_c[fields[0]] = value;
                break;
            case 2:
                c_trav_c[fields[0]][fields[1]] = value;
                break;
            case 3:
                c_trav_c[fields[0]][fields[1]][fields[2]] = value;
                break;
            default:
                break;
        }
        setContactTraveler(c_trav_c);

        //HANDLE IF CONTACT TRAVEL IS IN TRAVELERS
        if (contact_is_traveler && !skipAdditionaCall) {
            updateTraveler(`${contact_traveler_index}.${field}`, value, true);
        }
    };

    const updateTraveler = (field, value, skipAdditionaCall) => {
        const c_trav_c = JSON.parse(JSON.stringify(travelers));
        const fields = field?.split('.');
        switch (fields.length) {
            case 1:
                c_trav_c[fields[0]] = value;
                break;
            case 2:
                c_trav_c[fields[0]][fields[1]] = value;
                break;
            case 3:
                c_trav_c[fields[0]][fields[1]][fields[2]] = value;
                break;
            default:
                break;
        }
        setTravelers(c_trav_c);
        //HANDLE IF CONTACT TRAVEL IS IN TRAVELERS
        if (contact_is_traveler && !skipAdditionaCall) {
            updateContactTraveler(`${field.substring(2, field.length)}`, value, true);
        }
    };

    const setDates = (values) => {
        if (values && values[0]) updateTrip('data.start_date', values[0]);
        if (values && values[1]) updateTrip('data.end_date', values[1]);
    };

    const showMoreContactTraveler = () => {
        setShowMoreContactTraveler(!show_more_contact_traveler);
    };
    const showMoreTraveler = (index) => {
        const c_trav_c = JSON.parse(JSON.stringify(travelers));
        c_trav_c[index].show_more = !c_trav_c[index].show_more;
        setTravelers(c_trav_c);
    };

    const addCard = (index) => {
        const travs_c = JSON.parse(JSON.stringify(travelers));
        if (!travs_c[index].loyalty_cards?.length) {
            travs_c[index].loyalty_cards = [];
        }
        travs_c[index].loyalty_cards.push({});
        setTravelers(travs_c);
    };

    const deleteCard = (index) => {
        const travs_c = JSON.parse(JSON.stringify(travelers));
        if (!travs_c[index].loyalty_cards?.length) {
            travs_c[index].loyalty_cards = [];
        }
        travs_c[index].loyalty_cards.splice(index, 1);
        setTravelers(travs_c);
    };

    /**Traveler & rooms */
    const removeRoom = () => {
        let trip_c = { ...trip };
        trip_c.data.traveler_group.pop();
        setTrip(trip_c);
    };
    const addRoom = () => {
        let trip_c = { ...trip };
        trip_c.data.traveler_group.push([]);
        setTrip(trip_c);
    };
    const getTraveler = (age_pax) => {
        return travelers.filter(t => {
            if (t.birth_date) {
                const birthdate = new Date(t.birth_date);
                const currentDate = new Date();
                const differenceInMs = currentDate - birthdate;
                const ageDate = new Date(differenceInMs);
                const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (age_pax === "adult" && calculatedAge >= 18) {
                    return true;
                } else if (age_pax === "kid" && calculatedAge < 18) {
                    return true;
                }
            }
            return false;
        });
    };
    const isKid = (traveler) => {
        if (traveler.birth_date) {
            const birthdate = new Date(traveler.birth_date);
            const currentDate = new Date();
            const differenceInMs = currentDate - birthdate;
            const ageDate = new Date(differenceInMs);
            const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
            if (calculatedAge < 18) {
                return true;
            }
            return false;
        }
    };
    const removeTraveler = (age_pax) => {
        const adults = getTraveler("adult");
        const kids = getTraveler("kid");
        if (age_pax === "adult") {
            adults.pop();
        }
        if (age_pax === "kid") {
            kids.pop();
        }
        setTravelers([...adults, ...kids]);
    };
    const addTraveler = (age_pax) => {
        const adults = getTraveler("adult");
        const kids = getTraveler("kid");
        if (age_pax === "adult") {
            adults.push({ ...defaultTraveler, last_name: "Adulte " + (adults.length + 1), rank: "Adulte " + (adults.length + 1) });
        }
        if (age_pax === "kid") {
            kids.push({
                first_name: "Voyageur",
                last_name: "Enfant " + (kids.length + 1),
                birth_date: "2009-05-31",
                show_more: false,
                rank: "Enfant " + (kids.length + 1),
                room: 0
            });
        }
        setTravelers([...adults, ...kids]);
    };


    /**HANDLE COMPANION */
    const chooseCompanion = (index, event) => {
        const selected = selected_user?.travel_companions?.find(t => t.id == event.target.value);
        if (selected) {
            const adults = getTraveler("adult");
            const kids = getTraveler("kid");
            const travelers_c = JSON.parse(JSON.stringify(travelers));
            travelers_c[index] = { ...travelers_c[index], ...selected, rank: isKid(selected) ? travelers_c[index].rank.replace("Adulte", "Enfant") : travelers_c[index].rank };
            setTravelers(travelers_c);
        } else if (event.target.value === null) {
            const travelers_c = JSON.parse(JSON.stringify(travelers));
            travelers_c[index] = { ...defaultTraveler, rank: travelers_c[index].rank };
            setTravelers(travelers_c);
        }
    };

    /**HANDLE CONTACT IN TRAVELER */
    const handleContactInTraveler = () => {
        const adults = getTraveler("adult");
        if (contact_is_traveler) {
            const travelers_c = JSON.parse(JSON.stringify(travelers));
            travelers_c[contact_traveler_index] = { ...defaultTraveler, last_name: "Adulte " + (adults.length), rank: "Adulte " + (adults.length) };
            setContactTravelerIndex(-1);
            setTravelers(travelers_c);
        }
        setContactIsTraveler(!contact_is_traveler);
    };
    const onSetContactTravelerIndex = (index) => {
        const c_trav_c = JSON.parse(JSON.stringify(travelers));
        c_trav_c[contact_traveler_index] = { ...defaultTraveler, last_name: "Adulte " + (contact_traveler_index + 1), rank: "Adulte " + (contact_traveler_index + 1) };
        c_trav_c[index] = { ...c_trav_c[index], ...contactTraveler };
        setTravelers(c_trav_c);
        setContactTravelerIndex(index);
    };

    /**CONFIRM */
    const confirm = () => {
        /*validation*/
        let is_valid = true;
        const final_trip = JSON.parse(JSON.stringify(trip));
        //remove all error fields here
        for (let key in final_trip) {
            if (key.includes("error")) {
                delete final_trip[key];
            }
        }

        const required_fields = ["currency", "target_client", "data.destination", "data.start_date", "data.end_date"];
        required_fields.forEach(field_string => {
            const fields = field_string.split('.');
            if (fields.length > 1 && (final_trip[fields[0]][fields[1]] === "" || final_trip[fields[0]][fields[1]] === undefined || final_trip[fields[0]][fields[1]] === null)) {
                final_trip[field_string + "_error"] = t('crm.field_required_error');
                enqueueSnackbar(`${t('crm.' + field_string.replace('data.', ''))} : ${t('crm.field_required_error')}`, { variant: "error" });
                is_valid = false;
            } else if (final_trip[fields[0]] === "" || final_trip[fields[0]] === undefined || final_trip[fields[0]] === null) {
                final_trip[field_string + "_error"] = t('crm.field_required_error');
                enqueueSnackbar(`${t('crm.' + field_string.replace('data.', ''))} : ${t('crm.field_required_error')}`, { variant: "error" });
                is_valid = false;
            }
        });
        const travelers_c = JSON.parse(JSON.stringify(travelers));
        travelers_c.forEach((traveler, traveler_index) => {
            if (!travelers_c[traveler_index].phone_number) travelers_c[traveler_index].phone_number = null;
            if (travelers_c[traveler_index].birth_date) travelers_c[traveler_index].birth_date = travelers_c[traveler_index].birth_date.substring(0, 10);
            if (travelers_c[traveler_index].country) travelers_c[traveler_index].country = travelers_c[traveler_index].country.id;
            if (contact_is_traveler && contact_traveler_index === traveler_index) {
                travelers_c[traveler_index].is_contact_traveler = true;
            }
            if (!final_trip.data.traveler_group[traveler.room].some(trav => trav.is_lead_traveler) && traveler.rank.includes('dult')) {
                travelers_c[traveler_index].is_lead_traveler = true;
            }
            final_trip.data.traveler_group[traveler.room].push(traveler);
        });
        if (final_trip.data.traveler_group.length) {
            if (final_trip.data.traveler_group.some(group => !group.length)) {
                enqueueSnackbar(t('crm.empty_room_error'), { variant: 'error' });
                is_valid = false;
            }
            //if (trip.data.traveler_group.some(group => !group.some(traveler => !traveler.rank.includes('dult')))) enqueueSnackbar(t('crm.empty_room_error'), { variant: 'error' });
        }
        console.log('DEBUG finaltrip', final_trip);
        if (!is_valid) {
            final_trip.data.traveler_group = trip.data.traveler_group;
            setTrip(final_trip);
            return;
        }
        final_trip.data.for_user = null;
        final_trip.data.start_date = typeof final_trip.data.start_date === 'string' ? final_trip.data.start_date : final_trip.data.start_date?.toISOString();
        final_trip.data.end_date = typeof final_trip.data.end_date === 'string' ? final_trip.data.end_date : final_trip.data.end_date?.toISOString();
        console.log('finaltrip', final_trip);
        /*post trip*/
        setClickLoading(true);
        postTrip(user_client.client, final_trip, (response) => {
            /*redirection*/
            if (response.data.id) {
                //props.router.push(`/${window.url_name}/apps/itinerary`);
                if (selected_user?.type === "LEAD") {
                    patchUser(user_client.client, { id: selected_user.id, type: "PROSPECT" }, ['type'], (resp) => {
                        if (user_list && resp.data) {
                            let user_list_updated = JSON.parse(JSON.stringify(user_list));
                            const index = user_list_updated.findIndex(u => u.id === resp.data.id);
                            if (index !== -1) {
                                user_list_updated[index] = resp.data;
                                dispatch(SetUserList(user_list_updated));
                            }
                        }
                        dispatch(SetSelectedUser(resp.data));
                    });
                }
                setClickLoading(false);
                handleClose();
                const url = `${window.url_name}/menu/trip-info/${response.data.id}`;
                const win = window.open(url, '_blank');
                if (win !== null) {
                    win.focus();
                }
            }
        }, (err) => {
            setClickLoading(false);
            enqueueSnackbar(err, { variant: 'error' });
        });
    };

    const setCookie = (cookie_name, cookie_value, cookie_expiration_days, cookie_path, cookie_domain) => {
        let date = new Date();
        date.setTime(date.getTime() + (cookie_expiration_days * 24 * 60 * 60 * 1000));
        if (cookie_domain !== null) {
            document.cookie = `${cookie_name}=${cookie_value};expires=${date.toUTCString()};path=${cookie_path};Domain=${cookie_domain}`;
        } else {
            document.cookie = `${cookie_name}=${cookie_value};expires=${date.toUTCString()};path=${cookie_path};`;
        }
    };

    const adults = getTraveler("adult");
    const kids = getTraveler("kid");

    return (
        <Dialog open={props.open} maxWidth="lg" className="create-trip-dialog">
            <div className="close-div" onClick={handleClose} style={{ padding: "7px 15px" }}>
                <CloseIcon />
            </div>
            <DialogTitle>{t('crm.trip_details')}</DialogTitle>
            <Grid container className="create-trip create-companion" spacing={1}>
                <Grid item xs={12}>
                    <h5>{t('crm.choose_agency')}</h5>
                </Grid>
                <Grid item xs={12}>
                    <GenericField label={t('crm.agencies')} onChange={updateTrip} object={trip} field={'target_client'} type="autocomplete" autocompleteGetter={getOrganisations} />
                </Grid>
                <Grid item xs={12}>
                    <h5>{t('crm.add_more_info')}</h5>
                </Grid>
                <Grid item xs={12}>
                    <GenericField label={t('crm.trip_name')} onChange={updateTrip} object={trip} field={'name'} />
                </Grid>
                <Grid item xs={6}>
                    <GenericField label={t('crm.internal_ref')} onChange={updateTrip} object={trip} field={'accounting_reference'} />
                </Grid>
                <Grid item xs={6}>
                    <GenericField label={t('crm.destination')} onChange={updateTrip} object={trip} field={'data.destination'} type="autocomplete" autocompleteGetter={getDestinations} getOptionLabel={(option) => option.data?.international_name} />
                </Grid>
                <Grid item xs={6}>
                    <GenericField label={t('crm.currency')} onChange={updateTrip} object={trip} field={'currency'} type="autocomplete" autocompleteGetter={getCurrencies} />
                </Grid>
                <Grid item xs={6}>
                    <GenericField label={t('crm.budget')} onChange={updateTrip} object={trip} field={'data.budget'} />
                </Grid>
                <Grid item xs={12}>
                    <h5>{t('crm.choose_dates')}</h5>
                </Grid>
                {/*<Grid item xs={6}>
                    <GenericField label={t('crm.start_date')} onChange={updateTrip} object={trip} field={'data.start_date'} type="datepicker" />
                </Grid>
                <Grid item xs={6}>
                    <GenericField label={t('crm.end_date')} onChange={updateTrip} object={trip} field={'data.end_date'} type="datepicker" />
                </Grid> */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={'fr'}>
                            <DateRangePicker
                                value={[trip.data?.start_date, trip.data?.end_date]}
                                startText={t('crm.start_date')}
                                endText={t('crm.end_date')}
                                onChange={(newValue) => setDates(newValue)}
                                renderInput={(startProps, endProps) => (
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}><TextField fullWidth {...startProps} /></Grid>
                                        <Grid item xs={6}><TextField fullWidth {...endProps} /></Grid>
                                    </Grid>
                                )}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormHelperText style={{ color: "red" }}>{trip["data.start_date_error"] ? (trip["data.end_date_error"] ? trip["data.end_date_error"] : null) : null}</FormHelperText>
                </Grid>
                {/*<Grid item xs={12}>
                    <h5>{t('crm.choose_start_city')}</h5>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel required control={<Checkbox />} label={t('crm.return_city')} />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField label={t('crm.start_city')} onChange={updateTrip} object={trip} field={'start_city'} />
                </Grid> */}
                <Grid item xs={12}>
                    <h5>{t('crm.count_traveler')}</h5>
                    <div className="create-trip-traveler">
                        <div>
                            <p><b>{t('crm.adults')}</b></p>
                            <p><small>{t('crm.adults_age')}</small></p>
                        </div>
                        <div>
                            <IconButton onClick={() => removeTraveler("adult")} disabled={Boolean(adults?.length <= 1)}><RemoveCircleOutlineIcon /></IconButton>
                            <p>{adults?.length ? adults.length : 1}</p>
                            <IconButton onClick={() => addTraveler("adult")}><AddCircleOutlineIcon /></IconButton>
                        </div>
                    </div>
                    <hr style={{ color: "#0000008A" }} />
                    <div className="create-trip-traveler">
                        <div>
                            <p><b>{t('crm.kids')}</b></p>
                            <p><small>{t('crm.kids_age')}</small></p>
                        </div>
                        <div>
                            <IconButton onClick={() => removeTraveler("kid")} disabled={Boolean(kids?.length < 1)}><RemoveCircleOutlineIcon /></IconButton>
                            <p>{kids?.length ? kids.length : 0}</p>
                            <IconButton onClick={() => addTraveler("kid")}><AddCircleOutlineIcon /></IconButton>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <h5>{t('crm.count_room')}</h5>
                    <div className="create-trip-traveler">
                        <div>
                            <p><b>{t('global.room')}</b></p>
                        </div>
                        <div>
                            <IconButton onClick={removeRoom} disabled={Boolean(trip?.data.traveler_group?.length <= 1)}><RemoveCircleOutlineIcon /></IconButton>
                            <p>{trip?.data.traveler_group?.length ? trip.data.traveler_group?.length : 0}</p>
                            <IconButton onClick={addRoom}><AddCircleOutlineIcon /></IconButton>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <h5>{t('crm.rooms_affectation')}</h5>
                    <div className="create-trip-rooms-affectations">
                        {
                            trip?.data.traveler_group?.map((group, index) => {
                                return (
                                    <div key={`traveler-group-${index}`}>
                                        <p>{t('global.room')} {index + 1} :</p>
                                        <FormGroup>
                                            {
                                                travelers.map((traveler, traveler_index) => {
                                                    return (
                                                        <FormControlLabel key={`traveler_index-${traveler_index}`}
                                                            control={<Checkbox checked={traveler.room === index} onChange={() => updateTraveler(`${traveler_index}.room`, index)} />} label={`${traveler.rank}`} />
                                                    );
                                                })
                                            }
                                        </FormGroup>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Grid>

                {/*CONTACT TRAVELER INFO*/}
                <div className="create-trip-user-info">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div>
                                <h3>{t('crm.contact_traveler')}</h3>
                                <FormControlLabel control={<Checkbox checked={contact_is_traveler} onChange={handleContactInTraveler} />} label={t('crm.lead_is_in_travelers')} />
                            </div>
                        </Grid>
                        <Grid item xs={1.5}>
                            <GenericField label={t('global.title')} onChange={updateContactTraveler} object={contactTraveler} field={'title'} type="select" options={
                                [
                                    { value: 'MR', label: t('title_value.mr') },
                                    { value: 'MRS', label: t('title_value.mrs') },
                                    { value: 'MISS', label: t('title_value.miss') },
                                    /* { value: 'MX', label: t('title_value.mx') } */
                                ]
                            } readonly={true} />
                        </Grid>
                        <Grid item xs={4}>
                            <GenericField label={t("global.first_name")} onChange={updateContactTraveler} object={contactTraveler} field={'first_name'} required={true} readonly={true} />
                        </Grid>
                        <Grid item xs={3.5}>
                            <GenericField label={t("global.last_name")} onChange={updateContactTraveler} object={contactTraveler} field={"last_name"} required={true} readonly={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <GenericField label={t('menu.trip_list.birthdate')} onChange={updateContactTraveler} object={contactTraveler} field={'birth_date'} type="datepicker" readonly={true} />
                        </Grid>
                        {
                            show_more_contact_traveler &&
                            <>
                                <Grid xs={8} item>
                                    <GenericField label={t('crm.traveler_validation.address')} onChange={updateContactTraveler} object={contactTraveler} field={'address'} readonly={true} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GenericField label={t('crm.traveler_validation.address_detail')} onChange={updateContactTraveler} object={contactTraveler} field={'address_detail'} readonly={true} />
                                </Grid>
                                <Grid xs={3} item>
                                    <GenericField label={t('crm.traveler_validation.postal_code')} onChange={updateContactTraveler} object={contactTraveler} field={'postal_code'} readonly={true} />
                                </Grid>
                                <Grid xs={5} item>
                                    <GenericField label={t('crm.traveler_validation.city')} onChange={updateContactTraveler} object={contactTraveler} field={'city'} readonly={true} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GenericField label={t('crm.traveler_validation.country')} onChange={updateContactTraveler} object={contactTraveler} field={'country'} type="autocomplete" autocompleteGetter={getCountries} readonly={true} />
                                </Grid>
                                <Grid xs={6} item>
                                    <GenericField label={t('crm.traveler_validation.phone')} onChange={updateContactTraveler} object={contactTraveler} field={'phone_number'} type="phone" readonly={true} />
                                </Grid>
                                <Grid xs={6} item>
                                    <GenericField label={t('crm.traveler_validation.email')} onChange={updateContactTraveler} object={contactTraveler} field={"email"} type={"email"} readonly={true} />
                                </Grid>
                            </>
                        }

                        <Grid xs={12} item style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", gap: 10 }}>
                                <Button variant="text" onClick={addCard} style={{ display: "none" }}>
                                    {t('menu.trip_list.add_card')}
                                </Button>
                            </div>
                            <Button variant="text" startIcon={show_more_contact_traveler ? <RemoveIcon /> : <AddIcon />} onClick={showMoreContactTraveler}
                                style={{ width: "6em" }}>
                                INFO
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/*TRAVELER INFO*/}
                {
                    travelers.map((traveler, traveler_index) => {
                        const travIsKid = isKid(traveler);
                        return (
                            <div className="create-trip-user-info">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div>
                                            <h3>{t('crm.traveler')} {traveler_index + 1}</h3>
                                            {
                                                contact_is_traveler && !travIsKid &&
                                                <FormControlLabel control={<Checkbox checked={contact_traveler_index === traveler_index} onChange={() => onSetContactTravelerIndex(traveler_index)} />} label={t('crm.traveler_is_lead')} />
                                            }
                                        </div>
                                        {
                                            contact_traveler_index !== traveler_index && selected_user?.travel_companions && selected_user.travel_companions.length > 0 &&
                                            <>
                                                <Grid item xs={4} style={{ margin: "10px 0" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('crm.choose_companion')}</InputLabel>
                                                        <Select
                                                            disabled={contact_traveler_index === traveler_index}
                                                            label={t('crm.choose_companion')}
                                                            onChange={(e) => chooseCompanion(traveler_index, e)}
                                                        >
                                                            <MenuItem key={`option-none`} value={null}>{t('crm.none')}</MenuItem>
                                                            {
                                                                selected_user?.travel_companions
                                                                    .filter(com => !travelers.filter(t => t.id !== traveler.id).map(tr => tr.id).includes(com.id))
                                                                    .map(c => {
                                                                        return (
                                                                            <MenuItem key={`option-${c.id}`} value={c.id}>{c.first_name + " " + c.last_name}</MenuItem>
                                                                        );
                                                                    })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8} />
                                            </>
                                        }
                                        <h3>{traveler.rank}</h3>
                                        <p>{t('menu.trip_list.affected_room')} <span>{t('global.room')} {traveler.room + 1}</span> </p>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <GenericField label={t('global.title')} onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'title'} type="select" options={
                                            [
                                                { value: 'MR', label: t('title_value.mr') },
                                                { value: 'MRS', label: t('title_value.mrs') },
                                                { value: 'MISS', label: t('title_value.miss') },
                                                /* { value: 'MX', label: t('title_value.mx') } */
                                            ]
                                        } readonly={contact_traveler_index === traveler_index} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <GenericField label={t("global.first_name")} object={travelers} onChange={updateTraveler} field={traveler_index + '.' + 'first_name'} required={true} readonly={contact_traveler_index === traveler_index} />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <GenericField label={t("global.last_name")} object={travelers} onChange={updateTraveler} field={traveler_index + '.' + "last_name"} required={true} readonly={contact_traveler_index === traveler_index} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <GenericField label={t('menu.trip_list.birthdate')} onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'birth_date'} type="datepicker"
                                            readonly={contact_traveler_index === traveler_index} />
                                    </Grid>

                                    {
                                        traveler.show_more &&
                                        <>

                                            <Grid xs={6} item>
                                                <GenericField label={t('crm.traveler_validation.phone')} onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'phone_number'} type="phone"
                                                    readonly={contact_traveler_index === traveler_index} />
                                            </Grid>
                                            <Grid xs={6} item>
                                                <GenericField label={t('crm.traveler_validation.email')} object={travelers} onChange={updateTraveler} field={traveler_index + '.' + "email"}
                                                    readonly={contact_traveler_index === traveler_index} />
                                            </Grid>
                                            <Grid xs={3} item>
                                                <GenericField label={t('crm.traveler_validation.document.type')} onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'identity_doc'} type="select" options={
                                                    [
                                                        { value: 'PASSPORT', label: t('crm.traveler_validation.document.passport') },
                                                        { value: 'CIN', label: t('crm.traveler_validation.document.id_card') }
                                                    ]
                                                } readonly={contact_traveler_index === traveler_index} />
                                            </Grid>
                                            <Grid xs={3} item>
                                                <GenericField label={t('crm.traveler_validation.document.number')} object={travelers} onChange={updateTraveler} field={traveler_index + '.' + 'identity_doc_number'}
                                                    readonly={contact_traveler_index === traveler_index} />
                                            </Grid>
                                            <Grid xs={3} item>
                                                <GenericField label={t('crm.traveler_validation.document.country')}
                                                    onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'identity_doc_country_emission'} type="autocomplete" autocompleteGetter={getCountries}
                                                    readonly={contact_traveler_index === traveler_index} />
                                            </Grid>
                                            <Grid xs={3} item>
                                                <GenericField label={t('crm.traveler_validation.document.expiration')} onChange={updateTraveler} object={travelers} field={traveler_index + '.' + 'identity_doc_expiration'} type="datepicker"
                                                    readonly={contact_traveler_index === traveler_index} />
                                            </Grid>

                                            {
                                                contact_traveler_index !== traveler_index && traveler?.loyalty_cards?.map((card, index) => {
                                                    return (
                                                        <>
                                                            <Grid xs={3.5} item>
                                                                <GenericField label={t('menu.trip_list.card_type')} onChange={updateTraveler} object={travelers} field={`${traveler_index}.loyalty_cards.${index}.product_type`} type="select" options={
                                                                    [
                                                                        { value: 0, label: t("global.hotel") },
                                                                        { value: 2, label: t("providers.rental_car") },
                                                                        { value: 4, label: t("providers.transfers") },
                                                                        { value: 5, label: t("providers.trains") },
                                                                        { value: 6, label: t("global.flight") }
                                                                    ]
                                                                } />
                                                            </Grid>
                                                            <Grid xs={4} item>
                                                                <GenericField label={t("menu.trip_list.card_supplier")} object={travelers} onChange={updateTraveler} field={`${traveler_index}.loyalty_cards.${index}.supplier`} />
                                                            </Grid>
                                                            <Grid xs={4} item>
                                                                <GenericField label={t("menu.trip_list.id_doc_number_short")} object={travelers} onChange={updateTraveler} field={`${traveler_index}.loyalty_cards.${index}.card_number`} />
                                                            </Grid>
                                                            <Grid xs={0.5} item style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                                <IconButton size="small">
                                                                    <DeleteOutlinedIcon onClick={() => deleteCard(traveler_index, index)} style={{ color: "black" }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </>
                                                    );
                                                })
                                            }
                                        </>
                                    }
                                    <Grid xs={12} item style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {
                                                contact_traveler_index !== traveler_index &&
                                                <Button variant="text" onClick={() => addCard(traveler_index)} style={{ display: traveler.show_more ? "block" : "none" }}>
                                                    {t('menu.trip_list.add_card')}
                                                </Button>
                                            }
                                        </div>
                                        <Button variant="text" startIcon={traveler.show_more ? <RemoveIcon /> : <AddIcon />} onClick={() => showMoreTraveler(traveler_index)}
                                            style={{ width: "6em" }}>
                                            INFO
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })
                }

                <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, width: "-webkit-fill-available" }}>
                    <Button variant="outlined" onClick={handleClose}>{t("crm.cancel")}</Button>
                    <LoadingButton loading={clickLoading} variant="contained" onClick={confirm}>{t("crm.create")}</LoadingButton>
                </div>
            </Grid>
        </Dialog>
    );
};
export default CrmUserDetailTripFormDialog;
