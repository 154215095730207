import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    FormControl,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { ArrowRightAlt, Functions } from "@mui/icons-material";
import { findLast } from "lodash";
import { ProviderQuotationCurrencyPicker } from "./ProviderQuotationCurrencyPicker";
import { ProviderQuotationConfirmationModal } from "./ProviderQuotationConfirmationModal";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { useGetPrice } from "../CartMaterial/utils/getPrice";
import { usePackagedProducts } from "../CartMaterial/utils/packagedProducts";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProviderQuotationProducts } from "./utils/providerQuotationProducts";
import { AppState } from "../../Reducers/Reducers";

export enum QuotationTab {
    PRODUCTS,
    TEXTS
}

type Props = {
    statuses: {
        [key: number]: {
            id: number,
            isCustom: boolean,
            type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    },
    onChangeTab: React.Dispatch<React.SetStateAction<QuotationTab>>
}

export const ProviderQuotationSideContent = withRouter<Props>(
    function ProviderQuotationSideContent(props): JSX.Element {
        const providerId = props.params.providerId;
        const tripId = props.params.tripId;
        const tripVersion = props.params.tripVersion;
        const stackNumber = parseInt(props.params.stackNumber ?? '-1');
        const { t } = useTranslation();
        const currencies = useSelector((state: AppState) => state.trip.currencies);
        const trip = useSelector((state: AppState) => state.trip.all_data);
        const tripData = useSelector((state: AppState) => state.trip.data_trip);
        const [priceType, setPriceType] = useState<'manual' | 'products-total'>('products-total');
        const [terrestrialPrice, setTerrestrialPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const [flightPrice, setFlightPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const [totalPrice, setTotalPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const terrestrialPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === terrestrialPrice?.currency;
            });
        }, [currencies, terrestrialPrice]);
        const flightPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === flightPrice?.currency;
            });
        }, [currencies, flightPrice]);
        const totalPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === totalPrice?.currency;
            });
        }, [currencies, totalPrice]);
        const packagedProducts = usePackagedProducts();
        const products = useMemo(() => {
            const key = Object.keys(packagedProducts).find((key) => {
                return key.startsWith(stackNumber + '-');
            }) ?? '';
            return packagedProducts[key] ?? {
                accommodations: [],
                assistances: [],
                cars: [],
                flights: [],
                manualProducts: [],
                pois: [],
                transfers: []
            };
        }, [packagedProducts]);
        const rawProducts = useMemo(() => {
            return [
                ...products.accommodations.map((item): typeof item.accommodation & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'accommodations' :
                            'manual',
                        ...item.accommodation
                    }
                }),
                ...products.assistances.map((item): typeof item.assistance & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'assistances' :
                            'manual',
                        ...item.assistance
                    }
                }),
                ...products.cars.map((item): typeof item.car & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'cars' :
                            'manual',
                        ...item.car
                    }
                }),
                ...products.flights.map((item): typeof item.flight & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'flights' :
                            'manual',
                        ...item.flight
                    }
                }),
                ...products.manualProducts.map((item): typeof item & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: 'manual',
                        ...item
                    }
                }),
                ...products.pois.map((item): typeof item.poi & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'pois' :
                            'manual',
                        ...item.poi
                    }
                }),
                ...products.transfers.map((item): typeof item.transfer & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'transfers' :
                            'manual',
                        ...item.transfer
                    }
                })
            ];
        }, [products]);
        const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
        const getPrice = useGetPrice();
        const hasFlight = products.flights.length > 0;
        const counterOffersProductIds = rawProducts.map((item) => {
            return item.counter_offer_of;
        }).filter((item): item is NonNullable<typeof item> => !!item);

        const onAutocomputePrice = useCallback(() => {
            const total = rawProducts.filter((product) => {
                const itemData = props.statuses[product.id];
                return itemData?.status?.provider !== 'refused' &&
                    !counterOffersProductIds.includes(product.id);
            }).reduce((prev, current) => {
                const price = getPrice(current.prices);
                return prev + price.purchaseCost;
            }, 0);
            setTotalPrice({
                amount: Math.round(total * 100) / 100,
                currency: getPrice(rawProducts[0]?.prices ?? []).purchaseCurrency?.id ?? null
            });
        }, [rawProducts]);

        const onChangeCurrency = (type: 'terrestrial' | 'flight' | 'total', currency: number | null) => {
            switch (type) {
                case 'terrestrial': {
                    setTerrestrialPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
                case 'flight': {
                    setFlightPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
                case 'total': {
                    setTotalPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
            }
        };

        const onChangeAmount = (type: 'terrestrial' | 'flight' | 'total', amount: number) => {
            switch (type) {
                case 'terrestrial': {
                    setTerrestrialPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
                case 'flight': {
                    setFlightPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
                case 'total': {
                    setTotalPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
            }
        };

        const onResetPrices = () => {
            if (tripData) {
                const terrestrialPrice = priceType === 'manual' ?
                    findLast(
                        tripData.prices_terrestrial?.filter((item) => {
                            return item.stack_number === stackNumber;
                        }) ?? [],
                        (item) => item.master_price
                    ) :
                    undefined;
                const terrestrialPriceAmount = parseFloat(terrestrialPrice?.purchase_price ?? '0');
                setTerrestrialPrice(
                    terrestrialPrice ?
                        {
                            amount: isNaN(terrestrialPriceAmount) ? 0 : terrestrialPriceAmount,
                            currency: terrestrialPrice?.purchase_currency ?? 47
                        } :
                        null
                );
                const flightPrice = priceType === 'manual' ?
                    findLast(
                        tripData.prices_flight?.filter((item) => {
                            return item.stack_number === stackNumber;
                        }) ?? [],
                        (item) => item.master_price
                    ) :
                    undefined;
                const flightPriceAmount = parseFloat(flightPrice?.purchase_price ?? '0');
                setFlightPrice(
                    flightPrice ?
                        {
                            amount: isNaN(flightPriceAmount) ? 0 : flightPriceAmount,
                            currency: flightPrice?.purchase_currency ?? 47
                        } :
                        null
                );
                if (priceType === 'products-total') {
                    onAutocomputePrice();
                }
            }
        }

        useEffect(() => {
            onResetPrices();
        }, [tripData, stackNumber]);

        useEffect(() => {
            onResetPrices();
        }, [priceType, onAutocomputePrice]);

        useEffect(() => {
            const total = rawProducts.filter((product) => {
                const itemData = props.statuses[product.id];
                return itemData?.status?.provider !== 'refused' &&
                    !counterOffersProductIds.includes(product.id);
            }).reduce((prev, current) => {
                const price = getPrice(current.prices);
                return prev + price.purchaseCost;
            }, 0);
            setTotalPrice({
                amount: Math.round(total * 100) / 100,
                currency: getPrice(rawProducts[0]?.prices ?? []).purchaseCurrency?.id ?? null
            });
        }, [
            rawProducts,
            props.statuses,
            rawProducts
        ]);

        return (
            <>
                <Paper
                    elevation={1}
                    sx={{
                        paddingTop: 2.5,
                        paddingBottom: 3.5,
                        paddingLeft: 3.5,
                        paddingRight: 3.5,
                        borderRadius: '4px'
                    }}
                >
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        component="div"
                        sx={{ marginBottom: 2.5 }}
                    >
                        {t('cart-material.provider-quotation-package-price')}
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <Select
                            value={priceType}
                            onChange={(event) => setPriceType(event.target.value as typeof priceType)}
                        >
                            <MenuItem value="products-total">
                                {t('cart-material.price-type-products-total')}
                            </MenuItem>
                            <MenuItem value="manual">
                                {t('cart-material.price-type-package-total')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {
                        priceType === 'manual' &&
                        <>
                            <Alert severity="info" sx={{ marginY: 1.5 }}>
                                {t('cart-material.provider-quotation-package-price-group-title')}
                            </Alert>
                            <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                <TextField
                                    type="number"
                                    size="small"
                                    label={t('cart-material.provider-quotation-terrestrial-selling-price')}
                                    value={terrestrialPrice?.amount ?? 0}
                                    onChange={(event) => onChangeAmount('terrestrial', parseFloat(event.target.value))}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {terrestrialPriceCurrency?.symbol}
                                            </InputAdornment>
                                        )
                                    }}
                                    fullWidth
                                />
                                <ProviderQuotationCurrencyPicker
                                    value={terrestrialPrice?.currency ?? 47}
                                    onChange={(currency) => onChangeCurrency('terrestrial', currency)}
                                />
                            </Stack>
                            {
                                hasFlight &&
                                <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                    <TextField
                                        type="number"
                                        size="small"
                                        label={t('cart-material.provider-quotation-flight-selling-price')}
                                        value={flightPrice?.amount ?? 0}
                                        onChange={(event) => onChangeAmount('flight', parseFloat(event.target.value))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {flightPriceCurrency?.symbol}
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                    />
                                    <ProviderQuotationCurrencyPicker
                                        value={flightPrice?.currency ?? 47}
                                        onChange={(currency) => onChangeCurrency('flight', currency)}
                                    />
                                </Stack>
                            }
                        </>
                    }
                    {
                        priceType === 'products-total' &&
                        <>
                            <Alert severity="info" sx={{ marginY: 1.5 }}>
                                {t('cart-material.provider-quotation-package-price-total-title')}
                            </Alert>
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    type="number"
                                    size="small"
                                    label={t('cart-material.provider-quotation-total-selling-price')}
                                    value={totalPrice?.amount ?? 0}
                                    onChange={(event) => onChangeAmount('total', parseFloat(event.target.value))}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {totalPriceCurrency?.symbol}
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled
                                    fullWidth
                                />
                                <ProviderQuotationCurrencyPicker
                                    value={totalPrice?.currency ?? 47}
                                    onChange={(currency) => onChangeCurrency('total', currency)}
                                    disabled
                                />
                            </Stack>
                        </>
                    }
                </Paper>
                {
                    trip?.provider_allowed_to_edit_byday &&
                    <Button
                        variant="outlined"
                        sx={{ marginTop: 2 }}
                        onClick={() => props.onChangeTab(QuotationTab.TEXTS)}
                        fullWidth
                    >
                        {t('cart-material.provider-edit-quotation-text')}
                    </Button>
                }
                <Button
                    variant="contained"
                    endIcon={<ArrowRightAlt />}
                    sx={{ marginTop: 2 }}
                    onClick={() => setOpenConfirmationModal(true)}
                    fullWidth
                >
                    {t('cart-material.provider-quotation-continue')}
                </Button>
                <ProviderQuotationConfirmationModal
                    tripId={tripId}
                    version={tripVersion}
                    providerId={providerId}
                    stackNumber={props.params.stackNumber}
                    prices={{
                        terrestrial: terrestrialPrice,
                        flight: hasFlight ? flightPrice : undefined,
                        total: totalPrice
                    }}
                    open={openConfirmationModal}
                    statuses={props.statuses}
                    onClose={() => setOpenConfirmationModal(false)}
                />
            </>
        );
    }
);