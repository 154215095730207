import { getProductStatus } from "./getProductStatus";
import { isProductPackaged } from "./isProductPackaged";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { Flight } from "../../Itinerary/network/flight";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { AssistanceCart } from "../../Itinerary/objects/assistanceCart";
import { CarCart } from "../../Itinerary/objects/carCart";
import { PoiCart } from "../../Itinerary/objects/poiCart";
import { TransferCart } from "../../Itinerary/objects/transferCart";
import { ManualProductAccommodationCart } from "../../Itinerary/objects/manualProductAccommodationCart";
import { ManualProductFlightCart } from "../../Itinerary/objects/manualProductFlightCart";
import { User } from '../../Menu/MaterialTripList/objects/user';
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

type Product = ({
    type: 'car',
    item: CarCart,
    index: number
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart,
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual',
    item: ManualProduct
} | {
    type: 'manual-flight',
    item: ManualProductFlightCart
})

export function isProductBookable(
    product: Product,
    user: User | null,
    stackInfos: TripVersion['stack_info'],
    quotationCode: string
): boolean {
    const status = getProductStatus(product.item, false);
    return product.item.is_available &&
        (product.item.booking_status === null || product.item.booking_status.status_booking === "ERROR") &&
        (
            quotationCode !== 'visiteurs' ||
            status !== 'HIDE'
        ) &&
        (
            product.item.booking_process_state === "NOTHING" ||
            product.type.includes('manual')
        ) &&
        (
            !product.type.includes('manual') ||
            (product.item as ManualProduct).is_bookable
        ) &&
        (
            !isProductPackaged({
                product: product.item,
                stackInfos
            }) ||
            product.type === 'flight'
        ) &&
        (
            product.item.product_type !== 11 ||
            (
                product.type !== 'manual' &&
                product.type !== 'manual-accommodation' &&
                product.type !== 'manual-flight' &&
                (
                    product.item.questions?.filter((question) => {
                        if (question.required && (question.answer_text === null || question.answer_text === "")) {
                            return true;
                        }
                        return false;
                    }).length ?? 0
                ) === 0
            )
        ) &&
        (user?.client_full?.type !== 2 || !product.item.agency_need_to_book);
}
