//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

//---- Actions ----//
import { SetBigCart } from "../../../../Actions/Menu";
import axios from "axios";
import { makeAccountingChargesRequest } from "../../MaterialTripList/Functions/makeAccountingVersionGetRequest";
import { makeInvoicePurchaseRequest } from "../../MaterialTripList/Functions/makeInvoiceVersionGetRequest";
import HasPermission from "../../../Common/Functions/HasPermission";


export default (dispatch, trip_info, id_version, user, permissions) => {
    let { pass_check, headers } = CheckBeforeRequest();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    let has_permission = HasPermission(permissions, "view_accountingbook", user);
    if (pass_check) {
        let request_product = [];
        if (has_permission) {
            request_product.push(
                makeAccountingChargesRequest({
                    id: trip_info.trip_reference
                })
            )
            request_product.push(
                makeInvoicePurchaseRequest({
                    id: trip_info.trip_reference
                })
            )
        }
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/manual-products/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/accommodation/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/car/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/flight/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/custom-products/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/transfers/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/assistance/`,
            })
        );
        request_product.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${id_version}/insurance/`,
            })
        );
        axios.all([...request_product]).then(axios.spread((...responses) => {
            let available_cart = [];
            if (has_permission) {
                let charges = [...responses[0].data.results];
                let purchase = [...responses[1].data.results];
                console.log('charges:', charges);
                console.log('purchase:', purchase);
                responses[2].data.map((product) => {
                    if (quotation_code === 'visiteurs' && product.auto_product === null) {
                        // if (product.is_available === undefined || product.is_available === true) {
                        if (user.client_full.type === 2 || (user.client_full.type !== 2 && user.client_full.type === product.creator.client.type)) {
                            if (product.booking_status === null || (product.booking_status !== undefined && product.booking_status !== null && product.booking_status.status_booking !== "CANCELLED")) {
                                available_cart.push(product);
                            }
                            if (product.booking_status === undefined) {
                                available_cart.push(product);
                            }
                        }
                    } else {
                        available_cart.push(product);
                    }
                });
                for (let i = 3; i < responses.length; i++) {
                    if (responses[i].config.url.includes("custom-products")) {
                        responses[i].data.map(data => {
                            if (!data.poi_type) {
                                available_cart.push(data);
                            }
                        });
                    } else {
                        if (responses[i].data.results !== undefined) {
                            available_cart = available_cart.concat([...responses[i].data.results]);
                        } else {
                            available_cart = available_cart.concat([...responses[i].data]);
                        }
                    }
                }
                available_cart.map((cart) => {
                    let find_charge = charges.find((charge) => charge.products.includes(cart.id));
                    console.log('find_charge:', find_charge);
                    if (find_charge !== undefined) {
                        let find_purchase = purchase.find((purchase) => {
                            return purchase.invoice_product.find((invoice_product) => {
                                return invoice_product.invoice === find_charge.invoice;
                            })
                        });
                        console.log('find_purchase:', find_purchase);
                        cart.origin_charge = parseFloat(find_charge.origin_amount);
                        cart.origin_charge_currency = find_charge.origin_currency;
                        cart.charge = parseFloat(find_charge.amount);
                        cart.charge_currency = find_charge.currency;
                        cart.charge_factor_used = find_charge.factor_used;
                        if (find_purchase !== undefined) {
                            cart.purchase = parseFloat(find_purchase.amount);
                            cart.purchase_currency = find_purchase.currency;
                            cart.is_paid = find_purchase.is_paid
                        }
                    }
                });
                console.log('available_cart:', available_cart);
                const distantFuture = new Date(8640000000000000);
                let sort_cart = [...available_cart].sort((a, b) => {
                    let dateA = a.start_date !== null ? new Date(a.start_date) : distantFuture
                    let dateB = b.start_date !== null ? new Date(b.start_date) : distantFuture
                    return dateA.getTime() - dateB.getTime()
                });
                console.log('sort_cart:', sort_cart);
                dispatch(SetBigCart(sort_cart, true));
                dispatch({type: 'MENU_SET_FINANCE_CHARGES', payload: charges});
                dispatch({type: 'MENU_SET_FINANCE_PURCHASE', payload: purchase});
            } else {
                responses[0].data.map((product) => {
                    if (quotation_code === 'visiteurs' && product.auto_product === null) {
                        // if (product.is_available === undefined || product.is_available === true) {
                        if (user.client_full.type === 2 || (user.client_full.type !== 2 && user.client_full.type === product.creator.client.type)) {
                            if (product.booking_status === null || (product.booking_status !== undefined && product.booking_status !== null && product.booking_status.status_booking !== "CANCELLED")) {
                                available_cart.push(product);
                            }
                            if (product.booking_status === undefined) {
                                available_cart.push(product);
                            }
                        }
                    } else {
                        available_cart.push(product);
                    }
                });
                const distantFuture = new Date(8640000000000000);
                let sort_cart = [...available_cart].sort((a, b) => {
                    let dateA = a.start_date !== null ? new Date(a.start_date) : distantFuture
                    let dateB = b.start_date !== null ? new Date(b.start_date) : distantFuture
                    return dateA.getTime() - dateB.getTime()
                });
                console.log('sort_cart:', sort_cart);
                dispatch(SetBigCart(sort_cart, true));
            }
        })).catch((error) => {
            console.log('error get all product finance:', error);
        });
    }
};
