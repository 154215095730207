import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PlaneLoader from "../PlaneLoader/PlaneLoader";
import GetCookie from "../Common/Functions/GetCookie";
import GetTrip from "./Functions/GetTrip";
import GetItinerary from "./Functions/GetItinerary";
import { ToggleAndSetLanguage } from "../../Actions/Header";
import { AppState } from "../../Reducers/Reducers";

export default function CrmRedirection(props: WithRouterProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const trip_id = useSelector((state: AppState) => state.trip.trip_id);
    const itinerary_retrieve = useSelector((state: AppState) => state.itinerary.itinerary_retrieve);
    const user = useSelector((state: AppState) => state.user.user);

    useEffect(() => {
        if (language !== null && language !== i18n.language) {
            if (language !== i18n.language) {
                i18n.changeLanguage(language);
            }
        }
        let lang = i18n.languages.filter((lng) => {
            return lng.length === 2;
        });
        dispatch(ToggleAndSetLanguage(false, lang[0]));
    }, [language]);

    useEffect(() => {
        if (trip_id === null || trip_id !== parseInt(GetCookie('trip_id'))) {
            GetTrip(user, dispatch, t);
        } else if (itinerary_retrieve) {
            GetItinerary(dispatch, t);
        }
    }, [trip_id, itinerary_retrieve]);

    useEffect(() => {
        if (props.location.query.duplication) {
            dispatch({
                type: "MENU_FROM_DUPLICATION",
                payload: {
                    from_duplication: true
                }
            });
        }
        if (trip_id !== null && trip_id === parseInt(GetCookie('trip_id'))) {
            props.router.push(`/${window.url_name}/apps/itinerary`);
        }
    }, [
        trip_id,
        props.location.query.duplication,
        props.router
    ]);

    return (
        <div className={"ft-light-green absolute-center ft-font-25"}>
            <PlaneLoader />
            <p>{t("trip.load")}</p>
        </div>
    );
}
