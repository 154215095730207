//---- Dependencies ----//
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import i18n from "../../i18n";
import clsx from "clsx";
import { debounce } from "lodash";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { SetHistorydest, SetItineraryTypeData, SetItineraryTypeLoading } from "../../Actions/ItineraryType";
import { DestinationChange } from "../../Actions/Header";
import MaterialHeader from "../Header/MaterialHeader.jsx";
import MaterialBreadcrumb from "./MaterialBreadcrumb";
import GetNewChildren from "./Functions/GetNewChildren";
import MaterialPackageCard from "./MaterialPackageCard";
import MaterialCircuitCard from "./MaterialCircuitCard";
import MaterialItiTypeCard from "./MaterialItiTypeCard";
import MaterialDestCard from "../Home/MaterialDestCard";
import MaterialDuplicateDialog from "./MaterialDuplicateDialog";
import MaterialProducsFilters from "./MaterialProducsFilters.tsx";
import Help from "../Base/Help";
import MaterialLogin from "../Home/MaterialLogin";

import localeText from "../Functions/localeText.js";


const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "24%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        "background": `${main_color}`,
        // padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: `${main_color}CA`
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        marginBottom: 50,
        fontWeight: 400
    }
}));


const MaterialDestinationProducts = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);

    const itinerary_type_data = useSelector(store => store.itinerary_type.itinerary_type_data);
    const second_dest_list = useSelector(store => store.itinerary_type.second_dest_list);
    const is_connected = useSelector(store => store.user.is_connected);
    const save_destination = useSelector(store => store.itinerary_type.save_destination);
    const locales = useSelector(state => state.user.locales);
    const filters_type = useSelector(store => store.home.filters_type);
    const filter_destinations = useSelector(store => store.home.filter_destinations);
    const filter_categories = useSelector(store => store.home.filter_categories);
    const guide_languages = useSelector(store => store.home.guide_languages);
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const [loading, setLoading] = useState(false);
    const [allIti, setAllIti] = useState([]);
    const [allPackage, setAllPackage] = useState([]);
    const [allCircuit, setAllCircuit] = useState([]);
    const [itiType, setItiType] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [circuitType, setCircuitType] = useState([]);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentGuideLang, setCurrentGuideLang] = useState([]);
    let id = props.params.desti_id;
    let history = [];
    let data_package = [];
    let data_circuit = [];
    let data_iti = [];

    useEffect(() => {
        if (save_destination[id] === undefined) {
            getDestinationData();
        } else {
            setAllIti(save_destination[id].itiType.slice());
            setAllPackage(save_destination[id].packageType.slice());
            setAllCircuit(save_destination[id].circuitType.slice());
            setItiType(save_destination[id].itiType.slice());
            setPackageType(save_destination[id].packageType.slice());
            setCircuitType(save_destination[id].circuitType.slice());
            // getCurrentDestination(save_destination[id].itiType, save_destination[id].packageType, save_destination[id].circuitType);
            GetNewChildren(id, dispatch);
        }
        // getCategories();
        setNewBreadCrumbs();
    }, [id]);
    useEffect(() => {
        if (itinerary_type_data !== null) {
            const { token, cancel } = axios.CancelToken.source();
            getNewCards(filter_destinations, filter_categories, guide_languages, token);
            return () => {
                cancel();
            }
            // setNewBreadCrumbs();
        }
    }, [
        itinerary_type_data,
        filter_destinations,
        filter_categories,
        guide_languages
    ]);
    useEffect(() => {
        if (allIti.length !== 0 || allPackage.length !== 0 || allCircuit.length !== 0) {
            let tmp_iti_type = [];
            let tmp_package = [];
            let tmp_circuit = [];
            if (filters_type.length !== 0) {
                // filters_type 0: package 1: circuits, 2: itinerary type
                if (filters_type.includes(t('itineraryType.all_itinerary_type'))) {
                    tmp_iti_type = [...allIti];
                }
                if (filters_type.includes(t('itineraryType.all_circuits'))) {
                    tmp_circuit = [...allCircuit];
                }
                if (filters_type.includes(t('itineraryType.all_packages'))) {
                    tmp_package = [...allPackage];
                }
                if (filters_type.includes(t('itineraryType.connected_circuits')) && !filters_type.includes(t('itineraryType.circuits'))) {
                    tmp_circuit = allCircuit.filter(el => !el.is_custom);
                }
                if (filters_type.includes(t('itineraryType.offline_circuits')) && !filters_type.includes(t('itineraryType.circuits'))) {
                    tmp_circuit = allCircuit.filter(el => el.is_custom);
                }
                if (filters_type.includes(t('itineraryType.connected_circuits')) && filters_type.includes(t('itineraryType.offline_circuits'))) {
                    tmp_circuit = [...allCircuit];
                }
            } else {
                tmp_iti_type = [...allIti];
                tmp_circuit = [...allCircuit];
                tmp_package = [...allPackage];
            }
            if (filter_destinations.length !== 0) {
                tmp_iti_type = tmp_iti_type.filter((row) => {
                    let desti = '';
                    if (row.destination !== undefined && row.destination !== null) {
                        if (filter_destinations.some((el) => el.id === row.destination.id)) {
                            return true;
                        }
                        // desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
                    } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
                        let data = row.data.find(el => el.id === row.current_version);
                        if (data.destination !== undefined && data.destination !== null) {
                            if (filter_destinations.some((el) => el.id === data.destination.id)) {
                                return true;
                            }
                            // desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                        }
                    }
                    // if (filter_destinations.includes(desti)) {
                    //     return true;
                    // }
                });
                // tmp_circuit = tmp_circuit.filter((row) => {
                //     let desti = '';
                //     if (row.destination !== undefined && row.destination !== null) {
                //         desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
                //     } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
                //         let data = row.data.find(el => el.id === row.current_version);
                //         if (data.destination !== undefined && data.destination !== null) {
                //             desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                //         }
                //     }
                //     if (filter_destinations.includes(desti)) {
                //         return true;
                //     }
                // });
                // tmp_package = tmp_package.filter((row) => {
                //     let desti = '';
                //     if (row.destination !== undefined && row.destination !== null) {
                //         desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
                //     } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
                //         let data = row.data.find(el => el.id === row.current_version);
                //         if (data.destination !== undefined && data.destination !== null) {
                //             desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                //         }
                //     }
                //     if (filter_destinations.includes(desti)) {
                //         return true;
                //     }
                // });
            }
            // if (filter_categories.length !== 0) {
            //     tmp_iti_type = [];
            //     tmp_package = tmp_package.filter(el => {
            //         return el.categories.some(categorie => filter_categories.find(tmp => tmp.id === categorie.id));
            //     });
            //     tmp_circuit = tmp_circuit.filter(el => {
            //         return el.categories.some(categorie => filter_categories.find(tmp => tmp.id === categorie.id));
            //     });
            // }
            // if (guide_languages.length !== 0) {
            //     tmp_iti_type = [];
            //     tmp_package = tmp_package.filter(el => {
            //         return el.guide_languages.some(lang => guide_languages.find(tmp => tmp.id === lang.id));
            //     });
            //     tmp_circuit = tmp_circuit.filter(el => {
            //         return el.guide_languages.some(lang => guide_languages.find(tmp => tmp.id === lang.id));
            //     });
            // }
            setItiType(tmp_iti_type);
            setPackageType(tmp_package);
            setCircuitType(tmp_circuit);
        }
    }, [filters_type, allIti, allCircuit, allPackage]);
    // }, [filters_type, filter_destinations, filter_categories, allIti, allCircuit, allPackage, guide_languages]);
    const getDestinationData = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            dispatch(SetItineraryTypeLoading(true));
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/`
            }).then(function (response) {
                dispatch(SetItineraryTypeData(response.data.data));
                // dispatch(DestinationChange(response.data));
                dispatch(SetItineraryTypeLoading(false));
                // setNewBreadCrumbs(response.data.destination, response.data.name !== undefined ? response.data.name : response.data.international_name);
                GetNewChildren(id, dispatch);
            }).catch(function (error) {
                dispatch(SetItineraryTypeLoading(false));
                console.log('err:', error);
            });
        }
    };
    const setNewBreadCrumbs = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/breadcrumbs/`
            }).then(function (response) {
                let data = response.data;
                history = [];
                let is_main = (getDestinationPositionOnBreadcrumbs(data.child, id));
                if (is_main.type !== undefined) {
                    if (is_main.type === 1) {
                        let tmp_arr = [];
                        let tmp_obj = {
                            name: t("home.back_home"),
                            data: null,
                            need_redirect: false

                        };
                        tmp_arr.push(tmp_obj);
                        tmp_obj = {
                            name: is_main.name,
                            data: is_main,
                            need_redirect: true
                        };
                        tmp_arr.push(tmp_obj);
                        dispatch(SetHistorydest(tmp_arr));
                    } else {
                        let tmp_arr = [];
                        let tmp_obj = {
                            name: t("home.back_home"),
                            data: null,
                            need_redirect: false
                        };
                        tmp_arr.push(tmp_obj);
                        for (let i = 0; i < history.length; i++) {
                            tmp_obj = {
                                name: history[i].name,
                                data: history[i],
                                need_redirect: true
                            };
                            tmp_arr.push(tmp_obj);
                        }
                        dispatch(SetHistorydest(tmp_arr));
                    }
                }
            }).catch(function (error) {
                console.log('err:', error);
            });
        }
    };
    const getDestinationPositionOnBreadcrumbs = (data, id_to_find) => {
        if (data.id !== parseFloat(id_to_find)) {
            if (data.data.type !== 0) {
                history.push(data.data);
            }
            return (getDestinationPositionOnBreadcrumbs(data.child, id_to_find));
        }
        history.push(data.data);
        return (data.data);
    };
    const redirectLoading = () => {
        setLoading(false);
        dispatch({
            type: "ITINERARY_SAVE_DESTINATION",
            payload: {
                destination_id: id,
                new_data: {
                    packageType: data_package,
                    circuitType: data_circuit,
                    itiType: data_iti
                }
            }
        });
    };
    const handleDuplicateOpen = () => {
        if (is_connected) {
            setDuplicationOpen(!duplicationOpen);
        } else {
            setLoginDialog(!loginDialog);
        }
    };
    const getCurrentDestination = (iti_type, tmp_package, tmp_circuit, destination_list) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/circuits/available_filters/`,
                params: {
                    "visibility__in": "PUBLIC,PRIVATE_TO",
                    homepage: true,
                },
                data: JSON.stringify({ destination_list: destination_list })
            }).then((response) => {
                setDestinations(response.data.destinations);
                setCurrentGuideLang(response.data.guide_languages);
                setCategories(response.data.categories);
                let array_request = [];
                for (let offset = 20; offset < response.data.total_count; offset = offset + 10) {
                    // axios({
                    //     method: "POST",
                    //     headers: headers,
                    //     url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                    //     params: {
                    //         limit: 20,
                    //         offset: offset,
                    //         guide_language_ids: guide_languages && guide_languages.length > 0 ? guide_languages.map(language => language.id).join(",") : undefined,
                    //         category_ids: filter_categories && filter_categories.length > 0 ? filter_categories.map(category => category.id).join(",") : undefined,
                    //     },
                    //     // url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                    //     data: JSON.stringify({ destination_list: destination_list })
                    // }).then((response) => {
                    //     response.data.map((data) => {
                    //         if (data.type === "CIRCUIT") {
                    //             if (!data_circuit.some((circuit) => circuit.id === data.id)) {
                    //                 data_circuit.push(data);
                    //             }
                    //         } else {
                    //             if (!data_package.some((circuit) => circuit.id === data.id)) {
                    //                 data_package.push(data);
                    //             }
                    //         }
                    //     });
                    //     setAllPackage(data_package);
                    //     setAllCircuit(data_circuit);
                    //     setPackageType(data_package);
                    //     setCircuitType(data_circuit);
                    // }).catch((error) => {
                    //
                    // });
                    array_request.push(
                        axios({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                            params: {
                                limit: 10,
                                offset: offset,
                                guide_language_ids: guide_languages && guide_languages.length > 0 ? guide_languages.map(language => language.id).join(",") : undefined,
                                category_ids: filter_categories && filter_categories.length > 0 ? filter_categories.map(category => category.id).join(",") : undefined,
                            },
                            // url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                            data: JSON.stringify({ destination_list: destination_list })
                        })
                    );
                }
                axios.all([...array_request]).then(axios.spread((...responses) => {
                    console.log('responses axios all:', responses);
                    responses.map((response) => {
                        response.data.map((data) => {
                            if (data.type === "CIRCUIT") {
                                if (!data_circuit.some((circuit) => circuit.id === data.id)) {
                                    data_circuit.push(data);
                                }
                            } else {
                                if (!data_package.some((circuit) => circuit.id === data.id)) {
                                    data_package.push(data);
                                }
                            }
                        });
                    })
                    setAllPackage(data_package);
                    setAllCircuit(data_circuit);
                    setPackageType(data_package);
                    setCircuitType(data_circuit);
                }));
            }).catch(() => {

            });
        }
        //
        //
        // let tmp_desti = [];
        // let tmp_lang = [];
        // iti_type.map((row) => {
        //     if (row.destination !== undefined && row.destination !== null) {
        //         let desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
        //         if (!tmp_desti.includes(desti)) {
        //             tmp_desti.push(desti);
        //         }
        //     } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
        //         let data = row.data.find(el => el.id === row.current_version);
        //         if (data.destination !== undefined && data.destination !== null) {
        //             let desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
        //             if (!tmp_desti.includes(desti)) {
        //                 tmp_desti.push(desti);
        //             }
        //         }
        //     }
        // });
        // tmp_package.map((row) => {
        //     if (row.destination !== undefined && row.destination !== null) {
        //         let desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
        //         if (!tmp_desti.includes(desti)) {
        //             tmp_desti.push(desti);
        //         }
        //     } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
        //         let data = row.data.find(el => el.id === row.current_version);
        //         if (data.destination !== undefined && data.destination !== null) {
        //             let desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
        //             if (!tmp_desti.includes(desti)) {
        //                 tmp_desti.push(desti);
        //             }
        //         }
        //     }
        // });
        // tmp_circuit.map((row) => {
        //     if (row.destination !== undefined && row.destination !== null) {
        //         let desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
        //         if (!tmp_desti.includes(desti)) {
        //             tmp_desti.push(desti);
        //         }
        //     } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
        //         let data = row.data.find(el => el.id === row.current_version);
        //         if (data.destination !== undefined && data.destination !== null) {
        //             let desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
        //             if (!tmp_desti.includes(desti)) {
        //                 tmp_desti.push(desti);
        //             }
        //         }
        //     }
        //     if (row.guide_languages !== undefined && row.guide_languages !== null) {
        //         row.guide_languages.some((lang) => {
        //             if (tmp_lang.find(tmp => tmp.id === lang.id) === undefined) {
        //                 tmp_lang.push(lang);
        //             }
        //         });
        //     }
        // });
        // tmp_desti = tmp_desti.sort();
        // setCurrentGuideLang(tmp_lang);
        // setDestinations(tmp_desti);
    };
    const getNewCards = useCallback(
        debounce(
            (filter_destinations, filter_categories, guide_languages, cancelToken) => {
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    if (itinerary_type_data !== null) {
                        setLoading(true);
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/destinations/${id}/get_inheritance/`,
                            cancelToken
                        }).then(function (response) {
                            let destination_list = response.data.split(',');
                            // setDestinationList(destination_list);
                            let array_request = [];
                            array_request.push(
                                axios({
                                    method: "POST",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/trip/by_destination/?typical=1&visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                                    data: JSON.stringify({ destination_list: filter_destinations && filter_destinations.length > 0 ? filter_destinations : destination_list }),
                                    cancelToken
                                })
                            );
                            // array_request.push(
                            //     axios({
                            //         method: "POST",
                            //         headers: headers,
                            //         url: `${API_HREF}client/${window.id_owner}/packages/by_destination/?visibility__in=PUBLIC,PRIVATE_TO`,
                            //         data: JSON.stringify({destination_list: destination_list}),
                            //     })
                            // );
                            array_request.push(
                                axios({
                                    method: "POST",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true&limit=20&offset=0`,
                                    params: {
                                        guide_language_ids: guide_languages && guide_languages.length > 0 ? guide_languages.map(language => language.id).join(",") : undefined,
                                        category_ids: filter_categories && filter_categories.length > 0 ? filter_categories.map(category => category.id).join(",") : undefined,
                                    },
                                    // url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                                    data: JSON.stringify({ destination_list: destination_list }),
                                    cancelToken
                                })
                            );

                            axios.all([...array_request]).then(axios.spread((...responses) => {
                                console.log('responses axios all:', responses);
                                data_iti = [...responses[0].data];
                                // data_package = [...responses[1].data];
                                responses[1].data.map((data) => {
                                    if (data.type === "CIRCUIT") {
                                        data_circuit.push(data);
                                    } else {
                                        data_package.push(data);
                                    }
                                });
                                setAllIti(data_iti);
                                setAllPackage(data_package);
                                setAllCircuit(data_circuit);
                                setPackageType(data_package);
                                setCircuitType(data_circuit);
                                setItiType(data_iti);
                                getCurrentDestination(data_iti, data_package, data_circuit, destination_list);
                                if (responses.length === 2) {
                                    redirectLoading();
                                }
                            })).catch((error) => {
                                console.log('error:', error);
                                if (axios.isCancel(error)) {
                                    setLoading(false);
                                }
                            })
                        }).catch(function (error) {
                            console.log('error:', error);
                            if (axios.isCancel(error)) {
                                setLoading(false);
                            }
                        });
                    }
                }
            }, 500),
        [
            id,
            data_circuit,
            data_iti,
            data_package,
            itinerary_type_data
        ]
    );
    // const getCategories = () => {
    // 	let { pass_check, headers } = CheckBeforeRequest();
    //     if (pass_check) {
    //         axios({
    //             method: "GET",
    //             headers: headers,
    //             url: `${API_HREF}client/${window.id_owner}/circuit-category/?limit=50`,
    //         }).then(function (response) {
    //             setCategories(response.data.results);
    //         }).catch((error) => {
    //             console.log('categories error:', error);
    //         });
    //     }
    // };
    let cover_picture = null;
    if (itinerary_type_data !== null && itinerary_type_data.cover_picture !== null) {
        cover_picture = itinerary_type_data.cover_picture;
    } else if (itinerary_type_data !== null && itinerary_type_data.cover_picture === null && itinerary_type_data.pictures !== null && itinerary_type_data.pictures.length > 0) {
        cover_picture = itinerary_type_data.pictures[0];
    }
    return (
        <Grid container style={{ display: "block" }}>
            <Grid item className={clsx(classes.headerPicture, "wrapper")}>
                <img src={itinerary_type_data !== null ? ((cover_picture !== undefined && cover_picture !== null) ? (cover_picture.thumbnail_big !== null ? cover_picture.thumbnail_big : cover_picture.url) : "/Img/default_dest1.jpeg") : "/Img/default_dest1.jpeg"} className={"ft-cover-img"} />
            </Grid>
            <Grid item className={classes.headerContainer} id={"home-picture"} style={{ display: "block" }}>
                <Grid container direction={"row"} justify={"flex-end"}>
                    <Grid item className={"home-nav"} id={"home-nav"} style={{ top: 0 }}>
                        {
                            !is_connected && (
                                <Fragment>
                                    <Grid item style={{ position: "absolute", left: 16, top: 20 }}>
                                        <Link to={`/home/${window.url_name}/`} id={"header-home-link"}>
                                            <img src={logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_favicon_header.png"} height={30} alt={""} />
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Button style={{ color: "white" }} onClick={() => setLoginDialog(true)}>{t("home.nav.login")}</Button>
                                    </Grid>
                                </Fragment>
                            )
                        }
                        {
                            is_connected && (
                                <MaterialHeader router={props.router} />
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.absoluteCenter} style={{ width: fullScreen ? "100%" : "auto" }}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                    {
                        itinerary_type_data !== null && (
                            <Grid item>
                                <Typography variant={"h2"} className={classes.destinationTitle}>{localeText(current_locale.id, itinerary_type_data.localization, itinerary_type_data.international_name, 'name')}</Typography>
                            </Grid>
                        )
                    }
                    <Grid item>
                        <Button variant={"contained"} fullWidth={fullScreen} color={"primary"} className={classes.customButton} onClick={handleDuplicateOpen}>{t("itineraryType.header_btn")}</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && (
                    <Grid item style={{ paddingBottom: 70 }}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} alignItems={"center"} spacing={4}>
                                <Grid item>
                                    <MaterialBreadcrumb router={props.router} />
                                </Grid>
                            </Grid>
                            <MaterialProducsFilters destinations={destinations} categories={categories} languages={currentGuideLang} />
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                                {
                                    ['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && itiType.length !== 0 && (
                                        <Fragment>
                                            <Grid item className={classes.genericText}>
                                                <Typography variant={"h4"}>{t("itineraryType.our_itinerary_type")}</Typography>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    {
                                                        itiType.map((iti_info, iti_index) => {
                                                            return (
                                                                <MaterialItiTypeCard key={iti_index} iti_info={iti_info} router={props.router} desti_id={id} />
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    is_connected && loading && (
                                        <Grid item style={{ paddingBottom: 70 }}>
                                            <Container className={classes.container}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    <Grid item>
                                                        <CircularProgress />
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </Grid>
                                    )
                                }
                                {
                                    packageType.length !== 0 && !loading && (
                                        <Fragment>
                                            <Grid item className={classes.genericText}>
                                                <Typography variant={"h4"}>{t("itineraryType.our_packages")}</Typography>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    {
                                                        packageType.map((package_info, package_index) => {
                                                            return (
                                                                <MaterialPackageCard key={package_index} package_info={package_info} router={props.router} desti_id={id} />
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    circuitType.length !== 0 && !loading && (
                                        <Fragment>
                                            <Grid item className={classes.genericText}>
                                                <Typography variant={"h4"}>{t("itineraryType.our_circuits")}</Typography>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    {
                                                        circuitType.map((circuit_info, circuit_index) => {
                                                            if (circuit_info.type === "CIRCUIT") {
                                                                return (
                                                                    <MaterialCircuitCard key={circuit_index} circuit_info={circuit_info} router={props.router} desti_id={id} />
                                                                );
                                                            }
                                                            return (
                                                                <MaterialPackageCard key={circuit_index} circuit_info={circuit_info} router={props.router} desti_id={id} />
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                            {/*<Grid item xs={ 12 }>*/}
                                            {/*    <Button onClick={ onLoadMoreCircuit }>{ t('home.load_more_circuit') }</Button>*/}
                                            {/*</Grid>*/}
                                        </Fragment>
                                    )
                                }
                                {
                                    !['cercledesvoyages'].includes(quotation_code) && itiType.length !== 0 && (
                                        <Fragment>
                                            <Grid item className={classes.genericText}>
                                                <Typography variant={"h4"}>{t("itineraryType.our_itinerary_type")}</Typography>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    {
                                                        itiType.map((iti_info, iti_index) => {
                                                            return (
                                                                <MaterialItiTypeCard key={iti_index} iti_info={iti_info} router={props.router} desti_id={id} />
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    second_dest_list !== null && second_dest_list.length > 0 && JSON.parse(localStorage.getItem("config")).quotation_code !== "verdie" && (
                                        <Fragment>
                                            <Grid item className={classes.genericText}>
                                                <Typography variant={"h4"}>{t("itineraryType.related_destination")}</Typography>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                                    {
                                                        second_dest_list.map((dest, dest_index) => {
                                                            return (
                                                                <MaterialDestCard key={dest_index} destination={dest} router={props.router} type={"product"} />
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            <MaterialDuplicateDialog
                duplicationOpen={duplicationOpen}
                setDuplicationOpen={setDuplicationOpen}
                isFrom={"createNewTrip"}
                duration_day={0}
                router={props.router}
            />
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={null} id={null} />
            {JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help />}
        </Grid>
    );
};
export default MaterialDestinationProducts;
