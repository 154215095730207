//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isNumber } from "lodash";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


import SearchIcon from '@material-ui/icons/Search';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

//---- custom icon ----//
import KidsIcon from "./KidsIcon.js";
import ManIcon from "./ManIcon.js";
import DoorIcon from "./DoorIcon.js";

import { useGetTaxesPrice } from "../CartMaterial/utils/getTaxesPrice";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId.js";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";

import MobileHeaderDialog from "./MobileHeaderDialog.js";
import GetStatusColorHexa from "../Menu/MaterialTripList/Functions/GetStatusColorHexa.js";

const useStyles = makeStyles(theme => ({
    orange: {
        color: "#E6592F !important"
    },
    budgetContainer: {
        width: '150px !important',
        maxWidth: '150px !important'
    },
    mobileContainerOpen: {
        position: "relative !important",
        transition: "background 200ms cubic-bezier(0.35, 0, 0.65, 1) 0s !important",
        background: "rgb(255, 255, 255) !important"
        //boxShadow: "rgb(0 0 0 / 8%) 0px 1px 0px !important"
    },
    topContainer: {
        WebkitBoxAlign: "center !important",
        display: "flex !important",
        alignItems: "center !important",
        minHeight: "64px !important"
    },
    leftSideOpen: {
        flexBasis: "44px !important",
        marginLeft: "12px !important",
        marginRight: "12px !important",
        flexShrink: "0 !important"
    },
    leftButtonOpen: {
        WebkitBoxPack: "center !important",
        WebkitBoxAlign: "center !important",
        appearance: "none !important",
        display: "inline-flex !important",
        borderRadius: "50% !important",
        border: "1px transparent !important",
        outline: "0px !important",
        margin: "0px !important",
        padding: "0px !important",
        color: "rgb(34, 34, 34) !important",
        backgroundColor: "transparent !important",
        cursor: "pointer !important",
        touchAction: "manipulation !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important",
        width: "44px !important",
        height: "44px !important"
    },
    middleOpen: {
        WebkitBoxFlex: "1 !important",
        flexGrow: "1 !important"
    },
    middleOpen1: {
        WebkitBoxPack: "center !important",
        WebkitBoxAlign: "center !important",
        display: "flex !important",
        width: "100% !important",
        alignItems: "center !important",
        justifyContent: "center !important"
    },
    middleOpenTitle: {
        fontSize: "16px !important",
        lineHeight: "20px !important",
        fontWeight: "600 !important",
        color: "rgb(34, 34, 34) !important"
    },
    rightOpen: {
        flexBasis: "44px !important",
        marginLeft: "12px !important",
        marginRight: "12px !important",
        flexShrink: "0 !important"
    },
    RightButtonOpen: {
        cursor: "pointer !important",
        position: "relative !important",
        touchAction: "manipulation !important",
        fontFamily: "inherit !important",
        fontSize: "inherit !important",
        lineHeight: "inherit !important",
        fontWeight: "inherit !important",
        borderRadius: "0px !important",
        outline: "none !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
        background: "transparent !important",
        border: "none !important",
        color: "inherit !important",
        display: "block !important",
        margin: "0px !important",
        padding: "0px !important",
        textAlign: "inherit !important",
        textDecoration: "none !important",
        height: "100% !important",
        width: "100% !important"
    },
    BottomContainer: {
        maxHeight: "500px !important",
        paddingLeft: "24px !important",
        paddingBottom: "24px !important",
        paddingRight: "24px !important"
    },
    boxOpen: {
        border: "1px solid rgb(221, 221, 221) !important",
        borderRadius: "12px !important",
        backgroundColor: "rgb(255, 255, 255) !important",
        boxShadow: "rgb(0 0 0 / 12%) 0px 6px 16px !important",
        color: "rgb(34, 34, 34) !important",
        transition: "box-shadow 0.2s cubic-bezier(0.35, 0, 0.65, 1) 0s !important"
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "12px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "0px 14px !important"
    },
    mobileList: {
        "& .mui-jss-MuiListItem-root": {
            paddingTop: "8px !important",
            paddingBottom: "8px !important"
        }
    }
}));

const MobileHeader = ({ setMobileOpen, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const trip = useSelector((state) => state.trip.data_trip);
    const travelers = useSelector((store) => {
        return store.trip.travelers;
    });
    const traveler_groups = useSelector((store) => {
        return store.trip.traveler_groups;
    });
    const start_date = useSelector((store) => {
        return store.trip.start_date;
    });
    const end_date = useSelector((store) => {
        return store.trip.end_date;
    });
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const all_data = useSelector((store) => {
        return store.trip.all_data;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const trip_reference = useSelector((store) => {
        return store.trip.trip_reference;
    });
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const bdd_cart = useSelector((store) => {
        return store.accommodation.cart;
    });
    const car_cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const flight_cart = useSelector((store) => {
        return store.flight.cart;
    });
    const manual_item_list = useSelector((store) => {
        return store.cart.manual_item_list;
    });
    const transfers_cart = useSelector((store) => {
        return store.transfers.cart;
    });
    const poi_cart = useSelector((store) => {
        return store.poi.cart;
    });
    const hasStackedItems = useSelector(state => state.cart.hasStackedItems);

    const [currencyCost, setCurrencyCost] = useState([]);
    const [dialog_type, setDialogType] = useState(null);
    const [baseAdult, setBaseAdult] = useState([]);
    const [baseChildren, setBaseChildren] = useState([]);
    const [roomNb, setRoomNb] = useState(0);
    const getTaxesPrice = useGetTaxesPrice();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    let nb_version = null;
    all_data.data.map((data, data_index) => {
        if (data.id === data_trip.id) {
            nb_version = data_index + 1;
        }
    });

    useEffect(() => {
        getData();
        getCompo();
    }, []);
    useEffect(() => {
        getData();
    }, [poi_cart, transfers_cart, manual_item_list, flight_cart, car_cart, bdd_cart]);
    const getCompo = () => {
        let tmp_base_adult = [];
        let tmp_base_child = [];
        let room_nb = 0;
        for (let i = 0; i < traveler_groups.length; i++) {
            if (traveler_groups[i].default) {
                room_nb += 1;
            }
        }
        travelers.map((traveler) => {
            if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18) {
                tmp_base_adult.push(traveler);
            } else {
                tmp_base_child.push(traveler);
            }
        });
        setBaseAdult(tmp_base_adult);
        setBaseChildren(tmp_base_child);
        setRoomNb(room_nb);
    };
    const getData = () => {
        let custom_cost = false;
        let currency_cost = [];
        let nb_items = 0;
        if (data_trip !== null && data_trip.prices_flight.length > 0) {
            let client_price = Object.assign({}, data_trip.prices_flight[GetPricesId(data_trip.prices_flight, user.client, user)]);
            if (data_trip.flight_taxes !== null) {
                client_price.selling_price = parseFloat(client_price.selling_price) + parseFloat(data_trip.flight_taxes);
            }
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = !hasStackedItems;
        }
        if (data_trip !== null && data_trip.prices_terrestrial.length > 0) {
            const client_price = data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, user.client, user)];
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = !hasStackedItems;
        }
        if (data_trip !== null && data_trip.prices_stack_product.length > 0) {
            const client_price = data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, user.client, user)];
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = true;
        }
        if (user !== undefined && user !== null && user.client !== undefined && user.client !== null) {
            for (let i = 0; i < flight_cart.length; i++) {
                if (flight_cart[i].is_optional === false && !isProductPackaged({ product: flight_cart[i], stackInfos: trip?.stack_info ?? null }) && flight_cart[i].prices && flight_cart[i].prices.length > 0 && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false) && (flight_cart[i].booking_status === null || (flight_cart[i].booking_status !== null && flight_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    let more_option = 0;
                    flight_cart[i].outbounds.map((outbound) => {
                        let leg = outbound.legs[0];
                        if (leg.paid_options.length > 0) {
                            leg.paid_options.map((option) => {
                                more_option += parseFloat(option.price);
                            });
                        }
                    });
                    let client_price = Object.assign({}, flight_cart[i].prices[GetPricesId(flight_cart[i].prices, user.client, user)]);
                    client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(flight_cart[i].taxes) + parseFloat(more_option);
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < bdd_cart.length; i++) {
                if (bdd_cart[i].is_optional === false && !isProductPackaged({ product: bdd_cart[i], stackInfos: trip?.stack_info ?? null }) && bdd_cart[i].prices && bdd_cart[i].prices.length > 0 && (bdd_cart[i].is_available !== undefined && bdd_cart[i].is_available !== false) && (bdd_cart[i].booking_status === null || (bdd_cart[i].booking_status !== null && bdd_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = bdd_cart[i].prices[GetPricesId(bdd_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < poi_cart.length; i++) {
                if (poi_cart[i].is_optional === false && !isProductPackaged({ product: poi_cart[i], stackInfos: trip?.stack_info ?? null }) && poi_cart[i].prices !== null && poi_cart[i].prices.length > 0 && (poi_cart[i].is_available !== undefined && poi_cart[i].is_available !== false) && (poi_cart[i].booking_status === null || (poi_cart[i].booking_status !== null && poi_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = poi_cart[i].prices[GetPricesId(poi_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < transfers_cart.length; i++) {
                if (transfers_cart[i].is_optional === false && !isProductPackaged({ product: transfers_cart[i], stackInfos: trip?.stack_info ?? null }) && transfers_cart[i].prices !== null && transfers_cart[i].prices.length > 0 && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false) && (transfers_cart[i].booking_status === null || (transfers_cart[i].booking_status !== null && transfers_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = transfers_cart[i].prices[GetPricesId(transfers_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < manual_item_list.length; i++) {
                if (manual_item_list[i].prices) {
                    if (custom_cost && user.client_full !== undefined && user.client_full.type === 2 && user.client_full.type === manual_item_list[i].creator.client.type) {
                        let client_price = Object.assign({}, manual_item_list[i].prices[GetPricesId(manual_item_list[i].prices, user.client, user, true)]);
                        if (manual_item_list[i].taxes !== null) {
                            client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(manual_item_list[i].taxes);
                        }
                        currency_cost = checkCurrencyCost(currency_cost, client_price);
                    } else if (manual_item_list[i].is_optional === false) {
                        let client_price = Object.assign({}, manual_item_list[i].prices[GetPricesId(manual_item_list[i].prices, user.client, user, quotation_code === "verdie")]);
                        if (manual_item_list[i].taxes !== null) {
                            client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(manual_item_list[i].taxes);
                        }
                        currency_cost = checkCurrencyCost(currency_cost, client_price);
                    }
                }
            }
            for (let i = 0; i < car_cart.length; i++) {
                if (car_cart[i].is_optional === false && !isProductPackaged({ product: car_cart[i], stackInfos: trip?.stack_info ?? null }) && car_cart[i].prices !== null && car_cart[i].prices.length > 0 && (car_cart[i].is_available !== undefined && car_cart[i].is_available !== false) && (car_cart[i].booking_status === null || (car_cart[i].booking_status !== null && car_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = car_cart[i].prices[GetPricesId(car_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
        }
        setCurrencyCost(currency_cost);
    };
    const checkCurrencyCost = (currency_cost, client_price) => {
        let added = false;
        for (let i = 0; i < currency_cost.length; i++) {
            if (currency_cost[i].currency.id === client_price.selling_currency) {
                currency_cost[i].cost += parseFloat(client_price.selling_price);
                added = true;
            }
        }
        if (!added) {
            const new_currency_cost = currency_list.find((currency) => {
                return currency.id === client_price.selling_currency;
            });
            currency_cost.push({
                currency: new_currency_cost,
                cost: parseFloat(client_price.selling_price)
            });
        }
        return currency_cost;
    };
    const GetTripById = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/`
            }).then(function (response) {
                dispatch({
                    type: "MENU_SET_TRIP_INFO",
                    payload: {
                        trip_info: response.data
                    }
                });
                router.push(`/${window.url_name}/menu/trip-info/${GetCookie("trip_id_version")}/`);
                setMobileOpen(null);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    return (
        <Fragment>
            <Grid item container xs={isTablet ? 6 : 10} className={classes.mobileContainerOpen}>
                <Grid item xs={12} className={classes.topContainer}>
                    <div className={classes.leftSideOpen}>
                        <IconButton className={classes.leftButtonOpen} onClick={() => {
                            setMobileOpen(null);
                        }}>
                            <ClearOutlinedIcon/>
                        </IconButton>
                    </div>
                    <div className={classes.middleOpen} onClick={() => {
                        setMobileOpen(null);
                    }}>
                        <div className={classes.middleOpen1}>
                            <div className={classes.middleOpenTitle}>{t('global.modify_trip')}</div>
                        </div>
                    </div>
                    <div className={classes.rightOpen} onClick={() => {
                        setMobileOpen(null);
                    }}>
                        <IconButton className={classes.RightButtonOpen}>
                            <TuneOutlinedIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Paper
                className={`${classes.paperStepper}`}
                elevation={5}
                style={{ width: isTablet ? "50%" : "88%" }}
                children={
                    <List className={classes.mobileList}>
                        {
                            !isTablet && (
                                <Fragment>
                                    <ListItem button onClick={() => setDialogType("budget")}>
                                        <ListItemText primary={
                                            <Fragment>
                                                <span>{t("global.budget") + " : "}</span>
                                                <span>
                                                    {currencyCost.map((current_currency, index_current_currency) => <span key={index_current_currency} style={{ fontWeight: "bold", fontSize: 15 }}>{ index_current_currency > 0 ? ' + ' : '' }{ new Intl.NumberFormat("fr-FR").format(Math.ceil(current_currency.cost)) + " " + current_currency.currency.symbol }</span>) }
                                                </span>
                                            </Fragment>
                                        }/>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem button onClick={() => {
                                        if (parseFloat(GetCookie("trip_id_version")) !== data_trip.trip.current_version) {
                                            dispatch({
                                                type: "MENU_TO_SHOW_VERSION",
                                                payload: {
                                                    to_show_version: parseFloat(GetCookie("trip_id_version"))
                                                }
                                            });
                                        }
                                        GetTripById();
                                    }}>
                                        <ListItemText primary={
                                            <Fragment>
                                                <FiberManualRecordIcon style={{ color: GetStatusColorHexa(data_trip.status), marginRight: 10, verticalAlign: "text-bottom" }}/>
                                                <span>{"Dossier " + "n° "}</span>
                                                <span style={{ fontWeight: "bold" }}>{trip_reference}</span>
                                                <span>{" - V" + nb_version}</span>
                                            </Fragment>
                                        }/>
                                    </ListItem>
                                    <Divider/>
                                </Fragment>
                            )
                        }
                        {
                            // <ListItem button onClick={() => setDialogType("destination")}>
                            //     <ListItemIcon>
                            //         <SearchIcon className={`${classes.orange}`}/>
                            //     </ListItemIcon>
                            //     <ListItemText primary={t("header.destination")}/>
                            // </ListItem>
                        }
                        <Divider/>
                        <ListItem button onClick={() => setDialogType("datepicker")}>
                            <ListItemIcon>
                                <DateRangeOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={moment.utc(start_date).format("DD MMM") + " - " + moment.utc(end_date).format("DD MMM")}/>
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => setDialogType("travelers")}>
                            <ListItemText
                                disableTypography={true}
                                style={{ marginLeft: "16%" }}
                                primary={
                                    <Fragment>
                                        <ManIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529' }}/>
                                        <span style={{ paddingRight: 4, fontWeight: "bold" }}>{baseAdult.length}</span>
                                        <KidsIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529' }}/>
                                        <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{baseChildren.length}</span>
                                        <DoorIcon style={{ verticalAlign: 'sub', opacity: '80%', color: '#212529' }}/>
                                        <span style={{ paddingLeft: 4, fontWeight: "bold" }}>{roomNb}</span>
                                    </Fragment>
                                }
                            />
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => setDialogType("version")}>
                            <ListItemIcon>
                                <AddCircleOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("menu.trip_list.edit_version")}/>
                        </ListItem>
                    </List>
                }
            />
            <MobileHeaderDialog setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
        </Fragment>
    );
};
export default React.memo(MobileHeader);
