import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function transformTransfersToProgramDetailsItems(
    options: {
        locale: number | null,
        destinationId: number | null,
        cart: ReturnType<typeof useCartProducts>,
        startDate: string,
        endDate: string,
        t: TFunction
    }
) {
    const cars = options.cart.transfers.filter((item) => {
        return (
            window.moment.utc(item.transfer.start_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate)
            )
        ) && (
                item.type === 'manual' ||
                item.transfer.start_destination?.id === options.destinationId
            ) &&
            !item.transfer.is_optional;
    });
    return flatten(
        cars.map((item) => {
            const result = [
                (
                    window.moment.utc(item.transfer.start_date).isBetween(
                        window.moment.utc(options.startDate),
                        window.moment.utc(options.endDate)
                    )
                ) && (
                        item.type === 'manual' ||
                        item.transfer.start_destination?.id === options.destinationId
                    ) ?
                    {
                        dateOrder: item.transfer.start_date,
                        date: window.moment.utc(item.transfer.start_date).format('DD/MM'),
                        ref: item.transfer.booking_status?.item_reference,
                        title: item.type === 'normal' || !item.transfer.hide_hours ?
                            options.t(
                                'roadbook.program-details-transfer-pickup-title',
                                {
                                    hourly: window.moment.utc(item.transfer.start_date).format('HH[h]mm'),
                                    name: (() => {
                                        const localization = item.transfer.localization?.find((item) => {
                                            return item.locale === options.locale;
                                        });
                                        if (localization) {
                                            return localization.name;
                                        } else if (
                                            item.type === 'normal'
                                        ) {
                                            return item.transfer.custom_product?.title;
                                        }
                                        return item.transfer.name;
                                    })()
                                }
                            ) :
                            options.t(
                                'roadbook.program-details-transfer-pickup-title-without-hours',
                                {
                                    name: (() => {
                                        const localization = item.transfer.localization?.find((item) => {
                                            return item.locale === options.locale;
                                        });
                                        if (localization) {
                                            return localization.name;
                                        }
                                        return item.transfer.name;
                                    })()
                                }
                            ),
                        place: item.type === 'normal' || !item.transfer.hide_address ?
                            options.t(
                                'roadbook.program-details-transfer-pickup-agency',
                                {
                                    from: item.transfer.station_name_pickup ??
                                        item.transfer.address_pickup,
                                    to: item.transfer.station_name_return ??
                                        item.transfer.address_return
                                }
                            ) :
                            null,
                        note: item.transfer.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
