import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isArray } from "lodash";
import { isProductPackaged } from "./isProductPackaged";
import { isProductSamePackage } from "./isProductSamePackage";
import { useGetFlightSellingPrice } from "./getFlightSellingPrice";
import { useGetPrice } from "./getPrice";
import { usePackagedProducts } from "./packagedProducts";
import { Currency } from "../../../Reducers/objects/currency";
import { AppState } from "../../../Reducers/Reducers";

type Package = {
    stackNumber: number | null,
    stackInfoId: number | null
}

type Costs = {
    cost: number,
    currency: Currency | undefined
}[]

export function usePackagePrice(packageItem: Package): Costs {
    const trip = useSelector((state: AppState) => state.trip?.data_trip);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const getPrice = useGetPrice();
    const [getFlightPrice] = useGetFlightSellingPrice();
    const packagedProducts = usePackagedProducts();
    const products = useMemo(() => {
        return packagedProducts[`${packageItem.stackNumber ?? ''}-${packageItem.stackInfoId ?? ''}`];
    }, [
        packagedProducts,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesFlight = useMemo(() => {
        return trip?.prices_flight?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            );
        });
    }, [
        trip,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesTerrestrial = useMemo(() => {
        return trip?.prices_terrestrial?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            );
        });
    }, [
        trip,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesAccommodations = useMemo(() => {
        return products?.accommodations.filter((item) => {
            return item.accommodation.is_available && isProductPackaged({
                product: item.accommodation,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.accommodation,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.accommodation.is_optional &&
            !item.accommodation.provider_refused;
        });
    }, [
        trip,
        products?.accommodations,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesTransfers = useMemo(() => {
        return products?.transfers.filter((item) => {
            return item.transfer.is_available && isProductPackaged({
                product: item.transfer,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.transfer,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.transfer.is_optional &&
            !item.transfer.provider_refused;
        });
    }, [
        trip,
        products?.transfers,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesPois = useMemo(() => {
        return products?.pois.filter((item) => {
            return item.poi.is_available && isProductPackaged({
                product: item.poi,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.poi,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.poi.is_optional &&
            !item.poi.provider_refused;
        });
    }, [
        trip,
        products?.pois,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesCars = useMemo(() => {
        return products?.cars.filter((item) => {
            return item.car.is_available && isProductPackaged({
                product: item.car,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.car,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.car.is_optional &&
            !item.car.provider_refused;
        });
    }, [
        trip,
        products?.cars,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesFlightsProducts = useMemo(() => {
        return products?.flights.filter((item) => {
            return isProductPackaged({
                product: item.flight,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.flight,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.flight.is_optional &&
            !item.flight.provider_refused;
        });
    }, [
        trip,
        products?.cars,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesManualProducts = useMemo(() => {
        return products?.manualProducts.filter((item) => {
            return isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
            !item.is_optional &&
            !item.provider_refused;
        });
    }, [
        trip,
        products?.manualProducts,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);

    return useMemo(() => {
        let total_cost: Costs = [];
        
        if ((pricesFlight?.length ?? 0) > 0) {
            let price = getPrice(pricesFlight!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost + (trip?.flight_taxes !== null ? parseFloat(trip?.flight_taxes ?? '0') : 0);
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost + (trip?.flight_taxes !== null ? parseFloat(trip?.flight_taxes ?? '0') : 0),
                    currency: price.currency
                });
            }
        }
        if ((pricesTerrestrial?.length ?? 0) > 0) {
            let price = getPrice(pricesTerrestrial!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }
        if ((pricesAccommodations?.length ?? 0) > 0) {
            for (const item of pricesAccommodations ?? []) {
                if (item.type === 'normal') {
                    for (const room of item.accommodation.rooms) {
                        let price = getPrice(room.prices);
                        let matched = false;
                        total_cost.map(currency_cost => {
                            if (currency_cost.currency?.id === price.currency?.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                            }
                        });
                        if (!matched) {
                            total_cost.push({
                                cost: price.cost,
                                currency: price.currency
                            });
                        }
                    }
                } else {
                    let price = getPrice(item.accommodation.prices);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency?.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        }
        if ((pricesTransfers?.length ?? 0) > 0) {
            for (const item of pricesTransfers ?? []) {
                let price = getPrice(item.transfer.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
        if ((pricesPois?.length ?? 0) > 0) {
            for (const item of pricesPois ?? []) {
                let price = getPrice(item.poi.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
        if ((pricesCars?.length ?? 0) > 0) {
            for (const item of pricesCars ?? []) {
                let price = getPrice(item.car.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
        if ((pricesFlightsProducts?.length ?? 0) > 0) {
            for (const item of pricesFlightsProducts ?? []) {
                let price = item.type === 'normal' ?
                    getFlightPrice(item.flight) :
                    getPrice(item.flight.prices);
                const priceItem = isArray(price) ?
                    price[0] :
                    price;
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === priceItem?.currency?.id) {
                        matched = true;
                        currency_cost.cost += priceItem?.cost ?? 0;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: priceItem?.cost ?? 0,
                        currency: priceItem?.currency
                    });
                }
            }
        }
        if ((pricesManualProducts?.length ?? 0) > 0) {
            for (const item of pricesManualProducts ?? []) {
                let price = getPrice(item.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
        if (((pricesTerrestrial?.length ?? 0) === 0 && (pricesFlight?.length ?? 0) === 0) && (trip?.prices_stack_product?.length ?? 0) > 0) {
            let price = getPrice(trip?.prices_stack_product!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }

        return total_cost;
    }, [
        trip,
        language,
        getPrice,
        getFlightPrice,
        packagedProducts,
        products,
        pricesFlight,
        pricesFlightsProducts,
        pricesTerrestrial,
        pricesAccommodations,
        pricesTransfers,
        pricesPois,
        pricesCars,
        pricesManualProducts
    ]);
}
