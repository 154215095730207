import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    Close,
    DateRangeOutlined,
    ErrorOutlineOutlined,
    GroupAddOutlined,
    NavigateBefore,
    PlaceOutlined,
    TransferWithinAStationOutlined,
    WorkOutlineOutlined
} from "@mui/icons-material";
import { CartProductCardProviderLogo } from "../../CartMaterial/CartProductCardProviderLogo";
import { CartProductIconText } from "../../CartMaterial/CartProductIconText";

import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { AppState } from "../../../Reducers/Reducers";
import { TransferCard } from "./TransferCard";
import { Transfer } from "../objects/Transfer";
import { useSelector } from "react-redux";

type Props = {
    card: Transfer,
    search: any,
    groups: any,
    router: any
};

type CancellationType = {
    amount: string,
    currency: number,
    from_when: string
}

export function RenderTransfer(props: Props): JSX.Element | null {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const currency_list = useSelector((store:AppState) => store.base.currency_list);

    const getPrice = useGetPrice();
    const totalCost = getPrice(props.card.price);
    const [cancellationDialog, setCancellationDialog] = useState(false);

    const openCancellationDialog = () => {
        setCancellationDialog(!cancellationDialog);
    };

    return (
        <>
            <TransferCard
                type="transfert"
                item={props.card}
                mainContent={
                    <Stack>
                        <CartProductIconText
                            text={
                                t("cars.categories." + ((props.card.type).toLowerCase()).replaceAll(" ", "_"))
                            }
                            size="medium"
                            fontWeight="bold"
                            startIcon={TransferWithinAStationOutlined}
                        />
                        <CartProductIconText
                            text={
                                t("global.caracteristics") + " : " + props.card.name
                            }
                            size="medium"
                        />
                        {
                            props.card.pickup_location !== '' &&
                            <CartProductIconText
                                text={
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <span>
                                            {
                                                props.card.pickup_location
                                            }
                                        </span>
                                        {
                                            props.card.dropoff_location !== '' &&
                                            <ArrowRightAltOutlined />
                                        }
                                        <span>
                                            {
                                                props.card.dropoff_location
                                            }
                                        </span>
                                    </Stack>
                                }
                                startIcon={PlaceOutlined}
                            />
                        }
                        {
                            props.card.pickup_time !== null &&
                            <CartProductIconText
                                text={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <div>
                                            {
                                                window.moment.utc(props.card.pickup_time).format('LLL')
                                            }
                                        </div>
                                    </Stack>
                                }
                                startIcon={DateRangeOutlined}
                            />
                        }
                        {/* <Stack
                            direction={{ md: 'row' }}
                            spacing={{ md: 2 }}
                        >
                            <CartProductIconText
                                text={
                                    props.card.max_capacity
                                }
                                startIcon={PeopleOutlineOutlined}
                            />
                        </Stack> */}
                        <Stack direction="row" spacing={1}>
                            {
                                props.card.max_capacity && 
                                <CartProductIconText
                                    text={
                                        <>
                                            {
                                                props.card.max_capacity
                                            }
                                        </>
                                    }
                                    startIcon={GroupAddOutlined}
                                />
                            }
                            {
                                props.card.baggage !== null &&
                                <CartProductIconText
                                    text={ props.card.baggage }
                                    startIcon={WorkOutlineOutlined}
                                />
                            }
                        </Stack>
                        {
                            props.card.cancellation !== undefined && props.card.cancellation !== null && props.card.cancellation.length !== 0 && (
                                <Stack>
                                    <CartProductIconText
                                        text={ t("global.cancelable") }
                                        startIcon={ErrorOutlineOutlined}
                                        onClick={openCancellationDialog}
                                    />
                                </Stack>
                            )
                        }
                        
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t('cart-material.cart-construction-amount')}
                        </Typography>
                        <Typography fontWeight="bold" textAlign="center">
                            {
                                new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency',
                                        currency: totalCost.currency?.iso_code ?? 'EUR'
                                    }
                                ).format(totalCost.cost)
                            }
                        </Typography>
                        <CartProductCardProviderLogo
                            logoUrl={
                                props.card.provider_logo
                            }
                            name={props.card.provider_name}
                        />
                    </Stack>
                }
                // footer={
                //     <Stack direction="row" alignItems="center" spacing={2}>
                        
                //     </Stack>
                // }
                picture={props.card.pict_url}
                search={props.search}
                group={props.groups[0]}
                card={props.card}
                router={props.router}
                showEntirePictures
            >
                <Stack>
                    <div>{`${t('accommodation.description')} :`}</div>
                    <div dangerouslySetInnerHTML={{ __html: props.card.description }}/>
                </Stack>
                {/* <CartTransferCardDetails transfer={props.card} /> */}
            </TransferCard>
            <Dialog fullScreen={fullScreen} fullWidth maxWidth={ 'md' } open={cancellationDialog} onClose={openCancellationDialog}>
                <DialogTitle>
                    <Grid container justifyContent={ 'space-between' } alignItems={ 'center' }>
                        {
                            fullScreen ? (
                                <Grid item style={{fontWeight: 'bold'}}>
                                    <IconButton edge={ 'start' } onClick={openCancellationDialog}><NavigateBefore/></IconButton> { t('transfers.cancellation_title') }
                                </Grid>
                            ) : (
                                <>
                                    <Grid item style={{ fontWeight: 'bold', width: '90%' }}>
                                        { t('transfers.cancellation_title') + ' :' }
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={openCancellationDialog}><Close/></IconButton>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        (props.card.cancellation !== undefined && props.card.cancellation !== null) && (
                            <Stack>
                                {
                                    props.card.cancellation.map((cancellation:CancellationType, index) => {
                                        let limit_date = window.moment(cancellation.from_when).format('L');
                                        let currency = currency_list.find((currency) => {
                                            return currency.id === cancellation.currency;
                                        });
                                        return <span key={ index }>{ t('transfers.cancellation') } { limit_date } : { cancellation.amount } { currency !== undefined && currency !== null ? currency.symbol : '' }</span>;
                                    })
                                }
                            </Stack>
                        )
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}

