import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { Close } from "@mui/icons-material";
import GoogleMapsLoader from "google-maps";
import axios, { CancelToken } from "axios";
import { isNumber } from "lodash";
import { LoadingBackDrop } from "../../Common/LoadingBackdrop";
import { useRoadbook } from "./utils/roadbook";
import { useUploadRoadbook } from "./utils/uploadRoadbook";
import template from "./utils/handlebars/roadbook.handlebars";
import { AppState, Key } from "../../../Reducers/Reducers";

type Props = {
    onClose: () => void
}

export function TripListRoadbookModal(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? null;
    });
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const contactTravelerId = useSelector((state: AppState) => state.trip.contact_traveler);
    const itineraryContentLoading = useSelector((state: AppState) => state.cartConstruction.fetchingTexts);
    const travelers = useSelector((state: AppState) => state.trip.travelers) ?? [];
    const [preview, setPreview] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [traveler, setTraveler] = useState<number | null>(null);
    const [format, setFormat] = useState<'A4' | 'A5'>('A5');
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
    const generateRoadbook = useRoadbook();
    const uploadRoadbook = useUploadRoadbook();

    const onGenerate = async () => {
        const assignedTraveler = travelers.find((item) => {
            return item.id === traveler;
        });
        if (assignedTraveler) {
            try {
                setGenerating(true);
                const roadbook = await generateRoadbook(assignedTraveler, format);
                if (roadbook) {
                    const content = await makeRequest({
                        test: false,
                        apiKey: 'a0Ahb9NO49IOnaQJH9Ir',
                        content: template(roadbook)
                    });
                    await uploadRoadbook(content);
                    props.onClose();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setGenerating(false);
            }
        }
    };

    //initialize map
    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
        const key = config.keys?.find((item) => item.identifier === 'google_api');

        if (key) {
            GoogleMapsLoader.KEY = key.value;
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
            GoogleMapsLoader.LANGUAGE = "fr";
            GoogleMapsLoader.VERSION = '3.50';
            GoogleMapsLoader.load(() => {
                setIsGoogleMapsLoaded(true);
            });
        }
    }, []);

    useEffect(() => {
        const assignedTraveler = travelers.find((item) => {
            return item.id === traveler;
        });
        if (
            isGoogleMapsLoaded &&
            tripVersion?.destination &&
            tripStartDate &&
            assignedTraveler &&
            !itineraryContentLoading
        ) {
            const cancelToken = axios.CancelToken.source();
            (async () => {
                setLoading(true);
                try {
                    const roadbook = await generateRoadbook(assignedTraveler, format);
                    if (roadbook) {
                        const html = template(roadbook);
                        const content = await makeRequest({
                            test: true,
                            apiKey: 'a0Ahb9NO49IOnaQJH9Ir',
                            cancelToken: cancelToken.token,
                            content: html
                        });
                        const url = URL.createObjectURL(content);
                        setPreview(url);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })();
            return () => {
                cancelToken.cancel();
            };
        }
    }, [
        isGoogleMapsLoaded,
        itineraryContentLoading,
        tripStartDate,
        trip,
        tripVersion,
        travelers,
        traveler,
        locale,
        format
    ]);

    useEffect(() => {
        return () => {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    useEffect(() => {
        if (contactTravelerId) {
            setTraveler(contactTravelerId);
        }
    }, [contactTravelerId]);

    return (
        <>
            <Dialog
                open
                onClose={props.onClose}
                maxWidth={preview ? 'lg' : 'md'}
                scroll="body"
                fullWidth
            >
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                {t<string>('roadbook.generate-roadbook-title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            <Stack spacing={1.5}>
                                <FormControl sx={{ minWidth: 250 }} size="small">
                                    <InputLabel>
                                        {t<string>('roadbook.traveler-selector-label')}
                                    </InputLabel>
                                    <Select
                                        label={t<string>('roadbook.traveler-selector-label')}
                                        value={traveler ?? 'none'}
                                        onChange={(event) => setTraveler(isNumber(event.target.value) ? event.target.value : null)}
                                    >
                                        {
                                            travelers.map((traveler) => (
                                                <MenuItem key={traveler.id} value={traveler.id}>
                                                    {traveler.last_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 250 }} size="small">
                                    <InputLabel>
                                        {t<string>('roadbook.format')}
                                    </InputLabel>
                                    <Select
                                        label={t<string>('roadbook.format')}
                                        value={format}
                                        onChange={(event) => setFormat(event.target.value as typeof format)}
                                    >
                                        <MenuItem value="A4">
                                            A4
                                        </MenuItem>
                                        <MenuItem value="A5">
                                            A5
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <IconButton onClick={props.onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {
                        loading &&
                        <Stack alignItems="center" spacing={1}>
                            <Typography variant="caption" textAlign="center">
                                {t<string>('roadbook.loading-preview')}
                            </Typography>
                            <CircularProgress />
                        </Stack>
                    }
                    {
                        !loading && preview &&
                        <PdfViewer src={preview} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t<string>('global.cancel')}
                    </Button>
                    <Button onClick={onGenerate} disabled={generating}>
                        {t<string>('roadbook.generate-pdf')}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={generating || itineraryContentLoading} />
        </>
    );
}

const PdfViewer = styled('embed')(() => ({
    width: '100%',
    height: '65vh'
}));

type RequestOptions = {
    cancelToken?: CancelToken,
    apiKey: string,
    content: string,
    test: boolean
}

async function makeRequest(options: RequestOptions): Promise<Blob> {
    const response = await axios.post<Blob>(
        `https://docraptor.com/docs`,
        {
            user_credentials: options.apiKey,
            doc: {
                test: options.test,
                type: 'pdf',
                pipeline: '9.2',
                document_content: options.content,
                javascript: true,
                ignore_console_messages: false,
                prince_options: {
                    css_dpi: 135,
                    baseurl: 'https://cercledesvoyages.facilitatrip.com',
                }
            }
        },
        {
            responseType: 'blob',
            cancelToken: options.cancelToken
        }
    );
    return response.data;
}
