import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Collapse,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {
    ExpandLess,
    ExpandMore,
    FlightOutlined,
    Work
} from "@mui/icons-material";
import { uniq } from "lodash";
import { AppState } from "../../../Reducers/Reducers";
import { Flight } from '../../Itinerary/network/flight';
import { FlightSegmentInfoPerPax } from "./FlightSegmentInfoPerPax";
import { FlightLegDetail } from "./FlightLegDetail";
import ConvertTime from "../../FlightMaterial/Functions/ConvertTime";


type FlightOutboundItemProps = {
    no: number,
    flight: Flight,
    flight_index: number,
    outbound: Flight['outbounds'][number],
    searched_group: any
}

const FlightOutboundItem = React.memo(function (props: FlightOutboundItemProps): JSX.Element {
    const { no, flight, outbound, searched_group, flight_index } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.user);
    const airlines = useSelector((state: AppState) => state.flight_search.airlines);
    const airports = useSelector((state: AppState) => state.flight_search.airports);
    const stations = useSelector((state: AppState) => state.flight_search.stations);
    const language = i18n.language;
    const [openDetails, setOpenDetails] = useState(false);
    const lastIndex = outbound.legs.length - 1;
    const outboundDatesDiff = window.moment.utc(outbound.legs[lastIndex]?.arrival_datetime_lt).startOf('day').diff(
        window.moment.utc(outbound.legs[0]?.departure_datetime_lt).startOf('day'),
        'days'
    );
    const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;
    const handleOpenDetail = () => {
        dispatch({
            type: 'FLIGHT_TOGGLE_DETAIL', payload: {
                flight_index: flight.id,
                outbound_index: no - 1,
                detail: !outbound.detail
            }
        });
    };
    let temp_carry_on = 0;
    let temp_check = 0;
    let paid_carry_on = 0;
    let paid_check = 0;
    let paid_options = {};
    if (outbound.legs[0]?.baggage) {
        outbound.legs[0].baggage.map((luggage) => {
            if (luggage.is_carry_on) {
                temp_carry_on += luggage.quantity;
            } else {
                temp_check += luggage.quantity;
            }
        });
    } else {
        temp_check = outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0;
    }

    const totalLuggages = temp_check + temp_carry_on + paid_check + paid_carry_on;
    const airlineObj = airlines.find(item => item.id === outbound.legs[0]?.marketing_airline);
    let stopover_iata = [];
    for (let i = 1; i < outbound.legs.length; i++) {
        if (outbound.legs[i]?.origin_airport) {
            stopover_iata.push(airports[outbound.legs[i]?.origin_airport]?.code);
        } else {
            stopover_iata.push(stations[outbound.legs[i]?.origin_station]?.code);
        }
    }
    return (
        <>
            <Box
                onClick={handleOpenDetail}
                style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    verticalAlign: 'bottom'
                }}
                component="tr"
            >
                <td>
                    <Stack direction="row" alignItems="flex-end" spacing={1}>
                        <Box
                            src={
                                `https://storagefacilitatrip.blob.core.windows.net/iata-company/${airlineObj?.code}.png`
                            }
                            component="img"
                            style={{
                                width: 100,
                                height: 30,
                                maxWidth: 100,
                                maxHeight: 30,
                                objectFit: 'contain'
                            }}
                        />
                        <Typography>
                            {t<string>('global.flight')} {no}
                        </Typography>
                    </Stack>
                </td>
                <td>
                    <Typography>
                        {window.moment.utc(outbound.legs[0]?.departure_datetime_lt).format('LL')}
                    </Typography>
                </td>
                <td>
                    <Typography>
                        {
                            outbound.legs[0]?.origin_airport ?
                                `${airports[outbound.legs[0].origin_airport].iata_city.name !== undefined ? airports[outbound.legs[0].origin_airport].iata_city.name : airports[outbound.legs[0].origin_airport].iata_city.international_name} (${airports[outbound.legs[0].origin_airport]?.code})` :
                                `${stations[outbound.legs[0]?.origin_station].iata_city?.name !== undefined ? stations[outbound.legs[0]?.origin_station].iata_city?.name : stations[outbound.legs[0]?.origin_station].iata_city?.international_name}`
                        }
                    </Typography>
                </td>
                <Box
                    style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        textAlign: 'center'
                    }}
                    component="td"
                >
                    <FlightOutlined
                        style={{
                            transform: 'rotateZ(90deg)',
                            verticalAlign: 'bottom'
                        }}
                    />
                </Box>
                <td>
                    <Typography>
                        {
                            outbound.legs[lastIndex]?.destination_airport ?
                                `${airports[outbound.legs[lastIndex]?.destination_airport]?.iata_city.name !== undefined ? airports[outbound.legs[lastIndex]?.destination_airport]?.iata_city.name : airports[outbound.legs[lastIndex]?.destination_airport]?.iata_city.international_name} (${airports[outbound.legs[lastIndex]?.destination_airport]?.code})` :
                                `${stations[outbound.legs[lastIndex]?.destination_station]?.iata_city?.name !== undefined ? stations[outbound.legs[lastIndex]?.destination_station]?.iata_city?.name : stations[outbound.legs[lastIndex]?.destination_station]?.iata_city?.international_name}`
                        }
                        {
                            outboundDatesDiff > 0 &&
                            <Box
                                style={{
                                    fontSize: 10,
                                    verticalAlign: 'top',
                                    marginLeft: 0.5
                                }}
                                component="span"
                            >
                                {' '}
                                <Box style={{ color: 'red' }} component="span">
                                    {"+" + outboundDatesDiff + t<string>("global.abrev_day")}
                                </Box>
                                {' '}
                                <Box style={{ opacity: 0.65 }} component="span">
                                    ({window.moment.utc(outbound.legs[lastIndex]?.arrival_datetime_lt).format('DD MMM')})
                                </Box>
                            </Box>
                        }
                    </Typography>
                </td>
                <td>
                    <Typography>
                        {window.moment.utc(outbound.legs[lastIndex]?.arrival_datetime_lt).format('LL')}
                    </Typography>
                </td>
                <td>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <Box
                            style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Work
                                style={{
                                    color: (outbound.legs[0]?.baggage?.length ?? 0) === 0 && totalLuggages === 0 ?
                                        'gray' :
                                        `${main_color}`,
                                    fontSize: '1.5rem'
                                }}
                            />
                            <Typography
                                style={{
                                    position: 'absolute',
                                    top: 'calc(45% + 4px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '0.75em',
                                    lineHeight: 1
                                }}
                            >
                                {
                                    temp_carry_on + temp_check + paid_check + paid_carry_on
                                }
                            </Typography>
                        </Box>
                        <Typography textAlign="center">
                            {
                                outbound.legs.length > 1 &&
                                    uniq(outbound.legs.map((item) => item.cabin_class)).length === 1 ?
                                    t(`flight_search.cabin_class.${outbound.legs[0]!.cabin_class?.replaceAll(' ', '_').toLowerCase()}`) :
                                    outbound.legs.map((item) => {
                                        return t(`flight_search.cabin_class.${item.cabin_class?.replaceAll(' ', '_').toLowerCase()}`)
                                    }).join(' - ')
                            }
                        </Typography>
                    </Stack>
                </td>
                <Box
                    style={{
                        paddingLeft: 2,
                        textAlign: 'center'
                    }}
                    component="td"
                >
                    {
                        outbound.detail ?
                            <ExpandLess style={{ verticalAlign: 'middle' }} /> :
                            <ExpandMore style={{ verticalAlign: 'middle' }} />
                    }
                </Box>
            </Box>
            <Box
                onClick={handleOpenDetail}
                style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    lineHeight: 1
                }}
                component="tr"
            >
                <td />
                <td>
                    <Typography variant="caption">
                        (
                        {
                            outbound.legs.map((leg) => {
                                let marketing_airline = airlines.find(item => item.id === leg.marketing_airline);
                                return `${marketing_airline?.code}-${leg.marketing_flight_number !== undefined && leg.marketing_airline !== null ? leg.marketing_flight_number : leg.flight_number}`;
                            }).join(', ')
                        }
                        )
                    </Typography>
                </td>
                <td>
                    <Typography variant="caption">
                        {window.moment.utc(outbound.legs[0]?.departure_datetime_lt).format('HH:mm')}
                    </Typography>
                </td>
                <td>
                    {
                        (() => {
                            const aircrafts = uniq(
                                outbound.legs.map((item) => {
                                    return item.aircraft?.commercial_name;
                                }).filter((item): item is NonNullable<typeof item> => !!item)
                            );
                            if (aircrafts.length > 0) {
                                return (
                                    <>
                                        <Typography variant="caption">
                                            ({aircrafts.join(', ')})
                                        </Typography>
                                        <br />
                                    </>
                                );
                            }
                        })()
                    }
                    <Typography variant="caption">
                        {ConvertTime(outbound.flight_duration, "seconds", language, t)}
                    </Typography>
                </td>
                <td>
                    <Typography variant="caption">
                        {window.moment.utc(outbound.legs[lastIndex]?.arrival_datetime_lt).format('HH:mm')}
                    </Typography>
                </td>
                <td>
                    {
                        outbound.legs.length === 1 ?
                            <Typography style={{ textTransform: 'capitalize' }} variant="caption">
                                {t<string>("flight_search.direct_flight")}
                            </Typography> :
                            <Typography variant="caption">
                                {
                                    outbound.legs.length === 2 ?
                                        `1 ${t<string>('flight_search.stopover')}` :
                                        `${outbound.legs.length - 1} ${t<string>('flight_search.stopovers')}`
                                }
                                {
                                    outbound.legs.length > 1 &&
                                    ` (${stopover_iata.join(', ')})`
                                }
                            </Typography>
                    }
                </td>
                <td>
                    {
                        user?.client_full?.type !== 2 &&
                        <Typography variant="caption" style={{ paddingLeft: '32px' }}>
                            {
                                outbound.legs.length > 1 &&
                                    uniq(outbound.legs.map((item) => item.class_of_service ?? item.booking_code)).length === 1 ?
                                    outbound.legs[0]!.class_of_service ?? outbound.legs[0]!.booking_code :
                                    outbound.legs.map((item) => {
                                        return item.class_of_service ?? item.booking_code;
                                    }).join(' - ')
                            }
                        </Typography>
                    }
                </td>
                <td />
            </Box>
            <Box component="tr" className="flight-segment-details">
                <td colSpan={12}>
                    <Collapse in={outbound.detail} onClick={() => dispatch({ type: 'FLIGHT_SET_DETAILED_INDEX', payload: flight.id })} style={{ transition: "none" }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1.5}
                            style={{
                                marginTop: 1,
                                marginBottom: 1
                            }}
                        >
                            <Grid style={{ flex: 1.5 }}>
                                {
                                    outbound.legs.map((leg, index, array) => (
                                        <FlightLegDetail
                                            key={index}
                                            leg={leg}
                                            leg_index={index}
                                            max_index={array.length - 1}
                                            stopover_duration={
                                                index < outbound.stopover_duration.length ?
                                                    outbound.stopover_duration[index] ?? null :
                                                    null
                                            }
                                            duration_days={outbound.duration_days}
                                            small
                                        />
                                    ))
                                }
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <FlightSegmentInfoPerPax
                                flight={flight}
                                outbound={outbound}
                                searched_group={searched_group}
                                flight_index={flight_index}
                                outbound_index={no - 1}
                            />
                        </Stack>
                    </Collapse>
                </td>
            </Box>
        </>
    );
})
export default FlightOutboundItem