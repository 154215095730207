import React, {Fragment, useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Checkbox from '@material-ui/core/Checkbox';

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Alert from '@material-ui/lab/Alert';

import { useGetTaxesPrice } from "../CartMaterial/utils/getTaxesPrice";
import RenderSummaryFlight from "./RenderProducts/RenderSummaryFlight";

import SummaryMoreInfo from "./RenderProducts/SummaryMoreInfo";
import CheckProducts from "./Functions/CheckProducts";
import GetOutOfCart from "./Functions/GetOutOfCart";
import GetTotalPrices from "./Functions/GetTotalPrices";
import RenderProductsStep from "./RenderProductsStep";
import RenderProductsDay from "./RenderProductsDay";
import RenderOutOfCartProducts from "./RenderOutOfCartProducts";

import { setTotalPrice } from "../../Actions/Summary";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    bold: {
        fontWeight: "bold"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    buttonContainer: {
        minWidth: 150,
        margin: '0 15px 15px'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
}));

const RenderAllProduct = React.forwardRef((prop, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const from = prop.from;
    const dayByDay = prop.dayByDay;
    const selectedProducts = prop.selectedProducts;
    const setSelectedProducts = prop.setSelectedProducts;

    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(store => store.summary.manual_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
    const itinerary = useSelector(store => store.summary.itinerary);
    const trip_info = useSelector(store => store.summary.trip_info);
    const out_of_cart = useSelector(store => store.summary.out_of_cart);
    const getTaxesPrice = useGetTaxesPrice();

    // const [flightSelected, setFlightSelected] = useState(null);
    // const [selectedProducts, setSelectedProducts] = useState({
    //     mainFlights: [],
    //     hotels: [],
    //     cars: [],
    //     transfers: [],
    //     poi: [],
    //     step_flight: []
    // });

    // useEffect(() => {
        // let isEmpty = true;
        // Object.keys(selectedProducts).map((key) => {
        //     if (selectedProducts[key].length !== 0) {
        //         isEmpty = false;
        //     }
        // });
        // if (isEmpty) {
            // let tmp_selected_product = CheckProducts(itinerary, flight_cart, hotel_cart, transfers_cart, cars_cart, poi_cart, manual_cart);
            // setSelectedProducts(Object.assign({}, tmp_selected_product));
        // }
    // }, [])
    useEffect(() => {
        let tmp_flights = [];
        let tmp_hotels = [];
        let tmp_poi = [];
        let tmp_cars = [];
        let tmp_manual = [];
        let tmp_transfers = [];
        let hidden_product = [];
        let out_of_cart = GetOutOfCart(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart);
        Object.keys(selectedProducts).map((key) =>{
            selectedProducts[key].map((product) => {
                product.map((id) => {
                    flight_cart.map((flight) => {
                        if (flight.id === id) {
                            tmp_flights.push(flight);
                        }
                    });
                    hotel_cart.map((hotel) => {
                        if (hotel.id === id) {
                            tmp_hotels.push(hotel);
                        }
                    });
                    poi_cart.map((poi) => {
                        if (poi.id === id) {
                            tmp_poi.push(poi);
                        }
                    });
                    cars_cart.map((car) => {
                        if (car.id === id) {
                            tmp_cars.push(car);
                        }
                    });
                    manual_cart.map((manual) => {
                        if (manual.id === id) {
                            tmp_manual.push(manual);
                        }
                    });
                    transfers_cart.map((tranfer) => {
                        if (tranfer.id === id) {
                            tmp_transfers.push(tranfer);
                        }
                    });
                });
            });
        });
        // let total_price = GetTotalPrices([...tmp_flights], [...tmp_hotels], [...tmp_manual], [...tmp_poi], [...tmp_cars], [...tmp_transfers], trip_info, []);
        // console.log('tmp_flights 2 : ', tmp_flights);
        // console.log('tmp_hotels 2 : ', tmp_hotels);
        // console.log('tmp_manual 2 : ', tmp_manual);
        // console.log('tmp_poi 2 : ', tmp_poi);
        // console.log('tmp_cars 2 : ', tmp_cars);
        // console.log('tmp_transfers 2 : ', tmp_transfers);
        // console.log('total_price 2 : ', total_price);
        tmp_flights = tmp_flights.concat(out_of_cart.flights);
        tmp_hotels = tmp_hotels.concat(out_of_cart.hotels);
        tmp_manual = tmp_manual.concat(out_of_cart.manuals);
        tmp_poi = tmp_poi.concat(out_of_cart.poi);
        tmp_cars = tmp_cars.concat(out_of_cart.cars);
        tmp_transfers = tmp_transfers.concat(out_of_cart.transfers);
        hidden_product = out_of_cart.hidden_product;
        tmp_flights = tmp_flights.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        tmp_hotels = tmp_hotels.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        tmp_manual = tmp_manual.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        tmp_poi = tmp_poi.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        tmp_cars = tmp_cars.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        tmp_transfers = tmp_transfers.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        hidden_product = hidden_product.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        let total_price = GetTotalPrices(tmp_flights, tmp_hotels, tmp_manual, tmp_poi, tmp_cars, tmp_transfers, trip_info, hidden_product, getTaxesPrice);
        dispatch(setTotalPrice(total_price, out_of_cart));
    }, [selectedProducts, getTaxesPrice]);
    return (
        <Grid container direction={"column"} spacing={fullScreen ? 2 : 4} >
            {
                trip_info.status === "USER_VALID" && from !== "tripSummary" && (
                    <Grid item>
                        <Alert severity={"warning"} className={`${classes.bold} ${classes.genericText}`}>{t("summary.contact_agency")}</Alert>
                    </Grid>
                )
            }
            <RenderSummaryFlight flight_cart={flight_cart} manual_cart={manual_cart} type={"departure"} start_date={trip_info.start_date} end_date={trip_info.end_date} position={"top"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={0} from={from}/>
            {
                (!dayByDay || from !== "tripSummary") && (
                    <RenderProductsStep ref={ref} from={from} dayByDay={dayByDay} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts}/>
                )
            }
            {
                dayByDay && (
                    <RenderProductsDay ref={ref} from={from} dayByDay={dayByDay} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts}/>
                )
            }
            {
                (out_of_cart.cars.length > 0 || out_of_cart.flights.length > 0 || out_of_cart.hotels.length > 0 || out_of_cart.manuals.length > 0 || out_of_cart.poi.length > 0 || out_of_cart.transfers.length > 0) && (
                    <RenderOutOfCartProducts from={from}/>
                )
            }
            <RenderSummaryFlight flight_cart={flight_cart} manual_cart={manual_cart} type={"return"} start_date={trip_info.start_date} end_date={itinerary[itinerary.length - 2].end_date} position={"bottom"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={0} from={from}/>
            <SummaryMoreInfo from={from}/>
        </Grid>
    )
})
export default RenderAllProduct;