import { Itinerary } from "../objects/itinerary";
import { ItineraryInput } from "../objects/itineraryState";

export function itineraryToItineraryInput(locale: number, item: Itinerary): ItineraryInput {
    return {
        ...item,
        destination: item.destination?.data ?
            {
                destination_id: item.destination.id,
                international_name: item.destination.data.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                item.destination.data.international_name ??
                '',
                suggested_hours: item.destination.data.suggested_hours,
                cover_pic__url: item.destination.data.cover_pic?.thumbnail_big ??
                                item.destination.data.cover_pic?.thumbnail_medium ??
                                item.destination.data.cover_pic?.thumbnail_little ??
                                item.destination.data.cover_pic?.url ??
                                item.destination.data.pictures?.[0]?.thumbnail_big ??
                                item.destination.data.pictures?.[0]?.thumbnail_medium ??
                                item.destination.data.pictures?.[0]?.thumbnail_little ??
                                item.destination.data.pictures?.[0]?.url ??
                                ''
            } :
            null
    };
}
