import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from "@material-ui/core/styles";
import {
    InputAdornment,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Button,
    ClickAwayListener
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TripListInvoiceSidePanel } from "./TripListInvoiceSidePanel";
import { TripListInvoiceCancelModal } from "./TripListInvoiceCancelModal";
import { LoadingBackDrop } from "../../Common/LoadingBackdrop";
import { recreateInputsFromInvoice } from "./Functions/recreateInputsFromInvoice";
import { previewBlob } from "./Functions/previewBlob";
import { downloadBlob } from "./Functions/downloadBlob";
import { safeStringToNumber } from "./Functions/safeStringToNumber";
import { useSaveInvoice } from "./Functions/useSaveInvoice";
import { useGenerateInvoice } from "./Functions/useGenerateInvoice";
import { useAwsFileDownload } from "./Functions/useAwsFileDownload";
import { useSendInvoice } from "./Functions/useSendInvoice";
import { useCartTotalPrices } from "../../../Components/CartMaterial/utils/cartTotalPrices";
import Add from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SaveAltOutlined from '@material-ui/icons/SaveAltOutlined';
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import { Checkbox, FormControl } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isAfter } from 'date-fns';
import axios from 'axios';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';

export function TripListInvoiceDetails(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const owner = useSelector((store) => store.trip.all_data && store.trip.all_data.owner);
    const client = useSelector((store) => store.trip.data_trip && store.trip.data_trip.contact_traveler_detail);
    const startDate = useSelector((store) => store.trip.data_trip && store.trip.data_trip.start_date);
    const endDate = useSelector((store) => store.trip.data_trip && store.trip.data_trip.end_date);
    const destination = useSelector((store) => store.trip.data_trip && store.trip.data_trip.destination);
    const tripName = useSelector((store) => store.trip.all_data && store.trip.all_data.name);
    const travelers = useSelector((store) => store.trip.data_trip && store.trip.data_trip.travelers);
    const currency = useSelector(state => state.trip.currency);
    const versions = useSelector((store) => store.trip.all_data && store.trip.all_data.data);
    const tripReference = useSelector((store) => store.trip.all_data && store.trip.all_data.trip_reference);
    const data_trip = useSelector(store => store.trip.data_trip);
    const invoices = useSelector(store => store.trip.invoices);
    const [detailsInputs, setDetailsInputs] = useState({ companyName: { data: '', edit: false } });
    const [productInputs, setProductInputs] = useState([]);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [updateAgreedPrice, setUpdateAgreedPrice] = useState(false);

    const totalWithoutTaxes = productInputs.reduce((prev, current) => {
        return prev + safeStringToNumber(current.totalWithoutTax);
    }, 0);
    const totalFlightTaxes = productInputs.reduce((prev, current) => {
        return prev + safeStringToNumber(current.flightTax);
    }, 0);
    const totalAmount = productInputs.reduce((prev, current) => {
        return prev + safeStringToNumber(current.totalWithoutTax) + safeStringToNumber(current.flightTax);
    }, 0);
    const invoiceType = props.invoice ? props.invoice.invoice_type : props.invoiceType;

    const save = useSaveInvoice({
        setLoading,
        invoiceType: props.invoiceType,
        onClose: props.onClose
    });
    const generate = useGenerateInvoice({
        setLoading,
        owner,
        client,
        invoiceType: props.invoiceType,
        startDate,
        tripReference,
        data_trip,
        versionNumber: versions !== null ? versions.map(version => version.id).indexOf(data_trip.id) + 1 : 0,
        endDate,
        destination,
        tripName,
        travelers,
        currency,
        onClose: props.onClose
    });
    const download = useAwsFileDownload({
        setLoading
    });
    const send = useSendInvoice({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(t('menu.invoice.invoice-sent'), { variant: 'success' });
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        onFinally() {
            setLoading(false);
        }
    });
    const prices = useCartTotalPrices();

    // const toggleUpdateAgreedPrice = () => {
    //     setUpdateAgreedPrice((prevState) => !prevState);
    // };

    const onAddInput = () => {
        setProductInputs((state) => state.concat([{
            id: Math.random().toString(),
            totalWithoutTax: 0,
            flightTax: 0
        }]));
    };

    const onDeleteInput = (id) => {
        setProductInputs((state) => state.filter((item) => item.id !== id));
    };

    const onChangeDetailsInput = (key, value) => {
        setDetailsInputs((state) => {
            const result = Object.assign({}, state);
            result[key].data = value;
            return result;
        });
    };

    const onToggleDetailsInput = (key) => {
        setDetailsInputs((state) => {
            const result = Object.assign({}, state);
            result[key].edit = !result[key].edit;
            return result;
        });
    };

    const onChangeProductInput = (id, key, value) => {
        setProductInputs((state) => {
            return state.map((item) => {
                if (item.id === id) {
                    const result = Object.assign({}, item);
                    result[key] = value;
                    return result;
                }
                return item;
            });
        });
    };

    const onSave = () => {
        save({
            invoice: props.invoice,
            detailsInputs,
            productInputs: productInputs.map((item) => {
                const result = Object.assign({}, item);
                result.totalWithoutTax = safeStringToNumber(item.totalWithoutTax).toString();
                result.flightTax = safeStringToNumber(item.flightTax).toString();
                return result;
            }),
            totalAmount
        });
    };

    const onGenerate = () => {
        if (invoiceType !== 'INVOICE' && isAfter(new Date(), new Date(data_trip.start_date)) && data_trip.status_modification === 'FIXED_PV') {
            const { headers } = CheckBeforeRequest();
            let amount = 0;
            productInputs.map((item) => {
                amount += parseFloat(item.totalWithoutTax) + parseFloat(item.flightTax);
            });
            axios.post(
                `${API_HREF}client/${window.id_owner}/trip/${data_trip.trip.id}/versions/${data_trip.id}/manual-products/`,
                {
                    name: t("header.adjustment_product_name"),
                    product_type: 20,
                    step: null,
                    provider_name: null,
                    provider_id: null,
                    start_date: null,
                    end_date: null,
                    address: null,
                    stars: null,
                    breakfast_included: false,
                    lunch_included: false,
                    diner_included: false,
                    rooms: null,
                    resort_fees: null,
                    resort_fees_currency: null,
                    flight_paste: null,
                    flight_segment: null,
                    cgos_reduction: false,
                    description: "",
                    custom_information: null,
                    group_passenger: undefined,
                    taxes: null,
                    nb_baggages: null,
                    picture_id: null,
                    prices: [{
                        purchase_price: 0,
                        purchase_currency: data_trip.trip.currency.id,
                        custom_rate: true,
                        custom_rate_type: 'FIX',
                        custom_value: amount
                    }]
                },
                {
                    headers: headers,
                }
            ).then((response) => {
                dispatch({ type: 'CART_ADD_MANUAL_ITEM', payload: response.data });
                axios.patch(
                    `${API_HREF}client/${window.id_owner}/trip/${data_trip.trip.id}/`,
                    {
                        agreed_price: prices.totalCartCost + amount,
                        agreed_margin: prices.margin.value + amount,
                    },
                    {
                        headers: headers,
                    }
                ).then((response) => {
                    dispatch({
                        type: "TRIP_SET_ALL_DATA",
                        payload: {
                            data: response.data
                        }
                    });
                    generate({
                        invoice: props.invoice,
                        detailsInputs,
                        productInputs: productInputs.map((item) => {
                            const result = Object.assign({}, item);
                            result.totalWithoutTax = safeStringToNumber(item.totalWithoutTax).toString();
                            result.flightTax = safeStringToNumber(item.flightTax).toString();
                            return result;
                        }),
                        totalWithoutTaxes,
                        totalFlightTaxes,
                        totalAmount
                    });
                });
            });
            // if (updateAgreedPrice) {
            //     axios.patch(
            //         `${API_HREF}client/${window.id_owner}/trip/${trip_id}/`,
            //         {
            //             agreed_price: prices.totalCartCost,
            //             agreed_margin: prices.margin.value,
            //         },
            //         {
            //             headers: headers,
            //         }
            //     ).then((response) => {
            //         dispatch({
            //             type: "TRIP_SET_ALL_DATA",
            //             payload: {
            //                 data: response.data
            //             }
            //         });
            //     });
            // } else {
            //
            // }
        } else {
            generate({
                invoice: props.invoice,
                detailsInputs,
                productInputs: productInputs.map((item) => {
                    const result = Object.assign({}, item);
                    result.totalWithoutTax = safeStringToNumber(item.totalWithoutTax).toString();
                    result.flightTax = safeStringToNumber(item.flightTax).toString();
                    return result;
                }),
                totalWithoutTaxes,
                totalFlightTaxes,
                totalAmount
            });
        }
    };

    const onOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const onCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const onExport = () => {
        if (props.invoice) {
            const date = window.moment.utc(props.invoice.accounting_date).format('DD-MM-YYYY');
            download(
                props.invoice.document,
                downloadBlob(
                    props.invoice.invoice_type === 'INVOICE' ?
                        `${t('menu.invoice.invoice')}-${date}` :
                        `${t('menu.invoice.credit-note')}-${date}`
                )
            );
        }
    };

    const onPrevisualizePdf = () => {
        if (props.invoice) {
            download(
                props.invoice.document,
                previewBlob
            );
        }
    };

    const onSendInvoice = () => {
        if (props.invoice) {
            const date = window.moment.utc(props.invoice.accounting_date).format('DD-MM-YYYY');
            const filename = props.invoice.invoice_type === 'INVOICE' ?
                `${t('menu.invoice.invoice')}-${date}` :
                `${t('menu.invoice.credit-note')}-${date}`;
            download(
                props.invoice.document,
                (blob) => {
                    //wait so backdrop is not closed instantly
                    setTimeout(() => send(props.invoice.id, filename, blob), 500);
                }
            );
        }
    };

    //recreate inputs
    useEffect(() => {
        if (props.invoice) {
            const [details, products] = recreateInputsFromInvoice(props.invoice);
            setDetailsInputs(details);
            setProductInputs(products);
        } else {
            switch (invoiceType) {
                case 'INVOICE': {
                    setProductInputs([
                        {
                            id: Math.random().toString(),
                            description: t('menu.invoice.trip-price-without-insurance'),
                            totalWithoutTax: (prices.totalCartCost - prices.flightTaxes - prices.totalInsurance).toFixed(2),
                            flightTax: prices.flightTaxes.toFixed(2)
                        },
                        {
                            id: Math.random().toString(),
                            description: 'Produit assurance',
                            totalWithoutTax: prices.totalInsurance.toFixed(2),
                            flightTax: 0
                        }
                    ]);
                    break;
                }
                case 'CREDIT_NOTE': {
                    if (invoices && invoices.length > 0) {
                        const filteredInvoices = invoices.filter((item) => item.invoice_type === 'INVOICE');
                        const lastId = filteredInvoices.map((item) => {
                            return item.id;
                        }).reduce(
                            (a, b) => Math.max(a, b),
                            -Infinity
                        );
                        const lastInvoice = filteredInvoices.find((item) => item.id === lastId);
                        if (lastInvoice) {
                            setProductInputs(lastInvoice.invoice_product.map((item) => ({
                                id: item.id,
                                description: item.name,
                                totalWithoutTax: item.total_without_tax * -1,
                                flightTax: item.flight_tax * -1
                            })));
                        }
                    }
                    break;
                }
            }
        }
    }, [invoiceType, props.invoice]);

    return (
        <div>
            <Title>{t('menu.invoice.informations')}</Title>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                    <ContentContainer>
                        <CloseButton
                            size="small"
                            onClick={
                                !props.invoice || props.invoice.is_generated === false ?
                                    onOpenCancelModal :
                                    props.onClose
                            }
                            startIcon={<ArrowBack />}
                        >
                            {t('menu.invoice.back-to-list')}
                        </CloseButton>
                        <EntitiesSection>
                            {
                                owner &&
                                <OwnerBlock>
                                    <OwnerLogo src={owner.logo.url} />
                                    <div>
                                        <OwnerBlockTitle variant="body1">
                                            {owner.name}
                                        </OwnerBlockTitle>
                                        <Typography variant="body2" color="textSecondary">
                                            {owner.street}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {owner.city} {owner.postal_code}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {owner.phone_number}
                                        </Typography>
                                    </div>
                                </OwnerBlock>
                            }
                            {
                                client &&
                                <ClientBlock>
                                    <ClientBlockTitle component="div">
                                        {
                                            detailsInputs.companyName.edit ?
                                                <ClickAwayListener onClickAway={() => onToggleDetailsInput('companyName')}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={detailsInputs.companyName.data}
                                                        onChange={(event) => onChangeDetailsInput('companyName', event.target.value)}
                                                    />
                                                </ClickAwayListener> :
                                                detailsInputs.companyName.data === '' ? t('menu.invoice.company') : detailsInputs.companyName.data
                                        }
                                        {
                                            (!props.invoice || props.invoice.is_generated === false) &&
                                            <IconButton size="small" onClick={() => onToggleDetailsInput('companyName')}>
                                                <Edit fontSize="small" />
                                            </IconButton>
                                        }
                                    </ClientBlockTitle>
                                    <Typography variant="body2" color="textSecondary">
                                        {client.title}{' '}{client.first_name}{' '}{client.last_name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {client.address}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        { client.city } { client.postal_code }
                                    </Typography>
                                    {
                                        client.country !== null &&
                                        <Typography variant="body2" color="textSecondary">
                                            { client.country.international_name }
                                        </Typography>
                                    }
                                </ClientBlock>
                            }
                        </EntitiesSection>
                        <InvoiceNumber variant="h6">
                            {
                                props.invoice && props.invoice.is_generated &&
                                (
                                    invoiceType === 'INVOICE' ?
                                        t('menu.invoice.invoice-number') + ' ' + props.invoice.invoice_number :
                                        t('menu.invoice.credit-note-number') + ' ' + props.invoice.credit_note_number
                                )
                            }
                            {
                                (!props.invoice || props.invoice.is_generated === false) &&
                                (
                                    invoiceType === 'INVOICE' ?
                                        t('menu.invoice.invoice-number-waiting') :
                                        t('menu.invoice.credit-note-number-waiting')
                                )
                            }
                        </InvoiceNumber>
                        {
                            startDate && tripName &&
                            <TripDetails container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <TripDetailTitle>
                                            {t('menu.invoice.departure-date')}
                                        </TripDetailTitle>: {window.moment.utc(startDate).format('DD/MM/YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <TripDetailTitle>
                                            {t('menu.invoice.invoice-date')}
                                        </TripDetailTitle>: {
                                            props.invoice && props.invoice.is_generated === true ?
                                                window.moment.utc(props.invoice.invoice_date).format('DD/MM/YYYY') :
                                                window.moment.utc(new Date().toString()).format('DD/MM/YYYY')
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <TripDetailTitle>
                                            {t('menu.invoice.arrival-date')}
                                        </TripDetailTitle>: {window.moment.utc(endDate).format('DD/MM/YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <TripDetailTitle>
                                            {t('menu.invoice.destination')}
                                        </TripDetailTitle>: {destination.data.international_name}
                                    </Typography>
                                </Grid>
                                {
                                    tripName &&
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <TripDetailTitle>
                                                {t('menu.invoice.trip-name')}
                                            </TripDetailTitle>: {tripName}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <TripDetailTitle>
                                            {t('menu.invoice.travelers-names')}
                                        </TripDetailTitle>: {
                                            travelers.map((traveler) => {
                                                return traveler.first_name + ' ' + traveler.last_name;
                                            }).join(', ')
                                        }
                                    </Typography>
                                </Grid>
                            </TripDetails>
                        }
                        <TableContainer elevation={0} component={Paper}>
                            <TableRoot>
                                <TableHead>
                                    <TableRow>
                                        {
                                            productInputs.length === 0 &&
                                            <TableHeadCell align="center">
                                                {t('menu.invoice.subject')}
                                            </TableHeadCell>
                                        }
                                        {
                                            productInputs.length > 0 &&
                                            <TableObjectHeadCell align="center">
                                                {t('menu.invoice.subject')}
                                            </TableObjectHeadCell>
                                        }
                                        <TableHeadCell align="center">
                                            {t('menu.invoice.total-without-taxes')}
                                        </TableHeadCell>
                                        <TableHeadCell align="center">
                                            {t('menu.invoice.flight-tax')}
                                        </TableHeadCell>
                                        <TableHeadCell align="center">
                                            {t('menu.invoice.total')}
                                        </TableHeadCell>
                                        {
                                            (!props.invoice || props.invoice.is_generated === false) &&
                                            <TableHeadCell />
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        productInputs.length === 0 &&
                                        <TableRow>
                                            <TableBodyCell colSpan={5}>
                                                <NoInputText variant="body2" color="textSecondary">
                                                    {t('menu.invoice.no-item')}
                                                </NoInputText>
                                            </TableBodyCell>
                                        </TableRow>
                                    }
                                    {
                                        productInputs.map((input) => (
                                            <TableRow key={input.id}>
                                                <TableBodyCell align="center">
                                                    {
                                                        props.invoice && props.invoice.is_generated === true ?
                                                            <Typography variant="body2">
                                                                {input.description}
                                                            </Typography> :
                                                            <Input
                                                                size="small"
                                                                variant="outlined"
                                                                placeholder={t('menu.invoice.description')}
                                                                value={input.description}
                                                                onChange={(event) => onChangeProductInput(input.id, 'description', event.target.value)}
                                                                fullWidth
                                                            />
                                                    }
                                                </TableBodyCell>
                                                <TableBodyCell align="center">
                                                    {
                                                        props.invoice && props.invoice.is_generated === true ?
                                                            <Typography variant="body2">
                                                                {
                                                                    currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                                        i18next.language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: currency ? currency.iso_code : 'EUR'
                                                                        }
                                                                    ).format(input.totalWithoutTax) : ''
                                                                }
                                                            </Typography> :
                                                            <Input
                                                                size="small"
                                                                type="number"
                                                                variant="outlined"
                                                                placeholder={t('menu.invoice.amount')}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {currency !== undefined && currency !== null && currency.symbol !== undefined ? currency.symbol : ''}
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                value={input.totalWithoutTax}
                                                                onChange={(event) => onChangeProductInput(
                                                                    input.id,
                                                                    'totalWithoutTax',
                                                                    event.target.value
                                                                )}
                                                                fullWidth
                                                            />
                                                    }
                                                </TableBodyCell>
                                                <TableBodyCell align="center">
                                                    {
                                                        props.invoice && props.invoice.is_generated === true ?
                                                            <Typography variant="body2">
                                                                {
                                                                    currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                                        i18next.language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: currency ? currency.iso_code : 'EUR'
                                                                        }
                                                                    ).format(input.flightTax) : ''
                                                                }
                                                            </Typography> :
                                                            <Input
                                                                size="small"
                                                                type="number"
                                                                variant="outlined"
                                                                placeholder={t('menu.invoice.amount')}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {currency !== undefined && currency !== null && currency.iso_code !== undefined ? currency.symbol : ''}
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                value={input.flightTax}
                                                                onChange={(event) => onChangeProductInput(
                                                                    input.id,
                                                                    'flightTax',
                                                                    event.target.value
                                                                )}
                                                                fullWidth
                                                            />
                                                    }
                                                </TableBodyCell>
                                                <TableBodyCell align="center">
                                                    <Typography variant="body2">
                                                        {
                                                            currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                                i18next.language,
                                                                {
                                                                    style: 'currency',
                                                                    currency: currency ? currency.iso_code : 'EUR'
                                                                }
                                                            ).format(safeStringToNumber(input.totalWithoutTax) + safeStringToNumber(input.flightTax)) : ''
                                                        }
                                                    </Typography>
                                                </TableBodyCell>
                                                {
                                                    (!props.invoice || props.invoice.is_generated === false) &&
                                                    <TableBodyCell>
                                                        <IconButton onClick={() => onDeleteInput(input.id)}>
                                                            <Close />
                                                        </IconButton>
                                                    </TableBodyCell>
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </TableRoot>
                        </TableContainer>
                        <TotalSection container>
                            <Grid item xs={12} md={8}>
                                {
                                    (!props.invoice || props.invoice.is_generated === false) &&
                                    <AddBlock>
                                        <AddButton size="small" onClick={onAddInput}>
                                            <Add />
                                        </AddButton>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('menu.invoice.add-prestation')}
                                        </Typography>
                                    </AddBlock>
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TotalBlocks>
                                    <FirstHalfTotalBlock>
                                        <div>
                                            <Typography color="textSecondary" component="div">
                                                {t('menu.invoice.total-without-taxes')}
                                            </Typography>
                                            <Typography component="div">
                                                {
                                                    currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                        i18next.language,
                                                        {
                                                            style: 'currency',
                                                            currency: currency ? currency.iso_code : 'EUR'
                                                        }
                                                    ).format(totalWithoutTaxes) : ''
                                                }
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography color="textSecondary" component="div">
                                                {t('menu.invoice.flight-taxes')}
                                            </Typography>
                                            <Typography component="div">
                                                {
                                                    currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                        i18next.language,
                                                        {
                                                            style: 'currency',
                                                            currency: currency ? currency.iso_code : 'EUR'
                                                        }
                                                    ).format(totalFlightTaxes) : ''
                                                }
                                            </Typography>
                                        </div>
                                    </FirstHalfTotalBlock>
                                    <SecondHalfTotalBlock>
                                        <Typography component="div">
                                            {t('menu.invoice.total')}
                                        </Typography>
                                        <Typography component="div">
                                            {
                                                currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                    i18next.language,
                                                    {
                                                        style: 'currency',
                                                        currency: currency ? currency.iso_code : 'EUR'
                                                    }
                                                ).format(totalAmount) : ''
                                            }
                                        </Typography>
                                    </SecondHalfTotalBlock>
                                </TotalBlocks>
                            </Grid>
                        </TotalSection>
                    </ContentContainer>
                    {/*{*/}
                    {/*    invoiceType !== 'INVOICE' && isAfter(new Date(), new Date(data_trip.start_date)) && data_trip.status_modification === 'FIXED_PV' &&*/}
                    {/*    <FormControl className={ 'ft-marginTop8' } fullWidth style={{*/}
                    {/*        marginTop: "0 !important",*/}
                    {/*        marginBottom: 10,*/}
                    {/*        alignContent: "center",*/}
                    {/*        flexWrap: 'wrap',*/}
                    {/*    }}>*/}
                    {/*        <FormControlLabel*/}
                    {/*            style={{marginBottom: 0, position: "relative"}}*/}
                    {/*            checked={ updateAgreedPrice }*/}
                    {/*            onChange={ toggleUpdateAgreedPrice }*/}
                    {/*            control={ <Checkbox color={ "primary" } size={ "small" }/> }*/}
                    {/*            label={ t('invoice.update-agreed-price') }*/}
                    {/*        />*/}
                    {/*    </FormControl>*/}
                    {/*}*/}
                    <ContentButtonsContainer>
                        {
                            (!props.invoice || props.invoice.is_generated === false) &&
                                <React.Fragment>
                                    <MainButton onClick={onSave}>
                                        {t('menu.invoice.save')}
                                    </MainButton>
                                    <MainButton onClick={onGenerate}>
                                        {t('menu.invoice.generate')}
                                    </MainButton>
                                </React.Fragment>
                        }
                    </ContentButtonsContainer>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TripListInvoiceSidePanel invoice={props.invoice} />
                    {
                        props.invoice && props.invoice.is_generated &&
                        <React.Fragment>
                            <SideButtonBlock>
                                <SideButton
                                    startIcon={<Visibility />}
                                    onClick={onPrevisualizePdf}
                                >
                                    {t('menu.invoice.view')}
                                </SideButton>
                                <SideButton
                                    startIcon={<SaveAltOutlined />}
                                    onClick={onExport}
                                >
                                    {t('menu.invoice.export')}
                                </SideButton>
                            </SideButtonBlock>
                            <MainButton onClick={onSendInvoice}>
                                {
                                    invoiceType === 'INVOICE' ?
                                        t('menu.invoice.send-invoice') :
                                        t('menu.invoice.send-credit-note')
                                }
                            </MainButton>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
            <TripListInvoiceCancelModal
                open={openCancelModal}
                onClose={onCloseCancelModal}
                onConfirm={props.onClose}
            />
            <LoadingBackDrop open={loading} />
        </div>
    );
}

const Title = styled('div')(({ theme }) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "#0000008A",
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: 24,
    letterSpacing: 1.25,
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {
        fontSize: 18
    }
}));

const ContentContainer = styled('div')(({ theme }) => ({
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2)
}));

const CloseButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    color: '#07070775'
}));

const EntitiesSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const OwnerBlock = styled('div')(() => ({
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
}));

const ClientBlock = styled('div')(() => ({
    textAlign: 'right'
}));

const ClientBlockTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '5px',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
}));

const OwnerLogo = styled('img')(() => ({
    width: '10%',
    height: 'auto'
}));

const OwnerBlockTitle = styled(Typography)((props) => ({
    marginBottom: props.theme.spacing(1)
}));

const InvoiceNumber = styled(Typography)(({ theme }) => ({
    textAlign: 'right',
    marginBottom: theme.spacing(1)
}));

const TripDetails = styled(Grid)(({ theme }) => ({
    backgroundColor: '#F7C8BA6E',
    borderRadius: 20,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
}));

const TripDetailTitle = styled('span')(() => ({
    textTransform: 'uppercase'
}));

const TableRoot = styled(Table)(() => ({
    textTransform: 'uppercase'
}));

const TableHeadCell = styled(TableCell)(() => ({
    borderTop: '1px solid rgba(224, 224, 224, 1)'
}));

const TableObjectHeadCell = styled(TableHeadCell)(() => ({
    width: '35%'
}));

const TableBodyCell = styled(TableCell)(() => ({
    border: 0,
    paddingBottom: 0
}));

const Input = styled(TextField)(() => ({
    backgroundColor: '#D2E6F870',
    textTransform: 'uppercase'
}));

const TotalSection = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    justifyContent: 'space-between'
}));

const AddBlock = styled('div')(() => ({
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    textTransform: 'uppercase'
}));

const AddButton = styled(IconButton)(() => ({
    "backgroundColor": '#E6592F',
    "color": '#fff',
    '&:hover': {
        backgroundColor: '#E6592F',
        color: '#fff'
    }
}));

const TotalBlocks = styled('div')(() => ({
    width: '100%'
}));

const FirstHalfTotalBlock = styled('div')(({ theme }) => ({
    "borderTop": '1px solid #000',
    "borderBottom": '1px solid #000',
    '& > div:first-child': {
        borderBottom: '1px solid #0000003F'
    },
    '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1)
    },
    '& > div div:nth-child(2)': {
        fontWeight: 'bold'
    }
}));

const SecondHalfTotalBlock = styled('div')(({ theme }) => ({
    "display": 'flex',
    "justifyContent": 'space-between',
    "padding": theme.spacing(1),
    '& *': {
        fontWeight: 'bold'
    }
}));

const ContentButtonsContainer = styled('div')(() => ({
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    width: '85%',
    margin: 'auto'
}));

const MainButton = styled(Button)(() => ({
    "width": '100%',
    "backgroundColor": '#E6592F',
    "color": '#fff',
    '&:hover': {
        backgroundColor: '#E6592F',
        color: '#fff'
    }
}));

const SideButtonBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    marginBottom: theme.spacing(2)
}));

const SideButton = styled(Button)(({ theme }) => ({
    "backgroundColor": '#F7C8BA',
    "borderRadius": 20,
    "width": '100%',
    "marginBottom": theme.spacing(2),
    '&:hover': {
        backgroundColor: '#F7C8BA',
        borderRadius: 20
    }
}));

const NoInputText = styled(Typography)(() => ({
    textAlign: 'center'
}));
