import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@mui/material";
import { ProviderContext } from "./utils/providerContext";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    logoUrl?: string | null,
    name?: string | null,
    width?: number,
    height?: number,
    isDirectContract?: boolean,
    enableClientLogoSwitchMode?: boolean,
    minimal?: boolean
}

export function CartProductCardProviderLogo(props: Props): React.ReactNode {
    const user = useSelector((state: AppState) => state.user.user);
    const [hasError, setHasError] = useState(false);
    const providerContext = useContext(ProviderContext);
    const config = JSON.parse(localStorage.getItem('config') ?? '{}');
    const quotation_code = config.quotation_code;
    const clientLogoUrl = config.logo?.url as string | undefined;
    const clientLogoName = config.logo?.name as string | undefined;

    if (providerContext.module) {
        return null;
    }

    return (
        <>
            {
                (user?.client_full?.type !== 2 || quotation_code === 'verdie' || props.isDirectContract) &&
                props.logoUrl &&
                <>
                    {
                        !hasError &&
                        <Box
                            src={props.logoUrl}
                            component="img"
                            sx={{
                                width: props.width ?? 75,
                                height: props.height ?? 75,
                                objectFit: 'contain'
                            }}
                            onError={() => {
                                setHasError(true);
                            }}
                        />
                    }
                    {
                        hasError &&
                        <Tooltip title={props.name}>
                            <Typography
                                sx={{
                                    height: !props.minimal ? (props.height ?? 75) : undefined,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                    display: 'inline-block'
                                }}>
                                {props.name}
                            </Typography>
                        </Tooltip>
                    }
                </>
            }
            {
                (user?.client_full?.type !== 2 || quotation_code === 'verdie' || props.isDirectContract) &&
                !props.logoUrl &&
                <Tooltip title={props.name}>
                    <Typography
                        sx={{
                            height: !props.minimal ? (props.height ?? 75) : undefined,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'center',
                            display: 'inline-block'
                        }}>
                        {props.name}
                    </Typography>
                </Tooltip>
            }
            {
                props.enableClientLogoSwitchMode &&
                user?.client_full?.type === 2 &&
                quotation_code !== 'verdie' &&
                <>
                    {
                        !hasError &&
                        <Box
                            src={clientLogoUrl}
                            component="img"
                            sx={{
                                width: props.width ?? 75,
                                height: props.height ?? 75,
                                objectFit: 'contain'
                            }}
                            onError={() => {
                                setHasError(true);
                            }}
                        />
                    }
                    {
                        hasError &&
                        <Tooltip title={clientLogoName}>
                            <Typography
                                sx={{
                                    height: !props.minimal ? (props.height ?? 75) : undefined,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center',
                                    display: 'inline-block'
                                }}>
                                {clientLogoName}
                            </Typography>
                        </Tooltip>
                    }
                </>
            }
        </>
    );
}
