import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography
} from "@mui/material";

type Props = {
    defaultComment: string | null,
    onConfirm: (providerComment: string | null) => void,
    onClose: () => void,
    disableCancel: boolean
}

export function ProviderQuotationProductCommentModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [comment, setComment] = useState('');
    const commentIsTooLong = comment.length > 500;

    const onSave = () => {
        props.onConfirm(comment);
        props.onClose();
    };

    useEffect(() => {
        setComment(props.defaultComment ?? '');
    }, [props.defaultComment]);

    return (
        <Dialog
            open
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {t('cart-material.provider-quotation-comment')}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t('cart-material.provider-quotation-comment-provider-title')}
                </Typography>
                <TextField
                    label={t('cart-material.provider-quotation-comment')}
                    value={comment}
                    sx={{ marginTop: 2 }}
                    minRows={10}
                    maxRows={10}
                    helperText={
                        <Stack direction="row">
                            {
                                commentIsTooLong &&
                                <Typography variant="caption">
                                    {t('cart-material.provider-quotation-comment-too-long-error')}
                                </Typography>
                            }
                            <Typography
                                variant="caption"
                                sx={{ marginLeft: 'auto' }}
                            >
                                {comment.length} / 500
                            </Typography>
                        </Stack>
                    }
                    onChange={(event) => setComment(event.target.value)}
                    error={commentIsTooLong}
                    multiline
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                {
                    !props.disableCancel &&
                    <Button onClick={props.onClose}>
                        {t('shared.cancel')}
                    </Button>
                }
                <Button onClick={onSave} disabled={commentIsTooLong}>
                    {t('cart-material.next')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
