import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AcUnitOutlined,
    ArrowRightAltOutlined,
    CloudCircle,
    CloudOff,
    DateRangeOutlined,
    DriveEtaOutlined,
    Edit,
    ErrorOutline,
    PanToolOutlined,
    PermIdentity,
    PlaceOutlined,
    ReportProblemOutlined,
    WorkOutline
} from "@mui/icons-material";
import axios from "axios";
import { isNumber } from "lodash";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartProductIconText } from "./CartProductIconText";
import { CartCarCardDetails } from "./CartCarCardDetails";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartProductCardName } from "./CartProductCardName";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import DoorIcon from "../CarsMaterial/DoorIcon";
import GearIcon from "../CarsMaterial/GearIcon";
import { ProviderContext } from "./utils/providerContext";
import { getCarPicture } from "./utils/getCarPicture";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { findPictureUrl } from "./utils/findPictureUrl";
import { insertDivider } from "./utils/insertDivider";
import { isProductPackaged } from "./utils/isProductPackaged";
import { useGetPrice } from "./utils/getPrice";
import { useShowError } from "../Utils/showError";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { CarCart } from "../Itinerary/objects/carCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    index: number,
    car: CarCart
} & Pick<CartProductCardProps, 'alwaysOpen'>

export function CartCarCard(props: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const providers = useSelector((state: AppState) => state.trip.providers);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const user = useSelector((state: AppState) => state.user?.user);
    const [editTime, setEditTime] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const [loading, setLoading] = useState(false);
    const [providerErrors, setProviderErrors] = useState<{
        provider: boolean,
        subprovider: boolean
    }>({ provider: false, subprovider: false });
    const pictures = useMemo(() => {
        const pictures = getCarPicture(props.car);

        return pictures.length > 0 ?
            pictures.map((picture) => {
                return findPictureUrl(picture);
            }) :
            [props.car.vehicle_picture_url ?? ''];
    }, [props.car]);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.car.provider;
        })?.provider;
    }, [props.car, providers, manualProviders]);
    const subprovider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.car.subprovider_id;
        })?.provider;
    }, [props.car, providers, manualProviders]);
    const { found, iti_error } = useMemo(() => {
        return findProductItineraryError(
            'car',
            {
                product: props.car,
                itinerary,
                stackInfos: trip?.stack_info ?? null
            }
        );
    }, [
        itinerary,
        props.car,
        trip
    ]);
    const getPrice = useGetPrice();
    const totalCost = getPrice(props.car.prices);
    const showError = useShowError();
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            dispatch({
                type: 'CAR_EDIT_CART_BY_ID',
                payload: product
            });
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const providerContext = useContext(ProviderContext);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onShowBookingDetail = () => {
        dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: props.car });
    };

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.car.id}/`,
            data: {
                traveler_modification: null
            }
        }).then(response => {
            dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    const onChangeBookingReference = (reference: string) => {
        if (
            providerContext.module === 'booking' &&
            providerContext.tripId &&
            providerContext.version
        ) {
            changeBookingStatus({
                tripId: providerContext.tripId,
                version: providerContext.version,
                productId: props.car.id,
                ref: reference,
                status: props.car.booking_status?.status_booking ?? null,
                isManual: false
            });
        }
    }

    return (
        <>
            <CartProductCard
                type="car"
                index={props.index}
                item={props.car}
                mainContent={
                    <Stack>
                        <CartProductCardName
                            type="car"
                            content={
                                [
                                    props.car.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ?? props.car.name,
                                    quotation_code === 'marcovasco' &&
                                    `(${props.car.vehicle_code})`,
                                    props.car.variant &&
                                    ` (${props.car.variant.localization?.find((item) => item.locale === locale)?.title ?? props.car.variant.name})`
                                ].filter((item) => item).join(' ')
                            }
                            modifiedName={
                                props.car.localization.find((item) => {
                                    return item.locale === locale;
                                })?.name ??
                                props.car.name
                            }
                            providerName={props.car.name}
                            localization={props.car.localization}
                            id={props.car.id}
                            isCustom={props.car.is_custom}
                            startIcon={DriveEtaOutlined}
                            isProposition={props.car.provider_created}
                        />
                        {
                            (
                                props.car.address_pickup &&
                                props.car.address_return
                            ) &&
                            <>
                                {
                                    props.car.address_pickup === props.car.address_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.same-agency')} :{' '}
                                                {props.car.address_pickup}{' '}
                                                {props.car.station_name_pickup ? props.car.station_name_pickup : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                                {
                                    props.car.address_pickup !== props.car.address_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.pickup-agency')} :{' '}
                                                {props.car.address_pickup}{' '}
                                                {props.car.station_name_pickup ? props.car.station_name_pickup : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                                {
                                    props.car.address_pickup !== props.car.address_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.return-agency')} :{' '}
                                                {props.car.address_return}{' '}
                                                {props.car.station_name_return ? props.car.station_name_return : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                            </>
                        }
                        {
                            (
                                !props.car.address_pickup ||
                                !props.car.address_return
                            ) &&
                            <>
                                {
                                    props.car.station_name_pickup === props.car.station_name_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.same-agency')} :{' '}
                                                {props.car.address_pickup || props.car.address_return}{' '}
                                                {props.car.station_name_pickup ? props.car.station_name_pickup : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                                {
                                    props.car.station_name_pickup !== props.car.station_name_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.pickup-agency')} :{' '}
                                                {props.car.address_pickup}{' '}
                                                {props.car.station_name_pickup ? props.car.station_name_pickup : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                                {
                                    props.car.station_name_pickup !== props.car.station_name_return &&
                                    <CartProductIconText
                                        text={
                                            <>
                                                {t('cart-material.return-agency')} :{' '}
                                                {props.car.address_return}{' '}
                                                {props.car.station_name_return ? props.car.station_name_return : ''}
                                            </>
                                        }
                                        startIcon={PlaceOutlined}
                                    />
                                }
                            </>
                        }
                        <CartProductIconText
                            text={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <div>
                                        {
                                            !window.moment.utc(props.car.start_date).isSame(props.car.end_date) &&
                                            t(
                                                'cart-material.cart-construction-products-table-date',
                                                {
                                                    from: window.moment.utc(props.car.start_date).format('LLL'),
                                                    to: window.moment.utc(props.car.end_date).format('LLL')
                                                }
                                            )
                                        }
                                        {
                                            window.moment.utc(props.car.start_date).isSame(props.car.end_date) &&
                                            window.moment.utc(props.car.start_date).format('LLL')
                                        }
                                    </div>
                                    {
                                        (
                                            !window.moment.utc(props.car.provider_start_date).isSame(window.moment.utc(props.car.start_date), 'minutes') ||
                                            !window.moment.utc(props.car.provider_end_date).isSame(window.moment.utc(props.car.end_date), 'minutes')
                                        ) &&
                                        <Tooltip title={t('cart-material.cart-construction-dates-changed-hint')}>
                                            <PanToolOutlined sx={{ fontSize: '1.2em' }} />
                                        </Tooltip>
                                    }
                                    {
                                        (
                                            props.car.is_custom ||
                                            props.car.booking_status
                                        ) &&
                                        (
                                            quotation_code !== 'visiteurs' ||
                                            user?.client_full?.type !== 2
                                        ) &&
                                        <IconButton size="small" onClick={() => setEditTime(true)}>
                                            <Edit fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Stack>
                            }
                            startIcon={DateRangeOutlined}
                        />
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Tooltip title={t("global.luggages")} placement={"bottom"}>
                                <CartProductIconText
                                    text={props.car.baggage}
                                    startIcon={WorkOutline}
                                />
                            </Tooltip>
                            <Tooltip title={t("flight_groups.passengers")} placement={"bottom"}>
                                <CartProductIconText
                                    text={props.car.group_passenger?.travelers.length ?? 0}
                                    startIcon={PermIdentity}
                                />
                            </Tooltip>
                            <Tooltip title={t("tooltip.door_nbr")} placement={"bottom"}>
                                <CartProductIconText
                                    text={props.car.door_count}
                                    startIcon={(props) => <DoorIcon {...props} fontSize="inherit" />}
                                />
                            </Tooltip>
                            {
                                props.car.air_conditioner &&
                                <Tooltip title={t("accommodation.equipment.air_conditioning")} placement={"bottom"}>
                                    <AcUnitOutlined fontSize="small" />
                                </Tooltip>
                            }
                            <Tooltip title={t("cars.transmission.transmission")} placement={"bottom"}>
                                <CartProductIconText
                                    text={t("cars.transmission." + (props.car.transmission_type).toLowerCase())}
                                    startIcon={(props) => <GearIcon {...props} fontSize="inherit" />}
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t('cart-material.cart-construction-amount')}
                        </Typography>
                        <ProviderQuotationPriceEdit
                            productId={props.car.id}
                            price={
                                (
                                    providerContext.module ?
                                        totalCost.purchaseCost :
                                        totalCost.cost
                                ).toString()
                            }
                            open={editPrice}
                            onOpen={() => setEditPrice(true)}
                            onClose={() => setEditPrice(false)}
                        >
                            <Typography fontWeight="bold" textAlign="center">
                                {
                                    new Intl.NumberFormat(
                                        i18n.language,
                                        {
                                            style: 'currency',
                                            currency: providerContext.module ?
                                                totalCost.purchaseCurrency?.iso_code ?? 'EUR' :
                                                totalCost.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(
                                        providerContext.module ?
                                            totalCost.purchaseCost :
                                            totalCost.cost
                                    )
                                }
                                {
                                    !providerContext.module &&
                                    props.car.price_change &&
                                    props.car.price_change.price_variation !== 0 &&
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: props.car.price_change.price_variation < 0 ? 'green' : 'red',
                                                fontSize: '10px'
                                            }}
                                        >
                                            {
                                                new Intl.NumberFormat(
                                                    i18n.language,
                                                    {
                                                        style: 'currency',
                                                        currency: totalCost.currency?.iso_code ?? 'EUR'
                                                    }
                                                ).format(props.car.price_change.price_variation)
                                            }
                                        </Typography>
                                        <ArrowRightAltOutlined
                                            sx={{
                                                transform: props.car.price_change.price_variation < 0 ?
                                                    'rotateZ(90deg)' :
                                                    'rotateZ(-90deg)',
                                                color: 'green',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </Stack>
                                }
                            </Typography>
                        </ProviderQuotationPriceEdit>
                        <Stack direction="row" spacing={1.5}>
                            {/*{*/}
                            {/*props.car.provider === props.car.subprovider_id && user?.client_full?.type === 2 && quotation_code === 'visiteurs' &&*/}
                            {/*<CartProductCardProviderLogo*/}
                            {/*enableClientLogoSwitchMode={true}*/}
                            {/*/>*/}
                            {/*}*/}
                            {
                                (props.car.subprovider_id === null || user?.client_full?.type !== 2) && props.car.provider !== props.car.subprovider_id &&
                                <CartProductCardProviderLogo
                                    logoUrl={
                                        provider?.logo?.url ??
                                        provider?.logo?.thumbnail_big ??
                                        provider?.logo?.thumbnail_medium ??
                                        provider?.logo?.thumbnail_little ??
                                        null
                                    }
                                    name={provider?.name ?? null}
                                    isDirectContract={isNumber(props.car.provider) && !props.car.subprovider_id}
                                    enableClientLogoSwitchMode={
                                        false
                                    }
                                />
                            }
                            {
                                props.car.subprovider_id === null && user?.client_full?.type === 2 &&
                                <CartProductCardProviderLogo
                                    enableClientLogoSwitchMode={true}
                                />
                            }
                            {
                                !providerContext.module &&
                                subprovider &&
                                <>
                                    {
                                        !providerErrors.subprovider &&
                                        <Box
                                            src={
                                                subprovider?.logo?.url ??
                                                subprovider?.logo?.thumbnail_big ??
                                                subprovider?.logo?.thumbnail_medium ??
                                                subprovider?.logo?.thumbnail_little ??
                                                ''
                                            }
                                            component="img"
                                            sx={{
                                                width: 75,
                                                height: 75,
                                                objectFit: 'contain'
                                            }}
                                            onError={() => {
                                                setProviderErrors((state) => ({
                                                    ...state,
                                                    subprovider: true
                                                }));
                                            }}
                                        />
                                    }
                                    {
                                        providerErrors.subprovider &&
                                        <Tooltip title={subprovider.name}>
                                            <Typography
                                                sx={{
                                                    width: 75,
                                                    maxWidth: 75,
                                                    height: 75,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                {subprovider.name}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </>
                            }
                            {
                                !providerContext.module &&
                                !subprovider &&
                                !providerErrors.subprovider &&
                                props.car.subprovider_logo &&
                                <Box
                                    src={props.car.subprovider_logo}
                                    component="img"
                                    sx={{
                                        width: 75,
                                        height: 75,
                                        objectFit: 'contain'
                                    }}
                                    onError={() => {
                                        setProviderErrors((state) => ({
                                            ...state,
                                            subprovider: true
                                        }));
                                    }}
                                />
                            }
                            {
                                !providerContext.module &&
                                !subprovider &&
                                providerErrors.subprovider &&
                                props.car.subprovider_name &&
                                <Tooltip title={props.car.subprovider_name}>
                                    <Typography
                                        sx={{
                                            width: 75,
                                            maxWidth: 75,
                                            height: 75,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                        {props.car.subprovider_name}
                                    </Typography>
                                </Tooltip>
                            }
                        </Stack>
                    </Stack>
                }
                footer={(providerActions, providerInfo) => (
                    <div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {
                                insertDivider(
                                    [
                                        !isProductPackaged({
                                            product: props.car,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <CartConstructionProductsTableItemStatus
                                                type="car"
                                                item={props.car}
                                                bookingProcessState={props.car.booking_process_state}
                                                bookingStatus={props.car.booking_status}
                                                agencyNeedToBook={props.car.agency_need_to_book}
                                            />
                                            {
                                                (
                                                    props.car.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                    props.car.booking_status?.status_booking === StatusBooking.PENDING ||
                                                    props.car.booking_status?.status_booking === StatusBooking.WAITING ||
                                                    providerContext.module === 'booking'
                                                ) &&
                                                <ProviderBookingReferenceEdit
                                                    open={editBookingReference}
                                                    productId={props.car.id}
                                                    reference={props.car.booking_status?.item_reference ?? ''}
                                                    onOpen={() => setEditBookingReference(true)}
                                                    onClose={() => setEditBookingReference(false)}
                                                    onSave={onChangeBookingReference}
                                                >
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.car.booking_status?.item_reference }
                                                            )
                                                        }
                                                    </Typography>
                                                </ProviderBookingReferenceEdit>
                                            }
                                        </>,
                                        providerActions,
                                        !providerContext.module &&
                                        !isProductPackaged({
                                            product: props.car,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        (
                                            user?.client_full?.type !== 2 ||
                                            props.car.creator?.client?.type === user.client_full.type
                                        ) &&
                                        <>
                                            {
                                                !props.car.is_custom ?
                                                    <Chip
                                                        label={t('cart-material.flux')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#2ACAEA',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudCircle color="inherit" />}
                                                    /> :
                                                    <Chip
                                                        size="small"
                                                        label={t('cart-material.offline')}
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudOff color="inherit" />}
                                                    />
                                            }
                                        </>,
                                        user?.client_full?.type !== 2 &&
                                        props.car.traveler_modification &&
                                        <Chip
                                            color="warning"
                                            label={
                                                props.car.traveler_modification === 'SET_IN_TRIP' ?
                                                    t('cart-material.add-to-cart-traveler') :
                                                    t('cart-material.add-to-option-traveler')
                                            }
                                            size="small"
                                            icon={<ErrorOutline color="inherit" />}
                                            onDelete={confirmTravelerModification}
                                        />,
                                        props.car.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                        props.car.on_request &&
                                        <Typography variant="caption">
                                            {t('cart-material.on-request')}
                                        </Typography>,
                                        !isProductPackaged({
                                            product: props.car,
                                            stackInfos: trip?.stack_info ?? null
                                        }) &&
                                        !props.car.is_cancellable &&
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t('cart-material.not-cancellable')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>
                                    ]
                                )
                            }
                        </Stack>
                        {
                            props.car.booking_status &&
                            <Button
                                sx={{
                                    padding: 0,
                                    textTransform: 'none',
                                    color: '#000',
                                    textDecoration: 'underline'
                                }}
                                onClick={onShowBookingDetail}
                            >
                                {t('cart-material.cart-construction-details-and-cancellation')}
                            </Button>
                        }
                        {providerInfo}
                    </div>
                )}
                alerts={
                    <Collapse
                        in={
                            (found.start && !!iti_error.start) ||
                            (found.end && !!iti_error.end) ||
                            !found.end ||
                            !found.start
                        }
                        unmountOnExit
                    >
                        <CardContent
                            sx={{
                                borderTop: (found.start && !!iti_error.start) ||
                                    (found.end && !!iti_error.end) ||
                                    !found.end ||
                                    !found.start ?
                                    '1px solid rgba(0, 0, 0, 0.25)' :
                                    undefined
                            }}
                        >
                            <Stack spacing={1}>
                                {
                                    iti_error.start &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.itinerary-error-1')}{' '}
                                        {
                                            iti_error.start.destination?.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            iti_error.start.destination?.data?.name ??
                                            iti_error.start.destination?.data?.international_name
                                        } {t('global.from')}{' '}
                                        {window.moment.utc(iti_error.start.start_date).format('DD/MM/YYYY HH:mm')}{' '}
                                        {t('global.to')}{' '}
                                        {window.moment.utc(iti_error.start.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
                                        {t('cart-material.itinerary-error-2')}.
                                    </Alert>
                                }
                                {
                                    iti_error.end &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.itinerary-error-1')}{' '}
                                        {
                                            iti_error.end.destination?.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            iti_error.end.destination?.data?.name ??
                                            iti_error.end.destination?.data?.international_name
                                        } {t('global.from')}{' '}
                                        {window.moment.utc(iti_error.end.start_date).format('DD/MM/YYYY HH:mm')}{' '}
                                        {t('global.to')}{' '}
                                        {window.moment.utc(iti_error.end.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
                                        {t('cart-material.itinerary-error-2')}.
                                    </Alert>
                                }
                                {
                                    !found.start && !found.end &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.no-destination-found')}
                                    </Alert>
                                }
                                {
                                    !found.start && found.end &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.no-start-destination-found')}
                                    </Alert>
                                }
                                {
                                    found.start && !found.end &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.no-end-destination-found')}
                                    </Alert>
                                }
                            </Stack>
                        </CardContent>
                    </Collapse>
                }
                margin={<MarginWrapper car={props.car} />}
                pictures={pictures}
                alwaysOpen={props.alwaysOpen}
                showEntirePictures
            >
                <CartCarCardDetails car_index={props.index} car={props.car} />
            </CartProductCard>
            {
                user?.client_full?.type !== 2 &&
                editTime &&
                <CartClientTimeModal
                    type="normal"
                    product={props.car}
                    onClose={() => setEditTime(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

type MarginWrapperProps = {
    car: CarCart
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                !isProductPackaged({
                    product: props.car,
                    stackInfos: trip?.stack_info ?? null
                })
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="car"
                    item={props.car}
                />
            </CardContent>
        </Collapse>
    );
}
