import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ListIcon from '@material-ui/icons/List';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import green from "@material-ui/core/colors/green";

import PoiCardGroups from "./PoiCardGroups";
import CustomTimePicker from "./CustomTimePicker";
import ConnectedPoiAvailability from "./ConnectedPoiAvailability";
import CustomPoiAvailability from "./CustomPoiAvailability";

import { SetCustomTimeError } from "../../../Actions/Poi";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import GetIsoCode from "../Functions/GetIsoCode";

import axios from "axios";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    orangeBorderButton: {
        color: "#E6592F",
        borderColor: "#E6592F",
        backgroundColor: "#FFFFFF"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    spacer: {
        padding: "8px 0px"
    },
    bigSpacer: {
        padding: "16px 0px"
    },
    timeBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        borderRadius: 8,
        boxSizing: "border-box",
        padding: 8
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const PoiCardMobilePrices = ({start_date, end_date, activity, current_travelers, productSelected, setProductSelected, assignedGroups, setAssignedGroups, openModal, setOpenModal, addToCart, addingToCart}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    const current_groups = useSelector(store => store.poi.current_groups);
    const destination = useSelector(state => state.poi.destination);

    const [arrivalDate, setArrivalDate] = useState(start_date);
    const [departureDate, setDepartureDate] = useState(end_date);
    const [availabilities, setAvailabilities] = useState([]);
    const [availabilitiesSearching, setAvailabilitiesSearching] = useState(false);
    const [seeAllAvailabilities, setSeeAllAvailabilities] = useState(false);
    const [displayCustomTime, setDisplayCustomTime] = useState(false);
    const [customTime, setCustomTime] = useState("00:00");
    const [minCustomTime, setMinCustomTime] = useState(null);
    const [maxCustomTime, setMaxCustomTime] = useState(null);
    const [variantChosen, setVariantChosen] = useState("");
    const [view, setView] = useState("card");
    const [displayCalendarDate, setDisplayCalendarDate] = useState(false);
    const [totalPrice, setTotalPrice] = useState(null);

    moment.locale(language);

    useEffect(() => {
        setAvailabilities([]);
        setProductSelected([]);
        setAssignedGroups([]);
        setDisplayCustomTime(false);
        setDisplayCalendarDate(false);
    }, [current_groups])

    useEffect(() => {
        if (productSelected.length > 0){
            let total_price = 0;
            let total_currency = 0;
            productSelected.map((product) => {
                total_price += parseFloat(product.total_price[0].selling_price);
                total_currency = product.total_price[0].selling_currency;
            })
            setTotalPrice({selling_price: total_price, selling_currency: total_currency});
        }
    }, [productSelected])

    const onClose = () => {
        setOpenModal(false);
    }

    const changeDates = (dates) => {
        setArrivalDate(dates[0].format("YYYY-MM-DD"));
        setDepartureDate(dates[1].format("YYYY-MM-DD"));
    }

    const getAvailabilities = () => {
        setAvailabilitiesSearching(true);
        let { pass_check, headers } = CheckBeforeRequest();
        // reset chosen variant
        setVariantChosen("");
        if (pass_check){
            let temp_availabilities = [];
            current_groups.map((group) => {
                axios({
                    method: "get",
                    url: `${API_HREF}client/${window.id_owner}/custom-products/product_prices/?provider_id=${(activity.is_custom ? activity.provider.provider.id : activity.provider.id)}&start_destination=${destination.destination.id}&custom_provider=${(activity.is_custom ? "true" : "false")}&product_identifiers=${(activity.is_custom ? encodeURIComponent(activity.id) : encodeURIComponent(activity.product_code))}&arrival_date=${arrivalDate}&departure_date=${departureDate}&comps=${group.id}&trip=${GetCookie("trip_id")}&product_quantity=1&token=${GetCookie("trip_token")}&language=${language}`,
                    headers: headers
                }).then(function(response){
                    // no availabilities found
                    if (response.data.scheduled_prices.length === 0){
                        enqueueSnackbar(t("poi.no_price"), { variant: "warning", autoHideDuration: 4000, disableWindowBlurListener: true })
                    }else{
                        let temp_response = Object.assign({}, response.data);
                        if (temp_response.choices !== undefined){
                            temp_response.choices.sort((a,b) => {
                                return a.name.localeCompare(b.name)
                            })

                            temp_response.choices.map((variant, variant_index) => {
                                let new_variant_prices = regroupPricesbyDate(variant.scheduled_prices, setDisplayCustomTime, true);
                                temp_response.choices[variant_index].scheduled_prices = new_variant_prices;
                            })
                        }
                        let new_schedules_prices = regroupPricesbyDate(temp_response.scheduled_prices, setDisplayCustomTime, false);
                        temp_response.scheduled_prices = new_schedules_prices;
                        temp_availabilities.push(temp_response);

                        if (temp_availabilities.length === current_groups.length){
                            setAvailabilities(temp_availabilities);
                        }
                    }
                    setAvailabilitiesSearching(false);
                }).catch(function(error){
                    console.log("availability error", error)
                })
            })
        }
    }

    const regroupPricesbyDate = (old_prices, setDisplayCustomTime, isVariant) => {
        // regroup schedules prices by day
        let new_schedules_prices = [];
        old_prices.map((one_price) => {
            let copy_price = Object.assign({}, one_price);
            copy_price.areBounds = false;
            if (copy_price.hour.length === 2 && copy_price.hour[0] === copy_price.hour[1]) {
                copy_price.areBounds = true;
                setDisplayCustomTime(true);
            }
            if (copy_price.hour.length === 0 || (copy_price.hour.length === 1 && copy_price.hour[0] === "") || copy_price.hour.length === 2 && copy_price.hour[0] === copy_price.hour[1]) {
                copy_price.areBounds = true;
                setDisplayCustomTime(true);
            }

            // if(copy_price.hour.length === 2 && copy_price.hour[0] !== copy_price.hour[1]){
            //     // by default two element in hour array means bounds
            //     copy_price.areBounds = true;
            //     // display custom time if copy_price have two hours (bounds)
            //     setDisplayCustomTime(true);
            // }else{
            //     if(copy_price.hour.length === 0){
            //         // display custom time if copy_price is empty
            //         setDisplayCustomTime(true);
            //     }else if (copy_price.hour.length === 1 && copy_price.hour[0] === ""){
            //         // display custom time if copy_price only element is empty
            //         setDisplayCustomTime(true);
            //     }
            //
            //     copy_price.areBounds = false;
            // }

            let date_to_be_added = true;
            new_schedules_prices.map((new_price) => {
                if (!isVariant && copy_price.date === new_price.date){
                    date_to_be_added = false;
                    let temp_new_hours = new_price.hour.slice();
                    if (copy_price.hour.length > 0){
                        temp_new_hours.push(copy_price.hour[0]);
                    }
                    let temp_new_identifiers = new_price.identifier.slice();
                    temp_new_identifiers.push(copy_price.identifier);
                    new_price.hour = temp_new_hours;
                    new_price.identifier = temp_new_identifiers;
                }
            })

            if (date_to_be_added){
                copy_price.identifier = [copy_price.identifier];
                new_schedules_prices.push(copy_price);
            }
        })

        new_schedules_prices.map((new_price) => {
            new_price.hourUnordered = new_price.hour.slice();
            if (new_price.areBounds === false){
                new_price.hour.sort((a, b) => {
                    return a.localeCompare(b);
                })
            }
        })
        return new_schedules_prices;
    }

    const handleView = () => {
        let temp_view = "";
        switch(view){
            case "card":
                temp_view = "calendar";
                break
            case "calendar":
                temp_view = "card";
                break
        }
        setView(temp_view);
    }

    const selectActivity = (chosen_date_prices, time_variable) => {
        let group_not_assigned = [];
        let not_assigned_index = [];
        let chosen_time = time_variable !== null ? time_variable : customTime;

        if ((chosen_date_prices.hour.length !== 1 || chosen_date_prices.hour[0] === "") && chosen_time === ""){
            enqueueSnackbar(t("poi.error_choose_time"), { variant: "error" });
            dispatch(SetCustomTimeError(true));
        }else{
            dispatch(SetCustomTimeError(false));
            current_groups.map((group, group_index) => {
                if (!assignedGroups.includes(group.id)){
                    group_not_assigned.push(group.id);
                    not_assigned_index.push(group_index);
                }
            })
            let temp_products = productSelected.slice();
            let temp_assigned_groups = assignedGroups.slice();

            // display custom time if day_prices is empty or have two hours (bounds)
            let add_product_request = {
                "arrival_date": (chosen_date_prices.hour.length === 1 && chosen_date_prices.hour[0] !== "") ? (chosen_date_prices.date + " " + chosen_date_prices.hour[0].replace("h", ":")) : chosen_date_prices.date + " " + chosen_time.replace("h", ":"),
                "comps": [group_not_assigned[0]],
                "custom_provider": activity.is_custom,
                "identifier": chosen_date_prices.identifier,
                "product_identifiers": activity.is_custom ? [activity.id] : [activity.product_code],
                "provider_id": activity.is_custom ? activity.provider.provider.id : activity.provider.id,
                "start_destination": destination.destination.id,
                "group_index": not_assigned_index[0],
                "detail_price": chosen_date_prices.details,
                "total_price": chosen_date_prices.total_price
            }

            // only for custom products
            if (chosen_date_prices.schedule_info !== undefined && chosen_date_prices.schedule_info !== null && chosen_date_prices.schedule_info !== ""){
                add_product_request["custom_information"] = chosen_date_prices.schedule_info;
            }

            // only for activities with variants
            if (variantChosen !== ""){
                add_product_request["variant_name"] = variantChosen.name;
            }

            temp_products.push(add_product_request);
            temp_assigned_groups.push(group_not_assigned[0]);

            setProductSelected(temp_products);
            setAssignedGroups(temp_assigned_groups);
            setSeeAllAvailabilities(false);
            setOpenModal(false);
        }
    }

    return (
        <Dialog fullScreen={ true } open={ openModal } fullWidth onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle>
                <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                    <Grid item className={ classes.bold }>
                        <IconButton onClick={ onClose }><NavigateBefore/></IconButton> { t('accommodation_list.parameters') }
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    productSelected.length !== 0 && totalPrice !== null && (
                        <Typography className={ `${classes.bold} ${classes.paddingBottom}` }>
                            { t("poi.total") + " : "}
                            { new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, totalPrice.selling_currency) }).format(parseFloat(totalPrice.selling_price)) }
                        </Typography>
                    )
                }
                {
                    current_groups !== null && current_groups.length !== 0 && (
                        <PoiCardGroups current_travelers={ current_travelers } />
                    )
                }
                <br />
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                    <DateRangePicker
                        startText={ t("poi.arrival") }
                        endText={ t("poi.departure") }
                        value={[arrivalDate, departureDate]}
                        onChange={dates => changeDates(dates)}
                        minDate={start_date}
                        maxDate={end_date}
                        renderInput={(startProps, endProps) => {
                            startProps.helperText = undefined;
                            startProps.fullWidth = true;
                            endProps.helperText = undefined;
                            endProps.fullWidth = true;
                            return (
                                <Fragment>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter>{ t("accommodation.to") }</DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </Fragment>
                            )
                        }}
                    />
                </LocalizationProvider>
                {
                    assignedGroups.length !== current_groups.length && (
                        <Grid>
                            <br />
                            <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={ () => getAvailabilities() } disabled={availabilitiesSearching}>
                                { t("poi.check_availability") }
                                { availabilitiesSearching && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                            </Button>
                            <br />
                        </Grid>
                    )
                }
                {
                    // change view
                    availabilities.length !== 0 && assignedGroups.length !== current_groups.length && (
                        <Grid container alignItems='center' justify="flex-end" className={classes.bigSpacer}>
                            <Grid item>
                                <Typography variant="body2" className={classes.bold}>
                                    { t("poi.pick_time") + " " + t("poi.for_group") + " " + (assignedGroups.length + 1) }
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title={view === "card" ? t("poi.calendar_view") : t("poi.card_view")}>
                                    <IconButton size="small" onClick={() => handleView()}>
                                        {
                                            view === "card" && (<CalendarTodayOutlinedIcon />)
                                        }
                                        {
                                            view === "calendar" && (<ListIcon />)
                                        }
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    // let the user choose a time for activity
                    displayCustomTime && availabilities.length !== 0 && assignedGroups.length !== current_groups.length && (
                        <Grid className={classes.timeBorder}>
                            <Typography className={`${classes.bold} ${classes.spacer}`} variant='body2'> { t("poi.select_hour") + " :" } </Typography>
                            {
                                minCustomTime !== null && maxCustomTime !== null && (
                                    <Typography className={`${classes.spacer}`} variant='body2'> { t("poi.open_hours") + " : " + minCustomTime + "-" + maxCustomTime } </Typography>
                                )
                            }
                            <CustomTimePicker value={customTime} setValue={setCustomTime} valueChoices={null} step={15} minValue={minCustomTime} maxValue={maxCustomTime} />
                            <Typography className={classes.spacer} style={{ fontSize: 10 }}> { t("poi.to_set_info") } </Typography>
                        </Grid>
                    )
                }
                {
                    !activity.is_custom && (
                        <ConnectedPoiAvailability
                            arrivalDate={arrivalDate}
                            departureDate={departureDate}
                            availabilities={availabilities}
                            assignedGroups={assignedGroups}
                            view={view}
                            displayCalendarDate = {displayCalendarDate}
                            setDisplayCalendarDate={setDisplayCalendarDate}
                            seeAllAvailabilities={seeAllAvailabilities}
                            setSeeAllAvailabilities={setSeeAllAvailabilities}
                            variantChosen={variantChosen}
                            setVariantChosen={setVariantChosen}
                            selectActivity={selectActivity} />
                    )
                }
                {
                    activity.is_custom && (
                        <CustomPoiAvailability
                            arrivalDate={arrivalDate}
                            departureDate={departureDate}
                            availabilities={availabilities}
                            assignedGroups={assignedGroups}
                            view={view}
                            displayCalendarDate = {displayCalendarDate}
                            setDisplayCalendarDate={setDisplayCalendarDate}
                            seeAllAvailabilities={seeAllAvailabilities}
                            setSeeAllAvailabilities={setSeeAllAvailabilities}
                            setMinCustomTime={setMinCustomTime}
                            setMaxCustomTime={setMaxCustomTime}
                            selectActivity={selectActivity} />
                    )
                }
                {
                    // add to cart button
                    availabilities.length !== 0 && assignedGroups.length !== 0 && (
                        <Grid>
                            <br />
                            <Button variant="contained" className={ classes.orangeButton } fullWidth={ true } onClick={() => addToCart()} disabled={addingToCart}>
                                { t("poi.validate_to_cart") }
                                { addingToCart && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                            </Button>
                        </Grid>
                    )
                }
            </DialogContent>
        </Dialog>
    )
};

export default React.memo(PoiCardMobilePrices);