import React from "react";
import { connect } from "react-redux";
import { WithRouterProps, withRouter } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import { areStepsEqual } from "./utils/areStepsEqual";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    route: any,
    steps: AppState['itinerary']['itinerary_list'],
    inputs: AppState['itinerarySlice']['stepsInputs'],
    tripStartDate: AppState['trip']['start_date'],
    tripEndDate: AppState['trip']['end_date'],
    isImportingTexts: AppState['itinerarySlice']['isImportingTexts'],
} & WithTranslation & WithRouterProps

export const ItinerarySavingRouteBlock = withRouter(
    withTranslation()(
        connect((state: AppState) => ({
            steps: state.itinerary.itinerary_list,
            inputs: state.itinerarySlice.stepsInputs,
            tripStartDate: state.trip.start_date,
            tripEndDate: state.trip.end_date,
            isImportingTexts: state.itinerarySlice.isImportingTexts
        }))(
            class ItinerarySavingRouteBlock extends React.Component<Props> {
                private isDataSaved(props: Props): boolean {
                    if (
                        props.inputs.length !== props.steps.length ||
                        !props.inputs.every((item, index) => {
                            const step = props.steps[index];
            
                            if (step) {
                                return areStepsEqual(item, step);
                            }
            
                            return false;
                        }) ||
                        props.isImportingTexts
                    ) {
                        return false;
                    }
                    return true;
                }

                public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
                    window.onbeforeunload = () => {
                        if (!this.isDataSaved(nextProps)) {
                            return nextProps.t('itinerary.unsaved-data');
                        }
                    };
                    return false;
                }

                public componentDidMount() {
                    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this));
                }

                public routerWillLeave() {
                    if (!this.isDataSaved(this.props)) {
                        return this.props.t('itinerary.unsaved-data');
                    }
                }

                public render() {
                    return null;
                }
            }
        )
    )
);
