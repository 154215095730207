import GetCookie from "../../Common/Functions/GetCookie";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";

const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

function callback(request_nb, response_nb, results, comps, search, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user) {
    if (request_nb === response_nb) {
        let copy_transfers = [];
        results.map((el) => {
        	el.transfers.map((transfers) => {
        		let tmp_transfers = Object.assign({}, transfers);
        		tmp_transfers.provider_id = el.provider_id;
        		tmp_transfers.custom_provider = el.custom_provider;
        		tmp_transfers.provider_logo = el.provider_logo;
        		tmp_transfers.provider_name = el.provider_name;
        		copy_transfers.push(tmp_transfers);
        	});
        });
        copy_transfers = copy_transfers.sort((a, b) => {
        	return parseFloat(a.price[GetPricesId(a.price, user ? user.client : null, user)].selling_price) - parseFloat(b.price[GetPricesId(b.price, user ? user.client : null, user)].selling_price);
        });
        setVehicleResults(copy_transfers);
        setArrivalDate(search.start_date);
        setGroups([comps]);
        setIsLoading(false);
        setIsForm(false);
    }
}

function transfersRequest(search, comps, transfers_list, trip_id, provider_list, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, headers, t, language, user) {
    const { origin_index, dest_index } = search;
    let request_nb = 1;
    let response_nb = 0;
    let origin_hour = search.origin_hour;
    if (search.origin_hour === t("global.noon")) {
	    origin_hour = "12h00"; 
    } else if (search.origin_hour === t("global.midnight")) {
	    origin_hour = "00h00"; 
    }
    origin_hour = origin_hour.replace("h", ":");
    const type_def = {
        1: 'accommodation',
        2: 'airport',
        3: 'city',
        4: 'port',
        5: 'station'
    };
    let type1 = typeof search.origin_type === 'number' ? type_def[search.origin_type] : search.origin_type;
    let type2 = typeof search.dest_type === 'number' ? type_def[search.dest_type] : search.dest_type;
    let results = [];
    let request1 = {
        arrival_date: moment(search.start_date).format("YYYY-MM-DD") + "T" + origin_hour,
        comps: comps.id,
        trip: trip_id,
        start_destination: search.origin_id,
        end_destination: search.dest_id,
        custom_provider: true
    };
    const promises = [];

    promises.push(
        new Promise((resolve) => {
            $.ajax({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/transfers/transfer_prices/`,
                data: request1,
                success: function (data1) {
                    response_nb++;
                    results = results.concat(data1);
                    callback(request_nb, response_nb, results, comps, search, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user);
                    resolve();
                },
                error: function (error) {
                    console.log(error);
                    response_nb++;
                    callback(request_nb, response_nb, results, comps, search, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user);
                    resolve();
                }
            });
        })
    );
    provider_list.map((provider) => {
        request_nb++;
	    let request2 = {
	    	arrival_date: moment(search.start_date).format("YYYY-MM-DD") + "T" + origin_hour,
	    	comps: comps.id,
	    	trip: trip_id,
	    	// start_destination: search.origin_id,
	        // end_destination: search.dest_id,
            custom_provider: false,
	    	provider_id: provider.provider.id,
	    	//location_code_pickup: type1 !== "city" ? transfers_list[origin_index].location_code_pickup : "",
	    	//location_code_return: type2 !== "city" ? transfers_list[origin_index].destination[dest_index].location_code_return : "",
            pickup_type: type1 === "accommodation" ? "Hotel" : type1,
		    return_type: type2 === "accommodation" ? "Hotel" : type2,
	    	country_code: search.country_code,
	    	language: language
	    };
        // if (type1 !== "city") {
            request2.location_code_pickup = search.origin_location_code;
        // }
        // if (type2 !== "city") {
            request2.location_code_return = search.dest_location_code;
        // }
        promises.push(
            new Promise((resolve) => {
                $.ajax({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/transfers/transfer_prices/`,
                    data: request2,
                    success: function (data2) {
                        response_nb++;
                        results = results.concat(data2);
                        callback(request_nb, response_nb, results, comps, search, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user);
                        resolve();
                    },
                    error: function (error) {
                        console.log(error);
                        response_nb++;
                        callback(request_nb, response_nb, results, comps, search, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user);
                        resolve();
                    }
                });
            })
        );
    });
    return Promise.all(promises);
}

export default function (traveler_groups, groups, pass_check, headers, providers, search, transfers_list, iti_dates, trip_id, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, user, language, t) {
    const { origin_index, dest_index } = search;
    let provider_list = providers.filter((el) => {
		if (!['visiteurs'].includes(quotation_code) || user.client_full.type !== 2) {
			return el.enabled_content_types.includes(4);
		} else {
			return el.enabled_content_types.includes(4) && el.provider.code !== 'hotelbeds';
		}
    });
    return new Promise((resolve, reject) => {
        if (pass_check) {
            //if (new_group) {
            let new_arr = [];
            groups[0].travelers_list.map((el) => {
                new_arr.push(el.id);
            });
            let request = {
                travelers: new_arr
            };
            $.ajax({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/`,
                data: JSON.stringify(request),
                success: function (data) {
                    transfersRequest(
                        search,
                        data,
                        transfers_list,
                        trip_id, provider_list,
                        setVehicleResults,
                        setArrivalDate,
                        setGroups,
                        setIsForm,
                        setIsLoading,
                        headers,
                        t,
                        language,
                        user
                    ).then(resolve).catch(reject);
                },
                error: function (error) {
                    console.log(error);
                    reject(error);
                }
            });
            //}
            //else {
            //transfersRequest(search, current_group, transfers_list, trip_id, provider_list, setVehicleResults, setArrivalDate, setGroups, setIsForm, setIsLoading, headers, t, language, user)
            //}
        } else {
            resolve();
        }
    });
}
