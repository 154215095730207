import React, { Fragment, useState, useEffect, version } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AddIcon from '@material-ui/icons/Add';

import { useSnackbar } from "notistack";
import axios from "axios";

import i18n from "../../i18n.jsx";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import SetCookie from "../Common/Functions/SetCookie";
import GetTrip from "../Base/Functions/GetTrip";
import { SetTripInfo } from "../../Actions/Menu.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SetItineraryTypeDetails } from "../../Actions/ItineraryType.js";

const useStyles = makeStyles(theme => ({
    blueButton: {
        backgroundColor: "#2196F3",
        color: "white",
        boxShadow: 'none'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        //marginLeft: "-232px",
        right: "16px",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 16px !important"
    },
    inputStepper: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: 12
        },
        "& .mui-jss-MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 20
        }
    },
    customSize: {
        width: 41,
        height: 41,
        marginTop: 4,
        boxShadow: 'none'
    },
    italic: {
        fontStyle: "italic",
        marginBottom: 8
    }
}));

const AddNewVersion = ({ setIsOpen, isOpen, data_trip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(store => store.user.user);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const all_data = useSelector(store => store.trip.all_data);
    const displayed_version = to_show_version !== null ? to_show_version : GetCookie("trip_id_version");
    const [value, setValue] = useState("");
    const [version_nb, setVersionNb] = useState(1);
    const [versionArray, setversionArray] = useState([]);
    const [spinVersion, setSpinVersion] = useState(false);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const trip_id = useSelector((store) => store.trip.trip_id);
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check && trip_id !== null) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/`
            }).then(function (response) {
                console.log('data:', response);
                let sort_versions = response.data.results;
                sort_versions.sort((a, b) => {
                    return a.id - b.id;
                });
                setversionArray(sort_versions);
                setVersionNb(response.data.count);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                //enqueueSnackbar(error, {
                //variant: "error"
                //});
            });
        }
    }, [trip_id]);
    const resetCookie = (data_res, id) => {
    	let user_token = GetCookie("token");
    	let id_user = GetCookie("id_user");
    	let trip_token = GetCookie("trip_token");
        let client_user = GetCookie("client_user");
        const crmCookies = {
            crm_token: GetCookie("crm_token"),
            crm_id_user: GetCookie("crm_id_user"),
            crm_client_user: GetCookie("crm_client_user"),
            isFromCrm: GetCookie("isFromCrm")
        };

    	//Delete the cookie
    	let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                let p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/");
                    p.pop();
                }
                d.shift();
            }
        }
    	SetCookie("trip_id", id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("trip_id_version", data_res.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        if (crmCookies.crm_token) {
            for (const key in crmCookies) {
                SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
        }
        dispatch({ type: "FLUSH_REMOVE_STATE" });
        dispatch({
            type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
            payload: {
                show_language: false,
                language: i18n.language
            }
        });
        GetTrip(user, dispatch, t);
    };

    const createNewVersion = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let id = GetCookie("trip_id");
        let version = displayed_version;

        if (pass_check) {
            setSpinVersion(true);
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/copy/?copy_booked_product=true`
            }).then(function (response) {
                console.log('data:', response);
                let request = {
                    name: (value === "" ? null : value)
                };
                if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code) && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV") {
                    request.status_contract = "CONFIRMED";
                    request.status_modification = "AMENDMENT";
                    request.status_booking_progress = "IN_PROGRESS";
                }
                if (itinerary_type_details_data !== null && itinerary_type_details_data.variant_name !== undefined && all_data !== null) {
                    let variantName = [...itinerary_type_details_data.variant_name];
                    let current_variant_name = itinerary_type_details_data.variant_name.filter(variant => variant.version === parseInt(version));
                    current_variant_name.forEach((variant) => {
                        variantName.push({
                            id: variant.id,
                            name: variant.name !== undefined ? variant.name : undefined,
                            version: response.data.id
                        });
                    });
                    let url = `${API_HREF}client/${window.id_owner}/${all_data.circuit_type ? 'circuits' : 'packages'}/${all_data.circuit_type ? all_data.circuit : all_data.package}/`;
                    axios({
                        method: 'PATCH',
                        headers: headers,
                        url: url,
                        data: {
                            variant_name: variantName
                        }
                    }).then((response) => {
                        dispatch(SetItineraryTypeDetails(response.data));
                        onClose();
                    }).catch((error) => {
                        console.log('error:', error.response);
                    });
                }
                axios({
                    method: "PATCH",
                    headers: headers,
                    data: JSON.stringify(request),
                    url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${response.data.trip.current_version}/`
                }).then(function (response1) {
                    enqueueSnackbar(t("header.success_to_create_new_version"), {
                        variant: "success"
                    });
                    if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code)) {
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${id}/`,
                            headers: headers,
                            data: {
                                current_version: version
                            }
                        }).then(function (response) {
                            setSpinVersion(false);
                            resetCookie(response1.data, id);
                            dispatch({
                                type: "MENU_TO_SHOW_VERSION",
                                payload: {
                                    to_show_version: response1.data.id
                                }
                            });
                            dispatch(SetTripInfo(response.data));
                            handleOpen();
                        }).catch(function (error) {
                            setSpinVersion(false);
                            console.log(error.response);
                        });
                    } else {
                        setSpinVersion(false);
                        resetCookie(response1.data, id);
                    }
                }).catch(function (error) {
                    //TODO: show snackbar error
                    setSpinVersion(false);
                    console.log('error:', error);
                    enqueueSnackbar(t("header.failed_to_create_new_version"), {
                        variant: "error"
                    });
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                setSpinVersion(false);
                console.log('error:', error);
                enqueueSnackbar(t("header.failed_to_create_new_version"), {
                    variant: "error"
                });
            });
        }
    };

    const cancel = () => {
        setValue("");
        handleOpen();
    };

    const handleOpen = () => {
        if (isOpen === null || isOpen !== "addNewVersion") {
            setIsOpen("addNewVersion");
        } else {
            setIsOpen(null);
        }
    };
    return (
        <Fragment>
            <Tooltip title={ t("header.save_new_version") } >
                <Fab className={`${classes.blueButton} ${classes.customSize}`} elevation={0} disableRipple={true}
                    onClick={() => {
                        if (!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code)) {
                            if (user.client_full.type !== 2) {
                                if (data_trip.status === "BOOKED" || data_trip.status === "ONREQUEST") {
                                    enqueueSnackbar(t('header.version_booked_status_to'), { variant: "warning" });
                                } else {
                                    handleOpen();
                                }
                            } else if (data_trip.status === "BOOKED" || data_trip.status === "ONREQUEST" || data_trip.status === "AGENCY_MOD") {
                                enqueueSnackbar(t('header.version_booked_status'), { variant: "warning" });
                            } else {
                                handleOpen();
                            }
                        } else if (data_trip.status_modification !== "AMENDMENT") {
                            handleOpen();
                        } else {
                            enqueueSnackbar(t('header.version_booked_status_amendment'), { variant: "warning" });
                        }
                    }}>
                    <AddIcon/>
                </Fab>
            </Tooltip>
            {
                isOpen === "addNewVersion" && (
                    <ClickAwayListener onClickAway={handleOpen}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <Fragment>
                                    <DialogTitle>{t("header.save_version_nb") + (version_nb + 1)}</DialogTitle>
                                    <DialogContent>
                                        <div className={classes.italic}>{t("header.custom_version_name")}</div>
                                        <TextField
                                            variant={"outlined"}
                                            fullWidth
                                            autoFocus={isOpen === "addNewVersion"}
                                            placeholder={t("header.version_name")}
                                            className={classes.inputStepper}
                                            onChange={(e) => {
                                                setValue(e.target.value);
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container alignItems="center" justify="flex-end">
                                            {
                                                versionArray.map((version_obj, version_index) => {
                                                    let version_new_index = version_index + 1;
                                                    if (version_obj.id === parseInt(displayed_version)) {
                                                        return (
                                                            <Grid item xs={6} key={`version-${version_obj.id}`} style={{ fontSize: 12 }}>
                                                                { t("header.version_copied") + " : "}
                                                                { t("menu.trip_list.nb_version") + version_new_index}
                                                                { version_obj.name !== null && (" (" + version_obj.name + ")")}
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                            <Grid item xs={3}>
                                                <Button onClick={cancel}>{t("accommodation.cancel")}</Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {
                                                    spinVersion ? (
                                                        <CircularProgress size={ 17 }/>
                                                    ) : (
                                                        <Button
                                                            variant={"contained"}
                                                            className={ classes.orangeButton }
                                                            onClick={createNewVersion}
                                                        >
                                                            {t("global.validate")}
                                                        </Button>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Fragment>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};
export default React.memo(AddNewVersion);
