import { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { flatten } from "lodash";
import GetCookie from "../Common/Functions/GetCookie";
import { ProviderContext } from "./utils/providerContext";
import { useItineraryContent } from "../Itinerary/network/itineraryContent";
import { useItineraryContentFetch } from "./network/itineraryContentFetch";
import { useAutoloadContents } from "./utils/autoloadContents";
import { useItineraryContentSetupSaveRequestsQueue } from "./network/itineraryContentSetupSaveRequestsQueue";
import { isContentStepEqual } from "./utils/isContentStepEqual";
import { SaveRequestsQueue } from "../Itinerary/utils/saveRequestsQueue";
import { setTextsSaveQueue } from "./redux/cartConstructionReducer";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { ItineraryByDay } from "../Itinerary/objects/itineraryByDay";
import { ItineraryByStep } from "../Itinerary/objects/itineraryByStep";
import { ItineraryContents } from "./objects/itineraryContents";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    loading?: boolean
}

export function CartConstructionContentsFormSync(props: Props): null {
    const providerContext = useContext(ProviderContext);
    const versionString = GetCookie("trip_id_version");
    const version = providerContext.module ?
        providerContext.version :
        (
            versionString ? parseInt(versionString) : -1
        );
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const textsSaveQueue = useSelector((state: AppState) => state.cartConstruction.textsSaveQueue);
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const syncedItinerarySteps = useItineraryContent(version);
    const currentContent = useMemo(() => {
        return itineraryContent?.state === 'success' ?
            itineraryContent.data :
            null;
    }, [
        itineraryContent?.state,
        itineraryContent?.state === 'success' ? itineraryContent.data : null
    ]);
    const shouldSync = useMemo(() => {
        if (!props.loading && currentContent) {
            return currentContent &&
                (
                    currentContent.content.length !== syncedItinerarySteps.length ||
                    !syncedItinerarySteps.every((item, index) => {
                        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const oldValue = currentContent.content[index]!;
                        return isContentStepEqual(
                            item,
                            { mode: currentContent.mode, content: oldValue } as ItineraryContentStep
                        );
                    })
                );
        }
        return false;
    }, [
        props.loading,
        currentContent,
        syncedItinerarySteps
    ]);
    const previousIsByStep = useRef<boolean | null>(null);
    const autoloadContents = useAutoloadContents();

    useItineraryContentFetch(version);
    useItineraryContentSetupSaveRequestsQueue();

    useEffect(() => {
        const queue = new SaveRequestsQueue<ItineraryByDay | ItineraryByStep, ItineraryContentStep>();
        dispatch(setTextsSaveQueue(queue));
        return () => {
            queue.release();
            dispatch(setTextsSaveQueue(null));
        };
    }, []);

    useEffect(() => {
        if (shouldSync && textsSaveQueue) {
            textsSaveQueue.add(syncedItinerarySteps, 'cart');
            textsSaveQueue.lock();
        } else if (!shouldSync && textsSaveQueue) {
            (async () => {
                let result: ItineraryContentStep[] = [...syncedItinerarySteps];

                const changed = await autoloadContents(
                    {
                        mode: syncedItinerarySteps[0]?.mode ?? 'by-day',
                        content: syncedItinerarySteps.map((item) => {
                            return item.content;
                        })
                    } as ItineraryContents,
                    {
                        locale,
                        onChangeItemTitle(id, value) {
                            result = result.map((item) => {
                                if (item.content.id === id) {
                                    const localizationIndex = item.content.localization.findIndex((localization) => {
                                        return localization.locale === locale;
                                    });
                                    return {
                                        mode: item.mode,
                                        content: {
                                            ...item.content,
                                            title: value,
                                            localization: localizationIndex >= 0 ?
                                                [
                                                    ...item.content.localization.slice(0, localizationIndex),
                                                    {
                                                        ...item.content.localization[localizationIndex],
                                                        title: value
                                                    },
                                                    ...item.content.localization.slice(localizationIndex + 1)
                                                ] :
                                                item.content.localization.concat([
                                                    {
                                                        locale,
                                                        long_description: item.content.long_description,
                                                        short_description: item.content.short_description,
                                                        title: value
                                                    }
                                                ])
                                        }
                                    } as typeof item;
                                }
                                return item;
                            });
                        },
                        onChangeItemText(id, texts) {
                            result = result.map((item) => {
                                if (item.content.id === id) {
                                    const localizationIndex = item.content.localization.findIndex((localization) => {
                                        return localization.locale === locale;
                                    });
                                    return {
                                        mode: item.mode,
                                        content: {
                                            ...item.content,
                                            short_description: '',
                                            long_description: texts.join('\n'),
                                            localization: (
                                                localizationIndex >= 0 ?
                                                    [
                                                        ...item.content.localization.slice(0, localizationIndex),
                                                        {
                                                            ...item.content.localization[localizationIndex],
                                                            long_description: texts.join('\n')
                                                        },
                                                        ...item.content.localization.slice(localizationIndex + 1)
                                                    ] :
                                                    item.content.localization.concat([
                                                        {
                                                            locale,
                                                            title: item.content.title,
                                                            short_description: item.content.short_description,
                                                            long_description: texts.join('\n')
                                                        }
                                                    ])
                                            )
                                        }
                                    } as typeof item;
                                }
                                return item;
                            });
                        },
                        onChangeItemLongText(id, texts) {
                            result = result.map((item) => {
                                if (item.content.id === id) {
                                    const localizationIndex = item.content.localization.findIndex((localization) => {
                                        return localization.locale === locale;
                                    });
                                    return {
                                        mode: item.mode,
                                        content: {
                                            ...item.content,
                                            short_description: texts.join('\n'),
                                            localization: (
                                                localizationIndex >= 0 ?
                                                    [
                                                        ...item.content.localization.slice(0, localizationIndex),
                                                        {
                                                            ...item.content.localization[localizationIndex],
                                                            short_description: texts.join('\n')
                                                        },
                                                        ...item.content.localization.slice(localizationIndex + 1)
                                                    ] :
                                                    item.content.localization.concat([
                                                        {
                                                            locale,
                                                            title: item.content.title,
                                                            long_description: item.content.long_description,
                                                            short_description: texts.join('\n')
                                                        }
                                                    ])
                                            )
                                        }
                                    } as typeof item;
                                }
                                return item;
                            });
                        },
                        onChangeItemPictures(id, pictures) {
                            result = result.map((item) => {
                                if (item.content.id === id) {
                                    return {
                                        mode: item.mode,
                                        content: {
                                            ...item.content,
                                            pictures
                                        }
                                    } as typeof item;
                                }
                                return item;
                            });
                        }
                    }
                );

                if (changed && textsSaveQueue) {
                    textsSaveQueue.add(
                        result,
                        'cart'
                    );
                    textsSaveQueue.lock();
                }
            })();
        }
    }, [shouldSync, syncedItinerarySteps, textsSaveQueue]);

    useEffect(() => {
        if (
            previousIsByStep.current === true &&
            tripAllData?.is_by_day_view &&
            !tripAllData?.circuit &&
            syncedItinerarySteps.findIndex((item) => {
                return item.mode === 'by-day' &&
                    item.content.day.length > 1;
            }) >= 0
        ) {
            textsSaveQueue?.add(
                flatten(
                    syncedItinerarySteps.map((item): ItineraryContentStep[] => {
                        const content = item.content as ItineraryByDay;
                        return content.day.map((day, index): ItineraryContentStep => {
                            return {
                                mode: 'by-day',
                                content: {
                                    ...content,
                                    id: index === 0 ?
                                        content.id :
                                        undefined,
                                    interval: content.interval.map((item) => {
                                        return {
                                            ...item,
                                            localization: item.localization.map((item) => {
                                                return {
                                                    ...item,
                                                    id: undefined
                                                };
                                            }),
                                            id: undefined
                                        };
                                    }),
                                    day: [day]
                                }
                            };
                        });
                    })
                ),
                'cart'
            );
            textsSaveQueue?.lock();
            previousIsByStep.current = false;
        }

        if (tripAllData?.is_by_day_view === false) {
            previousIsByStep.current = true;
        }
    }, [
        tripAllData?.is_by_day_view,
        tripAllData?.circuit,
        syncedItinerarySteps,
        textsSaveQueue
    ]);

    return null;
}
