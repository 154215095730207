import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
    findLast,
    groupBy,
    isNumber,
    mapValues
} from "lodash";
import { CartConstructionProductsPackages } from "./CartConstructionProductsPackages";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { useSendProviderQuotationDemand } from "./utils/sendProviderQuotationDemand";
import { useCartSummarizedProducts } from "./utils/cartSummarizedProducts";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import GetTrip from "../Base/Functions/GetTrip";
import { AppState } from "../../Reducers/Reducers";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    onClose: () => void
}

export function CartSendProviderQuotationDemandModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const allDataTrip = useSelector((state: AppState) => state.trip.all_data);
    const user = useSelector((state: AppState) => state.user.user);
    const [selected, setSelected] = useState<{[stackNumber: number]: boolean}>({});
    const [loading, setLoading] = useState(false);
    const [allowProviderEditTexts, setAllowProviderEditTexts] = useState(false);
    const products = useCartSummarizedProducts();
    const packages = useMemo(() => {
        return groupBy(
            products.filter((item) => {
                return isProductPackaged({
                    product: {
                        stack_number: item.stackNumber,
                        stack_info_id: item.stackInfoId
                    },
                    stackInfos: trip?.stack_info ?? null
                });
            }),
            (item) => `${item.stackNumber ?? ''}-${item.stackInfoId ?? ''}`
        );
    }, [products]);
    const showError = useShowError();
    const send = useSendProviderQuotationDemand({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(trip) {
            enqueueSnackbar(
                t('cart-material.cart-construction-package-quotation-request-success'),
                { variant: 'success' }
            );
            dispatch({
                type: 'TRIP_SET_ALL_DATA',
                payload: {
                    data: trip
                }
            });
            GetTrip(
                user,
                dispatch,
                t
            );
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const providerContext = useContext(ProviderContext);
    const onSend = () => {
        const packages = Object.keys(selected).map((key) => {
            const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
            const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
            const provider = findLast(
                trip?.prices_flight ?? [],
                (item) => {
                    return isProductSamePackage(
                        item,
                        {
                            stack_number: stackNumber,
                            stack_info_id: stackInfoId
                        }
                    );
                }
            )?.provider ?? findLast(
                trip?.prices_terrestrial ?? [],
                (item) => {
                    return isProductSamePackage(
                        item,
                        {
                            stack_number: stackNumber,
                            stack_info_id: stackInfoId
                        }
                    );
                }
            )?.provider;

            if (isNumber(provider) && isNumber(stackNumber)) {
                return {
                    stackNumber,
                    providerId: provider
                };
            }

            return null;
        }).filter((item): item is NonNullable<typeof item> => !!item);
        send(
            parseInt(GetCookie('trip_id_version') ?? '-1'),
            packages,
            allowProviderEditTexts
        );
    };

    const onClose = () => {
        if (!loading) {
            props.onClose();
        }
    };

    useEffect(() => {
        setSelected(
            mapValues(
                packages,
                () => false
            )
        );
    }, [packages]);

    useEffect(() => {
        setAllowProviderEditTexts(allDataTrip?.provider_allowed_to_edit_byday ?? false);
    }, [allDataTrip]);

    return (
        <Dialog
            open
            onClose={onClose}
            scroll="body"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h6" fontWeight="bold">
                        {t('cart-material.cart-construction-package-quotation-request')}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allowProviderEditTexts}
                            onChange={(_, checked) => setAllowProviderEditTexts(checked)}
                        />
                    }
                    label={t('cart-material.provider-quotation-allow-provider-texts-edit')}
                />
                <CartConstructionProductsPackages
                    cart={packages}
                    selection={selected}
                    onChangeSelection={setSelected}
                    itemActions={(item) => {
                        if (providerContext.module) {
                            return null;
                        }
                        return (
                            <Stack
                                alignSelf="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <CartConstructionProductsTableItemProviderQuotationStatus
                                    item={item}
                                />
                                <CartPackageItemChangeProviderQuotationStatusButtons item={item} />
                            </Stack>
                        );
                    }}
                    selectable
                    disablePrices
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={loading}
                >
                    {t('shared.cancel')}
                </Button>
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    onClick={onSend}
                    disabled={
                        Object.values(selected).filter((item) => item).length === 0
                    }
                >
                    {t('shared.send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
