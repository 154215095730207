import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    ClickAwayListener,
    Fab,
    Grow,
    Stack,
    Tooltip,
    svgIconClasses
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { RegisteredBlocks } from "../Menu/MaterialTripList/MailVisualEditor/utils/registered-blocks";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    extraActions?: {
        type: string,
        label: string,
        icon: JSX.Element
    }[],
    fromProducts?: boolean,
    onAdd: (type: string) => void
}

export function CartConstructionContentsFormItemFloatingAddButton(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [openItems, setOpenItems] = useState(false);
    const providerContext = useContext(ProviderContext);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const actions = RegisteredBlocks.map((item) => {
        return {
            type: item.type,
            label: item.label,
            icon: item.icon
        };
    }).filter((item) => {
        if (quotationCode === 'verdie' && props.fromProducts) {
            return false;
        }
        if (quotationCode === 'verdie') {
            return item.type === 'text';
        }
        if (providerContext.module) {
            return [
                'text',
                'image'
            ].includes(item.type);
        }
        return [
            'text',
            'image',
            'spacer',
            'divider'
        ].includes(item.type);
    }).concat(props.extraActions ?? []).map((item) => {
        return (
            <Tooltip key={item.type} title={t(item.label)}>
                <Fab
                    size="medium"
                    onClick={() => onAdd(item.type)}
                    sx={{
                        width: 36,
                        height: 36,
                        minHeight: 0,
                        zIndex: 1,
                        fontSize: '1.25rem',
                        pointerEvents: open ?
                            'all' :
                            'none',
                        [`& .${svgIconClasses.root}`]: {
                            fontSize: 'inherit'
                        }
                    }}
                >
                    {item.icon}
                </Fab>
            </Tooltip>
        );
    });

    const onAdd = (type: string) => {
        props.onAdd(type);
        setOpen(false);
    };

    const onToggle = () => {
        setOpen((state) => !state);
    };

    const onClickAway = () => {
        setOpenItems(false);
        setTimeout(() => setOpen(false), (actions.length - 1) * 200);
    };

    useEffect(() => {
        setOpenItems(open);
    }, [open]);

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    display: open ?
                        'block !important' :
                        undefined,
                    pointerEvents: 'none',
                    backgroundColor: open ? '#fff' : undefined,
                    marginLeft: -1,
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    paddingLeft: 1,
                    paddingRight: 1,
                    zIndex: 2,
                    borderRadius: 4
                }}
                className="floating-actions-button"
            >
                <Stack direction="row" spacing={1.5}>
                    <Tooltip
                        title={
                            providerContext.module && !open ?
                                t('cart-material.provider-add-text-or-image') :
                                ''
                        }
                    >
                        <Fab
                            size="medium"
                            color="primary"
                            onClick={onToggle}
                            sx={{
                                width: 36,
                                height: 36,
                                minHeight: 0,
                                zIndex: 1,
                                fontSize: '1.5rem',
                                pointerEvents: 'all'
                            }}
                        >
                            {
                                open ?
                                    <Close fontSize="inherit" /> :
                                    <Add fontSize="inherit" />
                            }
                        </Fab>
                    </Tooltip>
                    {
                        actions.map((action, index, array) => (
                            <Grow
                                key={action.key}
                                in={openItems}
                                timeout={{
                                    enter: index * 200,
                                    exit: (array.length - index - 1) * 200
                                }}
                            >
                                {action}
                            </Grow>
                        ))
                    }
                </Stack>
            </Box>
        </ClickAwayListener>
    );
}
