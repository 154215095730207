import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { flatten } from "lodash";
import { CartTransferCard } from "../../CartMaterial/CartTransferCard";
import { CartManualProductCard } from "../../CartMaterial/CartManualProductCard";
import { CartCarCard } from "../../CartMaterial/CartCarCard";
import { CartAccommodationCard } from "../../CartMaterial/CartAccommodationCard";
import { CartPoiCard } from "../../CartMaterial/CartPoiCard";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { useProductDays } from "../../CartMaterial/utils/productDays";
import { usePackagedProducts } from "../../CartMaterial/utils/packagedProducts";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { AppState } from "../../../Reducers/Reducers";

export function useProviderProducts(
    products: ReturnType<typeof usePackagedProducts>[string],
    checkCounterOffer = true
) {
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const steps = useMemo(() => {
        return itinerary.filter((step) => {
            return step.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);
    const accommodations = useMemo(() => {
        return products.accommodations.map((item) => {
            return item.accommodation;
        });
    }, [products]);
    const getDays = useProductDays();

    const productsWithSteps: {
        step: Itinerary | undefined,
        content: React.ReactNode
    }[] = !trip?.is_by_day_view ?
            products.transfers.map((item) => {
                const counterOffer = products.transfers.find((product) => {
                    return item.transfer.id === product.transfer.counter_offer_of;
                });
                const step = steps.find((step) => {
                    return window.moment.utc(item.transfer.start_date).startOf('day').isBetween(
                        window.moment.utc(step.start_date).startOf('day'),
                        window.moment.utc(step.end_date).startOf('day'),
                        'days',
                        '[]'
                    ) ||
                        window.moment.utc(item.transfer.end_date).startOf('day').isBetween(
                            window.moment.utc(step.start_date).startOf('day'),
                            window.moment.utc(step.end_date).startOf('day'),
                            'days',
                            '[]'
                        )
                });

                if (!checkCounterOffer) {
                    return {
                        step,
                        content: item.type === 'normal' ?
                            <CartTransferCard
                                key={item.transfer.id}
                                transfer={item.transfer}
                            /> :
                            <CartManualProductCard
                                key={item.transfer.id}
                                product={item.transfer}
                            />
                    };
                }

                if (counterOffer) {
                    return {
                        step,
                        content: (
                            <CounterOfferLayout
                                key={item.transfer.id}
                                product={
                                    item.type === 'normal' ?
                                        <CartTransferCard
                                            transfer={item.transfer}
                                        /> :
                                        <CartManualProductCard
                                            product={item.transfer}
                                        />
                                }
                                counterOffer={
                                    counterOffer.type === 'normal' ?
                                        <CartTransferCard
                                            transfer={counterOffer.transfer}
                                        /> :
                                        <CartManualProductCard
                                            product={counterOffer.transfer}
                                        />
                                }
                            />
                        )
                    };
                }

                if (!item.transfer.counter_offer_of) {
                    return {
                        step,
                        content: item.type === 'normal' ?
                            <CartTransferCard
                                key={item.transfer.id}
                                transfer={item.transfer}
                            /> :
                            <CartManualProductCard
                                key={item.transfer.id}
                                product={item.transfer}
                            />
                    }
                }

                return {
                    step: undefined,
                    content: []
                };
            }).concat(
                products.cars.map((item, index) => {
                    const counterOfferIndex = products.cars.findIndex((product) => {
                        return item.car.id === product.car.counter_offer_of;
                    });
                    const counterOffer = products.cars.find((product) => {
                        return item.car.id === product.car.counter_offer_of;
                    });
                    const step = steps.find((step) => {
                        return window.moment.utc(item.car.start_date).isBetween(
                            window.moment.utc(step.start_date).startOf('day'),
                            window.moment.utc(step.end_date).startOf('day'),
                            'days',
                            '[]'
                        ) ||
                            window.moment.utc(item.car.end_date).isBetween(
                                window.moment.utc(step.start_date).startOf('day'),
                                window.moment.utc(step.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.start_date).isBetween(
                                window.moment.utc(item.car.start_date).startOf('day'),
                                window.moment.utc(item.car.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.end_date).isBetween(
                                window.moment.utc(item.car.start_date).startOf('day'),
                                window.moment.utc(item.car.end_date).startOf('day'),
                                'days',
                                '[]'
                            );
                    });

                    if (!checkCounterOffer) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartCarCard
                                    key={item.car.id}
                                    index={index}
                                    car={item.car}
                                /> :
                                <CartManualProductCard
                                    key={item.car.id}
                                    product={item.car}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            step,
                            content: (
                                <CounterOfferLayout
                                    key={item.car.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartCarCard
                                                index={index}
                                                car={item.car}
                                            /> :
                                            <CartManualProductCard
                                                product={item.car}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartCarCard
                                                index={counterOfferIndex}
                                                car={counterOffer.car}
                                            /> :
                                            <CartManualProductCard
                                                product={counterOffer.car}
                                            />
                                    }
                                />
                            )
                        };
                    }

                    if (!item.car.counter_offer_of) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartCarCard
                                    key={item.car.id}
                                    index={index}
                                    car={item.car}
                                /> :
                                <CartManualProductCard
                                    key={item.car.id}
                                    product={item.car}
                                />
                        }
                    }

                    return {
                        step: undefined,
                        content: []
                    }
                })
            ).concat(
                products.accommodations.map((item) => {
                    const counterOffer = products.accommodations.find((product) => {
                        return item.accommodation.id === product.accommodation.counter_offer_of;
                    });
                    const step = steps.find((step) => {
                        return window.moment.utc(item.accommodation.start_date).startOf('day').isBetween(
                            window.moment.utc(step.start_date).startOf('day'),
                            window.moment.utc(step.end_date).startOf('day'),
                            'days',
                            '[]'
                        ) ||
                            window.moment.utc(item.accommodation.end_date).startOf('day').isBetween(
                                window.moment.utc(step.start_date).startOf('day'),
                                window.moment.utc(step.end_date).startOf('day'),
                                'days',
                                '[]'
                            );
                    });

                    if (!checkCounterOffer) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="accommodation"
                                    accommodation={item.accommodation}
                                /> :
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="manual"
                                    accommodation={item.accommodation}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            step,
                            content: (
                                <CounterOfferLayout
                                    key={item.accommodation.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartAccommodationCard
                                                type="accommodation"
                                                accommodation={item.accommodation}
                                                {...{ accommodationsList: accommodations }}
                                            /> :
                                            <CartAccommodationCard
                                                type="manual"
                                                accommodation={item.accommodation}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartAccommodationCard
                                                type="accommodation"
                                                accommodation={counterOffer.accommodation}
                                                {...{ accommodationsList: accommodations }}
                                            /> :
                                            <CartAccommodationCard
                                                type="manual"
                                                accommodation={counterOffer.accommodation}
                                            />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.accommodation.counter_offer_of) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="accommodation"
                                    accommodation={item.accommodation}
                                /> :
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="manual"
                                    accommodation={item.accommodation}
                                />
                        }
                    }

                    return {
                        step: undefined,
                        content: []
                    };
                })
            ).concat(
                products.pois.map((item) => {
                    const counterOffer = products.pois.find((product) => {
                        return item.poi.id === product.poi.counter_offer_of;
                    });
                    const step = steps.find((step) => {
                        return window.moment.utc(item.poi.start_date).isBetween(
                            window.moment.utc(step.start_date).startOf('day'),
                            window.moment.utc(step.end_date).startOf('day'),
                            'days',
                            '[]'
                        ) ||
                            window.moment.utc(item.poi.end_date).isBetween(
                                window.moment.utc(step.start_date).startOf('day'),
                                window.moment.utc(step.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.start_date).isBetween(
                                window.moment.utc(item.poi.start_date).startOf('day'),
                                window.moment.utc(item.poi.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.end_date).isBetween(
                                window.moment.utc(item.poi.start_date).startOf('day'),
                                window.moment.utc(item.poi.end_date).startOf('day'),
                                'days',
                                '[]'
                            );
                    });

                    if (!checkCounterOffer) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartPoiCard
                                    key={item.poi.id}
                                    poi={item.poi}
                                /> :
                                <CartManualProductCard
                                    key={item.poi.id}
                                    product={item.poi}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            step,
                            content: (
                                <CounterOfferLayout
                                    key={item.poi.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartPoiCard
                                                poi={item.poi}
                                            /> :
                                            <CartManualProductCard
                                                product={item.poi}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartPoiCard
                                                poi={counterOffer.poi}
                                            /> :
                                            <CartManualProductCard
                                                product={counterOffer.poi}
                                            />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.poi.counter_offer_of) {
                        return {
                            step,
                            content: item.type === 'normal' ?
                                <CartPoiCard
                                    key={item.poi.id}
                                    poi={item.poi}
                                /> :
                                <CartManualProductCard
                                    key={item.poi.id}
                                    product={item.poi}
                                />
                        }
                    }

                    return {
                        step: undefined,
                        content: []
                    }
                })
            ).concat(
                products.manualProducts.map((item) => {
                    const counterOffer = products.manualProducts.find((product) => {
                        return item.id === product.counter_offer_of;
                    });
                    const step = steps.find((step) => {
                        return window.moment.utc(item.start_date).isBetween(
                            window.moment.utc(step.start_date).startOf('day'),
                            window.moment.utc(step.end_date).startOf('day'),
                            'days',
                            '[]'
                        ) ||
                            window.moment.utc(item.end_date).isBetween(
                                window.moment.utc(step.start_date).startOf('day'),
                                window.moment.utc(step.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.start_date).isBetween(
                                window.moment.utc(item.start_date).startOf('day'),
                                window.moment.utc(item.end_date).startOf('day'),
                                'days',
                                '[]'
                            ) ||
                            window.moment.utc(step.end_date).isBetween(
                                window.moment.utc(item.start_date).startOf('day'),
                                window.moment.utc(item.end_date).startOf('day'),
                                'days',
                                '[]'
                            );
                    });

                    if (!checkCounterOffer) {
                        return {
                            step,
                            content: (
                                <CartManualProductCard
                                    key={item.id}
                                    product={item}
                                />
                            )
                        };
                    }

                    if (counterOffer) {
                        return {
                            step,
                            content: (
                                <CounterOfferLayout
                                    key={item.id}
                                    product={
                                        <CartManualProductCard
                                            product={item}
                                        />
                                    }
                                    counterOffer={
                                        <CartManualProductCard
                                            product={counterOffer}
                                        />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.counter_offer_of) {
                        return {
                            step,
                            content: (
                                <CartManualProductCard
                                    key={item.id}
                                    product={item}
                                />
                            )
                        }
                    }

                    return {
                        step: undefined,
                        content: []
                    }
                })
            ) :
            [];
    const productStepIds = flatten(
        productsWithSteps.map((item) => {
            return item.step;
        })
    ).map((step) => {
        return step?.id;
    });

    const productsWithDays: {
        days: number[],
        content: React.ReactNode
    }[] = trip?.is_by_day_view ?
            products.transfers.map((item) => {
                const counterOffer = products.transfers.find((product) => {
                    return item.transfer.id === product.transfer.counter_offer_of;
                });
                const days = getDays(item.transfer.start_date);

                if (!checkCounterOffer) {
                    return {
                        days,
                        content: item.type === 'normal' ?
                            <CartTransferCard
                                key={item.transfer.id}
                                transfer={item.transfer}
                            /> :
                            <CartManualProductCard
                                key={item.transfer.id}
                                product={item.transfer}
                            />
                    };
                }

                if (counterOffer) {
                    return {
                        days,
                        content: (
                            <CounterOfferLayout
                                key={item.transfer.id}
                                product={
                                    item.type === 'normal' ?
                                        <CartTransferCard
                                            transfer={item.transfer}
                                        /> :
                                        <CartManualProductCard
                                            product={item.transfer}
                                        />
                                }
                                counterOffer={
                                    counterOffer.type === 'normal' ?
                                        <CartTransferCard
                                            transfer={counterOffer.transfer}
                                        /> :
                                        <CartManualProductCard
                                            product={counterOffer.transfer}
                                        />
                                }
                            />
                        )
                    };
                }

                if (!item.transfer.counter_offer_of) {
                    return {
                        days,
                        content: item.type === 'normal' ?
                            <CartTransferCard
                                key={item.transfer.id}
                                transfer={item.transfer}
                            /> :
                            <CartManualProductCard
                                key={item.transfer.id}
                                product={item.transfer}
                            />
                    }
                }

                return {
                    days: [],
                    content: []
                };
            }).concat(
                products.cars.map((item, index) => {
                    const counterOfferIndex = products.cars.findIndex((product) => {
                        return item.car.id === product.car.counter_offer_of;
                    });
                    const counterOffer = products.cars.find((product) => {
                        return item.car.id === product.car.counter_offer_of;
                    });
                    const days = getDays(item.car.start_date);

                    if (!checkCounterOffer) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartCarCard
                                    key={item.car.id}
                                    index={index}
                                    car={item.car}
                                /> :
                                <CartManualProductCard
                                    key={item.car.id}
                                    product={item.car}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            days,
                            content: (
                                <CounterOfferLayout
                                    key={item.car.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartCarCard
                                                index={index}
                                                car={item.car}
                                            /> :
                                            <CartManualProductCard
                                                product={item.car}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartCarCard
                                                index={counterOfferIndex}
                                                car={counterOffer.car}
                                            /> :
                                            <CartManualProductCard
                                                product={counterOffer.car}
                                            />
                                    }
                                />
                            )
                        };
                    }

                    if (!item.car.counter_offer_of) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartCarCard
                                    key={item.car.id}
                                    index={index}
                                    car={item.car}
                                /> :
                                <CartManualProductCard
                                    key={item.car.id}
                                    product={item.car}
                                />
                        }
                    }

                    return {
                        days: [],
                        content: []
                    }
                })
            ).concat(
                products.accommodations.map((item) => {
                    const counterOffer = products.accommodations.find((product) => {
                        return item.accommodation.id === product.accommodation.counter_offer_of;
                    });
                    const days = getDays(item.accommodation.start_date);

                    if (!checkCounterOffer) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="accommodation"
                                    accommodation={item.accommodation}
                                /> :
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="manual"
                                    accommodation={item.accommodation}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            days,
                            content: (
                                <CounterOfferLayout
                                    key={item.accommodation.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartAccommodationCard
                                                type="accommodation"
                                                accommodation={item.accommodation}
                                                {...{ accommodationsList: accommodations }}
                                            /> :
                                            <CartAccommodationCard
                                                type="manual"
                                                accommodation={item.accommodation}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartAccommodationCard
                                                type="accommodation"
                                                accommodation={counterOffer.accommodation}
                                                {...{ accommodationsList: accommodations }}
                                            /> :
                                            <CartAccommodationCard
                                                type="manual"
                                                accommodation={counterOffer.accommodation}
                                            />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.accommodation.counter_offer_of) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="accommodation"
                                    accommodation={item.accommodation}
                                /> :
                                <CartAccommodationCard
                                    key={item.accommodation.id}
                                    type="manual"
                                    accommodation={item.accommodation}
                                />
                        }
                    }

                    return {
                        days: [],
                        content: []
                    };
                })
            ).concat(
                products.pois.map((item) => {
                    const counterOffer = products.pois.find((product) => {
                        return item.poi.id === product.poi.counter_offer_of;
                    });
                    const days = getDays(item.poi.start_date);

                    if (!checkCounterOffer) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartPoiCard
                                    key={item.poi.id}
                                    poi={item.poi}
                                /> :
                                <CartManualProductCard
                                    key={item.poi.id}
                                    product={item.poi}
                                />
                        };
                    }

                    if (counterOffer) {
                        return {
                            days,
                            content: (
                                <CounterOfferLayout
                                    key={item.poi.id}
                                    product={
                                        item.type === 'normal' ?
                                            <CartPoiCard
                                                poi={item.poi}
                                            /> :
                                            <CartManualProductCard
                                                product={item.poi}
                                            />
                                    }
                                    counterOffer={
                                        counterOffer.type === 'normal' ?
                                            <CartPoiCard
                                                poi={counterOffer.poi}
                                            /> :
                                            <CartManualProductCard
                                                product={counterOffer.poi}
                                            />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.poi.counter_offer_of) {
                        return {
                            days,
                            content: item.type === 'normal' ?
                                <CartPoiCard
                                    key={item.poi.id}
                                    poi={item.poi}
                                /> :
                                <CartManualProductCard
                                    key={item.poi.id}
                                    product={item.poi}
                                />
                        }
                    }

                    return {
                        days: [],
                        content: []
                    }
                })
            ).concat(
                products.manualProducts.map((item) => {
                    const counterOffer = products.manualProducts.find((product) => {
                        return item.id === product.counter_offer_of;
                    });
                    const days = getDays(item.start_date);

                    if (!checkCounterOffer) {
                        return {
                            days,
                            content: (
                                <CartManualProductCard
                                    key={item.id}
                                    product={item}
                                />
                            )
                        };
                    }

                    if (counterOffer) {
                        return {
                            days,
                            content: (
                                <CounterOfferLayout
                                    key={item.id}
                                    product={
                                        <CartManualProductCard
                                            product={item}
                                        />
                                    }
                                    counterOffer={
                                        <CartManualProductCard
                                            product={counterOffer}
                                        />
                                    }
                                />
                            )
                        }
                    }

                    if (!item.counter_offer_of) {
                        return {
                            days,
                            content: (
                                <CartManualProductCard
                                    key={item.id}
                                    product={item}
                                />
                            )
                        }
                    }

                    return {
                        days: [],
                        content: []
                    }
                })
            ) :
            [];

    return {
        productStepIds,
        productsWithDays,
        productsWithSteps
    }
}

type CounterOfferLayoutProps = {
    product: React.ReactNode,
    counterOffer: React.ReactNode
}

export function CounterOfferLayout(props: CounterOfferLayoutProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="center"
            spacing={2}
            sx={(theme) => ({
                padding: 2,
                border: `1px solid ${theme.palette.grey[500]}`
            })}
        >
            {props.product}
            <Typography color={green[600]}>
                ({t('cart-material.provider-quotation-proposition-marker')})
            </Typography>
            {props.counterOffer}
        </Stack>
    )
}