// Dependencies
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Core
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import { EditorState } from 'draft-js';

// Common
import RichEditor, { RichEditorStyleMap } from '../Menu/MaterialTripList/utils/editor/editor';
import { isHTMLEmpty } from './utils/isHTMLEmpty';
import { renderRichEditorContentHTML } from '../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { customColors } from '../Menu/MaterialTripList/utils/editor/customColors';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { AccommodationCart } from '../Itinerary/objects/accommodationCart';

const useStyles = makeStyles({
    validateButton: {
        padding: '15px 10px',
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        padding: '15px 10px',
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

type Props = {
    accommodation: AccommodationCart,
    onManualCancellationRules: () => void
}

const initialState = EditorState.createEmpty();

const CartAccommodationManualCancellationRules = ({accommodation, onManualCancellationRules}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [noRefund, setNoRefund] = useState(accommodation.no_refund);
    const [inputState, setInputState] = useState(initialState);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onChangeInputState = (html: string) => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        const editorState = restoreRichEditorStateFromHtml(
            styles,
            html
        );

        setInputState(editorState);
    }

    const onNoRefundChange = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
        if (event.target.value === 'true') {
            onChangeInputState(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                onChangeInputState(response.data.cancellation_text);
            }).catch(error => {
            });
        }
        setNoRefund(event.target.value === 'true');
    };

    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();

        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        const content = renderRichEditorContentHTML(
            styles,
            inputState.getCurrentContent()
        );
    
        accommodation.rooms.map(room => {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                data: {
                    no_refund: noRefund,
                    cancel_condition_text: isHTMLEmpty(content) ? null : content
                }
            }).then(response => {
                dispatch({type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data});
                onManualCancellationRules();
            }).catch(error => {
                console.log(error);
                onManualCancellationRules();
            });
        });
    };
    const onCancelManualCancellationRules = () => {
        onManualCancellationRules();
    };
    useEffect(() => {
        if (accommodation.cancel_condition_text === null) {
            const { headers } = CheckBeforeRequest();
            let ids = '';
            accommodation.rooms.map((room, index_room) => {
                if (index_room > 0) {
                    ids += '&';
                }
                ids += `ids=${room.id}`;
            });
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/get_cancellation_text_from_charges_multi/?${ids}`
            }).then(response => {
                onChangeInputState(response.data.cancellation_text);
            }).catch(error => {
            });
        }
    }, []);

    useEffect(() => {
        onChangeInputState(accommodation.cancel_condition_text ?? '');
    }, [accommodation.cancel_condition_text]);

    return (
        <Grid container spacing={ 2 }>
            <Grid item xs={ 4 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <Select id={ 'no-refund' } value={ noRefund ? 'true' : 'false' } onChange={ onNoRefundChange }>
                        <MenuItem value="false">{ t('cart-material.cancellable-with-conditions') }</MenuItem>
                        <MenuItem value="true">{ t('cart-material.not-cancellable') }</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <RichEditor
                    values={{}}
                    pictures={null}
                    editorState={inputState}
                    setEditorState={setInputState}
                    controlledState
                />
            </Grid>
            <Grid item xs={ 2 }>
                <Button variant={ 'contained' } className={ classes.cancelButton } fullWidth={ true } onClick={ onCancelManualCancellationRules }>{ t('global.cancel') }</Button>
            </Grid>
            <Grid item xs={ 2 }>
                <Button variant={ 'contained' } className={ classes.validateButton } fullWidth={ true } onClick={ onValidateManualCancellationRules }>{ t('global.validate') }</Button>
            </Grid>
        </Grid>
    );
};

export default CartAccommodationManualCancellationRules;