import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import GoogleMapsLoader from "google-maps";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import FlightIcon from '@material-ui/icons/Flight';
import RoomIcon from '@material-ui/icons/Room';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

const useStyles = makeStyles({
    marker: {
        backgroundColor: "white",
        color: "black",
        cursor: "pointer",
        height: '30px !important',
    },
    infoWindowTitle: {
        padding: "8px 32px 0 32px",
        fontWeight: "bold",
        textAlign: "center"
    },
    marginLeft: {
        marginLeft: 8
    },
    marginBottom: {
        marginBottom: 8
    },
    perNight: {
        marginLeft: 8,
        display: "inline-block"
    },
    paddingRight: {
    	paddingRight: 10
    },
    centerImg: {
    	display: "block",
    	marginLeft: "auto",
    	marginRight: "auto"
    },
    bottomAlign: {
    	verticalAlign: "bottom"
    },
    paddingTop15: {
    	paddingTop: 15
    },
	ftGray: {
		opacity: '54%'
	},
    markerColor: {
        backgroundColor: 'white',
        color: 'black'
    },
    markerColorBlack: {
        backgroundColor: 'black',
        color: 'white'
    }
});

let infowindow = null;
let station_marker = [];

function CustomMarker(latlng, black, map) {
    this.latlng = latlng;
    this.black = black;
    this.setMap(map);
}

const RenderMap = ({card_index, station, hovered, provider, card}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [map, setGoogleMap] = useState(null);
    const [google, setGoogle] = useState(null);
    const initMap = () => {
    	let key = "";
    	let config = JSON.parse(localStorage.getItem("config"));
    	for (let i = 0; i < config.keys.length; i++) {
    	    if (config.keys[i].identifier === "google_api") {
    	        key = config.keys[i].value;
    	    }
    	}
    	GoogleMapsLoader.KEY = key;
    	GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
    	GoogleMapsLoader.LANGUAGE = "fr";
    	GoogleMapsLoader.load(function(google) {
    	    if (document.getElementById(`map-agency-${card_index}`) !== null) {
    	        let options = {
    	            mapTypeId: google.maps.MapTypeId.ROADMAP,
    	            center: {lat: parseFloat(0), lng: parseFloat(0)},
    	            zoom: 10,
    	            draggable: true,
    	            clickableIcons: false,
    	            scaleControl: true
    	        };
    	        let map = new google.maps.Map(document.getElementById(`map-agency-${card_index}`), options);
    	        infowindow = new google.maps.InfoWindow({
    	            pixelOffset: new google.maps.Size(-5, -25)
    	        });
    	        CustomMarker.prototype = new google.maps.OverlayView();
    	        CustomMarker.prototype.createDiv = function() {
	               this.div = ReactDOM.render(<div><Fab className={this.black ? classes.markerColorBlack : classes.markerColor } size={ "small" }><DriveEtaIcon/></Fab></div>, document.createElement("div"));
	               this.div.style.position = "absolute";
	               google.maps.event.addDomListener(this.div, 'click', event => {
                        google.maps.event.trigger(this, 'click');
	               });
    	        };
    	        CustomMarker.prototype.appendDivToOverlay = function() {
    	            const panes = this.getPanes();
    	            if (panes !== null) {
    	                panes.overlayImage.appendChild(this.div);
    	            }
    	        };
    	        CustomMarker.prototype.positionDiv = function() {
    	            const projection = this.getProjection();
    	            if (projection !== null) {
    	                const point = projection.fromLatLngToDivPixel(this.latlng);
    	                let offset = 25;
    	                if (point) {
    	                    this.div.style.left = `${point.x - offset}px`;
    	                    this.div.style.top = `${point.y - offset}px`;
    	                }
    	            }
    	        };
    	        CustomMarker.prototype.draw = function() {
    	            if (!this.div) {
    	                this.createDiv();
    	                this.appendDivToOverlay();
    	            }
    	            this.positionDiv();
    	        };
    	        CustomMarker.prototype.remove = function() {
    	            this.setMap(null);
    	            if (this.div) {
    	                this.div.parentNode.removeChild(this.div);
    	                this.div = null;
    	            }
    	        };
    	        setGoogle(google);
    	        setGoogleMap(map);
    	    }
    	});
    };

    useEffect(() => {
    	if (map === null || google === null) {
    	    initMap();
    	}
    	else {
            getMarker();
    	}
    }, [station]);
    useEffect(() => {
        if (hovered !== null) {
            if (station_marker.length > 0) {
                for (let i = 0; i < station_marker.length; i++) {
                    station_marker[i].marker.setMap(null);
                }
                station_marker = [];
            }
            if (station[hovered].latitude !== null && station[hovered].longitude !== null) {
                let coord = new google.maps.LatLng(station[hovered].latitude, station[hovered].longitude);
                renderMarker(coord, hovered, true);
            }
            else {
                let geocoder = new google.maps.Geocoder();
                let address = station[hovered].address_line;
                if (station[hovered].postal_code !== "") {
                    address += " " + station[hovered].postal_code;
                }
                if (station[hovered].city_name !== "") {
                    address += " " + station[hovered].city_name;
                }
                geocoder.geocode({'address': address}, function(results, status) {
                    if (status === 'OK') {
                        renderMarker(results[0].geometry.location, hovered, true);
                    } else {
                        console.log('Geocode was not successful for the following reason: ', status);
                        console.log('address:', address);
                    }
                });
            }
            infowindow.close();
        }
        else {
            if (google !== null && map !== null) {
                getMarker();
            }
        }
    }, [hovered])
    const renderMarker = (results, i, black) => {
        map.setCenter(results);
        let marker = new CustomMarker(results, black, map);
        marker.addListener("click", function () {
            this.black = true;
            this.div.parentNode.removeChild(this.div);
            this.createDiv();
            this.appendDivToOverlay();
            this.positionDiv();
            infowindow.setPosition(results);
            infowindow.setOptions({
                pixelOffset: new google.maps.Size(-5, -20)
            });
            infowindow.setContent(ReactDOM.render(
                <div>
                    {
                        card.subprovider_id === card.provider_id && (
                            <img className={`${classes.centerImg} ${classes.paddingTop15}` } src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url }/>
                        )
                    }
                    {
                        card.subprovider_id !== card.provider_id && (
                            <img className={`${classes.centerImg} ${classes.paddingTop15}`} src={ card.subprovider_logo }/>
                        )
                    }
                    <Typography className={ classes.infoWindowTitle }>{ station[i].name }</Typography>
                    <br/>
                    <div className={ `${classes.paddingRight} ${classes.marginBottom}` }>
                        <Typography className={ classes.perNight } variant={ "body2" }>
                            <RoomIcon className={ `${classes.bottomAlign} ${classes.ftGray}` }/>
                            {station[i].address_line}
                        </Typography>
                        {
                            station[i].at_airport && (
                                <Fragment>
                                    <br/>
                                    <Typography className={ classes.perNight} variant={ "body2" }>
                                        <FlightIcon className={ `${classes.bottomAlign} ${classes.ftGray}` }/>
                                        {t("cars.free_shuttle_false")}
                                    </Typography>
                                </Fragment>
                            )
                        }
                    </div>
                </div>
            , document.createElement("div")));
            infowindow.open(map, marker);
            google.maps.event.addListenerOnce(infowindow, "position_changed", function() {
                if (marker.div !== null) {
                    marker.black = false;
                    marker.div.parentNode.removeChild(marker.div);
                    marker.createDiv();
                    marker.appendDivToOverlay();
                    marker.positionDiv();
                }
            });
            google.maps.event.addListenerOnce(infowindow, "closeclick", function() {
                if (marker.div !== null) {
                    marker.black = false;
                    marker.div.parentNode.removeChild(marker.div);
                    marker.createDiv();
                    marker.appendDivToOverlay();
                    marker.positionDiv();
                }
            });
        });
        station_marker.push({
            marker: marker
        });
    }
    const getMarker = () => {
        if (station_marker.length > 0) {
            for (let i = 0; i < station_marker.length; i++) {
                station_marker[i].marker.setMap(null);
            }
            station_marker = [];
        }
        let geocoder = new google.maps.Geocoder();
        for (let i = 0; i < station.length; i++) {
            let station_price = card.station_prices.find((el) => {
                return el.pickup_station === station.code;
            });
            if (station_price !== undefined && station[i].address_line !== null && station[i].address_line !== "" && station[i].latitude === null && station[i].longitude === null){
                let address = station[i].address_line;
                if (station[i].postal_code !== "") {
                    address += " " + station[i].postal_code;
                }
                if (station[i].city_name !== "") {
                    address += " " + station[i].city_name;
                }
                setTimeout(function(){
                    geocoder.geocode({'address': address}, function(results, status) {
                        if (status === 'OK') {
                            renderMarker(results[0].geometry.location, i, false);
                        } else {
                            console.log('Geocode was not successful for the following reason: ', status);
                            console.log('address:', address);
                        }
                   });
                }, 500 * i);
            }
            else if (station[i].latitude !== null && station[i].longitude !== null) {
                let coord = new google.maps.LatLng(station[i].latitude, station[i].longitude);
                renderMarker(coord, i, false);
            }
        }
    }
    return (
        <Fragment>
            <div id={ `map-agency-${card_index}` } style={{height: '500px', width: '100%'}}/>
        </Fragment>
    );
};
export default React.memo(RenderMap);