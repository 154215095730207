import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import { CartCancellationRules } from "./CartCancellationRules";
import { isHTMLEmpty } from "./utils/isHTMLEmpty";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { ManualProduct } from "../../Reducers/objects/manualProduct";

type Props = {
    open: boolean,
    item: ManualProduct,
    onClose: () => void,
    onCloseMenu: () => void
}

export function CartConstructionProductsTableEditManualProductCancellationRulesModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onValidateManualCancellationRules = (noRefund: boolean, content: string) => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.item.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: isHTMLEmpty(content) ? null : content
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            props.onCloseMenu();
        }).catch(error => {
            console.log(error);
            props.onCloseMenu();
        });
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onClick={(event) => event.stopPropagation()}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t('cart-material.manual-cancellation-rules')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <CartCancellationRules
                    type="manual"
                    open
                    id={props.item.id}
                    text={props.item.cancel_condition_text}
                    moved_with_recalc_cancel_condition_text={props.item.moved_with_recalc_cancel_condition_text}
                    noRefund={props.item.no_refund}
                    isCustom={false}
                    onConfirmCancelConditionRecalc={() => null}
                    onDeleteCancellationRules={() => null}
                    onToggle={props.onCloseMenu}
                    onValidate={onValidateManualCancellationRules}
                />
            </DialogContent>
        </Dialog>
    );
}
