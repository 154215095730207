import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import {
    AttachMoney,
    Badge,
    DirectionsBoat,
    DirectionsCar,
    DirectionsTransit,
    DriveEta,
    Flight,
    FolderSpecial,
    HealthAndSafety,
    Hotel,
    ImportContacts,
    Inventory,
    Margin,
    PointOfSale,
    ProductionQuantityLimits,
    QrCode,
    Restaurant,
    RoomService,
    Sailing,
    Stadium,
    Star,
    Tour,
    Visibility
} from "@mui/icons-material";
import { isNumber, pullAll } from "lodash";
import { CartPoiIcon } from "../CartPoiIcon";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";
import { ProviderContext } from "./providerContext";
import { getCarPicture } from "./getCarPicture";
import { getManualProductPicture } from "./getManualProductPicture";
import { getTransferPicture } from "./getTransferPicture";
import { getPoiPicture } from "./getPoiPicture";
import { getAccommodationPictures } from "./getAccommodationPictures";
import { findPictureUrl } from "./findPictureUrl";
import { htmlHasEmptyContent } from "../../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { useItineraryContentCartProducts } from "./itineraryContentCartProducts";
import { CartConstructionContentsFormProductsInputItemProps } from "../CartConstructionContentsFormProductsInputItem";
import { AppState } from "../../../Reducers/Reducers";

type Products = (
    CartConstructionContentsFormProductsInputItemProps &
    {key: string, type: keyof ReturnType<ReturnType<typeof useItineraryContentCartProducts>>, isOnEndDate: boolean}
)[]

export function useCartConstructionContentsFormProducts(content: ItineraryContentStep, index: number, stackNumber: number | null): Products {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const getFilteredCart = useItineraryContentCartProducts();
    const filteredCart = useMemo(() => {
        return getFilteredCart(content, index);
    }, [getFilteredCart, content, index]);
    const providerContext = useContext(ProviderContext);

    return useMemo(() => {
        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        let contents: Products = filteredCart.cars.filter((item) => {
            return !providerContext.module ||
                   item.car.stack_number === stackNumber;
        }).map((item, index): Products[number] => {
            let title = item.car.localization?.find((item) => {
                return item.locale === locale;
            })?.name ??
            item.car.name;
        
            if (item.type === 'normal' && quotation_code === 'marcovasco') {
                title += ` (${ item.car.vehicle_code })`;
            }

            if (item.isDayIncluded && item.car.is_optional) {
                title += ` (${t('cart-material.cart-construction-day-included')})`;
            }
    
            let picture = '';

            const pictures = item.type === 'normal' ?
                getCarPicture(item.car) :
                getManualProductPicture(item.car);

            if (pictures[0]) {
                picture = findPictureUrl(pictures[0]);
            }
        
            return {
                key: `car-${item.type}-${item.car.id}`,
                type: 'cars',
                typeIcon: <DriveEta />,
                isDayIncluded: item.isDayIncluded,
                isOnEndDate: item.isOnEndDate,
                image: picture,
                title,
                name: item.car.name,
                destination: item.car.address_pickup ?? undefined,
                description: item.type === 'manual' ? item.car.description : undefined,
                localization: item.car.localization ?? [],
                dates: [item.car.start_date, item.car.end_date],
                item: item.type === 'normal' ?
                    {
                        type: 'car',
                        index,
                        item: item.car
                    } :
                    {
                        type: 'manual',
                        item: item.car
                    }
            };
        }).concat(
            filteredCart.transfers.filter((item) => {
                return !providerContext.module ||
                       item.transfer.stack_number === stackNumber;
            }).map((item): typeof contents[number] => {
                const title = item.type === 'normal' ?
                    item.transfer.localization.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    item.transfer.custom_product?.title ??
                    item.transfer.name :
                    item.transfer.localization?.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    item.transfer.name;

                let picture = '';
    
                const pictures = item.type === 'normal' ?
                    getTransferPicture(item.transfer) :
                    getManualProductPicture(item.transfer);
    
                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                return {
                    key: `transfer-${item.type}-${item.transfer.id}`,
                    type: 'transfers',
                    typeIcon: <DriveEta />,
                    isDayIncluded: item.isDayIncluded,
                    isOnEndDate: item.isOnEndDate,
                    image: picture,
                    title,
                    name: item.type === 'normal' && item.transfer.is_custom ?
                        item.transfer.custom_product?.title ??
                        item.transfer.name ??
                        '' :
                        item.transfer.name ??
                        '',
                    description: item.type === 'manual' ? item.transfer.description : undefined,
                    localization: item.transfer.localization ?? [],
                    destination: item.transfer.station_name_pickup ?? undefined,
                    dates: [item.transfer.start_date, item.transfer.end_date],
                    item: item.type === 'normal' ?
                        {
                            type: 'transfer',
                            item: item.transfer
                        } :
                        {
                            type: 'manual',
                            item: item.transfer
                        }
                };
            })
        ).concat(
            filteredCart.pois.filter((item) => {
                return !providerContext.module ||
                       item.poi.stack_number === stackNumber;
            }).map((item): typeof contents[number] => {
                let picture = '';
    
                const pictures = item.type === 'normal' ?
                    getPoiPicture(item.poi) :
                    getManualProductPicture(item.poi);
    
                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                const localization = item.type === 'normal' ?
                    item.poi.localization?.find((item) => {
                        return item.locale === locale;
                    }) :
                    undefined;
                const providerLocalization = item.type === 'normal' ?
                    item.poi.custom_product?.localization?.find((item) => {
                        return item.locale === locale;
                    }) :
                    undefined;
                
                let variantDescription: string | null = null;
    
                if (item.type === 'normal') {
                    variantDescription = (
                        // eslint-disable-next-line no-nested-ternary
                        item.poi.cust_variant &&
                            !isNumber(item.poi.cust_variant) ?
                            item.poi.cust_variant.localization?.find((item) => {
                                return item.locale === locale;
                            })?.short_description ??
                                item.poi.cust_variant.short_description :
                            (
                                item.poi.variant && !isNumber(item.poi.variant) ?
                                    item.poi.variant.localization?.find((item) => {
                                        return item.locale === locale;
                                    })?.short_description ??
                                        item.poi.variant?.short_description :
                                    null
                            )
                    );
                }

                let description = '';
        
                if (!htmlHasEmptyContent(localization?.description ?? '')) {
                    description = localization?.description ?? '';
                } else if (!htmlHasEmptyContent(item.poi.description ?? '')) {
                    description = item.poi.description ?? '';
                } else if (!htmlHasEmptyContent(providerLocalization?.short_description ?? '')) {
                    description = [
                        providerLocalization?.short_description ?? '',
                        variantDescription ?? ''
                    ].join('');
                } else if (!htmlHasEmptyContent(item.poi.custom_product?.short_description ?? '')) {
                    description = [
                        item.poi.custom_product?.short_description ?? '',
                        variantDescription ?? ''
                    ].join('');
                } else {
                    description = variantDescription ?? '';
                }
        
                return {
                    key: `poi-${item.type}-${item.poi.id}`,
                    type: 'pois',
                    typeIcon: item.poi.poi_type ?
                        <CartPoiIcon fontSize="1.5rem" /> :
                        <Visibility />,
                    isDayIncluded: item.isDayIncluded,
                    isOnEndDate: item.isOnEndDate,
                    image: picture,
                    title: item.type === 'normal' ?
                        item.poi.localization.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        providerLocalization?.title ??
                        item.poi.custom_product?.title ??
                        '' :
                        item.poi.localization?.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        item.poi.name,
                    name: item.type === 'normal' ?
                        item.poi.localization.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        providerLocalization?.title ??
                        item.poi.custom_product?.title ??
                        '' :
                        item.poi.localization?.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                    item.poi.name,
                    description: item.type === 'manual' ?
                        item.poi.description :
                        description,
                    localization: item.poi.localization ?? [],
                    destination: (
                        item.poi.custom_product?.place.address &&
                            !item.poi.custom_product?.place.address.includes('null') ?
                            item.poi.custom_product?.place.address :
                            null
                    ) ??
                        item.poi.start_destination?.data?.localization.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        item.poi.start_destination?.data?.international_name ??
                        '',
                    dates: [item.poi.start_date, item.poi.end_date],
                    item: item.type === 'normal' ?
                        {
                            type: 'poi',
                            item: item.poi
                        } :
                        {
                            type: 'manual',
                            item: item.poi
                        }
                };
            })
        ).concat(
            filteredCart.flights.filter((item) => {
                return !providerContext.module ||
                       item.flight.stack_number === stackNumber;
            }).map((item, index): typeof contents[number] => {
                let name = '';
            
                if (item.type === 'normal') {
                    let originName = [
                        item.leg.origin?.city ?? item.leg.origin_station?.iata_city?.international_name,
                        item.leg.origin?.name ?? item.leg.origin_station?.international_name
                    ].filter((item) => item).join(' ');
                    originName = originName.trim().length === 0 ?
                        item.leg.origin?.international_name ?? '' :
                        originName;
                    let destinationName = [
                        item.leg.destination?.city ?? item.leg.destination_station?.iata_city?.international_name,
                        item.leg.destination?.name ?? item.leg.origin_station?.international_name
                    ].filter((item) => item).join(' ');
                    destinationName = destinationName.trim().length === 0 ?
                        item.leg.destination?.international_name ?? '' :
                        destinationName;
                    name = `${originName} (${item.leg.origin?.airport_code ?? item.leg.origin_station?.station_code}) - ${destinationName} (${item.leg.destination?.airport_code ?? item.leg.destination_station?.station_code})`;
                } else {
                    name = `${item.leg.origin_airport?.airport_code} - ${item.leg.destination_airport?.airport_code}`;
                }
        
                return {
                    key: `flight-${item.type}-${index}`,
                    type: 'flights',
                    typeIcon: <Flight />,
                    isDayIncluded: item.isDayIncluded,
                    isOnEndDate: item.isOnEndDate,
                    image: '',
                    title: name,
                    name,
                    description: '',
                    localization: [],
                    destination: '',
                    dates: item.type === 'normal' ?
                        [item.leg.departure_time, item.leg.arrival_time] :
                        [item.leg.start_date, item.leg.end_date],
                    item: item.type === 'normal' ?
                        {
                            type: 'flight',
                            item: item.flight
                        } :
                        {
                            type: 'manual',
                            item: item.flight
                        }
                };
            })
        ).concat(
            filteredCart.manualProducts.filter((item) => {
                return !providerContext.module ||
                       item.stack_number === stackNumber;
            }).map((item): typeof contents[number] => {
                let picture = '';
    
                const pictures = getManualProductPicture(item);
    
                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }

                let typeIcon: JSX.Element = <ProductionQuantityLimits />;
                
                switch (item.product_type) {
                    case 12: {
                        typeIcon = item.poi_type ?
                            <CartPoiIcon fontSize="1.5rem" /> :
                            <Visibility />;
                        break;
                    }
                    case 8: typeIcon = <HealthAndSafety />; break;
                    case 11: typeIcon = <Inventory />; break;
                    case -1: typeIcon = <QrCode />; break;
                    case 7: typeIcon = <Hotel />; break;
                    case 17: typeIcon = <ImportContacts />; break;
                    case 9: typeIcon = <Sailing />; break;
                    case 1: typeIcon = <Stadium />; break;
                    case 13: typeIcon = <DirectionsBoat />; break;
                    case 16: typeIcon = <FolderSpecial />; break;
                    case 10: typeIcon = <Tour />; break;
                    case 0: typeIcon = <RoomService />; break;
                    case 18: typeIcon = <AttachMoney />; break;
                    case 3: typeIcon = <Restaurant />; break;
                    case 5: typeIcon = <DirectionsTransit />; break;
                    case 4: typeIcon = <DriveEta />; break;
                    case 2: typeIcon = <DirectionsCar />; break;
                    case 61: typeIcon = <Flight />; break;
                    case 20: typeIcon = <Badge />; break;
                    case 21: typeIcon = <Margin />; break;
                    case 22: typeIcon = <PointOfSale />; break;
                }
        
                return {
                    key: `manual-${item.id}`,
                    type: 'manualProducts',
                    typeIcon,
                    isDayIncluded: item.isDayIncluded,
                    isOnEndDate: item.isOnEndDate,
                    image: picture,
                    title: item.localization?.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    item.name,
                    name: item.name ?? '',
                    description: item.description ?? undefined,
                    localization: item.localization ?? [],
                    dates: [item.start_date, item.end_date],
                    item: {
                        type: 'manual',
                        item: item
                    }
                };
            })
        ).sort((a, b) => {
            const aDate = window.moment.utc(a.dates[0]);
            const bDate = window.moment.utc(b.dates[0]);
            return aDate.isBefore(bDate) ? -1 : 1;
        }).concat(
            filteredCart.accommodations.filter((item) => {
                return !providerContext.module ||
                       item.accommodation.stack_number === stackNumber;
            }).map((item, _, array): typeof contents[number] => {
                let picture = '';
    
                const pictures = item.type === 'normal' ?
                    getAccommodationPictures(item.accommodation) :
                    getManualProductPicture(item.accommodation);
    
                if (pictures[0]) {
                    picture = findPictureUrl(pictures[0]);
                }
        
                const info = item.type === 'normal' ?
                    item.accommodation.hotel[0] :
                    undefined;
                let address = '';
                if (info?.city_name) {
                    address = info.city_name + (info.destination_name && info.destination_name !== info.city_name ? `, ${info.destination_name}` : '');
                } else if (info?.destination_name) {
                    address = info.destination_name;
                }
        
                return {
                    key: `accommodation-${item.type}-${item.accommodation.id}`,
                    type: 'accommodations',
                    typeIcon: <Hotel />,
                    isDayIncluded: item.isDayIncluded,
                    isOnEndDate: item.isOnEndDate,
                    image: picture,
                    title: (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                lineHeight="1"
                                component="span"
                            >
                                {
                                    item.type === 'normal' ?
                                        item.accommodation.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.accommodation.hotel[0]?.name ??
                                        '' :
                                        item.accommodation.localization?.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.accommodation.name
                                }
                            </Typography>
                            <Box>
                                {
                                    new Array(
                                        item.type === 'normal' ?
                                            item.accommodation.hotel[0]?.stars ?? 1 :
                                            item.accommodation.stars
                                    ).fill(null).map((_, index) => (
                                        <Star key={index} sx={{ fontSize: '15px' }} />
                                    ))
                                }
                            </Box>
                        </Stack>
                    ),
                    name: item.type === 'normal' ?
                        item.accommodation.hotel[0]?.name ??
                        '' :
                        item.accommodation.name ??
                        '',
                    description: item.type === 'manual' ?
                        item.accommodation.description ?? undefined :
                        undefined,
                    localization: item.accommodation.localization ?? [],
                    destination: address,
                    dates: [item.accommodation.start_date, item.accommodation.end_date],
                    item: item.type === 'normal' ?
                        {
                            type: 'accommodation',
                            accommodations: array,
                            item: item.accommodation
                        } :
                        {
                            type: 'manual-accommodation',
                            item: item.accommodation
                        }
                };
            }).sort((a, b) => {
                const aDate = window.moment.utc(a.dates[0]);
                const bDate = window.moment.utc(b.dates[0]);
                return aDate.isBefore(bDate) ? -1 : 1;
            })
        );
    
        const dayIncludedCars = contents.filter((item) => {
            return item.type === 'cars' &&
                   (item.isDayIncluded || item.isOnEndDate);
        });
    
        contents = pullAll(
            contents,
            dayIncludedCars
        );
    
        contents = contents.concat(
            [...dayIncludedCars].sort((a, b) => {
                const aDate = window.moment.utc(a.dates[0]);
                const bDate = window.moment.utc(b.dates[0]);
                return aDate.isBefore(bDate) ? -1 : 1;
            })
        );
    
        return contents;
    }, [locale, filteredCart]);
}
