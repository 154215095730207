//Dependencies
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, Fragment } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { EditorState } from 'draft-js';

//Common
import RichEditor, { RichEditorStyleMap } from '../Menu/MaterialTripList/utils/editor/editor';
import { isHTMLEmpty } from './utils/isHTMLEmpty';
import { renderRichEditorContentHTML } from '../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { customColors } from '../Menu/MaterialTripList/utils/editor/customColors';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { AccommodationCart } from '../Itinerary/objects/accommodationCart';

type Props = {
    room: AccommodationCart['rooms'][number],
    validate: boolean,
    onRequestDone: () => void
}

const initialState = EditorState.createEmpty();

const CartAccommodationManualCancellationRulesRoom = ({ room, validate, onRequestDone }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [noRefund, setNoRefund] = useState(room.no_refund);
    const [inputState, setInputState] = useState(initialState);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onNoRefundChange = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
        if (event.target.value === 'true') {
            onChangeInputState(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                onChangeInputState(response.data.cancellation_text);
            }).catch(error => {
            });
        }
        setNoRefund(event.target.value === 'true');
    };

    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();

        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        const content = renderRichEditorContentHTML(
            styles,
            inputState.getCurrentContent()
        );

        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: isHTMLEmpty(content) ? null : content
            }
        }).then(response => {
            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
            onRequestDone();
        }).catch(error => {
            console.log(error);
            onRequestDone();
        });
    };

    const onChangeInputState = (html: string) => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        const editorState = restoreRichEditorStateFromHtml(
            styles,
            html
        );

        setInputState(editorState);
    }

    useEffect(() => {
        if (validate) {
            onValidateManualCancellationRules();
        }
    }, [validate]);
    useEffect(() => {
        if (room.cancel_condition_text === null) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                onChangeInputState(response.data.cancellation_text);
            }).catch(error => {
            });
        }
    }, []);

    useEffect(() => {
        onChangeInputState(room.cancel_condition_text ?? '');
    }, [room.cancel_condition_text]);

    return (
        <Fragment>
            <Grid item xs={4}>
                <FormControl fullWidth variant={'outlined'}>
                    <Select id={'no-refund'} value={noRefund ? 'true' : 'false'} onChange={onNoRefundChange}>
                        <MenuItem value="false">{t('cart-material.cancellable-with-conditions')}</MenuItem>
                        <MenuItem value="true">{t('cart-material.not-cancellable')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <RichEditor
                    values={{}}
                    pictures={null}
                    editorState={inputState}
                    setEditorState={setInputState}
                    controlledState
                />
            </Grid>
        </Fragment>
    );
};

export default CartAccommodationManualCancellationRulesRoom;
