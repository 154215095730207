import axios from "axios";
import { flatten } from "lodash";
import memoizee from "memoizee";
import GetPricesArr from "./GetPricesArr.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
// import { setPackage } from "../../pages/redux/circuitSlice";

let count_response = 0;

function Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch) {
    let price_data = GetPricesArr(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, variant_selected, current_version);
    dispatch({ type: 'ITINERARY_TYPE_SET_PERIODS', payload: periods_results });
    setPriceData(price_data);
}
//
// function Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch) {
//     let price_data = GetPricesArr(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, variant_selected, current_version);
//     dispatch({ type: 'ITINERARY_TYPE_SET_PERIODS', payload: periods_results })
//     setPriceData(price_data);
//
//     const periodIds = flatten(
//         prices_terrestrial_results.map((item) => {
//             return item.periods;
//         }).concat(prices_flight_results.map((item) => {
//             return item.periods;
//         }))
//     );
//     const periods = periods_results.filter((period) => {
//         return periodIds.includes(period.id) &&
//             window.moment.utc(period.start_date).startOf('day').isSameOrAfter(
//                 window.moment.utc().startOf('day')
//             ) &&
//             window.moment.utc(period.end_date).startOf('day').isAfter(
//                 window.moment.utc().startOf('day')
//             );
//     });
//
//     dispatch(
//         setPackage({
//             key: 'periods',
//             value: periods
//         })
//     );
//     dispatch(
//         setPackage({
//             key: 'priceTerrestrial',
//             value: prices_terrestrial_results
//         })
//     );
//     dispatch(
//         setPackage({
//             key: 'priceFlight',
//             value: prices_flight_results
//         })
//     );
// }

export default async (contract, package_id, setPriceData, current_version, variant_selected, type, dispatch, source, is_custom) => {
    let owner = window.id_owner;
    try {
        const responses = await getPrices({
            owner,
            type,
            package_id,
            contract,
            source,
            is_custom
        });
        let periods_results = [...responses[0].data.results];
        let prices_terrestrial_results = [...responses[1].data];
        let prices_flight_results = [...responses[2].data];
        let promos_results = [...responses[3].data];
        Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch);
    } catch (errors) {
        console.log('errors:', errors);
    }
}

const getPrices = memoizee(
    async function (options) {
        const {headers} = CheckBeforeRequest();
        return await Promise.all([
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${options.owner}/${options.type}/${options.package_id}/contracts/${options.contract}/product-period/`,
                params: {
                    start_date_after_today: true,
                    margin: options.source !== "GESTOUR",
                    cms_reduced: !options.is_custom,
                    limit: 100000
                }
            }),
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${options.owner}/${options.type}/${options.package_id}/contracts/${options.contract}/prices-terrestrial/`,
                params: {
                    margin: options.source !== "GESTOUR",
                    cms_reduced: !options.is_custom,
                    limit: 100000
                }
            }),
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${options.owner}/${options.type}/${options.package_id}/contracts/${options.contract}/prices-flight/`,
                params: {
                    margin: options.source !== "GESTOUR",
                    cms_reduced: !options.is_custom,
                    limit: 100000
                }
            }),
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${options.owner}/${options.type}/${options.package_id}/contracts/${options.contract}/promos/`,
                params: {
                    margin: options.source !== "GESTOUR",
                    cms_reduced: !options.is_custom,
                    limit: 100000
                }
            })
        ]);
    },
    {
        promise: true,
        normalizer(args) {
            return `${args[0].owner};${args[0].type};${args[0].package_id};${args[0].contract};${args[0].source}`
        }
    }
);


// import GetPricesArr from "./GetPricesArr.js";
// import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
// import axios from "axios";
//
// function Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch) {
//     let price_data = GetPricesArr(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, variant_selected, current_version);
//     dispatch({ type: 'ITINERARY_TYPE_SET_PERIODS', payload: periods_results });
//     setPriceData(price_data);
// }
//
// export default (package_info, package_id, setPriceData, current_version, variant_selected, type, dispatch) => {
//     const { pass_check, headers } = CheckBeforeRequest();
//     let owner = window.id_owner;
//     if (pass_check) {
//         let request_product = [];
//         for (const contract of package_info?.contracts ?? []) {
//             request_product.push(
//                 axios({
//                     method: "GET",
//                     headers: headers,
//                     url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/product-period/?margin=true&limit=1000&start_date_after_today=true`
//                 })
//             );
//             request_product.push(
//                 axios({
//                     method: "GET",
//                     headers: headers,
//                     url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/prices-terrestrial/?margin=true&limit=1000`
//                 })
//             );
//             request_product.push(
//                 axios({
//                     method: "GET",
//                     headers: headers,
//                     url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/prices-flight/?margin=true&limit=1000`
//                 })
//             );
//             request_product.push(
//                 axios({
//                     method: "GET",
//                     headers: headers,
//                     url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/promos/?margin=true`
//                 })
//             );
//         }
//         axios.all([...request_product]).then(axios.spread((...responses) => {
//             let periods_results = [];
//             let prices_terrestrial_results = [];
//             let prices_flight_results = [];
//             let promos_results = [];
//             for (let i = 0; i < responses.length; i++) {
//                 if (responses[i].config.url.includes('product-period')) {
//                     periods_results = [...periods_results, ...responses[i].data.results];
//                 } else if (responses[i].config.url.includes('prices-terrestrial')) {
//                     prices_terrestrial_results = [...prices_terrestrial_results, ...responses[i].data];
//                 } else if (responses[i].config.url.includes('prices-flight')) {
//                     prices_flight_results = [...prices_flight_results, ...responses[i].data];
//                 } else if (responses[i].config.url.includes('promos')) {
//                     promos_results = [...promos_results, ...responses[i].data];
//                 }
//             }
//             Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch);
//         })).catch((errors) => {
//             console.log('errors:', errors);
//         });
//     }
// };
//
