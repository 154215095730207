import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

export function shouldProductBeBook(trip: TripVersion | undefined, item: any): boolean {
    const stackInfo = trip?.stack_info?.find((stack) => {
        return stack.id === item.stack_info_id;
    });
    return !isProductPackaged({
        product: item,
        stackInfos: trip?.stack_info ?? null
    }) || (
            !isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            }) &&
            !item.is_manual
        ) ||
        (
            isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) &&
            (
                stackInfo?.is_custom === true ||
                (
                    !stackInfo &&
                    !item.is_manual
                )
            )
        );
}