import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import green from "@material-ui/core/colors/green";

import FlightCardContainer from "../FlightSearch/FlightCardContainer";
import RemoveLuggageModal from "./RemoveLuggageModal";
import { LoadingBackDrop } from "../../Common/LoadingBackdrop";

import { OpenRemoveLuggage } from "../../../Actions/FlightSearch";

import CheckAvailability from "../Functions/CheckAvailability";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import { useReplaceFlightSegment } from "../network/replaceFlightSegment";
import { useShowError } from "../../Utils/showError";
import CheckResponse from "../Functions/CheckResponse";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    uppercase: {
        textTransform: "uppercase"
    },
    genericPadding: {
        padding: "0px 16px"
    },
    spacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 8px"
    },
    formShadow: {
        borderRadius: 15,
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        padding: "0px 16px"
    },
    deleteIcon: {
        fontSize: 16,
        marginBottom: 4,
        color: '#E6592F'
    },
    buttonGrid: {
        textAlign: "center",
        margin: 16
    },
    addToCartButton: {
        backgroundColor: `${main_color}`,
        color: "white",
        padding: "8px 10px"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlightRecap = ({ searched_group, flight, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const trip = useSelector((state) => state.trip.data_trip);
    const language = useSelector(store => store.header.language);
    const trip_currency = useSelector(store => store.trip.currency);
    const end_date = useSelector(store => store.trip.end_date);
    const providers_list = useSelector(store => store.trip.providers);
    const filter_time = useSelector(store => store.flight_search.filter_time);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const update_trip_date = useSelector(store => store.flight.update_trip_date);
    const flight_providers = useSelector(store => store.flight_search.providers);
    const selected_seats = useSelector(store => store.flight_search.selected_seats);
    const seats_by_segment = useSelector(store => store.flight_search.seats_by_segment);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);
    const open_remove_luggage = useSelector(store => store.flight_search.open_remove_luggage);
    //const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);
    const trace_id = useSelector(store => store.flight_search.trace_id);
    const user = useSelector(store => store.user.user);

    const [providersObj, setProvidersObj] = useState({});
    const [isPaidOption, setIsPaidOption] = useState(false);
    const [totalPrice, setTotalPrice] = useState(flight.total_price);
    const [addingToCart, setAddingToCart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [luggageIndex, setLuggageIndex] = useState(null);
    const [outboundIndex, setOutboundIndex] = useState(null);
    const [keySegments, setKeySegments] = useState(null);
    const [travelerId, setTravelerId] = useState(null);

    const replaceProductContext = useContext(CartConstructionReplaceProductContext);
    const showError = useShowError();
    const replaceFlightSegment = useReplaceFlightSegment({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(flight) {
            if (trip) {
                const response = CheckResponse([flight], trip.end_date)[0];
                if (response) {
                    dispatch({
                        type: 'FLIGHT_EDIT_CART_BY_ID',
                        payload: response
                    });
                    enqueueSnackbar(
                        t('cart-material.cart-construction-segment-replace-success'),
                        { variant: 'success' }
                    );
                }
            }
        },
        onError(error) {
            showError(error);
        },
        onFinally() {
            setAddingToCart(false);
            setLoading(false);
        }
    });

    useEffect(() => {
        getPaidOption();
    }, []);

    useEffect(() => {
        if (flight_providers !== null) {
            let temp_providers_obj = {};
            flight_providers.map((provider) => {
                providers_list.map((full_provider) => {
                    if (full_provider.provider.id === provider.id) {
                        temp_providers_obj[provider.id] = full_provider.provider;
                    }
                });
            });
            setProvidersObj(temp_providers_obj);
        }
    }, [flight_providers]);

    useEffect(() => {
        if (isPaidOption) {
            let temp_total = parseFloat(flight.total_price);
            let option_on_flight = [];
            flight.outbounds.map((outbound_flight) => {
                let option_on_group = [];
                outbound_flight.legs.map((leg) => {
                    if (Object.keys(leg.paid_options).length !== 0) {
                        Object.keys(leg.paid_options).forEach(key => {
                            leg.paid_options[key].map((option) => {
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${key}`)) {
                                        temp_total += parseFloat(option.prices[0].selling_price);
                                        option_on_flight.push(`${option.key_optional_service}-${key}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${key}`)) {
                                        temp_total += parseFloat(option.prices[0].selling_price);
                                        option_on_group.push(`${option.key_optional_service}-${key}`);
                                    }
                                } else {
                                    temp_total += parseFloat(option.prices[0].selling_price);
                                }
                            });
                        });
                    }
                    if (Object.keys(leg.seats).length !== 0) {
                        Object.keys(leg.seats).map((key) => {
                            temp_total += parseFloat(leg.seats[key].price);
                        });
                    }
                });
            });
            //temp_total = temp_total;
            setTotalPrice(temp_total);
        }
    }, [isPaidOption, flight, open_remove_luggage]);

    useEffect(() => {
        replaceProductContext.onRegisterNextEvent(() => {
            setAddingToCart(true);
        });
    }, []);

    useEffect(() => {
        if (
            addingToCart &&
            (
                replaceProductContext.mode === 'add' ||
                !replaceProductContext.enableReplace
            )
        ) {
            CheckAvailability(flight_groups, flight_providers, searched_group, index_group, flight_groups.length - 1, trip_currency, flight, trace_id, end_date, filter_time, user, enqueueSnackbar, dispatch, t, router, replaceProductContext);
        } else if (
            addingToCart &&
            replaceProductContext.enableReplace &&
            replaceProductContext.extraData?.current?.flight &&
            replaceProductContext.extraData?.current?.segmentIndices
        ) {
            replaceFlightSegment({
                flightId: replaceProductContext.extraData.current.flight.id ?? -1,
                newFlightKey: flight.options?.fares[0]?.key_pricing_solution ?? '',
                segmentGroup: replaceProductContext.extraData.current.segmentIndices.map((item) => {
                    return replaceProductContext.extraData.current.flight.outbounds[item]?.legs[0]?.group ?? -1;
                })
            });
        }
    }, [addingToCart]);

    useEffect(() => {
        if (update_trip_date) {
            setAddingToCart(false);
        }
    }, [update_trip_date]);

    const sumTaxes = (tax_list) => {
        let total_taxes = 0;
        tax_list.map((tax) => {
            total_taxes += parseFloat(tax.selling_price);
        });
        total_taxes = total_taxes;
        return total_taxes;
    };
    const getPaidOption = () => {
        let temp_paid_option = false;
        if (selected_seats.length !== 0) {
            selected_seats.map(seats => {
                if (seats.length !== 0) {
                    temp_paid_option = true;
                }
            });
        }
        flight.outbounds.map((outbound_flight) => {
            outbound_flight.legs.map((leg) => {
                leg.seats = {};
                selected_seats.map((selected, selected_index) => {
                    if (seats_by_segment[selected_index].segment_key === leg.segment_key) {
                        selected.map((traveler) => {
                            leg.seats[traveler.traveler] = { seat: traveler.seat_number, price: traveler.price };
                        });
                    }
                });
                if (Object.keys(leg.paid_options).length !== 0) {
                    temp_paid_option = true;
                }
            });
        });
        setIsPaidOption(temp_paid_option);
    };
    const removeSeat = (traveler_id, segment_key) => {
        selected_seats.map((selected, selected_index) => {
            if (seats_by_segment[selected_index].segment_key === segment_key) {
                let total_price = totalPrice;
                let find_traveler = selected.find(el => el.traveler === parseFloat(traveler_id));
                total_price -= parseFloat(find_traveler.price);
                setTotalPrice(total_price);
                dispatch({ type: 'FLIGHT_REMOVE_SELECTED_SEAT', payload: { traveler_id: parseFloat(traveler_id), segment_index: selected_index } });
            }
        });
        getPaidOption();
    };
    let option_on_flight = [];
    console.log('flight:', flight);
    return (
        <>
            <Grid container alignItems="baseline" justify="space-between" spacing={2} style={ isHandHeld ? { marginBottom: 64 } : {}}>
                <Grid item md={8} xs={12}>
                    <FlightCardContainer searched_group={searched_group} flight={flight} flight_index={selected_flight} router={router}/>
                    {/* <FlightCard searched_group={searched_group} flight={flight} flight_index={selected_flight} providersObj={providersObj} router={router} small={true} /> */}
                </Grid>
                <Grid item md={4} xs={12}>
                    <Paper elevation={0} variant={isHandHeld ? "elevation" : "outlined"} className={isHandHeld ? "" : classes.formShadow}>
                        <Grid className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize16} ${classes.uppercase}`}>
                            { t("flight_search.summary_price") }
                        </Grid>
                        <Divider />
                        <Grid className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase}`}>
                            { t("flight_search.ticket_price")}
                            {
                                flight.adult !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.adult.number}x ${flight.adult.number > 1 ? t("flight_search.adults") : t("flight_search.adult")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.adult.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.adult.total_price)} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(flight.adult.total_price) * flight.adult.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.children !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.children.number}x ${flight.children.number > 1 ? t("flight_search.children") : t("flight_search.child")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.children.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.children.total_price)} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(flight.children.total_price) * flight.children.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.baby !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.baby.number}x ${flight.baby.number > 1 ? t("flight_search.babies") : t("flight_search.baby")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.baby.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.baby.total_price)} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(flight.baby.total_price) * flight.baby.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Divider />
                        <Grid className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase}`}>
                            { t("flight_search.taxe_price") }
                            {
                                flight.adult !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.adult.number}x ${flight.adult.number > 1 ? t("flight_search.adults") : t("flight_search.adult")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.adult.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.adult.taxes))} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.adult.taxes) * flight.adult.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.children !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.children.number}x ${flight.children.number > 1 ? t("flight_search.children") : t("flight_search.child")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.children.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.children.taxes))} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.children.taxes) * flight.children.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.baby !== null && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.baby.number}x ${flight.baby.number > 1 ? t("flight_search.babies") : t("flight_search.baby")}` }
                                        </Grid>
                                        <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { `${flight.baby.number}x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.baby.taxes))} = ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.baby.taxes) * flight.baby.number)}` }
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                        {
                            isPaidOption && (
                                <Grid>
                                    <Divider />
                                    <div className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase}`}>
                                        { t("flight_search.more_option") }
                                    </div>
                                </Grid>
                            ) 
                        }
                        {
                            isPaidOption && flight.outbounds.map((outbound_flight, outbound_index) => {
                                return (
                                    outbound_flight.legs.map((leg, leg_index) => {
                                        let option_on_group = [];
                                        if (Object.keys(leg.paid_options).length !== 0 || Object.keys(leg.seats).length !== 0) {
                                            return (
                                                <Grid key={leg_index} className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                                    {
                                                        leg.origin_airport !== undefined && airports[leg.origin_airport].code
                                                    }
                                                    {
                                                        leg.origin_station !== undefined && stations[leg.origin_station].code
                                                    }
                                                    { " - " }
                                                    {
                                                        leg.destination_airport !== undefined && airports[leg.destination_airport].code
                                                    }
                                                    {
                                                        leg.destination_station !== undefined && stations[leg.destination_station].code
                                                    }
                                                    {
                                                        Object.keys(leg.paid_options).length !== 0 && Object.keys(leg.paid_options).map((traveler_id) => {
                                                            let traveler = searched_group.travelers.find(item => parseInt(item.id) === parseInt(traveler_id));
                                                            return (
                                                                <Grid key={traveler_id} className={classes.horizontalSpacer}>
                                                                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                                                        { traveler.first_name + " " + traveler.last_name }
                                                                    </Grid>
                                                                    {
                                                                        leg.paid_options[traveler_id].map((luggage, luggage_index) => {
                                                                            let to_render = false;
                                                                            if (luggage.option_on_group) {
                                                                                if (!option_on_group.includes(`${luggage.key_optional_service}-${traveler_id}`)) {
                                                                                    to_render = true;
                                                                                    option_on_group.push(`${luggage.key_optional_service}-${traveler_id}`);
                                                                                }
                                                                            } else if (luggage.option_on_flight) {
                                                                                if (!option_on_flight.includes(`${luggage.key_optional_service}-${traveler_id}`)) {
                                                                                    to_render = true;
                                                                                    option_on_flight.push(`${luggage.key_optional_service}-${traveler_id}`);
                                                                                }
                                                                            } else if (luggage.key_segments.includes(leg.segment_key)) {
                                                                                to_render = true;
                                                                            }
                                                                            return (
                                                                                <Grid container key={`${traveler_id}-${luggage_index}`} alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                        { `${luggage.baggage_quantity} x ${luggage.description}` }
                                                                                    </Grid>
                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                        { to_render && `1 x ${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(luggage.prices[0].selling_price)}`}
                                                                                        <Tooltip title={t("flight_search.del_luggage")}>
                                                                                            <IconButton size="small" onClick={() => {
                                                                                                setLuggageIndex(luggage_index);
                                                                                                setOutboundIndex(outbound_index);
                                                                                                setKeySegments(leg.key_segments);
                                                                                                setTravelerId(traveler_id);
                                                                                                dispatch(OpenRemoveLuggage(true));
                                                                                            }}>
                                                                                                <DeleteIcon className={classes.deleteIcon} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        leg.seats[traveler_id] !== undefined &&
                                                                        <Grid container alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                {t('flight_seat.seat', { seat: leg.seats[traveler_id].seat }) }
                                                                            </Grid>
                                                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                { leg.seats[traveler_id].price !== null ? `${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(leg.seats[traveler_id].price)}` : ''}
                                                                                <IconButton size="small" onClick={() => removeSeat(traveler_id, leg.segment_key)}>
                                                                                    <DeleteIcon className={classes.deleteIcon} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        Object.keys(leg.seats).length !== 0 && Object.keys(leg.seats).map((traveler_id) => {
                                                            let traveler = searched_group.travelers.find(item => parseInt(item.id) === parseInt(traveler_id));
                                                            if (Object.keys(leg.paid_options).length === 0 || leg.paid_options[traveler_id] === undefined) {
                                                                return (
                                                                    <Grid key={traveler_id} className={classes.horizontalSpacer}>
                                                                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                                                            { traveler.first_name + " " + traveler.last_name }
                                                                        </Grid>
                                                                        <Grid container alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                {t('flight_seat.seat', { seat: leg.seats[traveler_id].seat }) }
                                                                            </Grid>
                                                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                                                { `${new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(leg.seats[traveler_id].price)}`}
                                                                                <IconButton size="small" onClick={() => removeSeat(traveler_id, leg.segment_key)}>
                                                                                    <DeleteIcon className={classes.deleteIcon} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Grid>
                                            );
                                        }
                                    })
                                );
                            })
                        }
                        <Divider />
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase}`}>
                                { t("flight_search.total") }
                            </Grid>
                            <Grid item className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase}`}>
                                { new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalPrice) }
                            </Grid>
                        </Grid>
                        {
                            !replaceProductContext.enableReplace &&
                            <Grid className={classes.buttonGrid}>
                                <Button size="small" variant="contained" className={classes.addToCartButton} fullWidth disabled={addingToCart} onClick={() => {
                                    setAddingToCart(true); 
                                }}>
                                    { t("flight_search.add_to_cart") }
                                    { addingToCart && <CircularProgress size={20} className={classes.buttonProgress} /> }
                                </Button>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <LoadingBackDrop open={loading} />
            <RemoveLuggageModal luggage_index={luggageIndex} outbound_index={outboundIndex} key_segment={keySegments} traveler_id={travelerId} />
        </>
    );
};

export default React.memo(FlightRecap);
