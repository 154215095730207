import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';

import SelectLead from './SelectLead.js';

import moment from 'moment';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    },
    marginSpacer: {
        marginBottom: 12
    }
}));

const BookingRoomInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const end_date = useSelector(store => store.trip.end_date);
    const to_book = useSelector(store => store.cart.to_book);
    const trip = useSelector(store => store.trip.data_trip);

    const [hotelsToBook, setHotelsToBook] = useState([]);

    useEffect(() => {
        let temp_hotels = [];

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 0) {
                    if (
                        product.is_manual &&
                        !isProductPackaged({
                            product,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        })
                    ) {
                        let temp_hotel = Object.assign({}, product);
                        if (temp_hotel.start_destination !== null) {
                            temp_hotel.city_name = temp_hotel.start_destination.name !== null ? temp_hotel.start_destination.name : temp_hotel.start_destination.international_name;
                        } else {
                            temp_hotel.city_name = null;
                        }
                        let temp_rooms_to_book = [];
                        temp_hotel.rooms.map((room) => {
                            let temp_room = Object.assign({}, room);
                            temp_room.group_passenger.lead_traveler_info = temp_room.group_passenger.lead_traveler_info !== null ? temp_room.group_passenger.lead_traveler_info : "";
                            temp_room.group_passenger.lead_traveler = temp_room.group_passenger.lead_traveler !== null ? temp_room.group_passenger.lead_traveler : "";
                            temp_room.travelers_age_nb = getNbPaxAges(temp_room.group_passenger);
                            temp_room.is_manual = true;
                            temp_room.product_id = product.product_id ?? product.id;
                            temp_rooms_to_book.push(temp_room);
                        });
                        temp_hotel.rooms_to_book = temp_rooms_to_book;
                        temp_hotels.push(temp_hotel);
                    } else if (product.group_passenger.default === false && !product.is_manual) {
                        if (!temp_hotels.some(item => item.id === product.hotel[0].id)) {
                            let temp_hotel = Object.assign({}, product.hotel[0]);
                            let temp_product = Object.assign({}, product);
    
                            temp_product.group_passenger.lead_traveler_info = temp_product.group_passenger.lead_traveler_info !== null ? temp_product.group_passenger.lead_traveler_info : "";
                            temp_product.group_passenger.lead_traveler = temp_product.group_passenger.lead_traveler !== null ? temp_product.group_passenger.lead_traveler : "";
                            temp_product.travelers_age_nb = getNbPaxAges(temp_product.group_passenger);
    
                            let temp_rooms_to_book = [temp_product];
                            temp_hotel.rooms_to_book = temp_rooms_to_book;
                            temp_hotel.start_date = product.start_date;
                            temp_hotel.end_date = product.end_date;
                            temp_hotels.push(temp_hotel);
                        } else {
                            temp_hotels.map((hotel, hotel_index) => {
                                if (hotel.id === product.hotel[0].id) {
                                    let temp_hotel = Object.assign({}, hotel);
                                    let temp_product = Object.assign({}, product);
    
                                    temp_product.group_passenger.lead_traveler_info = temp_product.group_passenger.lead_traveler_info !== null ? temp_product.group_passenger.lead_traveler_info : "";
                                    temp_product.group_passenger.lead_traveler = temp_product.group_passenger.lead_traveler !== null ? temp_product.group_passenger.lead_traveler : "";
                                    temp_product.travelers_age_nb = getNbPaxAges(temp_product.group_passenger);
    
                                    temp_hotel.rooms_to_book.push(temp_product);
                                    temp_hotels[hotel_index] = temp_hotel;
                                }
                            });
                        }
                    }
                }
            }
        });
        setHotelsToBook(temp_hotels);
    }, [to_book]);

    const getNbPaxAges = (group) => {
        let nb_adults = 0;
        let nb_teens = 0;
        let nb_children = 0;
        let nb_babies = 0;

        group.travelers_list.map((traveler) => {
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                nb_teens += 1;
            } else if (age > 2 && age <= 12) {
                nb_children += 1;
            } else if (age <= 2) {
                nb_babies += 1;
            } else if (age >= 18) {
                nb_adults += 1;
            }
        });
        return [nb_adults, nb_teens, nb_children, nb_babies];
    };

    return (
        <Grid>
            {
                hotelsToBook.length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder} ${classes.marginSpacer}`}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.room_composition_info") }
                        </Grid>
                        <Grid className={`${classes.genericPadding} ${classes.marginSpacer} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.room_composition_details") }
                        </Grid>
                        {
                            hotelsToBook.map((hotel) => {
                                return (
                                    <Grid key={`hotel-${hotel.id}`} className={`${classes.genericPadding} ${classes.genericBorder} ${classes.marginSpacer}`}>
                                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                            { hotel.name } {
                                                hotel.rooms_to_book?.[0] &&
                                                isProductPackaged({
                                                    product: hotel.rooms_to_book[0],
                                                    stackInfos: trip?.stack_info ?? null
                                                }) &&
                                                <Box sx={{ color: '#2F80ED' }} component="span">
                                                    ({t('cart-material.cart-construction-package-name', { name: hotel.rooms_to_book[0].stack_number })})
                                                </Box>
                                            }
                                        </Grid>
                                        {
                                            hotel.city_name !== null && (
                                                <Grid className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                    { hotel.city_name }
                                                </Grid>
                                            )
                                        }
                                        <Grid className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                                            { t("global.from") + " " + moment.utc(hotel.start_date).format('DD MMMM') + " " + t("global.to") + " " + moment.utc(hotel.end_date).format('DD MMMM') }
                                        </Grid>
                                        <Grid className={classes.genericPadding}>
                                            {
                                                hotel.rooms_to_book.map((room, room_index) => {
                                                    return (
                                                        <Grid key={`room-${room.id}`} className={classes.spacer}>
                                                            <Grid className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`} >
                                                                { t("global.room") + ' ' + (room_index + 1) + " : "}
                                                                { room.travelers_age_nb[0] > 0 ? (room.travelers_age_nb[0] > 1 ? room.travelers_age_nb[0] + " " + t("flight_search.adults") : room.travelers_age_nb[0] + " " + t("flight_search.adult")) : "" }{ room.travelers_age_nb[1] > 0 ? " - " : "" }
                                                                { room.travelers_age_nb[1] > 0 ? (room.travelers_age_nb[1] > 1 ? room.travelers_age_nb[1] + " " + t("flight_search.ados") : room.travelers_age_nb[1] + " " + t("flight_search.ado")) : "" }{ room.travelers_age_nb[2] > 0 ? " - " : "" }
                                                                { room.travelers_age_nb[2] > 0 ? (room.travelers_age_nb[2] > 1 ? room.travelers_age_nb[2] + " " + t("flight_search.children") : room.travelers_age_nb[2] + " " + t("flight_search.child")) : "" }{ room.travelers_age_nb[3] > 0 ? " - " : "" }
                                                                { room.travelers_age_nb[3] > 0 ? (room.travelers_age_nb[3] > 1 ? room.travelers_age_nb[3] + " " + t("flight_search.babies") : room.travelers_age_nb[3] + " " + t("flight_search.baby")) : "" }
                                                            </Grid>
                                                            <Grid container alignItems="center" justify="space-between" className={`${classes.marginEnd}`}>
                                                                <Grid item md={6} xs={12} className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight700} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                                    { t("booking.is_contact_lead") + (room_index + 1)}
                                                                </Grid>
                                                                <Grid item md={6} xs={12}>
                                                                    <SelectLead group={room.group_passenger} mode={"room"} product={room} product_list={null} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingRoomInfo);
