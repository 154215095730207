import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GetAllCart from "../TripList/Functions/GetAllCart";

import TripListFinanceSynthesis from "./TripListFinanceSynthesis";
import TripListFinanceTable from "./TripListFinanceTable";

import { SetCartDone } from "../../../Actions/Menu";
import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";
import { usePackagedProducts } from "../../CartMaterial/utils/packagedProducts";
import { GetExportData } from "./Functions/GetExportData";
import HasPermission from "../../Common/Functions/HasPermission";

const TripListFinance = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getPrice = useGetPrice();

    const packagedProducts = usePackagedProducts();
    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);
    const permissions = useSelector(store => store.user.permissions);
    const providers = useSelector(store => store.trip.providers);
    const manual_providers = useSelector(store => store.trip.manual_providers);
    const list_currency = useSelector(store => store.base.currency_list);
    const currency = useSelector(store => store.trip.currency);
    const get_cart_done = useSelector(store => store.menu.get_cart_done);
    const big_cart = useSelector(store => store.menu.big_cart);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const manual_cart = useSelector(store => store.trip.manual_cart);
    const data_trip = useSelector(store => store.trip.data_trip);
    const finance_charges = useSelector(store => store.menu.finance_charges);
    const finance_purchase = useSelector(store => store.menu.finance_purchase);
    const owner_id = useState(user ? user.client : null);

    const [exportData, setExportData] = useState([]);
    const [exportDataCSV, setExportDataCSV] = useState([]);
    const getTaxesPrice = useGetTaxesPrice();

    const handleError = (error) => {
        console.error(error);
        enqueueSnackbar(
            error.message,
            { variant: 'error' }
        );
        options.setLoading(false);
    };

    useEffect(() => {
        let version = to_show_version !== null ? to_show_version : trip_info.current_version;
        GetAllCart(dispatch, trip_info, version, user, permissions);
    }, [to_show_version]);
    useEffect(() => {
        if (user.client_full.type !== 2 && providers.length !== 0 && get_cart_done) {
            const { tmp_data, tmp_data1 } = GetExportData(list_currency, currency, big_cart, manual_cart, providers, manual_providers, trip_info, data_trip, packagedProducts, user, owner_id, getPrice, t, getTaxesPrice);
            setExportData(tmp_data);
            setExportDataCSV(tmp_data1);
            // dispatch(SetCartDone(false));
        }
    }, [providers, manual_providers, get_cart_done]);
    console.log('finance_charges:', finance_charges);
    console.log('finance_purchase:', finance_purchase);
    console.log('big_cart:', big_cart);
    return (
        <Fragment>
            <TripListFinanceSynthesis exportDataCSV={exportDataCSV} exportData={exportData} real={false}/>
            {
                finance_charges !== null && finance_charges.length > 0 && user.client_full.type !== 2 && HasPermission(permissions, "view_accountingbook", user) && (
                    <TripListFinanceSynthesis exportDataCSV={exportDataCSV} exportData={exportData} reel={true}/>
                )
            }
            {
                big_cart !== null && big_cart.length > 0 && user.client_full.type !== 2 && (
                    <TripListFinanceTable exportDataCSV={exportDataCSV} exportData={exportData}/>
                )
            }
        </Fragment>

    );
};
export default React.memo(TripListFinance);
