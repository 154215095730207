import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { isFunction, isNumber } from "lodash";
import GoogleMapsLoader from "google-maps";
import { AppState, Key } from "../../Reducers/Reducers";

type Props = {
    markerLocation: {
        latitude: number | null,
        longitude: number | null
    },
    onChangeMarkerLocation: (location: google.maps.LatLng) => void
}

export const CartManualProductFormAddressMap = React.forwardRef<google.maps.Map | null, Props>(
    function CartManualProductFormAddressMap(props, map): JSX.Element {
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const step = useSelector((state: AppState) => state.cart.manual_product_destination_associated);
        const marker = useRef<google.maps.Marker | null>(null);
        const destination = useMemo(() => {
            return itinerary.find((item) => {
                return item.id === step;
            })?.destination;
        }, [itinerary, step]);
    
        const onLoad = (element: HTMLDivElement | null) => {
            if (element && map && !isFunction(map) && !map.current) {
                const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
                const key = config.keys?.find((item) => item.identifier === 'google_api');
    
                if (key) {
                    GoogleMapsLoader.KEY = key.value;
                    GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
                    GoogleMapsLoader.LANGUAGE = 'fr';
                    GoogleMapsLoader.VERSION = '3.50';
                    GoogleMapsLoader.load((google) => {
                        map.current = new google.maps.Map(
                            element,
                            {
                                center: { lat: -34.397, lng: 150.644 },
                                zoom: 4,
                                zoomControl: true,
                                scaleControl: true,
                                disableDefaultUI: true
                            }
                        );
                        marker.current = new google.maps.Marker({
                            position: { lat: -34.397, lng: 150.644 },
                            draggable: true,
                            map: map.current
                        });
                        marker.current.addListener(
                            'dragend',
                            () => {
                                const position = marker.current?.getPosition();
                                if (position) {
                                    props.onChangeMarkerLocation(position);
                                }
                            }
                        );
                    });
                }
            }
        };
    
        useEffect(() => {
            if (
                isNumber(props.markerLocation.latitude) &&
                isNumber(props.markerLocation.longitude) &&
                map &&
                !isFunction(map)
            ) {
                const position = {
                    lat: props.markerLocation.latitude,
                    lng: props.markerLocation.longitude
                };
                marker.current?.setPosition(position);
                map.current?.panTo(position);
            }
        }, [props.markerLocation]);
    
        useEffect(() => {
            if (
                (
                    !isNumber(props.markerLocation.latitude) ||
                    !isNumber(props.markerLocation.longitude)
                ) &&
                destination?.data?.latitude &&
                destination.data.longitude &&
                map &&
                !isFunction(map)
            ) {
                const position = new google.maps.LatLng({
                    lat: parseFloat(destination.data.latitude),
                    lng: parseFloat(destination.data.longitude)
                });
                marker.current?.setPosition(position);
                map.current?.panTo(position);
            }
        }, [destination]);
    
        return (
            <div
                ref={onLoad}
                style={{ height: 300 }}
            />
        );
    }
);
