import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GoogleMapsLoader from "google-maps";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import DestinationInput from './DestinationInput';
import DatePickerInput from './DatePickerInput';
import RenderTime from './RenderTime';
import StationInput from './stationInput';

const useStyles = makeStyles(theme => ({
    root: {
	    '& > *': {
	    	marginTop: theme.spacing(4),
	      	marginBottom: theme.spacing(2)
	    }
  	},
  	root1: {
	  	'& > *': {
	  	  	marginBottom: theme.spacing(2)
	  	}	
  	},
  	gridTop: {
  		marginTop: theme.spacing(1)
  	},
  	gridPadding: {
  		padding: '0 30px 0 30px'
  	},
  	gridTop1: {
  		marginTop: theme.spacing(2)
  	},
  	addSearch: {
  		verticalAlign: 'middle',
  		marginRight: "10px",
  		paddingLeft: '10px'
  	},
  	formControlRoot: {
  		"width": '30%',
	  	"&& .mui-jss-MuiFormControlLabel-labelPlacementStart": {
	  		justifyContent: 'space-between'
	  	},
  		"& .mui-jss-MuiInputLabel-outlined": {
  			transform: 'translate(14px, 13px) scale(1)'
  		},
  		"& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
  			transform: 'translate(14px, -6px) scale(0.75)'
  		},
  		"& .mui-jss-MuiOutlinedInput-input": {
  			padding: '9.5px 15px'
  		}
  	},
  	formControlRoot1: {
  		"width": '30%',
	  	"&& .mui-jss-MuiFormControlLabel-labelPlacementStart": {
	  		justifyContent: 'space-between'
	  	},
  		"& .mui-jss-MuiInputLabel-outlined": {
  			transform: 'translate(14px, 13px) scale(1)'
  		},
  		"& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
  			transform: 'translate(14px, -6px) scale(0.75)'
  		},
  		"& .mui-jss-MuiOutlinedInput-input": {
  			padding: '17px 15px'
  		}
  	},
  	formControlRoot2: {
  		"& .mui-jss-MuiInputLabel-outlined": {
  			transform: 'translate(14px, 13px) scale(1)'
  		},
  		"& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
  			transform: 'translate(14px, -6px) scale(0.75)'
  		},
  		"& .mui-jss-MuiOutlinedInput-input": {
  			//padding: '9.5px 35px'
  			paddingTop: '9.5px',
  		    paddingBottom: '9.5px',
  		    paddingRight: '26px'
  		}
  	},
  	formControlRoot3: {
  		"& .mui-jss-MuiInputLabel-outlined": {
  			transform: 'translate(14px, 20px) scale(1)'
  		},
  		"& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
  			transform: 'translate(14px, -6px) scale(0.75)'
  		},
  		"& .mui-jss-MuiOutlinedInput-input": {
  			//padding: '9.5px 35px'
  			paddingTop: '17px',
  		    paddingBottom: '17px',
  		    paddingRight: '26px'
  		}
  	},
  	paddingBottom8: {
  		paddingBottom: 8
  	},
  	paddingTop8: {
  		paddingTop: 8
  	},
    separator: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline",
        position: "absolute",
        left: 0,
        top: "25%",
        bottom: "25%"
    }
}));

const RenderForm = ({ elem, elem_index, search, setSearch, setYoungDriver }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    //const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches = useMediaQuery('(min-width:600px)');
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const downTablet = useMediaQuery(theme.breakpoints.down(960));
    const [google, setGoogle] = useState(null);
	const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const ageArray = [];
    const carsArray = [];
    useEffect(() => {
        if (google === null) {
            let key = "";
            const config = JSON.parse(localStorage.getItem("config"));
            for (let i = 0; i < config.keys.length; i++) {
			    if (config.keys[i].identifier === "google_api") {
			        key = config.keys[i].value;
			    }
            }
            GoogleMapsLoader.KEY = key;
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
            GoogleMapsLoader.LANGUAGE = "fr";
            GoogleMapsLoader.load(function (google) {
                setGoogle(google);
            });
        }
    }, []);
    for (let i = 18; i <= 30; i++) {
        if (i === 30) {
            ageArray.push("30+");
        } else {
            ageArray.push(i);
        }
    }
    for (let i = 1; i <= 10; i++) {
        carsArray.push(i);
    }
    function handleChange(e, type) {
        let tmp_search = search.slice();
        let copy_search = [];
        tmp_search.map((elem, index) => {
            if (index === elem_index) {
                let tmp_elem = Object.assign({}, elem);
                if (type === "age") {
					if (parseInt(e.target.value) <= 3) {
						setYoungDriver(true);
					} else {
						setYoungDriver(false);
					}
                    tmp_elem.age = ageArray[e.target.value];
                } else if (type === "contract") {
                    tmp_elem.contract = e.target.value;
                } else {
                    tmp_elem.nb_vehicle = e.target.value;
                }
                elem = tmp_elem;
            }
            copy_search.push(elem);
        });
        setSearch(copy_search);
    }
    return (
        <Grid container spacing={0}>
            {
                //<Grid item xs={1}/>
            }
            <Grid item xs={12} className={matches ? classes.gridPadding : "" }>
                <form className={matches ? classes.root : classes.root1} noValidate autoComplete={"off"}>
		      		<Grid container spacing={matches ? 0 : 2}>
		      			<Grid item sm={!elem.same_dest ? 6 : 12} md={!elem.same_dest ? 3 : 6} xs={12}>
		      				<DestinationInput label={t("cars.place_origin")} search={search} setSearch={setSearch} elem_index={elem_index} type={"departure"} google={google}/>
		      			</Grid>
				      	{
				      		!elem.same_dest && (
		      					<Grid item md={3} sm={6} xs={12} style={{ position: "relative" }} >
						      		<DestinationInput label={t("cars.place_return")} search={search} setSearch={setSearch} elem_index={elem_index} type={"return"} google={google}/>
                                    {
                                        (matches || isTablet) && (
                                            <div className={classes.separator}/>
                                        )
                                    }
						      	</Grid>
				      		)
				      	}
				      	<Grid container item md={3} sm={6} xs={12} className={isTablet ? classes.gridTop1 : ""} style={{ position: "relative" }}>
				      		<DatePickerInput label={t("cars.date_origin")} search={search} setSearch={setSearch} elem_index={elem_index} type={"departure"}/>
                            {
                                (matches && !isTablet) && (
                                    <div className={classes.separator}/>
                                )
                            }
				      	</Grid>
				      	<Grid container item md={3} sm={6} xs={12} className={isTablet ? classes.gridTop1 : ""} style={{ position: "relative" }}>
		      				<DatePickerInput label={t("cars.date_return")} search={search} setSearch={setSearch} elem_index={elem_index} type={"return"}/>
                            {
                                (matches && !isTablet) && (
                                    <div className={classes.separator}/>
                                )
                            }
				      	</Grid>
	      		      	{
	      		      		downTablet && (
	      				      	<Grid item lg={3} sm={6} xs={12} className={isTablet ? classes.gridTop1 : classes.gridTop}>
	      				      		<FormControl fullWidth variant={"outlined"} className={matches ? classes.formControlRoot2 : classes.formControlRoot3}>
	      					      		<InputLabel id={"contract-input"}>{t("cars.optional_contract")}</InputLabel>
	      		      		    		<Select
                                            MenuProps={{ disableScrollLock: true }}
	      			      		    		labelId={"contract-input"}
	      			      		    		id={"select-contract-input"}
	      		      		    	  		value={elem.contract}
	      	  		      		    		label={t("cars.optional_contract")}
	      		      		    	  		onChange={(e) => handleChange(e, "contract")}
	      		      		    		>
	      		      		    			<MenuItem value={""}>-</MenuItem>
	      		      		    			<MenuItem value={"FULLY"}>{t("cars.contract_fully")}</MenuItem>
	      		      		    			<MenuItem value={"FULLY GPS"}>{t("cars.contract_fully_gps")}</MenuItem>
	      		      		    			<MenuItem value={"SUPER FULLY"}>{t("cars.contract_super_fully")}</MenuItem>
	      		      		    			<MenuItem value={"SUPER FULLY GPS"}>{t("cars.contract_super_fully_gps")}</MenuItem>
	      		      		    			<MenuItem value={"GOLD"}>{t("cars.contract_gold")}</MenuItem>
	      		      		    			<MenuItem value={"GOLD GPS"}>{t("cars.contract_gold_gps")}</MenuItem>
	      		      		    		</Select>
	      		      		    	</FormControl>
	      				      	</Grid>
	      		      		)
	      		      	}
						{
							["volonline", "gattinoni", "touristforum"].includes(quotation_code) &&
							<Grid item container sm={12} style={{marginTop: 14}}>
								<Grid item sm={!elem.same_dest ? 6 : 12} md={!elem.same_dest ? 3 : 6} xs={12}>
									<StationInput label={t("cart-material.station-pickup")} search={search} setSearch={setSearch} elem_index={elem_index} type={"departure"}/>
								</Grid>
								{
									!elem.same_dest && (
										<Grid item md={3} sm={6} xs={12} style={{ position: "relative" }} >
											<StationInput label={t("cart-material.station-return")} search={search} setSearch={setSearch} elem_index={elem_index} type={"return"}/>
											{
												(matches || isTablet) && (
													<div className={classes.separator}/>
												)
											}
										</Grid>
									)
								}
							</Grid>
						}
	      		      	<Grid item container sm={12}>
	      		      		<Fragment>
						      	<Grid item lg={3} sm={6} xs={12} className={matches ? classes.gridTop : classes.paddingBottom8}>
						      		{
						      			matches && (
						      				<Typography variant="overline" className={ classes.addSearch }>{t("cars.driver_age")}</Typography>
					      				)
						      		}
			      		    		<Select
									  	MenuProps={{ disableScrollLock: true }}
				      		    		variant="outlined"
				      		    		value={ageArray.indexOf(elem.age)}
		  		      		    		className={matches ? classes.formControlRoot : classes.formControlRoot1}
			      		    	  		onChange={(e) => handleChange(e, "age")}
			      		    		>
			      		    		{
			      		    			ageArray.map((age, age_index) => {
			      		    				return (
			      		    					<MenuItem key={age_index} value={age_index}>{age}</MenuItem>
			      		    				);
			      		    			})
			      		    		}
			      		    		</Select>
			      		    		{
			      		    			!matches && (
						      				<Typography variant="overline" className={ classes.addSearch }>{t("cars.driver_age")}</Typography>
			      		    			)
			      		    		}
						      	</Grid>
						      	<Grid item lg={3} sm={6} xs={12} className={matches ? classes.gridTop : classes.paddingTop8}>
						      	{
						      		matches && (
		  				      			<Typography variant="overline" className={ classes.addSearch }>{t("cars.cars_nbr")}</Typography>
						      		)
						      	}
			      		    		<Select
									  	MenuProps={{ disableScrollLock: true }}
				      		    		variant="outlined"
			      		    	  		value={elem.nb_vehicle}
		  		      		    		className={matches ? classes.formControlRoot : classes.formControlRoot1}
			      		    	  		onChange={(e) => handleChange(e, "nb_car")}
			      		    		>
			      		    		{
			      		    			carsArray.map((car, car_index) => {
			      		    				return (
			      		    					<MenuItem key={car_index} value={car}>{car}</MenuItem>
			      		    				);
			      		    			})
			      		    		}
			      		    		</Select>
			      		    		{
			      		    			!matches && (
		  				      				<Typography variant="overline" className={ classes.addSearch }>{t("cars.cars_nbr")}</Typography>
			      		    			)
			      		    		}
						      	</Grid>
						      	{
						      		!downTablet && (
								      	<Grid item lg={4} sm={6} xs={12} className={matches ? classes.gridTop : ""}>
								      		<FormControl fullWidth variant={"outlined"} className={matches ? classes.formControlRoot2 : classes.formControlRoot3}>
									      		<InputLabel id={"contrat-input"}>{t("cars.optional_contract")}</InputLabel>
						      		    		<Select
												  	MenuProps={{ disableScrollLock: true }}
							      		    		labelId={"contrat-input"}
							      		    		id={"select-contrat-input"}
						      		    	  		value={elem.contract}
					  		      		    		label={t("cars.optional_contract")}
						      		    	  		onChange={(e) => handleChange(e, "contract")}
						      		    		>
						      		    			<MenuItem value={""}>-</MenuItem>
						      		    			<MenuItem value={"FULLY"}>{t("cars.contract_fully")}</MenuItem>
						      		    			<MenuItem value={"FULLY GPS"}>{t("cars.contract_fully_gps")}</MenuItem>
						      		    			<MenuItem value={"SUPER FULLY"}>{t("cars.contract_super_fully")}</MenuItem>
						      		    			<MenuItem value={"SUPER FULLY GPS"}>{t("cars.contract_super_fully_gps")}</MenuItem>
						      		    			<MenuItem value={"GOLD"}>{t("cars.contract_gold")}</MenuItem>
						      		    			<MenuItem value={"GOLD GPS"}>{t("cars.contract_gold_gps")}</MenuItem>
						      		    		</Select>
						      		    	</FormControl>
								      	</Grid>
						      		)
						      	}
	      		      		</Fragment>
	      		      	</Grid>
		      		</Grid>
			    </form>
            </Grid>
        </Grid>
    );
};
export default RenderForm;
