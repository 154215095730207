import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    IconButton,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ItineraryTransportItemAddInput } from "./itineraryTransportItemAddInput";
import { FlashDestination } from "./objects/flashDestination";

type Props = {
    open: boolean,
    onChoose: (destination: FlashDestination) => void,
    onClose: () => void
} & Pick<TooltipProps, 'PopperProps' | 'children'>

export function ItineraryStepItemChangeTooltip(props: Props): JSX.Element {
    const { t } = useTranslation();

    const onChoose = (destination: FlashDestination) => {
        props.onChoose(destination);
        props.onClose();
    };

    return (
        <LightTooltip
            open={props.open}
            title={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                        paddingTop: 0,
                        width: 450,
                        maxWidth: '100%'
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{
                            alignSelf: 'flex-end',
                            marginBottom: 1,
                            marginRight: -3
                        }}
                        onClick={props.onClose}
                    >
                        <Close fontSize="inherit" sx={{ fontSize: 16 }} />
                    </IconButton>
                    <ItineraryTransportItemAddInput
                        label={t('itinerary.change-step-destination')}
                        placeholder={t('itinerary.destination-name')}
                        loading={false}
                        onChoose={onChoose}
                    />
                </Box>
            }
            PopperProps={props.PopperProps}
            sx={(theme) => ({
                [`.${tooltipClasses.tooltip}`]: {
                    backgroundColor: theme.palette.common.white,
                    color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow: theme.shadows[1]
                }
            })}
            placement="top"
            arrow
        >
            {props.children}
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid #ccc'
        }
    }
}));
