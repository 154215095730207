//---- Dependencies ----//
let GoogleMapsLoader = require('google-maps');

export default (component) => { 
    let map = null;
    let key = "";
    let config = JSON.parse(localStorage.getItem("config"));
    for (let i = 0; i < config.keys.length; i++) {
        if (config.keys[i].identifier === "google_api") {
            key = config.keys[i].value;
        }
    }
    GoogleMapsLoader.KEY = key;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
    GoogleMapsLoader.LANGUAGE = "fr";
    GoogleMapsLoader.load(function(google) {
        if (document.getElementById('map-summary-main') !== null) {
            let options = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: {lat: parseFloat(0), lng: parseFloat(0)},
                zoom: 4,
                draggable: true,
                clickableIcons: false,
                disableDefaultUI: true,
                zoomControl: true
            };
            map = new google.maps.Map(document.getElementById('map-summary-main'), options);
            component.setState({google: google, map: map});
        }
    });
};
