import { Transport } from "../objects/transport";

type Options = {
    a: Transport['alternatives'][number],
    b: Transport['alternatives'][number],
    aLocation: google.maps.LatLng,
    bLocation: google.maps.LatLng,
}

export function sortPlaneAlternatives(options: Options): number {
    const aAirStep = options.a.steps.find((item) => {
        return item.vehicle.kind === 'plane';
    });
    const bAirStep = options.b.steps.find((item) => {
        return item.vehicle.kind === 'plane';
    });

    const aHeadDistance = google.maps.geometry.spherical.computeDistanceBetween(
        options.aLocation,
        aAirStep?.path[0] ?? options.aLocation
    );
    const bHeadDistance = google.maps.geometry.spherical.computeDistanceBetween(
        options.aLocation,
        bAirStep?.path[0] ?? options.aLocation
    );
    const aTailDistance = google.maps.geometry.spherical.computeDistanceBetween(
        options.bLocation,
        aAirStep?.path[aAirStep.path.length - 1] ?? options.bLocation
    );
    const bTailDistance = google.maps.geometry.spherical.computeDistanceBetween(
        options.bLocation,
        bAirStep?.path[bAirStep.path.length - 1] ?? options.bLocation
    );

    const aDistance = aHeadDistance + aTailDistance;
    const bDistance = bHeadDistance + bTailDistance;

    if (aDistance < bDistance) {
        return -1;
    }

    return 1;
}
