import { useSelector } from "react-redux";
import axios from "axios";
import { flatten } from "lodash";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import { Flight } from "../../Itinerary/network/flight";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (flightId: number) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (flights: Flight[]) => Promise<void> | void,
    onError: (error: Error) => Promise<void> | void,
    onFinally: () => void
}>

export function useFlightPnrEmit(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;

    return async (flightId) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const flights = await makeRequest({
                    tripId,
                    version,
                    flightId,
                });
                if (options.onSuccess) {
                    await options.onSuccess(flatten(flights));
                }
            } catch (error: any) {
                if (options.onError) {
                    await options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    flightId: number,
}

async function makeRequest(options: RequestOptions): Promise<Flight[][]> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.post<Flight[][]>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/booking/queue_for_flight_issuance/`,
            {
                item: options.flightId
            },
            {
                headers,
                params: {
                    product_types: 6
                }
            }
        );
        return response.data;
    }

    throw new Error('Could not make queue_for_flight_issuance request.');
}
