//Dependencies
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//Core
import { Alert, AlertTitle, Box } from '@mui/material';
import DOMPurify from "dompurify";
//Common
import { isProductSamePackage } from './utils/isProductSamePackage';
import { AppState } from '../../Reducers/Reducers';

type Props = {
    stackNumber: number | null,
    stackInfoId: number | null
}

export function CartStackedInstructions(props: Props): JSX.Element | null {
    const { t } = useTranslation();
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const info = useMemo(() => {
        return data_trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
    }, [
        props.stackNumber,
        props.stackInfoId,
        data_trip
    ]);

    if (!info?.instructions) {
        return null;
    }

    return (
        <Alert
            variant={'filled'}
            severity={'info'}
            sx={{
                "paddingBottom": 0,
                '& .MuiAlert-message': {
                    paddingBottom: 0.65
                }
            }}
        >
            <AlertTitle>
                {t('shared.good-to-know')}
            </AlertTitle>
            {
                info.instructions &&
                <Box
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.instructions.join('\n')) }}
                    sx={{ marginTop: 2 }}
                />
            }
        </Alert>
    );
}
