import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { TripListInvoiceDetails } from "./TripListInvoiceDetails";
import { TripListInvoiceList } from "./TripListInvoiceList";
import { TripListInvoiceCreateCreditNoteModal } from "./TripListInvoiceCreateCreditNoteModal";
import { LoadingBackDrop } from '../../Common/LoadingBackdrop';
import { recreateInputsFromInvoice } from "./Functions/recreateInputsFromInvoice";
import { downloadBlob } from "./Functions/downloadBlob";
import { useGenerateInvoice } from './Functions/useGenerateInvoice';
import { useAwsFileDownload } from "./Functions/useAwsFileDownload";
import { useSendInvoice } from "./Functions/useSendInvoice";
import { isAfter } from 'date-fns';

function TripListInvoice(props) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const owner = useSelector((store) => store.trip.all_data && store.trip.all_data.owner);
    const client = useSelector((store) => store.trip.data_trip && store.trip.data_trip.contact_traveler_detail);
    const startDate = useSelector((store) => store.trip.data_trip && store.trip.data_trip.start_date);
    const endDate = useSelector((store) => store.trip.data_trip && store.trip.data_trip.end_date);
    const destination = useSelector((store) => store.trip.data_trip && store.trip.data_trip.destination);
    const tripName = useSelector((store) => store.trip.all_data && store.trip.all_data.name);
    const travelers = useSelector((store) => store.trip.data_trip && store.trip.data_trip.travelers);
    const tripReference = useSelector(store => store.trip.all_data && store.trip.all_data.trip_reference);
    const data_trip = useSelector(store => store.trip.data_trip);
    const currency = useSelector(state => state.trip.currency);
    const invoices = useSelector(store => store.trip.invoices);
    const trip = useSelector((state) => state.menu.trip_info);
    const to_show_version = useSelector((state) => state.menu.to_show_version);
    const versions = useSelector((store) => store.menu.trip_info && store.menu.trip_info.data);
    const [page, setPage] = useState(0);
    const [invoiceType, setInvoiceType] = useState('INVOICE');
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openCreateCreditNoteModal, setOpenCreditNoteModal] = useState(false);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const displayedVersion = to_show_version !== null ? to_show_version : trip?.current_version;
    const version_number = versions !== null ? versions.map((version) => version.status_contract === 'CONFIRMED' ? version.status_modification : '').indexOf('FIXED_PV') : -1;
    const current_version = (
        (version_number !== -1 || quotation_code === 'cercledesvoyages') &&
        versions !== null ?
            versions.find(item => item.id === displayedVersion) :
            undefined
    );
    const leadTravelerEmail = trip?.data.find((item) => {
        return item.id === displayedVersion;
    })?.contact_traveler_detail?.email;
    const generate = useGenerateInvoice({
        setLoading,
        owner,
        client,
        startDate,
        endDate,
        data_trip,
        tripReference,
        destination,
        tripName,
        travelers,
        currency,
        onClose: () => null
    });
    const send = useSendInvoice({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(t('menu.invoice.invoice-sent'), { variant: 'success' });
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        onFinally() {
            setLoading(false);
        }
    });
    const download = useAwsFileDownload({ setLoading });

    const onSeeInvoice = (invoice) => {
        if (props.crmData) {
            onPreviewInvoicePdf(invoice);
        } else {
            setSelectedInvoice(invoice);
            setPage(1);
        }
    };

    const onGenerateInvoice = (invoice) => {
        const [details, products] = recreateInputsFromInvoice(invoice);
        const totalWithoutTaxes = products.reduce((prev, current) => {
            return prev + parseFloat(current.totalWithoutTax, 10);
        }, 0);
        const totalFlightTaxes = products.reduce((prev, current) => {
            return prev + parseFloat(current.flightTax, 10);
        }, 0);
        const totalAmount = products.reduce((prev, current) => {
            return prev + parseFloat(current.totalWithoutTax, 10) + parseFloat(current.flightTax, 10);
        }, 0);
        generate({
            invoice,
            detailsInputs: details,
            productInputs: products,
            totalWithoutTaxes,
            totalFlightTaxes,
            totalAmount
        });
    };

    const onDownloadInvoicePdf = (invoice) => {
        const date = window.moment.utc(invoice.accounting_date).format('DD-MM-YYYY');
        download(
            invoice.document,
            downloadBlob(
                invoice.invoice_type === 'INVOICE' ?
                    `${t('menu.invoice.invoice')}-${date}` :
                    `${t('menu.invoice.credit-note')}-${date}`
            )
        );
    };

    const onPreviewInvoicePdf = (invoice) => {
        download(
            invoice.document,
            (blob) => {
                const url = URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.target = '__blank';
                anchor.href = url;
                anchor.click();
                URL.revokeObjectURL(url);
            }
        );
    };

    const onSendInvoice = (invoice) => {
        if (
            !leadTravelerEmail ||
            leadTravelerEmail.trim().length === 0
        ) {
            enqueueSnackbar(
                t('menu.email-history.lead-traveler-email-not-found'),
                { variant: 'error' }
            );
            return;
        }
        const date = window.moment.utc(invoice.accounting_date).format('DD-MM-YYYY');
        const filename = invoice.invoice_type === 'INVOICE' ?
            `${t('menu.invoice.invoice')}-${date}` :
            `${t('menu.invoice.credit-note')}-${date}`;
        download(
            invoice.document,
            (blob) => {
                //wait so backdrop is not closed instantly
                setTimeout(() => send(invoice.id, filename, blob), 500);
            }
        );
    };

    const onAddInvoice = () => {
        //first verify if last element is not an invoice
        if (invoices && invoices.length > 0) {
            const lastElement = invoices.reduce((prev, current) => {
                if (prev.id > current.id) {
                    return prev;
                }
                return current;
            });
            //if last element is an invoice show warning
            if (lastElement.invoice_type === 'INVOICE') {
            // if (lastElement.invoice_type === 'INVOICE' && !(isAfter(new Date(), new Date(current_version.start_date)) && current_version.status_modification === 'FIXED_PV')) {
                setOpenCreditNoteModal(true);
                return;
            }
        }

        //from here, we can create a new invoice
        setSelectedInvoice(null);
        setPage(1);
        setInvoiceType('INVOICE');
    };

    const onAddCreditNote = () => {
        setSelectedInvoice(null);
        setPage(1);
        setInvoiceType('CREDIT_NOTE');
    };

    const onCloseCreatePage = () => {
        setSelectedInvoice(null);
        setPage(0);
    };

    const onCloseCreateCreditNoteModal = () => {
        setOpenCreditNoteModal(false);
    };

    return (
        <React.Fragment>
            {
                page === 0 &&
                <TripListInvoiceList
                    onAddInvoice={onAddInvoice}
                    onAddCreditNote={onAddCreditNote}
                    onSee={onSeeInvoice}
                    onGenerate={onGenerateInvoice}
                    onDownload={onDownloadInvoicePdf}
                    onPreview={onPreviewInvoicePdf}
                    onSend={onSendInvoice}
                    setLoading={setLoading}
                    crmData={props.crmData}
                />
            }
            {
                page === 1 &&
                <TripListInvoiceDetails
                    invoiceType={invoiceType}
                    invoice={selectedInvoice}
                    onClose={onCloseCreatePage}
                />
            }
            <TripListInvoiceCreateCreditNoteModal
                open={openCreateCreditNoteModal}
                onClose={onCloseCreateCreditNoteModal}
            />
            <LoadingBackDrop open={loading} />
        </React.Fragment>
    );
}

export default TripListInvoice;
