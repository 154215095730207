import React, { PropsWithChildren, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    alwaysShow?: boolean
}

export function CartTextsConstructionWrapper(props: PropsWithChildren<Props>) {
    const step = useSelector((state: AppState) => state.cart.step);
    const rendered = useRef(false);

    if (step !== 2 && !rendered.current && !props.alwaysShow) {
        return null;
    }

    rendered.current = true;

    return (
        <Box sx={{ display: step === 2 || props.alwaysShow ? 'block' : 'none' }}>
            {props.children}
        </Box>
    );
}