import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useSnackbar } from "notistack";
import axios from "axios";
import { isNumber } from "lodash";

import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: "20px",
        padding: 0,
        position: "relative",
        textTransform: "capitalize",
        display: "block",
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)'
  	},
    inputStepper: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: 12
        },
        "& .mui-jss-MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 20
        }
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 16px !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    arrowDown: {
        margin: '6px 5px',
        position: 'absolute',
        right: 0
    }
}));
const MobileTripBudget = ({ dialog_type, setDialogType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const bdd_cart = useSelector((store) => {
        return store.accommodation.cart;
    });
    const car_cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const flight_cart = useSelector((store) => {
        return store.flight.cart;
    });
    const manual_item_list = useSelector((store) => {
        return store.cart.manual_item_list;
    });
    const transfers_cart = useSelector((store) => {
        return store.transfers.cart;
    });
    const poi_cart = useSelector((store) => {
        return store.poi.cart;
    });
    const trip_budget = useSelector((store) => {
        return store.trip.budget;
    });
    const trip_currency = useSelector((store) => {
        return store.trip.currency;
    });

    const [currencyCost, setCurrencyCost] = useState([]);
    const [nbItems, setNbItems] = useState(0);
    const [customCost, setCustomCost] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [remainingBudget, SetRemainingBudget] = useState(0);
    const [value, setInputValue] = useState("");
    const [error, setError] = useState(false);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const getTaxesPrice = useGetTaxesPrice();

    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [trip_budget, poi_cart, transfers_cart, manual_item_list, flight_cart, car_cart, bdd_cart]);

    const getData = () => {
        let custom_cost = false;
        let currency_cost = [];
        let nb_items = 0;
        if (data_trip !== null && data_trip.prices_flight.length > 0) {
            let client_price = Object.assign({}, data_trip.prices_flight[GetPricesId(data_trip.prices_flight, user.client, user)]);
            if (data_trip.flight_taxes !== null) {
                client_price.selling_price = parseFloat(client_price.selling_price) + parseFloat(data_trip.flight_taxes);
            }
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = true;
        }
        if (data_trip !== null && data_trip.prices_terrestrial.length > 0) {
            const client_price = data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, user.client, user)];
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = true;
        }
        if (data_trip !== null && data_trip.prices_stack_product.length > 0) {
            const client_price = data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, user.client, user)];
            currency_cost = checkCurrencyCost(currency_cost, client_price);
            custom_cost = true;
        }
        if (user !== undefined && user !== null && user.client !== undefined && user.client !== null) {
            for (let i = 0; i < flight_cart.length; i++) {
                if (flight_cart[i].is_optional === false && flight_cart[i].is_displayed !== undefined && flight_cart[i].is_displayed !== false && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false)) {
                    nb_items++;
                }
                if (flight_cart[i].is_optional === false && !isProductPackaged({ product: flight_cart[i], stackInfos: data_trip?.stack_info ?? null }) && flight_cart[i].prices && flight_cart[i].prices.length > 0 && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false) && (flight_cart[i].booking_status === null || (flight_cart[i].booking_status !== null && flight_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    let more_option = 0;
                    flight_cart[i].outbounds.map((outbound) => {
                        let leg = outbound.legs[0];
                        if (leg.paid_options.length > 0) {
                            leg.paid_options.map((option) => {
                                more_option += parseFloat(option.price);
                            });
                        }
                    });
                    let client_price = Object.assign({}, flight_cart[i].prices[GetPricesId(flight_cart[i].prices, user.client, user)]);
                    client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(flight_cart[i].taxes) + parseFloat(more_option);
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < bdd_cart.length; i++) {
                if (bdd_cart[i].is_optional === false && bdd_cart[i].is_displayed !== undefined && bdd_cart[i].is_displayed !== false && (bdd_cart[i].is_available !== undefined && bdd_cart[i].is_available !== false)) {
                    nb_items++;
                }
                if (bdd_cart[i].is_optional === false && !isProductPackaged({ product: bdd_cart[i], stackInfos: data_trip?.stack_info ?? null }) && bdd_cart[i].prices && bdd_cart[i].prices.length > 0 && (bdd_cart[i].is_available !== undefined && bdd_cart[i].is_available !== false) && (bdd_cart[i].booking_status === null || (bdd_cart[i].booking_status !== null && bdd_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = bdd_cart[i].prices[GetPricesId(bdd_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < poi_cart.length; i++) {
                if (poi_cart[i].is_optional === false && poi_cart[i].is_displayed !== undefined && poi_cart[i].is_displayed !== false && (poi_cart[i].is_available !== undefined && poi_cart[i].is_available !== false)) {
                    nb_items++;
                }
                if (poi_cart[i].is_optional === false && !isProductPackaged({ product: poi_cart[i], stackInfos: data_trip?.stack_info ?? null }) && poi_cart[i].prices !== null && poi_cart[i].prices.length > 0 && (poi_cart[i].is_available !== undefined && poi_cart[i].is_available !== false) && (poi_cart[i].booking_status === null || (poi_cart[i].booking_status !== null && poi_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = poi_cart[i].prices[GetPricesId(poi_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < transfers_cart.length; i++) {
                if (transfers_cart[i].is_optional === false && transfers_cart[i].is_displayed !== undefined && transfers_cart[i].is_displayed !== false && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false)) {
                    nb_items++;
                }
                if (transfers_cart[i].is_optional === false && !isProductPackaged({ product: transfers_cart[i], stackInfos: data_trip?.stack_info ?? null }) && transfers_cart[i].prices !== null && transfers_cart[i].prices.length > 0 && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false) && (transfers_cart[i].booking_status === null || (transfers_cart[i].booking_status !== null && transfers_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = transfers_cart[i].prices[GetPricesId(transfers_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
            for (let i = 0; i < manual_item_list.length; i++) {
                if (manual_item_list[i].prices) {
                    if (custom_cost && user.client_full !== undefined && user.client_full.type === 2 && user.client_full.type === manual_item_list[i].creator.client.type) {
                        nb_items += 1;
                        let client_price = Object.assign({}, manual_item_list[i].prices[GetPricesId(manual_item_list[i].prices, user.client, user, true)]);
                        if (manual_item_list[i].taxes !== null) {
                            client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(manual_item_list[i].taxes);
                        }
                        currency_cost = checkCurrencyCost(currency_cost, client_price);
                    } else if (manual_item_list[i].is_optional === false) {
                        nb_items += 1;
                        let client_price = Object.assign({}, manual_item_list[i].prices[GetPricesId(manual_item_list[i].prices, user.client, user, quotation_code === "verdie")]);
                        if (manual_item_list[i].taxes !== null) {
                            client_price.selling_price = parseFloat(client_price.selling_price) + getTaxesPrice(manual_item_list[i].taxes);
                        }
                        currency_cost = checkCurrencyCost(currency_cost, client_price);
                    }
                }
            }
            for (let i = 0; i < car_cart.length; i++) {
                if (car_cart[i].is_optional === false && car_cart[i].is_displayed !== undefined && car_cart[i].is_displayed !== false && (car_cart[i].is_available !== undefined && car_cart[i].is_available !== false)) {
                    nb_items++;
                }
                if (car_cart[i].is_optional === false && !isProductPackaged({ product: car_cart[i], stackInfos: data_trip?.stack_info ?? null }) && car_cart[i].prices !== null && car_cart[i].prices.length > 0 && (car_cart[i].is_available !== undefined && car_cart[i].is_available !== false) && (car_cart[i].booking_status === null || (car_cart[i].booking_status !== null && car_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                    const client_price = car_cart[i].prices[GetPricesId(car_cart[i].prices, user.client, user)];
                    currency_cost = checkCurrencyCost(currency_cost, client_price);
                }
            }
        }
        let total_cost = 0;
        currency_cost.map((cost) => {
            total_cost += cost.cost;
        });
        let remaining_budget = trip_budget === null || trip_budget === 0 ? 0 : trip_budget - total_cost;
        setCurrencyCost(currency_cost);
        setNbItems(nb_items);
        setCustomCost(custom_cost);
        setTotalCost(total_cost);
        SetRemainingBudget(remaining_budget);
    };
    const cancel = () => {
        setInputValue("");
        setDialogType(null);
    };
    const checkCurrencyCost = (currency_cost, client_price) => {
        let added = false;
        for (let i = 0; i < currency_cost.length; i++) {
            if (currency_cost[i].currency.id === client_price.selling_currency) {
                currency_cost[i].cost += parseFloat(client_price.selling_price);
                added = true;
            }
        }
        if (!added) {
            const new_currency_cost = currency_list.find((currency) => {
                return currency.id === client_price.selling_currency;
            });
            currency_cost.push({
                currency: new_currency_cost,
                cost: parseFloat(client_price.selling_price)
            });
        }
        return currency_cost;
    };
    const changeBudget = () => {
        if (value !== "" && (parseFloat(value) - totalCost) >= 0) {
            setError(false);
            updateBudget();
        }
        if ((parseFloat(value) - totalCost) < 0) {
            enqueueSnackbar(t('header.budget_too_low'), {
                variant: "error"
            });
            setError(true);
        }
    };
    const updateBudget = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                budget: value
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                console.log('data:', response);
                dispatch({
                    type: "TRIP_SET_BUDGET",
                    payload: {
                        budget: response.data.budget
                    }
                });
                setDialogType(null);
            }).catch(function (error) {
                //TODO: show snackbar error
            });
        }
    };
    return (
        <Fragment>
            <DialogTitle>{t("header.new_budget")}</DialogTitle>
            <DialogContent>
                <TextField
                    error={error}
                    fullWidth
                    variant={"outlined"}
                    placeholder={t("header.current_budget") + trip_budget + " " + trip_currency.symbol}
                    autoFocus={dialog_type === "budget"}
                    className={classes.inputStepper}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions disableSpacing={ true }>
                <Button onClick={cancel}>{t("accommodation.cancel")}</Button>
                <Button
                    variant={"contained"}
                    className={ classes.orangeButton }
                    onClick={changeBudget}
                >
                    {t("global.validate")}
                </Button>
            </DialogActions>
        </Fragment>
    );
};
export default React.memo(MobileTripBudget);
