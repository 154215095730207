import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import { makeStyles, useTheme, useMediaQuery, Typography, styled } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DateTimePicker, LocalizationProvider } from "@material-ui/pickers";

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';


import i18n from "../../../i18n.jsx";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { SetTripInfo, SetTripInfoVersion, SetTripList, ToShowVersion, toggleMCTOModal, setMCTOData, SetArrayTab, toggleMTRIPModal, setMTRIPData } from "../../../Actions/Menu";
import { SetTripData } from "../../../Actions/Trip";

import GetUserParam from '../LeftMenuPanel/Functions/GetUserParam.js';
import GetStatusColorHexa from "./Functions/GetStatusColorHexa";
import MCTOModal from "./MCTOModal.js";
import MTRIPModal from "./MTRIPModal.tsx";
import GetUserAgency from '../LeftMenuPanel/Functions/GetUserAgency.js';
import GetUser from '../LeftMenuPanel/Functions/GetUser.js';
import GetTrip from '../../Base/Functions/GetTrip.js';
import SetCookie from '../../Common/Functions/SetCookie.js';
import GetCookie from '../../Common/Functions/GetCookie.js';

import "../../../Style/Menu/TripList.css";
import { Destination } from '../../Itinerary/objects/destination.js';
import { TripVersion } from './objects/tripVersion.js';
import { Invoice } from './objects/invoice.js';
import { Traveler } from '../../../Reducers/objects/Traveler.js';
import { AccommodationCart } from '../../Itinerary/objects/accommodationCart.js';
import { CarCart } from '../../Itinerary/objects/carCart.js';
import { TransferCart } from '../../Itinerary/objects/transferCart.js';
import { CustomProduct } from '../../Itinerary/objects/customProduct.js';
import { ManualProduct } from '../../Itinerary/objects/manualProduct.js';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    dueDatePast: {
        "&& .mui-jss-MuiInput-input": {
            color: "red",
            //textAlign: "right",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: 12,
            letterSpacing: 1.25,
            fontWeight: 400
        }
    },
    dueDateFuture: {
        "&& .mui-jss-MuiInput-input": {
            color: "#71DD13",
            //textAlign: "right",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: 12,
            letterSpacing: 1.25,
            fontWeight: 400
        }
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    mctoBorder: {
        background: "#FFFFFF",
        border: `0.772358px solid ${main_color}`,
        boxSizing: "border-box",
        borderRadius: 23
    },
    iconBorder: {
        paddingRight: 4,
        marginLeft: 8,
        borderRadius: 20
    },
    checkBorder: {
        border: "1px solid #71DB14"
    },
    editBorder: {
        border: "1px solid #808080"
    },
    cancelBorder: {
        border: "1px solid #FF0000"
    },
    smallIcon: {
        width: 16,
        height: 16
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    upperCase: {
        textTransform: "uppercase"
    },
    bold: {
        fontWeight: 'bold'
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 12
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    fontSize28: {
        fontSize: 28
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    colorBlue: {
        color: "#2C98F0"
    },
    colorGrey: {
        color: "#808080"
    },
    colorOrange: {
        color: `${main_color}`
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    textJustify: {
        textAlign: 'justify'
    },
    textLeft: {
        textAlign: 'left'
    },
    itemsCenter: {
        alignItems: "center"
    },
    selectBorder: {
        borderRadius: 30
    },
    datesFormat: {
        fontSize: 12,
        fontWeight: 500,
        fontStyle: "italic"
    },
    paddingTop: {
        paddingTop: "2.5%"
    },
    marginLeft: {
        marginLeft: 39
    },
    editableFields: {
        maxWidth: "80%"
    },
    textFieldInput: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        color: '#0000008A',
        paddingTop: 4,
        paddingBottom: 4
    },
    withBorder: {
        borderRadius: "0px 8px 8px 0px"
    },
    withFullBorder: {
        borderRadius: 8
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    pointer: {
        cursor: "pointer"
    },
    formControlRoot: {
        width: "50%"
    },
    formControlRoot2: {
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)',
            fontSize: 12,
            fontWeight: 500,
            color: "black"
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 5
        },
        "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
            paddingRight: '10px',
            fontSize: 12,
            fontWeight: "bold"
        }
    },
    relative: {
        position: "relative"
    },
    absoluteClose: {
        position: "absolute",
        top: 32,
        right: "45%"
    },
    fixedPopper: {
        width: '300px !important'
    },
    noSearch: {
        minWidth: '0px !important'
    }
}));

type AmendmentRequest = {
    name: null | string,
    expected_deposit: null | string,
    has_manual_expected_deposit: boolean,
    status_contract?: string,
    status_modification?: string,
    status_booking_progress?: string
}

const TripListInfoHeader = withRouter(({ router, contact_lead, setContactLead }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const trip_info = useSelector((store: any) => store.menu.trip_info);
    const trip_list = useSelector((store: any) => store.menu.trip_list);
    const trip_list_nb = useSelector((store: any) => store.menu.trip_list_nb);
    const start_date = useSelector((store: any) => store.trip.start_date);
    const end_date = useSelector((store: any) => store.trip.end_date);
    const travelers = useSelector((store: any) => store.trip.travelers);
    const total_cost = useSelector((store: any) => store.trip.total_cost);
    const to_show_version = useSelector((store: any) => store.menu.to_show_version);
    const trip_docs = useSelector((store: any) => store.menu.trip_docs);
    const user = useSelector((store: any) => store.user.user);
    const itinerary_steps = useSelector((store: any) => store.itinerary.itinerary_list);
    const accommodation_cart = useSelector((store: any) => store.accommodation.cart);
    const car_cart = useSelector((store: any) => store.cars_search.cart);
    const flight_cart = useSelector((store: any) => store.flight.cart);
    const poi_cart = useSelector((store: any) => store.poi.cart);
    const transfer_cart = useSelector((store: any) => store.transfers.cart);
    const manual_cart = useSelector((store: any) => store.cart.manual_item_list);
    const applicant = useSelector((store: any) => store.trip.contact_traveler_detail);
    const userList = useSelector((store: any) => store.menu.userList);
    const userAgenceList = useSelector((store: any) => store.menu.userAgenceList);
    const global_margin_value = useSelector((store: any) => store.cart.global_margin_value);
    const invoices = useSelector((store: any) => store.trip.invoices);
    const { enqueueSnackbar } = useSnackbar();
    const [trip_status, setTripStatus] = useState<string>("ONGOING");
    const [status_choices, setStatusChoices] = useState<any>([]);
    const [trip_name, setTripName] = useState<string>("");
    const [all_versions, setAllVersions] = useState<TripVersion[]>([]);
    const [edit_trip_name, setEditTripName] = useState<boolean>(false);
    const [trip_displayed_version, setVersion] = useState<number>(to_show_version !== null ? to_show_version : trip_info.current_version);
    const [displayed_version_num, setVersionNum] = useState<number>(0);
    const [displayed_version_name, setVersionName] = useState<string>("");
    const [destination, setDestination] = useState<null | Destination>(null);
    const [edit_desti, setEditDesti] = useState<boolean>(false);
    const [desti_input, setDestiInput] = useState<string>("");
    const [desti_results, setDestiResults] = useState<Destination[]>([]);
    const [due_date, setDueDate] = useState<moment.MomentInput | null>(moment(end_date).subtract(35, "days"));
    const [nights, setNights] = useState<number>(0);
    const [is_current, setIsCurrent] = useState<boolean>(false);
    const [current_version_id, setCurrentId] = useState<number>(trip_info.current_version);
    const [displayedVersion, setDisplayedVersion] = useState<TripVersion>(trip_info.data.find((data: any) => to_show_version !== null ? to_show_version === data.id : trip_info.current_version === data.id));
    const [trip_completion, setCompletion] = useState<number>(0);
    const [cercleStatus, setCercleStatus] = useState<any>([{ group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" }]);
    const [validity_date, setValidityDate] = useState<Date | moment.MomentInput | null>(new Date());
    const [openBoUser, setOpenBoUser] = useState<boolean>(false);
    const [openSellerUser, setOpenSellerUser] = useState<boolean>(false);
    const [firstConfirmationDate, setFirstConfirmationDate] = useState<string | null>(null);

    //number of [adults, teens, children, babies]
    const [travelers_ages, setTravelersAge] = useState<number[]>([0, 0, 0, 0]);
    const [is_traveler_default, setIsTravelerDefault] = useState<boolean>(true);
    const [rooms, setRooms] = useState<null | number>(null);
    moment.locale(i18n.language);

    const days: moment.MomentInput | null = moment.utc(moment.utc(end_date).format("YYYY-MM-DD")).diff(moment.utc(moment.utc(start_date).format("YYYY-MM-DD")), 'd') + 1;

    useEffect(() => {
        if (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines', 'touristforum'].includes(quotation_code)) {
            GetUserParam(user.client, dispatch, "is_bo_user", t, "tripListInfo");
            GetUserParam(user.client, dispatch, "is_seller_user", t, "tripListInfo");
        } else {
            GetUser(user.client, dispatch);
        }
        setTripName(trip_info.name);
        let sort_versions = trip_info.data.slice();
        sort_versions.sort((a: TripVersion, b: TripVersion) => {
            return a.id - b.id;
        });
        console.log('sort_versions:', sort_versions);
        if (sort_versions.filter((version: TripVersion) => version.status_contract_confirmed_date !== null).length > 1) {
            let first_confirmation_date = sort_versions.filter((version: TripVersion) => version.status_contract_confirmed_date !== null).reduce(function (a: any, b: any) {
                return a.status_contract_confirmed_date < b.status_contract_confirmed_date ? a.status_contract_confirmed_date : b.status_contract_confirmed_date;
            });
            setFirstConfirmationDate(first_confirmation_date);
        } else {
            setFirstConfirmationDate(sort_versions[0].status_contract_confirmed_date);
        }
        setAllVersions(sort_versions);
    }, []);
    useEffect(() => {
        if (!['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines', 'touristforum'].includes(quotation_code) && userList.length !== 0 && trip_info.target_client !== null) {
            GetUserAgency(trip_info.target_client, userList, dispatch);
        }
    }, [userList]);
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (to_show_version !== null) {
            if (pass_check) {
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${to_show_version}/`,
                    headers: headers
                }).then(function (response) {
                    setTripStatus(response.data.status);
                    setDueDate(response.data.due_date);
                    dispatch({
                        type: "TRIP_SET_DATA_TRIP",
                        payload: {
                            data_trip: response.data
                        }
                    });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [to_show_version]);
    useEffect(() => {
        let nb_adults = 0;
        let nb_teens = 0;
        let nb_children = 0;
        let nb_babies = 0;
        for (let traveler_index = 0; traveler_index < travelers.length; traveler_index++) {
            let first_name = travelers[traveler_index].first_name;
            let last_name = travelers[traveler_index].last_name;
            let age: moment.MomentInput | null = moment.utc(end_date).diff(moment.utc(travelers[traveler_index].birth_date), "y");
            if (age > 12 && age < 18) {
                nb_teens += 1;
            } else if (age > 2 && age <= 12) {
                nb_children += 1;
            } else if (age <= 2) {
                nb_babies += 1;
            } else if (age >= 18) {
                nb_adults += 1;
            }
            if ((first_name !== t<string>("global.first_name") && first_name !== t<string>("trip.traveler")) || (last_name !== t<string>("global.last_name") && !last_name.includes(t<string>("global.adult")) && !last_name.includes(t<string>("global.child")))) {
                setIsTravelerDefault(false);
            }
        }
        setTravelersAge([nb_adults, nb_teens, nb_children, nb_babies]);
    }, [travelers]);

    useEffect(() => {
        setIsCurrent(trip_displayed_version === trip_info.current_version);
        for (let version_index = 0; version_index < trip_info.data.length; version_index++) {
            if (trip_info.data[version_index].id === trip_displayed_version) {
                let new_version_index = version_index + 1;
                setVersionNum(new_version_index);

                let displayed_version = trip_info.data[version_index];
                setDestination(displayed_version.destination);
                setDestiResults([displayed_version.destination]);
                setDisplayedVersion(displayed_version);

                if (displayed_version.name !== null) {
                    setVersionName(displayed_version.name);
                }

                if (displayed_version.contact_traveler_detail !== null && contact_lead === null) {
                    setContactLead(displayed_version.contact_traveler_detail);
                }
                if (!['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines', 'touristforum'].includes(quotation_code)) {
                    setTripStatus(displayed_version.status);
                } else {
                    let status = [];
                    if (displayed_version.status_contract !== undefined && displayed_version.status_contract !== null) {
                        status.push({ group: "TRIP_STATUS_CONTRACT", label: displayed_version.status_contract });
                    }
                    if (displayed_version.status_contract === "CONFIRMED" && displayed_version.status_modification !== undefined && displayed_version.status_modification !== null) {
                        status.push({ group: "TRIP_STATUS_MODIFICATION", label: displayed_version.status_modification });
                    }
                    if (displayed_version.status_contract === "CONFIRMED" && displayed_version.status_modification === "FIXED_PV" && displayed_version.status_booking_progress !== undefined && displayed_version.status_booking_progress !== null) {
                        status.push({ group: "TRIP_STATUS_BOOKING_PROGRESS", label: displayed_version.status_booking_progress });
                    }
                    console.log('status : ', status);
                    if (status.length !== 0) {
                        setCercleStatus(status);
                    }
                }
                if (displayed_version.due_date !== null) {
                    setDueDate(displayed_version.due_date);
                } else {
                    let new_date = moment(displayed_version.created_date).add(3, 'd').format();
                    if (moment(new_date).day() === 0) {
                        new_date = moment(new_date).add(1, "d").format();
                    }
                    setDueDate(new Date(new_date));
                    let { pass_check, headers } = CheckBeforeRequest();
                    let request = {
                        due_date: new_date
                    };
                    if (pass_check) {
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_info.data[version_index].id}/`,
                            headers: headers,
                            data: JSON.stringify(request)
                        }).then(function (response) {
                            for (let i = 0; i < response.data.prices.length; i++) {
                                if (response.data.prices[i].selling_price === undefined) {
                                    response.data.prices.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_flight.length; i++) {
                                if (response.data.prices_flight[i].selling_price === undefined) {
                                    response.data.prices_flight.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                                if (response.data.prices_stack_product[i].selling_price === undefined) {
                                    response.data.prices_stack_product.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                                if (response.data.prices_terrestrial[i].selling_price === undefined) {
                                    response.data.prices_terrestrial.splice(i, 1);
                                    i--;
                                }
                            }
                            dispatch(SetTripInfoVersion(response.data));
                            dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                        }).catch(function (error) {
                            console.log(error.responseJSON);
                        });
                    }
                }
                if (displayed_version.validity_date !== null) {
                    setValidityDate(displayed_version.validity_date);
                }
                let temp_status_choices: any = [];
                if (quotation_code === "verdie") {
                    temp_status_choices = ["ONGOING", "TOBOOK_VERDIE", "AGENCY_MOD", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED", "ARCHIVED"];
                    // temp_status_choices = ["ONGOING", "VALIDATED", "TOBOOK_VERDIE", "AGENCY_MOD", "ADDITEM", "ONREQUEST", "BOOKED", "DEPOSIT", "CLOSED", "CANCELLED", "ARCHIVED"]
                } else if (quotation_code === "visiteurs") {
                    temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "TOBOOK", "ADDITEM", "ONREQUEST", "BOOKED", "INPROGRESS", "FINISHED", "CLOSED", "CANCELLED"];
                } else if (quotation_code === "beachcomber") {
                    temp_status_choices = ["ONGOING", "OPTION_OK", "BOOKED"];
                } else if (quotation_code === "marcovasco" || quotation_code === "americavoyages" || quotation_code === "tripadekua") {
                    temp_status_choices = ["ONGOING", "VALIDATED", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED"];
                } else if (quotation_code === "volonline" || quotation_code === "gattinoni") {
                    temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "ADDITEM", "TOBOOK", "BOOKED", "CANCELLED"];
                } else if (['cercledesvoyages', 'continentsinsolites'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CDV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
                    ];
                } else if (['tropicalementvotre'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION_SENT" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "COUNTEROFFER" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TO_TRANSMIT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
                    ];
                } else if (['touristforum'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "NOT_AVAILABLE" }
                    ];
                } else if (['terreslointaines'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
                    ];
                }

                if (!['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines', 'touristforum'].includes(quotation_code) && (displayed_version.status === "INPROGRESS" || displayed_version.status === "FINISHED")) {
                    temp_status_choices.push(displayed_version.status);
                }
                setStatusChoices(temp_status_choices);
            }
        }
        let sort_versions = trip_info.data.slice();
        sort_versions.sort((a: TripVersion, b: TripVersion) => {
            return a.id - b.id;
        });
        if (sort_versions.filter((version: TripVersion) => version.status_contract_confirmed_date !== null).length > 1) {
            let first_confirmation_date = sort_versions.filter((version: TripVersion) => version.status_contract_confirmed_date !== null).reduce(function (a: TripVersion, b: TripVersion) {
                return a.status_contract_confirmed_date < b.status_contract_confirmed_date ? a.status_contract_confirmed_date : b.status_contract_confirmed_date;
            });
            setFirstConfirmationDate(first_confirmation_date);
        } else {
            setFirstConfirmationDate(sort_versions[0].status_contract_confirmed_date);
        }
        setAllVersions(sort_versions);
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check && rooms === null) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/travelers-group/?token=${trip_info.token}`
            }).then(function (response) {
                let temp_rooms = 0;
                let all_groups = response.data.results;
                all_groups.map((group: any) => {
                    if (group.default === true) {
                        temp_rooms += 1;
                    }
                });
                setRooms(temp_rooms);
            });
        }
    }, [trip_displayed_version, trip_info, trip_info.data]);

    useEffect(() => {
        if (invoices !== null && invoices.length !== 0) {
            let { pass_check, headers } = CheckBeforeRequest();
            let invoice: Invoice[] = invoices.filter((el: Invoice) => el.invoice_type === "INVOICE").length;
            let credit_note: Invoice[] = invoices.filter((el: Invoice) => el.invoice_type === "CREDIT_NOTE").length;
            if (pass_check && invoice === credit_note) {
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                    headers: headers,
                    data: JSON.stringify({
                        has_invoice: false
                    })
                }).then((response) => {
                    dispatch(SetTripInfoVersion(response.data));
                }).catch((error) => {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [invoices]);

    useEffect(() => {
        if (days !== undefined && days !== null) {
            let temp_nights: number = days - 1;
            for (let itinerary_index = 1; itinerary_index < itinerary_steps.length; itinerary_index++) {
                if (!moment.utc(itinerary_steps[itinerary_index - 1].end_date).isSame(moment.utc(itinerary_steps[itinerary_index].start_date), "day")) {
                    temp_nights -= 1;
                }
            }
            setNights(temp_nights);
        }
    }, [itinerary_steps]);

    useEffect(() => {
        if (destination !== null && desti_input.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/destinations/?limit=5&reduced=true&ordering=current_version__type&search=${desti_input}&visibility__in=PUBLIC,PRIVATE_TO`
                }).then(function (response) {
                    let data = response.data.results;
                    if (data.length > 0) {
                        setDestiResults(Object.keys(data).map((key) => data[key]));
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [desti_input]);

    useEffect(() => {
        if (trip_info !== null && trip_list !== null) {
            let temp_trip_list = trip_list.slice();
            for (let trip_index = 0; trip_index < temp_trip_list.length; trip_index++) {
                if (temp_trip_list[trip_index].id == trip_info.id) {
                    temp_trip_list[trip_index] = trip_info;
                }
            }
            dispatch(SetTripList(temp_trip_list, false, trip_list_nb));
        }
    }, [trip_info]);

    useEffect(() => {
        let non_default_names = 0;
        let total_requested = 0;
        let total_received = 0;
        let total_products = 0;
        let total_booked = 0;
        let temp_completion = 0;

        let displayed_version = trip_info.data.find((data: TripVersion) => data.id === trip_displayed_version);

        travelers.map((traveler: Traveler) => {
            //count first_name and last_name separetely for more accuracy
            if (traveler.first_name !== t("global.first_name") && traveler.first_name !== t("trip.traveler")) {
                non_default_names += 1;
            } if (traveler.last_name !== t("global.last_name") && !traveler.last_name?.includes(t("global.adult")) && !traveler.last_name?.includes(t("global.child"))) {
                non_default_names += 1;
            }
        });

        accommodation_cart.map((accommodation: AccommodationCart) => {
            if (accommodation.is_displayed === true) {
                total_products += 1;
            }
            if (accommodation.is_displayed === true && accommodation.booking_status !== null && accommodation.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        car_cart.map((car: CarCart) => {
            if (car.is_displayed === true) {
                total_products += 1;
            }
            if (car.is_displayed === true && car.booking_status !== null && car.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        flight_cart.map((flight: any) => {
            if (flight.is_displayed === true) {
                total_products += 1;
            }
            if (flight.is_displayed === true && flight.booking_status !== null && flight.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        transfer_cart.map((transfer: TransferCart) => {
            if (transfer.is_displayed === true) {
                total_products += 1;
            }
            if (transfer.is_displayed === true && transfer.booking_status !== null && transfer.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        poi_cart.map((poi: CustomProduct) => {
            if (poi.is_displayed === true) {
                total_products += 1;
            }
            if (poi.is_displayed === true && poi.booking_status !== null && poi.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        manual_cart.map((product: ManualProduct) => {
            if (product.is_displayed === true) {
                total_products += 1;
            }
            if (product.is_displayed === true && product.booking_status !== null && product.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        if (travelers.length !== 0) {
            temp_completion += 25 * (non_default_names / (travelers.length * 2));
        }

        if (quotation_code === "verdie") {
            trip_docs.map((document: any) => {
                if (document.requested === true) {
                    total_requested += 1;
                    if (document.received === true) {
                        total_received += 1;
                    }
                }
            });
            //div by 0
            if (total_requested !== 0) {
                temp_completion += 25 * (total_received / total_requested);
            }

            //div by 0
            if (total_products !== 0) {
                temp_completion += 50 * (total_booked / total_products);
            }
        } else if (total_products !== 0) {
            temp_completion += 75 * (total_booked / total_products);
        }
        temp_completion = parseFloat((Math.round(temp_completion * 10) / 10).toFixed(1));
        if (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'terreslointaines', 'touristforum'].includes(quotation_code)) {
            temp_completion = displayed_version !== undefined ? displayed_version.completion_percentage : 0;
        }
        setCompletion(temp_completion);
    }, [travelers, trip_docs, accommodation_cart, car_cart, flight_cart, manual_cart, transfer_cart, poi_cart]);
    const handleTripName = () => {
        if (edit_trip_name === true) {
            let request = {
                name: trip_name
            };
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    data: JSON.stringify(request)
                }).then(function (response) {
                    dispatch(SetTripInfo(response.data));
                    dispatch({
                        type: "TRIP_SET_ALL_DATA",
                        payload: {
                            data: response.data
                        }
                    });
                    enqueueSnackbar(t<string>("menu.trip_list.success_change_name"), { variant: "success", disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t<string>("menu.trip_list.failed_change_name"), { variant: "error", disableWindowBlurListener: true });
                });
            }
        }
        setEditTripName(!edit_trip_name);
    };

    const handleChangeDestination = () => {
        if (is_current === false) {
            enqueueSnackbar(t<string>("menu.trip_list.warning_change_desti"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 7000 });
        } else if (edit_desti === true) {
            let request = {
                destination: destination?.id
            };
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                    data: JSON.stringify(request)
                }).then(function (response) {
                    dispatch({
                        type: "TRIP_SET_DATA_TRIP",
                        payload: {
                            data_trip: response.data
                        }
                    });
                    dispatch({
                        type: "MENU_SET_TRIP_INFO_VERSION",
                        payload: {
                            version: response.data
                        }
                    });
                    enqueueSnackbar(t<string>("menu.trip_list.success_change_desti"), { variant: "success", disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t<string>("menu.trip_list.failed_change_desti"), { variant: "error", disableWindowBlurListener: true });
                });

                //update trip info
                //axios({
                //method: "GET",
                //url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                //headers: headers,
                //}).then(function(response){
                //console.log('toto');
                //dispatch(SetTripInfo(response.data));
                //})
            }
        }
        setEditDesti(!edit_desti);
    };

    const checkLeadCart = () => {
        let block_status_change: boolean = false;
        for (let accommodation_index: number = 0; accommodation_index < accommodation_cart.length; accommodation_index++) {
            let accommodation: AccommodationCart = accommodation_cart[accommodation_index];
            if (accommodation.is_displayed === true && accommodation.booking_status === null && (accommodation.group_passenger === null || (accommodation.group_passenger !== null && accommodation.group_passenger?.lead_traveler === null))) {
                block_status_change = true;
            }
        }

        if (block_status_change === false) {
            for (let car_index: number = 0; car_index < car_cart.length; car_index++) {
                let car: CarCart = car_cart[car_index];
                if (car.is_displayed === true && car.booking_status === null && car.driver === null) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (let poi_index: number = 0; poi_index < poi_cart.length; poi_index++) {
                let poi: CustomProduct = poi_cart[poi_index];
                if (poi.is_displayed === true && poi.booking_status === null && (poi.group_passenger === null || (poi.group_passenger !== null && poi.group_passenger?.lead_traveler === null))) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (let transfer_index: number = 0; transfer_index < transfer_cart.length; transfer_index++) {
                let transfer: TransferCart = transfer_cart[transfer_index];
                if (transfer.is_displayed === true && transfer.booking_status === null && (transfer.group_passenger === null || (transfer.group_passenger !== null && transfer.group_passenger?.lead_traveler === null))) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (let manual_index = 0; manual_index < manual_cart.length; manual_index++) {
                let manual_product = manual_cart[manual_index];
                if (manual_product.is_displayed === true && manual_product.booking_status === null && manual_product.group_passenger !== null && manual_product.group_passenger.lead_traveler === null) {
                    block_status_change = true;
                }
            }
        }

        return (block_status_change);
    };

    const handleStatus = (event: { target: { value: any; }; }) => {
        let new_status = event.target.value;
        if (new_status !== trip_status) {
            if (user.client_full.type === 2 && (new_status === "ADDITEM" || new_status === "ONREQUEST" || new_status === "BOOKED" || new_status === "INPROGRESS" || new_status === "FINISHED")) {
                enqueueSnackbar(t<string>("menu.trip_list.agency_cant_change_status"), { variant: "warning", disableWindowBlurListener: true });
            } else if (user.client_full.type === 2 && (trip_status === "ONREQUEST" || trip_status === "BOOKED" || trip_status === "INPROGRESS" || trip_status === "FINISHED" || trip_status === "CANCELLED" || trip_status === "AGENCY_MOD")) {
                enqueueSnackbar(t<string>("menu.trip_list.agency_cant_change_status"), { variant: "warning", disableWindowBlurListener: true });
            } else if (user.client_full.type === 2 && new_status === "AGENCY_MOD" && (is_traveler_default === true || checkLeadCart() === true)) {
                enqueueSnackbar(t<string>("menu.trip_list.default_travelers_cant_change_status"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 10000 });
            } else {
                let { pass_check, headers } = CheckBeforeRequest();
                let new_due_date: moment.MomentInput | null = null;
                if (new_status === "ONGOING" || new_status === "AGENCY_MOD") {
                    new_due_date = moment().add(3, "d").format();
                } else if (new_status === "TOBOOK" && quotation_code === "verdie") {
                    new_due_date = moment().add(6, "d").format();
                }
                if (new_due_date !== null && moment(new_due_date).day() === 0) {
                    new_due_date = moment(new_due_date).add(1, "d").format();
                }

                let request = {
                    status: new_status,
                    due_date: new_due_date
                };
                if (pass_check) {
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        for (let i = 0; i < response.data.prices.length; i++) {
                            if (response.data.prices[i].selling_price === undefined) {
                                response.data.prices.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_flight.length; i++) {
                            if (response.data.prices_flight[i].selling_price === undefined) {
                                response.data.prices_flight.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                            if (response.data.prices_stack_product[i].selling_price === undefined) {
                                response.data.prices_stack_product.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                            if (response.data.prices_terrestrial[i].selling_price === undefined) {
                                response.data.prices_terrestrial.splice(i, 1);
                                i--;
                            }
                        }
                        dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                        //dispatch(SetTripInfoStatus(new_status, GetDataFromCurrentVersion(current_version_id, trip_info.data, to_show_version)));
                        dispatch(SetTripInfoVersion(response.data));
                        enqueueSnackbar(t<string>("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
                        setTripStatus(new_status);
                        setDueDate(new_due_date);
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t<string>("menu.trip_list.failed_change_status"), { variant: "error", disableWindowBlurListener: true });
                    });
                }
            }
        }
    };

    const handleVersion = (event: { target: { value: any; }; }) => {
        let new_version_id: number = event.target.value;
        setVersion(new_version_id);
        dispatch(ToShowVersion(new_version_id));

        //change version number and name
        for (let version_index:number = 0; version_index < all_versions.length; version_index++) {
            let tmp_version:any = { ...all_versions[version_index] };
            if (tmp_version.id === new_version_id) {
                let new_version_index = version_index + 1;
                setVersionNum(new_version_index);
                setVersionName(tmp_version.name);
            }
        }

        let { pass_check, headers } = CheckBeforeRequest();
        //change current version bool
        setIsCurrent(new_version_id === current_version_id);

        //change status & due_date
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${new_version_id}/`,
                headers: headers
            }).then(function (response) {
                setTripStatus(response.data.status);
                setDueDate(response.data.due_date);
            }).catch(function (error) {
                console.log(error.responseJSON);
            });
        }
    };

    const handleChangeCurrent = () => {
        if (is_current === false) {
            let request = {
                current_version: trip_displayed_version
            };

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    headers: headers,
                    data: request
                }).then(function (response) {
                    let data = response.data;
                    dispatch(SetTripInfo(data));
                    setCurrentId(trip_displayed_version);
                    enqueueSnackbar(t<string>("menu.trip_list.success_change_current"), { variant: "success", disableWindowBlurListener: true });
                    setIsCurrent(!is_current);
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t<string>("menu.trip_list.failed_change_current"), { variant: "error", disableWindowBlurListener: true });
                });
            }
        } else {
            enqueueSnackbar(t<string>("menu.trip_list.warning_change_current"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 10000 });
        }
    };

    const handleDueDate = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            due_date: due_date
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                for (let i = 0; i < response.data.prices.length; i++) {
                    if (response.data.prices[i].selling_price === undefined) {
                        response.data.prices.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_flight.length; i++) {
                    if (response.data.prices_flight[i].selling_price === undefined) {
                        response.data.prices_flight.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                    if (response.data.prices_stack_product[i].selling_price === undefined) {
                        response.data.prices_stack_product.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                    if (response.data.prices_terrestrial[i].selling_price === undefined) {
                        response.data.prices_terrestrial.splice(i, 1);
                        i--;
                    }
                }
                dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                dispatch(SetTripInfoVersion(response.data));
                enqueueSnackbar(t<string>("menu.trip_list.success_change_due_date"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t<string>("menu.trip_list.failed_change_due_date"), { variant: "error", disableWindowBlurListener: true });
            });
        }
    };

    const handleValidityDate = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            validity_date: validity_date
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                for (let i = 0; i < response.data.prices.length; i++) {
                    if (response.data.prices[i].selling_price === undefined) {
                        response.data.prices.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_flight.length; i++) {
                    if (response.data.prices_flight[i].selling_price === undefined) {
                        response.data.prices_flight.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                    if (response.data.prices_stack_product[i].selling_price === undefined) {
                        response.data.prices_stack_product.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                    if (response.data.prices_terrestrial[i].selling_price === undefined) {
                        response.data.prices_terrestrial.splice(i, 1);
                        i--;
                    }
                }
                dispatch({
                    type: "TRIP_SET_DATA_TRIP",
                    payload: {
                        data_trip: response.data
                    }
                });
                //dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                dispatch(SetTripInfoVersion(response.data));
                enqueueSnackbar(t<string>("menu.trip_list.success_change_validity_date"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t<string>("menu.trip_list.failed_change_validity_date"), { variant: "error", disableWindowBlurListener: true });
            });
        }
    };

    const createMCTO = () => {
        const { headers } = CheckBeforeRequest();
        let url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/${quotation_code === "verdie" ? 'mcto' : 'pgi'}/generate/`;
        if (quotation_code === 'marcovasco') {
            url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/dave-mcto/notify/`;
        }
        axios({
            method: "GET",
            url,
            headers: headers
        }).then(function (response) {
            enqueueSnackbar(t<string>("menu.trip_list.success_create_mcto"), { variant: "success", disableWindowBlurListener: true });
        }).catch(function (error) {
            console.log(error.responseJSON);
            enqueueSnackbar(t<string>("menu.trip_list.failed_create_mcto"), { variant: "error", disableWindowBlurListener: true });
        });
    };
    const getMCTO = () => {
        const { headers } = CheckBeforeRequest();
        dispatch(toggleMCTOModal());
        let url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/${quotation_code === "verdie" ? 'mcto' : 'pgi'}/`;
        if (quotation_code === 'marcovasco') {
            url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/dave-mcto/`;
        }
        axios({
            method: "GET",
            url,
            headers: headers
        }).then(function (response) {
            dispatch(setMCTOData(response.data.results));
        }).catch(function (error) {
            console.log(error.responseJSON);
        });
    };
    const createZucchetti = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/zucchetti-mcto/generate/`,
            headers: headers
        }).then(function (response) {
            enqueueSnackbar(t<string>("menu.trip_list.success_create_zucchetti"), { variant: "success", disableWindowBlurListener: true });
        }).catch(function (error) {
            console.log(error.responseJSON);
            enqueueSnackbar(t<string>("menu.trip_list.failed_create_zucchetti"), { variant: "error", disableWindowBlurListener: true });
        });
    }
    const createMTRIP = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/mtrip/generate/`,
            headers: headers
        }).then(function (response) {
            enqueueSnackbar(t<string>("menu.trip_list.success_create_mtrip"), { variant: "success", disableWindowBlurListener: true });
        }).catch(function (error) {
            console.log(error.responseJSON);
            enqueueSnackbar(t<string>("menu.trip_list.failed_create_mtrip"), { variant: "error", disableWindowBlurListener: true });
        });
    };
    const getMTRIP = () => {
        const { headers } = CheckBeforeRequest();
        dispatch(toggleMTRIPModal());
        axios({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/mtrip/`,
            headers: headers
        }).then(function (response) {
            dispatch(setMTRIPData(response.data));
        }).catch(function (error) {
            console.log(error.responseJSON);
        });
    };
    const handleCercleStatus = (status: { group: string; label: any; }[]) => {
        const { headers } = CheckBeforeRequest();
        let status_booking_progress: null | string = null;
        let status_contract: null | string = null;
        let status_modification: null | string = null;
        status.map((el) => {
            if (el.group === "TRIP_STATUS_CONTRACT") {
                status_contract = el.label;
            } else if (el.group === "TRIP_STATUS_MODIFICATION") {
                status_modification = el.label;
            } else if (el.group === "TRIP_STATUS_BOOKING_PROGRESS") {
                status_booking_progress = el.label;
            }
        });
        if (status_contract === "CONFIRMED" && status_modification === "FIXED_PV" && total_cost !== null) {
            let request_array = [];
            if (trip_info.agreed_price === null || trip_info.agreed_margin === null || trip_info.agreed_price === '0.00' || current_version_id !== trip_displayed_version) {
                request_array.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                        headers: headers,
                        data: {
                            agreed_price: (status_contract !== undefined && total_cost !== null) ? total_cost.toFixed(2) : undefined,
                            agreed_margin: status_contract !== undefined ? global_margin_value : undefined,
                            current_version: trip_displayed_version
                        }
                    })
                );
            }
            for (let i = 0; i < trip_info.data.length; i++) {
                if (trip_info.data[i].id !== trip_displayed_version) {
                    request_array.push(
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_info.data[i].id}/`,
                            headers: headers,
                            data: {
                                status_contract: "CLOSED"
                            }
                        })
                    );
                }
            }
            request_array.push(
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                    headers: headers,
                    data: {
                        status_contract: status_contract !== null ? status_contract : undefined,
                        status_modification: status_modification !== null ? status_modification : undefined,
                        status_booking_progress: (displayedVersion.status_booking_progress === "IN_PROGRESS" && status_booking_progress !== null) ? status_booking_progress : "IN_PROGRESS"
                        //total_cost: (status_contract !== undefined && total_cost !== null) ? total_cost.toFixed(2) : undefined
                    }
                })
            );
            axios.all([...request_array]).then(axios.spread((...responses) => {
                console.log('responses axios all:', responses);
                enqueueSnackbar(t<string>("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
                if (responses[0]?.config !== undefined && responses[0]?.config?.url?.includes('versions')) {
                    dispatch({
                        type: "TRIP_SET_DATA_TRIP",
                        payload: {
                            data_trip: responses[0]?.data
                        }
                    });
                    dispatch({
                        type: "MENU_SET_TRIP_INFO_VERSION",
                        payload: {
                            version: responses[0]?.data
                        }
                    });
                } else {
                    dispatch({
                        type: "MENU_SET_TRIP_INFO",
                        payload: {
                            trip_info: responses[0]?.data
                        }
                    });
                    dispatch({
                        type: "TRIP_SET_ALL_DATA",
                        payload: {
                            data: responses[0]?.data
                        }
                    });
                }
                for (let i = 1; i < responses.length; i++) {
                    if (responses[i]?.data.id !== trip_displayed_version) {
                        dispatch({
                            type: "MENU_SET_TRIP_INFO_VERSION",
                            payload: {
                                version: responses[i]?.data
                            }
                        });
                    } else if (responses[i]?.data.id === trip_displayed_version) {
                        dispatch({
                            type: "TRIP_SET_DATA_TRIP",
                            payload: {
                                data_trip: responses[i]?.data
                            }
                        });
                        dispatch({
                            type: "MENU_SET_TRIP_INFO_VERSION",
                            payload: {
                                version: responses[i]?.data
                            }
                        });
                        if (status_contract === "CONFIRMED" && status_modification === null) {
                            status.push({ group: "TRIP_STATUS_MODIFICATION", label: responses[i]?.data.status_modification !== null ? responses[i]?.data.status_modification : "FIXED_PV" });
                        }
                        if (status_contract === "CONFIRMED" && status_modification === "FIXED_PV" && status_booking_progress === null) {
                            status.push({ group: "TRIP_STATUS_BOOKING_PROGRESS", label: responses[i]?.data.status_booking_progress !== null ? responses[i]?.data.status_booking_progress : "IN_PROGRESS" });
                        }
                    }
                }
            })).catch(function (error) {
                console.log(error.responseJSON);
            });
        } else if (status_contract === "CONFIRMED" && status_modification === "AMENDMENT") {
            createAmendment();
        } else {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${displayedVersion.id}/`,
                headers: headers,
                data: {
                    status_contract: status_contract !== null ? status_contract : undefined,
                    status_modification: status_modification !== null ? status_modification : undefined,
                    status_booking_progress: status_booking_progress !== null ? status_booking_progress : undefined,
                    total_cost: (status_contract !== undefined && total_cost !== null) ? total_cost.toFixed(2) : undefined
                }
            }).then((response) => {
                enqueueSnackbar(t<string>("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
                dispatch({
                    type: "TRIP_SET_DATA_TRIP",
                    payload: {
                        data_trip: response.data
                    }
                });
                dispatch({
                    type: "MENU_SET_TRIP_INFO_VERSION",
                    payload: {
                        version: response.data
                    }
                });
                if (status_contract === "CLOSED") {
                    console.log('trip_info.data:', trip_info.data);
                    let last_confirmed_version = trip_info.data.find((data: TripVersion) => data.status_contract === 'CONFIRMED');
                    if (last_confirmed_version !== undefined) {
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                            headers: headers,
                            data: {
                                current_version: last_confirmed_version.id
                            }
                        }).then((response) => {
                            dispatch({
                                type: "MENU_SET_TRIP_INFO",
                                payload: {
                                    trip_info: response.data
                                }
                            });
                            dispatch({
                                type: "TRIP_SET_ALL_DATA",
                                payload: {
                                    data: response.data
                                }
                            });
                        });
                    }
                    console.log('last_confirmed_version:', last_confirmed_version);
                }
                let displayed_version = trip_info.data.find((data: TripVersion) => data.id === current_version_id);
                if (status_contract === "CONFIRMED" && status_modification === null) {
                    status.push({ group: "TRIP_STATUS_MODIFICATION", label: displayed_version.status_modification !== null ? displayed_version.status_modification : "FIXED_PV" });
                }
                if (status_contract === "CONFIRMED" && status_modification === "FIXED_PV" && status_booking_progress === null) {
                    status.push({ group: "TRIP_STATUS_BOOKING_PROGRESS", label: displayed_version.status_booking_progress !== null ? displayed_version.status_booking_progress : "IN_PROGRESS" });
                }
                //setCercleStatus(status);
            }).catch(function (error) {
                console.log(error.responseJSON);
            });
        }
        setCercleStatus(status);
    };
    const onCercleStatusChange = (selectedOptions: any) => {
        if (displayedVersion !== null && displayedVersion.contact_traveler_detail !== null && (displayedVersion.contact_traveler_detail.email === null || displayedVersion.contact_traveler_detail.first_name === t('global.first_name') || displayedVersion.contact_traveler_detail.last_name === t('global.last_name') || (travelers.find((el: Traveler) => el.first_name === t('global.first_name') && el.last_name === t('global.last_name')) !== undefined))) {
            enqueueSnackbar(t<string>("menu.trip_list.change_status_warning"), { variant: "warning", disableWindowBlurListener: true });
        } else {
            let sort_group = ["TRIP_STATUS_CONTRACT", "TRIP_STATUS_MODIFICATION", "TRIP_STATUS_BOOKING_PROGRESS"];
            let tmp = selectedOptions.slice();
            let last_elem = tmp[tmp.length - 1];
            if (last_elem.label === "CANCELLED_CDV" || last_elem.label === "CANCELLED_CGV") {
                tmp = [last_elem];
            } else if (last_elem.group === "TRIP_STATUS_CONTRACT" && last_elem.label === "CONFIRMED") {
                tmp = tmp.filter((el: any) => el.group !== last_elem.group);
                tmp = [last_elem];
                tmp.push({ group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" });
            } else if (last_elem.group === "TRIP_STATUS_CONTRACT" && last_elem.label === "QUOTATION") {
                tmp = [last_elem];
            } else if (last_elem.group === "TRIP_STATUS_MODIFICATION" && last_elem.label === "AMENDMENT") {
                tmp = [{ group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" }, { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" }];
            } else {
                tmp = tmp.filter((el: any) => el.group !== last_elem.group);
                tmp.push(last_elem);
                tmp = tmp.sort((a: any, b: any) => {
                    return sort_group.indexOf(a.group) - sort_group.indexOf(b.group);
                });
            }
            handleCercleStatus(tmp);
        }
    };
    const handleBoUser = () => {
        if (user.client_full.type !== 2) {
            setOpenBoUser(!openBoUser);
        }
    };
    const handleSellerUser = () => {
        if (user.client_full.type !== 2) {
            setOpenSellerUser(!openSellerUser);
        }
    };
    const onBoUserChange = (selectedOptions: { id: any }) => {
        console.log('selectedOptions:', selectedOptions);
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                headers: headers,
                data: {
                    user_to: selectedOptions.id === null ? null : selectedOptions.id
                }
            }).then(function (response) {
                dispatch(SetTripInfo(response.data));
                enqueueSnackbar(quotation_code !== "cercledesvoyage" && quotation_code !== "continentsinsolites" && quotation_code !== "touristforum" ? t<string>("menu.trip_list.edit_user_to") : t<string>("menu.trip_list.edit_user_bo"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error);
                enqueueSnackbar(t<string>("menu.trip_list.failed_change_user"), { variant: "error", disableWindowBlurListener: true });
            });
        }
        setOpenBoUser(!openBoUser);
    };
    const onSellerUSerChange = (selectedOptions: { id: any; }) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                headers: headers,
                data: {
                    user_ta: selectedOptions.id
                }
            }).then(function (response) {
                dispatch(SetTripInfo(response.data));
                enqueueSnackbar(quotation_code !== "cercledesvoyage" && quotation_code !== "continentsinsolites" && quotation_code !== "touristforum" ? t<string>("menu.trip_list.edit_user_ta") : t<string>("menu.trip_list.edit_user_seller"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t<string>("menu.trip_list.failed_change_user"), { variant: "error", disableWindowBlurListener: true });
            });
        }
        setOpenSellerUser(!openSellerUser);
    };
    const getProductsToDelete = () => {
        const { pass_check, headers } = CheckBeforeRequest();

        let product_to_delete: any = [];
        let status_contract_confirmed_date = displayedVersion.status_contract_confirmed_date;
        accommodation_cart.map((accommodation: AccommodationCart) => {
            if (moment(accommodation.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/`
                    })
                );
            }
        });
        car_cart.map((car: CarCart) => {
            if (moment(car.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`
                    })
                );
            }
        });
        flight_cart.map((flight: any) => {
            if (moment(flight.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`
                    })
                );
            }
        });
        poi_cart.map((poi: CustomProduct) => {
            if (moment(poi.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`
                    })
                );
            }
        });
        transfer_cart.map((transfer: TransferCart) => {
            if (moment(transfer.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${transfer.id}/`
                    })
                );
            }
        });
        manual_cart.map((manual_product: ManualProduct) => {
            if (moment(manual_product.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`
                    })
                );
            }
        });
        axios.all([...product_to_delete]).catch(function (error) {
            console.log('error delete product:', error);
            //TODO: show snackbar error
        });
    };
    const resetCookie = (data_res: { id: any; }, id:string|null) => {
        let user_token = GetCookie("token");
        let id_user = GetCookie("id_user");
        let trip_token = GetCookie("trip_token");
        let client_user = GetCookie("client_user");
        const crmCookies = {
            crm_token: GetCookie("crm_token"),
            crm_id_user: GetCookie("crm_id_user"),
            crm_client_user: GetCookie("crm_client_user"),
            isFromCrm: GetCookie("isFromCrm")
        };

        //Delete the cookie
        let cookies:string[] = document.cookie.split("; ");
        if (cookies.length !== 0) {
            for (let c = 0; c < cookies.length; c++) {
                let d = window.location.hostname.split(".");
                while (d.length > 0) {
                    let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                    let p = location.pathname.split("/");
                    document.cookie = cookieBase + "/";
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join("/");
                        p.pop();
                    }
                    d.shift();
                }
            }
        }
        SetCookie("trip_id", id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_id_version", data_res.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        if (crmCookies.crm_token) {
            for (const key in crmCookies) {
                SetCookie(key, crmCookies[key as keyof typeof crmCookies], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
        }
        dispatch({ type: "FLUSH_REMOVE_STATE" });
        dispatch({
            type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
            payload: {
                show_language: false,
                language: i18n.language
            }
        });
        GetTrip(user, dispatch, t);
    };

    const createAmendment = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let id = GetCookie("trip_id");
            let version = displayedVersion.id;
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/copy/?copy_booked_product=true`
            }).then(function (response) {
                console.log('data:', response);
                let request: AmendmentRequest = {
                    name: null,
                    expected_deposit: null,
                    has_manual_expected_deposit: false
                };
                if ((['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && displayedVersion.status_contract === "CONFIRMED" && displayedVersion.status_modification === "FIXED_PV") {
                    request.status_contract = "CONFIRMED";
                    request.status_modification = "AMENDMENT";
                    request.status_booking_progress = "IN_PROGRESS";
                }
                getProductsToDelete();
                axios({
                    method: "PATCH",
                    headers: headers,
                    data: JSON.stringify(request),
                    url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${response.data.id}/`
                }).then(function (response1) {
                    enqueueSnackbar(t<string>("header.success_to_create_new_version"), {
                        variant: "success"
                    });
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${id}/`,
                        headers: headers,
                        data: {
                            current_version: version
                        }
                    }).then(function (response2) {
                        resetCookie(response1.data, id);
                        //setVersion(response.data.id);
                        dispatch({
                            type: "MENU_TO_SHOW_VERSION",
                            payload: {
                                to_show_version: response.data.id
                            }
                        });
                        dispatch(SetTripInfo(response2.data));
                    }).catch(function (error) {
                        console.log(error.response);
                    });
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                    enqueueSnackbar(t<string>("header.failed_to_create_new_version"), {
                        variant: "error"
                    });
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t<string>("header.failed_to_create_new_version"), {
                    variant: "error"
                });
            });
        }
    };
    return (
        <Grid>
            <Grid container direction={'row'} style={{ width: '100%', background: '#F3F3F9', padding: 10 }} alignItems={'center'} justify={'space-between'}>
                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize20} ${classes.letterSpacing}`}>
                    {t<string>('global.trip_information')}
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item>
                            {
                                !['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'tropicalementvotre', 'touristforum'].includes(quotation_code) && (
                                    <Select
                                        labelId={"change_status_label"}
                                        id={"change_trip_status"}
                                        value={trip_status}
                                        onChange={handleStatus}
                                        style={{ paddingLeft: 5 }}
                                        MenuProps={{ disableScrollLock: true }}
                                        disableUnderline>
                                        {
                                            status_choices.map((status: string, status_index: number) => {
                                                let new_value = status === "TOBOOK_VERDIE" ? "TOBOOK" : status;
                                                return (
                                                    <MenuItem value={new_value} key={`${status_index}`}>
                                                        <Grid container className={classes.itemsCenter}>
                                                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(status), marginRight: 3 }} />
                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                                { t(`trip.status.${quotation_code}.${status}`) }
                                                                {/*{quotation_code === "volonline" ? t(`trip.status.volonline.${status}`) : t<string>(`menu.trip_list.${status}`)}*/}
                                                            </Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                )
                            }
                            {
                                ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) && (
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        disableClearable
                                        options={status_choices}
                                        value={cercleStatus}
                                        classes={{
                                            popper: classes.fixedPopper
                                        }}
                                        groupBy={(option) => t<string>("menu.trip_list." + option.group)}
                                        getOptionLabel={(option) => t(`trip.status.${quotation_code}.${option.label}`) }
                                        // getOptionLabel={(option) => t<string>("menu.trip_list." + option.label)}
                                        getOptionSelected={(option, value) => option.label === value.label && option.group === value.group}
                                        renderOption={(option, { selected }) => {
                                            return (
                                                <Grid container className={classes.itemsCenter} style={{ width: '100%' }}>
                                                    <FiberManualRecordIcon style={{ color: GetStatusColorHexa(option.label), marginRight: 3 }}/>
                                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                        { t(`trip.status.${quotation_code}.${option.label}`) }
                                                        {/*{ quotation_code === "volonline" ? t(`trip.status.volonline.${option.label}`) : t<string>(`menu.trip_list.${option.label}`)}*/}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Tooltip title={ t(`trip.status.${quotation_code}.${option.label}`) }>
                                                    {/*<Tooltip title={t<string>('menu.trip_list.' + option.label)}>*/}
                                                        <FiberManualRecordIcon style={{ color: GetStatusColorHexa(option.label), marginRight: 3, cursor: 'pointer' }}/>
                                                    </Tooltip>
                                                );
                                            })
                                        }
                                        getOptionDisabled={(option) => {
                                            //if (option.label === "AMENDMENT" && cercleStatus.find((el) => el.group === "TRIP_STATUS_CONTRACT" && el.label !== "CONFIRMED") !== undefined) {
                                            //return true;
                                            //}
                                            if ((option.group === "TRIP_STATUS_MODIFICATION" || option.group === "TRIP_STATUS_BOOKING_PROGRESS") && cercleStatus.find((el:any) => el.group === "TRIP_STATUS_CONTRACT" && el.label === "CONFIRMED") === undefined) {
                                                return true;
                                            }
                                            if ((option.group === "TRIP_STATUS_MODIFICATION" || option.group === "TRIP_STATUS_BOOKING_PROGRESS") && cercleStatus.find((el: any) => el.group === "TRIP_STATUS_CONTRACT") === undefined) {
                                                return true;
                                            }
                                            if (option.group === "TRIP_STATUS_BOOKING_PROGRESS" && cercleStatus.find((el: any) => el.label === "FIXED_PV") === undefined) {
                                                return true;
                                            }
                                            //if (option.label === "AMENDMENT") {
                                            //return true;
                                            //}
                                        }}
                                        onChange={(event, selectedOptions, reason) => {
                                            if (reason === "remove-option") {
                                                setCercleStatus(selectedOptions);
                                            } else if (reason === "select-option") {
                                                onCercleStatusChange(selectedOptions);
                                            } else if (reason === "clear") {
                                                setCercleStatus([]);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                //InputProps={{
                                                //...params.InputProps,
                                                //className: `${classes.genericBorder} ${classes.selectBorder}`,
                                                //}}
                                                variant="standard"
                                                size="small"
                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                                InputLabelProps={{ ...params.InputProps, style: { fontWeight: "bold", color: "#000000" } }}
                                                fullWidth
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Backspace') {
                                                        event.stopPropagation();
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize20} ${classes.letterSpacing}`}>
                            {t<string>("menu.file_nb") + trip_info.trip_reference}
                        </Grid>
                        <Grid item>
                            <Select
                                labelId={"change_version_label"}
                                id={"change_trip_version"}
                                value={trip_displayed_version}
                                onChange={handleVersion}
                                style={{ paddingLeft: 22, paddingTop: 2, paddingBottom: 2 }}
                                MenuProps={{ disableScrollLock: true }}
                                disableUnderline
                                renderValue={(value) => {
                                    if (all_versions.length !== 0) {
                                        let index = all_versions.findIndex((version: TripVersion) => version.id === value);
                                        if (index !== -1) {
                                            let version_new_index = index + 1;
                                            return (<div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.letterSpacing}`}>
                                                { t<string>("menu.trip_list.nb_version") + version_new_index}
                                                {/*{  all_versions[index].name !== null && (" (" + all_versions[index].name + ")")}
                                                {
                                                    (['cercledesvoyages', 'continentsinsolites'].includes(quotation_code)) && all_versions[index].status_contract === "CONFIRMED" && all_versions[index].status_modification === "FIXED_PV" && (
                                                        " - " + t("menu.trip_list.BOOKED")
                                                    )
                                                }
                                                {
                                                    (['cercledesvoyages', 'continentsinsolites'].includes(quotation_code)) && all_versions[index].status_contract === "CONFIRMED" && all_versions[index].status_modification === "AMENDMENT" && (
                                                        " - " + t("menu.trip_list.AMENDMENT")
                                                    )
                                                }
                                                {
                                                    (['cercledesvoyages', 'continentsinsolites'].includes(quotation_code)) && all_versions[index].status_contract === "CLOSED" && (
                                                        " - " + t("menu.trip_list.CLOSED")
                                                    )
                                                } */}
                                            </div>);
                                        }
                                    }
                                }}
                            >
                                {
                                    all_versions.map((version, version_index) => {
                                        let version_new_index = version_index + 1;
                                        return (
                                            <MenuItem value={version.id} key={"version-id-" + version.id}>
                                                <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                    {t<string>("menu.trip_list.nb_version") + version_new_index}
                                                    {version.name !== null && (" (" + version.name + ")")}
                                                    {
                                                        (['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && version.status_contract === "CONFIRMED" && version.status_modification === "FIXED_PV" && (
                                                            " - " + t<string>("menu.trip_list.BOOKED")
                                                        )
                                                    }
                                                    {
                                                        (['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && version.status_contract === "CONFIRMED" && version.status_modification === "AMENDMENT" && (
                                                            " - " + t<string>("menu.trip_list.AMENDMENT")
                                                        )
                                                    }
                                                    {
                                                        (['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && version.status_contract === "CLOSED" && (
                                                            " - " + t<string>("menu.trip_list.CLOSED")
                                                        )
                                                    }
                                                </Grid>
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid item>
                            <Box className={`${classes.textRight} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.letterSpacing} ${classes.paddingTop} ${is_current === true ? classes.colorBlue : classes.colorGrey}`} maxWidth={"sm"}>
                                <Grid container alignItems={'center'}>
                                    <Grid item>
                                        {t<string>("menu.trip_list.current_version")}
                                        {
                                            (['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && is_current && (
                                                <div className={`${classes.genericText} ${classes.fontSize10} ${classes.letterSpacing} ${classes.colorGrey}`}>{`(${t<string>('menu.trip_list.visible_by_client')})`}</div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t<string>("menu.trip_list.change_current_ver")}>
                                            <Switch checked={is_current} color="primary" onChange={handleChangeCurrent} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'row'} style={{ marginTop: 10 }} justify={'space-between'}>
                <Grid item xs={5}>
                    <Grid container direction={'column'}>
                        {
                            ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>
                                    {!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) ? t<string>("menu.trip_list.user") + " : " : t<string>("menu.trip_list.seller") + " : "}
                                    {
                                        !openSellerUser && (
                                            <Fragment>
                                                <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing} ${user.client_full.type !== 2 ? classes.pointer : ""}`} onClick={handleSellerUser}>
                                                    {trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : t<string>("menu.trip_list.not_attributed")}
                                                </span>
                                                <IconButton size="small" onClick={handleSellerUser} className={`${classes.editBorder} ${classes.iconBorder}`}>
                                                    {<CreateOutlinedIcon className={classes.smallIcon} />}
                                                </IconButton>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        openSellerUser && (
                                            <Fragment>
                                                <Autocomplete
                                                    className={classes.formControlRoot}
                                                    options={userAgenceList}
                                                    size={"small"}
                                                    disableClearable
                                                    onChange={(event, selectedOptions) => {
                                                        onSellerUSerChange(selectedOptions);
                                                    }}
                                                    getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    value={trip_info.user_ta}
                                                    inputValue={trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : ""}
                                                    renderInput={(params) => (
                                                        <TextField {...params} className={`${classes.formControlRoot2}`} variant={"outlined"} label={t<string>("menu.trip_list.seller")} style={{ marginTop: 10 }} />
                                                    )}
                                                />
                                                <Close onClick={handleSellerUser} className={`${classes.pointer} ${classes.absoluteClose}`} />
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                            )
                        }
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>
                            {!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) ? "TO : " : t("menu.trip_list.user_bo") + " : "}
                            {
                                !openBoUser && (
                                    <Fragment>
                                        <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing} ${user.client_full.type !== 2 ? classes.pointer : ""}`} onClick={handleBoUser}>
                                            {trip_info.user_to !== null ? trip_info.user_to.first_name + " " + trip_info.user_to.last_name : t<string>("menu.trip_list.not_attributed")}
                                        </span>
                                        {
                                            //(['cercledesvoyages', 'continentsinsolites'].includes(quotation_code)) && (
                                            user.client_full.type !== 2 &&
                                            <IconButton size="small" onClick={handleBoUser} className={`${classes.editBorder} ${classes.iconBorder}`}>
                                                {<CreateOutlinedIcon className={classes.smallIcon} />}
                                            </IconButton>
                                            //)
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                openBoUser && (
                                    <Fragment>
                                        <Autocomplete
                                            className={classes.formControlRoot}
                                            options={userList}
                                            size={"small"}
                                            disableClearable
                                            onChange={(event, selectedOptions) => {
                                                onBoUserChange(selectedOptions);
                                            }}
                                            getOptionLabel={(option) => option.id !== null ? option.first_name + " " + option.last_name : t<string>("menu.trip_list.not_attributed")}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            value={trip_info.user_to}
                                            inputValue={trip_info.user_to ? trip_info.user_to.first_name + " " + trip_info.user_to.last_name : ""}
                                            renderInput={(params) => (
                                                <TextField {...params} className={`${classes.formControlRoot2}`} variant={"outlined"} label={t<string>("menu.trip_list.user_bo")} style={{ marginTop: 10 }} />
                                            )}
                                        />
                                        <Close onClick={handleBoUser} className={`${classes.pointer} ${classes.absoluteClose}`} />
                                    </Fragment>
                                )
                            }
                        </Grid>
                        {
                            !['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code) &&
                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>
                                {!['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code) ? t<string>("menu.trip_list.user") + " : " : t<string>("menu.trip_list.seller") + " : "}
                                {
                                    !openSellerUser && (
                                        <Fragment>
                                            <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing} ${user.client_full.type !== 2 ? classes.pointer : ""}`} onClick={handleSellerUser}>
                                                {trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : "Non attribué"}
                                            </span>
                                            {
                                                user.client_full.type !== 2 &&
                                                <IconButton size="small" onClick={handleSellerUser} className={`${classes.editBorder} ${classes.iconBorder}`}>
                                                    {<CreateOutlinedIcon className={classes.smallIcon} />}
                                                </IconButton>
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    openSellerUser && (
                                        <Fragment>
                                            <Autocomplete
                                                className={classes.formControlRoot}
                                                options={userAgenceList}
                                                size={"small"}
                                                disableClearable
                                                onChange={(event, selectedOptions) => {
                                                    onSellerUSerChange(selectedOptions);
                                                }}
                                                getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                value={trip_info.user_ta}
                                                inputValue={trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : ""}
                                                renderInput={(params) => (
                                                    <TextField {...params} className={`${classes.formControlRoot2}`} variant={"outlined"} label={t<string>("menu.trip_list.seller")} style={{ marginTop: 10 }}/>
                                                )}
                                            />
                                            <Close onClick={handleSellerUser} className={`${classes.pointer} ${classes.absoluteClose}`}/>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                {t<string>("menu.created_by") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    {trip_info.creator.first_name + " " + trip_info.creator.last_name}
                                </span>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                {t<string>("shared.created-date-trip") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    {moment.utc(trip_info.created_date).format("L")}
                                </span>
                            </div>
                        </Grid>
                        {
                            displayedVersion !== undefined && displayedVersion !== null &&
                            <Grid item>
                                <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    {t<string>("shared.created-date-version") + " : "}
                                    <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                        {moment.utc(displayedVersion.created_date).format("L")}
                                    </span>
                                </div>
                            </Grid>
                        }
                        {/*{*/}
                        {/*    all_versions !== undefined && all_versions !== null && all_versions.length > 0 && (*/}
                        {/*        <Fragment>*/}
                        {/*            <Grid item>*/}
                        {/*                {*/}
                        {/*                    !!all_versions[displayed_version_num - 1] && all_versions[displayed_version_num - 1]?.creator !== undefined && all_versions[displayed_version_num - 1]?.creator !== null && (*/}
                        {/*                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>*/}
                        {/*                            {t<string>("menu.created_by") + " : "}*/}
                        {/*                            <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>*/}
                        {/*                                {all_versions[displayed_version_num - 1]?.creator.first_name + " " + all_versions[displayed_version_num - 1]?.creator.last_name}*/}
                        {/*                            </span>*/}
                        {/*                        </div>*/}
                        {/*                    )*/}
                        {/*                }*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item>*/}
                        {/*            {*/}
                        {/*                !!all_versions[displayed_version_num - 1] && all_versions[displayed_version_num - 1]?.created_date !== undefined && all_versions[displayed_version_num - 1]?.created_date !== null && (*/}
                        {/*                    <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>*/}
                        {/*                        {t<string>("shared.created-date") + " : "}*/}
                        {/*                        <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>*/}
                        {/*                            {moment.utc(all_versions[displayed_version_num - 1]?.created_date).format("L")}*/}
                        {/*                        </span>*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            }*/}
                        {/*            </Grid>*/}
                        {/*        </Fragment>*/}
                        {/*    )*/}
                        {/*}*/}
                    </Grid>
                </Grid>
                {
                    !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) &&
                    <Grid item xs={3}>
                        <Grid container direction={'column'}>
                            {
                                all_versions !== undefined && all_versions !== null && all_versions.length > 0 && (
                                    <Fragment>
                                        <Grid item>
                                            {
                                                all_versions[displayed_version_num - 1] !== undefined && all_versions[displayed_version_num - 1]?.last_author !== null && (
                                                    <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                                        {t<string>("menu.edited_by") + " : "}
                                                        <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                                            {all_versions[displayed_version_num - 1]?.last_author.first_name + " " + all_versions[displayed_version_num - 1]?.last_author.last_name}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            {
                                                all_versions[displayed_version_num - 1] !== undefined && all_versions[displayed_version_num - 1]?.modified_date !== null && (
                                                    <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                                        {t<string>("menu.edition_day") + " : "}
                                                        <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                                            {moment.utc(all_versions[displayed_version_num - 1]?.modified_date).format("L")}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </Grid>
                    </Grid>
                }
                {
                    ['cercledesvoyages', 'continentsinsolites', 'touristforum'].includes(quotation_code) &&
                    <Grid item xs={3} />
                }
                {
                    !['marcovasco', 'cercledesvoyages'].includes(quotation_code) &&
                    <Grid item xs={3}>
                        <Grid container direction={'column'}>
                            {
                                trip_info.target_client !== null && trip_info.target_client.name !== null &&
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>{trip_info.target_client.name}</Grid>
                            }
                            {
                                trip_info.target_client !== null && trip_info.target_client.street !== null &&
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>{`${trip_info.target_client.street} ${trip_info.target_client.zip_code !== null ? trip_info.target_client.zip_code : '' } ${trip_info.target_client.city !== null ? trip_info.target_client.city : ''}`}</Grid>
                            }
                            {
                                trip_info.target_client !== null && trip_info.target_client.phone_number !== null &&
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.relative}`}>{trip_info.target_client.phone_number}</Grid>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                {
                    ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) && displayedVersion !== undefined && ((displayedVersion.status_contract === "CONFIRMED" && displayedVersion.status_modification === "FIXED_PV") || displayedVersion.status_contract === "CLOSED") && displayedVersion.status_contract_confirmed_date !== null && (
                        <Grid item xs={5} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.fontSize12} ${classes.letterSpacing}`}>
                            {t<string>("menu.trip_list.confirmation_date")} : <span style={{ color: "#71DD13" }}>{moment(displayedVersion.status_contract_confirmed_date).format("L")}</span>
                        </Grid>
                    )
                }
                {
                    (['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'touristforum', 'terreslointaines'].includes(quotation_code)) && (
                        <Grid item xs={4}>
                            <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                                <Grid item className={` ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    {t<string>("menu.trip_list.validity_date") + ' : '}
                                </Grid>
                                {
                                    validity_date !== null && (
                                        <Grid item>
                                            <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={i18n.language}>
                                                <DateTimePicker
                                                    value={validity_date}
                                                    onChange={(newValue) => setValidityDate(moment(newValue).format())}
                                                    onClose={handleValidityDate}
                                                    InputProps={{ disableUnderline: true }}
                                                    InputAdornmentProps={{ style: { paddingRight: 12, paddingBottom: 5 } }}
                                                    OpenPickerButtonProps={{ style: { padding: "none" } }}
                                                    renderInput={(props) =>
                                                    (<TextField
                                                        {...props}
                                                        className={clsx({ [classes.dueDateFuture]: moment(validity_date).diff(moment(), "d") > 0, [classes.dueDatePast]: moment(validity_date).diff(moment(), "d") <= 0 })}
                                                        helperText=""
                                                        margin="normal"
                                                        size="small"
                                                    />)
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )
                }
                <Grid item xs={["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'touristforum', 'terreslointaines'].includes(quotation_code) ? 3 : 4}>
                    {
                        quotation_code !== "marcovasco" && (
                            <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing} ${classes.paddingTop}`}>
                                    {!['cercledesvoyages', 'continentsinsolites', 'touristforum'].includes(quotation_code) ? t<string>("menu.trip_list.due_date") + ' : ' : t<string>("menu.trip_list.balance_date") + ' : '}
                                </Grid>
                                {
                                    due_date !== null && (
                                        <Grid item className={`${classes.textRight}`}>
                                            <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={i18n.language}>
                                                <DateTimePicker
                                                    value={due_date}
                                                    onChange={(newValue) => setDueDate(moment(newValue).format())}
                                                    onClose={handleDueDate}
                                                    InputProps={{ disableUnderline: true }}
                                                    InputAdornmentProps={{ style: { paddingRight: 12, paddingBottom: 5 } }}
                                                    OpenPickerButtonProps={{ style: { padding: "none" } }}
                                                    renderInput={(props) =>
                                                    (<TextField
                                                        {...props}
                                                        className={clsx({ [classes.dueDateFuture]: moment(due_date).diff(moment(), "d") > 0, [classes.dueDatePast]: moment(due_date).diff(moment(), "d") <= 0 })}
                                                        helperText=""
                                                        margin="normal"
                                                        size="small"
                                                    />)
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                <Grid item xs={5} container>
                    {
                        edit_desti === false && destination !== null && (
                            <Grid item className={`${classes.editableFields} ${classes.fontSize12} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight500} ${classes.letterSpacing}`}>
                                <span className={classes.bold}>{t<string>('shared.main-destination')}</span>{' : ' + destination?.data?.international_name}
                            </Grid>
                        )
                    }
                    {
                        edit_desti === true && (
                            <Grid item xs={edit_desti === true ? 10 : 11}>
                                <Autocomplete
                                    getOptionSelected={(option: any, value: any) => option?.data?.international_name === value?.data?.international_name}
                                    getOptionLabel={(option: any) => option?.data?.international_name}
                                    onInputChange={((event, value) => {
                                        setDestiInput(value);
                                    })}
                                    onChange={((event, value) => {
                                        if (value !== null) {
                                            setDestination(value);
                                        }
                                    })}
                                    noOptionsText={t<string>("menu.trip_list.no_desti_found")}
                                    value={destination}
                                    options={desti_results}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t<string>("header.destination")}
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ style: { fontWeight: "bold", color: "#000000" } }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={edit_desti === true ? 2 : 1}>
                        <Tooltip title={edit_desti === true ? t<string>("menu.trip_list.confirm_desti") : t<string>("menu.trip_list.edit_desti")}>
                            <IconButton size="small" onClick={handleChangeDestination} className={`${edit_desti === true ? classes.checkBorder : classes.editBorder} ${classes.iconBorder}`}>
                                {edit_desti === true ? <CheckIcon style={{ color: "#71DD13" }} className={classes.smallIcon} /> : <CreateOutlinedIcon className={classes.smallIcon} />}
                            </IconButton>
                        </Tooltip>
                        {
                            edit_desti === true && (
                                <Tooltip title={t("global.cancel")}>
                                    <IconButton size="small" className={`${classes.cancelBorder} ${classes.iconBorder}`} onClick={() => {
                                        setEditDesti(false);
                                        setDestination(all_versions[displayed_version_num - 1]?.destination);
                                    }} style={{ color: "#FF0000" }}> <ClearIcon className={classes.smallIcon} /> </IconButton>
                                </Tooltip>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    start_date !== null && end_date !== null && (
                        <Grid item xs={4} className={`${classes.genericText} ${classes.fontWeight500} ${classes.datesFormat} ${classes.letterSpacing}`}>
                            {t<string>("global.from") + "  " + moment.utc(start_date).format("L") + "  " + t<string>("global.to") + "  " + moment.utc(end_date).format("L")}
                            {nights > 0 ? (nights > 1 ? " (" + nights + " " + t("itinerary.nights") + ")" : " (" + nights + " " + t("itinerary.night") + ")") : ""}
                        </Grid>
                    )
                }
                {
                    travelers_ages.length === 4 && (
                        <Grid item xs={3} className={`${classes.genericText} ${classes.fontSize12} ${classes.letterSpacing}`}>
                            <span className={classes.bold}>{t<string>('shared.composition') + ' : '}</span>
                            {rooms !== null && rooms > 0 ? (rooms > 1 ? rooms + " " + t<string>("global.rooms") : rooms + " " + t<string>("global.room")) + ", " : " "}
                            {travelers_ages[0] > 0 ? (travelers_ages[0] > 1 ? travelers_ages[0] + " " + t<string>("flight_search.adults") : travelers_ages[0] + " " + t<string>("flight_search.adult")) : ""}{travelers_ages[1] > 0 ? ", " : ""}
                            {travelers_ages[1] > 0 ? (travelers_ages[1] > 1 ? travelers_ages[1] + " " + t<string>("flight_search.ados") : travelers_ages[1] + " " + t<string>("flight_search.ado")) : ""}{travelers_ages[2] > 0 ? ", " : ""}
                            {travelers_ages[2] > 0 ? (travelers_ages[2] > 1 ? travelers_ages[2] + " " + t<string>("flight_search.children") : travelers_ages[2] + " " + t<string>("flight_search.child")) : ""}{travelers_ages[3] > 0 ? ", " : ""}
                            {travelers_ages[3] > 0 ? (travelers_ages[3] > 1 ? travelers_ages[3] + " " + t<string>("flight_search.babies") : travelers_ages[3] + " " + t<string>("flight_search.baby")) : ""}
                        </Grid>
                    )
                }
            </Grid>
            <Grid container alignItems={'center'}>
                <Grid item xs>
                    <Grid container direction={'row'} alignItems={'center'} justify={'flex-start'}>
                        {
                            edit_trip_name === false && destination !== null && (
                                <Grid item className={`${classes.editableFields} ${classes.fontSize12} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight500} ${classes.letterSpacing}`}>
                                    <span className={classes.bold}>{t<string>('cart.trip_name') + ' : '}</span>
                                    {trip_name !== null && trip_name !== destination?.data?.international_name ? trip_name : t<string>("menu.trip_list.trip_of") + (contact_lead ? contact_lead.title + " " + contact_lead.last_name : '')}
                                </Grid>
                            )
                        }
                        {
                            edit_trip_name === true && destination !== null && (
                                <Grid item xs={edit_trip_name === true ? 10 : 11}>
                                    <TextField onChange={((event) => {
                                        setTripName(event.target.value);
                                    })}
                                        fullWidth
                                        label={t<string>("menu.trip_list.trip_name")}
                                        InputLabelProps={{ style: { fontWeight: "bold", color: "#000000" } }}
                                        defaultValue={trip_name !== null && trip_name !== destination?.data?.international_name ? trip_name : t<string>("menu.trip_list.trip_of") + (contact_lead ? contact_lead.title + " " + contact_lead.last_name : '')}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={edit_trip_name === true ? 2 : 1}>
                            <Tooltip title={edit_trip_name === true ? t<string>("menu.trip_list.confirm_trip_name") : t<string>("menu.trip_list.edit_trip_name")}>
                                <IconButton size="small" onClick={handleTripName} className={`${edit_trip_name === true ? classes.checkBorder : classes.editBorder} ${classes.iconBorder}`}>
                                    {edit_trip_name === true ? <CheckIcon style={{ color: "#71DD13" }} className={classes.smallIcon} /> : <CreateOutlinedIcon className={classes.smallIcon} />}
                                </IconButton>
                            </Tooltip>
                            {
                                edit_trip_name === true && (
                                    <Tooltip title={t<string>("global.cancel")}>
                                        <IconButton size="small" onClick={() => {
                                            setEditTripName(false);
                                        }} style={{ color: "#FF0000" }} className={`${classes.cancelBorder} ${classes.iconBorder}`}>
                                            <ClearIcon className={classes.smallIcon} />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'row'} spacing={1}>
                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.colorBlue} ${classes.letterSpacing}`}>
                    {t<string>("menu.trip_list.completion") + ' : '}
                </Grid>
                <Grid item xs>
                    <Box display="flex" >
                        <Box width="30%">
                            <LinearProgress variant="determinate" value={trip_completion} style={{ paddingTop: "2%", marginTop: "2%", backgroundColor: "rgba(44, 152, 240, 0.36)" }} />
                        </Box>
                        <Box style={{ paddingLeft: "2%" }}>
                            <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.colorBlue} ${classes.letterSpacing}`}>
                                {trip_completion + "%"}
                            </div>
                        </Box>
                    </Box>
                </Grid>
                <Grid item container spacing={2}>
                    {
                        (
                            'visiteurs' === quotation_code ||
                            (
                                quotation_code === 'verdie' &&
                                window.location.hostname.endsWith('.fr')
                            )
                        ) &&
                        user.client_full.type !== 2 &&
                        applicant !== null &&
                        <Grid item style={{ padding: '0px !important' }}>
                            <Tooltip title={applicant.first_name !== t<string>("global.first_name")
                                && applicant.first_name !== t<string>("trip.traveler")
                                && applicant.last_name !== t<string>("global.last_name")
                                && !applicant.last_name.includes(t<string>("global.adult"))
                                ? "" : (t<string>("menu.trip_list.enable_mtrip"))}>
                                <span>
                                    <Button
                                        className={`${classes.mctoBorder} ${classes.colorOrange}`}
                                        onClick={createMTRIP}
                                        disabled={!(applicant.first_name !== t<string>("global.first_name")
                                            && applicant.first_name !== t<string>("trip.traveler")
                                            && applicant.last_name !== t<string>("global.last_name")
                                            && !applicant.last_name.includes(t<string>("global.adult")))}>
                                        <div style={{ marginTop: -3, marginBottom: -3 }}>
                                            {"MTRIP"}
                                        </div>
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={t("global.display") + " " + "MTRIP"}>
                                <IconButton size="small" onClick={getMTRIP} style={{ marginLeft: 5 }} edge="start">
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                    {
                        quotation_code === "volonline" &&
                        <Grid item style={{ padding: '0px !important' }}>
                            <Button
                                className={`${classes.mctoBorder} ${classes.colorOrange}`}
                                onClick={createZucchetti}
                            >
                                <div style={{ marginTop: -3, marginBottom: -3 }}>
                                    ZUCCHETTI
                                </div>
                            </Button>
                        </Grid>
                    }
                    {
                        (quotation_code === 'marcovasco' || quotation_code === "verdie" || (quotation_code === "visiteurs" && user.client_full.type !== 2)) && applicant !== null && (
                            trip_status === "ONREQUEST" || trip_status === "TOBOOK" || trip_status === "DEPOSIT" || trip_status === "BOOKED" || trip_status === "ADDITEM" || trip_status === "AGENCY_MOD") && (
                            <Grid item style={{ padding: '0px !important' }}>
                                <Tooltip title={applicant.first_name !== t<string>("global.first_name")
                                && applicant.first_name !== t<string>("trip.traveler")
                                && applicant.last_name !== t<string>("global.last_name")
                                && !applicant.last_name.includes(t<string>("global.adult"))
                                    ? "" : (quotation_code === "verdie" ? t<string>("menu.trip_list.enable_mcto") : t<string>("menu.trip_list.enable_pgi"))}>
                                    <span>
                                        <Button
                                            className={`${classes.mctoBorder} ${classes.colorOrange}`}
                                            onClick={createMCTO}
                                            disabled={!(applicant.first_name !== t<string>("global.first_name")
                                                && applicant.first_name !== t<string>("trip.traveler")
                                                && applicant.last_name !== t<string>("global.last_name")
                                                && !applicant.last_name.includes(t<string>("global.adult")))}>
                                            <div style={{ marginTop: -3, marginBottom: -3 }}>
                                                {
                                                    quotation_code === 'verdie' &&
                                                    'MCTO'
                                                }
                                                {
                                                    quotation_code === 'marcovasco' &&
                                                    'Dave'
                                                }
                                                {
                                                    !['verdie', 'marcovasco'].includes(quotation_code) &&
                                                    'PGI'
                                                }
                                            </div>
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        t<string>("global.display") + " " +
                                        (() => {
                                            switch (quotation_code) {
                                                case 'veride': return 'MCTO';
                                                case 'marcovasco': return 'Dave';
                                                default: return 'PGI';
                                            }
                                        })()
                                    }
                                >
                                    <IconButton size="small" onClick={getMCTO} style={{ marginLeft: 5 }} edge="start">
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            {
                (['cercledesvoyages', 'continentsinsolites', 'terreslointaines', 'touristforum'].includes(quotation_code)) && trip_info !== null && displayedVersion !== null && (
                    <Grid container direction={"row"} spacing={ 2 }>
                        {
                            trip_info.is_paid && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.is_paid_balance")} />
                                </Grid>
                            )
                        }
                        {
                            !trip_info.is_paid && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.is_no_paid_balance")} />
                                </Grid>
                            )
                        }
                        {
                            displayedVersion !== undefined && displayedVersion.is_paid && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.is_paid_deposit")} />
                                </Grid>
                            )
                        }
                        {
                            trip_info.is_signed && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.is_signed")} />
                                </Grid>
                            )
                        }
                        {
                            displayedVersion !== undefined && displayedVersion.has_invoice && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.has_invoice")} />
                                </Grid>
                            )
                        }
                        {
                            //!displayedVersion.has_invoice && (
                            //<Grid item>
                            //<Chip label={t("menu.trip_list.has_no_invoice")}/>
                            //</Grid>
                            //)
                        }
                        {
                            displayedVersion !== undefined && displayedVersion.has_roadbook && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.has_roadbook")} />
                                </Grid>
                            )
                        }
                        {
                            displayedVersion !== undefined && displayedVersion.has_router && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.has_router")} />
                                </Grid>
                            )
                        }
                        {
                            displayedVersion !== undefined && displayedVersion.is_processed && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.is_processed")} />
                                </Grid>
                            )
                        }
                        {
                            trip_info.minisite_link_sent && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.minisite_link_sent")} />
                                </Grid>
                            )
                        }
                        {
                            trip_info.minisite_link_sent_modified && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.minisite_link_sent_modified")} />
                                </Grid>
                            )
                        }
                        {
                            trip_info.minisite_link_amendment && (
                                <Grid item>
                                    <Chip label={t<string>("menu.trip_list.minisite_link_amendment")} />
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            <MCTOModal />
            <MTRIPModal />
        </Grid>
    );
});
const StyledTextField = styled(TextField)({
    "&& .mui-jss-MuiAutocomplete-input": {
        minWidth: '0px !important',
        cursor: 'pointer'
    }
});
export default React.memo(TripListInfoHeader);
