import axios from 'axios';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//Core
import { Button } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { useCartTotalPrices } from './utils/cartTotalPrices';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import { useSnackbar } from "notistack";
import moment from 'moment';

const useStyles = makeStyles({
    spinRequestContainer: {
        position: 'relative'
    },
    spinRequest: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -16
    }
});

const CartAssistanceEstimation = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [spinRequest, setSpinRequest] = useState(false);
    const assistance_package = useSelector(state => state.cart.assistance_package);
    const assistance_travelers_type = useSelector(state => state.cart.assistance_travelers_type);
    const assistance_option_pocket_wifi = useSelector(state => state.cart.assistance_option_pocket_wifi);
    const pocket_wifi_pickup = useSelector(state => state.cart.pocket_wifi_pickup);
    const pocket_wifi_physical_address = useSelector(state => state.cart.pocket_wifi_physical_address);
    const assistance_pax = useSelector(state => state.cart.assistance_pax);
    const assistance_type = useSelector(state => state.cart.assistance_type);
    const travelers = useSelector(state => state.trip.travelers);
    const providers = useSelector((state) => state.trip.providers);
    const user = useSelector(state => state.user.user);
    const start_date = useSelector((state) => state.trip.start_date);
    const end_date = useSelector((state) => state.trip.end_date);
    const data_trip = useSelector((state) => state.trip.data_trip);
    const prices = useCartTotalPrices();
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const onGetEstimation = () => {
        const { headers } = CheckBeforeRequest();
        for (let i = 0; i < providers.length; i++) {
            if (assistance_type === 1 && providers[i].provider.code === "webassur") {
                setSpinRequest(true);
                let request_product = [];
                request_product.push(
                    axios({
                        method: 'POST',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/calculate_product/`,
                        data: {
                            provider_id: providers[i].provider.id,
                            custom_provider: false,
                            trip_price: Math.ceil(((prices.totalCartCost - prices.totalInsurance) / travelers.length) * assistance_pax.length),
                            contract_reference: quotation_code === 'cercledesvoyages' ? '4181-1' : quotation_code === 'americavoyages' ? '609.941G' : ''
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: 'POST',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/calculate_product/`,
                        data: {
                            provider_id: providers[i].provider.id,
                            custom_provider: false,
                            trip_price: Math.ceil(((prices.totalCartCost - prices.totalInsurance) / travelers.length) * assistance_pax.length),
                            contract_reference: quotation_code === 'cercledesvoyages' ? '4182-1' : quotation_code === 'americavoyages' ? '602.941.0001-1' : ''
                        }
                    })
                );
                if (quotation_code === 'cercledesvoyages') {
                    request_product.push(
                        axios({
                            method: 'POST',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/calculate_product/`,
                            data: {
                                provider_id: providers[i].provider.id,
                                custom_provider: false,
                                trip_price: Math.ceil(((prices.totalCartCost - prices.totalInsurance) / travelers.length) * assistance_pax.length),
                                contract_reference: '9602-1'
                            }
                        })
                    );
                }
                axios.all([...request_product]).then(axios.spread((...responses) => {
                    const prices = [];
                    responses.forEach(response => {
                        prices.push(response.data);
                    });
                    setSpinRequest(false);
                    dispatch({ type: 'CART_SET_ASSISTANCE_PRICES', payload: prices });
                }));
                //.catch(error => {
                //// TODO add error notification
                //setSpinRequest(false);
                //enqueueSnackbar(t('cart-material.assistance-estimate-error'), {variant: 'danger'});
                //console.log(error);
                //});
                break;
            } else if (assistance_type === 0 && providers[i].provider.code === "onspot") {
                setSpinRequest(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/calculate_product/`,
                    data: {
                        provider_id: providers[i].provider.id,
                        type_of_package: assistance_package,
                        type_of_traveler: assistance_travelers_type,
                        agent_email: 'laurie@noxcod.com',
						// "agent_email": user.email,
                        custom_provider: false,
                        // option_vip: assistance_vip
                    }
                }).then(response => {
                    setSpinRequest(false);
                    dispatch({ type: 'CART_SET_ASSISTANCE_PRICES', payload: response.data });
                }).catch(error => {
                    //TODO add error notification
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.assistance-estimate-error'), { variant: 'danger' });
                    console.log(error);
                });
                break;
            } else if (assistance_type === 1 && ["gritchen", "intermundial"].includes(providers[i].provider.code)) {
                setSpinRequest(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/insurances/search_quote/`,
                    data: {
                        provider_id: providers[i].provider.id,
                        reference: `${GetCookie('trip_id')}-${GetCookie('trip_id_version')}`,
                        total_price: Math.ceil(((prices.totalCartCost - prices.totalInsurance) / travelers.length) * assistance_pax.length) * 100,
                        start_date: moment.utc(start_date).format('YYYY-MM-DD'),
                        end_date: moment.utc(end_date).format('YYYY-MM-DD'),
                        origin: "FR",
                        destination: data_trip.destination.data.country.country_code,
                        travelers: travelers.filter((traveler) => assistance_pax.includes(traveler.id)).map((traveler) => {
                            return {
                                traveler_id: traveler.id,
                                price_cents: Math.ceil(((prices.totalCartCost - prices.totalInsurance) / travelers.length) * assistance_pax.length) * 100,
                                currency: 'EUR',
                                email: traveler.email,
                                phone_number: traveler.phone_number
                            };
                        })
                    }
                }).then(response => {
                    setSpinRequest(false);
                    dispatch({ type: 'CART_SET_ASSISTANCE_PRICES', payload: response.data });
                }).catch(error => {
                    //TODO add error notification
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.assistance-estimate-error'), { variant: 'danger' });
                    console.log(error);
                });
                break;
            }
        }
    };
    return (
        <div className={ classes.spinRequestContainer }>
            <Button variant="outlined" onClick={ onGetEstimation } disabled={ spinRequest }>
                { t('cart-material.assistance-run-estimate') }
            </Button>
            { spinRequest && <CircularProgress size={ 24 } className={ classes.spinRequest }/> }
        </div>
    );
};

export default CartAssistanceEstimation;
