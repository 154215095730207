// Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel   from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
// Components
import SummaryHeader from './SummaryHeader';
import PlaneLoader from '../PlaneLoader/PlaneLoader.jsx';
// Functions
import { useGetTaxesPrice } from '../CartMaterial/utils/getTaxesPrice';
import GetTripInfo from './Functions/GetTripInfo';
import GetOutOfCart from './Functions/GetOutOfCart';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
// Actions
import { SetTripDocs } from '../../Actions/Menu';
import { SetSummaryStep } from '../../Actions/Summary';
import GetPrice from './Functions/GetPrice';

const useStyles = makeStyles(theme => ({
	absoluteCenter: {
		margin: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	lightGreen: {
		color: '#76B6C2 !important'
	},
	textCenter: {
		textAlign: 'center'
	},
	container: {
		marginTop: 70,
		paddingTop: 32
	},
	paper: {
		padding: 16
	},
	depositTitle: {
		fontSize: 20,
		fontWeight: 'bold'
	},
	orangeButton: {
		backgroundColor: '#E6592F',
		color: 'white'
	},
	genericText: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		color: '#0000008A'
	},
}));

const SummaryPaymentSuccess = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [missingDocs, setMissingDocs] = useState(0);
	const [payment, setPayment] = useState(null);
    const [paymentChoices, setPaymentChoices] = useState([]);

	const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const trip_info = useSelector(store => store.summary.trip_info);
	const trip_main_info = useSelector(store => store.summary.trip_main_info);
	const trip_docs = useSelector(store => store.menu.trip_docs);
	const selectedProducts = useSelector(store => store.summary.selectedProducts);
	const itinerary = useSelector(store => store.summary.itinerary);
	const { headers } = CheckBeforeRequest();
	const getTaxesPrice = useGetTaxesPrice();

	useEffect(() => {
		dispatch(SetSummaryStep(7));
		setIsLoading(true);
		GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
		
	}, []);
	useEffect(() => {
		let { pass_check, headers } = CheckBeforeRequest();
		if (trip_main_info !== null) {
			getDoc();
			SavePayment();
			if (pass_check){
				axios({
					method: 'GET',
					url: `${API_HREF}client/${window.id_owner}/payment/get_payment_type/`,
					headers: headers
				}).then(function(response){
					let temp_choices = Object.assign({}, response.data);
					temp_choices["DEFAULT"] = t("menu.trip_list.payment_default_type");
					setPaymentChoices(temp_choices);
				}).catch(function(error){
					console.log('error:', error);
					enqueueSnackbar(t("menu.trip_list.payment_type_error"), { variant: "error"});
				})
			}
		}
	}, [trip_main_info]);
	useEffect(() => {
		if (!isLoading) {
			GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch, getTaxesPrice);
		}
	}, [isLoading, getTaxesPrice]);
	useEffect(() => {
		if (trip_docs.length !== 0) {
			let total_requested = 0;
			let total_received = 0;
			trip_docs.map((document) =>{
				if(document.requested === true){
					total_requested += 1;
					if(document.received === true){
						total_received += 1;
					}
				}
			});
			let temp_missing_docs = total_requested - total_received;
			setMissingDocs(temp_missing_docs);
		}
	}, [trip_docs]);
	const getDoc = () => {
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	};
	const SavePayment = () => {
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/payment-save/confirm_payment/?code=${props.location.query.payment_code}&card_holder=${props.location.query.CardHolder}&identifier=${props.location.query.PayID}`,
			headers: headers,
		}).then(function(response){
			console.log('response.data:', response.data);
			setPayment(response.data);
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	}
	const onRedirection = () => {
        props.router.push(`/${window.url_name}/summary-quotation/payment/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
    }
	if (isLoading) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
	return (
		<Grid container style={{display: 'block'}}>
			<Grid item xs={ 12 }>
				<SummaryHeader missingDocs={ missingDocs } props={props}/>
			</Grid>
			<Grid item xs={ 12 }>
				<Container className={ classes.container } maxWidth={ 'lg' }>
					<Paper className={ classes.paper }>
						<Grid container spacing={ 2 }>
							<Grid item xs={ 12 }>
								<Typography className={ classes.depositTitle } align={ 'center' }>{ t('summary-payment.payment-success') }</Typography>
							</Grid>
							<Grid item xs={ 12 }>
								<Grid container direction={ 'column' } spacing={ 2 }>
									<Grid item xs={ 12 } container spacing={ 2 } direction={ 'row' }>
										<Grid item xs={ 3 } className={`${classes.genericText}`}>
											{ t('summary-payment.payment-choice')}
										</Grid>
										<Grid item xs={ 3 } className={`${classes.genericText}`}>
											{t('global.description')}
										</Grid>
										<Grid item xs={ 3 } className={`${classes.genericText}`}>
											{t('menu.trip_list.amount')}
										</Grid>
										<Grid item xs={ 3 } className={`${classes.textCenter} ${classes.genericText}`}>
											{t('global.date')}
										</Grid>
									</Grid>
									{
										payment !== null && (
											<Grid item xs={ 12 } container direction={ 'row' } spacing={ 2 }>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{ paymentChoices[payment.payment_type]}
												</Grid>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{payment.description !== null ? payment.description : "-"}
												</Grid>
												<Grid item xs={ 3 } className={`${classes.genericText}`}>
													{ new Intl.NumberFormat(i18n.language, { style: 'currency', currency: payment.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payment.amount) }
												</Grid>
												<Grid item xs={ 3 } className={`${classes.textCenter} ${classes.genericText}`}>
													{moment.utc(payment.done_date).format("L")}
												</Grid>
											</Grid>
										)
									}
								</Grid>
							</Grid>
							<Grid item container justify={ 'flex-end' } xs={ 12 }>
								<Grid item>
									<Button className={ classes.orangeButton } onClick={ onRedirection }>{ t('summary-payment.payment-redirection') }</Button>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};

export default SummaryPaymentSuccess;