import { kebabCase, uniq, uniqBy } from "lodash";
import { htmlHasEmptyContent } from "./htmlHasEmptyContent";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import { getProviderData } from "./getProviderData";
import { createDomObjectsFromHtml } from "../MailVisualEditor/utils/dom";
import { Rubric } from "../objects/rubric";
import { CreatePageOptions } from "../objects/createPageOptions";

type Options = {
    title: string,
    rubrics: Omit<Rubric, 'id'>[]
}

export async function createContactsContent(options: CreatePageOptions & Options): Promise<Parameters<Template>[0]['contents'][0]> {
    const providers = uniq(
        options.cart.accommodations.map((item) => {
            return item.accommodation.provider;
        }).concat(
            options.cart.cars.map((item) => {
                return item.car.provider;
            })
        ).concat(
            options.cart.flights.map((item) => {
                return item.flight.provider;
            })
        ).concat(
            options.cart.pois.filter((poi) => {
                return !poi.poi.poi_type;
            }).map((item) => {
                return item.poi.provider;
            })
        ).concat(
            options.cart.transfers.map((item) => {
                return item.transfer.provider;
            })
        ).concat(
            options.cart.assistances.map((item) => {
                return item.assistance.provider;
            })
        ).concat(
            options.cart.insurances.map((item) => {
                return item.insurance.provider;
            })
        )
    ).map((id) => {
        return options.manualProviders.find((item) => {
            return item.provider.id === id;
        })?.id
    }).filter((id): id is NonNullable<typeof id> => !!id);

    const providerDetails = (
        await Promise.allSettled(
            providers.map(getProviderData)
        )
    ).filter((item) => {
        if (item.status === 'rejected') {
            return false;
        }
        if (!item.value.contact_details) {
            return false;
        }
        return !htmlHasEmptyContent(item.value.contact_details);
    });

    return {
        type: 'contacts',
        anchor: kebabCase(options.title),
        showInSummary: true,
        summaryTitle: options.title,
        title: options.title,
        providers: uniqBy(
            providerDetails.map((item) => {
                if (item.status === 'fulfilled') {
                    return {
                        name: item.value.provider?.name ?? '',
                        text: item.value.contact_details
                    };
                }
                return null
            }).filter((item): item is NonNullable<typeof item> => !!item),
            (item) => item.name
        ),
        rubrics: options.rubrics.map((item) => {
            const localization = item.localization.find((item) => {
                return item.locale === options.locale;
            });

            const { document } = createDomObjectsFromHtml(localization?.description ?? '');

            for (const styleNode of document.body.getElementsByTagName('style')) {
                styleNode.remove();
            }

            for (const table of document.body.getElementsByTagName('table')) {
                table.replaceWith(...table.children);
            }

            return {
                title: localization?.title ?? '',
                text: document.body.innerHTML
            };
        })
    };
}
