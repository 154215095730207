import React, { PropsWithChildren, useContext, useState } from "react";
import {
    ClickAwayListener,
    IconButton,
    Stack,
    styled
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import { ProviderContext } from "../CartMaterial/utils/providerContext";

type Props = {
    productId: number,
    price: string,
    open: boolean,
    onOpen: () => void,
    onClose: () => void
}

export function ProviderQuotationPriceEdit(props: PropsWithChildren<Props>): React.ReactNode {
    const [priceInput, setPriceInput] = useState('');
    const providerContext = useContext(ProviderContext);

    const onSavePrice = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (providerContext.module === 'quotation') {
            providerContext.onChangePrice(props.productId, parseFloat(priceInput));
        }
        props.onClose();
    };

    const onEditPrice = () => {
        props.onOpen();
        setPriceInput(props.price);
    };

    if (providerContext.module !== 'quotation') {
        return props.children;
    }

    return (
        <Stack direction="row" spacing={1}>
            {
                !props.open &&
                <>
                    {props.children}
                    <IconButton
                        size="small"
                        onClick={onEditPrice}
                        sx={{ fontSize: 16 }}
                    >
                        <Edit fontSize="inherit" />
                    </IconButton>
                </>
            }
            {
                props.open &&
                <>
                    <ClickAwayListener
                        onClickAway={onSavePrice}
                        mouseEvent="onMouseDown"
                    >
                        <PriceInput
                            value={priceInput}
                            onChange={(event) => setPriceInput(event.target.value)}
                        />
                    </ClickAwayListener>
                    <IconButton
                        size="small"
                        onMouseDown={onSavePrice}
                        sx={{ fontSize: 16 }}
                    >
                        <Save fontSize="inherit" />
                    </IconButton>
                </>
            }
        </Stack>
    );
}

const PriceInput = styled('input')((props) => ({
    fontSize: '0.75rem',
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: props.theme.shape.borderRadius
}));
