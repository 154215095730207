import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

export function makeInvoicePurchaseRequest(options) {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/invoice-history/?search=${options.id}&invoice_type__in=PURCHASE`,
            // `${API_HREF}client/${window.id_owner}/invoice-history/?provider_purchase_from_tripversion_id=${options.id}&nvoice_type__in=PURCHASE`,
            {
                headers: headers
            }
        );
    }

    return null;
}