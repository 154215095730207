import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import Icon from "../../../../Img/noun-car-2558234-3.png?roadbook";

export function transformTransfersToCalendarTransports(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        dateString: string,
        locale: number | null,
        t: TFunction
    }
) {
    const date = window.moment.utc(options.dateString, "YYYY-MM-DD").startOf('day');
    const cars = options.cart.transfers.filter((item) => {
        return (
            window.moment.utc(item.transfer.start_date).isSame(date, 'day')
        ) &&
            !item.transfer.is_optional;
    });
    return flatten(
        cars.map((item) => {
            const departure = window.moment.utc(item.transfer.start_date);
            const result = [
                departure.clone().startOf('day').isSame(
                    date,
                    'days'
                ) ?
                    {
                        dateOrder: item.transfer.start_date,
                        url: Icon,
                        hourly: item.type === 'normal' || !item.transfer.hide_hours ?
                            departure.format('HH:mm') :
                            '',
                        description: options.t(
                            'roadbook.program-details-transfer-pickup-title-without-hours',
                            {
                                name: item.type === 'normal' ?
                                    (() => {
                                        const localization = item.transfer.localization.find((item) => {
                                            return item.locale === options.locale;
                                        });
                                        if (localization) {
                                            return localization.name;
                                        } else if (
                                            item.transfer.is_custom
                                        ) {
                                            return item.transfer.custom_product?.title;
                                        }
                                        return item.transfer.name;
                                    })() :
                                    item.transfer.localization?.find((item) => {
                                        return item.locale === options.locale;
                                    })?.name ??
                                    item.transfer.name
                            }
                        )
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
