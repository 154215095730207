import { flatten, groupBy, mapValues } from "lodash";
import { GeoFlashDestination } from "../objects/geoFlashDestination";

export function filterDestinationsBasedOnZoom<T extends GeoFlashDestination>(
    destinations: T[],
    zoom: number
): T[] {
    let type_dest: number | null = null;
    let dest_array: T[] = [];

    if (zoom <= 3) {
        type_dest = 0;
    } else if (zoom > 3 && zoom <= 4) {
        type_dest = 1;
    } else if (zoom > 4 && zoom <= 6) {
        type_dest = 3;
    } else if (zoom > 6 && zoom <= 14) {
        type_dest = 4;
    } else {
        type_dest = 5;
    }
    for (let i = 0; i < destinations.length; i++) {
        if (destinations[i]?.type === type_dest) {
            dest_array.push(destinations[i]!);
        }
        if (type_dest === 4 && destinations[i]?.type === 5) {
            dest_array.push(destinations[i]!);
        }
        if (i + 1 === destinations.length && dest_array.length <= 0 && type_dest < 4) {
            i = 0;
            type_dest += 1;
        }
    }

    const groupped = mapValues(
        groupBy(
            dest_array,
            (item) => item.type
        ),
        (values) => [...values].sort((a, b) => a.note < b.note ? 1 : -1)
    );

    const keys = Object.keys(groupped).map((item) => parseInt(item)).sort().reverse();

    return flatten(keys.map((key) => groupped[key]!)).slice(0, 10);
}
