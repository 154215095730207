import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import reducers from "./Reducers/Reducers";

//const persistedReducer = persistReducer(persistConfig, reducers);
const middlewares = [
    promise(),
    thunk,
    process.env.NODE_ENV !== 'test' ?
        createLogger() :
        undefined
].filter((middleware): middleware is NonNullable<typeof middleware> => {
    return !!middleware;
}) as any[];

const middleware = applyMiddleware(...middlewares);

export const store = createStore(reducers, middleware);
export const create = () => createStore(reducers, middleware);
