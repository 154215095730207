//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useSnackbar } from "notistack";
import axios from "axios";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import moment from 'moment';
import { format } from "date-fns";

//Material UI Components
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import TableRow from '@material-ui/core/TableRow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from "@material-ui/core/TextField";
import TooltipMui from "@material-ui/core/Tooltip";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
//Chart
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import "chartjs-plugin-doughnut-innertext";

//Icons
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Close from "@material-ui/icons/Close";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import AddCircle from '@material-ui/icons/AddCircle';

//Actions
import { addPaymentRemark, changePayRemarkDescription, editPayRemarkDescription, changePayRemarkType, editPayRemarkType, changePayRemarkValue, editPayRemarkValue, emptyPaymentRemarks, removePnrRemark, setFeesRemark, SetPaymentDate, editPaymentDate, setPaymentDone, setAdvancePayment, setCancelEdit, setValidateEdit, setOpenGirReminder, SetPvtRemark } from "../../../Actions/Payment.js";
import { SetDataTrip } from "../../../Actions/Trip.js";

import CheckBeforeRequest from '../../Common/CheckBeforeRequest.js';
import { useCloseDatePicker } from './Functions/useClosePicker.js';
import TripListPaymentRemarks from './TripListPaymentRemarks.js';
import TripListPaymentEditRemarks from './TripListPaymentEditRemarks.js';
import { useCartTotalPrices } from '../../CartMaterial/utils/cartTotalPrices';
import GetCookie from '../../Common/Functions/GetCookie.js';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    tableDepositWidth: {
        width: "90%",
        paddingBottom: 20,
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "unset"
    },
    borderTableCell: {
        borderBottom: "none"
    },
    textCenter: {
        textAlign: "center"
    },
    textLeft: {
        textAlign: "left"
    },
    paddingTop: {
        paddingTop: 12
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    blue: {
        color: "#319FE3"
    },
    purple: {
        color: "#D6057F"
    },
    orange: {
        color: "#EE7203"
    },
    green: {
        color: "#67B92C"
    },
    red: {
        color: "#FF0000"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    tableSpace: {
        paddingBottom: 8
    },
    tableCellSpacer: {
        padding: "8px 0px"
    },
    paymentsSpace: {
        padding: "24px 0px 8px"
    },
    textFieldInput: {
        "borderRadius": 0,
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "fontWeight": 400,
        "color": '#0000008A',
        "paddingTop": 4,
        "paddingBottom": 4,
        "&& .mui-jss-MuiSelect-iconOutlined": {
            display: "none"
        }
    },
    textFieldLabel: {
        fontWeight: 900
    },
    validateButton: {
        backgroundColor: "#E6592F",
        color: "white",
        padding: "8px 60px"
    },
    progress: {
        position: 'relative',
        margin: '4px',
        float: 'left',
        textAlign: 'center'
    },
    barOverflow: { /*Wraps the rotating .bar */
        position: 'relative',
        overflow: 'hidden', /*Comment this line to understand the trick */
        width: '300px',
        height: '150px', /*Half circle (overflow) */
        marginBottom: '-14px' /*bring the numbers up */
    },
    bar: {
        "position": 'absolute',
        "top": 0,
        "left": 0,
        "width": '300px',
        "height": '300px', /*full circle! */
        "borderRadius": '50%',
        "boxSizing": 'border-box',
        '-webkit-box-sizing': 'border-box',
        '-moz-box-sizing': 'border-box',
        "border": '12px solid #B32B02', /*half gray, */
        "borderBottomColor": '#E99C85', /*half azure */
        "borderRightColor": '#E99C85'
    },
    stickyPaper: {
        borderRadius: 16,
        padding: 16,
        position: 'sticky',
        top: 140
    },
    orangeButton: {
        background: "#F7C8BA",
        borderRadius: "20px"
    },
    orangeIcon: {
        color: "#E6592F"
    },
    active: {
        background: '#F5F5F5'
    },
    doughnutContainer: {
        height: 270
    }
}));

let source = null; 

const TripListPayment = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    ChartJS.register(ArcElement, Legend, Tooltip);

    const user = useSelector(state => state.user.user);
    const trip = useSelector(state => state.trip.all_data);
    const start_date = useSelector((store) => store.trip.start_date);
    const language = useSelector(state => state.header.tmp_language);
    const currency_list = useSelector((store) => store.base.currency_list);
    const currency = useSelector(state => state.trip.currency);
    const pois = useSelector(store => store.poi.cart);
    const flights = useSelector(store => store.flight.cart);
    const cars = useSelector(store => store.cars_search.cart);
    const transfers = useSelector(store => store.transfers.cart);
    const accommodations = useSelector(store => store.accommodation.sort_cart);
    const manual_products = useSelector(store => store.cart.manual_item_list);
    const assistance_cart = useSelector(store => store.cart.assistance_cart);
    const fees_remark = useSelector(store => store.payment.fees_remark);
    const payment_remarks = useSelector(store => store.payment.payment_remarks);
    const paymentDone = useSelector(store => store.payment.payment_done);
    const advance_payment = useSelector(store => store.payment.advance_payment);
    const reservation_mode = useSelector(store => store.payment.reservation_mode);
    const trip_id = useSelector(store => store.trip.trip_id);
    const total_cost = useSelector(store => store.trip.total_cost);
    const global_margin_value = useSelector(store => store.cart.global_margin_value);
    const travelers = useSelector(store => store.trip.travelers);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const trip_info = useSelector(store => store.menu.trip_info);

    const [totalPaid, setTotalPaid] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalCost, setTotalCost] = useState([]);
    const [paymentChoices, setPaymentChoices] = useState([]);
    const [paidAmount, setPaidAmount] = useState(0);
    const [openPicker, setOpenPicker] = useState([]);
    const [totalRemark, setTotalRemark] = useState(0);
    const [restToPay, setRestToPay] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [editPayment, setEditPayment] = useState(null);
    const [editFees, setEditFees] = useState(false);
    const [tmpFeesRemark, setTmpFeesRemark] = useState(0);
    const [dataTrip, setDataTrip] = useState(null);
    const [clickDoughnut, setClickDoughnut] = useState(null);
    const [errorIdentifier, setErrorIdentifier] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(t('summary-payment.payment-created'));
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    //const [paymentDone, setPaymentDone] = useState([]);
    const datePickerRef = useRef(null);
    const datePopperRef = useRef(null);
    const doughnutRef = useRef(null);
    const bottomTableRef = useRef(null);
    const rowRef = useRef([]);
    let tableColumn = [t('summary-payment.payment-created'), t('menu.invoice.type'), t('menu.invoice.transaction_number'), t('menu.invoice.receipt_date'), t('shared.description'), t('shared.amount'), t('menu.invoice.payment_source'), t('menu.invoice.accounting_date_sent')];
    const gradients = [];
    const prices = useCartTotalPrices(true);

    if (trip !== null && paymentDone.length !== 0 && document.getElementById('doughnut-payments') !== null) {
        let ctx = document.getElementById('doughnut-payments').getContext('2d');
        paymentDone.forEach(payment => {
            let gradient = ctx.createLinearGradient(0, 0, 150, 150);
            gradient.addColorStop(0, '#E6592F');
            gradient.addColorStop(1, '#721A00');
            gradients.push(gradient);
        });
    }

    const doughnut_data = {
        datasets: [
            {
                data: trip !== null ? paymentDone : [],
                backgroundColor: gradients,
                color: ['white', 'black'],
                borderWidth: 1,
                borderColor: '#FFFFFF',
                hoverOffset: 10,
                hoverBackgroundColor: "#E6592F",
                hoverBorderWidth: 5,
                cutout: '50%'
            }
        ]
    };
    const doughnut_options = {
        //animation: {
        //animateScale: true
        //},
        layout: { padding: 20 },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (context) => {
                        return `${context.raw.description !== null ?( context.raw.description.length > 20 ? context.raw.description.substring(0, 20) + '...' : context.raw.description) : ''} ${context.raw.description !== null && context.raw.description !== '' ? ':' : ''} ${new Intl.NumberFormat(language, { style: 'currency', currency: context.raw.currency.iso_code }).format(parseFloat(context.raw.amount).toFixed(2))}`;
                    }
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        parsing: {
            key: 'amount'
        },
        restToPay
    };
    const doughnut_plugin = [
        {
            id: 'customDataLabels',
            afterDatasetsDraw(chart, args, pluginOptions) {
                const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;
                if (chart.getDatasetMeta(0).data.length !== 0) {
                    data.datasets[0].data.forEach((datapoint, index) => {
                        //Text
                        ctx.fillStyle = data.datasets[0].color[0];
                        ctx.font = "white 14px Roboto";
                        //Control position
                        const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();
                        ctx.textBaseline = "middle";
                        ctx.textAlign = "center";
                        ctx.fillText(`${new Intl.NumberFormat(language, { style: 'currency', currency: datapoint.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(datapoint.amount)}`, x, y);
                    });
                    ctx.save();
                }
            }
        },
        {
            id: 'centerText',
            afterDatasetsDraw(chart, args, pluginOptions) {
                if (chart.getDatasetMeta(0).data.length !== 0) {
                    const ctx = chart.ctx;
                    const data = chart.data;
                    const updateRestToPay = chart.config.options.restToPay;
                    const lineheight = 15;
                    let rest_to_pay_str = t('menu.trip_list.left_to_pay');
                    ctx.restore();
                    const x = chart.getDatasetMeta(0).data[0].x;
                    const y = chart.getDatasetMeta(0).data[0].y;
                    ctx.font = "bold 14px Roboto";
                    ctx.fillStyle = data.datasets[0].color[1];
                    ctx.textAlign = 'center';
                    ctx.textBaseline = "middle";
                    const str = `${rest_to_pay_str} \n${new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(updateRestToPay)}`;
                    const lines = str.split('\n');
                    for (let i = 0; i < lines.length; i++) {
                        ctx.fillText(lines[i], x, y + (i * lineheight));
                    }
                    ctx.save();
                }
            }
        }
    ];
    useCloseDatePicker(datePickerRef, datePopperRef, openPicker, setOpenPicker);
    useEffect(() => {
        let data_trip = trip_info.data.find((trip) => trip.id === trip_info.current_version);
        setDataTrip(data_trip);
        return () => {
            dispatch(emptyPaymentRemarks());
        };
    }, []);
    useEffect(() => {
        if (dataTrip !== null) {
            dispatch(setPaymentDone([]));
            getPayments();
        }
    }, [dataTrip, trip]);
    useEffect(() => {
        if (to_show_version !== null) {
            let data_trip = trip_info.data.find((trip) => trip.id === to_show_version);
            setDataTrip(data_trip);
        }
    }, [to_show_version]);
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/payment/get_payment_type/`,
                headers: headers
            }).then(function (response) {
                let temp_choices = {};
                Object.keys(response.data).map((key) => {
                    if (response.data[key] === 'CB') {
                        temp_choices[key] = `${response.data[key]} (${key})`;
                    } else {
                        temp_choices[key] = response.data[key];
                    }
                })
                temp_choices.DEFAULT = isMobile ? t("menu.trip_list.payment_default_type_short") : t("menu.trip_list.payment_default_type");
                setPaymentChoices(temp_choices);
            }).catch(function (error) {
                enqueueSnackbar(t("menu.trip_list.payment_type_error"), { variant: "error" });
            });
        }
    }, [isMobile]);
    useEffect(() => {
        let tmp_paid = 0;
        paymentDone.map((payment) => {
            tmp_paid += parseFloat(payment.amount) * 100;
        });
        tmp_paid = tmp_paid / 100;
        setTotalPaid(parseFloat(tmp_paid));
        // if (advance_payment !== 0 && totalCost.length !== 0) {
        //     const { headers } = CheckBeforeRequest();
        //     let total_cost = 0;
        //     totalCost.map((currency_cost) => {
        //         let tmp = currency_cost.cost.toFixed(2);
        //         // let tmp = currency_cost.cost.replace(",", ".");
        //         console.log('tmp:', tmp);
        //         // tmp = tmp.replace(/\s/g, '');
        //         total_cost += parseFloat(tmp) * 100;
        //     });
        //     total_cost = total_cost / 100;
        //
        //     let default_name = dataTrip !== null && dataTrip.contact_traveler_detail !== null && dataTrip.contact_traveler_detail.first_name === t('global.first_name') && dataTrip.contact_traveler_detail.last_name === t('global.last_name') && (travelers.find((el) => el.first_name === t('global.first_name') && el.last_name === t('global.last_name')) !== undefined);
        //     if (['cercledesvoyages', 'continentsinsolites'] && parseFloat(tmp_paid) >= parseFloat(advance_payment)) {
        //         let request_array = [];
        //         request_array.push(
        //             axios({
        //                 method: "PATCH",
        //                 url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip.id}/`,
        //                 headers: headers,
        //                 data: {
        //                     is_paid: !dataTrip.is_paid ? true : undefined,
        //                     status_contract: dataTrip.status_contract === "QUOTATION" && !default_name && trip.is_signed ? "CONFIRMED" : undefined,
        //                     status_modification: dataTrip.status_contract === "QUOTATION" && !default_name && trip.is_signed ? "FIXED_PV" : undefined
        //                 }
        //             })
        //         );
        //         request_array.push(
        //             axios({
        //                 method: "PATCH",
        //                 url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/`,
        //                 headers: headers,
        //                 data: {
        //                     is_paid: parseFloat(tmp_paid) >= parseFloat(total_cost) ? true : false,
        //                     agreed_price: dataTrip.status_contract === "QUOTATION" && !default_name && trip.is_signed && total_cost !== null ? total_cost.toFixed(2) : undefined,
        //                     agreed_margin: dataTrip.status_contract === "QUOTATION" && !default_name && trip.is_signed ? global_margin_value : undefined
        //                 }
        //             })
        //         );
        //         axios.all([...request_array]).then(axios.spread((...responses) => {
        //             dispatch({
        //                 type: "TRIP_SET_DATA_TRIP",
        //                 payload: {
        //                     data_trip: responses[0].data
        //                 }
        //             });
        //             dispatch({
        //                 type: "MENU_SET_TRIP_INFO_VERSION",
        //                 payload: {
        //                     version: responses[0].data
        //                 }
        //             });
        //             dispatch({
        //                 type: "MENU_SET_TRIP_INFO",
        //                 payload: {
        //                     trip_info: responses[1].data
        //                 }
        //             });
        //             dispatch({
        //                 type: "TRIP_SET_ALL_DATA",
        //                 payload: {
        //                     data: responses[1].data
        //                 }
        //             });
        //             if (dataTrip.status_contract === "QUOTATION" && trip.is_signed) {
        //                 enqueueSnackbar(t("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
        //             }
        //         })).catch(function (error) {
        //             console.log(error.responseJSON);
        //         });
        //     }
        //     if ((quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolite")) {
        //         let request_array = [];
        //         if (parseFloat(tmp_paid) < parseFloat(total_cost)) {
        //             request_array.push(
        //                 axios({
        //                     method: "PATCH",
        //                     url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/`,
        //                     headers: headers,
        //                     data: {
        //                         is_paid: false
        //                     }
        //                 })
        //             );
        //         }
        //         if (parseFloat(tmp_paid) < parseFloat(advance_payment)) {
        //             request_array.push(
        //                 axios({
        //                     method: "PATCH",
        //                     url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip.id}/`,
        //                     headers: headers,
        //                     data: {
        //                         is_paid: false
        //                     }
        //                 })
        //             );
        //         }
        //         axios.all([...request_array]).then(axios.spread((...responses) => {
        //             dispatch({
        //                 type: "MENU_SET_TRIP_INFO",
        //                 payload: {
        //                     trip_info: responses[0].data
        //                 }
        //             });
        //             dispatch({
        //                 type: "TRIP_SET_ALL_DATA",
        //                 payload: {
        //                     data: responses[0].data
        //                 }
        //             });
        //             if (parseFloat(tmp_paid) < parseFloat(advance_payment)) {
        //                 dispatch({
        //                     type: "TRIP_SET_DATA_TRIP",
        //                     payload: {
        //                         data_trip: responses[1].data
        //                     }
        //                 });
        //                 dispatch({
        //                     type: "MENU_SET_TRIP_INFO_VERSION",
        //                     payload: {
        //                         version: responses[1].data
        //                     }
        //                 });
        //             }
        //         })).catch(function (error) {
        //             console.log(error.responseJSON);
        //         });
        //     }
        // }
    }, [paymentDone]);
    //useEffect(() => {
    //let tmp_amount = 0;
    //payment_remarks.map((remark) => {
    //tmp_amount += remark.amount;
    //});
    //setPaidAmount(tmp_amount);
    //}, [payment_remarks]);
    useEffect(() => {
        const { headers } = CheckBeforeRequest();
        if (totalCost.length !== 0) {
            let total_fees = 0;
            totalCost.map((currency_cost) => {
                // let tmp = currency_cost.cost.replace(",", ".");
                let tmp = currency_cost.cost;
                // tmp = tmp.replace(/\s/g, '');
                total_fees += parseFloat(tmp);
            });
            if (dataTrip !== null && dataTrip.expected_deposit !== null) {
                if (dataTrip.has_manual_expected_deposit) {
                    dispatch(setAdvancePayment(parseFloat(dataTrip.expected_deposit)));
                } else {
                    let deposit_percent = 100;
                    let diff = moment.utc(start_date).diff(moment(), "day");
                    if (['cercledesvoyages', 'continentsinsolites', 'terreslointaines'].includes(quotation_code)) {
                        if (diff <= 35) {
                            deposit_percent = 100;
                        } else {
                            deposit_percent = 30;
                        }
                    }
                    if (quotation_code === 'americavoyages') {
                        if (diff <= 45) {
                            deposit_percent = 100;
                        } else {
                            deposit_percent = 50;
                        }
                    }
                    let advance_payment = ((total_fees * deposit_percent) / 100).toFixed(2);
                    if (parseFloat(dataTrip.expected_deposit) !== parseFloat(advance_payment)) {
                        if (source !== null) {
                            source.cancel('Operation canceled by the user.');
                        }
                        source = axios.CancelToken.source();
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
                            data: {
                                expected_deposit: parseFloat(advance_payment)
                            },
                            cancelToken: source.token
                        }).then(function (response) {
                            dispatch({
                                type: "TRIP_SET_DATA_TRIP",
                                payload: {
                                    data_trip: response.data
                                }
                            });
                            dispatch(setAdvancePayment(parseFloat(advance_payment)));
                        }).catch(function (error) {
                            if (!axios.isCancel(error)) {
                                console.log('Request canceled', error.message);
                            } else {
                                console.log("data trip refresh error", error);
                            }
                        })
                    } else {
                        dispatch(setAdvancePayment(parseFloat(dataTrip.expected_deposit)));
                    }
                }
            }
            setTotalFees(total_fees);
        }
    }, [totalCost]);
    useEffect(() => {
        let total_fees = parseFloat(tmpFeesRemark);
        totalCost.map((currency_cost) => {
            let tmp = currency_cost.cost;
            // let tmp = currency_cost.cost.replace(",", ".");
            // tmp = tmp.replace(/\s/g, '');
            total_fees += parseFloat(tmp);
        });
        setTotalFees(total_fees);
    }, [tmpFeesRemark]);
    useEffect(() => {
        if (dataTrip !== null) {
            let total_cost = prices.totalCost;
            // total_cost.map(currency_cost => currency_cost.cost = currency_cost.cost.toLocaleString(language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
            setTotalCost(total_cost);
        }
    }, [flights, cars, accommodations, pois, transfers, manual_products, assistance_cart, dataTrip]);
    useEffect(() => {
        //if (payment_remarks.length !== 0) {
        // let total_remark = 0;
        // paymentDone.map((remark) => {
        //     total_remark += parseFloat(remark.amount);
        // });
        let rest_to_pay = (parseFloat(totalFees) * 100) - (parseFloat(totalPaid) * 100);
        // let rest_to_pay = (parseFloat(totalFees) * 100) - (parseFloat(totalPaid) * 100) - (parseFloat(total_remark) * 100);
        // setTotalRemark(!isNaN(total_remark) ? total_remark : totalRemark);
        setRestToPay(!isNaN(rest_to_pay) ? rest_to_pay / 100 : restToPay);
        //}
    }, [totalFees, totalPaid]);
    useEffect(() => {
        // sort payments by created_date
        if (paymentDone.length !== 0) {
            sortData(paymentDone);
        }
    }, [order]);
    const getPayments = () => {
        if (trip !== undefined && trip !== null) {
            let payments = trip.payments_done.filter(payment => parseFloat(payment.amount) !== 0);
            let sort_payments = [...payments].sort((a, b) => {
                const dateA = new Date(a.created_date);
                const dateB = new Date(b.created_date);
                return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
            });
            //payments = payments.sort((a, b) => {
            //return new Date(a.done_date) - new Date(b.done_date)
            //});
            if (dataTrip.installment_fees !== null) {
                dispatch(setFeesRemark(parseFloat(dataTrip.installment_fees)));
                setTmpFeesRemark(parseFloat(dataTrip.installment_fees));
            }
            rowRef.current = rowRef.current.slice(0, sort_payments.length);
            dispatch(setPaymentDone(sort_payments));
        }
    };
    const changeFeesValue = (new_value) => {
        if (new_value.match(/^[0-9.,]+$/) !== null) {
            let final_value = new_value;
            if (final_value.startsWith("0") && final_value !== "0") {
                final_value = final_value.substring(1);
            }

            if (final_value.match(/^\d*(\.\d{0,2})?$/) !== null) {
                setTmpFeesRemark(parseFloat(final_value));
            } else {
                enqueueSnackbar(t("menu.trip_list.payment_decimals"), { variant: "warning" });
            }
        } else {
            setTmpFeesRemark(0);
        }
    };
    const addRemark = () => {
        //if (parseFloat((parseFloat(totalPaid) + parseFloat(paidAmount)).toFixed(2)) === parseFloat(totalFees)){
        //enqueueSnackbar(t("menu.trip_list.payment_excess"), { variant: "warning" });
        //}else{
        let new_date = new Date();
        let default_remark = {
            payment_type: "DEFAULT",
            amount: 0,
            currency_id: currency.id,
            description: "",
            done_date: moment(new_date),
            identifier: ""
            //is_manual: true
        };
        let tmp_open_picker = openPicker.slice();
        tmp_open_picker.push(false);
        setOpenPicker(tmp_open_picker);
        bottomTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(addPaymentRemark(default_remark));
        //}
    };
    
    const savePayment = () => {
        //if (restToPay < 0) {
        //enqueueSnackbar(t("menu.trip_list.payment_excess"), { variant: "warning" });
        //} else {
        let { pass_check, headers } = CheckBeforeRequest();
        let error_identifier = errorIdentifier.slice();
        if (pass_check) {
            setLoading(true);
            let request = [];
            let payment_done = paymentDone.slice();
            payment_remarks.map((payment, payment_index) => {
                let tmp_payment = {...payment};
                if (tmp_payment.identifier !== '') {
                    if (error_identifier.includes(payment_index)) {
                        error_identifier = error_identifier.filter((index) => index !== payment_index);
                    }
                    // tmp_payment.done_date = format(tmp_payment.done_date, 'yyyy-MM-dd') + 'T00:00:00Z';
                    tmp_payment.done_date = moment.utc(tmp_payment.done_date).format('YYYY-MM-DD' ) + 'T00:00:00Z';
                    request.push(
                        axios({
                            method: 'POST',
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip.id}/add_payment/`,
                            headers: headers,
                            data: JSON.stringify(tmp_payment)
                        })
                    );
                } else {
                    if (!error_identifier.includes(payment_index)) {
                        error_identifier.push(payment_index);
                    }
                }
            });
            if (request.length === 0) {
                setLoading(false);
            }
            axios.all([...request]).then(axios.spread((...responses) => {
                if (error_identifier.length !== 0) {
                    setErrorIdentifier(error_identifier);
                }
                if (responses.length > 0) {
                    let payments = responses[responses.length - 1].data.payments_done.filter(payment => parseFloat(payment.amount) !== 0);
                    sortData(payments);
                    if (dataTrip !== null && (dataTrip.status_contract === "QUOTATION" || dataTrip.status_modification !== 'FIXED_PV') && quotation_code === "cercledesvoyages") {
                        axios({
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip?.id}/update_agreed_prices/`,
                            params: {
                                token: dataTrip.trip.token
                            },
                        }).then((response) => {
                            dispatch(SetDataTrip(response.data));
                            dispatch({
                                type: "TRIP_SET_DATA_TRIP",
                                payload: {
                                    data_trip: response.data
                                }
                            });
                            dispatch({
                                type: "MENU_SET_TRIP_INFO_VERSION",
                                payload: {
                                    version: response.data
                                }
                            });
                            axios({
                                method: 'GET',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/`,
                            }).then((response) => {
                                dispatch({
                                    type: "TRIP_SET_ALL_DATA",
                                    payload: {
                                        data: response.data
                                    }
                                });
                                setLoading(false);
                            }).catch((error) => {
                                setLoading(false);
                            });
                        }).catch((error) => {
                            setLoading(false);
                            console.log(error);
                        });
                    } else {
                        axios({
                            method: 'GET',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/`,
                        }).then((response) => {
                            dispatch({
                                type: "TRIP_SET_ALL_DATA",
                                payload: {
                                    data: response.data
                                }
                            });
                            setLoading(false);
                        }).catch((error) => {
                            setLoading(false);
                        });
                    }
                    dispatch(emptyPaymentRemarks());
                }
            })).catch(function (error) {
                setLoading(false);
                console.log('error save payment:', error);
                //TODO: show snackbar error
            });
            //}
        }
    };
    const deletePayment = (id) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let payment_done = paymentDone.slice();
            let request = {
                id: id
            };
            axios({
                method: 'DELETE',
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip.id}/delete_payment/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then((response) => {
                let payments = response.data.payments_done.slice();
                // payments = payments.sort((a, b) => {
                //     const dateA = new Date(a.created_date);
                //     const dateB = new Date(b.created_date);
                //     return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
                // });
                // dispatch(setPaymentDone(payments));
                sortData([...payments]);

                // setEditPayment(null);
            }).catch(function (error) {
                console.log('error delete payment:', error);
                //TODO: show snackbar error
            });
        }
    };

    const onToggleEditFees = () => {
        setEditFees(!editFees);
    };
    const onToggleCancelEditFees = () => {
        setTmpFeesRemark(fees_remark);
        setEditFees(false);
    };
    const onToggleValidateEditFees = () => {
        const { headers } = CheckBeforeRequest();
        let request = {
            installment_fees: parseFloat(tmpFeesRemark)
        };
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${dataTrip.id}/`,
            data: request
        }).then(function (response) {
            dispatch(SetDataTrip(response.data));
            setEditFees(false);
        }).catch(function (error) {
            console.log("data trip refresh error", error);
        });
    };
    const onClickDoughnut = (event) => {
        if (getElementAtEvent(doughnutRef.current, event).length !== 0) {
            doughnutRef.current.setActiveElements([{
                datasetIndex: 0,
                index: getElementAtEvent(doughnutRef.current, event)[0].index
            }]);
            doughnutRef.current.update();
            rowRef.current[getElementAtEvent(doughnutRef.current, event)[0].index].scrollIntoView({ behavior: 'smooth', block: 'start' });
            setClickDoughnut(getElementAtEvent(doughnutRef.current, event)[0].index);
        }
    };
    const onClickRow = (row) => (event) => {
        if (clickDoughnut !== row) {
            doughnutRef.current.setActiveElements([{
                datasetIndex: 0,
                index: row
            }]);
            doughnutRef.current.update();
            setClickDoughnut(row);
        } else {
            doughnutRef.current.setActiveElements([]);
            doughnutRef.current.update();
            setClickDoughnut(null);
        }
    };
    const onMouseEnterRow = (event, row) => {
        if (clickDoughnut === null) {
            doughnutRef.current.setActiveElements([{
                datasetIndex: 0,
                index: row
            }]);
            doughnutRef.current.update();
        }
    };
    const onMouseLeaveRow = (row) => {
        if (clickDoughnut === null) {
            doughnutRef.current.setActiveElements([]);
            doughnutRef.current.update();
        }
    };
    const handleClickAway = () => {
        if (doughnutRef.current !== null) {
            doughnutRef.current.setActiveElements([]);
            doughnutRef.current.update();
            setClickDoughnut(null);
        }
    };
    const handleRequestSort = (column) => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
    };
    const onCloseDeleteConfirmation = (event, reason) => {
        if (reason !== 'backdropClick') {        
            setDeleteConfirmation(null);
        }
    }
    const sortData = (payment_data) => {
        console.log('payment_data:', payment_data);
        let payments = [...payment_data].sort((a, b) => {
            if (orderBy === t('shared.amount')) {
                return (parseFloat(a.amount) - parseFloat(b.amount)) * (order === 'asc' ? 1 : -1);
            } else if (orderBy === t('summary-payment.payment-created')) {
                const dateA = new Date(a.created_date);
                const dateB = new Date(b.created_date);
                return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
            } else if (orderBy === t('menu.invoice.receipt_date')) {
                const dateA = new Date(a.done_date);
                const dateB = new Date(b.done_date);
                return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
            } else if (orderBy === t('menu.invoice.accounting_date_sent')) {
                const dateA = new Date(a.sent_date);
                const dateB = new Date(b.sent_date);
                return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
            } else {
                return 0;
            }
        });
        dispatch(setPaymentDone(payments));
    }
    //let percentage = Math.round((100*666.99)/totalFees);
    const percentage = (totalPaid !== 0 && totalFees !== 0 ? Math.round((100 * totalPaid) / totalFees) : 0);
    if (dataTrip !== null && loading === false) {
        return (
            <Fragment>

                <ClickAwayListener onClickAway={handleClickAway}>
                    <Grid container direction={ 'column' } style={{ marginTop: 35 }} spacing={ 5 }>
                        <Grid item>
                            <Grid container direction={ 'row' } spacing={ 2 }>
                                <Grid item md={ 8 }>
                                    <Grid container direction={ 'column' } justify={ 'center' } alignItems={ 'center' } spacing={ 8 }>
                                        <Grid item>
                                            <div className={classes.progress}>
                                                <div className={classes.barOverflow}>
                                                    <div className={classes.bar} style={{ transform: "rotate(" + (45 + (percentage * 1.8)) + "deg)" }} />
                                                </div>
                                                <div style={{ position: 'absolute', top: '33%' }}>
                                                    <div style={{ color: '#E6592F', fontWeight: 'bold', fontSize: 20 }}>{`${t('menu.trip_list.paid_at')} ${percentage}%`}</div>
                                                    <div style={{ color: '#E6592F', fontWeight: 'bold', fontSize: 20 }}>{`${t('menu.trip_list.total_paid')} : ${new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalPaid)}`}</div>
                                                    <div style={{ fontSize: 25 }}>{`${t('menu.trip_list.left_to_pay')} ${new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(restToPay)}`}</div>
                                                    {
                                                        ((totalPaid === 0 || advance_payment > totalPaid) && totalFees !== 0) &&
                                                        <div style={{ fontSize: 25 }}>{`${t('menu.trip_list.deposit')} : ${new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(advance_payment)}`}</div>
                                                    }
                                                    {
                                                        totalPaid !== 0 && totalPaid >= advance_payment && 
                                                        <div style={{ fontSize: 19 }}>{t("menu.trip_list.advance_payment_paid")}</div>
                                                    }
                                                    <div style={{ color: '#E6592F', fontWeight: 'bold', fontSize: 20, marginTop: 13 }}>{`${t('menu.trip_list.balance_to_be_paid')} ${moment(dataTrip.due_date).utc().format("L")}`}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item style={{ marginTop: 90 }}>
                                            {
                                                totalFees !== 0 && (
                                                    <Button
                                                        variant={"contained"}
                                                        className={classes.orangeButton}
                                                        startIcon={<AddCircle className={classes.orangeIcon} />}
                                                        onClick={addRemark}
                                                    >
                                                        {t("menu.trip_list.add")}
                                                    </Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={ 4 }>
                                    <Card className={ classes.stickyPaper } raised>
                                        <Grid container direction={ 'column' } justify={ 'center' } alignItems={ 'center' }>
                                            <Grid item style={{ color: '#E6592F', textTransform: 'uppercase', fontWeight: 'bold' }}>{t('menu.trip_list.distribution')}</Grid>
                                            {
                                                trip !== null && trip.payments_done.length !== 0 && (
                                                    <Grid item style={{ width: '100%' }}>
                                                        <div className={classes.doughnutContainer}>
                                                            <Doughnut data={doughnut_data} onClick={(event) => onClickDoughnut(event)} ref={doughnutRef} options={doughnut_options} plugins={doughnut_plugin} id={'doughnut-payments'}/>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                                {
                                    parseFloat(totalPaid) !== 0 && parseFloat(totalPaid) < parseFloat(advance_payment) &&
                                    <Grid item md={8}>
                                        <Alert variant={ 'filled' } severity={ 'warning' }>{ t('summary-payment.partial-deposit') }</Alert>
                                    </Grid>
                                }
                            </Grid>                    
                        </Grid>
                        <Grid item xs={ 12 }>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {tableColumn.map(column => {
                                                if ([t('summary-payment.payment-created'), t('menu.invoice.receipt_date'), t('shared.amount'), t('menu.invoice.accounting_date_sent')].includes(column)) {
                                                    return (
                                                        <TableCell
                                                            key={ column }
                                                            sortDirection={orderBy === column ? order : false}
                                                        >
                                                            <TableSortLabel
                                                                style={{color: '#E6592F'}}
                                                                active={orderBy === column}
                                                                direction={orderBy === orderBy ? order : 'asc'}
                                                                orderBy={orderBy}
                                                                onClick={() => handleRequestSort(column)}
                                                            >
                                                                {column}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    )
                                                } else {
                                                    return <TableCell key={ column } style={{color: '#E6592F'}}>{column}</TableCell>
                                                }
                                            }
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            paymentDone.map((payment, payment_index) => {
                                                if (editPayment !== payment_index) {
                                                    return (
                                                        <TableRow key={payment.id} ref={el => rowRef.current[payment_index] = el} className={clsx({ [classes.active]: payment_index === clickDoughnut })} hover onClick={onClickRow(payment_index)} onMouseEnter={(event) => onMouseEnterRow(event, payment_index)} onMouseLeave={() => onMouseLeaveRow(payment_index)} style={{ cursor: 'pointer' }}>
                                                            <TableCell>{moment(payment.created_date).format('L LT')}</TableCell>
                                                            <TableCell>{paymentChoices[payment.payment_type] !== undefined && payment.payment_type !== 'DEFAULT' ? paymentChoices[payment.payment_type] : ''}</TableCell>
                                                            {
                                                                // ['cercledesvoyages'].includes(quotation_code) &&
                                                                <TableCell>{payment.identifier !== null && payment.identifier !== '' ? payment.identifier : ''}</TableCell>
                                                            }
                                                            <TableCell>{moment(payment.done_date).format('L')}</TableCell>
                                                            <TableCell>
                                                                <TooltipMui title={payment.description}>
                                                                    <div style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 200 }}>{payment.description}</div>
                                                                </TooltipMui>
                                                            </TableCell>
                                                            <TableCell>{`${new Intl.NumberFormat(language, { style: 'currency', currency: payment.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(payment.amount))}`}</TableCell>
                                                            <TableCell>{payment.creator !== null ? t('menu.trip_list.seller') : t('menu.trip_list.traveler_website')}</TableCell>
                                                            <TableCell>{payment.sent_date !== null ? moment(payment.sent_date).format('L') : ''}</TableCell>
                                                            {
                                                                user.groups.some(group => group.is_admin === true) && moment.utc(payment.created_date).isSame(moment.utc(), 'day') && (
                                                                    <TableCell padding={"none"} align={"right"} className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16}`}>
                                                                        <TooltipMui title={t("global.delete")}>
                                                                            <IconButton size="small" onClick={() => {
                                                                                setDeleteConfirmation(payment.id)
                                                                            }}>
                                                                                <Close/>
                                                                            </IconButton>
                                                                        </TooltipMui>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    );
                                                } 
                                                return (
                                                    <TripListPaymentEditRemarks key={payment_index} remark={payment} remark_index={payment_index} paymentChoices={paymentChoices} openPicker={openPicker} setOpenPicker={setOpenPicker} totalPaid={totalPaid} totalFees={totalFees} paidAmount={paidAmount} restToPay={restToPay} setEditPayment={setEditPayment}/>
                                                );
                                            })
                                        }
                                        {
                                            payment_remarks.map((remark, remark_index) => {
                                                return (
                                                    <TripListPaymentRemarks key={remark_index} remark={remark} remark_index={remark_index} paymentChoices={paymentChoices} openPicker={openPicker} setOpenPicker={setOpenPicker} totalPaid={totalPaid} totalFees={totalFees} paidAmount={paidAmount} restToPay={restToPay} errorIdentifier={errorIdentifier} setErrorIdentifier={setErrorIdentifier}/>
                                                );
                                            })
                                        }
                                        <TableRow ref={bottomTableRef}/>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {
                            payment_remarks.length !== 0 && (
                                <Grid item xs={12} className={classes.textCenter} style={{ padding: isMobile ? "0px 16px 12px" : "12px 24px" }}>
                                    <Button fullWidth={isMobile ? true : false} variant="contained" className={classes.validateButton} onClick={savePayment}>
                                        { t("global.validate") }
                                    </Button>
                                </Grid>
                            )
                        }
                    </Grid>
                </ClickAwayListener>
                <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ deleteConfirmation !== null } fullWidth maxWidth={ "md" } onClose={ onCloseDeleteConfirmation } disableEscapeKeyDown>
                    <DialogTitle onClose={ onCloseDeleteConfirmation }>
                        { t("summary-payment.delete-confirmation-title") }
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            { t("summary-payment.delete-confirmation-text") }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ onCloseDeleteConfirmation } color="primary">
                            { t("global.cancel") }
                        </Button>
                        <Button onClick={ () => {deletePayment(deleteConfirmation)} } color="primary" autoFocus>
                            { t("global.delete") }
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
    return (
        <CircularProgress/>
    );
};
export default React.memo(TripListPayment);
