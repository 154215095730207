import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close, StoreOutlined } from "@mui/icons-material";
import PictureModalCreateForm from "../Menu/MaterialTripList/picture/pictureModalCreateForm";
import CartPictureEdit from "./CartPictureEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderContext } from "./utils/providerContext";
import { getPoiPicture } from "./utils/getPoiPicture";
import { getCarPicture } from "./utils/getCarPicture";
import { getAccommodationPictures } from "./utils/getAccommodationPictures";
import { getTransferPicture } from "./utils/getTransferPicture";
import { getAssistancePicture } from "./utils/getAssistancePicture";
import { getManualProductPicture } from "./utils/getManualProductPicture";
import { findPictureUrl } from "./utils/findPictureUrl";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";
import { CarCart } from "../Itinerary/objects/carCart";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { Flight } from "../Itinerary/network/flight";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    onClose: () => void
} & ({
    type: 'car',
    item: CarCart,
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart,
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual',
    item: ManualProduct
})

export function CartProductPicturesModal(props: Props): JSX.Element {
    const providerContext = useContext(ProviderContext);
    const versionString = GetCookie("trip_id_version");
    const version = providerContext.module ?
        providerContext.version :
        (
            versionString ? parseInt(versionString) : -1
        );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedPicture = useSelector((state: AppState) => state.pictures.picture);
    const [inputs, setInputs] = useState<Picture[]>([]);
    const [loading, setLoading] = useState(false);
    const [openPictureEditModal, setOpenPictureEditModal] = useState(false);
    const [openPicturesModal, setOpenPicturesModal] = useState(false);
    const providerPictures = useMemo(() => {
        switch (props.type) {
            case 'poi': return getPoiPicture(props.item, 'provider');
            case 'car': return getCarPicture(props.item, 'provider');
            case 'accommodation': return getAccommodationPictures(props.item, 'provider');
            case 'transfer': return getTransferPicture(props.item, 'provider');
            case 'assistance': return getAssistancePicture(props.item, 'provider');
            case 'manual':
            case 'manual-accommodation': return getManualProductPicture(props.item, 'provider');
            default: return [];
        }
    }, [props.type, props.item]);
    const providerPictureIds = useMemo(() => {
        return providerPictures.map((picture) => {
            return picture.id;
        });
    }, [providerPictures]);
    const inputIds = useMemo(() => {
        return inputs.map((picture) => {
            return picture.id;
        });
    }, [inputs]);
    const onSwap = useCallback(
        (
            draggedItem: {index: number, pictureId: number},
            swappedItem: {index: number, pictureId: number}
        ) => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    setInputs((state) => {
                        if (
                            draggedItem.index !== swappedItem.index &&
                            draggedItem.pictureId !== swappedItem.pictureId
                        ) {
                            const pictures = [...state];
                            const item = state[draggedItem.index];
                            pictures.splice(draggedItem.index, 1);
                            pictures.splice(swappedItem.index, 0, item!);
                            resolve();
                            return pictures;
                        }
                        resolve();
                        return state;
                    });
                }, 0);
            });
        },
        [setInputs]
    );
    const showError = useShowError();
    const update = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const isProviderPicture = (picture: Picture) => {
        return providerPictureIds.includes(picture.id);
    };

    const onDelete = (picture: Picture, index: number) => {
        setInputs((state) => {
            return state.filter((item, itemIndex) => {
                return item.id !== picture.id ||
                       itemIndex !== index;
            });
        });
    };

    const onOpenAddPictureModal = () => {
        setOpenPicturesModal(true);
    };

    const onAddPicture = (pictures: Picture[]) => {
        setInputs((state) => {
            return state.concat(pictures);
        });
    };

    const onSave = () => {
        update(
            version,
            {
                id: props.item.id,
                type: props.type,
                roomIds: props.type === 'accommodation' ?
                    props.item.rooms.map((room) => {
                        return room.id;
                    }) :
                    [],
                isCustom: props.item.is_custom
            },
            { overload_pictures: inputs.map((picture) => picture.id) }
        );
    };

    const onClose = () => {
        dispatch({
            type: 'PICTURE_SET_PICTURE',
            payload: false
        });
        setOpenPictureEditModal(false);
    };

    useEffect(() => {
        switch (props.type) {
            case 'poi': {
                setInputs(getPoiPicture(props.item));
                break;
            }
            case 'car': {
                setInputs(getCarPicture(props.item));
                break;
            }
            case 'accommodation': {
                setInputs(getAccommodationPictures(props.item));
                break;
            }
            case 'transfer': {
                setInputs(getTransferPicture(props.item));
                break;
            }
            case 'assistance': {
                setInputs(getAssistancePicture(props.item));
                break;
            }
            case 'manual':
            case 'manual-accommodation': {
                setInputs(getManualProductPicture(props.item));
                break;
            }
        }
    }, [props.item]);

    useEffect(() => {
        setInputs((pictures) => {
            return pictures.map((picture) => {
                if (picture.id === selectedPicture?.id) {
                    return selectedPicture;
                }
                return picture;
            });
        });
    }, [selectedPicture]);

    return (
        <>
            <Dialog open onClose={props.onClose} fullScreen>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontWeight="bold">
                            {t('cart-material.cart-construction-product-pictures-title')}
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Alert severity="info" sx={{ marginBottom: 4 }}>
                        { t('cart-material.provider-picture-helper') }
                    </Alert>
                    <Button sx={{ marginBottom: 1 }} onClick={onOpenAddPictureModal}>
                        {t('shared.add')}
                    </Button>
                    {
                        inputs.length > 0 &&
                        <Grid
                            spacing={2}
                            sx={{}}
                            container
                        >
                            {
                                inputs.map((picture, index) => {
                                    return (
                                        <Grid
                                            key={`${index}-${picture.id}`}
                                            item
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            xl={3}
                                        >
                                            <DraggableItem
                                                key={`${index}-${picture.id}`}
                                                index={index}
                                                picture={picture}
                                                isProviderPicture={isProviderPicture(picture)}
                                                onOpenEditModal={() => setOpenPictureEditModal(true)}
                                                onDelete={() => onDelete(picture, index)}
                                                onSwap={onSwap}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    }
                    {
                        providerPictures.length > 0 &&
                        <>
                            <Divider sx={{ marginTop: 3, marginBottom: 2 }} />
                            <Typography fontWeight="bold" sx={{ marginBottom: 2 }}>
                                {t('cart-material.cart-construction-product-pictures-provider-pictures-list')}
                            </Typography>
                            <Grid spacing={2} container >
                                {
                                    providerPictures.filter((picture) => {
                                        return !inputIds.includes(picture.id);
                                    }).map((picture) => {
                                        return (
                                            <Grid
                                                key={picture.id}
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}
                                            >
                                                <Card>
                                                    <CardMedia
                                                        component={ 'img' }
                                                        alt={picture.alt}
                                                        height={200}
                                                        image={findPictureUrl(picture)}
                                                    />
                                                    <CardContent>
                                                        <Typography>{ picture.name }</Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <StoreOutlined />
                                                            <Typography sx={{ lineHeight: 1 }} fontWeight="bold">
                                                                {t('cart-material.cart-construction-provider-picture')}
                                                            </Typography>
                                                        </Stack>
                                                        <Button onClick={() => onAddPicture([picture])}>
                                                            {t('cart-material.cart-construction-activate')}
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t('shared.cancel')}
                    </Button>
                    <Button onClick={onSave}>
                        {t('shared.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                openPicturesModal &&
                <PictureModalCreateForm
                    pictureTypeOnUpload={
                        (() => {
                            switch (props.type) {
                                case 'manual':
                                case 'manual-accommodation': return 'MANUAL_PRODUCT';
                                case 'accommodation': return 'HOTEL';
                                default: return 'PICTURE';
                            }
                        })()
                    }
                    onChangePictures={onAddPicture}
                    onClose={() => setOpenPicturesModal(false)}
                />
            }
            {
                openPictureEditModal &&
                <PictureEditModal onClose={onClose} />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}


type DraggableItemProps = {
    index: number,
    picture: Picture,
    isProviderPicture: boolean,
    onOpenEditModal: () => void,
    onDelete: (picture: Picture, index: number) => void,
    onSwap: (
        draggedItem: {
            index: number,
            pictureId: number
        },
        swappedIndex: {
            index: number,
            pictureId: number
        },
    ) => Promise<void>
}

function DraggableItem(props: DraggableItemProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const elementRef = useRef<HTMLImageElement | null>(null);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dragCollected, drag] = useDrag(() => ({
        type: 'picture-reorder',
        item: { pictureId: props.picture.id, index: props.index }
    }), [props.index, props.picture.id]);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dropCollected, drop] = useDrop<{pictureId: number, index: number}>(() => ({
        accept: 'picture-reorder',
        async hover(item) {
            await props.onSwap(
                item,
                { index: props.index, pictureId: props.picture.id }
            );
            item.index = props.index;
        }
    }), [props.index, props.picture.id, props.onSwap]);

    const onEdit = () => {
        dispatch({
            type: 'PICTURE_SET_PICTURE',
            payload: props.picture
        });
        props.onOpenEditModal();
    };

    drag(drop(elementRef));

    return (
        <Card>
            <CardMedia
                ref={elementRef}
                component={ 'img' }
                height={200}
                image={findPictureUrl(props.picture)}
                sx={{ cursor: 'move' }}
            />
            <CardContent>
                <Typography sx={{ minHeight: 24 }}>
                    { props.picture.name}
                </Typography>
            </CardContent>
            <CardActions>
                {
                    props.isProviderPicture &&
                    <>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <StoreOutlined />
                            <Typography sx={{ lineHeight: 1 }} fontWeight="bold">
                                {t('cart-material.cart-construction-provider-picture')}
                            </Typography>
                        </Stack>
                        <Button sx={{ marginLeft: 2 }} onClick={() => props.onDelete(props.picture, props.index)}>
                            {t('cart-material.cart-construction-deactivate')}
                        </Button>
                    </>
                }
                {
                    !props.isProviderPicture &&
                    <>
                        <Button onClick={onEdit}>
                            {t('shared.edit')}
                        </Button>
                        <Button onClick={() => props.onDelete(props.picture, props.index)}>
                            {t('shared.delete-picture')}
                        </Button>
                    </>
                }
            </CardActions>
        </Card>
    );
}

type PictureEditModalProps = {
    onClose: () => void
}

function PictureEditModal(props: PictureEditModalProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Dialog open onClose={props.onClose}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>
                        {t('cart-material.cart-construction-product-edit-title')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <CartPictureEdit onClose={props.onClose} />
            </DialogContent>
        </Dialog>
    );
}
