//---- Dependencies ----//
import React, {Fragment} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import { RemoveXHistorydest, SetItineraryTypeData } from "../../Actions/ItineraryType";
import { DestinationChange } from "../../Actions/Header";

import GetNewChildren from "./Functions/GetNewChildren";
import HomeIcon from '@material-ui/icons/Home';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    basicLink: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: `${main_color} !important`,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    basicLinkV: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#BF202D !important",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    orange: {
        fontFamily: "Roboto",
        color: `${main_color} !important`
    },
    red: {
        fontFamily: 'Roboto',
        color: '#BF202D !important'
    },
    gray: {
        fontFamily: "Roboto",
        color: "#0000008A"
    },
    wrapIcon: {
        verticalAlign: "sub",
        display: "inline-flex"
    }
}));

const MaterialBreadcrumb = ({router}) => {
    const dest_history = useSelector(store => store.itinerary_type.dest_history);
    const dispatch = useDispatch();
    const classes = useStyles();
    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    return (
        <Fragment>
        {
            dest_history.map((dest, index_dest) => {
                return (
                    <Fragment key={index_dest} >
                        {
                            index_dest !== (dest_history.length - 1) && (
                                <Fragment>
                                    <span onClick={() => {
                                        if (index_dest === 0) {
                                            router.push(`/home/${window.url_name}`);
                                            dispatch(RemoveXHistorydest(dest_history.length - (index_dest + 1), null));
                                        } else {
                                            // GetNewChildren(dest.data, dispatch);
                                            dispatch(RemoveXHistorydest(dest_history.length - (index_dest + 1), null));
                                            dispatch(SetItineraryTypeData(dest.data));
                                            if (dest.need_redirect === true) {
                                                router.push(`/${window.url_name}/destination-product/${dest.data.destination}`);
                                            }
                                        }
                                    }} className={clsx({[classes.basicLinkV]: ['visiteurs'].includes(quotation_code), [classes.basicLink]: !['visiteurs'].includes(quotation_code)})} >
                                    {
                                        index_dest === 0 && (
                                            <span className={classes.wrapIcon}>
                                                <HomeIcon className={clsx({[classes.red]: ['visiteurs'].includes(quotation_code), [classes.orange]: !['visiteurs'].includes(quotation_code)})} size={"small"} />
                                            </span>
                                        )
                                    }
                                    { dest.name }
                                    </span>
                                    <span className={clsx({[classes.red]: ['visiteurs'].includes(quotation_code), [classes.orange]: !['visiteurs'].includes(quotation_code)})} > {">"} </span>
                                </Fragment>
                            )
                        }
                        {
                            index_dest === (dest_history.length - 1) && index_dest !== 0 && (
                                <span className={classes.gray} > { dest.name } </span>
                            )
                        }
                        {
                            index_dest === (dest_history.length - 1) && index_dest === 0 && (
                                <Fragment>
                                    <span className={classes.wrapIcon}>
                                        <HomeIcon className={clsx({[classes.red]: ['visiteurs'].includes(quotation_code), [classes.orange]: !['visiteurs'].includes(quotation_code)})} size={"small"} />
                                    </span>
                                    <span className={clsx({[classes.basicLinkV]: ['visiteurs'].includes(quotation_code), [classes.basicLink]: !['visiteurs'].includes(quotation_code)})} onClick={() => {
                                        router.push(`/home/${window.url_name}`);
                                        dispatch(RemoveXHistorydest(dest_history.length - (index_dest + 1), null));
                                    }} > { dest.name } </span>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            })
        }
        </Fragment>
    )
}
export default MaterialBreadcrumb;