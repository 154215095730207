import axios from "axios";
import { Flush } from "../../../Actions/Flush";
import { SetTravelers } from "../../../Actions/Trip";
import { getQuotation } from "./getQuotation";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import SetCookie from "../../Common/Functions/SetCookie";
import PatchTravelers from "./PatchTravelers";

import moment from "moment";

const CreateNewTrip = (rangeDate, agencyFilter, pgiRef, tripName, groups, tempTravelers, tempContactLead, withApplicant, currency, budget, destination, user, trip_type, isHoneymoon, isWeddingAnniversary, dispatch, router, enqueueSnackbar, t) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let travelers = [];

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    PatchTravelers(tempTravelers, tempContactLead, withApplicant, headers, 'create', dispatch, enqueueSnackbar, t);
    tempTravelers.map((traveler) => {
        travelers.push(traveler.id);
    });
    let request = {
        currency: currency.id,
        accounting_reference: pgiRef !== '' ? pgiRef : null,
        name: tripName !== "" ? tripName : destination.name,
        data: {
            budget: (budget !== null && budget !== "" && budget !== " ") ? parseFloat(budget) : 0,
            start_date: rangeDate[0] === null ? null : moment.utc(rangeDate[0].format("YYYY-MM-DD")).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(),
            end_date: rangeDate[1] === null ? null : moment.utc(rangeDate[1].format("YYYY-MM-DD")).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).format(),
            travelers: travelers,
            destination: destination.destination
        },
        target_client: (agencyFilter !== null ? agencyFilter.id : user.client),
        trip_type: trip_type !== null ? trip_type.id : null
    };
    if (['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code)) {
        request.user_to = user.is_bo_user ? user.id : null;
        request.user_ta = user.is_seller_user ? user.id : null;
    }
    if (isHoneymoon) {
        request.is_honeymoon = isHoneymoon;
    }
    if (isWeddingAnniversary) {
        request.is_wedding_anniversary = isWeddingAnniversary;
    }
    if (tempContactLead !== null && tempContactLead.id !== 0) {
        request.data.contact_traveler = tempContactLead.id;
    }
    console.log('request:', request);
    getQuotation(destination.destination).then((quotation) => {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/`,
            data: JSON.stringify({
                ...request,
                is_by_day_view: !quotation?.step_by_step
            })
        }).then((response) => {
            let data = Object.assign({}, response.data);
            let today = new Date();
            let year = today.getFullYear();
            $.each(data.data.travelers, function (index_travelers, value_travelers) {
                let birth = value_travelers.birth_date;
                let birth_date = new Date(birth);
                let birth_year = birth_date.getFullYear();
                if ((year - birth_year) >= 12) {
                    value_travelers.flight_type = "ADT";
                } else if ((year - birth_year) >= 2) {
                    value_travelers.flight_type = "CNN";
                } else {
                    value_travelers.flight_type = "INF";
                }
                value_travelers.passenger_group = null;
            });
            let user_token = GetCookie("token");
            let id_user = GetCookie("id_user");
            let client_user = GetCookie("client_user");
            const crmCookies = {
                crm_token: GetCookie("crm_token"),
                crm_id_user: GetCookie("crm_id_user"),
                crm_client_user: GetCookie("crm_client_user"),
                isFromCrm: GetCookie("isFromCrm")
            };
            //Delete the cookie
            let cookies = document.cookie.split("; ");
            for (let c = 0; c < cookies.length; c++) {
                let d = window.location.hostname.split(".");
                while (d.length > 0) {
                    let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                    let p = location.pathname.split("/");
                    document.cookie = cookieBase + "/";
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join("/");
                        p.pop();
                    }
                    d.shift();
                }
            }

            SetCookie("trip_id", data.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("trip_id_version", data.current_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("trip_token", data.token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            if (crmCookies.crm_token) {
                for (const key in crmCookies) {
                    SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                }
            }
            dispatch(SetTravelers(data.data.travelers));
            let travelers_group = [];
            groups.map((group) => {
                travelers_group.push({
                    default: true,
                    travelers: group.travelers
                });
            });
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${data.id}/versions/${data.current_version}/travelers-group/array_create/`,
                data: JSON.stringify(travelers_group)
            }).then((response1) => {
                dispatch(Flush());
                router.push(`/${window.url_name}/apps/itinerary`);
            }).catch((traveler_group_error) => {
                console.log('travelers group error:', traveler_group_error);
            });
        }).catch((trip_error) => {
            console.log('creation trip error:', trip_error);
        });
    });
};
export default CreateNewTrip;
