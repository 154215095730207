import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, LinearProgress, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from "clsx";

import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import ConvertPrice from "../../Cart/Functions/Manual/ConvertPrice.js";
import CheckCustomCurrency from "./Functions/CheckCustomCurrency.js";
import GetMargin from "./Functions/GetMargin.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import HasPermission from "../../Common/Functions/HasPermission";
import { Price } from "../../FlightMaterial/objects/price";
import { Segment } from "../../FlightMaterial/objects/segment";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../Reducers/objects/currency";
import { AppState } from "../../../Reducers/Reducers";

const useStyles = makeStyles({
    padding60: {
        padding: 60
    },
    marginTop20: {
        marginTop: 20
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textAlightRight: {
        textAlign: "right"
    },
    bold: {
        fontWeight: "bold"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    },
    csvButton: {
        "color": "#3f51b5 !important",
        "border": "1px solid rgba(63, 81, 181, 0.5)",
        "padding": "8px 13px",
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "borderRadius": 5,
        "cursor": "pointer",
        "&:hover": {
            border: "1px solid #3f51b5",
            backgroundColor: "rgba(63, 81, 181, 0.04)",
            textDecoration: "none"
        }
    },
    fontSize11: {
        fontSize: 11
    }
});

type Props = {
    exportData: [],
    exportDataCSV: [],
    reel: boolean
}

const TripListFinanceSynthesis = (props: Props) => {
    const { exportData, exportDataCSV, reel } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((store: AppState) => store.user.user);
    const permissions = useSelector(store => store.user.permissions);
    const list_currency = useSelector((store: AppState) => store.base.currency_list);
    const currency = useSelector((store: AppState) => store.trip.currency);
    const language = useSelector((store: AppState) => store.header.tmp_language);
    const get_cart_done = useSelector((store: AppState) => store.menu.get_cart_done);
    const big_cart = useSelector((store: AppState) => store.menu.big_cart);
    const trip_start_date = useSelector((store: AppState) => store.trip.start_date);
    const trip_end_date = useSelector((store: AppState) => store.trip.end_date);
    const manual_cart = useSelector((store: AppState) => store.trip.manual_cart);
    const data_trip = useSelector((store: AppState) => store.trip.data_trip);
    const finance_charges = useSelector((store: AppState) => store.menu.finance_charges);
    const providers = useSelector((store: AppState) => store.trip.providers);
    const manual_providers = useSelector((store: AppState) => store.trip.manual_providers);

    const owner_id = useState(user ? user.client : null);
    const [get_total_price, setGetTotalPrice] = useState(false);
    const [total_price, setTotalPrice] = useState(0);
    const [full_buying_price, setFullBuyingPrice] = useState(0);
    const [only_main_currency_price, setOnlyMainCurrencyPrice] = useState(0);
    const [not_same_currency_cart, setNotSameCurrencyCart] = useState([]);
    const [to_margin, setToMargin] = useState(0);
    const [total_surcom, setTotalSurcom] = useState(0);
    const [total_network, setTotalNetwork] = useState(0);
    const [total_margin, setTotalMargin] = useState(0);
    const [total_margin_agency, setTotalMarginAgency] = useState(0);
    const [cashBack, setCashBack] = useState(0);
    const [big_total_margin_agency, setBigtotalMarginAgency] = useState(0);
    const [total_taxe, setTotalTaxe] = useState(0);
    const [custom_currencies, setCustomCurrency] = useState(null);
    const [has_custom_currency, setHasCustomCurrency] = useState(false);
    const [custom_factor, setCustomFactor] = useState(null);
    const [currency_base, setCurrencyBase] = useState(null);
    const [tvaValue, setTvaValue] = useState(0);
    
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    const getPrice = useGetPrice();
    const getTaxesPrice = useGetTaxesPrice();

    useEffect(() => {
        if (get_cart_done) {
            //if (user.client_full.type !== 2) {
            //setData();
            //}
            let tmp_only_main_currency_price = 0;
            let tmp_full_price = 0;
            let tmp_full_buying_price = 0;
            let total_surcom = 0;
            let to_margin = 0;
            let total_network = 0;
            let total_taxe = 0;
            let tmp_cash_back = 0;
            let tva = 0;
            let tmp_not_same_currency_cart = [];
            if (big_cart !== null) {
                for (let i = 0; i < big_cart.length; i++) {
                    if (!isProductPackaged({ product: big_cart[i], stackInfos: data_trip?.stack_info ?? null }) && !big_cart[i].is_optional && !big_cart[i].is_hidden_for_traveler && big_cart[i].is_available && (big_cart[i].booking_status === null || big_cart[i].booking_status.status_booking !== 'CANCELLED' || big_cart[i].booking_process_state === "CANCEL")) {
                        let price = big_cart[i].prices.find((price) => price.is_tva);
                        if (price === undefined) {
                            price = big_cart[i].prices.find((price) => price.master_price);
                        } else if (price.is_tva) {
                            tva += parseFloat(price.selling_price) - parseFloat(price.purchase_price);
                        }
                        if (price === undefined) {
                            price = big_cart[i].prices.find((price) => !price.master_price && !price.is_commissioned && !price.is_network && !price.is_surcom);
                        }
                        tmp_full_price += parseFloat(price.selling_price);

                        if (big_cart[i].product_type === 18) {
                            tmp_cash_back += parseFloat(price.selling_price);
                        }
                        if (big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_currency === currency.id) {
                            let currency_buy = big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_currency;
                            tmp_only_main_currency_price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            tmp_full_buying_price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            if (big_cart[i].product_type === 6) {
                                const master_price = big_cart[i].prices.find((price) => price.master_price);
                                const provider = providers.concat(manual_providers).find((item) => {
                                    return item.provider.id === big_cart[i].provider;
                                })?.provider;
                                let seatsCost = 0;
                                let option_cost = 0;
                                let otherOptionsCost = 0;
                                let option_on_flight = [];
                                if (provider?.code !== 'amadeus_rest') {
                                    let outbounds = big_cart[i].segments || big_cart[i].outbounds;
                                    outbounds.map(outbound => {
                                        let option_on_group = [];
                                        if (outbound.legs !== undefined) {
                                            outbound.legs.map(leg => {
                                                for (const seat of leg.seats ?? []) {
                                                    let seatPrice = 0;
                                                    if ((seat.prices?.length ?? 0) > 0) {
                                                        seatPrice = getPrice(seat.prices).cost;
                                                    } else {
                                                        seatPrice = parseFloat(seat.price ?? '0');
                                                    }
                                                    seatsCost += seatPrice;
                                                }
                            
                                                if (leg.paid_options && leg.paid_options.length > 0) {
                                                    leg.paid_options.map(option => {
                                                        let optionPrice = 0;
                                                        if ((option.prices?.length ?? 0) > 0) {
                                                            optionPrice = getPrice(option.prices, undefined, false).cost;
                                                        } else {
                                                            optionPrice = parseFloat(option.price);
                                                        }
                                                        if (option.option_on_flight) {
                                                            if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                                option_cost += optionPrice;
                                                                option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                            }
                                                        } else if (option.option_on_group) {
                                                            if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                                option_cost += optionPrice;
                                                                option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                            }
                                                        } else {
                                                            option_cost += optionPrice;
                                                        }
                                                    }); 
                                                }
                                            });
                                        } else {
                                            for (const seat of outbound.seats ?? []) {
                                                let seatPrice = 0;
                                                if ((seat.prices?.length ?? 0) > 0) {
                                                    seatPrice = getPrice(seat.prices).cost;
                                                } else {
                                                    seatPrice = parseFloat(seat.price ?? '0');
                                                }
                                                seatsCost += seatPrice;
                                            }
                        
                                            if (outbound.paid_options && outbound.paid_options.length > 0) {
                                                outbound.paid_options.map(option => {
                                                    let optionPrice = 0;
                                                    if ((option.prices?.length ?? 0) > 0) {
                                                        optionPrice = getPrice(option.prices, undefined).cost;
                                                    } else {
                                                        optionPrice = parseFloat(option.price);
                                                    }
                                                    if (option.option_on_flight) {
                                                        if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else if (option.option_on_group) {
                                                        if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else {
                                                        option_cost += optionPrice;
                                                    }
                                                }); 
                                            }
                                        }
                                    });
                                }
                                else {
                                    seatsCost = big_cart[i].paid_option_price.filter((item) => {
                                        return item.service_type === 'SEATS';
                                    }).reduce((prev, current) => {
                                        return prev + getPrice(current.prices).cost;
                                    }, 0);
                                    otherOptionsCost = big_cart[i].paid_option_price.filter((item) => {
                                        return item.service_type !== 'SEATS';
                                    }).reduce((prev, current) => {
                                        return prev + getPrice(current.prices, undefined).cost;
                                    }, 0);
                                }
                                tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
                                tmp_only_main_currency_price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
                                if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                    // price += getTaxesPrice(item.taxes);
                                    const master_price = big_cart[i].prices.find((price) => price.master_price);
                                    tmp_full_buying_price += parseFloat(ConvertPrice(getTaxesPrice(big_cart[i].taxes), currency, currency_buy, master_price.factor_used));
                                    tmp_only_main_currency_price += parseFloat(ConvertPrice(getTaxesPrice(big_cart[i].taxes), currency, currency_buy, master_price.factor_used));
                                }
                            }
                        } else {
                            let currency_buy = list_currency.find((currency) => {
                                return currency.id === big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_currency;
                            });
                            // if (tmp_not_same_currency_cart.length === 0) {
                                let tmp_price;
                                let tmp_obj = {};
                                tmp_obj.currency = currency_buy;
                                tmp_obj.factor_used = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used);
                                tmp_price = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                                tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                                if (big_cart[i].product_type === 6) {
                                    if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                        console.log('taxes 3 : ');
                                        const master_price = big_cart[i].prices.find((price) => price.master_price);
                                        tmp_price += getTaxesPrice(big_cart[i].taxes);
                                        tmp_obj.converted_price += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                                        total_taxe += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                                    }
                                }
                                tmp_obj.price = tmp_price;
                                tmp_not_same_currency_cart.push(tmp_obj);
                            // } else {
                            //     let found = false;
                            //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                            //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                            //             found = true;
                            //             tmp_not_same_currency_cart[j].price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            //         }
                            //         //if (j === tmp_not_same_currency_cart.length && found === false) {
                            //         if (found === false) {
                            //             let tmp_obj = {};
                            //             let tmp_price;
                            //             tmp_obj.currency = currency_buy;
                            //             tmp_obj.factor_used = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used);
                            //             tmp_price = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            //             if (big_cart[i].product_type === 6) {
                            //                 if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                            //                     console.log('taxes 4 : ');
                            //                     tmp_price += getTaxesPrice(big_cart[i].taxes);
                            //                     tmp_obj.converted_price += getTaxesPrice(big_cart[i].taxes);
                            //                     total_taxe += getTaxesPrice(big_cart[i].taxes);
                            //                 }
                            //             }
                            //             tmp_obj.price = tmp_price;
                            //             tmp_not_same_currency_cart.push(tmp_obj);
                            //             break;
                            //         }
                            //         found = false;
                            //     }
                            // }
                            tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            if (big_cart[i].product_type === 6) {
                                const master_price = big_cart[i].prices.find((price) => price.master_price);
                                const provider = providers.concat(manual_providers).find((item) => {
                                    return item.provider.id === big_cart[i].provider;
                                })?.provider;
                                let seatsCost = 0;
                                let option_cost = 0;
                                let otherOptionsCost = 0;
                                let option_on_flight = [];
                                if (provider?.code !== 'amadeus_rest') {
                                    let outbounds = big_cart[i].segments || big_cart[i].outbounds;
                                    outbounds.map(outbound => {
                                        let option_on_group = [];
                                        if (outbound.legs !== undefined) {
                                            outbound.legs.map(leg => {
                                                for (const seat of leg.seats ?? []) {
                                                    let seatPrice = 0;
                                                    if ((seat.prices?.length ?? 0) > 0) {
                                                        seatPrice = getPrice(seat.prices).cost;
                                                    } else {
                                                        seatPrice = parseFloat(seat.price ?? '0');
                                                    }
                                                    seatsCost += seatPrice;
                                                }
                            
                                                if (leg.paid_options && leg.paid_options.length > 0) {
                                                    leg.paid_options.map(option => {
                                                        let optionPrice = 0;
                                                        if ((option.prices?.length ?? 0) > 0) {
                                                            optionPrice = getPrice(option.prices, undefined, false).cost;
                                                        } else {
                                                            optionPrice = parseFloat(option.price);
                                                        }
                                                        if (option.option_on_flight) {
                                                            if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                                option_cost += optionPrice;
                                                                option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                            }
                                                        } else if (option.option_on_group) {
                                                            if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                                option_cost += optionPrice;
                                                                option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                            }
                                                        } else {
                                                            option_cost += optionPrice;
                                                        }
                                                    }); 
                                                }
                                            });
                                        } else {
                                            for (const seat of outbound.seats ?? []) {
                                                let seatPrice = 0;
                                                if ((seat.prices?.length ?? 0) > 0) {
                                                    seatPrice = getPrice(seat.prices).cost;
                                                } else {
                                                    seatPrice = parseFloat(seat.price ?? '0');
                                                }
                                                seatsCost += seatPrice;
                                            }
                        
                                            if (outbound.paid_options && outbound.paid_options.length > 0) {
                                                outbound.paid_options.map(option => {
                                                    let optionPrice = 0;
                                                    if ((option.prices?.length ?? 0) > 0) {
                                                        optionPrice = getPrice(option.prices, undefined).cost;
                                                    } else {
                                                        optionPrice = parseFloat(option.price);
                                                    }
                                                    if (option.option_on_flight) {
                                                        if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else if (option.option_on_group) {
                                                        if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else {
                                                        option_cost += optionPrice;
                                                    }
                                                }); 
                                            }
                                        }
                                    });
                                }
                                else {
                                    seatsCost = big_cart[i].paid_option_price.filter((item) => {
                                        return item.service_type === 'SEATS';
                                    }).reduce((prev, current) => {
                                        return prev + getPrice(current.prices).cost;
                                    }, 0);
                                    otherOptionsCost = big_cart[i].paid_option_price.filter((item) => {
                                        return item.service_type !== 'SEATS';
                                    }).reduce((prev, current) => {
                                        return prev + getPrice(current.prices, undefined).cost;
                                    }, 0);
                                }
                                tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, null));
                                if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                    // price += getTaxesPrice(item.taxes);
                                    const master_price = big_cart[i].prices.find((price) => price.master_price);
                                    tmp_full_buying_price += parseFloat(ConvertPrice(getTaxesPrice(big_cart[i].taxes), currency, currency_buy, null));
                                }
                            }
                        }
                        CheckCustomCurrency(big_cart[i], custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                        let my_margin = GetMargin(big_cart[i], big_cart[i].type, i, list_currency, currency, user, owner_id);
                        total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                        to_margin += my_margin.item.margin_value !== null ? parseFloat(my_margin.item.margin_value) : 0;
                        total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                    }
                }
            }
            if (manual_cart) {
                if (data_trip.flight_taxes !== null) {
                    tmp_full_price += parseFloat(data_trip.flight_taxes);
                }
                if (data_trip.prices_flight.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user)].selling_price);

                    if (data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        if (data_trip.flight_taxes !== null) {
                            tmp_full_buying_price += parseFloat(data_trip.flight_taxes);
                            tmp_only_main_currency_price += parseFloat(data_trip.flight_taxes);
                            total_taxe += parseFloat(data_trip.flight_taxes);
                        }
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_currency;
                        });
                        // if (tmp_not_same_currency_cart.length === 0) {
                            let tmp_price;
                            let tmp_obj = {};
                            tmp_obj.currency = currency_buy;
                            tmp_obj.factor_used = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used);
                            tmp_price = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                            tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                            if (data_trip.flight_taxes !== null) {
                                tmp_price += parseFloat(data_trip.flight_taxes);
                                tmp_obj.converted_price += parseFloat(data_trip.flight_taxes);
                                total_taxe += parseFloat(data_trip.flight_taxes);
                            }
                            tmp_obj.price = tmp_price;
                            tmp_not_same_currency_cart.push(tmp_obj);
                        // } else {
                        //     let found = false;
                        //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                        //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                        //             found = true;
                        //             tmp_not_same_currency_cart[j].price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                        //         }
                        //         if (j === tmp_not_same_currency_cart.length && found === false) {
                        //             let tmp_obj = {};
                        //             let tmp_price;
                        //             tmp_obj.currency = currency_buy;
                        //             tmp_obj.factor_used = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used);
                        //             tmp_price = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                        //             if (data_trip.flight_taxes !== null) {
                        //                 tmp_price += parseFloat(data_trip.flight_taxes);
                        //                 tmp_obj.converted_price += parseFloat(data_trip.flight_taxes);
                        //                 total_taxe += parseFloat(data_trip.flight_taxes);
                        //             }
                        //             tmp_obj.price = tmp_price;
                        //             tmp_not_same_currency_cart.push(tmp_obj);
                        //         }
                        //     }
                        // }
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_flight", null, list_currency, currency, user, owner_id, 0);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.flight.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                }
                if (data_trip.prices_terrestrial.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user)].selling_price);

                    if (data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_currency;
                        });
                        // if (tmp_not_same_currency_cart.length === 0) {
                            let tmp_price;
                            let tmp_obj = {};
                            tmp_obj.currency = currency_buy;
                            tmp_obj.factor_used = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used);
                            tmp_price = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                            tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                            tmp_obj.price = tmp_price;
                            tmp_not_same_currency_cart.push(tmp_obj);
                        // } else {
                        //     let found = false;
                        //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                        //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                        //             found = true;
                        //             tmp_not_same_currency_cart[j].price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                        //         }
                        //         if (j === tmp_not_same_currency_cart.length && found === false) {
                        //             let tmp_obj = {};
                        //             let tmp_price;
                        //             tmp_obj.currency = currency_buy;
                        //             tmp_obj.factor_used = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used);
                        //             tmp_price = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                        //             tmp_obj.price = tmp_price;
                        //             tmp_not_same_currency_cart.push(tmp_obj);
                        //         }
                        //     }
                        // }
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_terrestrial", null, list_currency, currency, user, owner_id, 1);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.terrestrial.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                }
                if (data_trip.prices_stack_product.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user)].selling_price);

                    if (data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_currency;
                        });
                        let tmp_price;
                        let tmp_obj = {};
                        tmp_obj.currency = currency_buy;
                        tmp_obj.factor_used = parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used);
                        tmp_price = parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                        tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used)));
                        tmp_obj.price = tmp_price;
                        tmp_not_same_currency_cart.push(tmp_obj);
                        
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_stack_product", null, list_currency, currency, user, owner_id, 2);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.terrestrial.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;

                }
            }
            let agency_margin = tmp_full_price - tmp_full_buying_price;
            setGetTotalPrice(true);
            setTotalPrice(tmp_full_price);
            if (!['cercledesvoyages'].includes(quotation_code)) {
                setFullBuyingPrice(tmp_full_buying_price);
            } else {
                setFullBuyingPrice(tmp_full_buying_price + parseFloat(total_surcom) + parseFloat(tva));
            }
            console.log('tmp_not_same_currency_cart:', tmp_not_same_currency_cart);
            console.log('tmp_only_main_currency_price:', tmp_only_main_currency_price);
            let group_not_same_currency = [];
            tmp_not_same_currency_cart.map((item) => {
                if (group_not_same_currency.length === 0 || group_not_same_currency.find(el => el.currency.id === item.currency.id) === undefined) {
                    group_not_same_currency.push(item);
                }
                else if (group_not_same_currency.find(el => el.currency.id === item.currency.id) !== undefined) {
                    group_not_same_currency.map(el => {
                        if (el.currency.id === item.currency.id) {
                            el.price += item.price;
                            el.converted_price += item.converted_price;
                        }
                    });
                }
            });
            console.log('group_not_same_currency:', group_not_same_currency);
            setNotSameCurrencyCart(group_not_same_currency);
            setOnlyMainCurrencyPrice(tmp_only_main_currency_price);
            setTotalSurcom(total_surcom);
            setToMargin(to_margin);
            setTotalNetwork(total_network);
            setCashBack(tmp_cash_back);
            setTvaValue(tva);
            if (!['cercledesvoyages'].includes(quotation_code)) {
                setTotalMargin(parseFloat(to_margin) + parseFloat(total_network) + parseFloat(total_surcom));
            } else {
                setTotalMargin(parseFloat(to_margin) + parseFloat(total_network));
            }
            setTotalMarginAgency(agency_margin);
            setBigtotalMarginAgency(agency_margin + total_network);
            setTotalTaxe(total_taxe);
        }
    }, [get_cart_done, getTaxesPrice]);
    return (
        <>
            <Grid container style={{ marginTop: 20 }}>
                <Grid item container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h4"} className={classes.genericText} style={{ textAlign: "center" }}>{(!HasPermission(permissions, "view_accountingbook", user) || finance_charges.length === 0) ? t("menu.trip_list.finance_synthesis") : (reel ? t("menu.trip_list.finance_synthesis_reel") : t("menu.trip_list.finance_synthesis_forecast"))}</Typography>
                    </Grid>
                </Grid>
                {
                    get_total_price && (
                        <>
                            {
                                quotation_code === "verdie" && (
                                    <Grid item xs={12} container direction={"row"} justifyContent={"space-between"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                        <Grid item sm={6} xs={12} container direction={"row"}>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    {t("menu.trip_list.selling_price") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_price).toFixed(2)) }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    {t("menu.trip_list.total_buy") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                            </Grid>
                                            {
                                                not_same_currency_cart.length > 0 && not_same_currency_cart.map((cart, cart_index) => (
                                                    <Fragment key={cart_index}>
                                                        <Grid item xs={12} container direction={"row"}>
                                                            <Grid item sm={6} xs={5}>
                                                                <div className={ "ft-font-12" }>{ cart_index === 0 ? "=" : "+" }<span className={ "ft-pl15 ft-font-12"}>{ t("menu.trip_list.currency_buy") }</span></div>
                                                            </Grid>
                                                            <Grid item sm={6} xs={7} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                <div className={ "ft-font-12" }>
                                                                    { new Intl.NumberFormat(language, { style: 'currency', currency: cart.currency.iso_code }).format(parseFloat(cart.price).toFixed(2)) + " -> " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(cart.converted_price).toFixed(2)) }
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} container direction={"row"}>
                                                            <Grid item sm={6} xs={10}>
                                                                <div className={ "ft-font-12" }><span className={ "ft-pl21 ft-font-12"}>{ t("menu.trip_list.price_factor") + " " }<span className={ "ft-font-10" }>{ cart.currency.iso_code + " -> " + " " + currency.iso_code}</span></span></div>
                                                            </Grid>
                                                            <Grid item sm={6} xs={2} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language).format(parseFloat(cart.factor_used).toFixed(2)) }</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                            }
                                            <Grid item xs={6}>
                                                <div className={ "ft-font-12" }>{not_same_currency_cart.length > 0 ? "+" : "=" }<span className={ "ft-pl15 ft-font-12" }>{ t("menu.trip_list.buy") }<span className={ "italic ft-font-11" }>{" " + t("global.in") + " " + currency.symbol }</span></span></div>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(only_main_currency_price).toFixed(2)) }</div>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={12} container direction={"row"} className={clsx({ [classes.textAlightRight]: !downDesktop })}>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.cashback") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                {Math.abs(cashBack) + " " + currency.symbol }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.TO_margin_verdie") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(to_margin).toFixed(2)) }
                                            </Grid>
                                            {
                                                total_surcom !== null && total_surcom !== undefined && total_surcom !== 0 && (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                { t("menu.trip_list.surcom_assistance") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_surcom).toFixed(2)) }
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.total_margin") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2)) }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                quotation_code !== "verdie" && (
                                    <>
                                        {
                                            user.client_full.type === 2 && (
                                                <Grid item xs={12} container direction={"row"} justifyContent={"center"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                                    <Grid item sm={6} xs={12} container direction={"row"}>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.net_TO") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((total_network !== null && total_network !== undefined ? parseFloat(full_buying_price) - parseFloat(total_network) : parseFloat(full_buying_price)).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            total_network !== null && total_network !== undefined && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_network_agency") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_buy_agency") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.total_margin_agency") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin_agency).toFixed(2)) }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.client_selling_price") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(full_buying_price) + parseFloat(total_margin_agency)).toFixed(2)) }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        {
                                            user.client_full.type !== 2 && (
                                                <Grid item xs={12} container direction={"row"} justifyContent={"space-between"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                                    <Grid item sm={6} xs={12} container direction={"row"}>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.selling_price") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_price).toFixed(2)) }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_buy") + " " : t("menu.trip_list.total_buy_fee")}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            not_same_currency_cart.length > 0 && not_same_currency_cart.map((cart, cart_index) => (
                                                                <Fragment key={cart_index}>
                                                                    <Grid item xs={12} container direction={"row"}>
                                                                        <Grid item sm={6} xs={5}>
                                                                            <div className={ "ft-font-12" }>{ cart_index === 0 ? "=" : "+" }<span className={ "ft-pl15 ft-font-12"}>{ t("menu.trip_list.currency_buy") }</span></div>
                                                                        </Grid>
                                                                        <Grid item sm={6} xs={7} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: cart.currency.iso_code }).format(parseFloat(cart.price).toFixed(2)) + " -> " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(cart.converted_price).toFixed(2)) }</div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} container direction={"row"}>
                                                                        <Grid item sm={6} xs={10}>
                                                                            <div className={ "ft-font-12" }><span className={ "ft-pl21 ft-font-12"}>{ t("menu.trip_list.price_factor") + " " }<span className={ "ft-font-10" }>{ cart.currency.iso_code + " -> " + " " + currency.iso_code}</span></span></div>
                                                                        </Grid>
                                                                        <Grid item sm={6} xs={2} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language).format(parseFloat(cart.factor_used).toFixed(2)) }</div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <Grid item xs={6}>
                                                            <div className={ "ft-font-12" }>
                                                                {not_same_currency_cart.length > 0 ? "+" : "=" }
                                                                <span className={ "ft-pl15 ft-font-12" }>{ t("menu.trip_list.buy") }
                                                                    <span className={ "italic ft-font-11" }>{" " + t("global.in") + " " + currency.symbol }</span>
                                                                </span>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(only_main_currency_price).toFixed(2)) }</div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12} container direction={"row"} className={clsx({ [classes.textAlightRight]: !downDesktop })}>
                                                        {
                                                            cashBack !== 0 && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.cashback") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                        {Math.abs(cashBack) + " " + currency.symbol }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                { (quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites" ? t("menu.trip_list.TO_margin") : t("menu.trip_list.TO_margin_verdie")) + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(to_margin).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            ['cercledesvoyages'].includes(quotation_code) && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.TO_margin_verdie") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " % " }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        {new Intl.NumberFormat(language).format(parseFloat(((to_margin / total_price) * 100).toFixed(2)).toFixed(2)) + '%' }
                                                                    </Grid>
                                                                    {
                                                                        tvaValue !== 0 && (
                                                                            <>
                                                                                <Grid item xs={6}>
                                                                                    <span className={classes.bold}>
                                                                                        { t('cart-material.manual-product-margin-vat-value') + " "}
                                                                                    </span>
                                                                                    <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                                        { t("global.in") + " " + currency.symbol }
                                                                                    </span>
                                                                                </Grid>
                                                                                <Grid item xs={6} className={classes.textAlightRight}>
                                                                                    { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(tvaValue).toFixed(2)) }
                                                                                </Grid>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            total_surcom !== null && total_surcom !== undefined && total_surcom !== 0 && quotation_code !== "marcovasco" && ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_surcom") : t("menu.trip_list.surcom")) + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_surcom).toFixed(2)) }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            total_network !== null && total_network !== undefined && total_network !== 0 && quotation_code !== "marcovasco" && ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_network") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_margin") : t("menu.trip_list.total_margin_trip")) + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { !['cercledesvoyages'].includes(quotation_code) ?
                                                                            new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2))
                                                                            :
                                                                            new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(total_margin) + parseFloat(total_surcom)).toFixed(2))
                                                                        }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
            </Grid>
            {
                !get_total_price && exportData.length === 0 && exportDataCSV.length === 0 && (
                    <div className={clsx({ [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                        <LinearProgress />
                    </div>
                )
            }
        </>
    );
};
export default TripListFinanceSynthesis;

