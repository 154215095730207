import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    CircularProgress,
    Typography,
    keyframes,
    styled
} from "@mui/material";
import {
    CalendarToday,
    ConnectingAirports,
    Downloading,
    HourglassBottom,
    QuestionMark,
    Save
} from "@mui/icons-material";
import { areStepsEqual } from "../Itinerary/utils/areStepsEqual";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryMapStatus(): JSX.Element | null {
    const { t } = useTranslation();
    const status = useSelector((state: AppState) => state.map.status);
    const isCalculatingTransport = useSelector((state: AppState) => {
        return !!state.itinerarySlice.beingCalculatedStep;
    });
    const isImportingTexts = useSelector((state: AppState) => state.itinerarySlice.isImportingTexts);
    const steps = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const inputs = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const isDataUnSaved = useMemo(() => {
        return inputs.length !== steps.length ||
            !inputs.every((item, index) => {
                const step = steps[index];

                if (step) {
                    return areStepsEqual(item, step);
                }

                return false;
            });
    }, [steps, inputs]);

    let Component = Save;
    let progressColor = '#25D0BC';
    let iconColor = '#5B9AF0';
    let message = '';

    if (isCalculatingTransport) {
        Component = ConnectingAirports;
        progressColor = '#F97977';
        iconColor = '#788BFF';
        message = t('itinerary.transports-calculation');
    } else if (status === 'changing-trip-dates') {
        Component = CalendarToday;
        progressColor = '#25D0BC';
        iconColor = '#5B9AF0';
        message = t('itinerary.changing-trip-dates');
    } else if (status === 'loading-itinerary') {
        Component = HourglassBottom;
        progressColor = '#c2c932';
        iconColor = '#c2c932';
        message = t('itinerary.loading-itinerary');
    } else if (status === 'saving') {
        message = t('itinerary.saving');
    } else if (isDataUnSaved) {
        Component = QuestionMark;
        message = t('itinerary.data-mismatch');
    } else if (isImportingTexts) {
        Component = Downloading;
        message = t('itinerary.texts-import');
    }

    if (
        !isCalculatingTransport &&
        status === 'idle' &&
        !isDataUnSaved &&
        !isImportingTexts
    ) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 10,
                left: 10,
                color: progressColor,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box sx={{ position: 'relative', display: 'flex' }}>
                <CircularProgress size={25} color="inherit" />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 3,
                        left: 3,
                        width: 19,
                        height: 19,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: iconColor
                    }}
                >
                    <Component
                        color="inherit"
                        fontSize="inherit"
                        sx={{ color: '#fff', fontSize: 10 }}
                    />
                </Box>
            </Box>
            <EllipsisAnimatedTypography variant="caption">
                {message}
            </EllipsisAnimatedTypography>
        </Box>
    );
}

const EllipsisAnimation = keyframes(`
to {
    width: 15px;
}
`);

const EllipsisAnimatedTypography = styled(Typography)(() => ({
    "color": '#fff',
    "fontWeight": 'bold',
    "marginLeft": 8,
    '&:after': {
        overflow: 'hidden',
        display: 'inline-block',
        verticalAlign: 'bottom',
        animation: `${EllipsisAnimation} steps(4, end) 900ms infinite`,
        content: '"\\2026"',
        width: 0
    }
}));
