import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Currency } from "../../../Reducers/objects/currency";
import { ProductPrice } from "../../Itinerary/objects/productPrice";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (
    prices: ProductPrice[],
    type?: 'master-price' | 'vat-included',
    force_network?: boolean
) => {
    id: number | null,
    cost: number,
    purchaseCost: number,
    margin: number,
    marginType: 'FIX' | 'PER' | null,
    currency: Currency | undefined,
    purchaseCurrency: Currency | undefined,
    custom_rate: boolean,
    percentageBetween: number,
    factorUsed: number,
    vatAmount: number,
    vatCurrency: Currency | undefined,
}

export function useGetPrice(): Callback {
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const user = useSelector((state: AppState) => state.user.user);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const priceToDisplay = (
        options: {
            price: ProductPrice,
            masterPrice: ProductPrice | null
            vatPrice: ProductPrice | null
        }
    ) => {
        return {
            id: options.price.id,
            cost: parseFloat(options.price.selling_price),
            purchaseCost: parseFloat(options.masterPrice?.purchase_price ?? options.price.purchase_price),
            margin: parseFloat(options.masterPrice?.custom_value ?? '0'),
            marginType: (options.masterPrice?.custom_rate_type ?? null) as 'FIX' | 'PER' | null,
            currency: currency_list.find((currency) => {
                return currency.id === options.price.selling_currency;
            }),
            purchaseCurrency: currency_list.find((currency) => {
                return currency.id === (options.masterPrice?.purchase_currency ?? options.price.purchase_currency);
            }),
            custom_rate: options.masterPrice?.custom_rate ?? false,
            percentageBetween: parseFloat(options.masterPrice?.percentage_between ?? '0'),
            factorUsed: parseFloat(options.masterPrice?.factor_used ?? '1'),
            vatAmount: parseFloat(options.vatPrice?.selling_price ?? '0') - parseFloat(options.vatPrice?.purchase_price ?? '0'),
            vatCurrency: currency_list.find((currency) => {
                return currency.id === options.vatPrice?.selling_currency;
            })
        };
    };

    return useMemo(() => {
        return (prices: ProductPrice[], type = 'vat-included', force_network = false) => {
            const masterPrice = prices.find((item) => {
                return item.master_price;
            });
            const vatPrice = prices.find((item) => {
                return item.is_tva;
            });
            if (quotation_code === "volonline" || quotation_code === "gattinoni") {
                type = 'master-price';
            }
            if (type === 'master-price' && masterPrice) {
                return priceToDisplay({
                    price: masterPrice,
                    masterPrice,
                    vatPrice: vatPrice ?? null
                });
            }

            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                if (force_network) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]?.is_network) {
                            return priceToDisplay({
                                price: prices[i]!,
                                masterPrice: masterPrice ?? null,
                                vatPrice: vatPrice ?? null
                            });
                        }
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.is_tva) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.is_surcom) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.master_price) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.owner === (user?.client as unknown as number)) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.is_tva) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]?.owner === (user?.client as unknown as number)) {
                        return priceToDisplay({
                            price: prices[i]!,
                            masterPrice: masterPrice ?? null,
                            vatPrice: vatPrice ?? null
                        });
                    }
                }
            }
            return {
                id: null,
                cost: 0,
                purchaseCost: 0,
                margin: 0,
                marginType: 'FIX',
                currency: currency,
                purchaseCurrency: currency,
                custom_rate: false,
                percentageBetween: 0,
                factorUsed: 1,
                vatAmount: 0,
                vatCurrency: currency
            };
        };
    }, [
        currency_list,
        currency,
        user
    ]);
}
