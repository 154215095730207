import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import {
    Box,
    Divider,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import { AllInclusive, Language } from "@mui/icons-material";
import { isNumber } from "lodash";
import { AppState } from "../../Reducers/Reducers";

type Props = WithRouterProps

export function ProviderBookingNavbar(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language ||
            (
                item.language_code.split('-')[0] &&
                item.language_code.split('-')[0] === i18n.language.split('-')[0]
            );
    })?.id ?? 1);
    const languages = useSelector((state: AppState) => state.user.locales);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const provider = useMemo(() => {
        const providerId = parseInt(props.params.providerId ?? '-1');
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === providerId;
        });
    }, [props, providers, manualProviders]);

    const onChangeLanguage = (value: number | string) => {
        const language = isNumber(value) ?
            languages.find((item) => {
                return item.id === value;
            })?.language_code :
            value;
        if (language) {
            i18n.changeLanguage(language);
            window.moment.locale(language);
        }
    };

    useEffect(() => {
        let language: string | null = navigator.language ??
                                        ((navigator as any).userLanguage);
        language = language.split('-')[0] ?? 'fr';
        if (language) {
            onChangeLanguage(language);
        }
    }, []);

    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 2.5,
                    paddingRight: 2.5
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <img
                            src={
                                tripAllData?.owner.logo?.url ??
                                tripAllData?.owner.logo?.thumbnail_medium ??
                                tripAllData?.owner.logo?.thumbnail_little ??
                                '/Img/inner_logo_header.png'
                            }
                            width="auto"
                            height="26px"
                        />
                        <AllInclusive />
                        <img
                            src={
                                provider?.provider.logo?.thumbnail_little ??
                                provider?.provider.logo?.url ??
                                '/Img/inner_logo_header.png'
                            }
                            width="auto"
                            height="26px"
                        />
                    </Stack>
                    <Typography
                        variant="h6"
                        textTransform="uppercase"
                        fontWeight={400}
                        component="div"
                    >
                        {provider?.provider.name}
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography fontWeight={100}>
                        {t('cart-material.provider-booking-navbar-title')} -{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {
                                t(
                                    'cart-material.provider-booking-trip-reference',
                                    { ref: tripAllData?.trip_reference }
                                )
                            }
                        </span>
                    </Typography>
                </Stack>

                <FormControl size="small">
                    <Select
                        value={locale}
                        onChange={(event) => onChangeLanguage(event.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <Language />
                            </InputAdornment>
                        }
                    >
                        {
                            languages.map((language) => (
                                <MenuItem key={language.id} value={language.id}>
                                    {
                                        t(
                                            `language.${language.language_code}`,
                                            { defaultValue: language.full_name }
                                        )
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Stack>
            <Divider />
        </Box>
    );
}
