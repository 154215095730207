import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    keyframes,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import {
    Air,
    AirportShuttle,
    BedroomBaby,
    CalendarToday,
    DirectionsBike,
    DirectionsBoat,
    DirectionsBus,
    DirectionsCar,
    DirectionsRailway,
    DirectionsSubway,
    DirectionsTransit,
    DirectionsWalk,
    Flight,
    HourglassEmpty,
    Info,
    LocalTaxi,
    Loop,
    QuestionMark,
    RemoveShoppingCart,
    ReportProblem,
    ThumbUp,
    TwoWheeler,
    Warning
} from "@mui/icons-material";
import { uniqBy } from "lodash";
import { ItineraryTransportItemAddInput } from "./itineraryTransportItemAddInput";
import { ItineraryTransportItemInsertStepButton } from "./itineraryTransportItemInsertStepButton";
import { ItineraryTransportItemAddInputContainer } from "./itineraryTransportItemAddInputContainer";
import { ItineraryStepItemFlightModal } from "./itineraryStepItemFlightModal";
import { StepsDirectionsManager } from "./utils/stepsDirectionsManager";
import { getTransportColor } from "./utils/getTransportColor";
import { ItineraryContext } from "./utils/itineraryContext";
import { VehicleKind } from "./objects/itinerary";
import { FlashDestination } from "./objects/flashDestination";
import { PlaneOperatingDays } from "./objects/planeOperatingDays";
import { ItineraryInput } from "./objects/itineraryState";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    index: number,
    step: ItineraryInput,
    nextStep: ItineraryInput | undefined,
    stepId: number | string,
    mode: VehicleKind,
    extraMode: VehicleKind[],
    distance: number,
    time: string,
    selected: boolean,
    last?: boolean,
    operatingDays?: PlaneOperatingDays[],
    hideTransport?: boolean,
    hasError?: boolean,
    isBeingReset?: boolean,
    isAboveGroup: boolean,
    loadingDestination: boolean,
    disabled?: boolean,
    onAddDestination: (destination: FlashDestination) => Promise<void>,
    onChangeTransport: () => void,
    onResetTransport: () => void
}

export function ItineraryTransportItem(props: Props): JSX.Element | null {
    const { t } = useTranslation();
    const isCalculating = useSelector((state: AppState) => {
        return Boolean(
            state.itinerarySlice.beingCalculatedStep &&
            state.itinerarySlice.beingCalculatedStep.id === props.stepId
        );
    });
    const isCalculatingAtIndex = useSelector((state: AppState) => {
        return !!state.itinerarySlice.calculatingTransportAtIndex[props.index];
    });
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const flights = useSelector((state: AppState) => {
        return state.flight.cart;
    });
    const manualProducts = useSelector((state: AppState) => {
        return state.cart.manual_item_list;
    });
    const [openFlightModal, setOpenFlightModal] = useState(false);
    const correspondingFlights = useMemo(() => {
        if (
            !props.step.r2r_json?.selected ||
            props.step.r2r_json?.vehicle?.kind !== 'plane'
        ) {
            return [];
        }
        const result = flights?.filter((item) => {
            return window.moment.utc(item.start_date).isSame(
                window.moment.utc(props.step.end_date)
            );
        }).concat(
            manualProducts?.filter((item) => {
                return item.product_type === 6 &&
                    window.moment.utc(item.start_date).isSame(
                        window.moment.utc(props.step.end_date)
                    );
            })
        );
        return result;
    }, [flights, manualProducts]);
    const context = useContext(ItineraryContext);
    const [addStep, setAddStep] = useState(false);
    const [isCorrect, setIsCorrect] = useState(true);
    const days: { no: number, name: string }[] = uniqBy(
        new Array(7).fill(null).map((_, index) => {
            const no = parseInt(t(`itinerary.operating-days[${index}].no`));
            const name = t(`itinerary.operating-days[${index}].name`);
            return { no, name };
        }),
        (item) => item.no
    );

    const onAddDestination = async (destination: FlashDestination) => {
        await props.onAddDestination(destination);
        setAddStep(false);
    };

    useEffect(() => {
        (async () => {
            if (map && props.nextStep) {
                const manager = StepsDirectionsManager.getInstance();
                setIsCorrect(
                    !(
                        await manager.isTransportBetweenInvalid(
                            props.step,
                            props.nextStep,
                            true
                        )
                    )
                );
            }
        })();
    }, [
        map,
        props.step,
        props.nextStep
    ]);

    if (context.isDragging) {
        return null;
    }

    return (
        <ListItem
            sx={{
                flexDirection: 'column',
                padding: 0,
                alignItems: 'stretch',
                paddingLeft: 4,
                paddingRight: 4,
                zIndex: 0
            }}
        >
            {
                (props.last || addStep || isCalculatingAtIndex) &&
                <ItineraryTransportItemAddInputContainer
                    transportColor={getTransportColor(props.mode)}
                    disableClose={
                        props.last ||
                        props.loadingDestination ||
                        isCalculatingAtIndex
                    }
                    onClose={() => setAddStep(false)}
                >
                    <ItineraryTransportItemAddInput
                        loading={props.loadingDestination || isCalculatingAtIndex}
                        onChoose={onAddDestination}
                    />
                </ItineraryTransportItemAddInputContainer>
            }
            {
                !props.hideTransport &&
                <Stack
                    direction="row"
                    spacing={2}
                    flex={1}
                >
                    <ModeIcon
                        transportColor={
                            props.hasError && !props.selected ?
                                '#f20000' :
                                getTransportColor(props.mode)
                        }
                        sx={{ marginLeft: props.extraMode.length > 0 ? '-15px !important' : undefined }}
                    >
                        <LightTooltip
                            title={
                                <Box sx={{ margin: 2 }}>
                                    <Stack direction="row" spacing={1}>
                                        <Info fontSize="inherit" sx={{ fontSize: 16 }} />
                                        <Typography variant="caption">
                                            {
                                                props.hasError ?
                                                    t('itinerary.available-only-with-error') :
                                                    t('itinerary.available-only')
                                            }
                                        </Typography>
                                    </Stack>
                                    <List dense>
                                        {
                                            days.filter(({ no }) => {
                                                return props.operatingDays?.includes(no);
                                            }).map(({ name }) => {
                                                return (
                                                    <ListItem key={name}>
                                                        <ListItemIcon sx={{ minWidth: 30 }}>
                                                            <CalendarToday fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={name}
                                                            primaryTypographyProps={{
                                                                variant: 'caption'
                                                            }}
                                                        />
                                                    </ListItem>
                                                );
                                            })
                                        }
                                    </List>
                                </Box>
                            }
                            sx={(theme) => ({
                                [`.${tooltipClasses.tooltip}`]: {
                                    backgroundColor: theme.palette.common.white,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    boxShadow: theme.shadows[1]
                                }
                            })}
                            disableHoverListener={
                                props.mode !== 'plane' ||
                                !props.operatingDays ||
                                props.selected
                            }
                            arrow
                        >
                            <div>
                                {
                                    isCalculating &&
                                    <CircularProgress color="inherit" size={15} />
                                }
                                {
                                    (!props.hasError || props.selected) && !isCalculating &&
                                    <>
                                        {
                                            props.extraMode.length === 0 &&
                                            <div className="icon">
                                                <TransportIcon mode={props.mode} />
                                            </div>
                                        }
                                        {
                                            props.extraMode.length > 0 &&
                                            <Stack direction="row" alignItems="center" gap={0.5}>
                                                <Box
                                                    className="icon"
                                                    sx={{
                                                        width: '20px !important',
                                                        height: '20px !important',
                                                        svg: {
                                                            fontSize: '10px !important'
                                                        }
                                                    }}

                                                >
                                                    <TransportIcon mode={props.mode} />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        fontSize: 13
                                                    }}
                                                    component="span"
                                                >
                                                    +
                                                </Box>
                                                {
                                                    props.extraMode.map((mode) => (
                                                        <Box
                                                            key={mode}
                                                            className="icon"
                                                            sx={{
                                                                width: '20px !important',
                                                                height: '20px !important',
                                                                borderColor: `${getTransportColor(mode)} !important`,
                                                                svg: {
                                                                    fontSize: '10px !important',
                                                                    color: `${getTransportColor(mode)} !important`
                                                                }
                                                            }}
                                                        >
                                                            <TransportIcon mode={mode} />
                                                        </Box>
                                                    ))
                                                }
                                            </Stack>
                                        }
                                    </>
                                }
                                {
                                    props.hasError &&
                                    !isCalculating &&
                                    !props.selected &&
                                    <Warning />
                                }
                            </div>
                        </LightTooltip>
                    </ModeIcon>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            marginBottom: props.isAboveGroup ? '26px !important' : undefined
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ marginTop: 'auto', marginBottom: -1, minHeight: 30.5 }}
                        >
                            {
                                props.distance >= 600 &&
                                ![
                                    'plane',
                                    'helicopter',
                                    'cablecar',
                                    'ferry'
                                ].includes(props.mode) &&
                                <Box
                                    sx={{
                                        fontSize: 30.5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#FFB050'
                                    }}
                                >
                                    <ReportProblem fontSize="inherit" color="inherit" />
                                </Box>
                            }
                            {
                                isCalculating &&
                                <Typography
                                    fontWeight={700}
                                    fontSize={12}
                                    sx={{
                                        opacity: 0.54,
                                        marginRight: 2,
                                        marginLeft: 2
                                    }}
                                >
                                    {t('itinerary.calculation-in-progress')}
                                </Typography>
                            }
                            {
                                !isCalculating &&
                                <>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={12}
                                        sx={{
                                            color: '#D04785',
                                            marginLeft: 2,
                                            marginRight: '5px'
                                        }}
                                    >
                                        {props.distance} Km
                                    </Typography>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={12}
                                        sx={{
                                            opacity: 0.54,
                                            marginRight: 2
                                        }}
                                    >
                                        {props.time.split('.')[0]}
                                    </Typography>
                                </>
                            }
                            <Typography
                                fontWeight={700}
                                fontSize={12}
                            >
                                {
                                    props.mode !== 'plane' &&
                                    t(`global.${props.mode}`)
                                }
                                {
                                    props.mode === 'plane' &&
                                    [
                                        t(`global.flight`),
                                        ...props.extraMode.map((item) => {
                                            if (item === 'ferry') {
                                                return t('apps_navigation.ferry');
                                            }
                                            return t(`global.${item}`);
                                        })
                                    ].join(' + ')
                                }
                            </Typography>
                            <Box sx={{ fontSize: '10px', display: 'flex', marginLeft: '5px' }}>
                                {
                                    props.selected && isCorrect &&
                                    <Tooltip title={t('itinerary.transport-in-cart')}>
                                        <ThumbUp
                                            fontSize="inherit"
                                            htmlColor="#4BC84B"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => setOpenFlightModal(true)}
                                        />
                                    </Tooltip>
                                }
                                {
                                    !props.selected && isCorrect &&
                                    <Tooltip title={t('itinerary.transport-not-in-cart')}>
                                        <ThumbUp
                                            fontSize="inherit"
                                            htmlColor="#EB5757"
                                            sx={{ transform: 'rotateX(180deg)', cursor: 'pointer' }}
                                        />
                                    </Tooltip>
                                }
                                {
                                    props.selected && !isCorrect &&
                                    <Tooltip title={t('itinerary.incompatible-product')}>
                                        <RemoveShoppingCart
                                            fontSize="inherit"
                                            htmlColor="#EB5757"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => setOpenFlightModal(true)}
                                        />
                                    </Tooltip>
                                }
                                {
                                    !props.selected && !isCorrect &&
                                    <Tooltip title={t('itinerary.transport-need-recalculation')}>
                                        <HourglassEmpty
                                            fontSize="inherit"
                                            htmlColor="#E9D502"
                                            sx={{ cursor: 'pointer' }}
                                        />
                                    </Tooltip>
                                }
                            </Box>
                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <IconButton
                                    sx={{ padding: 0, lineHeight: 1, fontSize: 15 }}
                                    onClick={props.onResetTransport}
                                    disabled={props.isBeingReset || props.disabled}
                                >
                                    <Loop
                                        fontSize="inherit"
                                        sx={{
                                            animation: props.isBeingReset ?
                                                `${ResetAnimation} 1.5s linear infinite` :
                                                undefined
                                        }}
                                    />
                                </IconButton>
                                <Button
                                    sx={(theme) => ({
                                        "padding": '0.2rem',
                                        "lineHeight": 1,
                                        "marginLeft": 0.5,
                                        "fontSize": 9,
                                        "background": props.selected ?
                                            '#E6E6E6' :
                                            '#E6592F',
                                        "color": theme.palette.getContrastText(
                                            props.selected ?
                                                '#E6E6E6' :
                                                '#E6592F'
                                        ),
                                        "textTransform": 'none',
                                        '&:disabled': {
                                            color: '#fff'
                                        }
                                    })}
                                    onClick={props.onChangeTransport}
                                    disabled={props.disabled}
                                >
                                    {
                                        props.selected ?
                                            t('itinerary.edit_transport') :
                                            t('itinerary.choose_transport')
                                    }
                                </Button>
                            </Box>
                        </Stack>
                        <ItineraryTransportItemInsertStepButton
                            onClick={() => setAddStep(true)}
                            disabled={props.disabled}
                        />
                    </Box>
                </Stack>
            }
            <ItineraryStepItemFlightModal
                open={openFlightModal}
                flights={correspondingFlights ?? []}
                onClose={() => setOpenFlightModal(false)}
            />
        </ListItem>
    );
}

type TransportIconProps = {
    mode: VehicleKind
}

function TransportIcon(props: TransportIconProps): JSX.Element {
    return (
        <>
            {
                props.mode === 'animal' &&
                <BedroomBaby />
            }
            {
                props.mode === 'bicycle' &&
                <DirectionsBike />
            }
            {
                props.mode === 'bus' &&
                <DirectionsBus />
            }
            {
                props.mode === 'cablecar' &&
                <DirectionsTransit />
            }
            {
                props.mode === 'car' &&
                <DirectionsCar />
            }
            {
                props.mode === 'ferry' &&
                <DirectionsBoat />
            }
            {
                props.mode === 'foot' &&
                <DirectionsWalk />
            }
            {
                props.mode === 'helicopter' &&
                <Air />
            }
            {
                props.mode === 'plane' &&
                <Flight />
            }
            {
                props.mode === 'rideshare' &&
                <DirectionsCar />
            }
            {
                props.mode === 'shuttle' &&
                <AirportShuttle />
            }
            {
                props.mode === 'subway' &&
                <DirectionsSubway />
            }
            {
                props.mode === 'taxi' &&
                <LocalTaxi />
            }
            {
                props.mode === 'towncar' &&
                <DirectionsCar />
            }
            {
                props.mode === 'train' &&
                <DirectionsRailway />
            }
            {
                props.mode === 'tram' &&
                <DirectionsTransit />
            }
            {
                props.mode === 'unknown' &&
                <QuestionMark />
            }
            {
                props.mode === 'bike' &&
                <TwoWheeler />
            }
        </>
    );
}

const ModeIcon = styled(
    Box,
    {
        shouldForwardProp(name) {
            return name !== 'transportColor';
        }
    }
)<{
    transportColor: string
}>((props) => ({
    "display": 'flex',
    "justifyContent": 'center',
    "alignItems": 'center',
    "minHeight": 55,
    "position": 'relative',
    '.icon': {
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `3px solid ${props.transportColor}`,
        borderRadius: '50%',
        padding: 2,
        zIndex: 1,
        backgroundColor: '#fff',
        color: props.transportColor,
        cursor: 'pointer',
        svg: {
            fontSize: 15
        }
    },
    '&:before': {
        content: '""',
        width: 3,
        height: 'calc((100% - 25px)/2 - 4px)',
        backgroundColor: props.transportColor,
        position: 'absolute',
        top: 2
    },
    '&:after': {
        content: '""',
        width: 3,
        height: 'calc((100% - 25px)/2 - 4px)',
        backgroundColor: props.transportColor,
        position: 'absolute',
        bottom: 2
    }
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white
    }
}));

const ResetAnimation = keyframes(`
from { transform: rotate(0deg); }
to { transform: rotate(360deg); }
`);
