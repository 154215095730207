import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Tooltip
} from "@mui/material";
import { Close, ReportProblem } from "@mui/icons-material";
import { Grid, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { EditorState } from "draft-js";
import axios from "axios";
import clsx from "clsx";
import DOMPurify from "dompurify";
import RichEditor, { RichEditorStyleMap } from "../Menu/MaterialTripList/utils/editor/editor";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { renderRichEditorContentHTML } from "../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml";
import { restoreRichEditorStateFromHtml } from "../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml";
import { customColors } from "../Menu/MaterialTripList/utils/editor/customColors";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";

type Props = {
    open: boolean,
    id: number | null,
    type: keyof ReturnType<typeof useCartProducts> | 'package' | 'manual',
    text: string | null,
    noRefund: boolean,
    moved_with_recalc_cancel_condition_text: boolean,
    providerCode?: string,
    isCustom: boolean,
    onToggle: () => void,
    onDeleteCancellationRules?: () => void,
    onConfirmCancelConditionRecalc: () => void,
    onValidate: (noRefund: boolean, content: string) => void
}

const initialState = EditorState.createEmpty();

export function CartCancellationRules(props: Props): JSX.Element | null {
    const { t } = useTranslation();
    const classes = useStyles();
    const [inputState, setInputState] = useState(initialState);
    const [noRefund, setNoRefund] = useState(false);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onChangeInputState = (html: string) => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        const editorState = restoreRichEditorStateFromHtml(
            styles,
            html
        );

        setInputState(editorState);
    }

    const onNoRefundChange = async (noRefund: boolean) => {
        if (noRefund) {
            onChangeInputState(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();

            switch (props.type) {
                case 'transfers': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.isCustom ? 'custom-products' : 'transfers'}/${props.id}/get_cancellation_text_from_charges/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'pois': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.id}/get_cancellation_text_from_charges/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'assistances': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.providerCode === "gritchen" ? "insurance" : "assistance"}/${props.id}/get_cancellation_text_from_charges/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'cars': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.id}/get_cancellation_text_from_charges/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'flights': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${props.id}/get_cancellation_text_from_charges/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'package': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/get_cancellation_text/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
                case 'manual': {
                    const response = await axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.id}/get_cancellation_text/`
                    });
                    onChangeInputState(response.data.cancellation_text ?? '');
                    break;
                }
            }
        }
        setNoRefund(noRefund);
    };

    const onValidate = () => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };

        props.onValidate(
            noRefund,
            renderRichEditorContentHTML(
                styles,
                inputState.getCurrentContent()
            )
        );
    }

    useEffect(() => {
        onChangeInputState(props.text ?? '');
    }, [props.text]);

    useEffect(() => {
        setNoRefund(props.noRefund);
    }, [props.noRefund]);

    if (!props.text) {
        return null;
    }

    if (props.open) {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth variant={'outlined'}>
                            <Select id={'no-refund'} value={noRefund} onChange={(event) => onNoRefundChange(event.target.value === 'true')}>
                                <MenuItem value={'false'}>{t('cart-material.cancellable-with-conditions')}</MenuItem>
                                <MenuItem value={'true'}>{t('cart-material.not-cancellable')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <RichEditor
                            values={{}}
                            pictures={null}
                            editorState={inputState}
                            setEditorState={setInputState}
                            controlledState
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <Button onClick={props.onToggle}>{t('global.cancel')}</Button>
                            <Button onClick={onValidate}>{t('global.validate')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                    <Grid item>
                        <Grid container spacing={2} alignItems={'center'}>
                            {
                                //!['verdie'].includes(quotation_code) &&
                                <>
                                    <Grid item>
                                        <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={props.onToggle}>
                                            {t('global.edit')}
                                        </Button>
                                    </Grid>
                                    {
                                        props.onDeleteCancellationRules &&
                                        <Grid item>
                                            <Close className={classes.deleteCustomInformation} onClick={props.onDeleteCancellationRules} />
                                        </Grid>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        {
                            props.moved_with_recalc_cancel_condition_text &&
                            <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                <div
                                    className={clsx(classes.traverlerModification, classes.delete)}
                                    onClick={props.onConfirmCancelConditionRecalc}
                                    style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                >
                                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                        <Grid item>{t('cart-material.recalc')}</Grid>
                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                    </Grid>
                                </div>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            }>
                <AlertTitle>
                    {
                        !['verdie'].includes(quotationCode) ?
                            t('cart-material.manual-cancellation-rules') :
                            t('cart-material.provider-cancellation-rules')
                    } : {props.noRefund ? t('cart-material.not-cancellable') : t('cart-material.cancellable-with-conditions')}
                </AlertTitle>
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            props.text === t('cart-material.not-cancellable') ?
                                '' :
                                props.text
                        )
                    }}
                />
            </Alert>
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    transferName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    composition: {
        float: 'left',
        marginRight: 4,
        cursor: 'pointer'
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        color: '#E6592F',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    bookingContainer: {
        paddingLeft: 15
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    unavailable: {
        color: '#f44336'
    },
    priceUnavailable: {
        fontSize: 20
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    detailIcon: {
        float: 'left',
        marginRight: 4
    },
    helpIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    additionalInformation: {
        backgroundColor: '#2196f3',
        color: 'white'
    },
    additionalInformationExpandIcon: {
        color: 'white'
    },
    additionalInformationInfoIcon: {
        marginRight: 8
    },
    additionalInformationDetails: {
        display: 'block'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));
