//---- Dependencies ----//
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";

import Alert from "@material-ui/lab/Alert";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";

import {
    Checkbox,
    Stack
} from "@mui/material";

import MuiPhoneNumber from "material-ui-phone-number";

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';


import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import axios from "axios";
import CountrySearch from "../Menu/MaterialTripList/CountrySearch.js";
import TravelerInfo from "../Menu/MaterialTripList/TravelerInfo.js";
import TravelerMoreInfo from '../Menu/MaterialTripList/TravelerMoreInfo';
import TravelerMoreInfoMobile from '../Menu/MaterialTripList/TravelerMoreInfoMobile';
import { RoomTypePicker } from './RoomTypePicker';
import i18n from '../../i18n.jsx';
import { CrmTraveler } from './CrmTraveler';
import TravelExchangeOptions from './TravelExchangeOptions';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    },
    formControlRoot1: {
        "width": "100%",
        "&& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 10px'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "#0000008A"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: "bold",
            color: "#0000008A",
            fontSize: 12,
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: "1 !important"
        }
    },
    formControlRootMobile1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        }
    },
    paddingPassenger: {
        paddingLeft: 16,
        paddingRight: 32
    },
    flexGrow: {
        WebkitBoxFlex: "1 !important",
        flexGrow: "1 !important"
    },
    titleStepper: {
        fontSize: "16px !important",
        lineHeight: "20px !important",
        fontWeight: "600 !important"
    },
    labelStepper: {
        fontSize: "14px !important",
        lineHeight: "18px !important",
        fontWeight: "400 !important",
        color: "rgb(113, 113, 113) !important"
    },
    itemPassenger: {
        WebkitBoxPack: "justify !important",
        WebkitBoxAlign: "center !important",
        color: "rgb(34, 34, 34) !important",
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        paddingRight: "4px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important"
        //minWidth: "330px !important"
    },
    stepperPassenger: {
        WebkitBoxPack: "justify !important",
        WebkitBoxAlign: "center !important",
        display: "inline-flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important",
        width: "104px !important",
        height: "32px !important",
        color: "rgb(34, 34, 34) !important",
        fontWeight: "400 !important",
        lineHeight: "20px !important"
    },
    iconStepper: {
        cursor: "pointer",
        fontSize: 29
    },
    customSelect: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: "16px 14px"
        },
        "fontSize": 14
    },
    orangeText: {
        color: "#E6592F"
    },
    listItem: {
        padding: '0 16px'
    },
    listItemLabel: {
        marginBottom: 0
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "0px 20px"
    },
    genericMargin: {
        margin: "20px 0px"
    },
    noMarginBottom: {
        marginBottom: 0
    },
    circleBorder: {
        borderRadius: 8
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize18: {
        fontSize: 18
    },
    fontWeight900: {
        fontWeight: 900
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    upperCase: {
        textTransform: "uppercase"
    },
    textRight: {
        textAlign: "right"
    },
    spacer: {
        padding: "8px 0px"
    }
}));

const MaterialPassengerInfo = ({
    setChildren,
    setAdults,
    setTravelers,
    travelers,
    adults,
    children,
    addTravelers,
    groups,
    setGroups,
    end_date,
    removeTravelers,
    update,
    setUpdate,
    withApplicant,
    setWithApplicant,
    tempContactLead,
    setTempContactLead,
    tempTravelers,
    setTempTravelers,
    moreInfo,
    setMoreInfo,
    infoIncomplete,
    groupsError,
    versionToShow,
    variantToShow,
    singleRoom,
    setSingleRoom,
    twinRoom,
    setTwinRoom,
    twinShare,
    setTwinShare,
    tripleRoom,
    setTripleRoom,
    quadRoom,
    setQuadRoom,
    travelExchangePrices,
    selectedTravelExchangePrices,
    setTravelExchangePrices,
    mealPlan,
    setMealPlan,
    startDate,
    adalteRooms,
    setAdalteRooms,
    adalteErrors,
    setAdalteErrors,
    adalteIncludeAge,
    pickups,
    setPickups,
    adalteLanguageCode,
    setAdalteLanguageCode,
                                   setPriceOptions,
                                   setCurrencyIso,
                                   setMealPlanChoise,
                                   setSelectedTravelExchangePrices
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const has_crm = JSON.parse(localStorage.getItem('config') ?? '{}').has_crm;
    const is_staging = document.location.hostname.split('.')[2] === 'fr' && document.location.hostname !== 'test-aws.facilitatrip.fr';
    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const user = useSelector(store => store.user.user);
    const price_data = useSelector(store => store.itinerary_type.price_data);
    const addressLabel = useRef(null);
    const addressLabelWidth = addressLabel.current ? addressLabel.current.clientWidth : 45;
    const addressDetailsLabel = useRef(null);
    const addressDetailsLabelWidth = addressDetailsLabel.current ? addressDetailsLabel.current.clientWidth : 126;
    const postalCodeLabel = useRef(null);
    const postalCodeLabelWidth = postalCodeLabel.current ? postalCodeLabel.current.clientWidth : 66;
    const cityLabel = useRef(null);
    const cityLabelWidth = cityLabel.current ? cityLabel.current.clientWidth : 24;
    const emailLabel = useRef(null);
    const emailLabelWidth = emailLabel.current ? emailLabel.current.clientWidth : 42;

    const [leadMoreInfo, setLeadMoreInfo] = useState(false);
    const [maxPax, setMaxPax] = useState(null);
    const [maxAdultPax, setMaxAdultPax] = useState(null);
    const [maxChildPax, setMaxChildPax] = useState(null);
    const [totalPax, setTotalPax] = useState(tempTravelers.length);

    let travelers_without_group = [];
    let travelers_with_group = [];
    tempTravelers.map((traveler) => {
        let has_group = false;
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].travelers_list.length; j++) {
                if (groups[i].travelers_list[j].id === traveler.id) {
                    has_group = true;
                    travelers_with_group.push(traveler);
                    break;
                }
            }
        }
        if (!has_group) {
            travelers_without_group.push(traveler);
        }
    });
    useEffect(() => {
        if (price_data !== undefined && price_data.length !== 0) {
            let max_pax = 0;
            let max_adult_pax = 0;
            let max_child_pax = 0;
            price_data.map((data) => {
                if (data.variant === variantToShow) {
                    if (data.terrestrial_price !== undefined) {
                        data.terrestrial_price.map((terrestrial_price) => {
                            if (terrestrial_price.model && (terrestrial_price.model.variable.includes('NUMBER_OF_PERSONS') || terrestrial_price.model.variable.includes('NUMBER_OF_PERSONS_PRICE'))) {
                                if (terrestrial_price.values.length === 1) {
                                    if (terrestrial_price.values[0].value[1] > max_pax) {
                                        max_pax = terrestrial_price.values[0].value[1];
                                    }
                                } else {
                                    let age_model = terrestrial_price.values.find((value) => {
                                        return value.variable === 'AGE';
                                    });
                                    if (age_model !== undefined) {
                                        let is_adult = age_model.value[0] >= 18;
                                        terrestrial_price.values.map((value) => {
                                            if (value.variable === 'NUMBER_OF_PERSONS' || value.variable === 'NUMBER_OF_PERSONS_PRICE') {
                                                if (is_adult) {
                                                    if (value.value[1] > max_adult_pax) {
                                                        max_adult_pax = value.value[1];
                                                    }
                                                } else {
                                                    if (value.value[1] > max_child_pax) {
                                                        max_child_pax = value.value[1];
                                                    }
                                                }
                                            }
                                        });
                                    } else {
                                        terrestrial_price.values.map((value) => {
                                            if (value.variable === 'NUMBER_OF_PERSONS' || value.variable === 'NUMBER_OF_PERSONS_PRICE') {
                                                if (value.value[1] > max_pax) {
                                                    max_pax = value.value[1];
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                            // if (terrestrial_price.travel_exchange_prices !== undefined && terrestrial_price.travel_exchange_prices !== null && terrestrial_price.travel_exchange_prices.length !== 0) {
                            //     let price = terrestrial_price.travel_exchange_prices[0];
                            //     let meal_plan_choice = [];
                            //     let default_meal = '';
                            //     if (price.standard_board !== null) {
                            //         switch (price.standard_board) {
                            //             case 'half_board':
                            //                 default_meal = t('accommodation_filters.half_board');
                            //                 break;
                            //             case 'full_board':
                            //                 default_meal = t('accommodation_filters.all_inclusive');
                            //                 break;
                            //             case 'b_and_b':
                            //                 default_meal = t('accommodation_filters.breakfast_included');
                            //                 break;
                            //             case 'inclusive_board':
                            //                 default_meal = t('accommodation_filters.inclusive_board');
                            //                 break;
                            //         }
                            //         meal_plan_choice.push({ type: default_meal, price: 0 });
                            //         if (price.full_board_price !== null) {
                            //             meal_plan_choice.push({ type: t('accommodation_filters.all_inclusive'), price: price.full_board_price });
                            //         }
                            //         if (price.bed_and_breakfast_price !== null) {
                            //             meal_plan_choice.push({ type: t('accommodation_filters.breakfast_included'), price: price.bed_and_breakfast_price });
                            //         }
                            //         if (price.inclusive_board_price !== null) {
                            //             meal_plan_choice.push({ type: t('accommodation_filters.inclusive_board'), price: price.inclusive_board_price });
                            //         }
                            //         if (price.half_board_price !== null) {
                            //             meal_plan_choice.push({ type: t('accommodation_filters.half_board'), price: price.half_board_price });
                            //         }
                            //
                            //         setMealPlanChoise(meal_plan_choice);
                            //         setMealPlan(default_meal);
                            //     }
                            //     setTravelExchangePrices(price);
                            // }
                        });
                    }
                }
            });
            if (max_pax !== 0) {
                setMaxPax(max_pax);
            }
            if (max_adult_pax !== 0) {
                setMaxAdultPax(max_adult_pax);
            }
            if (max_child_pax !== 0) {
                setMaxChildPax(max_child_pax);
            }
        }
    }, [price_data]);
    useEffect(() => {
        getCompo();
    }, []);
    useEffect(() => {
        if (update) {
            getCompo();
        }
    }, [update]);
    useEffect(() => {
        setTotalPax(tempTravelers.length);
    }, [tempTravelers]);
    useEffect(() => {
        if (itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom) {
            setSingleRoom(0);
            setTwinRoom(0);
            setTwinShare(0);
            setTripleRoom(0);
            setQuadRoom(0);
        }
    }, [totalPax]);
    // useEffect(() => {
    //     if (itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom) {
    //         let groups_cpy = [];
    //         for (let i = 0; i < singleRoom; i++) {
    //             groups_cpy.push({
    //                 travelers: [],
    //                 travelers_list: []
    //             });
    //         }
    //         for (let i = 0; i < twinRoom; i++) {
    //             groups_cpy.push({
    //                 travelers: [],
    //                 travelers_list: []
    //             });
    //         }
    //         for (let i = 0; i < twinShare; i++) {
    //             groups_cpy.push({
    //                 travelers: [],
    //                 travelers_list: []
    //             });
    //         }
    //         for (let i = 0; i < tripleRoom; i++) {
    //             groups_cpy.push({
    //                 travelers: [],
    //                 travelers_list: []
    //             });
    //         }
    //         for (let i = 0; i < quadRoom; i++) {
    //             groups_cpy.push({
    //                 travelers: [],
    //                 travelers_list: []
    //             });
    //         }
    //         setGroups(groups_cpy);
    //     }
    // }, [singleRoom, twinRoom, twinShare, tripleRoom, quadRoom]);
    const handleWithApplicant = () => {
        let temp_new_lead = Object.assign({}, tempContactLead);
        temp_new_lead.id = 0;
        temp_new_lead.first_name = "";
        temp_new_lead.last_name = "";
        setTempContactLead(temp_new_lead);
        setWithApplicant(!withApplicant);

        let temp_travelers = [];
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            let temp_traveler = Object.assign({}, tempTravelers[traveler_index]);
            temp_traveler.temp_lead = false;
            temp_travelers.push(temp_traveler);
        }
        setTempTravelers(temp_travelers);
    };
    const getCompo = () => {
        let tmp_adults = [];
        let tmp_child = [];
        let new_travelers = [];
        tempTravelers.map((traveler) => {
            let tmp_traveler = Object.assign({}, traveler);
            tmp_traveler.room = groups.findIndex((el) => {
                return el.travelers.includes(traveler.id);
            });
            if (tmp_traveler.room === -1) {
                tmp_traveler.room = null;
            }
            if (moment.utc(end_date).diff(moment.utc(tmp_traveler.birth_date), "y") >= 18) {
                tmp_adults.push(tmp_traveler);
            } else {
                tmp_child.push(tmp_traveler);
            }
            new_travelers.push(tmp_traveler);
        });
        setAdults(tmp_adults);
        setChildren(tmp_child);
        setTempTravelers(new_travelers);
        setUpdate(false);
    };
    const updateChildrenAge = (id, age) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                birth_date: moment.utc().subtract(age, "y").format("YYYY-MM-DD")
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/travelers/${id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                console.log(response.data);
                let new_travelers = [];
                for (let i = 0; i < tempTravelers.length; i++) {
                    if (tempTravelers[i].id !== id) {
                        new_travelers.push(tempTravelers[i]);
                    } else {
                        new_travelers.push(response.data);
                    }
                }
                const groups_cpy = [...groups];
                for (let i = 0; i < groups_cpy.length; i++) {
                    for (let j = 0; j < groups_cpy[i].travelers_list.length; j++) {
                        if (groups_cpy[i].travelers_list[j].id === id) {
                            groups_cpy[i].travelers_list[j] = response.data;
                        }
                    }
                }
                setGroups(groups_cpy);
                setTempTravelers(new_travelers);
                setUpdate(true);
            }).catch(function (error) {
                //TODO: show snackbar error
            });
        }
    };
    const handleLeadAddress = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.address = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadAddressDetails = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.address_details = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadPostalCode = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.postal_code = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadCity = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.city = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handlePhoneNumber = ((value, country_data, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.temp_phone = value;
        //country_data has the country code
        temp_traveler.phone_country_code = "+" + country_data.dialCode;
        temp_traveler.phone_number = value.replace(`+${country_data.dialCode}`, "");
        if (traveler.id === tempContactLead.id) {
            setTempContactLead(temp_traveler);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let email = tempTravelers[traveler_index].email;
                let newTravelers = tempTravelers.slice();
                temp_traveler.email = email;
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    });

    const handleEmail = (e, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.email = e.target.value;
        if (traveler.id === tempContactLead.id) {
            setTempContactLead(temp_traveler);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    };
    const changeApplicant = (traveler, traveler_index) => {
        let temp_travelers = tempTravelers.slice();
        //change id of temp Contact lead
        for (parse_traveler_index = 0; parse_traveler_index < temp_travelers.length; parse_traveler_index++) {
            if (temp_travelers[parse_traveler_index].id === tempContactLead.id && temp_travelers[parse_traveler_index].temp_lead === true) {
                let contact_traveler = Object.assign({}, temp_travelers[parse_traveler_index]);
                contact_traveler.temp_lead = false;
                temp_travelers[parse_traveler_index] = contact_traveler;
            }
        }
        let new_traveler = Object.assign({}, traveler);

        new_traveler.temp_lead = true;

        setTempContactLead(new_traveler);
        temp_travelers[traveler_index] = new_traveler;
        setTempTravelers(temp_travelers);
    };
    let empty_room = false;
    groups.map((group) => {
        if (group.travelers.length === 0) {
            empty_room = true;
        }
    });
    const singleRoomOptions = () => {
        let options = [];
        let totalAdults = (totalPax - children.length);
        let pax_room = Math.floor(totalAdults - (twinRoom * (2 - children.length)) - (twinShare * (2 - children.length)) - (tripleRoom * (3 - children.length)) - (quadRoom * (4 - children.length))) + (children.length === 0 ? 1 : 0);
        let singleRoomsAvailable = children.length !== 0 && (twinRoom > 0 || twinShare > 0 || tripleRoom > 0 || quadRoom > 0) ? (pax_room + 1) : Math.max(pax_room, 0);
        for (let i = 0; i < singleRoomsAvailable; i++) {
            options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return options;
    };
    const twinRoomOptions = () => {
        let options = [];
        let pax_room = Math.floor((totalPax - singleRoom - (twinShare * 2) - (tripleRoom * 3) - (quadRoom * 4)) / 2);
        let twinRoomsAvailable = Math.max(pax_room, 0);
        for (let i = 0; i <= twinRoomsAvailable; i++) {
            options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return options;
    };
    const twinShareOptions = () => {
        let options = [];
        let pax_room = Math.floor((totalPax - singleRoom - (twinRoom * 2) - (tripleRoom * 3) - (quadRoom * 4)) / 2);
        let twinShareAvailable = Math.max(pax_room, 0);
        for (let i = 0; i <= twinShareAvailable; i++) {
            options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return options;
    };
    const tripleRoomOptions = () => {
        let options = [];
        let pax_room = Math.floor((totalPax - singleRoom - (twinRoom * 2) - (twinShare * 2) - (quadRoom * 4)) / 3);
        let tripleRoomAvailable = Math.max(pax_room, 0);
        for (let i = 0; i <= tripleRoomAvailable; i++) {
            options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return options;
    };
    const quadRoomOptions = () => {
        let options = [];
        let pax_room = Math.floor((totalPax - singleRoom - (twinRoom * 2) - (twinShare * 2) - (tripleRoom * 3)) / 4);
        let quadRoomAvailable = Math.max(pax_room, 0);
        for (let i = 0; i <= quadRoomAvailable; i++) {
            options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return options;
    };
    const renderChildAge = () => {
        let options = [];
        if (itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && selectedTravelExchangePrices !== undefined && selectedTravelExchangePrices !== null && selectedTravelExchangePrices.child_age !== null && selectedTravelExchangePrices.child_age.length !== 0) {
            for (let i = 0; i <= selectedTravelExchangePrices.child_age; i++) {
                options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
            }
        } else {
            for (let i = 0; i <= 17; i++) {
                options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
            }
        }
        return options;
    };
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <Typography variant={"h6"} className={classes.genericText}> {t("global.traveler_number")} </Typography>
            </Grid>
            {
                maxPax !== null &&
                <Grid item>
                    <Typography className={classes.genericText}>{t('itineraryType.max_pax', { max: maxPax })}</Typography>
                </Grid>
            }
            {
                maxAdultPax !== null &&
                <Grid item>
                    <Typography className={classes.genericText}>{t('itineraryType.max_adult_pax', { max: maxAdultPax })}</Typography>
                </Grid>
            }
            {
                maxChildPax !== null &&
                <Grid item>
                    <Typography className={classes.genericText}>{t('itineraryType.max_child_pax', { max: maxChildPax })}</Typography>
                </Grid>
            }
            <Grid item style={{ width: "100%" }}>
                <List className={classes.paddingPassenger}>
                    <ListItem className={classes.itemPassenger}>
                        <div className={classes.flexGrow}>
                            <div className={classes.titleStepper}>{t("flight_search.adults")}</div>
                            <div className={classes.labelStepper}>{t("itineraryType.adult_age", { age: itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && selectedTravelExchangePrices !== undefined && selectedTravelExchangePrices !== null && selectedTravelExchangePrices.child_age !== null ? selectedTravelExchangePrices.child_age + 1 : 18 })}</div>
                        </div>
                        <div className={classes.stepperPassenger}>
                            <IconButton className={classes.customIconButton} disabled={adults.length === 0} disableRipple={true} onClick={() => {
                                removeTravelers(adults[adults.length - 1].id);
                            }}>
                                <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                            </IconButton>
                            <div>{adults.length}</div>
                            <IconButton className={classes.customIconButton} disableRipple={true} onClick={() => {
                                addTravelers("ADULT", 1);
                            }} disabled={maxPax !== null ? adults.length + children.length === maxPax : (maxAdultPax !== null ? adults.length === maxAdultPax : false)}>
                                <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                            </IconButton>
                        </div>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.itemPassenger}>
                        <div className={classes.flexGrow}>
                            <div className={classes.titleStepper}>{t("flight_search.children")}</div>
                            <div className={classes.labelStepper}>{t("itineraryType.child_age", { age: itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && selectedTravelExchangePrices !== undefined && selectedTravelExchangePrices !== null && selectedTravelExchangePrices.child_age !== null ? selectedTravelExchangePrices.child_age : 17 })} {itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && selectedTravelExchangePrices !== undefined && selectedTravelExchangePrices !== null && selectedTravelExchangePrices.child_discount !== null ? `(-${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: itinerary_type_details_data?.trip.currency.iso_code }).format(parseFloat(selectedTravelExchangePrices.child_discount))})` : ''}</div>
                        </div>
                        <div className={classes.stepperPassenger}>
                            <IconButton disabled={children.length === 0} className={classes.customIconButton} onClick={() => {
                                removeTravelers(children[children.length - 1].id);
                            }}>
                                <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                            </IconButton>
                            <div>{children.length}</div>
                            <IconButton onClick={() => {
                                addTravelers("CHILDREN", 1);
                            }} className={classes.customIconButton} disabled={maxPax !== null ? adults.length + children.length === maxPax : (maxChildPax !== null ? children.length === maxChildPax : false)}>
                                <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                            </IconButton>
                        </div>
                    </ListItem>
                </List>
                <Grid container className={classes.paddingPassenger} spacing={2}>
                    {
                        children.map((child, child_index) => {
                            let age = moment.utc(end_date).diff(moment.utc(child.birth_date), "y");
                            return (
                                <Grid item xs={6} key={child_index}>
                                    <FormControl variant={"outlined"} fullWidth>
                                        <InputLabel id={`label-input-age-${child_index}`}>{`${t("header.child_age")}${child_index + 1}`}</InputLabel>
                                        <Select
                                            labelId={`label-input-age-${child_index}`}
                                            id={`input-age-${child_index}`}
                                            value={age}
                                            //onChange={handleChange}
                                            MenuProps={{ disablePortal: true }}
                                            label={`${t("header.child_age")} ${child_index + 1}`}
                                            className={classes.formControlRoot1}
                                            onChange={(e) => {
                                                updateChildrenAge(child.id, e.target.value);
                                            }}
                                        >
                                            {renderChildAge()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            );
                        })
                    }
                </Grid>
                <br />
                <Typography variant={"h6"} className={classes.genericText}> {t("home.modal.room_number")} </Typography>
                <List className={classes.paddingPassenger}>
                    {
                        // (itinerary_type_details_data?.is_custom === undefined || (itinerary_type_details_data?.is_custom && itinerary_type_details_data.contracts.find(contract => contract.provider?.provider?.code === 'TRAVEL_EXCHANGE') === undefined)) && 
                        <ListItem className={classes.itemPassenger}>
                            <div className={classes.flexGrow}>
                                <div className={classes.titleStepper}>{t("global.rooms")}</div>
                            </div>
                            <div className={classes.stepperPassenger}>
                                <IconButton disabled={groups.length <= 1} className={classes.customIconButton} onClick={() => {
                                    if (groups.length > 1) {
                                        let groups_cpy = [];
                                        for (let i = 0; i < groups.length - 1; i++) {
                                            groups_cpy.push({
                                                travelers: [],
                                                travelers_list: [],
                                                twin_room: false
                                            });
                                            setGroups(groups_cpy);
                                        }
                                    }
                                }}>
                                    <RemoveCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                </IconButton>
                                <div>{groups.length}</div>
                                <IconButton disabled={groups.length >= tempTravelers.length} className={classes.customIconButton} onClick={() => {
                                    if (groups.length < tempTravelers.length) {
                                        let groups_cpy = groups.slice();
                                        groups_cpy.push({
                                            travelers: [],
                                            travelers_list: [],
                                            twin_room: false
                                        });
                                        setGroups(groups_cpy);
                                    }
                                }}>
                                    <AddCircleOutlineOutlinedIcon className={classes.iconStepper} />
                                </IconButton>
                            </div>
                        </ListItem>
                    }
                    {
                        // itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && travelExchangePrices !== undefined && travelExchangePrices !== null &&
                        // <>
                        // {
                        //     travelExchangePrices.single_room_price !== null && ((totalPax - children.length) - (twinRoom * (2 - children.length)) - (twinShare * (2 - children.length)) - (tripleRoom * (3 - children.length)) - (quadRoom * (4 - children.length)) >= 1 || singleRoom > 0) &&
                        //     <ListItem className={classes.itemPassenger}>
                        //         <div className={classes.flexGrow}>
                        //             <div className={classes.titleStepper}>{t("shared.room-single")}</div>
                        //             <div className={classes.labelStepper}>{`(+${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: travelExchangePrices.currency }).format(parseFloat(travelExchangePrices.single_room_price))})`}</div>
                        //         </div>
                        //         <div className={classes.stepperPassenger}>
                        //             <FormControl variant={"outlined"} fullWidth>
                        //                 <Select
                        //                     id={`single-room`}
                        //                     value={singleRoom}
                        //                     //onChange={handleChange}
                        //                     MenuProps={{ disablePortal: true }}
                        //                     className={ classes.formControlRoot1 }
                        //                     onChange={(e) => {
                        //                         setSingleRoom(e.target.value)
                        //                     }}
                        //                 >
                        //                 {singleRoomOptions()}
                        //                 </Select>
                        //             </FormControl>
                        //         </div>
                        //     </ListItem>
                        // }
                        // {
                        //     travelExchangePrices.twin_room && totalPax - singleRoom - (twinShare * 2) - (tripleRoom * 3) - (quadRoom * 4) >= 2 &&
                        //     <ListItem className={classes.itemPassenger}>
                        //         <div className={classes.flexGrow}>
                        //             <div className={classes.titleStepper}>{t("summary.twin_room")}</div>
                        //         </div>
                        //         <div className={classes.stepperPassenger}>
                        //             <FormControl variant={"outlined"} fullWidth>
                        //                 <Select
                        //                     id={`twin-room`}
                        //                     value={twinRoom}
                        //                     //onChange={handleChange}
                        //                     MenuProps={{ disablePortal: true }}
                        //                     className={ classes.formControlRoot1 }
                        //                     onChange={(e) => {
                        //                         setTwinRoom(e.target.value)
                        //                     }}
                        //                 >
                        //                 {twinRoomOptions()}
                        //                 </Select>
                        //             </FormControl>
                        //         </div>
                        //     </ListItem>
                        // }
                        // {
                        //     travelExchangePrices.twin_share && totalPax - singleRoom - (twinRoom * 2) - (tripleRoom * 3) - (quadRoom * 4) >= 2 &&
                        //     <ListItem className={classes.itemPassenger}>
                        //         <div className={classes.flexGrow}>
                        //             <div className={classes.titleStepper}>{t("shared.room-double")}</div>
                        //         </div>
                        //         <div className={classes.stepperPassenger}>
                        //             <FormControl variant={"outlined"} fullWidth>
                        //                 <Select
                        //                     id={`double-room`}
                        //                     value={twinShare}
                        //                     //onChange={handleChange}
                        //                     MenuProps={{ disablePortal: true }}
                        //                     className={ classes.formControlRoot1 }
                        //                     onChange={(e) => {
                        //                         setTwinShare(e.target.value)
                        //                     }}
                        //                 >
                        //                 {twinShareOptions()}
                        //                 </Select>
                        //             </FormControl>
                        //         </div>
                        //     </ListItem>
                        // }
                        // {
                        //     travelExchangePrices.triple_room_price !== null && totalPax - singleRoom - (twinRoom * 2) - (twinShare * 2) - (quadRoom * 4) >= 3 &&
                        //     <ListItem className={classes.itemPassenger}>
                        //         <div className={classes.flexGrow}>
                        //             <div className={classes.titleStepper}>{t("shared.room-triple")}</div>
                        //             <div className={classes.labelStepper}>{`(+${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: travelExchangePrices.currency }).format(parseFloat(travelExchangePrices.triple_room_price) * 3)})`}</div>
                        //         </div>
                        //         <div className={classes.stepperPassenger}>
                        //             <FormControl variant={"outlined"} fullWidth>
                        //                 <Select
                        //                     id={`triple-room`}
                        //                     value={tripleRoom}
                        //                     //onChange={handleChange}
                        //                     MenuProps={{ disablePortal: true }}
                        //                     className={ classes.formControlRoot1 }
                        //                     onChange={(e) => {
                        //                         setTripleRoom(e.target.value)
                        //                     }}
                        //                 >
                        //                 {tripleRoomOptions()}
                        //                 </Select>
                        //             </FormControl>
                        //         </div>
                        //     </ListItem>
                        // }
                        // {
                        //     travelExchangePrices.quad_room_price !== null && totalPax - singleRoom - (twinRoom * 2) - (twinShare * 2) - (tripleRoom * 3) >= 4 &&
                        //     <ListItem className={classes.itemPassenger}>
                        //         <div className={classes.flexGrow}>
                        //             <div className={classes.titleStepper}>{t("shared.room-quad")}</div>
                        //             <div className={classes.labelStepper}>{`(+${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: travelExchangePrices.currency }).format(parseFloat(travelExchangePrices.quad_room_price) * 4)})`}</div>
                        //         </div>
                        //         <div className={classes.stepperPassenger}>
                        //             <FormControl variant={"outlined"} fullWidth>
                        //                 <Select
                        //                     id={`quad-room`}
                        //                     value={quadRoom}
                        //                     //onChange={handleChange}
                        //                     MenuProps={{ disablePortal: true }}
                        //                     className={ classes.formControlRoot1 }
                        //                     onChange={(e) => {
                        //                         setQuadRoom(e.target.value)
                        //                     }}
                        //                 >
                        //                 {quadRoomOptions()}
                        //                 </Select>
                        //             </FormControl>
                        //         </div>
                        //     </ListItem>
                        // }
                        // </>
                    }
                </List>

                <Typography variant={"h6"} className={classes.genericText}> {t("home.modal.room_allocation")} </Typography>
                {
                    empty_room && (
                        <Alert className={classes.warningAlert} severity={"error"} variant={"filled"} style={{ marginBottom: 10 }}>{t("header.no_empty_room")}</Alert>
                    )
                }
                {
                    travelers_without_group.length > 0 && (
                        <Fragment>
                            <Alert className={classes.warningAlert} style={{ marginBottom: 10 }} severity={travelers_without_group.length === adults.length + children.length ? "error" : "warning"} variant={"filled"}>{t("booking.prices_changed_1") + " " + travelers_without_group.length + " " + t("header.unassigned travelers") + " : "}</Alert>
                            {/*<Typography className={classes.sectionTitle}> </Typography> */}
                            {
                                travelers_without_group.map((traveler, index_traveler) => {
                                    return (
                                        <Typography className={`${classes.orangeText}`} key={index_traveler}> {traveler.default_name ? traveler.age_label : traveler.first_name + " " + traveler.last_name}</Typography>
                                    );
                                })
                            }
                            <br />
                        </Fragment>
                    )
                }
                <Grid container spacing={4}>
                    {
                        groups.map((group, index_group) => {
                            return (
                                <Grid key={index_group} item>
                                    <Typography className={classes.bold}>
                                        <>{t("accommodation.room")} {index_group + 1} :</>
                                    </Typography>
                                    {
                                        itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && itinerary_type_details_data?.is_custom !== undefined && !itinerary_type_details_data?.is_custom && setTravelExchangePrices !== undefined && setTravelExchangePrices !== null && group.travelers.length === 2 && setTravelExchangePrices.twin_room && setTravelExchangePrices.twin_share &&
                                        <>
                                            <List>
                                                <ListItem className={ classes.listItem}>
                                                    <FormControlLabel className={ classes.listItemLabel } control={ <Checkbox sx={{
                                                        'color': `${main_color}`,
                                                        '&.Mui-checked': {
                                                            color: `${main_color}`
                                                        }
                                                    }} checked={group.twin_room}/>} label={t("summary.twin_room")} onChange={(e) => {
                                                        let groups_cpy = [...groups];
                                                        groups_cpy[index_group].twin_room = e.target.checked;
                                                        setGroups(groups_cpy);
                                                        setUpdate(true);
                                                    }} />
                                                </ListItem>
                                            </List>
                                            <Divider />
                                        </>
                                    }
                                    <List>
                                        {
                                            tempTravelers.map((traveler, index_traveler) => {
                                                return (
                                                    <ListItem key={ index_traveler } className={ classes.listItem }>
                                                        <FormControlLabel className={ classes.listItemLabel } control={ <Checkbox sx={{
                                                            'color': `${main_color}`,
                                                            '&.Mui-checked': {
                                                                color: `${main_color}`
                                                            }
                                                        }} checked={ group.travelers.includes(traveler.id) } onChange={(e) => {
                                                            let groups_cpy = groups.slice();
                                                            if (e.target.checked) {
                                                                groups_cpy.map((group_check, group_check_index) => {
                                                                    const index = group_check.travelers.indexOf(traveler.id);
                                                                    if (index > -1) {
                                                                        groups_cpy[group_check_index] = Object.assign({}, groups[group_check_index]);
                                                                        groups_cpy[group_check_index].travelers = groups_cpy[group_check_index].travelers.slice();
                                                                        groups_cpy[group_check_index].travelers.splice(index, 1);
                                                                        groups_cpy[group_check_index].travelers_list = groups_cpy[group_check_index].travelers_list.slice();
                                                                        groups_cpy[group_check_index].travelers_list.splice(index, 1);
                                                                    }
                                                                });
                                                                groups_cpy[index_group] = Object.assign({}, groups[index_group]);
                                                                groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                                groups_cpy[index_group].travelers.push(traveler.id);
                                                                groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                                groups_cpy[index_group].travelers_list.push(traveler);
                                                            } else {
                                                                const index = groups_cpy[index_group].travelers.indexOf(traveler.id);
                                                                if (index > -1) {
                                                                    groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                                    groups_cpy[index_group].travelers.splice(index, 1);
                                                                    groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                                    groups_cpy[index_group].travelers_list.splice(index, 1);
                                                                }
                                                            }
                                                            setGroups(groups_cpy);
                                                            setUpdate(true);
                                                        }} />} label={traveler.default_name ? `${traveler.age_label}` : `${traveler.first_name} ${traveler.last_name}`} />
                                                    </ListItem>
                                                );
                                            })
                                        }
                                    </List>
                                </Grid>
                            );
                        })
                    }
                </Grid>
                {
                    itinerary_type_details_data?.source === 'ADALTE' &&
                    <RoomTypePicker
                        circuitId={itinerary_type_details_data.id}
                        version={versionToShow}
                        startDate={startDate}
                        adultTravelers={adults}
                        childTravelers={children}
                        groups={groups}
                        pickups={pickups}
                        onChangePickups={setPickups}
                        adalteLanguageCode={adalteLanguageCode}
                        onChangeAdalteLanguageCode={setAdalteLanguageCode}
                        rooms={adalteRooms}
                        onChangeRooms={setAdalteRooms}
                        errors={adalteErrors}
                        onChangeErrors={setAdalteErrors}
                        includeAge={adalteIncludeAge}
                    />
                }
                {
                    itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' &&
                    <TravelExchangeOptions
                        circuitId={itinerary_type_details_data.id}
                        version={versionToShow}
                        startDate={startDate}
                        adultTravelers={adults}
                        childTravelers={children}
                        groups={groups}
                        setMealPlanChoise={setMealPlanChoise}
                        setMealPlan={setMealPlan}
                        setTravelExchangePrices={setTravelExchangePrices}
                        setSelectedTravelExchangePrices={setSelectedTravelExchangePrices}
                        setPriceOptions={setPriceOptions}
                        setCurrencyIso={setCurrencyIso}
                    />
                }
                <br />
                <Grid className={classes.genericBorder}>
                    <Grid container className={classes.genericPadding} alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography variant={"h6"} className={classes.genericText}> {t("menu.trip_list.applicant")} </Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={ <Checkbox sx={{
                                    'color': `${main_color}`,
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={withApplicant} onChange={() => handleWithApplicant()} /> }
                                label={ t("home.set_first_lead") }
                                classes={{ label: `${classes.genericText}` }}
                                className={`${classes.noMarginBottom} ${classes.genericText}`}
                            />
                        </Grid>
                    </Grid>
                    {
                        tempContactLead !== null && (
                            <Grid className={classes.genericPadding} style={!downDesktop ? {} : { marginBottom: 8 }}>
                                <Grid className={`${classes.spacer} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    {t("menu.trip_list.identity")}
                                </Grid>
                                {
                                    (!is_staging || !!has_crm) &&
                                        <CrmTraveler setTempContactLead={setTempContactLead} tempContactLead={tempContactLead} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} contactOnly={true}/>
                                }
                                <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={tempContactLead} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} redFormControl={false} nationality_option={false} size={"medium"} isFrom={"createTrip"} />
                                {
                                    leadMoreInfo && (
                                        <Grid className={`${classes.spacer} ${classes.separator} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`} style={{ marginTop: 8 }}>
                                            {t("menu.trip_list.living_place")}
                                        </Grid>
                                    )
                                }
                                {
                                    !downDesktop && leadMoreInfo && (
                                        <Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4} >
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} className={classes.textRight}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.postal_code")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="postal-code"
                                                            id="postal-code-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={postalCodeLabelWidth}
                                                            value={tempContactLead.postal_code}
                                                            onChange={handleLeadPostalCode}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.city")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="city"
                                                            id="city-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={cityLabelWidth}
                                                            value={tempContactLead.city}
                                                            onChange={handleLeadCity}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5} className={classes.textRight}>
                                                    <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false} size={"medium"} isFrom={"createTrip"} />
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} style={{ paddingBottom: 16 }} spacing={4}>
                                                <Grid item xs={3}>
                                                    <MuiPhoneNumber
                                                        defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb"}
                                                        value={tempContactLead.temp_phone}
                                                        className={classes.formControlRoot1}
                                                        onChange={(value, country_data) => {
                                                            handlePhoneNumber(value, country_data, tempContactLead);
                                                        }}
                                                        variant="outlined"
                                                        label={t("global.phone")}
                                                        InputProps={{ className: classes.circleBorder }}
                                                        disableAreaCodes />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="email-label-lead" ref={emailLabel} className={classes.fontSize16}>
                                                            {t("global.email")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="email"
                                                            id="email-input-lead"
                                                            className={classes.circleBorder}
                                                            labelWidth={emailLabelWidth}
                                                            value={tempContactLead.email}
                                                            onChange={((event) => {
                                                                handleEmail(event, tempContactLead);
                                                            })}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    downDesktop && leadMoreInfo && (
                                        <Grid>
                                            <Grid container alignItems="flex-start" justify="space-between" direction="column" className={classes.spacer} spacing={2} >
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={1}>
                                                    <Grid item xs={5}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.postal_code")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                name="postal-code"
                                                                id="postal-code-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={postalCodeLabelWidth}
                                                                value={tempContactLead.postal_code}
                                                                onChange={handleLeadPostalCode}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.city")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                name="city"
                                                                id="city-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={cityLabelWidth}
                                                                value={tempContactLead.city}
                                                                onChange={handleLeadCity}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false} />
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <MuiPhoneNumber
                                                    defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb"}
                                                    value={tempContactLead.temp_phone}
                                                    className={classes.formControlRootMobile1}
                                                    onChange={(value, country_data) => {
                                                        handlePhoneNumber(value, country_data, tempContactLead);
                                                    }}
                                                    variant="outlined"
                                                    label={t("global.phone")}
                                                    InputProps={{ className: classes.circleBorder }}
                                                    disableAreaCodes />
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                    <InputLabel id="email-label" ref={emailLabel} className={classes.fontSize16}>
                                                        {t("global.email")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        name="email"
                                                        id="email-input"
                                                        className={classes.circleBorder}
                                                        labelWidth={emailLabelWidth}
                                                        value={tempContactLead.email}
                                                        onChange={((event) => {
                                                            handleEmail(event, tempContactLead);
                                                        })}
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid className={classes.textRight} style={{ paddingBottom: 8 }}>
                                    {
                                        !leadMoreInfo && (
                                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={() => {
                                                setLeadMoreInfo(true);
                                            }}>
                                                {t("global.short_more_info")}
                                            </Button>
                                        )
                                    }
                                    {
                                        leadMoreInfo && (
                                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={() => {
                                                setLeadMoreInfo(false);
                                            }}>
                                                {t("global.short_less_info")}
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
                {
                    tempContactLead !== null && tempTravelers.map((traveler, traveler_index) => {
                        let is_applicant = (withApplicant === true) && (traveler.id === tempContactLead.id);
                        let open_more_info = moreInfo[traveler_index];
                        return (
                            <Grid key={traveler_index} className={`${classes.genericBorder} ${classes.genericMargin}`}>
                                <Grid className={classes.genericPadding} container alignItems="center" justify="space-between">
                                    <Grid item className={`${!downDesktop ? "" : classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16} ${classes.upperCase}`} style={!downDesktop ? { padding: "12px 0px" } : {}}>
                                        {t("trip.traveler") + " " + t("global.number") + (traveler_index + 1)}
                                    </Grid>
                                    {
                                        withApplicant && (
                                            <Grid item style={!downDesktop ? { paddingRight: 32 } : {}}>
                                                <FormControlLabel
                                                    control={ <Checkbox sx={{
                                                        'color': `${main_color}`,
                                                        '&.Mui-checked': {
                                                            color: `${main_color}`
                                                        }
                                                    }} checked={is_applicant} onChange={() => {
                                                        changeApplicant(traveler, traveler_index);
                                                    }} />
                                                    }
                                                    label={t("menu.trip_list.traveler_is_applicant")}
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}` }}
                                                    className={`${classes.noMarginBottom} ${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.titleBorder} style={{ marginBottom: 10 }} />
                                <Grid className={classes.genericPadding}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing} ${classes.upperCase}`}>
                                        {traveler.age_label}
                                    </Grid>
                                    <div className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`} style={{ marginBottom: 8 }}>
                                        {t("menu.trip_list.affected_room") + " "}
                                        <span className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing} ${classes.upperCase}`} style={!downDesktop ? { paddingLeft: 10 } : {}}>
                                            {traveler.room_label}
                                        </span>
                                    </div>
                                    {
                                        !is_applicant && (!is_staging || !!has_crm) &&
                                        <CrmTraveler setTempContactLead={setTempContactLead} tempContactLead={tempContactLead} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} contactOnly={false}/>
                                    }
                                    <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={traveler} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} redFormControl={infoIncomplete[traveler_index]} nationality_option={false} size={"medium"} isFrom={"createTrip"} />
                                    {
                                        downDesktop && (
                                            <TravelerMoreInfoMobile traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size={"medium"} isFrom={"createTrip"} />
                                        )
                                    }
                                    {
                                        !downDesktop && (
                                            <TravelerMoreInfo traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size={"medium"} isFrom={"createTrip"} />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
};
export default React.memo(MaterialPassengerInfo);
