import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Circuit } from "../objects/circuit";

async function get(circuitId: number, reduced = false): Promise<Circuit | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get(
            `${API_HREF}client/${window.id_owner}/circuits/${circuitId}/`,
            {
                headers,
                params: { reduced }
            }
        );
        return response.data;
    }

    return null;
}

export const getCircuit = memoizee(
    get,
    { promise: true, primitive: true }
);
