//Dependencies
import axios from "axios";
import CheckBeforeRequest from "../CheckBeforeRequest";
import GetCookie from "./GetCookie";
//Actions
import { SetDataTrip } from "../../../Actions/Trip";

let source = null; 

const getPrice = (user, prices, quotation_code, is_tva = true) => {
    if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
        if (is_tva) {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return parseFloat(prices[i].selling_price);
                }
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_surcom) {
                return parseFloat(prices[i].selling_price);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].master_price) {
                return parseFloat(prices[i].selling_price);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].owner === user.client) {
                return parseFloat(prices[i].selling_price);
            }
        }
    } else {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_tva) {
                return parseFloat(prices[i].selling_price);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].owner === user.client) {
                return parseFloat(prices[i].selling_price);
            }
        }
    }
    return 0;
};

export default (total_price, quotation_code, update_date, start_date, dispatch, status_contract, status_modification, has_manual_expected_deposit, manual_products, user, all_cart, data_trip) => {
    const { headers } = CheckBeforeRequest();
    let expected = 0;
    let deposit_percent = 100;
    let custom_expected = null;
    let diff = moment.utc(start_date).diff(moment(), "day");
    if (quotation_code === 'cercledesvoyages') {
        if (diff <= 35) {
            deposit_percent = 100;
        } else {
            deposit_percent = 30;
        }
    } else if (quotation_code === 'continentsinsolites') {
        if (diff <= 45) {
            deposit_percent = 100;
        } else {
            deposit_percent = 35;
        }
    } else if (quotation_code === 'tropicalementvotre') {
        if (diff <= 30) {
            deposit_percent = 100;
        } else {
            deposit_percent = 30;
        }
    } else if (quotation_code === 'americavoyages') {
        if (diff <= 45) {
            deposit_percent = 100;
        } else {
            deposit_percent = 50;
        }
    } else if (quotation_code === 'touristforum') {
        let has_accommodation_allot = false;
        let has_flight = false;
        let all_flight_allot = true;
        let total_paid = 0;
        if (data_trip?.payments_done?.length) {
            total_paid = data_trip.payments_done.reduce((acc, obj) => { return acc + parseFloat(obj.amount ?? '0'); }, 0);
        }
        all_cart.map((product) => {
            if (product.product_type === 6) {
                has_flight = true;
                if (product.flight_source !== "CUSTOM_FLIGHT") {
                    all_flight_allot = false;
                }
            } else if (product.product_type === 0 && product.is_custom) {
                has_accommodation_allot = true;
            }
        });
        if (diff <= 21) {
            if (data_trip?.payments_done?.length === 0) {
                deposit_percent = 50;
            } else {
                deposit_percent = 100;
            }
        } else if (diff <= 70) {
            if (data_trip?.payments_done?.length === 0) {
                deposit_percent = 50;
            } else {
                custom_expected = (total_price * (50 / 100)) - total_paid;
            }
        } else {
            if (data_trip?.payments_done?.length === 0) {
                if (has_accommodation_allot && (has_flight && all_flight_allot)) {
                    custom_expected = trip_version.travelers.length * 100;
                } else if ((has_flight && all_flight_allot)) {
                    let non_refundable_total = 0;
                    all_cart.map((product) => {
                        if (!product.is_optional && !product.is_hidden_for_traveler && product.is_displayed && !product.is_cancellable) {
                            non_refundable_total = getPrice(product.prices);
                        }
                    });
                    custom_expected = non_refundable_total > trip_version.travelers.length * 100 ? non_refundable_total : trip_version.travelers.length * 100;
                } else {
                    deposit_percent = 50;
                }
            } else {
                custom_expected = (total_price * (50 / 100)) - total_paid;
            }
        }
    }
    expected = total_price * (deposit_percent / 100);
    expected = custom_expected && custom_expected >= 0 ? custom_expected : expected ? expected : 0;

    let request = {
        expected_deposit: (status_contract !== "CONFIRMED" || status_modification !== "FIXED_PV") && !has_manual_expected_deposit ? parseFloat(expected) : undefined,
        total_cost: total_price.toFixed(2)
    };
    //if (update_date) {
    //request.status_contract_confirmed_date = moment.utc();
    //}
    console.log('manual_products:', manual_products);
    if (source !== null) {
        source.cancel('Operation canceled by the user.');
    }
    source = axios.CancelToken.source();
    axios({
        method: "PATCH",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
        data: request,
        cancelToken: source.token
    }).then(function (response) {
        //dispatch(SetDataTrip(response.data));
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/`
        }).then((products) => {
            console.log('products:', products);
            let new_products = [];
            let deleted_products = [];
            let old_products_price = 0;
            let new_products_price = 0;
            manual_products.map(manual_product => {
                let price = getPrice(user, manual_product.prices, quotation_code);
                old_products_price += price + (manual_product.taxes !== null ? getTaxesPrice(manual_product.taxes) : 0);
                if (products.data.find((manual_item) => manual_item.id === manual_product.id) === undefined) {
                    deleted_products.push(manual_product);
                }
            });
            products.data.forEach((item) => {
                let price = getPrice(user, item.prices, quotation_code);
                new_products_price += price + (item.taxes !== null ? getTaxesPrice(item.taxes) : 0);
                if (manual_products.find((manual_item) => manual_item.id === item.id) === undefined) {
                    new_products.push(item);
                }
            });
            if (new_products.filter(item => item.auto_product !== null).length > 0 || deleted_products.filter(item => item.auto_product !== null).length > 0 || new_products_price !== old_products_price) {
                dispatch({ type: 'CART_SET_NEW_MANUAL_PRODUCT_LIST', payload: new_products });
                dispatch({ type: 'CART_SET_DELETED_MANUAL_PRODUCT_LIST', payload: deleted_products });
                dispatch({ type: 'CART_SET_MANUAL_CART', payload: { manual_cart: products.data } });
                dispatch({ type: 'HEADER_AUTOMATIC_PRODUCT_UPDATE', payload: true });
            }
            //if (products.data.length !== manual_products.length) {
            //dispatch({type: 'CART_SET_NEW_MANUAL_PRODUCT_LIST', payload: products.data});
            //dispatch({type: 'HEADER_AUTOMATIC_PRODUCT_UPDATE', payload: true});
            //}
        }).catch((products_error) => {
            console.log('products_error:', products_error);
        });
        dispatch({
            type: "TRIP_SET_DATA_TRIP",
            payload: {
                data_trip: response.data
            }
        });
        dispatch({
            type: "MENU_SET_TRIP_INFO_VERSION",
            payload: {
                version: response.data
            }
        });
        if (update_date) {
            dispatch({
                type: 'CART_UPDATE_DATE',
                payload: false
            });
        }
    }).catch(function (error) {
        if (!axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log("data trip refresh error", error);
        }
    });
};
