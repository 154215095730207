import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Moment } from "moment-timezone";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useItineraryUpdate } from "../Itinerary/network/itineraryUpdate";
import { useShowError } from "../Utils/showError";
import { getItineraryDayDifference } from "../Menu/MaterialTripList/utils/getItineraryDifference";
import { itineraryToItineraryInput } from "../Itinerary/utils/itineraryToItineraryInput";
import { matchItineraryContentWithItinerary } from "./utils/matchItineraryContentWithItinerary";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { StepsDatesManager } from "../Itinerary/utils/stepsDatesManager";
import { Itinerary } from "../Itinerary/objects/itinerary";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { ItineraryInput } from "../Itinerary/objects/itineraryState";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    item: ReturnType<typeof matchItineraryContentWithItinerary>[number],
    steps: ItineraryContentStep[],
    index: number,
    isEditable: boolean
}

export function CartConstructionContentStepperByDayEditableDates(props: Props): JSX.Element {
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const [openEditModal, setOpenEditModal] = useState(false);
    const steps = useMemo(() => {
        return itinerary.filter((step) => {
            return step.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);

    const onOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setOpenEditModal(true);
    };

    if (!props.isEditable) {
        return (
            <StepsDates item={props.item} />
        );
    }

    return (
        <Box sx={{ display: 'inline-block', margin: 'auto' }}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                flexWrap="nowrap"
            >
                <Box>
                    <StepsDates item={props.item} />
                </Box>
                <IconButton
                    size="small"
                    sx={{ fontSize: 14 }}
                    onClick={onOpen}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            </Stack>
            <EditDatesModal
                open={openEditModal}
                steps={
                    props.item.steps.filter((step) => {
                        const firstDay = steps[0]?.start_date ?
                            utcToZonedTime(steps[0].start_date, 'Etc/UTC') :
                            utcToZonedTime(new Date(), 'Etc/UTC');
                        const daysDifference = getItineraryDayDifference(step);
                        const stepDays = daysDifference.map((item) => {
                            return differenceInCalendarDays(
                                item,
                                firstDay
                            ) + 1;
                        }).sort((a, b) => a - b);
                        return props.item.content.mode === 'by-day' &&
                            stepDays[stepDays.length - 1] &&
                            props.item.content.content.day.includes(stepDays[stepDays.length - 1]!);
                    })
                }
                onClose={() => setOpenEditModal(false)}
            />
        </Box>
    );
}

type StepsDatesProps = {
    item: Props['item']
}

function StepsDates(props: StepsDatesProps): JSX.Element {
    const { t } = useTranslation();
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const steps = useMemo(() => {
        return itinerary.filter((step) => {
            return step.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);

    const firstDay = steps[0]?.start_date ?
        utcToZonedTime(steps[0].start_date, 'Etc/UTC') :
        utcToZonedTime(new Date(), 'Etc/UTC');

    const Content = (step: Itinerary) => {
        if (props.item.content.mode === 'by-day') {
            const daysDifference = getItineraryDayDifference(step);
            const stepDays = daysDifference.map((item) => {
                return differenceInCalendarDays(
                    item,
                    firstDay
                ) + 1;
            }).sort((a, b) => a - b);
            const stepStartDate = stepDays[0] && props.item.content.content.day.includes(
                stepDays[0]
            ) ?
                window.moment.utc(step.start_date).format('L HH:mm') :
                null;
            const stepEndDate = stepDays[stepDays.length - 1] && props.item.content.content.day.includes(
                stepDays[stepDays.length - 1]!
            ) ?
                window.moment.utc(step.end_date).format('L HH:mm') :
                null;
            const firstContentDate = window.moment.utc(steps[0]?.start_date).startOf('day').add(
                ([...props.item.content.content.day].sort((a, b) => a - b)[0] ?? 1) - 1,
                'days'
            ).format('L');
            const lastContentDate = window.moment.utc(steps[0]?.start_date).add(
                (props.item.content.content.day[props.item.content.content.day.length - 1] ?? 1) - 1,
                'days'
            ).format('L');
            return t<string>(
                'cart-material.cart-construction-products-table-date',
                {
                    from: stepStartDate ?? firstContentDate,
                    to: stepEndDate ?? lastContentDate
                }
            );
        }
    };

    return (
        <>
            {
                props.item.steps.length === 1 ?
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                        {Content(props.item.steps[0]!)}
                    </Box> :
                    props.item.steps.map((step) => {
                        return (
                            <Stack spacing={1}>
                                <Box sx={{ whiteSpace: 'nowrap' }}>
                                    {Content(step)}
                                </Box>
                            </Stack>
                        );
                    })
            }
        </>
    );
}

type EditDatesModalProps = {
    open: boolean,
    steps: Itinerary[],
    onClose: () => void
}

function EditDatesModal(props: EditDatesModalProps): JSX.Element {
    const { t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const [loading, setLoading] = useState(false);
    const [itineraryListInput, setItineraryListInput] = useState<ItineraryInput[]>([]);
    const steps = useMemo(() => {
        const ids = props.steps.map((item) => {
            return item.id.toString();
        });
        return itineraryListInput.filter((item) => {
            return ids.includes(item.id.toString());
        });
    }, [itineraryListInput, props.steps]);
    const manager = useMemo(() => {
        if (tripStartDate && tripEndDate) {
            return new StepsDatesManager(tripStartDate, tripEndDate);
        }
        return null;
    }, [tripStartDate, tripEndDate]);
    const showError = useShowError();
    const update = useItineraryUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(itinerary) {
            dispatch({
                type: 'ITINERARY_SET_ITINERARY_LIST',
                payload: {
                    itinerary_list: itinerary
                }
            });
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onChangeEndDate = (step: ItineraryInput, field: 'start' | 'end', date: Moment | null) => {
        setItineraryListInput((state) => {
            if (manager && date) {
                let result = state.map((item) => {
                    if (step.id === item.id) {
                        const startDate = window.moment.utc(item.start_date);
                        const endDate = window.moment.utc(item.end_date);
                        switch (field) {
                            case 'start': {
                                const newDate = date.toISOString();
                                return {
                                    ...item,
                                    start_date: newDate,
                                    end_date: startDate.isAfter(endDate) ?
                                        newDate :
                                        item.end_date
                                };
                            }
                            case 'end': {
                                const newDate = date.toISOString();
                                return {
                                    ...item,
                                    start_date: endDate.isBefore(startDate) ?
                                        newDate : 
                                        item.start_date,
                                    end_date: newDate
                                };
                            }
                        }
                    }
                    return item;
                });
                const index = state.findIndex((item) => {
                    return item.id === step.id;
                });
                const preSlice = result.slice(0, index + 1).reverse().map((current, index, array) => {
                    const prev = array[index - 1];
        
                    if (prev) {
                        const result = manager.recomputeDatesInReverse(current, prev);
                        return result[0];
                    }
        
                    return current;
                }).reverse();
                const postSlice = result.slice(index).map((current, index, array) => {
                    const prev = array[index - 1];
        
                    if (prev) {
                        const result = manager.recomputeDates(prev, current);
                        return result[1];
                    }
        
                    return current;
                });
                return preSlice.concat(postSlice.slice(1));
            }
            return state;
        });
    };

    const onSave = () => {
        update(itineraryList, itineraryListInput);
    };

    useEffect(() => {
        setItineraryListInput(
            itineraryList.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary)
        );
    }, [itineraryList]);

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                sx={(theme) => ({
                    zIndex: theme.zIndex.tooltip + 1
                })}
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle>
                    {t<string>('cart-material.cart-construction-step-time-change')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ "padding": 1.5, '& div:last-child': { marginBottom: 0 } }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {
                                steps.map((step) => {
                                    return (
                                        <Box sx={{ marginBottom: 2 }}>
                                            <Typography
                                                fontWeight="bold"
                                                sx={{ marginBottom: 1 }}
                                                component="div"
                                            >
                                                {step.destination?.international_name}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                {
                                                    t<string>(
                                                        'itinerary.step-from',
                                                        { date: window.moment.utc(step.start_date).format('LLL') }
                                                    )
                                                }
                                            </Typography>
                                            <Stack direction="row" spacing={0.5}>
                                                <Typography variant="caption" component="div">

                                                    {
                                                        t<string>(
                                                            'itinerary.step-to',
                                                            { date: window.moment.utc(step.end_date).format('LL') }
                                                        )
                                                    }
                                                </Typography>
                                                <TimeField
                                                    value={
                                                        window.moment.utc(step.end_date)
                                                    }
                                                    onChange={(value) => onChangeEndDate(step, 'end', value)}
                                                    sx={{
                                                        '& input': {
                                                            width: '35px',
                                                            textDecoration: 'underline',
                                                            padding: 0,
                                                            fontSize: '0.75rem'
                                                        },
                                                        '& fieldset': {
                                                            border: 'none'
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                    );
                                })
                            }
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t<string>('shared.cancel')}
                    </Button>
                    <Button onClick={onSave}>
                        {t<string>('shared.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
