import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    BuildCircle,
    CardGiftcard,
    Check,
    CloudCircle,
    CloudOff,
    ExpandLess,
    ExpandMore,
    FlightOutlined,
    PeopleOutlineOutlined,
    ReportProblemOutlined,
    Sync,
    Work
} from "@mui/icons-material";
import { uniq } from "lodash";
import { useSnackbar } from "notistack";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartFlightSegmentInfoPerPax } from "./CartFlightSegmentInfoPerPax";
import { CartProductIconText } from "./CartProductIconText";
import { CartFlightCardDetails } from "./CartFlightCardDetails";
import { CartConstructionReplaceProductModal } from "./CartConstructionReplaceProductModal";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartManualFlightCardDetails } from "./CartManualFlightCardDetails";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartFlightCardCancelBookingModal } from "./CartFlightCardCancelBookingModal";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { CartConstructionProductsTableEditFlightRetrievePnrModal } from "./CartConstructionProductsTableEditFlightRetrievePnrModal";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import RenderFlightLegDetail from "../MaterialSummaryQuotation/RenderProducts/RenderFlightLegDetail";
import { ProviderContext } from "./utils/providerContext";
import { isProductBookable } from "./utils/isProductBookable";
import { isProductPackaged } from "./utils/isProductPackaged";
import { useGetPrice } from "./utils/getPrice";
import { useGetTaxesPrice } from "./utils/getTaxesPrice";
import { useGetFlightSellingPrice } from "./utils/getFlightSellingPrice";
import { useShowError } from "../Utils/showError";
import { useFlightPnrEmit } from "./network/flightPnrEmit";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import { Currency } from "../../Reducers/objects/currency";
import { Flight } from "../Itinerary/network/flight";
import { ManualProductFlightCart } from "../Itinerary/objects/manualProductFlightCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = ({
    type: 'normal'
    flight: Flight
} | {
    type: 'manual',
    flight: ManualProductFlightCart
}) & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/destination_default.jpg';

export function CartFlightCard(props: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const providers = useSelector((state: AppState) => state.trip.providers);
    const to_book = useSelector((state: AppState) => state.cart.to_book);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const user = useSelector((state: AppState) => state.user.user);
    const [openRetrievePnrModal, setOpenRetrievePnrModal] = useState(false);
    const [selectedSegmentIndices, setSelectedSegmentIndices] = useState<number[] | null>(null);
    const [openReplaceModal, setOpenReplaceModal] = useState(false);
    const [openCancelBookingModal, setOpenCancelBookingModal] = useState(false);
    const [emittingPnr, setEmittingPnr] = useState(false);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.flight.provider;
        })?.provider;
    }, [props.flight, providers, manualProviders]);
    const checked = useMemo(() => {
        return !!to_book?.find(book_item => book_item.id === props.flight.id);
    }, [props.flight, to_book]);
    const context = useContext(CartConstructionProductsContext);
    const getPrice = useGetPrice();
    const [getFlightSellingPrice] = useGetFlightSellingPrice();
    const pictures = useMemo(() => {
        if (props.flight.hide_pictures) {
            return [DEFAULT_PICTURE];
        }

        if ((props.flight.overload_pictures?.length ?? 0) > 0) {
            return props.flight.overload_pictures?.map((item) => {
                return [
                    item.url,
                    item.thumbnail_big,
                    item.thumbnail_medium,
                    item.thumbnail_little,
                    DEFAULT_PICTURE
                ].find((item) => {
                    return (item?.length ?? 0) > 0;
                }) ?? '';
            }) ?? [];
        }

        return [DEFAULT_PICTURE];
    }, [props.flight]);
    const getTaxesPrice = useGetTaxesPrice();
    const totalCost = useMemo(() => {
        if (props.type === 'normal') {
            return getFlightSellingPrice(props.flight);
        }
        let total_cost: {
            cost: number,
            currency: Currency | undefined
        }[] = [];
        let matched = false;
        let price = getPrice(props.flight.prices);
        total_cost.map(currency_cost => {
            if (currency_cost.currency?.id === price.currency?.id) {
                matched = true;
                currency_cost.cost += price.cost + getTaxesPrice(props.flight.taxes ?? '0');
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost + getTaxesPrice(props.flight.taxes ?? '0'),
                currency: price.currency
            });
        }
        return total_cost;
    }, [props.flight, user, getPrice, getTaxesPrice]);
    const showError = useShowError();
    const emitFlightPnr = useFlightPnrEmit({
        onTrigger() {
            setEmittingPnr(true);
        },
        onSuccess(flights) {
            for (const flight of flights) {
                let arr = CheckResponse(flight, trip?.end_date);
                dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            }
            enqueueSnackbar(t('cart-material.flight-pnr-emitted'), { variant: 'success' });
        },
        onFinally() {
            setEmittingPnr(false);
        },
        onError(error) {
            showError(error);
        }
    });
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            if (trip) {
                dispatch({
                    type: 'FLIGHT_EDIT_CART_BY_ID',
                    payload: CheckResponse([product], trip.end_date)[0]!
                });
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const providerContext = useContext(ProviderContext);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onShowPriceDetail = () => {
        dispatch({ type: 'CART_TOGGLE_FLIGHT_PRICE_DETAIL', payload: props.flight });
    };

    const onToggleForBooking = () => {
        dispatch({ type: 'CART_TOGGLE_FOR_BOOKING', payload: props.flight });
    };

    const onSelectSegment = (index: number, checked: boolean) => {
        setSelectedSegmentIndices((state) => {
            const result = state?.filter((item) => {
                return item !== index;
            });
            return checked ?
                result?.concat([index]) ?? null :
                result ?? null;
        });
    };

    const onSelectAllSegment = (checked: boolean) => {
        if (props.type === 'normal') {
            if (checked) {
                setSelectedSegmentIndices(
                    new Array(props.flight.outbounds.length).fill(null).map((_, index) => {
                        return index;
                    })
                );
            } else {
                setSelectedSegmentIndices([]);
            }
        }
    };

    const onEmitPnr = async () => {
        emitFlightPnr(props.flight.id);
    }

    const onChangeBookingReference = (reference: string) => {
        if (
            providerContext.module === 'booking' &&
            providerContext.tripId &&
            providerContext.version
        ) {
            changeBookingStatus({
                tripId: providerContext.tripId,
                version: providerContext.version,
                productId: props.flight.id,
                ref: reference,
                status: props.flight.booking_status?.status_booking ?? null,
                isManual: props.type === 'manual'
            });
        }
    }

    if (props.type === 'normal') {
        return (
            <>
                <CartProductCard
                    direction="vertical"
                    type="flight"
                    item={props.flight}
                    mainContent={(sideActions) => (
                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {
                                        context.enableBooking &&
                                        isProductBookable(
                                            { type: 'flight', item: props.flight },
                                            user,
                                            trip?.stack_info ?? null,
                                            quotationCode
                                        ) &&
                                        <Checkbox
                                            onChange={onToggleForBooking}
                                            checked={checked}
                                            sx={{
                                                padding: 0
                                            }}
                                            disableRipple
                                        />
                                    }
                                    <CartProductIconText
                                        text={
                                            [
                                                t(
                                                    'roadbook.adults-count',
                                                    {
                                                        count: props.flight.group_passenger?.travelers_list?.filter((traveler) => {
                                                            const result = window.moment.utc(tripEndDate).diff(
                                                                window.moment.utc(traveler.birth_date),
                                                                'years'
                                                            );
                                                            return result >= 18;
                                                        }).length ?? 0
                                                    }
                                                ),
                                                (() => {
                                                    const childrenCount = props.flight.group_passenger?.travelers_list?.filter((traveler) => {
                                                        const result = window.moment.utc(tripEndDate).diff(
                                                            window.moment.utc(traveler.birth_date),
                                                            'years'
                                                        );
                                                        return result < 18;
                                                    }).length ?? 0;
                                                    return childrenCount > 0 ?
                                                        t(
                                                            'roadbook.children-count',
                                                            { count: childrenCount }
                                                        ) :
                                                        null;
                                                })()
                                            ].filter((item) => item).join(', ')
                                        }
                                        startIcon={PeopleOutlineOutlined}
                                    />
                                    {
                                        !providerContext.module &&
                                        !isProductPackaged({
                                            product: props.flight,
                                            stackInfos: trip?.stack_info ?? null
                                        }) &&
                                        (
                                            <>
                                                <Divider orientation="vertical" flexItem />
                                                <Typography variant="caption">
                                                    {
                                                        ['public', 'all'].includes(props.flight.private_fare) ?
                                                            t('flight_groups.public_price') :
                                                            t('flight_groups.private_price')
                                                    }
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    onClick={onShowPriceDetail}
                                                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    {t('cart-material.price-detail')}
                                                </Typography>
                                            </>
                                        )
                                    }
                                    {
                                        (
                                            props.flight.estimated_last_ticketing_date ||
                                            props.flight.booking_status?.last_ticketing_date
                                        ) && (
                                            <>
                                                <Divider orientation="vertical" flexItem />
                                                <Typography variant="caption">
                                                    {t("cart-material.flight-last-ticketing-date")}{' '}
                                                    {
                                                        window.moment(
                                                            props.flight.estimated_last_ticketing_date ??
                                                            props.flight.booking_status?.last_ticketing_date ??
                                                            undefined
                                                        ).format("L")
                                                    }
                                                </Typography>
                                            </>
                                        )
                                    }
                                    {
                                        !isProductPackaged({
                                            product: props.flight,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <CartConstructionProductsTableItemStatus
                                                type="flight"
                                                item={props.flight}
                                                bookingProcessState={props.flight.booking_process_state}
                                                bookingStatus={props.flight.booking_status}
                                                agencyNeedToBook={props.flight.agency_need_to_book}
                                            />
                                            {
                                                (
                                                    props.flight.booking_status?.item_reference ||
                                                    providerContext.module === 'booking'
                                                ) &&
                                                <ProviderBookingReferenceEdit
                                                    open={editBookingReference}
                                                    productId={props.flight.id}
                                                    reference={props.flight.booking_status?.item_reference ?? ''}
                                                    onOpen={() => setEditBookingReference(true)}
                                                    onClose={() => setEditBookingReference(false)}
                                                    onSave={onChangeBookingReference}
                                                >
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.flight.booking_status?.item_reference }
                                                            )
                                                        }
                                                    </Typography>
                                                </ProviderBookingReferenceEdit>
                                            }
                                        </>
                                    }
                                    {

                                        props.flight.booking_status?.status_booking === StatusBooking.CONFIRMED &&
                                        (user?.client_full?.type !== 2 || quotation_code !== "visiteurs") &&
                                        <>
                                            <Tooltip title={t('cart-material.cart-construction-pnr-retrieve-menu-title')}>
                                                <IconButton
                                                    onClick={() => setOpenRetrievePnrModal(true)}
                                                    sx={{ padding: 0 }}
                                                >
                                                    <Sync />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                quotation_code === 'continentsinsolites' &&
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="info"
                                                    startIcon={
                                                        (() => {
                                                            if (emittingPnr) {
                                                                return (
                                                                    <CircularProgress size="1em" />
                                                                );

                                                            }

                                                            if (props.flight.booking_status.flight_queued_for_issuance) {
                                                                return (
                                                                    <Check fontSize="inherit" color="success" />
                                                                );
                                                            }

                                                            return null;
                                                        })()
                                                    }
                                                    onClick={onEmitPnr}
                                                    sx={{ textTransform: 'none' }}
                                                    disabled={emittingPnr || props.flight.booking_status.flight_queued_for_issuance}
                                                >
                                                    {t('cart-material.flight-pnr-issue-request')}
                                                </Button>
                                            }
                                            {
                                                (props.flight.issued_ticket?.length ?? 0) === 0 &&
                                                (
                                                    provider?.code !== 'amadeus_rest' ||
                                                    props.flight.flight_source !== 'NDC'
                                                ) &&
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    onClick={() => setOpenCancelBookingModal(true)}
                                                    sx={{ textTransform: 'none' }}
                                                >
                                                    {t('shared.cancel')}
                                                </Button>
                                            }
                                        </>
                                    }
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={3}
                                        sx={{ marginRight: 2 }}
                                    >
                                        {
                                            !isProductPackaged({
                                                product: props.flight,
                                                stackInfos: trip?.stack_info ?? null
                                            }) &&
                                            <CartProductCardProviderLogo
                                                logoUrl={
                                                    provider?.logo?.url ??
                                                    provider?.logo?.thumbnail_big ??
                                                    provider?.logo?.thumbnail_medium ??
                                                    provider?.logo?.thumbnail_little ??
                                                    null
                                                }
                                                name={provider?.name ?? null}
                                                height={24}
                                            />
                                        }
                                        {
                                            !providerContext.module &&
                                            !isProductPackaged({
                                                product: props.flight,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            (
                                                user?.client_full?.type !== 2 ||
                                                props.flight.creator?.client?.type === user.client_full.type
                                            ) &&
                                            <>
                                                {
                                                    (
                                                        props.flight.is_custom ||
                                                        props.flight.flight_source === 'CUSTOM_FLIGHT'
                                                    ) &&
                                                    <Chip
                                                        size="small"
                                                        label={t('cart-material.offline')}
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudOff color="inherit" />}
                                                    />
                                                }
                                                {
                                                    !props.flight.is_custom &&
                                                    props.flight.flight_source !== 'CUSTOM_FLIGHT' &&
                                                    <Chip
                                                        label={t('cart-material.flux')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#2ACAEA',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudCircle color="inherit" />}
                                                    />
                                                }
                                            </>
                                        }
                                    </Stack>
                                    {
                                        (
                                            !isProductPackaged({
                                                product: props.flight,
                                                stackInfos: trip?.stack_info ?? null
                                            }) ||
                                            providerContext.module
                                        ) &&
                                        <Typography fontWeight="bold" textAlign="center" position="relative">
                                            <ProviderQuotationPriceEdit
                                                productId={props.flight.id}
                                                price={
                                                    (
                                                        providerContext.module ?
                                                            getPrice(props.flight.prices).purchaseCost :
                                                            totalCost[0]?.cost ?? 0
                                                    ).toString()
                                                }
                                                open={editPrice}
                                                onOpen={() => setEditPrice(true)}
                                                onClose={() => setEditPrice(false)}
                                            >
                                                <div>
                                                    {
                                                        totalCost.map((currency_cost) => {
                                                            return new Intl.NumberFormat(
                                                                i18n.language,
                                                                {
                                                                    style: 'currency',
                                                                    currency: providerContext.module ?
                                                                        getPrice(props.flight.prices).purchaseCurrency?.iso_code ?? 'EUR' :
                                                                        currency_cost.currency?.iso_code ?? 'EUR'
                                                                }
                                                            ).format(
                                                                providerContext.module ?
                                                                    getPrice(props.flight.prices).purchaseCost :
                                                                    currency_cost.cost
                                                            );
                                                        }).join(' + ')
                                                    }
                                                </div>
                                            </ProviderQuotationPriceEdit>
                                            {
                                                !providerContext.module &&
                                                props.flight.price_change &&
                                                props.flight.price_change.price_variation !== 0 &&
                                                <Stack direction="row" alignItems="center" justifyContent="center">
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            color: props.flight.price_change.price_variation < 0 ? 'green' : 'red',
                                                            fontSize: '10px'
                                                        }}
                                                    >
                                                        {
                                                            new Intl.NumberFormat(
                                                                i18n.language,
                                                                {
                                                                    style: 'currency',
                                                                    currency: totalCost[0]?.currency?.iso_code ?? 'EUR'
                                                                }
                                                            ).format(props.flight.price_change.price_variation)
                                                        }
                                                    </Typography>
                                                    <ArrowRightAltOutlined
                                                        sx={{
                                                            transform: props.flight.price_change.price_variation < 0 ?
                                                                'rotateZ(90deg)' :
                                                                'rotateZ(-90deg)',
                                                            color: 'green',
                                                            fontSize: '14px'
                                                        }}
                                                    />
                                                </Stack>
                                            }
                                            {
                                                props.flight.flight_source &&
                                                <Box
                                                    sx={(theme) => ({
                                                        ...theme.typography.caption,
                                                        position: 'absolute',
                                                        opacity: 0.4,
                                                        left: '50%',
                                                        transform: 'translateX(-50%)'
                                                    })}
                                                >
                                                    {
                                                        props.flight.flight_source === 'NDC' ?
                                                            t('cart-material.cart-construction-ndc') :
                                                            t('cart-material.cart-construction-standard')
                                                    }
                                                </Box>
                                            }
                                        </Typography>
                                    }
                                    {sideActions(true)}
                                </Stack>
                            </Stack>
                            <Box
                                sx={{
                                    "width": '100%',
                                    '& > table tr.flight-segment-details:not(:last-child)': {
                                        paddingBottom: 1,
                                        marginBottom: 2,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
                                    }
                                }}>
                                {
                                    props.flight.booking_status?.status_booking === StatusBooking.CONFIRMED &&
                                    user?.client_full?.type !== 2 &&
                                    (
                                        provider?.code.includes('sabre') ||
                                        provider?.code === '1G'
                                    ) &&
                                    (props.flight.issued_ticket?.length ?? 0) === 0 &&
                                    !isProductPackaged({
                                        product: props.flight,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    <>
                                        {
                                            !selectedSegmentIndices &&
                                            <Typography
                                                fontWeight={700}
                                                sx={{
                                                    marginBottom: 1,
                                                    textDecoration: 'underline',
                                                    background: 'none',
                                                    border: 'none',
                                                    cursor: 'pointer'
                                                }}
                                                component="button"
                                                onClick={() => setSelectedSegmentIndices([])}
                                            >
                                                {t('cart-material.cart-construction-edit-segments')}
                                            </Typography>
                                        }
                                        {
                                            selectedSegmentIndices &&
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Checkbox
                                                    checked={
                                                        selectedSegmentIndices.length === props.flight.outbounds.length
                                                    }
                                                    indeterminate={
                                                        selectedSegmentIndices.length > 0 &&
                                                        selectedSegmentIndices.length !== props.flight.outbounds.length
                                                    }
                                                    onChange={(_, checked) => onSelectAllSegment(checked)}
                                                />
                                                <Typography>
                                                    {
                                                        t(
                                                            'cart-material.cart-construction-selected-count',
                                                            { count: selectedSegmentIndices.length }
                                                        )
                                                    }
                                                </Typography>
                                                <Button
                                                    size="small"
                                                    onClick={() => setOpenReplaceModal(true)}
                                                >
                                                    {t('shared.edit')}
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onClick={() => setSelectedSegmentIndices(null)}
                                                >
                                                    {t('shared.cancel')}
                                                </Button>
                                            </Stack>
                                        }
                                    </>
                                }
                                <Box
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                        td: {
                                            height: '1px !important'
                                        }
                                    }}
                                    component="table"
                                >
                                    {
                                        props.flight.outbounds.map((outbound, index) => (
                                            <FlightOutboundItem
                                                key={index}
                                                no={index + 1}
                                                outbound={outbound}
                                                flight={props.flight}
                                                enableSelection={!!selectedSegmentIndices}
                                                selected={!!selectedSegmentIndices?.includes(index)}
                                                onChangeSelection={(checked) => onSelectSegment(index, checked)}
                                            />
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Stack>
                    )}
                    footer={(providerActions, providerInfo) => (
                        <>
                            {
                                !isProductPackaged({
                                    product: props.flight,
                                    stackInfos: trip?.stack_info ?? null
                                }) &&
                                    !props.flight.is_cancellable ?
                                    <>
                                        <Divider orientation="vertical" flexItem />
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t('cart-material.not-cancellable')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>
                                    </> :
                                    undefined
                            }
                            {providerActions}
                            {providerInfo}
                        </>
                    )}
                    margin={<MarginWrapper flight={props.flight} />}
                    pictures={pictures}
                    alwaysOpen={props.alwaysOpen}
                    disablePictures
                >
                    <CartFlightCardDetails flight={props.flight} />
                </CartProductCard>
                <CartConstructionProductsTableEditFlightRetrievePnrModal
                    open={openRetrievePnrModal}
                    flight={props.flight}
                    onClose={() => setOpenRetrievePnrModal(false)}
                />
                {
                    openReplaceModal &&
                    <CartConstructionReplaceProductModal
                        type="flight-segment"
                        mode="edit"
                        segmentIndices={selectedSegmentIndices ?? []}
                        flight={props.flight}
                        onClose={() => setOpenReplaceModal(false)}
                    />
                }
                <CartFlightCardCancelBookingModal
                    open={openCancelBookingModal}
                    flight={props.flight}
                    onClose={() => setOpenCancelBookingModal(false)}
                />
                <LoadingBackDrop open={loading} />
            </>
        );
    }

    return (
        <CartProductCard
            direction="vertical"
            type="manual"
            item={props.flight}
            mainContent={(sideActions) => (
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            {
                                context.enableBooking &&
                                isProductBookable(
                                    { type: 'manual-flight', item: props.flight },
                                    user,
                                    trip?.stack_info ?? null,
                                    quotationCode
                                ) &&
                                <Checkbox
                                    onChange={onToggleForBooking}
                                    checked={checked}
                                    sx={{
                                        padding: 0
                                    }}
                                    disableRipple
                                />
                            }
                            <CartProductIconText
                                text={
                                    [
                                        t(
                                            'roadbook.adults-count',
                                            {
                                                count: props.flight.group_passenger?.travelers_list?.filter((traveler) => {
                                                    const result = window.moment.utc(tripEndDate).diff(
                                                        window.moment.utc(traveler.birth_date),
                                                        'years'
                                                    );
                                                    return result >= 18;
                                                }).length ?? 0
                                            }
                                        ),
                                        (() => {
                                            const childrenCount = props.flight.group_passenger?.travelers_list?.filter((traveler) => {
                                                const result = window.moment.utc(tripEndDate).diff(
                                                    window.moment.utc(traveler.birth_date),
                                                    'years'
                                                );
                                                return result < 18;
                                            }).length ?? 0;
                                            return childrenCount > 0 ?
                                                t(
                                                    'roadbook.children-count',
                                                    { count: childrenCount }
                                                ) :
                                                null;
                                        })()
                                    ].filter((item) => item).join(', ')
                                }
                                startIcon={PeopleOutlineOutlined}
                            />
                            {
                                !isProductPackaged({
                                    product: props.flight,
                                    stackInfos: trip?.stack_info ?? null
                                }) && (
                                    <>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography variant="caption">
                                            {
                                                ['public', 'all'].includes(props.flight.private_fare) ?
                                                    t('flight_groups.public_price') :
                                                    t('flight_groups.private_price')
                                            }
                                        </Typography>
                                    </>
                                )
                            }
                            {
                                !isProductPackaged({
                                    product: props.flight,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <CartConstructionProductsTableItemStatus
                                        type="manual"
                                        item={props.flight}
                                        bookingProcessState={props.flight.booking_process_state}
                                        bookingStatus={props.flight.booking_status}
                                        agencyNeedToBook={props.flight.agency_need_to_book}
                                    />
                                    {
                                        (
                                            props.flight.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                            props.flight.booking_status?.status_booking === StatusBooking.PENDING ||
                                            props.flight.booking_status?.status_booking === StatusBooking.WAITING ||
                                            providerContext.module === 'booking'
                                        ) &&
                                        <ProviderBookingReferenceEdit
                                            open={editBookingReference}
                                            productId={props.flight.id}
                                            reference={props.flight.booking_status?.item_reference ?? ''}
                                            onOpen={() => setEditBookingReference(true)}
                                            onClose={() => setEditBookingReference(false)}
                                            onSave={onChangeBookingReference}
                                        >
                                            <Typography fontWeight={100} fontSize="0.75rem">
                                                {
                                                    t(
                                                        'cart-material.cart-construction-reference',
                                                        { ref: props.flight.booking_status?.item_reference }
                                                    )
                                                }
                                            </Typography>
                                        </ProviderBookingReferenceEdit>
                                    }
                                </>
                            }
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={3}
                                sx={{ marginRight: 2 }}
                            >
                                {
                                    !isProductPackaged({
                                        product: props.flight,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    <CartProductCardProviderLogo
                                        logoUrl={
                                            provider?.logo?.url ??
                                            provider?.logo?.thumbnail_big ??
                                            provider?.logo?.thumbnail_medium ??
                                            provider?.logo?.thumbnail_little ??
                                            null
                                        }
                                        name={provider?.name ?? null}
                                        height={24}
                                    />
                                }
                                {
                                    !providerContext.module &&
                                    !isProductPackaged({
                                        product: props.flight,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    (
                                        user?.client_full?.type !== 2 ||
                                        props.flight.creator?.client?.type === user.client_full.type
                                    ) &&
                                    <>
                                        {
                                            props.flight.auto_product === null ?
                                                <Chip
                                                    label={t('accommodation.manual')}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: '#6A329F',
                                                        color: '#fff'
                                                    }}
                                                    icon={<BuildCircle color="inherit" />}
                                                /> :
                                                <Chip
                                                    label={t('cart-material.annex')}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: '#f5823b',
                                                        color: '#fff'
                                                    }}
                                                    icon={<CardGiftcard color="inherit" />}
                                                />
                                        }
                                    </>
                                }
                            </Stack>
                            {
                                (
                                    !isProductPackaged({
                                        product: props.flight,
                                        stackInfos: trip?.stack_info ?? null
                                    }) ||
                                    providerContext.module
                                ) &&
                                <ProviderQuotationPriceEdit
                                    productId={props.flight.id}
                                    price={
                                        (
                                            providerContext.module ?
                                                getPrice(props.flight.prices).purchaseCost :
                                                totalCost[0]?.cost ?? 0
                                        ).toString()
                                    }
                                    open={editPrice}
                                    onOpen={() => setEditPrice(true)}
                                    onClose={() => setEditPrice(false)}
                                >
                                    <Typography fontWeight="bold">
                                        {
                                            totalCost.map((currency_cost) => {
                                                return new Intl.NumberFormat(
                                                    i18n.language,
                                                    {
                                                        style: 'currency',
                                                        currency: providerContext.module ?
                                                            getPrice(props.flight.prices).purchaseCurrency?.iso_code ?? 'EUR' :
                                                            currency_cost.currency?.iso_code ?? 'EUR'
                                                    }
                                                ).format(
                                                    providerContext.module ?
                                                        getPrice(props.flight.prices).purchaseCost :
                                                        currency_cost.cost
                                                );
                                            }).join(' + ')
                                        }
                                    </Typography>
                                </ProviderQuotationPriceEdit>
                            }
                            {sideActions(true)}
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            "width": '100%',
                            '& > table tr.flight-segment-details:not(:last-child)': {
                                paddingBottom: 1,
                                marginBottom: 2,
                                borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
                            }
                        }}>
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'left'
                            }}
                            component="table"
                        >
                            {
                                props.flight.flight_segment.map((segment, index) => (
                                    <ManualFlightSegmentItem
                                        key={index}
                                        no={index + 1}
                                        segment={segment}
                                        nb_baggages={props.flight.nb_baggages}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                </Stack>
            )}
            footer={(providerActions, providerInfo) => (
                <>
                    {providerActions}
                    {providerInfo}
                </>
            )}
            margin={<ManualMarginWrapper flight={props.flight} />}
            pictures={pictures}
            alwaysOpen={props.alwaysOpen}
            disablePictures
        >
            <CartManualFlightCardDetails
                manual_product={props.flight}
            />
        </CartProductCard>
    );
}

type FlightOutboundItemProps = {
    no: number,
    flight: Flight,
    outbound: Flight['outbounds'][number],
    selected: boolean,
    enableSelection: boolean,
    onChangeSelection: (checked: boolean) => void
}

function FlightOutboundItem(props: FlightOutboundItemProps): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.user.user);
    const [openDetails, setOpenDetails] = useState(false);
    const lastIndex = props.outbound.legs.length - 1;
    const outboundDatesDiff = window.moment.utc(props.outbound.legs[lastIndex]?.arrival_time).startOf('day').diff(
        window.moment.utc(props.outbound.legs[0]?.departure_time).startOf('day'),
        'days'
    );

    let temp_carry_on = 0;
    let temp_check = 0;
    let paid_carry_on = 0;
    let paid_check = 0;
    let paid_options = {};
    if (props.outbound.legs[0]?.baggage) {
        props.outbound.legs[0].baggage.map((luggage) => {
            if (luggage.is_carry_on) {
                temp_carry_on += luggage.quantity;
            } else {
                temp_check += luggage.quantity;
            }
        });
    } else {
        temp_check = props.outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0;
    }
    if (props.outbound.legs[0]?.paid_options) {
        props.outbound.legs[0].paid_options.map((luggage) => {
            if (luggage.option_type === "Baggage") {
                if ((paid_options as any)[luggage.traveler] === undefined) {
                    (paid_options as any)[luggage.traveler] = [];
                }
                (paid_options as any)[luggage.traveler].push(luggage);
            }
        });
        let paid_options_array = Object.keys(paid_options);
        if (paid_options_array.length > 0) {
            (paid_options as any)[paid_options_array[0]!].map((luggage: any) => {
                if (luggage.baggage_is_carry_on) {
                    paid_carry_on += luggage.quantity;
                } else {
                    paid_check += luggage.quantity;
                }
            });
        }
    }
    const totalLuggages = temp_check + temp_carry_on + paid_check + paid_carry_on;

    let stopover_iata = [];
    for (let i = 1; i < props.outbound.legs.length; i++) {
        if (props.outbound.legs[i]?.origin) {
            stopover_iata.push(props.outbound.legs[i]!.origin?.airport_code);
        } else {
            stopover_iata.push(props.outbound.legs[i]!.origin_station?.station_code);
        }
    }

    return (
        <>
            <Box
                onClick={() => setOpenDetails((state) => !state)}
                sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    verticalAlign: 'bottom'
                }}
                component="tr"
            >
                {
                    props.enableSelection &&
                    <td>
                        <Checkbox
                            checked={props.selected}
                            onChange={(_, checked) => props.onChangeSelection(checked)}
                            onClick={(event) => event.stopPropagation()}
                            sx={{ padding: 0 }}
                        />
                    </td>
                }
                <td>
                    <Stack direction="row" alignItems="flex-end" spacing={1}>
                        <Box
                            src={
                                `https://storagefacilitatrip.blob.core.windows.net/iata-company/${props.outbound.legs[0]?.marketing_airline?.airline_code}.png`
                            }
                            component="img"
                            sx={{
                                width: 100,
                                height: 30,
                                maxWidth: 100,
                                maxHeight: 30,
                                objectFit: 'contain'
                            }}
                        />
                        <Typography>
                            {t('global.flight')} {props.no}
                        </Typography>
                    </Stack>
                </td>
                <td>
                    <Typography>
                        {window.moment.utc(props.outbound.legs[0]?.departure_time).format('LL')}
                    </Typography>
                </td>
                <td>
                    <Typography>
                        {
                            props.outbound.legs[0]?.origin ?
                                (props.outbound.legs[0].origin.iata_city.name !== undefined ? props.outbound.legs[0].origin.iata_city.name : props.outbound.legs[0].origin.iata_city.international_name) :
                                (props.outbound.legs[0]?.origin_station?.iata_city?.name !== undefined ? props.outbound.legs[0]?.origin_station?.iata_city?.name : props.outbound.legs[0]?.origin_station?.iata_city?.international_name)
                        }
                    </Typography>
                </td>
                <Box
                    sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        textAlign: 'center'
                    }}
                    component="td"
                >
                    <FlightOutlined
                        sx={{
                            transform: 'rotateZ(90deg)',
                            verticalAlign: 'bottom'
                        }}
                    />
                </Box>
                <td>
                    <Typography>
                        {
                            props.outbound.legs[lastIndex]?.destination ?
                                (props.outbound.legs[lastIndex]?.destination?.iata_city.name !== undefined ? props.outbound.legs[lastIndex]?.destination?.iata_city.name : props.outbound.legs[lastIndex]?.destination?.iata_city.international_name) :
                                (props.outbound.legs[lastIndex]?.destination_station?.iata_city?.name !== undefined ? props.outbound.legs[lastIndex]?.destination_station?.iata_city?.name : props.outbound.legs[lastIndex]?.destination_station?.iata_city?.international_name)
                        }
                        {
                            outboundDatesDiff > 0 &&
                            <Box
                                sx={{
                                    fontSize: 10,
                                    verticalAlign: 'top',
                                    marginLeft: 0.5
                                }}
                                component="span"
                            >
                                {' '}
                                <Box sx={{ color: 'red' }} component="span">
                                    {"+" + outboundDatesDiff + t("global.abrev_day")}
                                </Box>
                                {' '}
                                <Box sx={{ opacity: 0.65 }} component="span">
                                    ({window.moment.utc(props.outbound.legs[lastIndex]?.arrival_time).format('DD MMM')})
                                </Box>
                            </Box>
                        }
                    </Typography>
                </td>
                <td>
                    <Typography>
                        {window.moment.utc(props.outbound.legs[lastIndex]?.arrival_time).format('LL')}
                    </Typography>
                </td>
                <td>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Work
                                sx={{
                                    color: (props.outbound.legs[0]?.baggage?.length ?? 0) === 0 && totalLuggages === 0 ?
                                        'gray' :
                                        '#E6592F',
                                    fontSize: '1.5rem'
                                }}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(45% + 4px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '0.75em',
                                    lineHeight: 1
                                }}
                            >
                                {
                                    temp_carry_on + temp_check + paid_check + paid_carry_on !== 0 ?
                                        temp_carry_on + temp_check + paid_check + paid_carry_on :
                                        props.outbound.legs[0]?.baggage?.length
                                }
                            </Typography>
                        </Box>
                        <Typography textAlign="center">
                            <div>
                                {
                                    props.outbound.legs.length > 1 &&
                                        uniq(props.outbound.legs.map((item) => item.class_name)).length === 1 ?
                                        t(`flight_search.cabin_class.${props.outbound.legs[0]!.class_name?.replaceAll(' ', '_').toLowerCase()}`) :
                                        props.outbound.legs.map((item) => {
                                            return t(`flight_search.cabin_class.${item.class_name?.replaceAll(' ', '_').toLowerCase()}`)
                                        }).join(' - ')
                                }
                            </div>
                        </Typography>
                    </Stack>
                </td>
                <Box
                    sx={{
                        paddingLeft: 2,
                        textAlign: 'center'
                    }}
                    component="td"
                >
                    {
                        openDetails ?
                            <ExpandLess sx={{ verticalAlign: 'middle' }} /> :
                            <ExpandMore sx={{ verticalAlign: 'middle' }} />
                    }
                </Box>
            </Box>
            <Box
                onClick={() => setOpenDetails((state) => !state)}
                sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    lineHeight: 1
                }}
                component="tr"
            >
                <td />
                <td>
                    <Typography variant="caption">
                        (
                        {
                            props.outbound.legs.map((leg) => {
                                return `${leg.marketing_airline?.airline_code}-${leg.flight_number}`;
                            }).join(', ')
                        }
                        )
                    </Typography>
                </td>
                <td>
                    <Typography variant="caption">
                        {window.moment.utc(props.outbound.legs[0]?.departure_time).format('HH:mm')}
                    </Typography>
                </td>
                <td>
                    {
                        (() => {
                            const aircrafts = uniq(
                                props.outbound.legs.map((item) => {
                                    return item.aircraft?.commercial_name;
                                }).filter((item): item is NonNullable<typeof item> => !!item)
                            );
                            if (aircrafts.length > 0) {
                                return (
                                    <Typography variant="caption">
                                        ({aircrafts.join(', ')})
                                    </Typography>
                                );
                            }
                        })()
                    }
                </td>
                <td>
                    <Typography variant="caption">
                        {window.moment.utc(props.outbound.legs[lastIndex]?.arrival_time).format('HH:mm')}
                    </Typography>
                </td>
                <td>
                    {
                        props.outbound.legs.length === 1 ?
                            <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
                                {t("flight_search.direct_flight")}
                            </Typography> :
                            <Typography variant="caption">
                                {
                                    props.outbound.legs.length === 2 ?
                                        `1 ${t('flight_search.stopover')}` :
                                        `${props.outbound.legs.length - 1} ${t('flight_search.stopovers')}`
                                }
                                {
                                    props.outbound.legs.length > 1 &&
                                    ` (${stopover_iata.join(', ')})`
                                }
                            </Typography>
                    }
                </td>
                <td>
                    {
                        user?.client_full?.type !== 2 &&
                        <Typography variant="caption" sx={{ paddingLeft: '32px' }}>
                            {
                                props.outbound.legs.length > 1 &&
                                    uniq(props.outbound.legs.map((item) => item.class_of_service)).length === 1 ?
                                    props.outbound.legs[0]!.class_of_service :
                                    props.outbound.legs.map((item) => {
                                        return item.class_of_service;
                                    }).join(' - ')
                            }
                        </Typography>
                    }
                </td>
                <td />
            </Box>
            <tr className="flight-segment-details">
                <td colSpan={12}>
                    <Collapse in={openDetails}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1.5}
                            sx={{
                                marginTop: 1,
                                marginBottom: 1
                            }}
                        >
                            <Box sx={{ flex: 1.5 }}>
                                {
                                    props.outbound.legs.map((leg, index, array) => (
                                        <RenderFlightLegDetail
                                            key={index}
                                            leg={leg}
                                            leg_index={index}
                                            max_index={array.length - 1}
                                            stopover_duration={
                                                index < props.outbound.stopover_duration.length ?
                                                    props.outbound.stopover_duration[index] ?? null :
                                                    null
                                            }
                                            duration_days={props.outbound.duration_days}
                                            small
                                        />
                                    ))
                                }
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <CartFlightSegmentInfoPerPax
                                flight={props.flight}
                                outbound={props.outbound}
                            />
                        </Stack>
                    </Collapse>
                </td>
            </tr>
        </>
    );
}


type ManualFlightSegmentItemProps = {
    no: number,
    segment: ManualProductFlightCart['flight_segment'][number],
    nb_baggages: null | number
}

function ManualFlightSegmentItem(props: ManualFlightSegmentItemProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <tr>
            <td>
                <Box
                    src={
                        `https://storagefacilitatrip.blob.core.windows.net/iata-company/${props.segment.airline.airline_code}.png`
                    }
                    component="img"
                    sx={{
                        width: 100,
                        height: 30,
                        maxWidth: 100,
                        maxHeight: 30,
                        objectFit: 'contain'
                    }}
                />
            </td>
            <td>
                <Typography>
                    {t('global.flight')} {props.no}
                </Typography>
            </td>
            <td>
                <Typography>
                    {props.segment.airline.airline_code}-{props.segment.flight_number}
                </Typography>
            </td>
            <td>
                <Typography>
                    {window.moment.utc(props.segment.start_date).format('LLL')}
                </Typography>
            </td>
            <Box
                sx={{ textAlign: 'right' }}
                component="td"
            >
                <Typography>
                    {
                        (() => {
                            if (props.segment.origin_airport) {
                                return props.segment.origin_airport.iata_city.name;
                            } else if (props.segment.origin_station) {
                                return props.segment.origin_station.iata_city.name;
                            } else if (props.segment.origin_terminal) {
                                return props.segment.origin_terminal.iata_city.name;
                            }
                        })()
                    }
                    {' '}(
                    {
                        (() => {
                            if (props.segment.origin_airport) {
                                return props.segment.origin_airport.airport_code;
                            } else if (props.segment.origin_station) {
                                return props.segment.origin_station.station_code;
                            } else if (props.segment.origin_terminal) {
                                return props.segment.origin_terminal.terminal_code;
                            }
                        })()
                    }
                    )
                </Typography>
            </Box>
            <Box
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    textAlign: 'center'
                }}
                component="td"
            >
                <FlightOutlined
                    sx={{
                        transform: 'rotateZ(90deg)',
                        verticalAlign: 'bottom'
                    }}
                />
            </Box>
            <td>
                <Typography>
                    {
                        (() => {
                            if (props.segment.destination_airport) {
                                return props.segment.destination_airport.iata_city.name;
                            } else if (props.segment.destination_station) {
                                return props.segment.destination_station.iata_city.name;
                            } else if (props.segment.destination_terminal) {
                                return props.segment.destination_terminal.iata_city.name;
                            }
                        })()
                    }
                    {' '}(
                    {
                        (() => {
                            if (props.segment.destination_airport) {
                                return props.segment.destination_airport.airport_code;
                            } else if (props.segment.destination_station) {
                                return props.segment.destination_station.station_code;
                            } else if (props.segment.destination_terminal) {
                                return props.segment.destination_terminal.terminal_code;
                            }
                        })()
                    }
                    )
                </Typography>
            </td>
            <td>
                <Typography>
                    {window.moment.utc(props.segment.end_date).format('LLL')}
                </Typography>
            </td>
            <td>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Work
                        fontSize="large"
                        sx={
                            (props.nb_baggages ?? props.segment.nb_baggages ?? 0) === 0 ?
                                { color: 'gray' } :
                                { color: '#E6592F' }
                        }
                    />
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: 'calc(50% + 4px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#fff'
                        }}
                    >
                        {props.nb_baggages ?? props.segment.nb_baggages ?? 0}
                    </Typography>
                </Box>
            </td>
            <td>
                <Typography>
                    {props.segment.cabin_class}
                </Typography>
            </td>
        </tr>
    );
}

type MarginWrapperProps = {
    flight: Flight
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                !isProductPackaged({
                    product: props.flight,
                    stackInfos: trip?.stack_info ?? null
                })
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="flight"
                    item={props.flight}
                />
            </CardContent>
        </Collapse>
    );
}

type ManualMarginWrapperProps = {
    flight: ManualProductFlightCart
}

function ManualMarginWrapper(props: ManualMarginWrapperProps): JSX.Element {
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse in={seeAllProductsMargins}>
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="manual-flight"
                    item={props.flight}
                />
            </CardContent>
        </Collapse>
    );
}
