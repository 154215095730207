import { useDispatch, useSelector } from "react-redux";
import { WithRouterProps } from "react-router";
import { flatten, uniqBy } from "lodash";
import { usePackagedProducts } from "./packagedProducts";
import { useBookableProducts } from "./bookableProducts";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { ManualProductAccommodationCart } from "../../Itinerary/objects/manualProductAccommodationCart";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (page: 'selection' | 'book') => void

type Props = Pick<WithRouterProps, 'router'>

export function useGoToBooking(props: Props): Callback {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.user);
    const toBeBookedProducts = useSelector((state: AppState) => state.cart.to_book);
    const toBeBookedPackages = useSelector((state: AppState) => state.cartConstruction.bookablePackages);
    const packagedProducts = usePackagedProducts();
    const bookableProducts = useBookableProducts();

    return (page) => {
        if (page === 'selection' && user?.client_full?.type !== 2) {
            dispatch({ type: 'CART_SET_STEP', payload: { step: 4 } });
        } else {
            const packagesFromProducts = user?.client_full?.type === 2 ?
                uniqBy(
                    Object.keys(packagedProducts).map((key) => {
                        const stack_number = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                        const stack_info_id = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                        return { stack_number, stack_info_id };
                    }),
                    (item) => `${item.stack_number}-${item.stack_info_id}`
                ) :
                toBeBookedPackages;
            const packagesProducts = flatten(
                packagesFromProducts.map((packageItem) => {
                    const cart = packagedProducts[`${packageItem.stack_number ?? ''}-${packageItem.stack_info_id ?? ''}`];

                    if (cart) {
                        return ([] as { id: number }[]).concat(
                            flatten(
                                cart.accommodations.filter((item) => {
                                    return !item.accommodation.is_optional &&
                                        (
                                            item.type !== 'manual' ||
                                            user?.client_full?.type !== 2
                                        ) &&
                                        !item.accommodation.provider_refused;
                                }).map((item): (AccommodationCart | ManualProductAccommodationCart)[] => {
                                    if (item.type === 'normal') {
                                        return (item.accommodation.rooms ?? []).map((room) => {
                                            return {
                                                ...item.accommodation,
                                                ...room,
                                                is_manual: false
                                            };
                                        });
                                    }
                                    return (item.accommodation.rooms ?? []).map((room) => {
                                        return {
                                            ...item.accommodation,
                                            prices: item.accommodation.prices.map((price) => {
                                                return {
                                                    ...price,
                                                    purchase_price: (parseFloat(price.purchase_price) / (item.accommodation.rooms?.length ?? 1)).toString(),
                                                    selling_price: (parseFloat(price.purchase_price) / (item.accommodation.rooms?.length ?? 1)).toString()
                                                };
                                            }),
                                            id: room.id,
                                            product_id: item.accommodation.id,
                                            group_passenger: room.group_passenger,
                                            is_manual: true
                                        };
                                    });
                                })
                            )
                        ).concat(
                            cart.assistances.filter((item) => {
                                return !item.assistance.is_optional &&
                                    (
                                        item.type !== 'manual' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    !item.assistance.provider_refused;
                            }).map((item) => {
                                return item.assistance;
                            })
                        ).concat(
                            cart.cars.filter((item) => {
                                return !item.car.is_optional &&
                                    (
                                        item.type !== 'manual' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    !item.car.provider_refused;
                            }).map((item) => {
                                return item.car;
                            })
                        ).concat(
                            cart.flights.filter((item) => {
                                return !item.flight.is_optional &&
                                    (
                                        item.type !== 'manual' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    !item.flight.provider_refused;
                            }).map((item) => {
                                return item.flight;
                            })
                        ).concat(
                            cart.pois.filter((item) => {
                                return !item.poi.is_optional &&
                                    (
                                        item.type !== 'manual' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    !item.poi.provider_refused;
                            }).map((item) => {
                                return item.poi;
                            })
                        ).concat(
                            cart.transfers.filter((item) => {
                                return !item.transfer.is_optional &&
                                    (
                                        item.type !== 'manual' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    !item.transfer.provider_refused;
                            }).map((item) => {
                                return item.transfer;
                            })
                        );
                    }

                    return [];
                })
            );
            dispatch({
                type: 'CART_SET_TO_BOOK',
                payload: user?.client_full?.type !== 2 ?
                    ([] as { id: number }[]).concat(toBeBookedProducts ?? []).concat(packagesProducts) :
                    ([] as { id: number }[]).concat(
                        bookableProducts.filter((item) => {
                            return !item.is_optional &&
                                !item.poi_type;
                        })
                    ).concat(packagesProducts)
            });
            dispatch({ type: 'CART_SET_STEP', payload: { step: 4 } });
            props.router.push({
                pathname: `/${window.url_name}/apps/booking`
            });
        }
    };
}
