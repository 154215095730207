// Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel   from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
// Components
import SummaryHeader from './SummaryHeader';
import PlaneLoader from '../PlaneLoader/PlaneLoader.jsx';
// Functions
import { useGetTaxesPrice } from '../CartMaterial/utils/getTaxesPrice';
import GetTripInfo from './Functions/GetTripInfo';
import GetOutOfCart from './Functions/GetOutOfCart';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
// Actions
import { SetTripDocs } from '../../Actions/Menu';
import { SetSummaryStep } from '../../Actions/Summary';
import GetPrice from './Functions/GetPrice';

const useStyles = makeStyles(theme => ({
	absoluteCenter: {
		margin: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	lightGreen: {
		color: '#76B6C2 !important'
	},
	textCenter: {
		textAlign: 'center'
	},
	container: {
		marginTop: 70,
		paddingTop: 32
	},
	paper: {
		padding: 16
	},
	depositTitle: {
		fontSize: 20,
		fontWeight: 'bold'
	},
	orangeButton: {
		backgroundColor: '#E6592F',
		color: 'white'
	},
	genericText: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		color: '#0000008A'
	},
}));

const SummaryPaymentError = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [missingDocs, setMissingDocs] = useState(0);
	const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const trip_info = useSelector(store => store.summary.trip_info);
	const trip_main_info = useSelector(store => store.summary.trip_main_info);
	const trip_docs = useSelector(store => store.menu.trip_docs);
	const selectedProducts = useSelector(store => store.summary.selectedProducts);
	const itinerary = useSelector(store => store.summary.itinerary);
	const { headers } = CheckBeforeRequest();
	const getTaxesPrice = useGetTaxesPrice();

	useEffect(() => {
		dispatch(SetSummaryStep(7));
		setIsLoading(true);
		GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
	}, []);
	useEffect(() => {
		if (trip_main_info !== null) {
			getDoc();
			deletePayment();
		}
	}, [trip_main_info]);
	useEffect(() => {
		if (!isLoading) {
			GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch, getTaxesPrice);
		}
	}, [isLoading, getTaxesPrice]);
	useEffect(() => {
		if (trip_docs.length !== 0) {
			let total_requested = 0;
			let total_received = 0;
			trip_docs.map((document) =>{
				if(document.requested === true){
					total_requested += 1;
					if(document.received === true){
						total_received += 1;
					}
				}
			});
			let temp_missing_docs = total_requested - total_received;
			setMissingDocs(temp_missing_docs);
		}
	}, [trip_docs]);
	const getDoc = () => {
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	};
    const onRedirection = () => {
        props.router.push(`/${window.url_name}/summary-quotation/payment/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
    }
	const deletePayment = () => {
		axios({
			method: 'DELETE',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/payment-save/?code=${props.location.query.payment_code}`,
			headers: headers,
		}).then(function(response){
			console.log('response.data:', response.data);
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	}
	if (isLoading) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
	return (
		<Grid container style={{display: 'block'}}>
			<Grid item xs={ 12 }>
				<SummaryHeader missingDocs={ missingDocs } props={props}/>
			</Grid>
			<Grid item xs={ 12 }>
				<Container className={ classes.container } maxWidth={ 'lg' }>
					<Paper className={ classes.paper }>
						<Grid container spacing={ 2 }>
                            <Grid item xs={ 12 }>
								<Typography className={ classes.depositTitle } align={ 'center' }>{ t('summary-payment.payment-error') }</Typography>
							</Grid>
							<Grid item container justify={ 'flex-end' } xs={ 12 }>
								<Grid item>
									<Button className={ classes.orangeButton } onClick={ onRedirection }>{ t('summary-payment.payment-redirection') }</Button>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};

export default SummaryPaymentError;