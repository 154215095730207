import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Flight } from "../Itinerary/network/flight";
import { AppState } from "../../Reducers/Reducers";
import { flatten, uniqBy } from "lodash";
import { Traveler } from "../../Reducers/objects/Traveler";

type Props = {
    flight: Flight,
    outbound: Flight['outbounds'][number],
    searched_group?: any,
    flight_index: number,
    outbound_index: number
}

export function FlightSegmentInfoPerPax(props: Props): JSX.Element {
    const { t } = useTranslation();
    const currency = useSelector((state: AppState) => state.trip.currency);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const selected_seats = useSelector(store => store.flight_search.selected_seats);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
    const carryOnLuggages = props.outbound.legs[0]?.baggage?.filter((item) => {
        return item.is_carry_on;
    }).map((item) => {
        if (item.weight > 0) {
            return `x${item.quantity} ${item.weight}kg`;
        }
        return `x${item.quantity} PC`;
    });
    const checkLuggages = props.outbound.legs[0]?.baggage?.filter((item) => {
        return !item.is_carry_on;
    }).map((item) => {
        if (item.weight > 0) {
            return `x${item.quantity} ${item.weight}kg`;
        }
        return `x${item.quantity} PC`;
    }) ??
    (
        (props.outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0) > 0 ?
            [`x${props.outbound.legs[0]?.baggage_allowance?.number_of_pieces}`] :
            []
    );
    let travelers_order = traveler_groups.reduce(function (flattenedArray, element) {
        return flattenedArray.concat(element.travelers_order ? element.travelers_order : []);
    }, [] as number[]);
    let flightTravelers = props.flight.group_passenger ?
        props.flight.group_passenger?.travelers_list as Traveler[] | undefined :
        props.searched_group?.travelers as Traveler[] | undefined;
    flightTravelers = flightTravelers ?? [];
    const tripTravelers = [...travelers ?? []];

    if (travelers_order.length !== 0) {
        flightTravelers.sort((a, b) => {
            return travelers_order.indexOf(a.id) - travelers_order.indexOf(b.id);
        });
        tripTravelers.sort((a, b) => {
            return travelers_order.indexOf(a.id) - travelers_order.indexOf(b.id);
        });
    }
    return (
        <Box
            style={{
                flex: 1,
                marginRight: 3.5
            }}
        >
            {
                flightTravelers.map((traveler:Traveler) => {
                    const index = tripTravelers.findIndex((item) => {
                        return item.id === traveler.id;
                    });
                    const tickets = props.flight.issued_ticket?.filter((ticket) => {
                        return ticket.traveler === traveler.id;
                    });

                    let option_on_flight: string[] = [];
                    let option_on_group: string[] = [];

                    const paidOptions: Flight['outbounds'][number]['legs'][number]['paid_options'] = [];
                    props.outbound.legs.map((leg) => {
                        const options = leg.paid_options?.length !== undefined ? leg.paid_options?.filter((option) => {
                            return option.traveler === traveler.id;
                        }) : [];
                        if (options && options.length > 0) {
                            for (const option of options) {
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        paidOptions.push(option);
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        paidOptions.push(option);
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    paidOptions.push(option);
                                }
                            }
                        }
                    });

                    const seats = uniqBy(
                        flatten(
                            selected_seats.map((leg) => {
                                if (leg?.length !== undefined) {
                                    return leg?.filter((seat) => {
                                        return seat.traveler === traveler.id;
                                    }) ?? [];
                                }
                            })
                        ),
                        (seat) => seat?.seat_number
                    );
                    return (
                        <Accordion key={index} style={{transition: 'none'}}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography>
                                    {
                                        t(
                                            'cart-material.cart-construction-traveler-no',
                                            { no: index + 1 }
                                        )
                                    }
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    color="GrayText"
                                    component="div"
                                >
                                    {t('cart-material.cart-construction-traveler-info-header')}
                                </Typography>
                                <Typography
                                    color="InfoText"
                                    component="div"
                                >
                                    {traveler.title} / {traveler.first_name} / {traveler.last_name}
                                </Typography>
                                <Typography
                                    color="GrayText"
                                    component="div"
                                >
                                    {
                                        t(
                                            'cart-material.cart-construction-traveler-birthdate',
                                            {
                                                date: window.moment.utc(traveler.birth_date, 'YYYY-MM-DD').format('L')
                                            }
                                        )
                                    }
                                </Typography>
                                <Stack style={{ marginTop: 1.5 }}>
                                    {
                                        (tickets?.length ?? 0) > 0 &&
                                        <Typography
                                            color="GrayText"
                                            component="div"
                                            style={{
                                                display: 'flex',
                                                gap: 1.5
                                            }}
                                        >
                                            <span>
                                                {t('cart-material.cart-construction-tickets')}
                                            </span>
                                            <Box
                                                style={{
                                                    listStylePosition: 'inside',
                                                    paddingLeft: 0
                                                }}
                                                component="ul"
                                            >
                                                {
                                                    tickets?.map((ticket) => (
                                                        <li key={ticket.ticket_number}>
                                                            {
                                                                t(
                                                                    'cart-material.cart-construction-ticket-item',
                                                                    {
                                                                        type: ticket.ticket_type,
                                                                        status: ticket.status,
                                                                        ref: ticket.ticket_number
                                                                    }
                                                                )
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </Box>
                                        </Typography>
                                    }
                                    <Typography
                                        color="GrayText"
                                        component="div"
                                        style={{
                                            display: 'flex',
                                            gap: 1.5
                                        }}
                                    >
                                        <span>
                                            {t("global.luggages_included")}
                                        </span>
                                        <Box
                                            style={{
                                                listStylePosition: 'inside',
                                                paddingLeft: 0
                                            }}
                                            component="ul"
                                        >
                                            <li>
                                                {
                                                    t(
                                                        'cart-material.cart-construction-luggage-carry-on',
                                                        {
                                                            details: (carryOnLuggages?.length ?? 0) > 0 ?
                                                                carryOnLuggages?.join(', ') :
                                                                t('flight_search.no_luggage')
                                                        }
                                                    )
                                                }
                                            </li>
                                            <li>
                                                {
                                                    t(
                                                        'cart-material.cart-construction-luggage-check',
                                                        {
                                                            details: checkLuggages.length > 0 ?
                                                                checkLuggages.join(', ') :
                                                                t('flight_search.no_luggage')
                                                        }
                                                    )
                                                }
                                            </li>
                                        </Box>
                                    </Typography>
                                    <div>
                                        {
                                            paidOptions.map((option, index) => (
                                                <Typography
                                                    key={`${option.key_optional_service}-${option.traveler}`}
                                                    color="GrayText"
                                                    component="div"
                                                >
                                                    {
                                                        t(
                                                            'cart-material.cart-construction-flight-option',
                                                            {
                                                                no: index + 1,
                                                                description: option.description,
                                                                price: new Intl.NumberFormat(
                                                                    language,
                                                                    {
                                                                        style: 'currency', 
                                                                        currency: currency?.iso_code ?? 'EUR'
                                                                    }
                                                                ).format(parseFloat(option.price))
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                            ))
                                        }
                                    </div>
                                    <div>
                                        {
                                            seats?.map((seat) => {
                                                if (seat !== undefined) {
                                                    return (
                                                        <Typography
                                                            key={seat.seat_number}
                                                            color="GrayText"
                                                            component="div"
                                                        >
                                                            {
                                                                t(
                                                                    'cart-material.cart-construction-flight-seat',
                                                                    {
                                                                        no: seat.seat_number,
                                                                        price: new Intl.NumberFormat(
                                                                            language,
                                                                            {
                                                                                style: 'currency', 
                                                                                currency: currency?.iso_code ?? 'EUR'
                                                                            }
                                                                        ).format(parseFloat(seat.price))
                                                                    }
                                                                )
                                                            }
                                                        </Typography>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
        </Box>
    );
}
