/* eslint-disable no-nested-ternary */
/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';

import '../../crm.css';
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, LocalizationProvider, StaticDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { IconButton, makeStyles } from "@material-ui/core";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { countriesPhone } from "../../objects/data";
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, FormHelperText } from "@mui/material";
import TravelerFormCountry from "../CrmCustomerCard/TravelerFormCountry";

import CheckBeforeRequest from "../../../../Common/CheckBeforeRequest";
import { useSnackbar } from "notistack";
import GenericField from "../../utils/GenericField";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    formControlRoot: {
        "width": "100%",
        "& .MuiOutlinedInput-input": {
            padding: '8px 14px'
        },
        "&& .MuiSvgIcon-root": {
            color: "#757575"
        },
        "& .MuiFormLabel-root": {
            fontWeight: 900,
            fontSize: 14,
            color: '#757575'
        },
        "&& .MuiInputLabel-outlined": {
            transform: "translate(14px, 11px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        }
    },
    formControlRootRed: {
        "width": "100%",
        "& .MuiOutlinedInput-input": {
            padding: '8px 14px;'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .MuiSvgIcon-root": {
            color: "#757575"
        },
        "& .MuiFormLabel-root": {
            fontWeight: 900,
            fontSize: 14,
            color: '#757575'
        },
        "&& .MuiInputLabel-outlined": {
            transform: "translate(14px, 11px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        }
    },
    formControlRootMobile: {
        "width": "100%",
        "& .MuiOutlinedInput-input": {
        },
        "& .MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 17,
            backgroundColor: "white",
            padding: "0 12px 0 2px",
            color: "#757575"
        },
        "& .MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 17,
            backgroundColor: "white",
            padding: "0 12px 0 2px",
            marginTop: 0
        }
    },
    formControlRootMobileRed: {
        "width": "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .MuiSvgIcon-root": {
            color: "#757575"
        },
        "& .MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 17,
            backgroundColor: "white",
            padding: "0 12px 0 2px",
            color: "#757575"
        },
        "& .MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 17,
            backgroundColor: "white",
            padding: "0 12px 0 2px",
            marginTop: 0
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput: {
        padding: "7px 30px 7px 20px;"
    },
    circleBorder: {
        borderRadius: 8
    },
    blue: {
        color: "#757575"
    },
    errorInfoRight: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        textAlign: "right",
        fontSize: 10
    },
    errorInfoMobile: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        textAlign: "center",
        fontSize: 10
    },
    errorInfo: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
    green: {
    },
    showStaticDatepicker: {
        "display": "block",
        "& >:first-child>:first-child": {
            marginLeft: -18
        }
    },
    hideStaticDatepicker: {
        display: "none"
    }
}));

const CrmUserDetailTravelerFormDialog = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    const [traveler, setTraveler] = useState({ loyalty_cards: [], vaccins: [], additional_phone_number: [], additional_emails: [] });
    const [show_more, setShowMore] = useState(false);
    const [clickLoading, setClickLoading] = useState(false);
    const [flightSupplierInput, setFlightSupplierInput] = useState("");
    const [flightSupplierResults, setFlightSupplierResults] = useState([]);

    const required_fields = ["first_name", "last_name", "birth_date", "title"];
    if (props.mode === 'user') {
        required_fields.push("phone_number", "email");
    }

    useEffect(() => {
        //setTraveler(trav_test);
        if (props.mode === 'user') {
            setTraveler({ ...selected_user, postal_code: selected_user.zip_code, address: selected_user.street });
        }
    }, [selected_user]);

    useEffect(() => {
        setClickLoading(false);
    }, [props.open]);

    const handleClose = () => {
        setTraveler({ loyalty_cards: [], vaccins: [] });
        props.handleClose();
    };

    const updateTraveler = (field, value) => {
        const traveler_c = { ...traveler };
        const fields = field?.split('.');
        switch (fields.length) {
            case 1:
                traveler_c[fields[0]] = value;
                break;
            case 2:
                traveler_c[fields[0]][fields[1]] = value;
                break;
            case 3:
                traveler_c[fields[0]][fields[1]][fields[2]] = value;
                break;
            default:
                break;
        }
        //handle required fields manually here
        if (required_fields.includes(field) && !value) {
            traveler_c[field + "_error"] = `ce champ ne peut être vide`;
        } else {
            traveler_c[field + "_error"] = null;
        }
        setTraveler(traveler_c);
    };

    const getPhoneCode = () => {
        const defaultLanguage = navigator.language;
        const locale = new Intl.Locale(defaultLanguage);
        const countryCode = locale.region ? locale.region : locale.language?.slice(-2)?.toLocaleUpperCase();
        const found = countriesPhone.find(e => e.code === countryCode);
        if (found) {
            return found.dial_code;
        }
        return "+1";
    };

    const handleTitleChange = (event) => {
        updateTraveler('title', event.target.value);
    };

    const selectType = (event) => {
        updateTraveler('identity_doc', event.target.value);
    };

    const onExpirationDateChange = (date) => {
        updateTraveler('identity_doc_expiration', date?.format("YYYY-MM-DD"));
    };

    const handleBirthDate = (date) => {
        updateTraveler('birth_date', date?.format("YYYY-MM-DD"));
    };

    const onVaccinExpirationDateChange = (index, date) => {
        updateTraveler(`vaccins.${index}.valid_until`, date?.format("YYYY-MM-DD"));
    };

    const showMore = () => {
        setShowMore(!show_more);
    };

    const addCard = () => {
        let traveler_copy = JSON.parse(JSON.stringify(traveler));
        if (!traveler_copy.loyalty_cards?.length) {
            traveler_copy.loyalty_cards = [];
        }
        traveler_copy.loyalty_cards.push({});
        setTraveler(traveler_copy);
    };

    const deleteCard = (index) => {
        let traveler_copy = JSON.parse(JSON.stringify(traveler));
        if (!traveler_copy.loyalty_cards?.length) {
            traveler_copy.loyalty_cards = [];
        }
        traveler_copy.loyalty_cards.splice(index, 1);
        setTraveler(traveler_copy);
    };

    const addVaccin = () => {
        let traveler_copy = JSON.parse(JSON.stringify(traveler));
        if (!traveler_copy.vaccins?.length) {
            traveler_copy.vaccins = [];
        }
        traveler_copy.vaccins.push({});
        setTraveler(traveler_copy);
    };

    const deleteVaccin = (index) => {
        let traveler_copy = JSON.parse(JSON.stringify(traveler));
        if (!traveler_copy.vaccins?.length) {
            traveler_copy.vaccins = [];
        }
        traveler_copy.vaccins.splice(index, 1);
        setTraveler(traveler_copy);
    };

    /**DATEPICKER*/
    const [openPicker, setOpenPicker] = useState(null);
    const handleOpenPicker = (id) => {
        setOpenPicker(id);
    };
    const handleClosePicker = () => {
        setOpenPicker(null);
    };
    const handleClickAway = (type) => {
        if (type === openPicker) {
            setOpenPicker(null);
        }
    };

    useEffect(() => {
        if (flightSupplierInput.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}iata-airlines/?search=${flightSupplierInput}`
                }).then(function (response) {
                    let data = response.data.results;
                    if (data.length > 0) {
                        data.push("")
                        setFlightSupplierResults(data);
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [flightSupplierInput]);

    const confirm = () => {
        let valid = true;
        let traveler_c = JSON.parse(JSON.stringify(traveler));
        console.log("traveler_c", traveler_c);
        if (!traveler_c.first_name) {
            traveler_c.first_name_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("global.first_name")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!traveler_c.last_name) {
            traveler_c.last_name_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("global.last_name")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!traveler_c.title) {
            traveler_c.title_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("crm.relationship.title")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!traveler_c.birth_date) {
            traveler_c.birth_date_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("menu.trip_list.birthdate")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!traveler_c.phone_number && props.mode === "user") {
            traveler_c.phone_number_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("crm.traveler_validation.phone")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!traveler_c.email && props.mode === "user") {
            traveler_c.email_error = t('crm.field_required_error');
            enqueueSnackbar(`${t("crm.traveler_validation.email")} : ${t('crm.field_required_error')}`, { variant: "error" });
            valid = false;
        }
        if (!valid) {
            setTraveler(traveler_c);
            return;
        }
        if (props.onConfirm !== undefined) {
            const traveler_clean = {
                loyalty_cards: traveler_c.loyalty_cards,
                vaccins: traveler_c.vaccins,
                title: traveler_c.title,
                first_name: traveler_c.first_name,
                last_name: traveler_c.last_name,
                birth_date: traveler_c.birth_date,
                wedding_date: traveler_c.wedding_date,
                address: traveler_c.address,
                address_detail: traveler_c.address_detail,
                postal_code: traveler_c.postal_code,
                city: traveler_c.city,
                country: traveler_c.country?.id,
                phone_number: traveler_c.phone_number,
                additional_emails: traveler_c.additional_emails,
                additional_phone_number: traveler_c.additional_phone_number,
                email: traveler_c.email,
                identity_doc: traveler_c.identity_doc,
                identity_doc_number: traveler_c.identity_doc_number,
                identity_doc_expiration: traveler_c.identity_doc_expiration,
                identity_doc_country_emission: traveler_c.identity_doc_country_emission?.id,
                traveler_relationship: traveler_c.traveler_relationship
            };
            setClickLoading(true);
            props.onConfirm(traveler_clean);
            setTraveler({ loyalty_cards: [], vaccins: [] });
        }
    };

    return (
        <Dialog open={props.open} maxWidth="lg" className="create-contact-dialog">
            <div className="close-div" onClick={handleClose}>
                <CloseIcon />
            </div>
            <DialogTitle>{props.title ? props.title : (props.mode === "companion" ? t('crm.create_companion') : t('crm.edit_user_info'))}</DialogTitle>
            <Grid container className="create-companion" spacing={1}>
                {
                    props.mode === 'companion' &&
                    <Grid item xs={12}>
                        <FormControl error={traveler.traveler_relationship_error} style={{ width: 300 }}>
                            <InputLabel>{t('crm.relationship.title')}*</InputLabel>
                            <Select
                                error={traveler.traveler_relationship_error}
                                label={t('crm.relationship.title') + '*'}
                                onChange={(event) => updateTraveler('traveler_relationship', event.target.value)}
                                value={traveler.traveler_relationship ? traveler.traveler_relationship : 'FAMILY'}
                            >
                                <MenuItem value={'FRIEND'}>{t('crm.relationship.friend')}</MenuItem>
                                <MenuItem value={'FAMILY'}>{t('crm.relationship.family')}</MenuItem>
                                <MenuItem value={'COUPLE'}>{t('crm.relationship.couple')}</MenuItem>
                                <MenuItem value={'ALONE'}>{t('crm.relationship.alone')}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormHelperText style={{ color: "red" }}>{traveler.title_error}</FormHelperText>
                    </Grid>

                }
                <Grid item xs={1.5}>
                    <FormControl fullWidth error={traveler.title_error}>
                        <InputLabel>{t('global.title')}*</InputLabel>
                        <Select
                            error={traveler.title_error}
                            label={t('global.title') + '*'}
                            onChange={handleTitleChange}
                            value={traveler.title}
                        >
                            <MenuItem value={'MR'}>{t('title_value.mr')}</MenuItem>
                            <MenuItem value={'MRS'}>{t('title_value.mrs')}</MenuItem>
                            <MenuItem value={'MISS'}>{t('title_value.miss')}</MenuItem>
                            {/* <MenuItem value={'MX'}>{t('title_value.mx')}</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormHelperText style={{ color: "red" }}>{traveler.title_error}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <GenericField label={t("global.first_name")} object={traveler} onChange={updateTraveler} field={'first_name'} required={true} />
                </Grid>
                <Grid item xs={3.5}>
                    <GenericField label={t("global.last_name")} object={traveler} onChange={updateTraveler} field={"last_name"} required={true} />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={'fr'}>
                        <ClickAwayListener onClickAway={() => handleClickAway('birthdate')}>
                            <Grid>
                                <DatePicker
                                    label={t("menu.trip_list.birthdate") + '*'}
                                    value={moment(new Date(traveler.birth_date ? traveler.birth_date : '1970-01-01'), "YYYY-MM-DD")}
                                    onChange={(newValue) => handleBirthDate(newValue)}
                                    minDate={new Date('1910-01-01')}
                                    maxDate={new Date()}
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                    open={openPicker === 'birthdate'}
                                    onOpen={() => handleOpenPicker('birthdate')}
                                    onClose={handleClosePicker}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant={"outlined"}
                                            helperText={traveler.birth_date_error}
                                            error={traveler.birth_date_error}
                                            fullWidth={true}
                                        />
                                    )}
                                />
                            </Grid>
                        </ClickAwayListener>
                    </LocalizationProvider>
                </Grid>
                {
                    props.mode === "user" &&
                    <Grid item xs={12} container>
                        <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={'fr'}>
                            <ClickAwayListener onClickAway={() => handleClickAway('wedding_date')}>
                                <Grid item xs={3}>
                                    <DatePicker
                                        label={t("crm.wedding_date")}
                                        value={traveler.wedding_date ? moment(new Date(traveler.wedding_date), "YYYY-MM-DD") : null}
                                        onChange={(newValue) => updateTraveler("wedding_date", newValue?.format("YYYY-MM-DD"))}
                                        minDate={new Date('1910-01-01')}
                                        maxDate={new Date()}
                                        views={["year", "month", "date"]}
                                        openTo="year"
                                        open={openPicker === 'wedding_date'}
                                        onOpen={() => {
                                            handleOpenPicker('wedding_date');
                                            updateTraveler("wedding_date", traveler.wedding_date ? traveler.wedding_date : '1990-01-01');
                                        }}
                                        fullWidth
                                        onClose={handleClosePicker}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                fullWidth
                                                variant={"outlined"}
                                                helperText={traveler.wedding_date_error}
                                                error={traveler.wedding_date_error}
                                            />
                                        )}
                                    />
                                </Grid>
                            </ClickAwayListener>
                        </LocalizationProvider>
                    </Grid>
                }
                <Grid xs={8} item>
                    <GenericField label={t('crm.traveler_validation.address')} onChange={updateTraveler} object={traveler} field={'address'} />
                </Grid>
                <Grid xs={4} item>
                    <GenericField label={t('crm.traveler_validation.address_detail')} onChange={updateTraveler} object={traveler} field={'address_detail'} />
                </Grid>
                <Grid xs={3} item>
                    <GenericField label={t('crm.traveler_validation.postal_code')} onChange={updateTraveler} object={traveler} field={'postal_code'} />
                </Grid>
                <Grid xs={5} item>
                    <GenericField label={t('crm.traveler_validation.city')} onChange={updateTraveler} object={traveler} field={'city'} />
                </Grid>
                <Grid xs={4} item>
                    <TravelerFormCountry onChange={updateTraveler} traveler={traveler} />
                </Grid>
                <Grid xs={4} item>
                    <MaterialUiPhoneNumber
                        fullWidth
                        type={'text'} label={t('crm.traveler_validation.phone') + (props.mode === "user" ? "*" : "")}
                        onChange={(value) => updateTraveler("phone_number", value)}
                        value={traveler.phone_number ? traveler.phone_number : getPhoneCode()}
                        variant="outlined"
                        disableAreaCodes
                        helperText={`${t('crm.traveler_validation.example')}: +33 6 12 34 56 78`}
                        error={traveler.phone_number_error}
                    />
                </Grid>
                <Grid xs={8} item>
                    <GenericField label={t('crm.traveler_validation.email')} object={traveler} required={props.mode === "user"} onChange={updateTraveler} field={"email"} type={"email"} />
                </Grid>

                {
                    show_more &&
                    <>
                        <Grid xs={3} item>
                            <FormControl fullWidth>
                                <InputLabel>{t('crm.traveler_validation.document.type')}</InputLabel>
                                <Select
                                    value={traveler.identity_doc}
                                    label={t('crm.traveler_validation.document.type')}
                                    onChange={selectType}
                                >
                                    <MenuItem value={'PASSPORT'}>{t('crm.traveler_validation.document.passport')}</MenuItem>
                                    <MenuItem value={'CIN'}>{t('crm.traveler_validation.document.id_card')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={3} item>
                            <GenericField label={t('crm.traveler_validation.document.number')} object={traveler} onChange={updateTraveler} field={'identity_doc_number'} />
                        </Grid>
                        <Grid xs={3} item>
                            <TravelerFormCountry doc_mode={true} onChange={updateTraveler} traveler={traveler} />
                        </Grid>
                        <Grid xs={3} item>
                            <FormControl fullWidth>
                                <GenericField label={t('crm.traveler_validation.document.expiration')} field={"identity_doc_expiration"}
                                    object={traveler} onChange={updateTraveler} type="datepicker" />
                            </FormControl>
                        </Grid>

                        {
                            traveler?.vaccins?.map((card, index) => {
                                return (
                                    <>
                                        <Grid xs={5.5} item>
                                            <GenericField label={t("crm.traveler_validation.vaccin_name")} object={traveler} onChange={updateTraveler} field={`vaccins.${index}.name`} />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <GenericField label={t('crm.traveler_validation.vaccin_date')} field={`vaccins.${index}.valid_until`}
                                                object={traveler} onChange={updateTraveler} type="datepicker" />
                                        </Grid>
                                        <Grid xs={0.5} item style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                            <IconButton size="small">
                                                <DeleteOutlinedIcon onClick={() => deleteVaccin(index)} style={{ color: "black" }} />
                                            </IconButton>
                                        </Grid>
                                    </>
                                )
                            })
                        }

                        {
                            traveler?.loyalty_cards?.map((card, index) => {
                                return (
                                    <>
                                        <Grid xs={3.5} item>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('menu.trip_list.card_type')}</InputLabel>
                                                <Select
                                                    value={traveler.loyalty_cards[index].product_type}
                                                    onChange={(event) => {
                                                        updateTraveler(`loyalty_cards.${index}.product_type`, event.target.value);
                                                    }}
                                                    label={t('menu.trip_list.card_type')}
                                                >
                                                    <MenuItem value={0}>{t("global.hotel")}</MenuItem>
                                                    <MenuItem value={2}>{t("providers.rental_car")}</MenuItem>
                                                    <MenuItem value={4}>{t("providers.transfers")}</MenuItem>
                                                    <MenuItem value={5}>{t("providers.trains")}</MenuItem>
                                                    <MenuItem value={6}>{t("global.flight")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={4} item>
                                            <GenericField label={t("menu.trip_list.card_supplier")} object={traveler} onChange={updateTraveler} field={`loyalty_cards.${index}.supplier`} />
                                        </Grid>
                                        <Grid xs={4} item>
                                            <GenericField label={t("menu.trip_list.id_doc_number_short")} object={traveler} onChange={updateTraveler} field={`loyalty_cards.${index}.card_number`} />
                                        </Grid>
                                        <Grid xs={0.5} item style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                            <IconButton size="small">
                                                <DeleteOutlinedIcon onClick={() => deleteCard(index)} style={{ color: "black" }} />
                                            </IconButton>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </>
                }

                {
                    props.mode === 'companion' &&
                    <Grid xs={12} item style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", gap: 10 }}>
                            <Button variant="text" onClick={addCard} style={{ display: show_more ? "block" : "none" }}>
                                {t('menu.trip_list.add_card')}
                            </Button>
                            <Button variant="text" onClick={addVaccin} style={{ display: show_more ? "block" : "none" }}>
                                +{t('crm.traveler_validation.add_vaccin')}
                            </Button>
                        </div>
                        <Button variant="text" startIcon={show_more ? <RemoveIcon /> : <AddIcon />} onClick={showMore}
                            style={{ width: "6em" }}>
                            INFO
                        </Button>
                    </Grid>
                }

                <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, width: "-webkit-fill-available" }}>
                    <Button variant="outlined" onClick={props.handleClose}>{t("crm.cancel")}</Button>
                    <LoadingButton loading={clickLoading} variant="contained" onClick={confirm}>{t("crm.save")}</LoadingButton>
                </div>
            </Grid>
        </Dialog>
    );
};
export default CrmUserDetailTravelerFormDialog;
