import { flatten, uniq } from "lodash";
import { transformItineraryContentToDestinationGroups } from "./transformItineraryContentToDestinationGroups";
import { transformItineraryToDestinationGroups } from "./transformItineraryToDestinationGroups";
import { ItineraryByDay } from "../objects/itineraryByDay";
import { Destination } from "../objects/destination";

type Options = {
    stepGroup: ReturnType<typeof transformItineraryToDestinationGroups>[number],
    contentGroup: ReturnType<typeof transformItineraryContentToDestinationGroups<ItineraryByDay>>[number],
    destinationsData: Destination[]
}

export function transformItineraryGroupsToContent(
    options: Options
): ItineraryByDay[] {
    if (options.contentGroup.length === 0) {
        return [];
    }

    const result: ItineraryByDay[] = [];

    // if there are multiple destinations, we can directly return it
    const destinations = uniq(
        options.stepGroup.map((step) => {
            return step.destination;
        })
    );

    if (destinations.length > 1) {
        result.push({
            ...options.contentGroup[0]!.data,
            destinations: options.stepGroup.map((group) => {
                return options.destinationsData.find((destination) => {
                    return destination.id === group.destination;
                });
            }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
            circuit: options.stepGroup[options.stepGroup.length - 1]?.circuit ?? null,
            circuit_trip_version: options.stepGroup[options.stepGroup.length - 1]?.circuit_trip_version ?? null,
            iti_type: options.stepGroup[options.stepGroup.length - 1]?.iti_type ?? null,
            day: options.stepGroup[0]!.days
        });
        return result;
    }

    const totalDays = flatten(
        options.stepGroup.map((step) => {
            return step.days;
        })
    );
    const totalContentDays = flatten(
        options.contentGroup.map((content) => {
            return content.days;
        })
    );
    const contentDaysCount = totalContentDays.length;
    const daysDiff = totalDays.length - contentDaysCount;

    // if it is a single destination case
    if (options.stepGroup.length === 1) {
        if (contentDaysCount < totalDays.length) {
            for (let i = 0; i < options.contentGroup.length; i++) {
                const item = options.contentGroup[i]!;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = item.data;
                result.push({
                    ...data,
                    destinations: options.stepGroup.map((group) => {
                        return options.destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    circuit: options.stepGroup[0]!.circuit,
                    circuit_trip_version: options.stepGroup[0]!.circuit_trip_version,
                    iti_type: options.stepGroup[0]!.iti_type,
                    day: i === options.contentGroup.length - 1 ?
                        new Array(item.days.length + daysDiff).fill(0) :
                        item.days
                });
            }
        } else if (contentDaysCount > totalDays.length) {
            let diff = Math.abs(daysDiff);
            let contents = options.contentGroup.map((content) => {
                return content.data;
            });
            contents = [...contents].reverse().map((item) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = item;
                const result = {
                    ...data,
                    destinations: options.stepGroup.map((group) => {
                        return options.destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    circuit: options.stepGroup[0]!.circuit,
                    circuit_trip_version: options.stepGroup[0]!.circuit_trip_version,
                    iti_type: options.stepGroup[0]!.iti_type,
                    day: new Array(
                        diff >= item.day.length ?
                            0 :
                            item.day.length - diff
                    ).fill(0)
                };
                diff -= diff >= item.day.length ?
                    item.day.length :
                    0;
                return result;
            });
            contents = contents.filter((item) => {
                return item.day.length > 0;
            });
            for (const content of contents) {
                result.push(content);
            }
        } else {
            for (let i = 0; i < options.contentGroup.length; i++) {
                const content = options.contentGroup[i]!;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = content.data;
                result.push({
                    ...data,
                    destinations: options.stepGroup.map((group) => {
                        return options.destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    day: content.days,
                    circuit: options.stepGroup[0]!.circuit,
                    circuit_trip_version: options.stepGroup[0]!.circuit_trip_version,
                    iti_type: options.stepGroup[0]!.iti_type
                });
            }
        }
        return result.filter((item) => {
            return item.day.length > 0;
        });
    }

    return result;
}
