import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { FlashGetDestination } from "../objects/flashGetDestination";

async function get(destinationId: number): Promise<FlashGetDestination | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<FlashGetDestination>(
            `${API_HREF}client/${window.id_owner}/destinations/flash_get/`,
            {
                headers,
                params: {
                    id: destinationId
                }
            }
        );
        return response.data;
    }

    return null;
}

export const getFlashDestination = memoizee(
    get,
    { promise: true, primitive: true }
);
