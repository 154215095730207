//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { isNumber } from 'lodash';
//Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
//Mui
import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { SecurityOutlined, SupportAgentOutlined } from '@mui/icons-material';
//Icons
import Close from '@material-ui/icons/Close';
import Restaurant from '@material-ui/icons/Restaurant';
import HelpOutline from '@material-ui/icons/HelpOutline';
import RoomService from '@material-ui/icons/RoomService';
import LocalBar from '@mui/icons-material';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import FreeBreakfastOutlined from '@material-ui/icons/FreeBreakfastOutlined';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';
import { CartRoomDetailsModal } from "./CartRoomDetailsModal";
//Commons
import { CartConstructionReplaceProductModal } from './CartConstructionReplaceProductModal';
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import Tooltip from "@material-ui/core/Tooltip";
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { isProductPackaged } from "./utils/isProductPackaged";
import Timer from '@material-ui/icons/Timer';
import TimerOff from '@material-ui/icons/TimerOff';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { CiMoneyCheck1 } from 'react-icons/ci';
import IconButton from '@material-ui/core/IconButton';
import { ProviderContext } from './utils/providerContext';

const useStyles = makeStyles({
    divider: {
        marginTop: '15px !important',
        marginBottom: '15px !important'
    },
    container: {
        justifyContent: 'space-between'
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        /*width: 100 */
    },
    delete: {
        display: 'block',
        marginLeft: 'auto',
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    taxesInfo: {
        cursor: 'pointer',
        position: 'absolute',
        marginLeft: 8,
        fontSize: 18,
        marginTop: 2
    },
    prices: {
        textAlign: 'right'
    },
    onRequest: {
        color: '#ff9800'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    priceDetail: {
        textDecoration: 'underline',
        textAlign: 'right',
        cursor: 'pointer',
        fontSize: 12
    },
    replace: {
        textDecoration: 'underline',
        pointer: 'cursor',
        textAlign: 'right',
        cursor: 'pointer',
        fontSize: 12,
        display: 'inline',
        marginLeft: 5
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '0px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        textTransform: 'uppercase'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    alertIcon: {
        alignItems: 'center'
    },
    floatLeft: {
        float: 'left'
    },
    marginRightSmall: {
        marginRight: 2
    },
    marginRight: {
        marginRight: 4
    },
    smallText: {
        fontSize: "12px !important"
    },
    inline: {
        display: 'inline-block',
        verticalAlign: 'super'
    },
    checkInInfo: {
        cursor: 'pointer',
        float: 'right',
        marginLeft: 8,
        fontSize: 12,
        marginTop: 2
    },
    smallIcon: {
        fontSize: 12,
        marginTop: 2
    },
    icon: {
        fontSize: 18,
        marginTop: 2
    }
});

const CartRoom = ({ room, room_index, router, provider, showProduct, accommodation }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const user = useSelector(state => state.user.user);
    const end_date = useSelector(state => state.trip.end_date);
    const currency = useSelector(state => state.trip.currency);
    const trip = useSelector(state => state.trip.data_trip);
    const currency_list = useSelector(state => state.base.currency_list);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const see_new_price = useSelector(state => state.cart.see_new_price);
    const itinerary_type_details_data = useSelector((state) => state.itinerary_type.itinerary_type_details_data);

    const replace_id = useSelector(state => state.accommodation.replace_id);
    const [price, setPrice] = useState(null);
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [refreshStatusRunning, setRefreshStatusRunning] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openReplaceModal, setOpenReplaceModal] = useState(false);
    const [feesCurrency, setFeesCurrency] = useState(null);
    const [checkInOpen, setCheckInOpen] = useState(false);
    const [taxesInfoOpen, setTaxesInfoOpen] = useState(false);
    const status = useMemo(() => {
        let status = 'DEFAULT';

        if (accommodation.is_optional) {
            status = 'OPTION';
        } else if (accommodation.is_hidden_for_traveler) {
            status = 'HIDE';
        } else if (accommodation.is_hidden_for_traveler_count_price) {
            status = 'HIDE_COUNT';
        } else if (accommodation.variant !== null && itinerary_type_details_data !== null && itinerary_type_details_data.variant_name !== null) {
            status = 'VARIANT';
        }
        return status;
    }, [accommodation, itinerary_type_details_data]);
    const providerContext = useContext(ProviderContext);

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const showCancellationDetail = () => {
        dispatch({ type: 'CART_TOGGLE_CANCELLATION_DETAIL', payload: { room: room, currency: price.currency } });
    };
    const onCheckInInfo = () => {
        setCheckInOpen((prevState) => !prevState);
    };
    const onTaxesInfo = () => {
        setTaxesInfoOpen((prevState) => !prevState);
    };
    const onRetryBooking = () => {
        dispatch({ type: 'CART_SET_STEP', payload: { step: 3 } });
    };
    const onDeleteRoom = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
        }).then(() => {
            dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
            enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${t('cart-material.room-not-deleted-2')}`, { variant: 'danger' });
        });
    };
    const showPriceDetail = () => {
        dispatch({ type: 'CART_TOGGLE_PRICE_DETAIL', payload: { ...room, currency: price.currency } });
    };
    const replaceRoom = () => {
        itinerary_list.map(step => {
            if (step.destination !== null && room.start_destination !== null && step.destination.id === room.start_destination.id) {
                const bounds = JSON.parse(step.destination.data.bounds.replace(/'/g, '"'));
                dispatch({
                    type: "MATERIAL_SET_ACTIVE_DESTINATION",
                    payload: {
                        active: step.id,
                        bounds: {
                            north: bounds.north,
                            east: bounds.east,
                            south: bounds.south,
                            west: bounds.west
                        },
                        zoom: step.destination.data.zoom_level,
                        start_date: step.start_date.split('T')[0],
                        end_date: step.end_date.split('T')[0],
                        destination: step,
                        reset: true
                    }
                });
            }
        });
        setOpenReplaceModal(true);
        dispatch({ type: 'ACCOMMODATION_REPLACE_ROOM', payload: room });
    };
    const priceToDisplay = price => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };
    const getPrice = prices => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };
    const confirmRoomRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
            data: {
                before_recalc_description: null
            }
        }).then(response => {
            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };
    const handleSeeNewPrice = () => {
        dispatch({
            type: 'CART_SEE_NEW_PRICE_MODAL',
            payload: {
                see_new_price: !see_new_price,
                data: see_new_price ? null : {
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    id: room.id,
                    old_price: room.prices[GetPricesId(room.prices, user.client, user, true)],
                    new_price: room.provider_wanted_price,
                    product: room,
                    custom_dispatch: 'ACCOMMODATION_EDIT_CART_BY_ID'
                }
            }
        });
    };
    useEffect(() => {
        let tmp_price = getPrice(room.prices);
        let tmp_adults = 0;
        let tmp_children = 0;
        let tmp_master_price = room.prices.find(price => price.master_price);
        if (tmp_master_price) {
            let tmp_currency = currency_list.find(currency => currency.id === tmp_master_price.purchase_currency);
            if (tmp_currency) {
                setFeesCurrency(tmp_currency);
            }
        }
        room.group_passenger.travelers_list.map(traveler => {
            if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), 'y') >= 18) {
                tmp_adults += 1;
            } else {
                tmp_children += 1;
            }
        });
        setAdults(tmp_adults);
        setChildren(tmp_children);
        setPrice({
            cost: tmp_price.cost.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            currency: tmp_price.currency
        });
    }, []);
    return (
        <>
            <Grid container className={classes.container}>
                <Grid className={clsx({ [classes.gridContent]: showProduct })} item>
                    <Typography>
                        <strong>
                            {t('global.room')} {room_index + 1} :
                        </strong>{' '}
                        {room.description} -{' '}
                        {
                            room.bed_type !== undefined &&
                                room.bed_type !== null &&
                                room.bed_type !== '' ?
                                ((room.bed_type === 'simple_bed' || room.bed_type === 'queen_size' || room.bed_type === 'king_size' || room.bed_type === 'twin_bed' || room.bed_type === 'double_bed' || room.bed_type === 'sofa_bed' || room.bed_type === 'baby_bed' || room.bed_type === 'rollaway_bed' || room.bed_type === 'bunk_bed' || room.bed_type === 'futon') ? t(`accommodation.${room.bed_type} - `) : room.bed_type + ' - ') : ''} {adults > 0 ? `${adults} ${t('global.adults')}` : ''} {children > 0 ? `${children} ${t('global.children')} (${room.group_passenger.travelers_list.filter(traveler => moment.utc(end_date).diff(moment.utc(traveler.birth_date), 'y') < 18).map(traveler => {
                                    return moment.utc(end_date).diff(moment.utc(traveler.birth_date), 'y');
                                }).join(", ")})` : ''
                        }
                        {
                            !providerContext.module &&
                            (!room.deletion_restriction && (room.booking_status === null || room.booking_status.status_booking !== 'CONFIRMED')) &&
                            <Typography className={classes.replace} onClick={replaceRoom}>
                                {t('cart-material.replace-room')}
                            </Typography>
                        }
                    </Typography>
                    {
                        room.before_recalc_description !== null &&
                        <Box
                            className={classes.containerMargin}
                            sx={{ display: 'inline-block' }}
                        >
                            <Tooltip title={t('cart-material.recalc-change-room-name', { name: room.before_recalc_description })}>
                                <Box
                                    className={clsx(classes.traverlerModification)}
                                    onClick={confirmRoomRecalc}
                                    sx={{
                                        fontSize: 13,
                                        cursor: 'pointer',
                                        color: '#ff9800',
                                        border: '2px solid #ff9800'
                                    }}
                                >
                                    <Grid container alignItems={'center'} spacing={2}>
                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                        <Grid item>{t('cart-material.recalc-change-room')}</Grid>
                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                    </Grid>
                                </Box>
                            </Tooltip>
                        </Box>
                    }
                    {
                        showProduct && (
                            <Fragment>
                                {
                                    room.breakfast_included &&
                                    <div className={classes.containerMargin}>
                                        <FreeBreakfastOutlined className={classes.roomIcon} /><Typography>{t('accommodation.breakfast_included')}</Typography>
                                    </div>
                                }
                                {
                                    room.lunch_included &&
                                    <div className={classes.containerMargin}>
                                        <Restaurant className={classes.roomIcon} /><Typography>{t('accommodation.lunch_included')}</Typography>
                                    </div>
                                }
                                {
                                    room.dinner_included &&
                                    <div className={classes.containerMargin}>
                                        <RoomService className={classes.roomIcon} /><Typography>{t('accommodation.dinner_included')}</Typography>
                                    </div>
                                }
                                {
                                    room.drink_included &&
                                    <div className={classes.containerMargin}>
                                        <LocalBar className={classes.roomIcon} /><Typography>{t('accommodation.drink_included')}</Typography>
                                    </div>
                                }
                                {
                                    room.assistance_included &&
                                    <div className={classes.containerMargin}>
                                        <SupportAgentOutlined className={classes.roomIcon} /><Typography>{t('accommodation.assistance_included')}</Typography>
                                    </div>
                                }
                                {
                                    room.insurance_included &&
                                    <div className={classes.containerMargin}>
                                        <SecurityOutlined className={classes.roomIcon} /><Typography>{t('accommodation.insurance_included')}</Typography>
                                    </div>
                                }
                                {
                                    (quotation_code === 'volonline' || quotation_code === 'gattinoni') &&
                                    room.all_inclusive &&
                                    <div className={classes.containerMargin}>
                                        <RoomService className={classes.roomIcon} /><Typography>{t('accommodation.full_board')}</Typography>
                                    </div>
                                }
                                {
                                    provider !== null && provider.provider.code === "expedia_rapid" &&
                                    <>
                                        {
                                            (room.check_in !== null || room.check_out !== null || room.check_in_instructions !== null) &&
                                            <Grid container spacing={0} style={{ marginTop: 8 }}>
                                                {
                                                    room.check_in !== null &&
                                                    <Grid item xs={4}>
                                                        <Timer className={`${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}`} />
                                                        <Typography className={classes.smallText}>{t('accommodation.check_in')} {room.check_in}</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    room.check_out !== null &&
                                                    <Grid item xs={4}>
                                                        <TimerOff className={`${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}`} />
                                                        <Typography className={classes.smallText}>{t('accommodation.check_out')} {room.check_out}</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    room.check_in_instructions !== null &&
                                                    <Grid item xs={4}>
                                                        <div onClick={onCheckInInfo} style={{ cursor: "pointer", width: "fit-content" }}>
                                                            <Timer className={`${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}`} />
                                                            <Typography className={`${classes.inline} ${classes.smallText}`}>{t('accommodation.check_in_information')}</Typography>
                                                            <InfoOutlined className={classes.checkInInfo} />
                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                        }
                                        {
                                            room.is_packaged ? (
                                                <Typography className={classes.onRequest}>{t('accommodation.is_packaged')}</Typography>
                                            ) : (
                                                <Typography className={classes.onRequest}>{t('accommodation.is_standalone')}</Typography>
                                            )
                                        }
                                        {
                                            room.fees_n_taxes !== null &&
                                            <div className={classes.paddingBottom}>
                                                <CiMoneyCheck1 className={`${classes.floatLeft} ${classes.marginRight} ${classes.icon}`} />
                                                <Typography className={classes.inline} style={{ fontSize: 14 }}>
                                                    Taxes and Fees : {room.fees_n_taxes} {feesCurrency?.symbol}
                                                </Typography>
                                                <InfoOutlined className={classes.taxesInfo} onClick={onTaxesInfo} />
                                            </div>
                                        }
                                        {
                                            room.sales_tax !== null &&
                                            <div className={classes.paddingBottom}>
                                                <CiMoneyCheck1 className={`${classes.floatLeft} ${classes.marginRight} ${classes.icon}`} />
                                                <Typography className={classes.flex} style={{ fontSize: 14 }}>
                                                    Sales Tax : {room.sales_tax} {feesCurrency?.symbol}
                                                </Typography>
                                            </div>
                                        }
                                    </>
                                }
                                {
                                    room.additional_products && room.additional_products.length > 0 &&
                                    <div className={classes.containerMargin}>
                                        <Typography><strong>{t('accommodation.additional-products')}</strong></Typography>
                                        {
                                            room.additional_products.map(additional_product => {
                                                const price = getPrice(additional_product.prices);
                                                return (
                                                    <Typography>{additional_product.name} + {new Intl.NumberFormat(i18n.language, { style: "currency", currency: price.currency.iso_code }).format(price.cost)}</Typography>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                {
                                    !isProductPackaged({
                                        product: room,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    <Fragment>
                                        {
                                            quotation_code === 'visiteurs' && user?.client_full?.type === 2 ? (
                                                <Fragment>
                                                    {
                                                        room.is_cancellable ? (
                                                            <div className={classes.containerMargin}>
                                                                <CheckCircleOutline className={classes.roomIcon} /><Typography className={classes.inlineBlock}>{t('cart-material.cancellable-with-conditions')}</Typography>
                                                                <HelpOutline onClick={showCancellationDetail} className={classes.cancellationInfo} />
                                                            </div>
                                                        ) : (
                                                            <div className={classes.containerMargin}>
                                                                <HighlightOff className={classes.roomIcon} /><Typography className={classes.inlineBlock}>{t('cart-material.not-cancellable')}</Typography>
                                                            </div>
                                                        )
                                                    }
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    {
                                                        room.is_cancellable ? (
                                                            <div className={classes.containerMargin}>
                                                                <CheckCircleOutline className={classes.roomIcon} /><Typography className={classes.inlineBlock}>
                                                                    {
                                                                        (
                                                                            (room.charges?.length ?? 0) > 0 ||
                                                                            parseFloat(room.cancellation_amount ?? '0') > 0
                                                                        ) ?
                                                                            t('cart-material.cancellable-with-conditions') :
                                                                            t('cart-material.cancellable')
                                                                    }
                                                                </Typography>
                                                                {
                                                                    (
                                                                        (room.charges?.length ?? 0) > 0 ||
                                                                        parseFloat(room.cancellation_amount ?? '0') > 0
                                                                    ) &&
                                                                    <HelpOutline
                                                                        onClick={showCancellationDetail}
                                                                        className={classes.cancellationInfo}
                                                                    />
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={classes.containerMargin}>
                                                                <HighlightOff className={classes.roomIcon} /><Typography className={classes.inlineBlock}>{t('cart-material.not-cancellable')}</Typography>
                                                            </div>
                                                        )
                                                    }
                                                </Fragment>
                                            )
                                        }
                                    </Fragment>
                                }
                                {
                                    room.transfer_included &&
                                    <div className={classes.containerMargin}>
                                        <TransferWithinAStation className={classes.roomIcon} /><Typography>{t('cart-material.room-transfer')}</Typography>
                                    </div>
                                }
                                {room.elements !== undefined && room.elements !== null && room.elements.map(element => element.description !== null && <Typography>{element.description}</Typography>)}
                            </Fragment>
                        )
                    }
                </Grid>
                {
                    showProduct && (
                        <Fragment>
                            {
                                (user?.client_full?.type !== 2 || !room.deletion_restriction) && !isProductPackaged({ product: room, stackInfos: trip?.stack_info ?? null }) && (room.booking_status === null || quotation_code !== 'marcovasco' || room.booking_status.status_booking !== 'CANCELLED') &&
                                <Grid className={classes.gridPrice} item>
                                    {((user?.client_full?.type !== 2 || !room.deletion_restriction) && (room.booking_status === null || room.booking_status.status_booking !== 'CONFIRMED')) && <Tooltip title={t('cart-material.delete-room')}><Close className={classes.delete} onClick={onDeleteRoom} /></Tooltip>}
                                    {
                                        (!room.price_change || room.price_change.is_available) &&
                                        !room.catalog_price &&
                                        !room.cataloged_price &&
                                        <>
                                            {
                                                room.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                                (
                                                    room.on_request ||
                                                    (!room.is_available && (provider === null || provider.provider.custom))
                                                ) &&
                                                <Typography className={clsx(classes.prices, { [classes.onRequest]: room.on_request })}>
                                                    {t('cart-material.on-request')}
                                                </Typography>
                                            }
                                            <Typography className={clsx(classes.prices)}>
                                                {price !== null ? `${price.cost}${price.currency.symbol === '$' || price.currency.symbol === '£' ? `${price.currency.symbol}${price.currency.iso_code}` : price.currency.symbol}` : ''}
                                            </Typography>
                                        </>
                                    }
                                    {room.price_change && (room.price_change.price_variation !== 0 || !room.price_change.is_available) &&
                                        <Grid container justify={'flex-end'} alignItems={'center'}>
                                            {
                                                room.price_change.is_available &&
                                                <Grid item>
                                                    {room.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                </Grid>
                                            }
                                            <Grid item>
                                                <Typography className={room.price_change.price_variation > 0 || !room.price_change.is_available ? classes.red : classes.green}>{!room.price_change.is_available ? t('cart-material.product-unavailable') : room.price_change.price_variation.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {!room.catalog_price && !room.cataloged_price && <Typography className={classes.priceDetail} onClick={showPriceDetail}>{t('cart-material.price-detail')}</Typography>}
                                    {
                                        accommodation.is_custom &&
                                        <Typography className={classes.replace} onClick={() => setOpenInfoModal(true)}>
                                            {t('cart-material.room-more-info')}
                                        </Typography>
                                    }
                                    <Box sx={{ display: 'table', marginLeft: 'auto' }}>
                                        <Stack
                                            spacing={1}
                                            alignItems="center"
                                            sx={{
                                                marginTop: 1,
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            <CartConstructionProductsTableItemStatus
                                                type="accommodation"
                                                item={accommodation}
                                                bookingProcessState={room.booking_process_state}
                                                bookingStatus={room.booking_status}
                                            />
                                            {
                                                room.booking_status?.item_reference &&
                                                <Typography fontWeight={100} fontSize="0.75rem">
                                                    {
                                                        t(
                                                            'cart-material.cart-construction-reference',
                                                            { ref: room.booking_status?.item_reference }
                                                        )
                                                    }
                                                </Typography>
                                            }
                                        </Stack>
                                    </Box>
                                </Grid>
                            }
                            {
                                user?.client_full?.type !== 2 && room.provider_wanted_price !== null && room.prices[GetPricesId(room.prices, user.client, user, true)].purchase_price !== room.provider_wanted_price &&
                                <Grid item xs={12}>
                                    <Alert
                                        variant={'filled'}
                                        severity={'error'}
                                        action={
                                            <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                                        }
                                        sx={(theme) => ({
                                            [theme.breakpoints.up('sm')]: {
                                                maxWidth: '50%'
                                            }
                                        })}
                                    >
                                        <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                                    </Alert>
                                </Grid>
                            }
                            {
                                user?.client_full?.type !== 2 && room.booking_status !== null && room.booking_status.details !== null && room.booking_status.details !== '' &&
                                <Grid item xs={12} style={{ marginTop: 5 }}>
                                    <Alert
                                        variant={'filled'}
                                        severity={'info'}
                                        sx={(theme) => ({
                                            [theme.breakpoints.up('sm')]: {
                                                maxWidth: '50%'
                                            }
                                        })}
                                    >
                                        <AlertTitle>{t('cart-material.provider-note')}</AlertTitle>
                                        {room.booking_status.details}
                                    </Alert>
                                </Grid>
                            }
                        </Fragment>
                    )
                }
                {
                    !showProduct && (
                        <Fragment>
                            {
                                (user?.client_full?.type !== 2 || !room.deletion_restriction) && !isProductPackaged({ product: room, stackInfos: trip?.stack_info ?? null }) && (room.booking_status === null || quotation_code !== 'marcovasco' || room.booking_status.status_booking !== 'CANCELLED') &&
                                <Grid className={classes.gridPrice} item>
                                    {((user?.client_full?.type !== 2 || !room.deletion_restriction) && (room.booking_status === null || room.booking_status.status_booking !== 'CONFIRMED')) && <Tooltip title={t('cart-material.delete-room')}><Close className={classes.delete} onClick={onDeleteRoom} /></Tooltip>}
                                    {(!room.price_change || room.price_change.is_available) && !room.catalog_price && !room.cataloged_price && <Typography className={clsx(classes.prices, { [classes.onRequest]: room.on_request })}>{room.booking_status?.status_booking !== StatusBooking.CONFIRMED && (room.on_request || (!room.is_available && (provider === null || provider.provider.custom))) ? t('cart-material.on-request') : price !== null ? `${price.cost}${price.currency.symbol === '$' || price.currency.symbol === '£' ? `${price.currency.symbol}${price.currency.iso_code}` : price.currency.symbol}` : ''}</Typography>}
                                    {room.price_change && (room.price_change.price_variation !== 0 || !room.price_change.is_available) &&
                                        <Grid container justify={'flex-end'} alignItems={'center'}>
                                            {
                                                room.price_change.is_available &&
                                                <Grid item>
                                                    {room.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                </Grid>
                                            }
                                            <Grid item>
                                                <Typography className={room.price_change.price_variation > 0 || !room.price_change.is_available ? classes.red : classes.green}>{!room.price_change.is_available ? t('cart-material.product-unavailable') : room.price_change.price_variation.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {
                                user?.client_full?.type !== 2 && room.provider_wanted_price !== null && room.prices[GetPricesId(room.prices, user.client, user, true)].purchase_price !== room.provider_wanted_price &&
                                <Grid item xs={12}>
                                    <Alert
                                        variant={'filled'}
                                        severity={'error'}
                                        action={
                                            <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                                        }
                                        sx={(theme) => ({
                                            [theme.breakpoints.up('sm')]: {
                                                maxWidth: '50%'
                                            }
                                        })}
                                    >
                                        <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                                    </Alert>
                                </Grid>
                            }
                        </Fragment>
                    )
                }
                {
                    !!room.resort_fees_text && accommodation.rooms.length > 1 &&
                    <Grid item xs={12}>
                        <Alert
                            className={showProduct ? classes.resortFees : ''}
                            classes={{ icon: classes.alertIcon }}
                            variant={'filled'}
                            severity={'warning'}
                            sx={(theme) => ({
                                [theme.breakpoints.up('sm')]: {
                                    maxWidth: '50%'
                                }
                            })}
                        >
                            {room.resort_fees_text}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            <CartRoomDetailsModal
                open={openInfoModal}
                room={room}
                onClose={() => setOpenInfoModal(false)}
            />
            <Dialog open={checkInOpen} onClose={onCheckInInfo}>
                <DialogTitle onClose={onCheckInInfo}>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item>
                            {t("accommodation.check_in_information")} :
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onCheckInInfo}><Close /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: room.check_in_instructions }} />
                    <br />
                </DialogContent>
            </Dialog>
            <Dialog open={taxesInfoOpen} onClose={onTaxesInfo}>
                <DialogTitle onClose={onTaxesInfo}>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item>
                            {t("accommodation.taxes_information")} :
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onTaxesInfo}><Close /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Typography>This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</Typography>
                    <br />
                </DialogContent>
            </Dialog>
            {
                openReplaceModal &&
                <CartConstructionReplaceProductModal
                    open={openReplaceModal}
                    type="accommodation"
                    mode="replace"
                    status={status}
                    accommodationParams={{ id: room.hotel[0]?.id }}
                    startDestination={room.start_destination.id}
                    onClose={() => setOpenReplaceModal(false)}
                />
            }
        </>
    );
};

export default withRouter(CartRoom);
