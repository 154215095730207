import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

type ProviderData = {
    id: number,
    contact_details: string | null,
    provider: {
        name: string | null
    } | null
}

async function get(rootProviderId: number): Promise<ProviderData> {
    const { headers } = CheckBeforeRequest();
    const response = await axios.get<ProviderData>(
        `${API_HREF}client/${window.id_owner}/custom-providers/${rootProviderId}/`,
        { headers }
    );
    return response.data;
}

export const getProviderData = memoizee(
    get,
    {
        promise: true,
        primitive: true
    }
);
