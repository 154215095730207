import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps
} from "@mui/material";
import { ArrowBackIosNew, Check } from "@mui/icons-material";
import axios from "axios";
import { isNumber, isObject } from "lodash";
import { useSnackbar } from "notistack";
import CartDeleteModal from "./CartDeleteModal";
import {
    CartConstructionProductsTableSpecificDemandModal
} from "./CartConstructionProductsTableSpecificDemandModal";
import {
    CartConstructionProductsTableEditAccommodationBookingModal
} from "./CartConstructionProductsTableEditAccommodationBookingModal";
import {
    CartConstructionProductsTableEditCarBookingModal
} from "./CartConstructionProductsTableEditCarBookingModal";
import {
    CartConstructionProductsTableEditTransferBookingModal
} from "./CartConstructionProductsTableEditTransferBookingModal";
import { 
    CartConstructionProductsTableEditPoiBookingModal
} from "./CartConstructionProductsTableEditPoiBookingModal";
import {
    CartConstructionProductsTableEditManualProductBookingModal
} from "./CartConstructionProductsTableEditManualProductBookingModal";
import {
    CartConstructionProductsTableEditCarNoteModal
} from "./CartConstructionProductsTableEditCarNoteModal";
import {
    CartConstructionProductsTableEditTransferNoteModal
} from "./CartConstructionProductsTableEditTransferNoteModal";
import {
    CartConstructionProductsTableEditHotelNoteModal
} from "./CartConstructionProductsTableEditHotelNoteModal";
import {
    CartConstructionProductsTableEditPoiNoteModal
} from "./CartConstructionProductsTableEditPoiNoteModal";
import {
    CartConstructionProductsTableEditManualProductNoteModal
} from "./CartConstructionProductsTableEditManualProductNoteModal";
import {
    CartConstructionProductsTableEditCarCancellationRulesModal
} from "./CartConstructionProductsTableEditCarCancellationRulesModal";
import {
    CartConstructionProductsTableEditTransferCancellationRulesModal
} from "./CartConstructionProductsTableEditTransferCancellationRulesModal";
import {
    CartConstructionProductsTableEditHotelCancellationRulesModal
} from "./CartConstructionProductsTableEditHotelCancellationRulesModal";
import {
    CartConstructionProductsTableEditPoiCancellationRulesModal
} from "./CartConstructionProductsTableEditPoiCancellationRulesModal";
import {
    CartConstructionProductsTableEditManualProductCancellationRulesModal
} from "./CartConstructionProductsTableEditManualProductCancellationRulesModal";
import {
    CartConstructionProductsTableEditFlightBookingModal
} from "./CartConstructionProductsTableEditFlightBookingModal";
import {
    CartConstructionProductsTableEditFlightNoteModal
} from "./CartConstructionProductsTableEditFlightNoteModal";
import {
    CartConstructionProductsTableEditFlightCancellationRulesModal
} from "./CartConstructionProductsTableEditFlightCancellationRulesModal";
import { CartConstructionProductsTableEditAssistanceBookingModal } from "./CartConstructionProductsTableEditAssistanceBookingModal";
import { CartConstructionProductsTableEditAssistanceNoteModal } from "./CartConstructionProductsTableEditAssistanceNoteModal";
import { CartConstructionProductsTableEditAssistanceCancellationRulesModal } from "./CartConstructionProductsTableEditAssistanceCancellationRulesModal";
import { CartConstructionProductStatusMenu } from "./CartConstructionProductStatusMenu";
import { CartConstructionReplaceProductModal } from "./CartConstructionReplaceProductModal";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useGetPrice } from "./utils/getPrice";
import { isDeleteButtonEnabled } from "./utils/isDeleteButtonEnabled";
import { getProductStatus } from "./utils/getProductStatus";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { ChangeCarPack } from "../../Actions/CarsSearch";
import { setLoading } from "./redux/cartConstructionReducer";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { Flight } from "../Itinerary/network/flight";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { ManualProductPoiCart } from "../Itinerary/objects/manualProductPoiCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AppState } from "../../Reducers/Reducers";

export type CartProductStatus = 'DEFAULT' | 'OPTION' | 'HIDE' | 'HIDE_COUNT' | 'VARIANT';

type Props = {
    open: boolean,
    anchorEl: MenuProps['anchorEl'],
    onClose: () => void
} & ({
    type: 'car',
    value: CarCart,
    index: number
} | {
    type: 'assistance',
    value: AssistanceCart
} | {
    type: 'poi',
    value: PoiCart
} | {
    type: 'accommodation',
    value: AccommodationCart,
    rooms: number[]
} | {
    type: 'transfer',
    value: TransferCart,
    isCustom: boolean
} | {
    type: 'flight',
    value: Flight
} | {
    type: 'manual',
    value: ManualProduct
})

export function CartConstructionProductsTableItemMenu(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const permissions = useSelector((state: AppState) => state.user.permissions);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const user = useSelector((state: AppState) => state.user.user);
    const { enqueueSnackbar } = useSnackbar();
    const provider = useMemo(() => {
        let tmp_provider = null;
        for (let i = 0; i < providers.length; i++) {
            if (providers[i]?.provider.id === props.value.provider) {
                tmp_provider = providers[i]!;
            }
        }
        for (let i = 0; i < manual_providers.length; i++) {
            if (manual_providers[i]?.provider.id === props.value.provider) {
                tmp_provider = manual_providers[i]!;
            }
        }
        return tmp_provider;
    }, [props.value.provider, providers, manual_providers]);
    const [openStatus, setOpenStatus] = useState(false);
    const [openVariant, setOpenVariant] = useState(false);
    const [openSpecificDemandModal, setOpenSpecificDemandModal] = useState(false);
    const [openEditBookingModal, setOpenEditBookingModal] = useState(false);
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
    const [openEditCancellationRulesModal, setOpenEditCancellationRulesModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [menuItemLoading, setMenuItemLoading] = useState(false);
    const [editMode, setEditMode] = useState<'edit' | 'replace'>('edit');
    const [openReplaceModal, setOpenReplaceModal] = useState(false);
    const menuRef = useRef<HTMLUListElement>(null);
    const status = useMemo(() => {
        return getProductStatus(props.value, !!itinerary_type_details_data);
    }, [props.value, itinerary_type_details_data]);
    const getPrice = useGetPrice();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onEditManualProduct = () => {
        if (props.type === 'manual') {
            dispatch({
                type: 'CART_EDIT_MANUAL_PRODUCT',
                payload: {
                    manual_product: props.value,
                    user: user,
                    quotation_code: quotation_code,
                    currency_list: currency_list
                }
            });
            props.onClose();
        }
    };

    const onDeleteAccommodation = () => {
        if (props.type === 'accommodation') {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            Promise.all(
                props.value.rooms.map((room, index_room) => {
                    if (room.booking_status && room.booking_status.status_booking === "CANCELLED") {
                        return axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                            data: {
                                is_displayed: false
                            }
                        }).then((response) => {
                            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                            enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                        }).catch(error => {
                            console.log(error);
                            enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                        });
                    }
                    return axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
                    }).then(() => {
                        dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                        enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                    }).catch(error => {
                        console.log(error);
                        enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                    });
                })
            ).finally(() => {
                setLoading(false);
            });
        }
    };

    const onDeleteFlight = () => {
        const { headers } = CheckBeforeRequest();
        setLoading(true);
        if (props.value.booking_status && props.value.booking_status.status_booking === 'CANCELLED') {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${props.value.id}/`,
                data: {
                    is_displayed: false
                }
            }).then((response) => {
                let new_flight = [];
                new_flight.push(response.data);
                let arr = CheckResponse(new_flight, trip?.end_date);
                dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
            }).finally(() => setLoading(false));
        } else {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${props.value.id}/`
            }).then(() => {
                dispatch({ type: 'FLIGHT_REMOVE_FLIGHT_FROM_CART_BY_ID', payload: props.value.id });
                enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
            }).finally(() => setLoading(false));
        }
    };

    const onDeleteCar = (silent = false) => {
        const { headers } = CheckBeforeRequest();
        if (props.value.booking_status && props.value.booking_status.status_booking === 'CANCELLED') {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.value.id}/`,
                data: {
                    is_displayed: false
                }
            }).then((response) => {
                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                if (!silent) {
                    enqueueSnackbar(t('cart-material.car-deleted'), { variant: 'success' });
                }
            }).catch(error => {
                console.log(error);
                if (!silent) {
                    enqueueSnackbar(t('cart-material.car-not-deleted'), { variant: 'error' });
                }
            });
        } else {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.value.id}/`
            }).then(() => {
                dispatch({ type: 'CAR_REMOVE_FROM_CART_BY_ID', payload: props.value.id });
                if (!silent) {
                    enqueueSnackbar(t('cart-material.car-deleted'), { variant: 'success' });
                }
            }).catch(error => {
                console.log(error);
                if (!silent) {
                    enqueueSnackbar(t('cart-material.car-not-deleted'), { variant: 'error' });
                }
            });
        }
    };

    const onDeleteTransfer = () => {
        if (props.type === 'transfer') {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            if (
                props.value.booking_status &&
                props.value.booking_status.status_booking === 'CANCELLED'
            ) {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.value.is_custom ? 'custom-products' : 'transfers'}/${props.value.id}/`,
                    data: {
                        is_displayed: false
                    }
                }).then((response) => {
                    dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                }).finally(() => setLoading(false));
            } else {
                axios({
                    method: 'DELETE',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.value.is_custom ? 'custom-products' : 'transfers'}/${props.value.id}/`
                }).then(() => {
                    dispatch({ type: 'TRANSFER_REMOVE_FROM_CART_BY_ID', payload: props.value.id });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                }).finally(() => setLoading(false));
            }
        }
    };

    const onDeletePoi = () => {
        if (props.type === 'poi') {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            if (props.value.booking_status && props.value.booking_status.status_booking === "CANCELLED") {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.value.id}/`,
                    data: {
                        is_displayed: false
                    }
                }).then((response) => {
                    dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.poi-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.poi-not-deleted'), { variant: 'error' });
                }).finally(() => setLoading(false));
            } else {
                axios({
                    method: 'DELETE',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.value.id}/`
                }).then(() => {
                    dispatch({ type: 'POI_REMOVE_CART_BY_ID', payload: props.value.id });
                    enqueueSnackbar(t('cart-material.poi-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.poi-not-deleted'), { variant: 'error' });
                }).finally(() => setLoading(false));
            }
        }
    };

    const onDeleteAssistance = () => {
        const { headers } = CheckBeforeRequest();
        setLoading(true);
        if (props.value.booking_status && props.value.booking_status.status_booking === "CANCELLED") {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${props.value.id}/`,
                data: {
                    is_displayed: false
                }
            }).then((response) => {
                if (props.value.product_type === 8) {
                    enqueueSnackbar(t('cart-material.insurance-deleted'), { variant: 'success' });
                } else {
                    enqueueSnackbar(t('cart-material.assistance-deleted'), { variant: 'success' });
                }
                dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
                if (props.value.product_type === 8) {
                    enqueueSnackbar(t('cart-material.insurance-not-deleted'), { variant: 'error' });
                } else {
                    enqueueSnackbar(t('cart-material.assistance-not-deleted'), { variant: 'error' });
                }
            }).finally(() => setLoading(false));
        } else {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${props.value.id}/`
            }).then(() => {
                if (props.value.product_type === 8) {
                    enqueueSnackbar(t('cart-material.insurance-deleted'), { variant: 'success' });
                } else {
                    enqueueSnackbar(t('cart-material.assistance-deleted'), { variant: 'success' });
                }
                dispatch({ type: 'CART_ASSISTANCE_DELETE_BY_ID', payload: props.value.id });
            }).catch(error => {
                console.log(error);
                if (props.value.product_type === 8) {
                    enqueueSnackbar(t('cart-material.insurance-not-deleted'), { variant: 'error' });
                } else {
                    enqueueSnackbar(t('cart-material.assistance-not-deleted'), { variant: 'error' });
                }
            }).finally(() => setLoading(false));
        }
    };

    const onDeleteManualProduct = () => {
        if (props.type === 'manual') {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.value.id}/`
            }).then(() => {
                dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: props.value.id });
                enqueueSnackbar(t('cart-material.manual-product-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.manual-product-not-deleted'), { variant: 'error' });
            }).finally(() => setLoading(false));
        }
    };

    const onReplaceCar = () => {
        if (props.type === 'car') {
            const { headers } = CheckBeforeRequest();
            let price = getPrice(props.value.prices).cost;
            let step_info: unknown[] = [];
            let id = props.value.id;
            let tmp_pickup = props.value.start_date.split(/[a-zA-Z]+/g);
            let tmp_resti = props.value.end_date.split(/[a-zA-Z]+/g);
            tmp_pickup = tmp_pickup[1]?.split(':') ?? [];
            let pickup_hour = tmp_pickup[0];
            let pickup_min = tmp_pickup[1];
            tmp_resti = tmp_resti[1]?.split(':') ?? [];
            let resti_hour = tmp_resti[0];
            let resti_min = tmp_resti[1];
            let selected_car = {
                id: id,
                selling_price: price,
                currency: getPrice(props.value.prices).currency?.id,
                address_pickup: props.value.address_pickup,
                address_return: props.value.address_return,
                location_code_pickup: props.value.location_code_pickup,
                location_pickup_name: props.value.station_name_pickup,
                location_return_name: props.value.station_name_return,
                location_code_return: props.value.location_code_return,
                provider_id: props.value.provider,
                subprovider_id: props.value.subprovider_id,
                subprovider: props.value.subprovider_name,
                subprovider_logo: props.value.subprovider_logo,
                forfait_service: [],
                special_equipments: [],
                vehicle_info: {
                    name: props.value.name,
                    vehicle_category_id: props.value.vehicle_category_id,
                    vehicle_type_id: props.value.vehicle_type_id,
                    transmission: props.value.transmission_type,
                    vehicle_code: props.value.vehicle_code,
                    air_conditioner: props.value.air_conditioner,
                    pict_url: props.value.vehicle_picture_url,
                    code: props.value.vehicle_code,
                    vehicle_category: props.value.vehicle_category,
                    passenger: props.value.max_passengers,
                    door_count: props.value.door_count,
                    baggage: props.value.baggage,
                    return_fuel_full: props.value.return_fuel_full,
                    accident_franchise: props.value.accident_franchise,
                    theft_franchise: props.value.theft_franchise,
                    young_taxes_age: props.value.young_taxes_age,
                    young_taxes_amount: props.value.young_taxes_amount
                },
                charges: props.value.charges,
                onsite_fares: props.value.onsite_fare,
                state_code: props.value.state_code,
                country_code: props.value.country_code,
                vehicle_services: props.value.included_service
            };
            step_info.push({
                agency_go: null,
                agency_return: null,
                car_nbr: 1,
                origin: props.value.location_code_pickup,
                destination: props.value.location_code_return,
                driver_age: ['25'],
                origin_date: window.moment.utc(props.value.start_date).format('YYYY-MM-DD'),
                return_date: (window.moment.utc(props.value.end_date).isValid() ? window.moment.utc(props.value.end_date) : window.moment.utc(props.value.start_date)).format('YYYY-MM-DD'),
                origin_hours: pickup_hour,
                return_hours: resti_hour,
                origin_minutes: pickup_min,
                return_minutes: resti_min,
                tmp_destination: [],
                start_destination: props.value.start_destination.id,
                end_destination: props.value.end_destination?.id,
                origin_id: props.value.start_destination && props.value.start_destination ? props.value.start_destination.id : undefined
            });
            setMenuItemLoading(true);
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/cars/vehicle_prices/?trip=${GetCookie('trip_id')}`,
                params: {
                    provider_id: props.value.provider,
                    location_code_pickup: props.value.location_code_pickup,
                    location_code_return: props.value.location_code_return,
                    date_pickup: props.value.start_date,
                    date_return: props.value.end_date,
                    driver_age: 25,
                    start_destination: props.value.start_destination && props.value.start_destination ? props.value.start_destination.id : undefined
                }
            }).then((response) => {
                let special_equipments: unknown | null = null;
                if (response.data.length > 0) {
                    response.data.map((data_provider: any) => {
                        //if (data_provider.subprovider_name === props.value.subprovider_name) {
                        if (data_provider.stations.length > 0) {
                            let stations = data_provider.stations;
                            stations.map((station: any) => {
                                if (station.code === selected_car.location_code_pickup) {
                                    special_equipments = station.special_equipments;
                                }
                            });
                        }
                        (selected_car as any).old_equipment = props.value.special_equipments;
                        if (special_equipments) {
                            (special_equipments as any).map((elem: any) => {
                                if ((selected_car as any).old_equipment && (selected_car as any).old_equipment.length > 0) {
                                    (selected_car as any).old_equipment.map((item: any) => {
                                        if (item.code_id === elem.code_id) {
                                            elem.quantity = item.quantity;
                                            elem.check = true;
                                            (selected_car.special_equipments as unknown[]).push(elem);
                                        }
                                    });
                                }
                            });
                        }
                        //}
                    });
                }
                dispatch(
                    ChangeCarPack(
                        step_info,
                        selected_car,
                        special_equipments,
                        id,
                        props.index,
                        null,
                        true,
                        props.value.contract_name
                    )
                );
                setOpenReplaceModal(true);
            }).catch((error) => {
                console.error(error);
            }).finally(() => setMenuItemLoading(false));
        }
    };

    const onEditTransfer = () => {
        setEditMode('edit');
        setOpenReplaceModal(true);
    };

    const onReplaceTransfer = () => {
        setEditMode('replace');
        setOpenReplaceModal(true);
    };

    const onStatusChange = async (value: CartProductStatus) => {
        const { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            setLoading(true);
            switch (props.type) {
                case 'flight': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([response.data], data_trip?.end_date)[0]!
                        });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'car': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'accommodation': {
                    await Promise.all(
                        props.rooms.map(async (room) => {
                            try {
                                const response = await axios({
                                    method: 'PATCH',
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room}/`,
                                    data: {
                                        is_optional: value === 'OPTION',
                                        variant: value === 'VARIANT' ? 1 : null,
                                        is_hidden_for_traveler: value === 'HIDE',
                                        is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                                    }
                                });
                                dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                            } catch (error) {
                                console.error(error);
                            }
                        })
                    );
                    break;
                }
                case 'poi': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'transfer': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.isCustom ? 'custom-products' : 'transfers'}/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'assistance': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'manual': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.value.id}/`,
                            data: {
                                is_optional: value === 'OPTION',
                                variant: value === 'VARIANT' ? 1 : null,
                                is_hidden_for_traveler: value === 'HIDE',
                                is_hidden_for_traveler_count_price: value === 'HIDE_COUNT'
                            }
                        });
                        dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            onClose();
            setLoading(false);
        }
    };

    const onClose = () => {
        setOpenStatus(false);
        setOpenEditBookingModal(false);
        setOpenDeleteModal(false);
        setOpenEditCancellationRulesModal(false);
        setOpenEditNoteModal(false);
        setOpenReplaceModal(false);
        setOpenSpecificDemandModal(false);
        setOpenVariant(false);
        props.onClose();
    };

    return (
        <Box onClick={(event) => event.stopPropagation()}>
            <Menu
                MenuListProps={{ ref: menuRef }}
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={onClose}
            >
                <MenuItem onClick={() => setOpenStatus(true)}>
                    <ListItemIcon>
                        <ArrowBackIosNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t('cart-material.cart-construction-product-status')}
                    </ListItemText>
                </MenuItem>
                {
                    props.value.variant && itinerary_type_details_data && itinerary_type_details_data.variant_name &&
                    <MenuItem onClick={() => setOpenVariant(true)}>
                        <ListItemIcon>
                            <ArrowBackIosNew fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            {t('cart-material.cart-construction-variant')}
                        </ListItemText>
                    </MenuItem>
                }
                {
                    provider &&
                    provider.provider.code !== 'iol_iwtx' &&
                    provider.provider.code !== 'passion_brazil' &&
                    props.type === 'accommodation' &&
                    <MenuItem
                        onClick={() => setOpenSpecificDemandModal((state) => !state)}
                        disabled={props.value.booking_status?.status_booking === StatusBooking.CONFIRMED}
                    >
                        <ListItemText inset>
                            {
                                props.value.booking_status?.status_booking !== StatusBooking.CONFIRMED ?
                                    t('cart-material.remark-booking') :
                                    t('cart-material.remark-booking-disabled')
                            }
                        </ListItemText>
                    </MenuItem>
                }
                {
                    user?.client_full?.type !== 2 &&
                    (
                        props.type !== 'manual' ||
                        (
                            props.value.is_bookable &&
                            ![16, 18, 20].includes(props.value.product_type)
                        )
                    ) &&
                    !props.value.is_optional &&
                    !(props.value as ManualProductPoiCart).poi_type &&
                    <MenuItem onClick={() => setOpenEditBookingModal((state) => !state)}>
                        <ListItemText inset>
                            {t('cart-material.update-quotation-status')}
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => setOpenEditNoteModal(true)}>
                    <ListItemText inset>
                        {t('cart-material.add-note')}
                    </ListItemText>
                </MenuItem>
                {
                    (
                        quotation_code !== 'visiteurs' ||
                        user?.client_full?.type !== 2
                    ) &&
                    (
                        props.type !== 'manual' ||
                        props.value.cancel_condition_text === null
                    ) &&
                    <MenuItem onClick={() => setOpenEditCancellationRulesModal(true)}>
                        <ListItemText inset>
                            {t('cart-material.manual-cancellation-rules')}
                        </ListItemText>
                    </MenuItem>
                }
                {
                    props.type === 'manual' &&
                    (
                        user?.client_full?.type !== 2 ||
                        props.value.creator?.client?.type === 2
                    ) &&
                    <MenuItem onClick={onEditManualProduct}>
                        <ListItemText inset>
                            {t('cart-material.cart-construction-edit-product')}
                        </ListItemText>
                    </MenuItem>
                }
                {
                    props.type === 'car' &&
                    (
                        !props.value.deletion_restriction &&
                        (
                            props.value.booking_status === null ||
                            props.value.booking_status.status_booking !== 'CONFIRMED'
                        )
                    ) &&
                    <MenuItem onClick={onReplaceCar}>
                        {
                            menuItemLoading &&
                            <ListItemIcon>
                                <CircularProgress size={17} />
                            </ListItemIcon>
                        }
                        <ListItemText inset={!menuItemLoading}>
                            {t('cart-material.replace-car')}
                        </ListItemText>
                    </MenuItem>
                }
                {
                    props.type === 'transfer' &&
                    (
                        !props.value.deletion_restriction &&
                        (
                            props.value.booking_status === null ||
                            props.value.booking_status.status_booking !== 'CONFIRMED'
                        )
                    ) &&
                    <div>
                        <MenuItem onClick={onEditTransfer}>
                            <ListItemText inset>
                                {t('cart-material.edit-transfer')}
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={onReplaceTransfer}>
                            <ListItemText inset>
                                {t('cart-material.replace-transfer')}
                            </ListItemText>
                        </MenuItem>
                    </div>
                }
                {
                    props.type === 'poi' &&
                    (
                        !props.value.deletion_restriction &&
                        (
                            props.value.booking_status === null ||
                            props.value.booking_status.status_booking !== 'CONFIRMED'
                        )
                    ) &&
                    <MenuItem onClick={() => setOpenReplaceModal(true)}>
                        <ListItemText inset>
                            {
                                props.value.poi_type ?
                                    t('cart-material.replace-poi') :
                                    t('cart-material.replace-activity')
                            }
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem
                    onClick={() => setOpenDeleteModal(true)}
                    disabled={
                        !isDeleteButtonEnabled({
                            item: {
                                type: props.type,
                                product: props.value
                            },
                            trip,
                            permissions,
                            user
                        })
                    }
                >
                    <ListItemText inset>
                        {t('cart-material.cart-construction-delete-product')}
                    </ListItemText>
                </MenuItem>
            </Menu>
            <CartConstructionProductStatusMenu
                status={status}
                anchorEl={menuRef.current}
                open={openStatus}
                onChangeStatus={onStatusChange}
            />
            <VariantSubmenu
                {...props}
                anchorEl={menuRef.current}
                open={openVariant}
                onClose={() => setOpenVariant(false)}
            />
            {
                props.type === 'flight' &&
                <>
                    <CartConstructionProductsTableEditFlightBookingModal
                        open={openEditBookingModal}
                        flight={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditFlightNoteModal
                        open={openEditNoteModal}
                        flight={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditFlightCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        flight={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteFlight}
                    />
                </>
            }
            {
                props.type === 'accommodation' &&
                <>
                    <CartConstructionProductsTableSpecificDemandModal
                        open={openSpecificDemandModal}
                        accommodation={props.value}
                        onClose={() => setOpenSpecificDemandModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditAccommodationBookingModal
                        open={openEditBookingModal}
                        accommodation={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditHotelNoteModal
                        open={openEditNoteModal}
                        accommodation={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditHotelCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        accommodation={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteAccommodation}
                    />
                </>
            }
            {
                props.type === 'car' &&
                <>
                    <CartConstructionProductsTableEditCarBookingModal
                        open={openEditBookingModal}
                        car={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditCarNoteModal
                        open={openEditNoteModal}
                        car={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditCarCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        car={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteCar}
                    />
                    {
                        openReplaceModal &&
                        <CartConstructionReplaceProductModal
                            type="car"
                            mode="edit"
                            car={props.value}
                            dataTransformer={(data) => {
                                if (isObject(data)) {
                                    return {
                                        ...data,
                                        stack_number: props.value.stack_number,
                                        stack_info_id: props.value.stack_info_id
                                    };
                                }
                                return {
                                    stack_number: props.value.stack_number,
                                    stack_info_id: props.value.stack_info_id
                                };
                            }}
                            onDelete={() => onDeleteCar(true)}
                            onClose={() => setOpenReplaceModal(false)}
                        />
                    }
                </>
            }
            {
                props.type === 'transfer' &&
                <>
                    <CartConstructionProductsTableEditTransferBookingModal
                        open={openEditBookingModal}
                        transfer={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditTransferNoteModal
                        open={openEditNoteModal}
                        transfer={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditTransferCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        transfer={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteTransfer}
                    />
                    {
                        openReplaceModal &&
                        <CartConstructionReplaceProductModal
                            type="transfer"
                            mode={editMode}
                            transfer={props.value}
                            onDelete={onDeleteTransfer}
                            onClose={() => setOpenReplaceModal(false)}
                        />
                    }
                </>
            }
            {
                props.type === 'poi' &&
                <>
                    <CartConstructionProductsTableEditPoiBookingModal
                        open={openEditBookingModal}
                        poi={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditPoiNoteModal
                        open={openEditNoteModal}
                        poi={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditPoiCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        poi={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeletePoi}
                    />
                    {
                        openReplaceModal &&
                        <CartConstructionReplaceProductModal
                            type="poi"
                            mode="replace"
                            poi={props.value}
                            onDelete={onDeletePoi}
                            onClose={() => setOpenReplaceModal(false)}
                        />
                    }
                </>
            }
            {
                props.type === 'assistance' &&
                <>
                    <CartConstructionProductsTableEditAssistanceBookingModal
                        open={openEditBookingModal}
                        assistance={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditAssistanceNoteModal
                        open={openEditNoteModal}
                        assistance={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditAssistanceCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        assistance={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteAssistance}
                    />
                </>
            }
            {
                props.type === 'manual' &&
                <>
                    <CartConstructionProductsTableEditManualProductBookingModal
                        open={openEditBookingModal}
                        item={props.value}
                        onClose={() => setOpenEditBookingModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditManualProductNoteModal
                        open={openEditNoteModal}
                        item={props.value}
                        onClose={() => setOpenEditNoteModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartConstructionProductsTableEditManualProductCancellationRulesModal
                        open={openEditCancellationRulesModal}
                        item={props.value}
                        onClose={() => setOpenEditCancellationRulesModal(false)}
                        onCloseMenu={onClose}
                    />
                    <CartDeleteModal
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={onDeleteManualProduct}
                    />
                </>
            }
            <LoadingBackDrop open={loading} />
        </Box>
    );
}

type VariantSubmenuProps = Props

function VariantSubmenu(props: VariantSubmenuProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);

    const onStatusChange = async (variant: number) => {
        const { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            dispatch(setLoading(true));
            switch (props.type) {
                case 'flight': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${props.value.id}/`,
                            data: { variant }
                        });
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([response.data], data_trip?.end_date)[0]!
                        });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'car': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.value.id}/`,
                            data: { variant }
                        });
                        dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'accommodation': {
                    await Promise.all(
                        props.rooms.map(async (room) => {
                            try {
                                const response = await axios({
                                    method: 'PATCH',
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room}/`,
                                    data: { variant }
                                });
                                dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                            } catch (error) {
                                console.error(error);
                            }
                        })
                    );
                    break;
                }
                case 'poi': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.value.id}/`,
                            data: { variant }
                        });
                        dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'transfer': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.isCustom ? 'custom-products' : 'transfers'}/${props.value.id}/`,
                            data: { variant }
                        });
                        dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                }
                case 'manual': {
                    try {
                        const response = await axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.value.id}/`,
                            data: { variant }
                        });
                        dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            dispatch(setLoading(false));
        }
    };

    return (
        <Menu
            open={props.open}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            PaperProps={{ sx: { transform: 'translateX(-10px) !important' } }}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
        >
            {
                itinerary_type_details_data?.variant_name?.filter((variant) => {
                    return variant.version === data_trip?.id;
                })?.map((variant, index) => {
                    return (
                        <MenuItem key={`${variant.version}-${variant.id}`} onClick={() => onStatusChange(variant.id)}>
                            {
                                (
                                    (isNumber(props.value.variant) && props.value.variant === variant.id) ||
                                    (!isNumber(props.value.variant) && props.value.variant?.id === variant.id)
                                ) &&
                                <ListItemIcon>
                                    <Check color="success" />
                                </ListItemIcon>
                            }
                            <ListItemText
                                inset={
                                    (isNumber(props.value.variant) && props.value.variant !== variant.id) ||
                                    (!isNumber(props.value.variant) && props.value.variant?.id !== variant.id)
                                }
                            >
                                {
                                    variant.name[locale] ??
                                    Object.values(variant.name)[0] ??
                                    t('shared.circuit-variant-num', {no: index + 1})
                                }
                            </ListItemText>
                        </MenuItem>
                    );
                })
            }
        </Menu>
    );
}
