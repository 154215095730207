//Dependencies
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    select: {
        width: '100%'
    }
});

const CartManualProductFormPrice = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([{
        custom_currency: [],
        id: 47,
        international_name: "Euro",
        iso_code: "EUR",
        name: "Euro",
        symbol: "€",
        usd_factor: "0.84280100"
    }]);
    const loading = open && options.length === 0;
    const manual_item_list = useSelector(state => state.cart.manual_item_list);
    const manual_product_id = useSelector(state => state.cart.manual_product_id);
    const currency_list = useSelector(state => state.base.currency_list);
    const margin_type = useSelector(state => state.cart.manual_product_margin_type);
    const margin_value = useSelector(state => state.cart.manual_product_margin_value);
    const purchase_price = useSelector(state => state.cart.manual_product_purchase_price);
    const purchase_currency = useSelector(state => state.cart.manual_product_purchase_currency);
    const product_type = useSelector(state => state.cart.manual_product_type);
    const manual_product_poi = useSelector(state => state.cart.manual_product_poi);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const onPurchasePriceChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_PURCHASE_PRICE', payload: event.target.value });
    };
    const onPurchaseCurrencyChange = (event, value) => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_PURCHASE_CURRENCY', payload: value });
    };
    const onMarginTypeChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_MARGIN_TYPE', payload: event.target.value });
    };
    const onMarginValueChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_MARGIN_VALUE', payload: event.target.value });
    };
    const onSearchChange = event => {
        setSearch(event.target.value);
    };
    useEffect(() => {
        if (!open) {
            setOptions([{
                custom_currency: [],
                id: 47,
                international_name: "Euro",
                iso_code: "EUR",
                name: "Euro",
                symbol: "€",
                usd_factor: "0.84280100"
            }]);
        }
    }, [open]);
    useEffect(() => {
        if (purchase_currency !== null) {
            setOptions([...options, currency_list.find(currency => currency.id === purchase_currency)]);
        }
    }, [purchase_currency]);
    if (manual_product_id !== null) {
        for (let i = 0; i < manual_item_list.length; i++) {
            if (manual_item_list[i].id === manual_product_id) {
                if (
                    manual_item_list[i].auto_product &&
                    (
                        !manual_item_list[i].is_price_editable ||
                        (
                            user?.is_seller_user &&
                            !manual_item_list[i].is_price_editable_seller
                        )
                    )
                ) {
                    return null;
                }
            }
        }
    }
    if (manual_product_poi) {
        return null;
    }
    return (
        <Fragment>
            <Grid item xs={ 12 }>
                <Typography>{ product_type !== 20 && product_type !== 21 ? t('global.price') : t('shared.margin') } :</Typography>
            </Grid>
            {

                !(product_type === 18 && ['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code)) && product_type !== 20 && product_type !== 21 && (
                    <Fragment>
                        <Grid item xs={ 6 }>
                            <FormControl fullWidth variant={ 'outlined' }>
                                {
                                    props.isForProposition ?
                                        <InputLabel htmlFor="manual-product-purchase-price">
                                            {t('cart-material.cart-construction-package-selling-price-provider')}
                                        </InputLabel> :
                                        <InputLabel htmlFor={ 'manual-product-purchase-price' }>
                                            {product_type !== 61 ? t('cart-material.manual-product-purchase-price') : t('cart.buying_price_HT')}
                                        </InputLabel>
                                }
                                <OutlinedInput
                                    id={ 'manual-product-purchase-price' }
                                    type={ 'number' }
                                    onChange={ onPurchasePriceChange }
                                    label={
                                        //eslint-disable-next-line no-nested-ternary
                                        props.isForProposition ?
                                            t('cart-material.cart-construction-package-selling-price-provider') :
                                            product_type !== 61 ?
                                                t('cart-material.manual-product-purchase-price') :
                                                t('cart.buying_price_HT')
                                    }
                                    value={ purchase_price }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <Autocomplete
                                id={ 'manual-product-purchase-currency' }
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => `${option.international_name} (${option.iso_code})`}
                                options={ currency_list }
                                loading={ loading }
                                value={ (typeof purchase_currency === "number" ? currency_list.find(currency => currency.id === purchase_currency) : purchase_currency) }
                                onChange={ onPurchaseCurrencyChange }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            props.isForProposition ?
                                                t('cart-material.provider-quotation-currency') :
                                                t('cart-material.manual-product-purchase-currency')
                                        }
                                        variant={ 'outlined' }
                                        onChange={ onSearchChange }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color={ 'inherit' } size={ 20 }/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Fragment>
                )
            }
            {
                product_type !== 20 && product_type !== 21 && !props.isForProposition &&
                <Grid item xs={ 6 }>
                    <FormControl fullWidth variant={ 'outlined' }>
                        <InputLabel id={ 'manual-product-margin-type-label' }>{ t('cart-material.manual-product-margin-type') }</InputLabel>
                        <Select className={ classes.select } labelId={ 'manual-product-margin-type-label' } label={ t('cart-material.manual-product-margin-type') } value={ margin_type } onChange={ onMarginTypeChange }>
                            <MenuItem value={ 'FIX' }>{ t('cart-material.manual-product-margin-fix') }</MenuItem>
                            <MenuItem value={ 'PER' }>{ t('cart-material.manual-product-margin-per') }</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            }
            {
                !props.isForProposition &&
                 <Grid item xs={ 6 }>
                     <FormControl fullWidth variant={ 'outlined' }>
                         <InputLabel htmlFor={ 'manual-product-margin-value' }>{ t('cart-material.manual-product-margin-value') }</InputLabel>
                         <OutlinedInput
                             id={ 'manual-product-margin-value' }
                             type={ 'number' }
                             onChange={ onMarginValueChange }
                             label={ t('cart-material.manual-product-margin-value') }
                             value={ margin_value }
                         />
                     </FormControl>
                 </Grid>
            }
        </Fragment>
    );
};

export default CartManualProductFormPrice;
