import axios from "axios";
import memoizee from "memoizee";
import { flatten } from "lodash";
import { getItinerary } from "./getItinerary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { ItineraryState } from "../objects/itineraryState";

type Options = {
    circuitId: number,
    tripId: number,
    version: number,
    contractIds: number[],
    source: string | null
}

async function get(options: Options): Promise<ItineraryState['blocks']['circuits']['cache'][number][number]> {
    let products: ItineraryState['blocks']['circuits']['cache'][number][number]['products'] = [];
    let steps: ItineraryState['blocks']['circuits']['cache'][number][number]['steps'] = [];

    const { headers } = CheckBeforeRequest();

    const pricesPromise = Promise.all(
        options.contractIds.map((contractId) => {
            return Promise.all([
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/circuits/${options.circuitId}/contracts/${contractId}/product-period/`,
                    params: {
                        trip_version: options.version,
                        margin: options.source !== 'GESTOUR',
                        limit: 10000,
                        cms_reduced: options.source === 'GESTOUR'
                    }
                }),
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/circuits/${options.circuitId}/contracts/${contractId}/prices-terrestrial/`,
                    params: {
                        trip_version: options.version,
                        margin: options.source !== 'GESTOUR',
                        limit: 10000,
                        cms_reduced: options.source === 'GESTOUR'
                    }
                }),
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/circuits/${options.circuitId}/contracts/${contractId}/prices-flight/`,
                    params: {
                        trip_version: options.version,
                        margin: options.source !== 'GESTOUR',
                        limit: 10000,
                        cms_reduced: options.source === 'GESTOUR'
                    }
                })
            ]);
        })
    );
    const itineraryPromise = getItinerary(options.tripId, options.version);
    const productsPromise = Promise.all([
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/accommodation/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/assistance/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/custom-products/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/manual-products/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/flight/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/transfers/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/car/`
        }),
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/insurance/`
        })
    ]);

    const [periods, priceTerrestrial, priceFlight] = (await pricesPromise).reduce((prev, current) => {
        return [
            prev[0].concat(current[0].data.results),
            prev[1].concat(current[1].data),
            prev[2].concat(current[2].data)
        ]
    }, [[], [], []] as [any[], any[], any[]]);
    steps = (await itineraryPromise) ?? [];
    products = flatten(
        (await productsPromise).map((item) => {
            return item.data;
        })
    );

    return {
        products,
        periods,
        priceTerrestrial,
        priceFlight,
        steps
    };
}

export const getBlockPackageData = memoizee(
    get,
    {
        promise: true,
        normalizer(args) {
            return `${args[0].tripId}-${args[0].version}-${args[0].circuitId}`;
        }
    }
);
