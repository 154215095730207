import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import React, {Fragment} from "react";
import Close from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import GetCookie from "../../../Components/Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { DialogActions, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { ActivitiesQuestions } from "../../BookingMaterial/ActivitiesQuestions";

const useStyles = makeStyles({
    orangeButton: {
        backgroundColor: '#E6592F !important',
        color: 'white !important'
    },
    cancelButton: {
        marginTop: 8,
        backgroundColor: 'white !important',
        border: '1px solid #E6592F !important',
        color: '#E6592F !important'
    },
});

const PoiQuestion = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const open = useSelector(state => state.poi.open_cart_questions);
    const questions = useSelector(state => state.poi.cart_questions);
    const cart_questions_id = useSelector(state => state.poi.cart_questions_id);
    const poi_cart = useSelector(state => state.poi.cart);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const activity = poi_cart.find(el => el.id === cart_questions_id);

    const onClose = () => {
        dispatch({type: 'MATERIAL_POI_CLOSE_QUESTIONS'});
    };
    const onValidate = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/custom-products/${cart_questions_id}/`,
            data: {
                questions: questions
            }
        }).then((response) => {
            dispatch({type: 'MATERIAL_POI_CLOSE_QUESTIONS'});
            dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
        }).catch((error) => {
            console.log(error);
            dispatch({type: 'MATERIAL_POI_CLOSE_QUESTIONS'});
        });
    };
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton> { t("poi.cart_questions") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item xs>
                                    { t("poi.cart_questions") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ onClose }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    // questions.map((question, index_question) => (
                    //     <Fragment key={ `poi-question-container-${question.id}` }>
                    //         <div>
                    //             <FormControl fullWidth variant={ 'outlined' }>
                    //                 <InputLabel htmlFor={ `poi-question-${question.id}` }>{ question.question_text }</InputLabel>
                    //                 <OutlinedInput
                    //                     id={ `poi-question-${question.id}` }
                    //                     type={ 'text' }
                    //                     label={ question.question_text }
                    //                     defaultValue={ question.answer_text !== null ? question.answer_text : '' }
                    //                     onChange={(e) => { questions[index_question].answer_text = e.target.value }}
                    //                 />
                    //             </FormControl>
                    //         </div>
                    //         <br/>
                    //     </Fragment>
                    // ))
                }
                {
                    activity !== undefined && activity !== null &&
                    <ActivitiesQuestions product={activity} tempTravelers={null} setTempTravelers={null}/>
                }
            </DialogContent>
            {/* <DialogActions>
                <Button variant={ 'contained' } className={ classes.cancelButton } onClick={ onClose }>{ t('global.cancel') }</Button>
                <Button variant={ 'contained' } className={ classes.orangeButton } onClick={ onValidate }>{ t('global.validate') }</Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default PoiQuestion;
