//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import { Fab, Skeleton, Tab, Tabs } from '@mui/material';

import ArticleIcon from '@mui/icons-material/Article';
import RoomIcon from '@mui/icons-material/Room';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import CustomHomeSlider from '../../Home/CustomHomeSlider.jsx';
import MaterialHeader from '../../Header/MaterialHeader.jsx';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest.js';
import MaterialBreadcrumb from '../MaterialBreadcrumb.js';
import { SetHistorydest, SetItineraryTypeDetails } from '../../../Actions/ItineraryType.js';
import { SetCurrentStep } from '../../../Actions/Summary.js';

import MaterialDuplicateDialog from '../MaterialDuplicateDialog.js';
import ItineraryTypeDetailsMap from "../../ItineraryTypeDetails/ItineraryTypeDetailsMap.jsx";
import GetAllInfoItiDetails from '../../ItineraryTypeDetails/Functions/GetAllInfoItiDetails.js';
import DetailStep from './DetailStep.tsx';
import DetailDay from './DetailDay.js';
import ItiTypeRightPanel from './ItiTypeRightPanel.js';
import Scroll from '../../Common/Scroll.js';
import Help from "../../Base/Help";
import HotelInfo from '../PackagesMaterial/HotelInfo.tsx';
import MaterialLogin from '../../Home/MaterialLogin.js';
import ByDayPicture from '../CircuitsMaterial/ByDayPicture.tsx';
import { ItinerarySummary }  from './ItinerarySummary.tsx';
import i18n from '../../../i18n.jsx';
import localeText from '../../Functions/localeText.js';

//---- CSS ----//
import "../../../Style/Home.css";
import "../../../Style/Global.css";
import "../../../Style/GlobalAgencies.css";
import RightPanel from '../PackagesMaterial/RightPanel.tsx';

const config = JSON.parse(localStorage.getItem("config"));
const quotation_code = config.quotation_code;
const main_color = config.main_color;

const useStyles = makeStyles(theme => ({
    breadCrumb: {
        maxWidth: 1200,
        marginTop: 80,
        marginBottom: 10,
        zIndex: 90,
        position: "relative"
    },
    container: {
        maxWidth: 1200,
        paddingTop: 15,
        backgroundColor: '#F5F5F5'

    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh",
        position: 'relative'
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        "background": "#e6592f",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    customButtonV: {
        "background": "#BF202D",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        //marginBottom: 50,
        fontWeight: 400
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    bold: {
        fontWeight: "bold"
    },
    tabs: {
        "& .MuiTab-root": {
            fontWeight: 'bold'
        },
        "& .MuiTabs-indicator": {
          height: 4,
        },
        "& .MuiTab-root.Mui-selected": {
          color: `${main_color}`,
        },
        position: 'sticky',
        top: 7,
        zIndex: 1000,
    },
    fabColor: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
        backgroundColor: `${main_color} !important`,
        color: 'white !important',
        "&:hover": {
            backgroundColor: `${main_color} !important`
        }
    },
    coverImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        height: "100%"
    },
    coverImgMap: {
        width: "100%",
        height: 300
    },
    paddingRight0: {
        paddingRight: '0px !important'
    },
    basicLink: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: `${main_color} !important`,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    basicLinkV: {
        fontFamily: "Roboto",
        cursor: "pointer",
        color: "#BF202D !important",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    wrapIcon: {
        verticalAlign: "top",
        display: "inline-flex",
        marginRight: 2
    },
    orange: {
        fontFamily: "Roboto",
        color: `${main_color} !important`
    },
    red: {
        fontFamily: 'Roboto',
        color: '#BF202D !important'
    },
    carouselAccommodationFullScren: {
        position: 'relative',
        float: 'left',
        width: '100%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodation: {
        position: 'relative',
        float: 'left',
        width: '50%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodationAlt: {
        width: '120% !important',
        overflow: 'hidden',
        display: 'flex',
        position: 'unset'
    },
    carouselAccommodationInteractions: {
        position: 'absolute',
        width: '96%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px'
    },
    carouselAccommodationInteractionsSvg: {
        cursor: 'pointer',
        fontSize: '22pt',
        backgroundColor: '#0000004f',
        color: 'white'
    },
    firstImg: {
        marginRight: '2%',
        width: '60% !important'
    },
    secondImg: {
        width: '49% !important'
    },
    containImage: {
        width: '100%',
        objectFit: 'contain'
    },
    onePicture: {
        margin: "15px 0",
        paddingLeft: '2em'
    },
    multiplePictures: {
        margin: "1.5em 0",
        position: "relative",
        overflow: "hidden",
        paddingLeft: '2em'
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        marginRight: 7,
        marginLeft: 0,
        marginTop: 10
    }
}));

const ItiTypeDetails = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { pass_check, headers } = CheckBeforeRequest();

    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data) ??
        props.itinerary_type_details_data ??
        null;
    const is_connected = useSelector(store => store.user.is_connected);
    const current_step = useSelector(store => store.summary.current_step);
    const user = useSelector(store => store.user.user);
    const itinerary = useSelector(store => store.itinerary_type.itinerary);
    const flight_cart = useSelector(store => store.itinerary_type.flight_cart);
    const poi_cart = useSelector(store => store.summary.poi_cart);
    const hotel_cart = useSelector(store => store.itinerary_type.hotel_cart);
    const all_hotels = useSelector(store => store.itinerary_type.all_hotels);
    const cars_cart = useSelector(store => store.itinerary_type.cars_cart);
    const manual_cart = useSelector(store => store.itinerary_type.manual_cart);
    const transfers_cart = useSelector(store => store.summary.transfers_cart);
    const guide_list = useSelector(store => store.itinerary_type.guide_list);
    const dest_history = useSelector(store => store.itinerary_type.dest_history);
    const locales = useSelector(state => state.user.locales);
    let history = [];
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isItiLoading, setIsItiLoading] = useState(false);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [mobileDialog, setMobileDialog] = useState(false);
    const [products, setProducts] = useState([]);
    const [sortProducts, setSortProducts] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [flights, setFlights] = useState([]);
    const [poi, setPoi] = useState([]);
    const [cars, setCars] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [assurances, setAssurances] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [totalDays, setTotalDays] = useState(0);
    const [totalNights, setTotalNights] = useState(0);
    const [isByDay, setIsByDay] = useState(false);
    const [travelFlight, setTravelFlight] = useState(null);
    const [loginDialog, setLoginDialog] = useState(false);
    const [tabValue, setTabValue] = useState('summary');
    const [byDayPicures, setByDayPictures] = useState([]);

    const topContainerRef = useRef(null);
    const contentRef = useRef([]);
    const mapRef = useRef(null);
    const pricesRef = useRef(null);
    const summaryCircuitRef = useRef(null);
    const itiRef = useRef(null);
    const photoRef = useRef(null);
    useEffect(() => {
        console.log('init iti type');
        dispatch({ type: "ITINERARY_SET_TYPE", payload: "iti type" });
        // if (itinerary_type_details_data === null || (itinerary_type_details_data !== null && itinerary_type_details_data.id !== parseInt(props.params.itinerary_type_id))) {
            getTripFromURL();
        // } else {
        //     setIsLoading(true);
        //     setIsItiLoading(true);
        //     //GetProducts(itinerary_type_details_data.trip.id, itinerary_type_details_data.trip.current_version, itinerary_type_details_data.trip.token, setProducts, dispatch, false);
        //     setNewBreadCrumbs(itinerary_type_details_data.data[0].destination.id, itinerary_type_details_data.name);
        //     // getQuotationType(itinerary_type_details_data);
        //     // checkDays(itinerary_type_details_data);
        //     GetAllInfoItiDetails(itinerary_type_details_data.id, itinerary_type_details_data.current_version, itinerary_type_details_data.token, setIsItiLoading, dispatch, setProducts);
        // }
        return () => {
            dispatch({ type: "ITINERARY_SET_TYPE", payload: "" });
            dispatch(SetItineraryTypeDetails(null));
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", checkCurrentStep, false);

        return function cleanup() {
            window.removeEventListener("scroll", checkCurrentStep);
        };
    });

    useEffect(() => {
        if (flight_cart.length > 0 && itinerary_type_details_data !== null) {
            let trip_start_date = itinerary_type_details_data.data[0].start_date;
            let trip_end_date = itinerary_type_details_data.data[0].end_date;
            flight_cart.map((flight) => {
                if (moment(flight.start_date, "YYYY-MM-DD").isSame(moment(trip_start_date, "YYYY-MM-DD"), "day") && moment(flight.end_date, "YYYY-MM-DD").isSame(moment(trip_end_date, "YYYY-MM-DD"), "day")) {
                    setTravelFlight(flight);
                }
            });
        }
    }, [flight_cart]);

    useEffect(() => {
        contentRef.current = contentRef.current.slice(0, itinerary.length);
        let pictures = [];
        itinerary.map((step) => {
            if (step !== null && step.destination !== null) {
                pictures = [...pictures, ...step.destination.data.pictures];
            }
        });
        setByDayPictures(pictures);
    }, [itinerary]);
    useEffect(() => {
        const { pass_check, headers } = CheckBeforeRequest();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (itinerary_type_details_data !== null && pass_check) {
            let data = itinerary_type_details_data.data.find(el => el.id === itinerary_type_details_data.current_version);
            if (data.start_date !== null && data.end_date !== null) {
                setTotalDays(moment.utc(data.end_date).diff(moment.utc(data.start_date), "d") + 1);
            }
            if (data.nights !== undefined && data.nights !== null) {
                setTotalNights(data.nights);
            }
        }
        return () => {
            source.cancel();
        }
    }, [itinerary_type_details_data]);
    useEffect(() => {
        if (products.length > 0) {
            let sort_products = [];
            let tmp_nb_active_hotel = 0;
            let tmp_hotel = [];
            let tmp_flight = [];
            let tmp_transfers = [];
            let tmp_linked_dest = [];
            let tmp_assurances = [];
            let tmp_options = [];
            let tmp_poi = [];
            let tmp_cars = [];
            let tmp_simple_product = [];
            let tmp_currency_symbol = "-";
            let tmp_currency_iso = null;
            let tmp_flight_included = false;
            let selected_variant = [];

            tmp_flight_included = itinerary_type_details_data !== null ? itinerary_type_details_data.flight_included : false;
            for (let i = 0; i < products.length; i++) {
                // if (!products[i].is_optional) {
                    if (products[i].product_type === 0 || products[i].product_type === 7) {
                        if (!products[i].is_manual) {
                            let same = false;
                            sort_products.map(sort_item => {
                                if ((sort_item.product_type === 0 || sort_item.product_type === 7) && products[i].is_displayed && sort_item.hotel[0].id === products[i].hotel[0].id && (sort_item.provider === products[i].provider || client_type === 2) && sort_item.start_date === products[i].start_date &&
                                    sort_item.end_date === products[i].end_date && sort_item.is_available === products[i].is_available && (sort_item.variant === products[i].variant || (products[i].variant === null && products[i].is_optional)) && sort_item.trip_version === products[i].trip_version) {
                                    same = true;
                                    sort_item.rooms.push(products[i]);
                                    sort_item.rooms = sort_item.rooms.sort((a, b) => {
                                        return a.room_ordering - b.room_ordering
                                    });
                                }
                            });
                            if (!same) {
                                sort_products.push({...products[i], rooms: [products[i]]});
                            }
                        }
                        // if (products[i].is_optional === false) {
                            // tmp_nb_active_hotel++;
                            tmp_hotel.push(products[i]);
                        // }
                    }
                    if (products[i].product_type === 8 && products[i].is_optional === true) {
                        tmp_assurances.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    if (products[i].product_type === 6) {
                        tmp_flight.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    if (products[i].product_type === 11 || products[i].product_type === 12) {
                        tmp_poi.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    if (products[i].product_type === 2) {
                        tmp_cars.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    if (products[i].product_type === 4) {
                        tmp_transfers.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    if (products[i].product_type === 8) {
                        tmp_assurances.push(products[i]);
                        sort_products.push(products[i]);
                    }
                    // let variant = products[i].variant !== null ? products[i].variant : 0;
                    // if (!variant_available.includes(variant)) {
                    //     variant_available.push(variant);
                    // }
                    selected_variant.push(products[i].id);
                // }
                // if (products[i].is_optional === true && products[i].product_type !== 8) {
                //     tmp_options.push(products[i]);
                // }
            }
            if (itinerary_type_details_data !== null && itinerary_type_details_data.trip?.currency !== undefined && itinerary_type_details_data.trip?.currency !== null) {
                tmp_currency_symbol = itinerary_type_details_data.trip.currency.symbol;
                tmp_currency_iso = itinerary_type_details_data.trip.currency.iso_code;
            }
            if (itinerary_type_details_data !== null && itinerary_type_details_data.currency !== undefined && itinerary_type_details_data.currency !== null) {
                tmp_currency_symbol = itinerary_type_details_data.currency.symbol;
                tmp_currency_iso = itinerary_type_details_data.currency.iso_code;
            }
            tmp_hotel = tmp_hotel.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_transfers = tmp_transfers.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_cars = tmp_cars.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_flight = tmp_flight.sort((a, b) => {
                return a.start_date < b.start_date;
            });
            tmp_poi = tmp_poi.sort((a, b) => {
                return a.start_date < b.start_date;
            });

            const base_arr = [0, 7, 4, 2, 11, 12, 1];

            tmp_options = tmp_options.sort((a, b) => {
                return base_arr.indexOf(a.product_type) - base_arr.indexOf(b.product_type);
            });

            setSortProducts(sort_products);
            checkVariantProduct();
            setHotels(tmp_hotel);
            setFlights(tmp_flight);
            setCars(tmp_cars);
            setPoi(tmp_poi);
            setTransfers(tmp_transfers);
            setOptions(tmp_options);
            setAssurances(tmp_assurances);
            // setSelectedVariant(selected_variant);
        }
    }, [products]);
    const checkVariantProduct = () => {
        let new_product = products.filter((el) => {
            return !el.is_optional;
        });
        let selected_variant = [];
        new_product.map((el) => {
            selected_variant.push(el.id);
        });
        setSelectedVariant(selected_variant);
    };
    const checkCurrentStep = () => {
        let scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        for (let i = 0; i < contentRef.current.length; i++) {
            if (contentRef.current[i] !== undefined && contentRef.current[i] !== null && contentRef.current[i].getBoundingClientRect().top > 500) {
                if (i === 0) {
                    if (itinerary[0].step_type === 'STEP') {
                        dispatch(SetCurrentStep(0));
                    }
                    break;
                } else {
                    let prev_item = i - 1;
                    if (prev_item !== current_step) {
                        dispatch(SetCurrentStep(prev_item));
                    }
                }
                break;
            }
            //if (i === contentRef.current.length - 1) {
            //dispatch(SetCurrentStep(i));
            //}
        }
        if (window.pageYOffset === scrollHeight - 937) {
            dispatch(SetCurrentStep(contentRef.current.length - 1));
        }
    };
    const variantIsChecked = (product) => {
        return selectedVariant.includes(product.id);
    }
    const setNewBreadCrumbs = (id, name) => {
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/breadcrumbs/`
            }).then(function (response) {
                let data = response.data;
                history = [];
                let is_main = getDestinationPositionOnBreadcrumbs(data.child, id);
                if (is_main.type !== undefined) {
                    let tmp_arr = [];
                    let tmp_obj = {
                        name: t("home.back_home"),
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj);
                    for (let i = 0; i < history.length; i++) {
                        tmp_obj = {
                            name: history[i].international_name,
                            data: history[i],
                            need_redirect: true
                        };
                        tmp_arr.push(tmp_obj);
                    }
                    let tmp_obj2 = {
                        name: name,
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj2);
                    dispatch(SetHistorydest(tmp_arr));
                    setIsLoading(false);
                }
            }).catch(function (error) {
                console.log('err:', error);
            });
        }
    };
    const getTripFromURL = () => {
        if (pass_check) {
            setIsLoading(true);
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${props.params.itinerary_type_id}/?typical=1`
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                console.log('iti type data:', data);
                setNewBreadCrumbs(data.data[0].destination.id, data.name);
                GetAllInfoItiDetails(data.id, data.current_version, data.token, setIsItiLoading, dispatch, setProducts);
                // checkDays(data);
                dispatch(SetItineraryTypeDetails(data));
                // getQuotationType(data);
            }).catch(function (error) {
                console.log('error:', error);
            });
        }
    };
    // const getQuotationType = (data) => {
    //     if (pass_check) {
    //         axios({
    //             method: "GET",
    //             headers: headers,
    //             url: `${API_HREF}client/${window.id_owner}/destinations/${data.data[0].destination.id}/get_quotation_type/`
    //         }).then(function (response) {
    //             let data = Object.assign({}, response.data);
    //             console.log('data quotation:', data);
    //             let is_day_by_day = (data.step_by_step === true || data.step_by_step === null) ? false : true;
    //             setIsByDay(is_day_by_day);
    //         }).catch(function (error) {
    //             console.log('error:', error);
    //         });
    //     }
    // };
    // const checkDays = (data) => {
    //     let tmp_total_days = moment.utc(data.data[0].end_date).diff(moment.utc(data.data[0].start_date), "d") + 1;
    //     let tmp_total_nights = 0;
    //     if (data.data[0].start_date !== null && data.data[0].end_date !== null) {
    //         tmp_total_nights = moment.utc(data.data[0].end_date).diff(moment.utc(data.data[0].start_date), "d");
    //     }
    //     for (let i = 1; i < data.length; i++) {
    //         if (!moment.utc(data[i - 1].end_date).isSame(moment.utc(data[i].start_date), "day")) {
    //             tmp_total_nights -= 1;
    //         }
    //     }
    //     setTotalDays(tmp_total_days);
    //     setTotalNights(tmp_total_nights);
    // };
    const getDestinationPositionOnBreadcrumbs = (data, id_to_find) => {
        if (data.id !== parseFloat(id_to_find)) {
            if (data.data.type !== 0) {
                history.push(data.data);
            }
            return (getDestinationPositionOnBreadcrumbs(data.child, id_to_find));
        } 
        history.push(data.data);
        return (data.data);
    };
    const handleDuplicateOpen = () => {
        setDuplicationOpen(!duplicationOpen);
    };
    const checkScreen = () => {
        if (is_connected) {
            if (!fullScreen) {
                handleDuplicateOpen();
            } else {
                setMobileDialog(true);
            }
        } else {
            setLoginDialog(true);
        }
    };
    const scrollToTop = () => {
        mapRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };
    const handleScroll = (value) => {
        switch (value) {
            case 'summary':
                setTimeout(() => {
                    summaryCircuitRef.current.scrollIntoView({
                        // behavior: "smooth",
                        block: "center",
                        inline: "start"
                    });
                }, 250);
                break;
            case 'itinerary':
                itiRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
            case 'pictures':
                photoRef.current.scrollIntoView({
                    // behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
                break;
        }
    }
    const onTabChange = (event, newValue) => {
        event.preventDefault();
        handleScroll(newValue);
        setTabValue(newValue);
    }
    const onTabClick = (value) => () => {
        if (value === tabValue) {
            handleScroll(value);
        }
    }
    const goBack = () => {
        if (dest_history.length > 1) {
            props.router.push(`/${window.url_name}/destination-product/${ dest_history[1].data.destination}`);
        } else {
            // dispatch(RemoveXHistorydest(dest_history.length - (index_dest + 1), null));
            props.router.push(`/home/${window.url_name}`);
        }
    }
    const onToggleVariant = product => () => {
        let variant_arr = selectedVariant.slice();
        let start_destination = product.start_destination.data.id;
        let start_date = product.start_date;
        let end_date = product.end_date;

        if (product.product_type === 0) {
            hotels.map((hotel) => {
                if (hotel.start_destination.data.id === start_destination && hotel.start_date === start_date && variant_arr.includes(hotel.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== hotel.id;
                    });
                    variant_arr.push(product.id);
                }
            })
        } else if (product.product_type === 4) {
            transfers.map((transfer) => {
                if (transfer.start_destination.data.id === start_destination && moment(transfer.start_date).isSame(moment(start_date), "day") && variant_arr.includes(transfer.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== transfer.id;
                    });
                } else {
                    variant_arr.push(product.id);
                }
            })
        } else if (product.product_type === 2) {
            cars.map((car) => {
                if (car.start_destination.data.id === start_destination && moment(car.start_date).isSame(moment(start_date), "day") && variant_arr.includes(car.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== car.id;
                    });
                } else {
                    variant_arr.push(product.id);
                }
            })
        } else if (product.product_type === 6) {
            let find_product = flights.find((el) => {
                return el.start_destination.data.id === start_destination && moment(el.start_date).isSame(moment(start_date), "day") && variant_arr.includes(el.id)
            });
            if (find_product !== undefined) {
                variant_arr = variant_arr.filter((el) => {
                    return el !== find_product.id;
                });
            } else {
                variant_arr.push(product.id);
            }
        }
        else {
            if (variant_arr.includes(product.id)) {
                variant_arr = variant_arr.filter((el) => {
                    return el !== product.id;
                });
            }
            else {
                variant_arr.push(product.id);
            }
        }
        setSelectedVariant(variant_arr);
    }
    let data = itinerary_type_details_data !== null ? itinerary_type_details_data.data[0] : null;
    return (
        <Grid container style={{ display: "block", backgroundColor: '#F5F5F5', scrollBehavior: 'smooth' }}>
            {
                // !fullScreen && (
                //     <Scroll showBelow={400}/>
                // )
            }
            {
                !props.contentOnly &&
                <Container className={classes.breadCrumb}>
                    <Grid container direction={"row"} alignItems={"center"} spacing={4} >
                        <Grid item >
                            {/* <MaterialBreadcrumb router={props.router}/> */}
                            <span className={classes.wrapIcon}>
                                <ArrowBackIcon className={clsx({[classes.red]: ['visiteurs'].includes(quotation_code), [classes.orange]: !['visiteurs'].includes(quotation_code)})} size={"small"}/>
                            </span>
                            <span className={clsx({[classes.basicLinkV]: ['visiteurs'].includes(quotation_code), [classes.basicLink]: !['visiteurs'].includes(quotation_code)})} onClick={goBack}>{t('itineraryType.return_to_product_list')}</span>
                        </Grid>
                    </Grid>
                </Container>
            }
            <Grid item className={"wrapper"} className={classes.headerPicture}>
                <CustomHomeSlider height={"Packages"} pictures={(itinerary_type_details_data !== null && itinerary_type_details_data.pictures !== null) ? [...itinerary_type_details_data.pictures] : []} />
                <Grid item className={classes.absoluteCenter} style={{ width: fullScreen ? "100%" : "auto" }}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                        {
                            itinerary_type_details_data !== null && (
                                <Grid item container direction={"column"} justify={"center"} alignItems={"center"} style={{ marginBottom: 50 }}>
                                    <Grid item>
                                        <Typography variant={"h2"} className={classes.destinationTitle}>{localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.name, 'name')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"h5"} style={{ color: "white", textShadow: "black 1px 0 10px", textAlign: "center" }} className={classes.genericText}>{ data.destination !== null && data.destination.data.country !== undefined && data.destination.data.country !== null ? data.destination.data.country.name + " - " : "" }{ data.destination !== null ? data.destination.data.name !== undefined ? data.destination.data.name : data.destination.data.international_name : "" }</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            // !props.contentOnly &&
                            // <Grid item>
                            //     <Button variant={"contained"} fullWidth={fullScreen} color={"primary"} className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`} onClick={checkScreen} >{t("itineraryType.choose_package")}</Button>
                            // </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                !props.contentOnly &&
                <Grid item className={classes.headerContainer} id={"home-picture"} style={{ display: "block" }}>
                    <Grid container direction={"row"} justify={"flex-end"}>
                        <Grid item className={"home-nav"} id={"home-nav"} style={{ top: 0 }}>
                            {
                                !is_connected && (
                                    <Fragment>
                                        <Grid item style={{ position: "absolute", left: 16, top: 20 }}>
                                            <Link to={ `/home/${window.url_name}/` } id={ "header-home-link" }>
                                                <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_favicon_header.png" } height={ 30 } alt={ "" }/>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Button style={{ color: "white" }} onClick={() => setLoginDialog(true)}>{t("home.nav.login")}</Button>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                            {
                                is_connected && (
                                    <MaterialHeader router={props.router}/>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && !isLoading && (
                    <Grid item style={{ paddingBottom: fullScreen ? 120 : 100 }} ref={topContainerRef}>
                        <Container className={classes.container}>
                            {
                                // !props.contentOnly &&
                                // <Grid container direction={"row"} alignItems={"center"} spacing={4}>
                                //     <Grid item>
                                //         <MaterialBreadcrumb router={props.router}/>
                                //     </Grid>
                                // </Grid>
                            }
                            {
                                (summaryCircuitRef !== null || itiRef !== null || priceCalendarRef !== null) &&
                                <Tabs
                                    value={tabValue}
                                    onChange={onTabChange}
                                    variant={'fullWidth'}
                                    className={classes.tabs}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: main_color
                                        }
                                    }}
                                    centered
                                >
                                    <Tab value={'summary'} label={t('menu.trip_list.quotation_brief')} onClick={onTabClick('summary')}/>
                                    {itinerary.length !== 0 && <Tab value={'itinerary'} label={t('apps_navigation.itinerary')} onClick={onTabClick('itinerary')}/>}
                                    {byDayPicures.length > 0 && <Tab value={'pictures'} label={t('summary.pictures')} onClick={onTabClick('pictures')}/>}
                                </Tabs>
                            }
                            {
                                itinerary_type_details_data !== null && (
                                    <Grid container direction={"row"} justify={"flex-start"} spacing={4} style={{ marginTop: 20 }}>
                                        <Grid item sm={fullScreen ? 12 : 8} xs={12}>
                                            <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"} spacing={2} >
                                                <Grid item ref={summaryCircuitRef}>
                                                    <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                        <ArticleIcon/>
                                                    </Fab>
                                                    <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t('summary.trip_summary')}</span>
                                                </Grid>
                                                <Grid item style={{width: '99%', marginLeft: 15}}>
                                                    <ItinerarySummary itinerary_type_details_data={itinerary_type_details_data} hotels={hotels} flights={flights} poi={poi} cars={cars} transfers={transfers} isItiLoading={isItiLoading} totalDays={totalDays} totalNights={totalNights}/>
                                                </Grid>
                                                <Grid item style={{ marginBottom: 20, marginTop: 20, width: "103%" }}>
                                                    {
                                                        isItiLoading ? (
                                                            <Skeleton variant={"rect"} width={"100%"} height={500}/>
                                                        ) : (
                                                            <>
                                                            {
                                                                itinerary.length !== 0 &&
                                                                <div id={"map"} ref={mapRef}>
                                                                    <ItineraryTypeDetailsMap contentRef={contentRef} itinerary_data={props.itinerary_data} />
                                                                </div>
                                                            }
                                                            </>
                                                        )
                                                    }
                                                </Grid>
                                                {
                                                    localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.description, 'description') !== '' &&
                                                    <Grid item style={{ width: "104%" }}>
                                                        <Grid container>
                                                            <Grid item xs={12} className={`${classes.genericText} ${classes.textJustify} `}>
                                                                <div className={classes.border} dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, itinerary_type_details_data.localization, itinerary_type_details_data.description, 'description') }}/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item container>
                                            {
                                                isItiLoading && (
                                                    <Grid item container direction={"row"} justify={"center"}>
                                                        <Skeleton variant={"rect"} width={"100%"} height={500}/>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                !isItiLoading &&
                                                <Fragment>
                                                {
                                                    // !isByDay && (
                                                        <DetailStep
                                                            ref={contentRef}
                                                            itinerary={itinerary}
                                                            hotel_cart={hotels}
                                                            cars_cart={cars}
                                                            poi_cart={poi}
                                                            transfers_cart={transfers}
                                                            manual_cart={manual_cart}
                                                            guide_list={guide_list}
                                                            itiRef={itiRef}
                                                            variantIsChecked={variantIsChecked}
                                                            onToggleVariant={onToggleVariant}
                                                            products={sortProducts}
                                                            travelFlight={travelFlight}
                                                        />
                                                    // )
                                                }
                                                {
                                                    // isByDay && (
                                                    //     <DetailDay
                                                    //         ref={contentRef}
                                                    //         itinerary={itinerary}
                                                    //         hotel_cart={hotels}
                                                    //         cars_cart={cars}
                                                    //         poi_cart={poi}
                                                    //         transfers_cart={transfers}
                                                    //         manual_cart={manual_cart}
                                                    //         guide_list={guide_list}
                                                    //         itiRef={itiRef}
                                                    //         variantIsChecked={variantIsChecked}
                                                    //         onToggleVariant={onToggleVariant}
                                                    //         products={sortProducts}
                                                    //         travelFlight={travelFlight}
                                                    //     />
                                                    // )
                                                }
                                                </Fragment>
                                            }
                                            </Grid>
                                            {
                                                byDayPicures.length !== 0 &&
                                                <Grid item ref={photoRef} style={{marginBottom: 20, marginTop: 20}}>
                                                    <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                                        <CameraAltIcon/>
                                                    </Fab>
                                                    <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t('summary.pictures')}</span>
                                                </Grid>
                                            }
                                            {
                                                byDayPicures.length !== 0 && (
                                                    <ByDayPicture pictures={byDayPicures} is_step={false}/>
                                                )
                                            }
                                        </Grid>
                                        {
                                            !fullScreen && (
                                                <Grid item sm={4}>
                                                    <Card className={ `${classes.stickyPaper} ${classes.genericText}` } raised>
                                                        <div style={{textAlign: 'center', fontSize: 20, backgroundColor: main_color, color: 'white', fontWeight: 'bold', padding: 10}}>{t('shared.trip-summary')}</div>
                                                        {/* <ItiTypeRightPanel
                                                            checkScreen={checkScreen}
                                                            contentOnly={props.contentOnly}
                                                        /> */}
                                                        <RightPanel
                                                            checkScreen={checkScreen}
                                                            options={options}
                                                            assurances={assurances}
                                                            selectedOptions={selectedOptions}
                                                            setSelectedOptions={setSelectedOptions}
                                                            pricesRef={pricesRef}
                                                            // setSalePitch={setSalePitch}
                                                            // dateIsAfter={dateIsAfter}
                                                            // versionAvailable={versionAvailable}
                                                            // setVersionToShow={setVersionToShow}
                                                            // versionToShow={versionToShow}
                                                            setIsItiLoading={setIsItiLoading}
                                                            // variantToShow={variantToShow}
                                                            // variantAvailable={variantAvailable}
                                                            // setVariantToShow={setVariantToShow}
                                                            topContainerRef={topContainerRef}
                                                            itinerary_data={itinerary_type_details_data}
                                                            contentOnly={props.contentOnly}
                                                            selectedVariant={selectedVariant}
                                                            products={products}
                                                            isItiLoading={isItiLoading}
                                                        />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                        {
                                            fullScreen && !props.contentOnly && (
                                                <Grid item xs={12} className={ classes.staticPriceContainer } style={{ width: "100%" }} >
                                                    <Grid container direction={"row"} justify={ "center" }>
                                                        <Grid item>
                                                            <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={ checkScreen } >{t("itineraryType.choose_package")}</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                        </Container>
                    </Grid>
                )
            }
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && isLoading && (
                    <Grid item style={{ paddingBottom: 70 }}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                                <Grid item>
                                    <CircularProgress/>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            <MaterialDuplicateDialog
                duplicationOpen={duplicationOpen}
                setDuplicationOpen={setDuplicationOpen}
                isFrom={"ItineraryType"}
                router={props.router}
                duration_day={totalDays}
                hotels={hotels}
                flights={flights}
                poi={poi}
                assurances={assurances}
                cars={cars}
                selectedVariant={selectedVariant}
                selectedOptions={selectedOptions}
                versionToShow={itinerary_type_details_data !== null ? itinerary_type_details_data.current_version : null}
            />
            <HotelInfo/>
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={null} id={null}/>
            { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
        </Grid>
    );
};
export default ItiTypeDetails;
