import { getLightDestinationName } from "./utils/getLightDestinationName";
import { getDestinationPicture } from "./utils/getDestinationPicture";
import { GeoFlashDestination } from "./objects/geoFlashDestination";
import content from "./utils/itineraryDestinationMarker.html?raw";
import "./utils/itineraryDestinationMarker.css";

type Options = {
    position: google.maps.LatLng,
    info: GeoFlashDestination,
    locale: number
}

export function createItineraryDestinationMarkerClass() {
    return class ItineraryDestinationMarker extends google.maps.OverlayView {
        private position: google.maps.LatLng;
        private container: HTMLDivElement;
        private info: GeoFlashDestination;
        private locale: number;
        private listeners: Partial<{
            click: () => void,
        }> = {};
    
        public constructor(options: Options) {
            super();
            this.position = options.position;
            this.container = document.createElement('div');
            this.container.innerHTML = content;
            this.container.style.display = "none";
            this.container.style.position = "absolute";
            this.container.style.zIndex = '2';
            this.container.style.transform = 'translate(-50%, -100%)';
            this.container.addEventListener(
                'mouseover',
                () => {
                    this.container.style.zIndex = '3';
                }
            );
            this.container.addEventListener(
                'mouseout',
                () => {
                    this.container.style.zIndex = '2';
                }
            );
            this.info = options.info;
            this.locale = options.locale;
            this.renderCover();
            ItineraryDestinationMarker.preventMapHitsAndGesturesFrom(this.container);
        }

        private renderCover(): void {
            const coverElement = this.container.getElementsByClassName('destination-marker-cover')[0];
            if (coverElement) {
                const url = getDestinationPicture(this.info);
                (coverElement as HTMLDivElement).style.backgroundImage = `url(${url})`;
            }

            const textElement = this.container.getElementsByClassName('destination-marker-name')[0];
            if (textElement) {
                (textElement as HTMLDivElement).innerText = getLightDestinationName(
                    this.locale,
                    this.info
                ).split(',')[0] ?? '';
            }
        }

        public onAdd(): void {
            this.getPanes()?.floatPane.appendChild(this.container);
        }
    
        public onRemove(): void {
            if (this.container.parentElement) {
                this.container.parentElement.removeChild(this.container);
            }
        }
    
        public draw(): void {
            let display = 'none';
            const position = this.getProjection().fromLatLngToDivPixel(this.position);
            if (position) {
                display = Math.abs(position.x) < 4000 && Math.abs(position.y) < 4000
                    ? "block"
                    : "none";
            }

            if (display === "block" && position) {
                this.container.style.left = position.x + "px";
                this.container.style.top = position.y + "px";
            }
        
            if (this.container.style.display !== display) {
                this.container.style.display = display;
            }
        }

        public setFeatureListener<K extends keyof ItineraryDestinationMarker['listeners']>(
            key: K,
            callback: NonNullable<ItineraryDestinationMarker['listeners'][K]>
        ): void {
            const currentCallback = this.listeners[key];

            if (currentCallback) {
                this.container.removeEventListener('click', currentCallback);
            }

            this.container.addEventListener('click', callback);
            this.listeners[key] = callback;
        }
    };
}
