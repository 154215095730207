//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useContext } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import green from '@material-ui/core/colors/green';
//Icons
import Close from '@material-ui/icons/Close';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';

//Components
import CartManualProductsManualBooking from './CartManualProductsManualBooking';
import CartDeleteModal from './CartDeleteModal';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartCancellationRules } from './CartCancellationRules';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
import { isHTMLEmpty } from './utils/isHTMLEmpty';
import { isProductPackaged } from './utils/isProductPackaged';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CartManualProductsNote from "./CartManualProductsNote";
import Button from "@material-ui/core/Button";
import FreeBreakfastOutlined from "@material-ui/icons/FreeBreakfastOutlined";
import Restaurant from "@material-ui/icons/Restaurant";
import RoomService from "@material-ui/icons/RoomService";
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
import { ManualProductAccommodationCart } from '../Itinerary/objects/manualProductAccommodationCart';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { Currency } from '../../Reducers/objects/currency';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { AppState } from '../../Reducers/Reducers';
import { ProviderContext } from './utils/providerContext';

type Props = {
    accommodation: ManualProductAccommodationCart
}

export const CartManualAccommodationCardDetails = withRouter<Props>(
    function CartManualAccommodationCardDetails(props): JSX.Element {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();

        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const user = useSelector((state: AppState) => state.user?.user);
        const end_date = useSelector((state: AppState) => state.trip.end_date);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const step = useSelector((state: AppState) => state.cart.step);
        const to_book = useSelector((state: AppState) => state.cart.to_book);
        const [showNote, setShowNote] = useState(false);
        const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
        const [showManualBooking, setShowManualBooking] = useState(false);
        const [margin, setMargin] = useState<{
            id: number | null,
            ids?: {
                room_id: number,
                price_id: number
            }[],
            rate_type: 'PER' | 'FIX',
            purchase: number,
            purchase_converted: number,
            purchase_currency?: Currency,
            factor_used: string,
            value: number,
            static_value?: number,
            markup: boolean,
            percent: number,
            static_percent?: number,
            selling: number,
            selling_currency?: Currency,
            custom_rate?: boolean,
            custom_rate_type?: string
        } | null>(null);
        const [surcom, setSurcom] = useState<{
            value?: number,
            static?: number,
            purchase: number,
            selling: number,
            percent: number,
            currency: Currency | null
        } | null>(null);
        const [onRequest, setOnRequest] = useState(false);
        const [resortFees, setResortFees] = useState<{
            cost: number,
            cost_currency: Currency | null,
            converted_cost: number,
            converted_currency: Currency | null
        } | null>(null);
        const [deleteModal, setDeleteModal] = useState(false);
        const [checkBooking, setCheckBooking] = useState(false);
        const [marginValue, setMarginValue] = useState<number | null>(null);
        const [marginPercent, setMarginPercent] = useState<number | null>(null);
        const providerContext = useContext(ProviderContext);

        const onManualBooking = () => {
            setShowManualBooking(!showManualBooking);
        };

        const onDeleteManualCancellationRules = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    no_refund: true,
                    cancel_condition_text: null
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            }).catch(error => {
                console.log(error);
                setShowManualCancellationRules(!showManualCancellationRules);
            });
        };

        const onAddNote = () => {
            setShowNote(!showNote);
        };

        const onDeleteNote = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    custom_information: null
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
            });
        };

        const onDelete = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`
            }).then(() => {
                dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: props.accommodation.id });
                enqueueSnackbar(t('cart-material.manual-product-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.manual-product-not-deleted'), { variant: 'error' });
            });
        };

        const onValidateManualCancellationRules = (noRefund: boolean, content: string) => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    no_refund: noRefund,
                    cancel_condition_text: isHTMLEmpty(content) ? null : content
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                setShowManualCancellationRules(false);
            }).catch(error => {
                console.log(error);
                setShowManualCancellationRules(false);
            });
        };

        const priceCurrencyConverter = (
            first_price: number,
            first_currency: Currency | undefined,
            second_currency: Currency
        ) => {
            if (first_currency?.iso_code === second_currency.iso_code) {
                return first_price;
            }
            return (first_currency?.iso_code !== 'USD' ? first_price / (first_currency?.usd_factor ?? 1) : first_price) * second_currency.usd_factor;
        };

        const getMargin = (
            tmp_margin: NonNullable<typeof margin>,
            tmp_surcom: NonNullable<typeof surcom>,
            prices: ProductPrice[]
        ) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                        tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
                if (tmp_margin.id === null) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]!.owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                            tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                            tmp_margin.custom_rate = prices[i]!.custom_rate;
                            tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                            tmp_margin.id = prices[i]!.id;
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        };

        const confirmRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    moved_with_recalc: false
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCustomInformationRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    moved_with_recalc_custom_information: false
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCancelConditionRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${props.accommodation.id}/`,
                data: {
                    moved_with_recalc_cancel_condition_text: false
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        useEffect(() => {
            if (marginValue && marginValue !== margin?.value) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.value = marginValue;
                    tmp_margin.rate_type = 'FIX';
                    if (tmp_margin.markup) {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / (tmp_margin.purchase_converted ?? tmp_margin.value)) * 100).toFixed(2));
                    } else {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / (tmp_margin.selling ?? tmp_margin.value)) * 100).toFixed(2));
                    }
                    setMarginPercent(tmp_margin.percent);
                    setMargin(tmp_margin);
                }
            }
        }, [marginValue]);

        useEffect(() => {
            if (marginPercent && marginPercent !== margin?.percent) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.percent = marginPercent;
                    tmp_margin.rate_type = 'PER';
                    if (tmp_margin.markup) {
                        tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                    } else {
                        tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                    }
                    setMarginValue(tmp_margin.value);
                    setMargin(tmp_margin);
                }
            }
        }, [marginPercent]);

        useEffect(() => {
            let resort_fees: {
                cost: number,
                cost_currency: Currency | null,
                converted_cost: number,
                converted_currency: Currency | null
            } | null = null;
            let on_request = false;
            let tmp_margin: NonNullable<typeof margin> = {
                purchase: 0,
                purchase_converted: 0,
                selling: 0,
                value: 0,
                percent: 0,
                custom_rate_type: 'PER',
                rate_type: 'FIX',
                factor_used: '0',
                markup: false,
                id: null
            };
            let tmp_surcom: NonNullable<typeof surcom> = {
                purchase: 0,
                selling: 0,
                value: 0,
                percent: 0,
                currency: null
            };
            if (props.accommodation.on_request) {
                on_request = true;
            }
            getMargin(tmp_margin, tmp_surcom, props.accommodation.prices);
            if (props.accommodation.resort_fees) {
                let resort_fees_currency = currency_list.find(currency => currency.id === props.accommodation.resort_fees_currency);
                resort_fees = {
                    cost: parseFloat(props.accommodation.resort_fees),
                    cost_currency: props.accommodation.resort_fees_currency ? resort_fees_currency ?? null : null,
                    converted_cost: props.accommodation.resort_fees_currency ? priceCurrencyConverter(parseFloat(props.accommodation.resort_fees), resort_fees_currency, currency) : parseFloat(props.accommodation.resort_fees),
                    converted_currency: currency
                };
            }
            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs((tmp_margin.percent) - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs((tmp_margin.percent) - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_surcom.static = tmp_surcom.value;
            tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
            let checked = to_book?.find(book_item => book_item.id === props.accommodation.id);
            if (checked) {
                setCheckBooking(true);
            } else {
                setCheckBooking(false);
            }
            setMargin(tmp_margin);
            setSurcom(tmp_surcom);
            setOnRequest(on_request);
            setResortFees(resort_fees);
        }, [props.accommodation]);

        useEffect(() => {
            const checked = props.accommodation.rooms?.every((item) => {
                return (
                    to_book?.findIndex((product) => {
                        return product.id === item.id;
                    }) ?? -1
                ) >= 0;
            });
            setCheckBooking(Boolean(checked));
        }, [to_book]);

        return (
            <Grid
                id={`product-${props.accommodation.id}`}
                className={classes.scrollMargin}
                container
                item
                xs
                direction={"column"}
                spacing={2}
            >
                <Grid item xs={12}>
                    <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                        {
                            user?.client_full?.type !== 2 &&
                            <Grid item xs={12}>
                                <CartConstructionProviderQuotationStatusLayout
                                    status={
                                        <CartConstructionProductsTableItemProviderQuotationStatus
                                            item={props.accommodation}
                                        />
                                    }
                                    buttons={
                                        <CartPackageItemChangeProviderQuotationStatusButtons
                                            item={{
                                                ...props.accommodation,
                                                type: 'manual',
                                                isCustom: props.accommodation.is_custom
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container item xs spacing={2}>
                    <Grid item xs>
                        <Grid container>
                            <Grid className={classes.gridContent} item xs>
                                {
                                    props.accommodation.rooms?.map((room, room_index) => {
                                        let adults = 0;
                                        let children = 0;
                                        if (room.group_passenger) {
                                            room.group_passenger.travelers_list?.map(traveler => {
                                                if (window.moment.utc(end_date ?? undefined).diff(window.moment.utc(traveler.birth_date), 'y') >= 18) {
                                                    adults += 1;
                                                } else {
                                                    children += 1;
                                                }
                                            });
                                        }
                                        return (
                                            <Fragment key={`manual-product-room-${room_index}`}>
                                                <Typography>
                                                    <strong>{t('global.room')} {room_index + 1} :</strong>{' '}
                                                    {room.name}
                                                    {room.group_passenger ? ` - ${adults > 0 ? `${adults} ${t('global.adults')}` : ''} ${children > 0 ? `${children} ${t('global.children')}` : ''}` : ''}
                                                </Typography>
                                                {
                                                    room.breakfast_included &&
                                                    <div className={classes.containerMargin}>
                                                        <FreeBreakfastOutlined className={classes.roomIcon} /><Typography>{t('accommodation.breakfast_included')}</Typography>
                                                    </div>
                                                }
                                                {
                                                    room.lunch_included &&
                                                    <div className={classes.containerMargin}>
                                                        <Restaurant className={classes.roomIcon} /><Typography>{t('accommodation.lunch_included')}</Typography>
                                                    </div>
                                                }
                                                {
                                                    room.dinner_included &&
                                                    <div className={classes.containerMargin}>
                                                        <RoomService className={classes.roomIcon} /><Typography>{t('accommodation.dinner_included')}</Typography>
                                                    </div>
                                                }
                                                {
                                                    room.all_inclusive &&
                                                    <div className={classes.containerMargin}>
                                                        <RoomService className={classes.roomIcon} /><Typography>{t('cart-material.manual-product-room-all-inclusive')}</Typography>
                                                    </div>
                                                }
                                                {
                                                    room.description_list.map((description, description_index) => (
                                                        <Typography className={classes.description} key={`room-${room_index}-description-${description_index}`}>
                                                            {description}
                                                        </Typography>
                                                    ))
                                                }
                                            </Fragment>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                        {
                            resortFees &&
                            <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                                {t('cart-material.resort-fees-1')}{' '}
                                {resortFees.cost}{resortFees.cost_currency ? resortFees.cost_currency.symbol : ''}{' '}
                                ({t('cart-material.resort-fees-2')} {resortFees.converted_cost}{resortFees.converted_currency ? resortFees.converted_currency.symbol : ''}){' '}
                                {t('cart-material.resort-fees-3')}
                            </Alert>
                        }
                        {
                            props.accommodation.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                            !isProductPackaged({
                                product: props.accommodation,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            onRequest &&
                            props.accommodation.is_available &&
                            <Typography className={clsx(classes.price, { [classes.unavailable]: !props.accommodation.is_available })}>
                                {t('cart-material.on-request')}
                            </Typography>
                        }
                        {
                            props.accommodation.booking_status && props.accommodation.booking_status.status_booking === 'CANCELLED' && props.accommodation.cancellation_charged_amount &&
                            <Typography className={clsx(classes.booking, classes.error)}>
                                {t('flight_search.cancel_fee')} :{' '}
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: currency_list.find((currency) => currency.id === props.accommodation.prices[GetPricesId(props.accommodation.prices, user?.client, user, true)]?.purchase_currency)?.iso_code ?? 'EUR',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(props.accommodation.cancellation_charged_amount)
                                }
                            </Typography>
                        }
                    </Grid>
                    {
                        props.accommodation.booking_status && props.accommodation.booking_status.status_booking === 'UNAVAILABLE' &&
                        <Grid item xs={12}>
                            <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                {t('cart-material.booking-unavailable')} : {props.accommodation.booking_status.item_reference}
                            </Alert>
                        </Grid>
                    }
                    {
                        props.accommodation.booking_status && props.accommodation.booking_status.status_booking === 'ERROR' && props.accommodation.booking_errors && props.accommodation.booking_errors.length > 0 &&
                        <Grid item xs={12}>
                            <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                                {
                                    props.accommodation.booking_errors.map((error) => (
                                        <Typography key={`booking-error-${error.created_date}`}>
                                            {window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}
                                        </Typography>
                                    ))
                                }
                            </Alert>
                        </Grid>
                    }
                    {
                        showManualBooking &&
                        <Grid item xs={12}>
                            <hr />
                            <CartManualProductsManualBooking manual_product={props.accommodation} onManualBooking={onManualBooking} />
                        </Grid>
                    }
                    {
                        !providerContext.module &&
                        props.accommodation.moved_with_recalc &&
                        (
                            quotation_code !== 'visiteurs' ||
                            user?.client_full?.type !== 2
                        ) &&
                        <Grid item xs={12} onClick={confirmRecalc} style={{ cursor: 'pointer' }}>
                            <Alert variant={'filled'} severity={'warning'} action={<Close className={classes.deleteCustomInformation} />}>
                                {t('cart-material.recalc-move')}
                            </Alert>
                        </Grid>
                    }
                    {
                        props.accommodation.description &&
                        <Grid item xs={12}>
                            <Typography
                                className={classes.description}
                                dangerouslySetInnerHTML={{ __html: props.accommodation.description }}
                            />
                        </Grid>
                    }
                    {
                        showNote ? (
                            <Grid id={`flight-${props.accommodation.id}-note`} item xs={12}>
                                <hr />
                                <CartManualProductsNote manual_product={props.accommodation} onAddNote={onAddNote} />
                            </Grid>
                        ) : props.accommodation.custom_information && (
                            <Grid item xs={12}>
                                <hr />
                                <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                    <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                        <Grid item>
                                            <Grid container spacing={2} alignItems={'center'}>
                                                <Grid item>
                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                        {t('global.edit')}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ width: '100%' }}>
                                            {
                                                props.accommodation.moved_with_recalc_custom_information &&
                                                <Tooltip title={t('cart-material.recalc-product-note')}>
                                                    <div
                                                        className={clsx(classes.traverlerModification, classes.delete)}
                                                        onClick={confirmCustomInformationRecalc}
                                                        style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                    >
                                                        <Grid container alignItems={'center'} justify={'space-between'}>
                                                            <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                            <Grid item>{t('cart-material.recalc')}</Grid>
                                                            <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                        </Grid>
                                                    </div>
                                                </Tooltip>
                                            }
                                        </Grid>
                                    </Grid>
                                }>
                                    <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                    {props.accommodation.custom_information}
                                </Alert>
                            </Grid>
                        )
                    }
                    <CartCancellationRules
                        type="manual"
                        id={props.accommodation.id}
                        open={showManualCancellationRules}
                        text={props.accommodation.cancel_condition_text}
                        moved_with_recalc_cancel_condition_text={props.accommodation.moved_with_recalc_cancel_condition_text}
                        noRefund={props.accommodation.no_refund}
                        isCustom={false}
                        onConfirmCancelConditionRecalc={confirmCancelConditionRecalc}
                        onDeleteCancellationRules={onDeleteManualCancellationRules}
                        onToggle={() => setShowManualCancellationRules((state) => !state)}
                        onValidate={onValidateManualCancellationRules}
                    />
                </Grid>
                <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
            </Grid>
        );
    }
);

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    image: {
        borderRadius: 16,
        width: 235
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    room: {
        fontWeight: 'bold'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    editManualProduct: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMarginName: {
        marginBottom: 8
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    hotelName: {
        /*float: 'left', */
        marginRight: 10,
        cursor: 'pointer',
        fontSize: 20,
        fontWeight: 'bold'
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    stars: {
        fontSize: 16,
        verticalAlign: 'middle',
        marginRight: 2
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    includedAmenities: {
        display: 'inline-block',
        marginRight: 4
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    unavailable: {
        color: 'red'
    },
    priceDetail: {
        textAlign: 'right',
        textDecoration: 'underline'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    additionalInformation: {
        backgroundColor: '#2196f3',
        color: 'white'
    },
    additionalInformationExpandIcon: {
        color: 'white'
    },
    additionalInformationInfoIcon: {
        marginRight: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    overflowText: {
        lineHeight: '1.5em',
        width: '100%',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    }
}));
