import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import moment from "moment";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from '@material-ui/core/InputAdornment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import Divider from "@material-ui/core/Divider";

import { PutTravelersList } from "../../../Actions/Flight";

import CountAgeStr from "../Functions/CountAgeStr";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    textFieldInput: {
        "borderRadius": "8px 0px 0px 8px",
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "fontWeight": 400,
        // "color": '#0000008A',
        "paddingTop": 4,
        "paddingBottom": 4,
        "&& .mui-jss-MuiSelect-iconOutlined": {
            display: "none"
        }
    },
    withFullBorder: {
        borderRadius: 8
    },
    startAdornment: {
        marginRight: 12
    }
}));

const ChoosePassenger = (({ origin_url, group_index, group_travelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const end_date = useSelector(store => store.trip.end_date);
    const all_travelers = useSelector(store => store.trip.travelers);
    const flight_param = useSelector(store => store.itinerary_type.flight_circuit);

    const [tempTravelers, setTempTravelers] = useState([]);

    const ChoosePassengerLabel = useRef(null);
    const ChoosePassengerLabelWidth = ChoosePassengerLabel.current ? ChoosePassengerLabel.current.clientWidth : 150;

    useEffect(() => {
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;
        let temp_travelers = [];
        let check_travelers = [];
        let tmp_travelers = group_travelers.length !== 0 ? group_travelers : all_travelers;

        tmp_travelers.map((traveler) => {
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = `${t("flight_search.ado")} ${numero_teen} (${age})`;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = `${t("flight_search.child")} ${numero_child} (${age})`;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = `${t("flight_search.baby")} ${numero_baby} (${age})`;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }
            temp_travelers.push(temp_traveler);
            if (origin_url === "groups") {
                if (flight_groups.length === 1 || flight_groups.find((group) => {
                    return group.travelers.find((el) => {
                        return el.id === temp_traveler.id;
                    });
                }) === undefined) {
                    check_travelers.push(temp_traveler);
                }
            } else {
                check_travelers = flight_groups[group_index].travelers;
            }
        });
        setTempTravelers(temp_travelers);
        dispatch(PutTravelersList(group_index, check_travelers));
    }, [all_travelers]);

    const selectPassengers = (selectedPassengers) => {
        // undefined in responsive ?
        setTimeout(() => {
            if (selectedPassengers.includes("all")) {
                dispatch(PutTravelersList(group_index, group_travelers.length === all_travelers.length ? [] : tempTravelers));
            } else {
                dispatch(PutTravelersList(group_index, selectedPassengers));
            }
        }, 0);
    };
    return (
        <FormControl variant="outlined" style={{ width: "100%" }} size="small" error={flight_groups[group_index].error.includes('travelers')}>
            <InputLabel id={`travelers-${group_index}`} ref={ChoosePassengerLabel}>
                { t("flight_groups.pick_passengers") }
            </InputLabel>
            <Select
                labelId={`travelers-${group_index}`}
                id={`select-travelers-${group_index}`}
                value={group_travelers}
                renderValue={(value) => CountAgeStr(value, end_date, t)}
                MenuProps={{ disableScrollLock: true, variant: "menu" }}
                multiple={true}
                onChange={(event) => selectPassengers(event.target.value)}
                input={
                    <OutlinedInput
                        id={`input-travelers-${group_index}`}
                        labelWidth={ChoosePassengerLabelWidth}
                        autoComplete="off"
                        label={t("flight_groups.pick_passengers")}
                        className={`${classes.textFieldInput} ${(isHandHeld || flight_param.length !== 0) ? classes.withFullBorder : classes.withBorder}`}
                        placeholder={t("flight_groups.select_passengers")}
                        startAdornment={<InputAdornment className={classes.startAdornment}> <SupervisorAccountIcon /> </InputAdornment>}
                    />
                }
            >
                <MenuItem value={"all"}>
                    <Checkbox sx={{
                        '&.Mui-checked': {
                            color: `${main_color}`
                        }
                    }} checked={group_travelers.length === all_travelers.length}  />
                    { t("flight_groups.all_travelers")}
                </MenuItem>
                <Divider />
                {
                    tempTravelers.map((traveler) => {
                        return (
                            <MenuItem value={traveler} key={`choose-${traveler.id}-${group_index}`}>
                                <Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={group_travelers.some(item => item.id === traveler.id)}  />
                                { traveler.age_label + " - " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
});

export default React.memo(ChoosePassenger);
