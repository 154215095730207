//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { SeatsBySegment } from '../objects/seatsBySegment';
import { SeatRow } from '../objects/seatRow';
import { Seat } from '../objects/seat';
import { AppState } from '../../../Reducers/Reducers';
import { Traveler } from '../../../Reducers/objects/Traveler';
import moment from 'moment';
import { Flight } from '../objects/flight';
import { Alert, Button, CircularProgress, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Close, DeleteForever, ExitToApp, FlightLand, FlightTakeoff, Height, NavigateBefore, NavigateNext, Wc } from '@mui/icons-material';
import { useSnackbar } from "notistack";
import CountAgePaxType from '../Functions/CountAgePaxType';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    marginCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    choiceSeatCenter: {
        lineHeight: "30px"
    },
    flightSeatCenter: {
        lineHeight: "25px"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    main_color: {
        color: `${main_color}`
    },
    orange: {
        color: "#EE7203"
    },
    blue: {
        color: "#319FE3"
    },
    purple: {
        color: "#D6057F"
    },
    grey: {
        color: "#0000008A"
    },
    lightgrey: {
        color: "#00000066"
    },
    white: {
        color: "white"
    },
    black: {
        color: "black"
    },
    backgroundGrey: {
        background: "#C4C4C4"
    },
    backgroundBlue: {
        background: "#319FE3"
    },
    backgroundDarkBlue: {
        background: "#0A237D"
    },
    backgroundPurple: {
        background: "#D6057F"
    },
    backgroundRed: {
        background: "red"
    },
    backgroundGreen: {
        background: "#00A149"
    },
    backgroundLightGreen: {
        background: "#BBE166"
    },
    backgroundYellow: {
        background: "#ECBF28"
    },
    backgroundPink: {
        background: "#C27BA0"
    },
    backgroundMainColor: {
        background: `${main_color}`
    },
    formShadow: {
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    spacer: {
        padding: "12px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    seatSpacer: {
        padding: 4
    },
    previousButton: {
        border: `1px solid ${main_color}`,
        backgroundColor: "#FFFFFF",
        color: `${main_color}`,
        padding: "8px 60px"
    },
    skipButton: {
        border: `1px solid ${main_color}`,
        backgroundColor: "#FFFFFF",
        color: `${main_color}`,
        padding: "8px 10px"
    },
    validateButton: {
        backgroundColor: `${main_color}`,
        color: "white",
        padding: "8px 60px"
    },
    cellDimension: {
        height: 33,
        width: 30
    },
    planeWidth: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto"
    },
    borderPlaneLeft: {
        borderLeft: "3px solid #C0BFBF"
    },
    borderPlaneRight: {
        borderRight: "3px solid #C0BFBF"
    },
    borderTableHead: {
        borderBottom: "1px solid rgba(196, 196, 196, 0.5)",
        borderTop: "1px solid rgba(196, 196, 196, 0.5)"
    },
    borderTableBottomCurrent: {
        borderBottom: `6px solid ${main_color}`
    },
    borderTableLeft: {
        borderLeft: "1px solid rgba(196, 196, 196, 0.5)"
    },
    borderTableLeftCurrent: {
        borderLeft: `6px solid ${main_color}`
    },
    borderTableRight: {
        borderRight: "1px solid rgba(196, 196, 196, 0.5)"
    },
    borderNone: {
        borderBottom: "none !important"
    },
    borderBottomBlue: {
        borderBottom: "3px solid #000B47"
    },
    borderBottomGrey: {
        borderBottom: "3px solid #B1B1B1"
    },
    borderBottomRed: {
        borderBottom: "3px solid #B20000"
    },
    borderBottomGreen: {
        borderBottom: "3px solid #016C35"
    },
    borderBottomLightGreen: {
        borderBottom: "3px solid #95B451"
    },
    borderBottomYellow: {
        borderBottom: "3px solid #AF902D"
    },
    borderBottomPink: {
        borderBottom: "3px solid #9B6280"
    },
    initials: {
        width: '25px !important',
        height: 25,
        borderRadius: 30
    },
    empty_seat: {
        borderRadius: "8px 8px 0px 0px",
        width: 30,
        height: 25,
        border: "2px dotted #C4C4C4",
        background: "white"
    },
    flight_seat: {
        borderRadius: "8px 8px 0px 0px",
        width: 30,
        height: 25,
        marginRight: 6
    },
    choice_seat: {
        borderRadius: "8px 8px 0px 0px",
        width: 30,
        height: 25
    },
    pointer: {
        cursor: "pointer"
    },
    seatTooltip: {
        background: "white",
        border: "1px solid #C4C4C4",
        boxShadow: "0px 2px"
    },
    iconMargin: {
        marginTop: 4
    }
}));

type FlightSeatsProps = {
    searched_group:any,
    flight:Flight,
    router:any,
    seats_by_segment:SeatsBySegment[],
    end_date: string,
    flightStep: number,
    setFlightStep: ((step: number) => void)
}

const FlightSeats = (props: FlightSeatsProps) : (JSX.Element | null) => {
    const { searched_group, flight, router, end_date, seats_by_segment, flightStep, setFlightStep } = props;
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const { enqueueSnackbar } = useSnackbar();

    const seat_current_segment = useSelector((state: AppState) => state.flight_search.seat_current_segment);
    const selected_seats = useSelector((state: AppState) => state.flight_search.selected_seats);
    const airlines = useSelector((state: AppState) => state.flight_search.airlines);
    const airports = useSelector((state: AppState) => state.flight_search.airports);
    const currency = useSelector((state: AppState) => state.flight_search.currency);
    const language = useSelector((state: AppState) => state.header.language);
    
    const [exitRows, setExitRows] = useState<Array<number>>([]);
    const [currentOrigin, setCurrentOrigin] = useState("");
    const [currentDestination, setCurrentDestination] = useState("");
    const [seatColumns, setSeatColumns] = useState<Array<string>>([]);
    const [currentTraveler, setCurrentTraveler] = useState<null | number>(null);
    const [noBabyTravelers, setNoBabyTravelers] = useState<Array<Traveler>>([]);
    const [selected_traveler, setSelectedTraveler] = useState<Traveler | null>(null);
    const [selecting_traveler, setSelectingTraveler] = useState<boolean>(false);
    const [mobileSeatMinIndex, setMobileSeatMinIndex] = useState(0);
    const [mobileSeatMaxIndex, setMobileSeatMaxIndex] = useState(4);
    const [multiMinSegment, setMultiMinSegment] = useState(0);
    const [multiMaxSegment, setMultiMaxSegment] = useState(2);

    var pax_type = CountAgePaxType(searched_group.travelers, end_date);

    useEffect(() => {
        return () => {
            dispatch({ type: "FLIGHT_SET_SEAT_CURRENT_SEGMENT", payload: 0 });
        };
    }, []);
    
    useEffect(() => {
        let exit_rows: Array<number> = [];
        seats_by_segment.map((seat_by_seg:SeatsBySegment) => {
            seat_by_seg.seat_rows.map((seat_row:SeatRow) => {
                seat_row.seats.map((seat:Seat) => {
                    if (seat.exit_row) {
                        if (!exit_rows.includes(seat_row.number)) {
                            exit_rows.push(seat_row.number);
                        }
                    }
                });
            });
        });
        setExitRows(exit_rows);
    }, [seats_by_segment]);
    useEffect(() => {
        if (seats_by_segment[seat_current_segment] !== undefined && airports !== null) {
            let segment_flight:(SeatRow|undefined) = seats_by_segment[seat_current_segment];
            Object.keys(airports).map((airport_id) => {
                if (airports[airport_id].code === segment_flight?.origin) {
                    setCurrentOrigin(airports[airport_id].iata_city.name !== undefined ? airports[airport_id].iata_city.name : airports[airport_id].iata_city.international_name);
                } else if (airports[airport_id].code === segment_flight?.destination) {
                    setCurrentDestination(airports[airport_id].iata_city.name !== undefined ? airports[airport_id].iata_city.name : airports[airport_id].iata_city.international_name);
                }
            });

            let temp_seat_columns: Array<string> = [];
            for (let column_index = 0; column_index < seats_by_segment[seat_current_segment].seat_rows[0].seats.length; column_index++) {
                let is_column_empty = true;
                seats_by_segment[seat_current_segment].seat_rows.map((seat_row:SeatRow) => {
                    let seat = seat_row.seats[column_index];
                    if (seat !== undefined && column_index === temp_seat_columns.length && column_index < seat_row.seats.length && seat.code !== null) {
                        temp_seat_columns.push(seat.code.slice(-1));
                        is_column_empty = false;
                    }
                });
                if (is_column_empty) {
                    temp_seat_columns.push("");
                }
            }
            setSeatColumns(temp_seat_columns);
        }
    }, [seats_by_segment, seat_current_segment]);
    useEffect(() => {
        let copy_travelers = searched_group.travelers.slice();
        let travelers_without_baby: Array<Traveler> = [];
        let baby_travelers: Array<Traveler> = [];

        copy_travelers.map((traveler:Traveler) => {
            if (traveler.birth_date !== null) {
                let copy_traveler = Object.assign({}, traveler);
                let traveler_age = moment(end_date).diff(moment.utc(traveler.birth_date), "y");
                if (traveler_age >= 2) {
                    travelers_without_baby.push(copy_traveler);
                }
            }
        });

        setCurrentTraveler(travelers_without_baby[0].id);
        setNoBabyTravelers(travelers_without_baby);
        if (!selected_traveler) setSelectedTraveler(travelers_without_baby[0]);
    }, [searched_group.travelers]);
    const multiPreviousSegments = () => {
        setMultiMinSegment(multiMinSegment - 2);
        setMultiMaxSegment(multiMaxSegment - 2);
    };
    const multiNextSegments = () => {
        setMultiMinSegment(multiMinSegment + 2);
        setMultiMaxSegment(multiMaxSegment + 2);
    };
    const changeSegment = (segment_index: number) => {
        dispatch({ type: "FLIGHT_SET_SEAT_CURRENT_SEGMENT", payload: segment_index });
    };
    const changeTraveler = (traveler_id: number) => {
        setCurrentTraveler(traveler_id);
    };
    const mobilePreviousSeats = () => {
        setMobileSeatMinIndex(mobileSeatMinIndex - 4);
        setMobileSeatMaxIndex(mobileSeatMaxIndex - 4);
    };
    const mobileNextSeats = () => {
        setMobileSeatMinIndex(mobileSeatMinIndex + 4);
        setMobileSeatMaxIndex(mobileSeatMaxIndex + 4);
    };
    const emptySelectedSeat = (traveler_id: number) => {
        let seat = selected_seats[seat_current_segment].find(item => item.traveler === traveler_id);
        if (seat !== undefined && seat.price !== undefined) {
            dispatch({ type: 'FLIGHT_REMOVE_SELECTED_PRICE', payload: parseInt(seat.price) });
        }
        dispatch({ type: 'FLIGHT_REMOVE_SELECTED_SEAT', payload: { segment_index: seat_current_segment, traveler_id: traveler_id } });
    };
    const selectSeatforTraveler = (seat: Seat) => {
        if (currentTraveler !== null) {
            let current_traveler_name = "";
            let current_traveler_index: null | number = null;
            let current_traveler_age = null;
            searched_group.travelers.map((traveler:Traveler, traveler_index:number) => {
                if (traveler.id === currentTraveler && traveler.first_name !== null && traveler.last_name !== null && traveler.birth_date !== null) {
                    current_traveler_name = traveler.first_name[0] + traveler.last_name[0];
                    current_traveler_index = traveler_index;
                    current_traveler_age = moment(end_date).diff(moment.utc(traveler.birth_date), "y");
                }
            });

            if (seat.availability === "AVAILABLE" && seat.code !== null) {
                if (current_traveler_age !== null && current_traveler_age >= 2 && current_traveler_age < 12 && seat.disallow_child) {
                    enqueueSnackbar(t<string>("flight.error.seat_not_available"), { variant: "warning", className: 'mobileErrorSnackbar', anchorOrigin: isHandHeld ? { vertical: 'bottom', horizontal: 'center' } : undefined });
                } else {
                    if (seat.prices !== null) {
                        dispatch({ type: 'FLIGHT_SELECT_SEAT', payload: {
                            segment_index: seat_current_segment,
                            traveler_id: currentTraveler,
                            traveler_name: current_traveler_name,
                            seat_number: seat.code,
                            price: seat.prices[0].selling_price
                        } });
                        dispatch({ type: 'FLIGHT_ADD_SELECTED_PRICE', payload: parseInt(seat.prices[0].selling_price) });
                    } else {
                        dispatch({ type: 'FLIGHT_SELECT_SEAT', payload: {
                            segment_index: seat_current_segment,
                            traveler_id: currentTraveler,
                            traveler_name: current_traveler_name,
                            seat_number: seat.code
                        } });
                    }

                    if (current_traveler_index !== null && !isHandHeld) {
                        if (current_traveler_index + 1 < noBabyTravelers.length) {
                            // pass onto next traveler if possible
                            setCurrentTraveler(noBabyTravelers[current_traveler_index + 1].id);
                        }
                        // else if (seat_current_segment + 1 < seats_by_segment.length) {
                        //     // pass onto next segment if all travelers for this segments have a seat
                        //     dispatch(setSeatCurrentSegment(seat_current_segment + 1));
                        //     setCurrentTraveler(noBabyTravelers[0].id);

                        //     if (seat_current_segment + 1 >= multiMaxSegment){
                        //         // change multi next_segments
                        //         multiNextSegments();
                        //     }
                        // }
                    }
                }
            } else {
                enqueueSnackbar(t<string>("flight_search.seat_not_available"), { variant: "warning", className: 'mobileErrorSnackbar', anchorOrigin: isHandHeld ? { vertical: 'bottom', horizontal: 'center' } : undefined });
            }
        }
    };
    console.log('seats_by_segment:', seats_by_segment);
    return (
        <Paper elevation={0} variant="outlined" className={classes.formShadow}>
            <Grid>
                <Grid container alignItems="center" justifyContent="space-between" direction={isMobile ? "column" : "row"} className={classes.spacer} spacing={isMobile ? 2 : 0}>
                    { !isMobile && <Grid item xs={4} /> }
                    <Grid item xs={isMobile ? 12 : 4} className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${isTablet ? classes.fontSize12 : (isMobile ? classes.fontSize16 : classes.fontSize12)} ${classes.uppercase}`}>
                        { t<string>("flight_search.select_seat")}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4} className={`${classes.spacer} ${classes.textCenter}`}>
                        <Button fullWidth={isMobile ? true : false} variant="contained" onClick={() => setFlightStep(flightStep + 1)} sx={{
                            backgroundColor: `${main_color}`,
                            color: "white",
                            padding: "8px 60px",
                            '&:hover': {
                                backgroundColor: `${main_color}CA`
                            }
                        }}>
                            { t<string>('flight-selected.skip-step') }
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid style={{ padding: isTablet ? 16 : (isMobile ? 8 : 20) }}>
                    {
                        seats_by_segment.length === 0 && <Grid className={classes.textCenter}> <CircularProgress /> </Grid>
                    }
                    {
                        seats_by_segment.length !== 0 && seats_by_segment[seat_current_segment] !== undefined && (
                            <Grid container alignItems={isTablet ? "center" : (isMobile ? "stretch" : "flex-start")} justifyContent="space-between" spacing={isHandHeld ? 2 : 0} direction={isHandHeld ? "column-reverse" : "row"} >
                                <Grid item container xs={isTablet ? false : (isMobile ? 12 : 7)} style={{ overflow: 'hidden' }}>
                                    <Grid item xs={12} className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${isTablet ? classes.fontSize12 : (isMobile ? classes.fontSize16 : classes.fontSize24)} ${classes.uppercase} ${classes.main_color}`}>{t<string>('shared.legend')}</Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatAvailable/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('global.available') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatBlocked/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('flight_search.seat_availability.OCCUPIED') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatExtraLegroom/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('flight_search.extra_legroom') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatWing/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('flight_search.wings') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatOccupied/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('flight_search.seat_availability.BLOCKED') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={ "center" } xs={ 6 } spacing={ 2 }>
                                        <Grid item>
                                            <FlightSelectedSeatInfantAllowed/>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{ t<string>('flight_search.suitable_for_adult_with_infant') }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.planeWidth} ${(!isMobile || mobileSeatMinIndex === 0) && classes.borderPlaneLeft} ${(!isMobile || mobileSeatMaxIndex >= seats_by_segment[seat_current_segment].seat_rows[0].seats.length) && classes.borderPlaneRight}`}>
                                        <TableContainer style={{ overflowY: 'hidden' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="none" className={classes.borderNone} />
                                                        {
                                                            exitRows.length > 0 && (
                                                                <TableCell padding="none" className={`${classes.genericText} ${classes.borderNone} ${classes.cellDimension}`} />
                                                            )
                                                        }
                                                        {
                                                            seatColumns.map((column, column_index) => {
                                                                if (!isMobile || (column_index >= mobileSeatMinIndex && column_index < mobileSeatMaxIndex)) {
                                                                    return (
                                                                        <TableCell key={column_index} padding="none" className={`${classes.genericText} ${classes.borderNone} ${classes.textCenter} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase} ${classes.black}`}>
                                                                            { column }
                                                                        </TableCell>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                        {
                                                            exitRows.length > 0 && (
                                                                <TableCell padding="none" className={`${classes.genericText} ${classes.borderNone} ${classes.cellDimension}`} />
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        seats_by_segment[seat_current_segment].seat_rows.map((seat_row:SeatRow, seat_row_index:number) => {
                                                            if (seat_row.number >= 0) {
                                                                return (
                                                                    <TableRow key={seat_row_index}>
                                                                        {
                                                                            exitRows.length > 0 && (
                                                                                <TableCell padding="none" className={`${classes.genericText} ${classes.textCenter} ${classes.borderNone} ${classes.cellDimension}`}>
                                                                                    {
                                                                                        exitRows.includes(seat_row.number) && (
                                                                                            <Tooltip title={t<string>("flight_search.exit_row")}>
                                                                                                <ExitToApp className={`${classes.fontSize12} ${classes.iconMargin}`} style={{ transform: "rotate(180deg)" }} />
                                                                                            </Tooltip>
                                                                                        )
                                                                                    }
                                                                                </TableCell>
                                                                            )
                                                                        }
                                                                        <TableCell padding="none" className={`${classes.genericText} ${classes.textRight} ${classes.borderNone} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase} ${classes.black} ${classes.cellDimension}`}>
                                                                            { seat_row.number !== 0 && seat_row.number }
                                                                        </TableCell>
                                                                        {/* seats */}
                                                                        {
                                                                            seat_row.seats.map((seat:Seat, seat_index:number) => {
                                                                                let seatBackground = classes.backgroundGrey;
                                                                                let seatBorderBottom = classes.borderBottomGrey;
                                                                                let current_traveler_obj = null;
                                                                                let current_traveler_age = null;
                                                                                if (currentTraveler !== null) {
                                                                                    current_traveler_obj = searched_group.travelers.find((item:Traveler) => item.id === currentTraveler);
                                                                                    if (current_traveler_obj !== undefined && current_traveler_obj.birth_date !== null) {
                                                                                        current_traveler_age = moment(end_date).diff(moment.utc(current_traveler_obj.birth_date), "y");
                                                                                    }
                                                                                }

                                                                                if (seat.availability === "OCCUPIED") {
                                                                                    seatBackground = classes.backgroundRed;
                                                                                    seatBorderBottom = classes.borderBottomRed;
                                                                                } else if (seat.availability === null) {
                                                                                    seatBackground = classes.backgroundYellow;
                                                                                    seatBorderBottom = classes.borderBottomYellow;
                                                                                } else if (seat.chosen) {
                                                                                    seatBackground = classes.backgroundGreen;
                                                                                    seatBorderBottom = classes.borderBottomGreen;
                                                                                } else if (seat.availability === "AVAILABLE") {
                                                                                    if (seat.extra_legroom) {
                                                                                        seatBackground = classes.backgroundDarkBlue;
                                                                                        seatBorderBottom = classes.borderBottomBlue;
                                                                                    } else if (seat.suitable_for_adult_with_infant) {
                                                                                        seatBackground = classes.backgroundPink;
                                                                                        seatBorderBottom = classes.borderBottomPink;
                                                                                    } else if (seat_row.wings) {
                                                                                        seatBackground = classes.backgroundLightGreen;
                                                                                        seatBorderBottom = classes.borderBottomLightGreen;
                                                                                    }
                                                                                }
                                                                                if (!isMobile || (seat_index >= mobileSeatMinIndex && seat_index < mobileSeatMaxIndex)) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            padding="none"
                                                                                            key={`${seat_row_index}-${seat_index}`}
                                                                                            className={`${classes.seatSpacer} ${classes.borderNone} ${classes.textCenter} ${classes.cellDimension}`}
                                                                                        >
                                                                                            {
                                                                                                seat.seat_type !== "Empty" && (
                                                                                                    <Tooltip
                                                                                                        placement={"top"}
                                                                                                        followCursor
                                                                                                        // classes={{ tooltipArrow: classes.seatTooltip, arrow: classes.white }}
                                                                                                        componentsProps={{
                                                                                                            tooltip: {
                                                                                                                sx: {
                                                                                                                    backgroundColor: 'white',
                                                                                                                    zIndex: 1,
                                                                                                                    border: "1px solid #C4C4C4"
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                        title={
                                                                                                            <Grid container direction={'row'} alignItems={"center"} justifyContent={"space-between"} spacing={2}>
                                                                                                                {
                                                                                                                    seat.code !== null && (
                                                                                                                        <Grid item xs className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize24} ${classes.uppercase} ${classes.main_color}`}>
                                                                                                                            { seat.code }
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                }
                                                                                                                {
                                                                                                                    seat.availability === "AVAILABLE" && (
                                                                                                                        <Grid item xs container direction={'column'} alignItems={"center"} justifyContent={"center"}>
                                                                                                                            {
                                                                                                                                seat.extra_legroom && (
                                                                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10}`}>
                                                                                                                                        { t<string>("flight_search.extra_legroom") }
                                                                                                                                    </Grid>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                seat_row.wings && (
                                                                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10}`}>
                                                                                                                                        { t<string>("flight_search.wings") }
                                                                                                                                    </Grid>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                seat.suitable_for_adult_with_infant && (
                                                                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10}`}>
                                                                                                                                        { t<string>("flight_search.suitable_for_adult_with_infant") }
                                                                                                                                    </Grid>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                current_traveler_age !== null && current_traveler_age >= 2 && current_traveler_age < 12 && seat.disallow_child && (
                                                                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10}`}>
                                                                                                                                        { t<string>("flight_search.disallow_child") }
                                                                                                                                    </Grid>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                seat.prices !== null && currency !== null &&
                                                                                                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.blue}`}>
                                                                                                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(seat.prices[0].selling_price)) }
                                                                                                                            </Grid>
                                                                                                                            }
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                }
                                                                                                                {
                                                                                                                    seat.availability !== "AVAILABLE" && (
                                                                                                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14}`}>
                                                                                                                            { `${t<string>(`flight_search.seat_availability.${seat.availability}`)}` }
                                                                                                                        </Grid>
                                                                                                                    )
                                                                                                                }
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    >
                                                                                                        <Grid
                                                                                                            className={`${classes.flight_seat} ${classes.marginCenter} ${seat.availability === "AVAILABLE" ? (current_traveler_age !== null && current_traveler_age >= 2 && current_traveler_age < 12 && seat.disallow_child ? "" : classes.pointer): ""} ${seatBackground} ${seatBorderBottom}`}
                                                                                                            onClick={() => selectSeatforTraveler(seat)}
                                                                                                        >
                                                                                                            {
                                                                                                                ["BLOCKED", "OCCUPIED"].includes(seat.availability) && <Close className={classes.white} style={{ marginLeft: 3 }}/>
                                                                                                            }
                                                                                                            {
                                                                                                                !["BLOCKED", "OCCUPIED"].includes(seat.availability) && !seat.chosen && seat.extra_legroom && <Height className={classes.white} style={{ transform: "rotate(45deg)", marginLeft: '3px' }} />
                                                                                                            }
                                                                                                            {
                                                                                                                seat.availability === "Toilet" && <Wc className={classes.white}/>
                                                                                                            }
                                                                                                            {
                                                                                                                seat.availability === "AVAILABLE" && current_traveler_age !== null && current_traveler_age >= 2 && current_traveler_age < 12 && seat.disallow_child && <Close className={classes.white}/>
                                                                                                            }
                                                                                                            {
                                                                                                                seat.chosen && seat.chosen_traveler !== undefined && (
                                                                                                                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.uppercase} ${classes.white} ${classes.flightSeatCenter} ${classes.textCenter}`}>
                                                                                                                        <div>
                                                                                                                            { seat.chosen_traveler }
                                                                                                                        </div>
                                                                                                                    </Grid>
                                                                                                                )
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Tooltip>
                                                                                                )
                                                                                            }
                                                                                        </TableCell>
                                                                                    );
                                                                                }
                                                                            })
                                                                        }
                                                                        {/* seats end */}
                                                                        {
                                                                            exitRows.length > 0 && (
                                                                                <TableCell padding="none" className={`${classes.genericText} ${classes.textCenter} ${classes.borderNone} ${classes.cellDimension}`}>
                                                                                    {
                                                                                        exitRows.includes(seat_row.number) && (
                                                                                            <Tooltip title={t<string>("flight_search.exit_row")}>
                                                                                                <ExitToApp className={`${classes.fontSize12} ${classes.iconMargin}`} />
                                                                                            </Tooltip>
                                                                                        )
                                                                                    }
                                                                                </TableCell>
                                                                            )
                                                                        }
                                                                    </TableRow>
                                                                );
                                                            }
                                                        })
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                {
                                    isMobile && (
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item xs={1}>
                                                    <IconButton size="small" onClick={() => mobilePreviousSeats()} disabled={mobileSeatMinIndex === 0}>
                                                        <NavigateBefore />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={10} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.textCenter}`}>
                                                    { t<string>("flight_search.plane_navigation") }
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton size="small" onClick={() => mobileNextSeats()} disabled={mobileSeatMaxIndex >= seats_by_segment[seat_current_segment].seat_rows[0].seats.length}>
                                                        <NavigateNext />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={isHandHeld ? 12 : 5} className={isHandHeld ? "" : classes.horizontalSpacer}>
                                    <Grid container alignItems="center" justifyContent={isHandHeld ? "center" : "flex-start"} spacing={2}>
                                        <Grid item>
                                            <FlightTakeoff className={classes.main_color} />
                                        </Grid>
                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${isMobile ? classes.fontSize14 : classes.fontSize12} ${classes.main_color} ${classes.uppercase}`}>
                                            {
                                                currentOrigin + " - " + currentDestination
                                            }
                                        </Grid>

                                    </Grid>
                                    {
                                        pax_type.INF > 0 && (
                                            <Alert severity="info">
                                                { t<string>("flight_search.baby_alert") }
                                            </Alert>
                                        )
                                    }
                                    <TableContainer style={{ paddingTop: 10 }}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={`${classes.borderTableHead} ${classes.borderTableLeft} ${classes.horizontalSpacer} ${classes.genericText} ${classes.fontWeight900} ${isMobile ? classes.fontSize12 : classes.fontSize14} ${classes.uppercase}`}>
                                                        { t<string>("flight_groups.passengers") }
                                                    </TableCell>
                                                    {
                                                        seats_by_segment.map((segment, segment_index) => {
                                                            if (segment_index >= multiMinSegment && segment_index < multiMaxSegment) {
                                                                return (
                                                                    <TableCell
                                                                        key={segment_index}
                                                                        padding="none"
                                                                        className={`${classes.borderTableHead} ${segment_index === multiMaxSegment -1 ? classes.borderTableRight : ""} ${segment_index === seat_current_segment ? classes.borderTableBottomCurrent : ""} ${classes.horizontalSpacer} ${classes.textCenter} ${classes.pointer}`}
                                                                        onClick={() => changeSegment(segment_index)}
                                                                    >
                                                                        {
                                                                        // segment_index === 0 && <FlightTakeoff className={segment_index === seat_current_segment ? classes.main_color : classes.grey} />
                                                                        }
                                                                        {
                                                                        // segment_index !== 0 && segment_index === seats_by_segment.length - 1 && <FlightLand className={segment_index === seat_current_segment ? classes.main_color : classes.grey} />
                                                                        }
                                                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${isMobile ? classes.fontSize12 : classes.fontSize14} ${segment_index === seat_current_segment ? classes.main_color : classes.grey} ${classes.uppercase}`}>
                                                                            {
                                                                                segment.origin + " - " + segment.destination
                                                                            }
                                                                        </Grid>
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>

                                            {/* passenger here */}
                                            <TableBody>
                                                {
                                                    noBabyTravelers.map((traveler) => {
                                                        let current_seats = selected_seats[seat_current_segment];
                                                        let traveler_current_segment_seat: null | SelectedSeat | undefined = null;
                                                        if (current_seats !== undefined && current_seats.length !== 0 && current_seats.find(item => item.traveler === traveler.id) !== undefined) {
                                                            traveler_current_segment_seat = current_seats.find(item => item.traveler === traveler.id);
                                                        }
                                                        return (
                                                            <TableRow key={traveler.id}>
                                                                <TableCell sx={{ textAlign: 'center', margin: 'auto' }} className={`${currentTraveler === traveler.id ? classes.borderTableLeftCurrent : classes.borderTableLeft} ${classes.pointer}`} onClick={() => changeTraveler(traveler.id)}>
                                                                    {
                                                                        traveler.first_name !== null && traveler.last_name !== null && (
                                                                            <Grid container alignItems="center" justifyContent="flex-start" spacing={isMobile ? 1 : 2}>
                                                                                <Grid item xs={isMobile ? 12 : false}>
                                                                                    <Grid container alignItems="center" justifyContent="center" className={`${classes.initials} ${classes.textCenter} ${currentTraveler === traveler.id ? classes.backgroundMainColor : classes.backgroundBlue}`}>
                                                                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10} ${classes.uppercase} ${classes.white}`}>
                                                                                            { traveler.first_name[0] + traveler.last_name[0] }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10} ${classes.uppercase} ${currentTraveler === traveler.id ? classes.main_color : classes.blue}`}>
                                                                                        { traveler.first_name + " " + traveler.last_name }
                                                                                    </Grid>
                                                                                    <Grid container alignItems="center" justifyContent="flex-start">
                                                                                        <Grid item className={`${classes.genericText} ${classes.fontSize10} ${currentTraveler === traveler.id ? classes.fontWeight900 : classes.lightgrey} ${classes.uppercase}`}>
                                                                                            {
                                                                                                traveler_current_segment_seat !== null && traveler_current_segment_seat !== undefined && currency !== null
                                                                                                    ? traveler_current_segment_seat.seat_number + " " + t<string>("global.selected") + (traveler_current_segment_seat.price !== undefined ? " " + new Intl.NumberFormat(language, { style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(traveler_current_segment_seat.price)) : "")
                                                                                                    : t<string>("flight_search.choose_seat")
                                                                                            }
                                                                                        </Grid>
                                                                                        {
                                                                                            traveler_current_segment_seat !== null && currency !== null && (
                                                                                                <Grid item>
                                                                                                    <IconButton size="small" onClick={() => emptySelectedSeat(traveler.id)}>
                                                                                                        <DeleteForever className={`${classes.blue}`} style={{ marginBottom: '2px' }} sx={{ fontSize: '16px !important' }} />
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                            )
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </TableCell>
                                                                {
                                                                    seats_by_segment.map((segment, segment_index) => {
                                                                        let selected_seats_segment = selected_seats[segment_index];
                                                                        let traveler_segment_seat: null | SelectedSeat | undefined = null;
                                                                        if (selected_seats_segment !== undefined && selected_seats_segment.length !== 0 && selected_seats_segment.find(item => item.traveler === traveler.id) !== undefined) {
                                                                            traveler_segment_seat = selected_seats_segment.find(item => item.traveler === traveler.id);
                                                                        }
                                                                        if (segment_index >= multiMinSegment && segment_index < multiMaxSegment) {
                                                                            return (
                                                                                <TableCell
                                                                                    key={segment_index}
                                                                                    className={segment_index === multiMaxSegment - 1 ? classes.borderTableRight : ""}
                                                                                    style={ seat_current_segment === segment_index ? { background: `${main_color}` } : {}}
                                                                                    onClick={() => {
                                                                                        changeTraveler(traveler.id);
                                                                                        changeSegment(segment_index);
                                                                                    }}
                                                                                >
                                                                                    <Grid container alignItems="center" justifyContent="center">
                                                                                        <Grid item className={`${traveler_segment_seat !== null ? classes.choice_seat : classes.empty_seat} ${classes.pointer} ${classes.textCenter} ${traveler_segment_seat !== null ? classes.borderBottomGreen : ""} ${traveler_segment_seat !== null ? classes.backgroundGreen : ""}`}>
                                                                                            {
                                                                                            // traveler_segment_seat !== null && traveler_segment_seat !== undefined && (
                                                                                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${classes.white} ${classes.choiceSeatCenter}`}>
                                                                                                    { traveler_segment_seat !== null && traveler_segment_seat !== undefined ? traveler_segment_seat.seat_number : ""}
                                                                                                </Grid>
                                                                                            // )
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </TableCell>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                            {/* passenger here end */}
                                        </Table>
                                    </TableContainer>
                                    {
                                    // seats_by_segment.length > 2 && (
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1} style={{ marginTop: 2 }}>
                                            <Grid item xs={6}>
                                                <Button size="small" fullWidth={true} variant="contained" onClick={() => {
                                                    changeSegment(seat_current_segment - 1);
                                                    setCurrentTraveler(noBabyTravelers[0].id);
                                                    if ((seat_current_segment - 1) % 2 !== 0) {
                                                        multiPreviousSegments();
                                                    }
                                                }} disabled={seat_current_segment - 1 < 0} sx={{
                                                    backgroundColor: `${main_color}`,
                                                    color: "white",
                                                    padding: "8px 60px",
                                                    '&:hover': {
                                                        backgroundColor: `${main_color}CA`
                                                    }
                                                }}>
                                                    { t<string>("flight_seat.prev_flight") }
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {
                                                    seat_current_segment !== seats_by_segment.length - 1 &&
                                                <Button size="small" fullWidth={true} variant="contained" onClick={() => {
                                                    changeSegment(seat_current_segment + 1);
                                                    setCurrentTraveler(noBabyTravelers[0].id);
                                                    if ((seat_current_segment + 1) % 2 === 0) {
                                                        multiNextSegments();
                                                    }
                                                }} sx={{
                                                // <Button size="small" fullWidth={true} variant="contained" onClick={() => multiNextSegments()} disabled={multiMaxSegment >= seats_by_segment.length} sx={{
                                                    backgroundColor: `${main_color}`,
                                                    color: "white",
                                                    padding: "8px 60px",
                                                    '&:hover': {
                                                        backgroundColor: `${main_color}CA`
                                                    }
                                                }}>
                                                    { t<string>("flight_seat.next_flight") }
                                                </Button>
                                                }
                                                {
                                                    seat_current_segment === seats_by_segment.length - 1 &&
                                                <Grid className={`${classes.spacer} ${classes.textCenter}`}>
                                                    <Button fullWidth={isMobile ? true : false} variant="contained" onClick={() => setFlightStep(flightStep + 1)} sx={{
                                                        backgroundColor: `${main_color}`,
                                                        color: "white",
                                                        padding: "8px 60px",
                                                        '&:hover': {
                                                            backgroundColor: `${main_color}CA`
                                                        }
                                                    }}>
                                                        { t<string>("global.validate") }
                                                    </Button>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    // )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Paper>
    );
};
const FlightSelectedSeatAvailable: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        backgroundColor: "#C4C4C4",
        color: "white",
        borderBottom: "3px solid #B2B1B1",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }} />
);
const FLightSelectedSeatEmpty: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        margin: "8px auto"
    }} />
);

const FlightSelectedSeatToilet: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        backgroundColor: "#C4C4C4",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }}><Wc style={{
            verticalAlign: 'text-top'
        }}/></div>
);
const FlightSelectedSeatWing: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        backgroundColor: "#BBE166",
        color: "white",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }} />
);
const FlightSelectedSeatBlocked: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        backgroundColor: "#FF0000",
        color: "white",
        borderBottom: "3px solid #A60000",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }}><Close style={{
            verticalAlign: 'text-top'
        }}/></div>
);

const FlightSelectedSeatOccupied: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        backgroundColor: "#C4C4C4",
        color: "white",
        borderBottom: "3px solid #B2B1B1",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }}><Close style={{
            verticalAlign: 'text-top'
        }}/></div>
);

const FlightSelectedSeatInfantAllowed: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        background: "#C27BA0",
        color: "white",
        borderBottom: "3px solid #AF902D",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }} />
);

const FlightSelectedSeatExtraLegroom: FC = (): ReactElement => (
    <div style={{
        width: 30,
        height: 30,
        background: "#0A237D",
        color: "white",
        borderBottom: "3px solid #000B47",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        margin: "8px auto"
    }}> <Height style={{ transform: "rotate(45deg)", color: 'white', verticalAlign: 'text-top' }} /></div>
);
export default React.memo(FlightSeats);