import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import { useItineraryTripUpdate } from "./network/itineraryTripUpdate";
import { useItineraryLaunchBlockAdd } from "./network/itineraryLaunchBlockAdd";
import { setTextsCheckModalOptions } from "./redux/reducer";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryBlockTextsCheckModal(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const options = useSelector((state: AppState) => state.itinerarySlice.textsCheckModalOptions);
    const [loading, setLoading] = useState(false);
    const launchBlockAdd = useItineraryLaunchBlockAdd();
    const showError = useShowError();
    const updateTrip = useItineraryTripUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(trip) {
            dispatch({
                type: 'TRIP_SET_ALL_DATA',
                payload: {
                    data: trip
                }
            });
        },
        onError(error) {
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onClose = () => {
        dispatch(setTextsCheckModalOptions(null));
    }

    const onContinueWithoutTexts = () => {
        if (options) {
            dispatch(setTextsCheckModalOptions(null));
            launchBlockAdd({
                ...options,
                skipTextsImport: true
            });
        }
    }

    const onSwitchMode = async () => {
        if (trip?.id && options) {
            const tripData = await updateTrip(
                trip.id,
                { is_by_day_view: !trip.is_by_day_view }
            );
            if (tripData) {
                dispatch(setTextsCheckModalOptions(null));
                launchBlockAdd({
                    ...options,
                    tripData: tripData
                });
            }
        }
    };

    return (
        <>
            <Dialog
                open={!!options}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography fontWeight={700}>
                            {t('itinerary.block-texts-mode-confirmation')}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {
                            !options?.tripByDay ?
                                t('itinerary.block-texts-switch-day-by-day') :
                                t('itinerary.block-texts-switch-step-by-step')
                        }
                    </Typography>
                </DialogContent>
                <DialogActions
                >
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{ textTransform: 'none' }}
                    >
                        {t('shared.cancel')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onContinueWithoutTexts}
                        sx={{ textTransform: 'none' }}
                    >
                        {t('itinerary.block-texts-continue-without')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onSwitchMode}
                        sx={{ textTransform: 'none' }}
                    >
                        {
                            !options?.tripByDay ?
                                t('itinerary.block-texts-switch-day-by-day-action') :
                                t('itinerary.block-texts-switch-step-by-step-action')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    )
}