import React from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { styled, ListItem, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { cloneDeep, isNumber } from "lodash";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import { StepsDirectionsManager } from "./utils/stepsDirectionsManager";
import { transformStepInputsToGroups } from "./utils/transformStepInputsToGroups";
import { useItineraryLaunchBlockAdd } from "./network/itineraryLaunchBlockAdd";
import { useFixedDateBlockCheck } from "./network/fixedDateBlockCheck";
import {
    markIndexAsCalculatingTransport,
    swapBlock,
    swapStepsInputs
} from "./redux/reducer";
import { ItineraryStepDragObject } from "./objects/itineraryStepDragObject";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    index: number,
    forceShow?: boolean
}

export function ItineraryStepItemDropTarget(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const steps = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const launchBlockAdd = useItineraryLaunchBlockAdd();
    const checkForFixedDateBlock = useFixedDateBlockCheck();
    const [collected, dropHandle] = useDrop<ItineraryStepDragObject, unknown, { isHover: boolean }>({
        accept: ['step', 'block'],
        async drop(item) {
            if (item.data) {
                if (item.drag === 'reorder') {
                    if (item.data.isBloc && item.data.group) {
                        const groups = transformStepInputsToGroups(steps);
                        const group = groups[item.data.group] ?? [];
                        let stepClone = cloneDeep(group[0]!);
                        let aboveStepClone = steps[props.index - 1] ?
                            cloneDeep(steps[props.index - 1]!) :
                            stepClone;

                        if (
                            tripStartDate &&
                            tripEndDate &&
                            isNumber(stepClone.circuit) &&
                            stepClone.circuit_start_date &&
                            (
                                stepClone.circuit !== aboveStepClone.circuit ||
                                stepClone.circuit_trip_version !== aboveStepClone.circuit_trip_version
                            )
                        ) {
                            const manager = StepsDirectionsManager.getInstance();
                            const datesManager = new StepsDatesManager(
                                tripStartDate,
                                tripEndDate
                            );

                            dispatch(
                                markIndexAsCalculatingTransport({
                                    index: props.index - 1,
                                    isCalculating: true
                                })
                            );
                            [aboveStepClone, stepClone] = await manager.recomputeTransportsBetween(
                                aboveStepClone,
                                stepClone
                            );
                            [aboveStepClone, stepClone] = datesManager.recomputeDates(aboveStepClone, stepClone);
                            dispatch(
                                markIndexAsCalculatingTransport({
                                    index: props.index - 1,
                                    isCalculating: false
                                })
                            );

                            if (
                                checkForFixedDateBlock({
                                    aboveStep: aboveStepClone,
                                    step: stepClone,
                                    belowStep: stepClone
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.bloc-fixed-date-dnd-warning'),
                                    { variant: 'error' }
                                );
                                return;
                            }
                        }

                        dispatch(swapBlock({ group: item.data.group, index: props.index }));
                    } else if (item.data.type === 'STEP') {
                        let stepClone = cloneDeep(
                            steps.find((step) => {
                                return step.id === item.data!.id;
                            })!
                        );
                        let aboveStepClone = steps[props.index - 1] ?
                            cloneDeep(steps[props.index - 1]!) :
                            stepClone;
                        let belowStepClone = steps[props.index] ?
                            cloneDeep(steps[props.index]!) :
                            stepClone;

                        if (
                            tripStartDate &&
                            tripEndDate &&
                            isNumber(belowStepClone.circuit) &&
                            belowStepClone.circuit_start_date &&
                            (
                                stepClone.circuit !== belowStepClone.circuit ||
                                stepClone.circuit_trip_version !== belowStepClone.circuit_trip_version
                            )
                        ) {
                            const manager = StepsDirectionsManager.getInstance();
                            const datesManager = new StepsDatesManager(
                                tripStartDate,
                                tripEndDate
                            );

                            dispatch(
                                markIndexAsCalculatingTransport({
                                    index: props.index - 1,
                                    isCalculating: true
                                })
                            );
                            [aboveStepClone, stepClone] = await manager.recomputeTransportsBetween(
                                aboveStepClone,
                                stepClone
                            );
                            [aboveStepClone, stepClone] = datesManager.recomputeDates(aboveStepClone, stepClone);
                            [stepClone, belowStepClone] = await manager.recomputeTransportsBetween(
                                stepClone,
                                belowStepClone
                            );
                            [stepClone, belowStepClone] = datesManager.recomputeDates(stepClone, belowStepClone);
                            dispatch(
                                markIndexAsCalculatingTransport({
                                    index: props.index - 1,
                                    isCalculating: false
                                })
                            );

                            if (
                                checkForFixedDateBlock({
                                    aboveStep: stepClone,
                                    step: stepClone,
                                    belowStep: belowStepClone
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.bloc-fixed-date-step-dnd-warning'),
                                    { variant: 'error' }
                                );
                                return;
                            }
                        }

                        dispatch(swapStepsInputs({ id: item.data.id, index: props.index }));
                    }
                } else if (item.data.circuitId && item.data.circuitVersion) {
                    launchBlockAdd({
                        type: 'circuit',
                        circuitId: item.data.circuitId,
                        circuitVersion: item.data.circuitVersion,
                        circuitVariant: item.data.circuitVariant,
                        circuitDate: item.data.circuitDate,
                        circuit: item.data.circuit,
                        circuitSource: item.data.circuitSource ?? '',
                        tripId: item.data.tripId,
                        tripVersion: item.data.tripVersion,
                        steps: item.data.steps,
                        index: props.index,
                        products: item.data.products
                    });
                } else {
                    launchBlockAdd({
                        type: 'typical-trip',
                        tripId: item.data.tripId,
                        tripVersion: item.data.tripVersion,
                        trip: item.data.typicalTrip,
                        steps: item.data.steps,
                        index: props.index
                    });
                }
            }
        },
        collect(monitor) {
            return {
                isHover: monitor.isOver()
            };
        }
    }, [
        props.index,
        tripStartDate,
        tripEndDate,
        checkForFixedDateBlock,
        launchBlockAdd
    ]);

    return (
        <DropTarget
            ref={dropHandle}
            sx={{
                overflow: 'hidden',
                height: collected.isHover || props.forceShow ? undefined : 24,
                opacity: collected.isHover || props.forceShow ? 1 : 0,
                paddingTop: collected.isHover || props.forceShow ? 0.5 : 0,
                paddingBottom: collected.isHover || props.forceShow ? 0.5 : 0
            }}
        >
            <Typography
                sx={(theme) => ({
                    width: '100%',
                    padding: 1,
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor: '#5B9AF0',
                    color: '#fff'
                })}
            >
                {t('itinerary.drop-target')}
            </Typography>
        </DropTarget>
    );
}

const DropTarget = styled(ListItem)((props) => ({
    padding: 0,
    paddingLeft: props.theme.spacing(4),
    paddingRight: props.theme.spacing(4),
    transition: props.theme.transitions.create(
        ['opacity', 'height'],
        {
            easing: props.theme.transitions.easing.sharp,
            duration: 250
        }
    )
}));
