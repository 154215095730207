import React, {Fragment, useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useSnackbar } from 'notistack';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';

import SummaryHeader from './SummaryHeader';
import PlaneLoader from '../PlaneLoader/PlaneLoader.jsx';
import Footer from "../Footer/Footer.jsx";
import SummaryHeaderMobile from "./SummaryHeaderMobile";

// Functions
import { useGetTaxesPrice } from "../CartMaterial/utils/getTaxesPrice";
import GetTripInfo from './Functions/GetTripInfo';
import GetOutOfCart from './Functions/GetOutOfCart';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { SetSummaryStep } from "../../Actions/Summary";
import GetPrice from "./Functions/GetPrice";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    contactUserImg: {
        height: 100,
        width: 100,
        borderRadius: "50%"
    },
    agencyUserImg: {
        height: 100,
        maxWidth: 100,
        objectFit: "contain"
    },
    bold: {
        fontWeight: "bold"
    },
    separator: {
		borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline"
	},
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    lightGreen: {
        color: "#76B6C2 !important"
    },
    textCenter: {
        textAlign: "center"
    },
}));

const SummaryContact = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { t } = useTranslation();
    const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [missingDocs, setMissingDocs] = useState(0);
	const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const trip_info = useSelector(store => store.summary.trip_info);
	const trip_main_info = useSelector(store => store.summary.trip_main_info);
	const trip_docs = useSelector(store => store.menu.trip_docs);
	const selectedProducts = useSelector(store => store.summary.selectedProducts);
	const itinerary = useSelector(store => store.summary.itinerary);
    let address = ""
	const { headers } = CheckBeforeRequest();
    const getTaxesPrice = useGetTaxesPrice();

    useEffect(() => {
        dispatch(SetSummaryStep(6));
		if (trip_info === null) {
			setIsLoading(true);
			GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
		} 
	}, []);
	useEffect(() => {
		if (trip_main_info !== null) {
			if (trip_docs.length === 0) {
				getDoc();
			}
            if (trip_main_info.target_client !== null) {
                if (trip_main_info.target_client.street !== null) {
                    address += trip_main_info.target_client.street;
                }
                if (trip_main_info.target_client.street2 !== null) {
                    if (trip_main_info.target_client.street !== null) {
                        address += " " + trip_main_info.target_client.street2;
                    } else {
                        address += trip_main_info.target_client.street2;
                    }
                }
                if (trip_main_info.target_client.zip_code !== null) {
                    if (trip_main_info.target_client.street !== null || trip_main_info.target_client.street2 !== null) {
                        address += " " + trip_main_info.target_client.zip_code;
                    } else {
                        address += trip_main_info.target_client.zip_code;
                    }
                }
                if (trip_main_info.target_client.city !== null) {
                    if (trip_main_info.target_client.street !== null || trip_main_info.target_client.street2 !== null || trip_main_info.target_client.zip_code !== null) {
                        address += " " + trip_main_info.target_client.city;
                    } else {
                        address += trip_main_info.target_client.city;
                    }
                }
            }
        }
	}, [trip_main_info]);
	// useEffect(() => {
	// 	headerRef.current.scrollIntoView({
	// 		behavior: 'smooth',
	// 		block: 'nearest',
	// 		inline: 'start'
	// 	});
	// 	getPrice();
	// }, [summary_step]);
	useEffect(() => {
		if (!isLoading) {
			GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch, getTaxesPrice);
		}
	}, [isLoading, getTaxesPrice]);
	useEffect(() => {
		if (trip_docs.length !== 0) {
			let total_requested = 0;
			let total_received = 0;
			trip_docs.map((document) =>{
				if(document.requested === true){
					total_requested += 1;
					if(document.received === true){
						total_received += 1;
					}
				}
			});
			let temp_missing_docs = total_requested - total_received;
			setMissingDocs(temp_missing_docs);
		}
	}, [trip_docs]);
	const getDoc = () => {
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	};
    if (isLoading || trip_info === null) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
    return (
        <Grid container style={{display: "block"}}>
            <Grid item>
                <SummaryHeader missingDocs={missingDocs} props={props}/>
            </Grid>
            <Grid item>
                <Container className={`${classes.container} ${classes.genericText} ${classes.textCenter}`} style={{paddingTop: fullScreen ? 120 : 70}}>
                    <Grid container direction={"column"} alignItems={"center"} justify={"center"} spacing={ fullScreen ? 2 : 8} style={{marginTop: 20}}>
                        <Grid item>
                            <Typography variant={"h4"}>{ t("summary.your_contact_at") + " " + (trip_main_info.target_client !== null ? trip_main_info.target_client.name : " - ") }</Typography>
                        </Grid>
                        <Grid item container direction={"row"} spacing={4}>
                            <Grid item sm={6} xs={12} className={clsx({[classes.separator]: !fullScreen})}>
                                <Grid item container direction={"column"} alignItems={"center"} justify={"center"} spacing={2}>
                                    <Grid item className={classes.bold}>{ t("summary.your_contact") }</Grid>
                                    <Grid item>
                                        <img className={classes.contactUserImg} src={ (trip_main_info.creator !== null && trip_main_info.creator.avatar !== null) ? (trip_main_info.creator.avatar.thumbnail_little !== null ? trip_main_info.creator.avatar.thumbnail_little : trip_main_info.creator.avatar.url) : "/Img/avatar.png"} />
                                    </Grid>
                                    <Grid item>
                                        <span>{ trip_main_info.creator !== null ? ( trip_main_info.creator.first_name + " " + trip_main_info.creator.last_name ) : "-" }</span>
                                    </Grid>
                                    <Grid item>
                                        <PhoneIcon style={{verticalAlign: "sub", marginRight: 5}}/>
                                        <span>{ trip_main_info.creator !== null ?  trip_main_info.creator.phone_number : "-" }</span>
                                    </Grid>
                                    <Grid item>
                                        <MailIcon style={{verticalAlign: "sub", marginRight: 5}}/>
                                        <span>{ trip_main_info.creator !== null ?  trip_main_info.creator.email : "-" }</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid item container direction={"column"} alignItems={"center"} justify={"center"} spacing={2}>
                                    <Grid item className={classes.bold}>{ t("summary.your_agency") }</Grid>
                                    <Grid item>
                                        <img className={classes.agencyUserImg} src={ (trip_main_info.target_client !== null && trip_main_info.target_client.logo !== null) ? (trip_main_info.target_client.logo.thumbnail_little !== null ? trip_main_info.target_client.logo.thumbnail_little : trip_main_info.target_client.logo.url) : "/Img/avatar.png"} />
                                    </Grid>
                                    <Grid item>
                                        <span>{ trip_main_info.target_client !== null ? trip_main_info.target_client.name : "-" }</span>
                                    </Grid>
                                    <Grid item>
                                        <PhoneIcon style={{verticalAlign: "sub", marginRight: 5}}/>
                                        <span>{trip_main_info.target_client !== null ? trip_main_info.target_client.phone_number : "-"}</span>
                                    </Grid>
                                    {
                                        address !== "" && (
                                            <Grid item>
                                                <RoomIcon style={{verticalAlign: "sub", marginRight: 5}}/>
                                                <span>{address}</span>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            {
                !fullScreen && (
                    <Grid item style={{marginTop: "calc(5% + 60px)", bottom: 0}}>
                        <Footer/>
                    </Grid>
                )
            }
			{
				fullScreen && (
					<Grid item>
						<SummaryHeaderMobile missingDocs={missingDocs} props={props}/>
					</Grid>
				)
			}
        </Grid>
    )
}
export default SummaryContact;