import { useCallback } from "react";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { StatusBooking } from "../../Itinerary/objects/statusBooking";

type Callback = (
    data: {
        tripId: number,
        version: number,
        productId: number,
        ref: string,
        status: StatusBooking | null,
        isManual: boolean
    }
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (product: unknown) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useProductBookingStatusChange(options: Options): Callback {
    return useCallback(
        async (data) => {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const product = await makeRequest(data);
                if (options.onSuccess && product) {
                    options.onSuccess(product);
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        },
        []
    );
}

type RequestOptions = {
    tripId: number,
    version: number,
    productId: number,
    ref: string,
    status: StatusBooking | null,
    isManual: boolean
}

async function makeRequest(options: RequestOptions): Promise<unknown> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        const response = await axios.post(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/status-manager/${options.productId}/status/`,
            {
                item_reference: options.ref,
                status_booking: options.status
            },
            { headers }
        );
        return response.data;
    }
}
