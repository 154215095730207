//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
//Core
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import { EditorState } from 'draft-js';
import { isNumber } from 'lodash';
//Components
import { OverlappingAccommodationsModal } from "./OverlappingAccommodationsModal";
import AccommodationStandaloneProductFormAccommodation from './AccommodationStandaloneProductFormAccommodation';
import AccommodationStandaloneProductFormPrice from './AccommodationStandaloneProductFormPrice';
import RichEditor, { RichEditorStyleMap } from '../../Menu/MaterialTripList/utils/editor/editor';
import { CartManualProductFormAddressPicker } from '../../CartMaterial/CartManualProductFormAddressPicker';
//Common
import { useShowError } from '../../Utils/showError';
import { customColors } from '../../Menu/MaterialTripList/utils/editor/customColors';
import { restoreRichEditorStateFromHtml } from '../../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { renderRichEditorContentHTML } from '../../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import GetCookie from '../../Common/Functions/GetCookie';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import i18n from "../../../i18n";

const useStyles = makeStyles({
    formContainer: {
        paddingTop: 8
    },
    gridImage: {
        width: 250
    },
    pictureContainer: {
        position: 'relative'
    },
    removePicture: {
        position: 'absolute',
        right: 8,
        top: 8,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 2
    },
    imageButton: {
        marginTop: 8
    },
    gridContent: {
        marginLeft: 8
    },
    image: {
        borderRadius: 16
    },
    select: {
        width: '100%'
    },
    travelersContainer: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 0 12px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    travelersError: {
        borderColor: 'red'
    },
    datesErrorText: {
        color: 'red',
        fontSize: 12
    },
    buttonSeparator: {
        margin: '16px 0 0 0'
    },
    manualProductCancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    manualProductCreateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    menuHeight: {
        maxHeight: 216
    }
});

const EmptyState = EditorState.createEmpty();

const AccommodationStandaloneProductForm = ({
    open,
    detail,
    current_groups,
    start_date_init,
    end_date_init,
    destination,
    onClose,
    standaloneSuccess
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const itinerary_list = useSelector(
        (state) => state.itinerary.itinerary_list
    );
    const name = detail.name;
    const product_type = useSelector((state) => state.cart.manual_product_type);
    const destination_associated = destination.id;

    const provider_id = useSelector(
        (state) => state.cart.manual_product_provider_id
    );
    const provider_name = useSelector(
        (state) => state.cart.manual_product_provider_name
    );
    const manual_product_groups = useSelector(
        (state) => state.cart.manual_product_groups
    );
    const manual_product_id = useSelector(
        (state) => state.cart.manual_product_id
    );
    const price_id = useSelector((state) => state.cart.manual_product_price_id);
    const default_pictures = useSelector(
        (state) => state.base.default_pictures
    );
    const start_date = useSelector(
        (state) => state.cart.manual_product_start_date
    );
    const start_hour = useSelector(
        (state) => state.cart.manual_product_start_hour
    );
    const end_date = useSelector((state) => state.cart.manual_product_end_date);
    const end_hour = useSelector((state) => state.cart.manual_product_end_hour);
    const stars = useSelector((state) => state.cart.manual_product_stars);
    const user = useSelector(state => state.user.user);

    const rooms = useSelector((state) => state.cart.manual_product_rooms);
    const picture = (detail.custom_pictures && detail.custom_pictures[0]) ? detail.custom_pictures[0] : (detail.pictures && detail.pictures[0]) ? detail.pictures[0] : null;
    const cgos_reduction = useSelector(
        (state) => state.cart.manual_product_cgos_reduction
    );
    const breakfast_included = useSelector(
        (state) => state.cart.manual_product_breakfast_included
    );
    const lunch_included = useSelector(
        (state) => state.cart.manual_product_lunch_included
    );
    const diner_included = useSelector(
        (state) => state.cart.manual_product_diner_included
    );
    const resort_fees = useSelector(
        (state) => state.cart.manual_product_resort_fees
    );
    const resort_fees_currency = useSelector(
        (state) => state.cart.manual_product_resort_fees_currency
    );
    const flight_paste = useSelector(
        (state) => state.cart.manual_product_flight_paste
    );
    const flight_taxes = useSelector(
        (state) => state.cart.manual_product_flight_taxes
    );
    const baggages = useSelector((state) => state.cart.manual_product_baggages);
    const margin_type = useSelector(
        (state) => state.cart.manual_product_margin_type
    );
    const margin_value = useSelector(
        (state) => state.cart.manual_product_margin_value
    );
    const purchase_price = useSelector(
        (state) => state.cart.manual_product_purchase_price
    );
    const purchase_currency = useSelector(
        (state) => state.cart.manual_product_purchase_currency
    );
    const description = useSelector(
        (state) => state.cart.manual_product_description
    );
    const note = useSelector((state) => state.cart.manual_product_note);
    const manual_item_list = useSelector(
        (state) => state.cart.manual_item_list
    );
    const providers = useSelector((state) => state.trip.providers);
    const travelers = useSelector((state) => state.trip.travelers);
    const manual_providers = useSelector(
        (state) => state.trip.manual_providers
    );
    const locales = useSelector(state => state.user.locales);
    const data_trip = useSelector((state) => state.trip.data_trip);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const [groups, setGroups] = useState([]);
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [providerError, setProviderError] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [travelersError, setTravelersError] = useState(false);
    const [roomNameError, setRoomNameError] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [stateModified, setStateModified] = useState(false);
    const [providersOption, setProvidersOption] = useState([]);
    const [productProvider, setProductProvider] = useState(null);
    const [geolocationError, setGeolocationError] = useState(false);
    const [descriptionEditorState, setDescriptionEditorState] = useState(EmptyState);
    const [loading, setLoading] = useState(false);
    const [geolocation, setGeolocation] = useState({
        address: '',
        position: {
            latitude: null,
            longitude: null
        }
    });
    const showError = useShowError();

    const quotation_code = JSON.parse(
        localStorage.getItem('config')
    ).quotation_code;
    const hours = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23
    ];
    const minutes = [0, 15, 30, 45];

    const onChangeGeolocation = (key, value) => {
        setGeolocation((state) => ({
            ...state,
            [key]: value
        }));
    };

    if (!stateModified) {
        let tmp_description = "";
        if (!detail.is_giata) {
            const localization = detail.localization.find((el) => {
                return el.locale === current_locale.id;
            });
            if (localization) {
                if (localization.room_desc !== "" && localization.room_desc !== null) {

                    tmp_description = tmp_description.concat(t('accommodation.description') + ' :' + '\n' + localization.room_desc + '\n\n');
                }
                if (localization.situation !== "" && localization.situation !== null) {
                    tmp_description = tmp_description.concat(t('accommodation.situation') + ' :' + '\n' + localization.situation + '\n\n');
                }
                if (localization.restoration !== "" && localization.restoration !== null) {
                    tmp_description = tmp_description.concat(t('accommodation.restoration') + ' :' + '\n' + localization.restoration + '\n\n');
                }
            } else {
                if (detail.room_desc !== "" && detail.room_desc !== null) {
                    tmp_description = tmp_description.concat(t('accommodation.description') + ' :' + '\n' + detail.room_desc + '\n\n');
                }
                if (detail.situation !== "" && detail.situation !== null) {
                    tmp_description = tmp_description.concat(t('accommodation.situation') + ' :' + '\n' + detail.situation + '\n\n');
                }
                if (detail.restoration !== "" && detail.restoration !== null) {
                    tmp_description = tmp_description.concat(t('accommodation.restoration') + ' :' + '\n' + detail.restoration + '\n\n');
                }
            }
        } else {
            detail.content.map((content) => {
                tmp_description = tmp_description.concat(content.title + " :" + "\n" + content.content + "\n\n");
            });
        }
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NAME_DESC',
            payload: {
                name: name,
                description: tmp_description
            }
        });
        if (itinerary_list) {
            dispatch({
                type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
                payload: {
                    value: destination.id,
                    itinerary_list: itinerary_list
                }
            });
        }
        setStateModified(true);
    }

    const onNameChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NAME',
            payload: event.target.value
        });
    };
    const onProductTypeChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_TYPE',
            payload: {
                value: parseInt(event.target.value),
                default_pictures: default_pictures
            }
        });
        setNameError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
    };
    const onDestinationAssociatedChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
            payload: {
                value: parseInt(event.target.value),
                itinerary_list: itinerary_list
            }
        });
    };
    const onProviderChange = (event, value) => {
        setProductProvider(value);
        dispatch({ type: 'CART_MANUAL_PRODUCT_PROVIDER', payload: value });
    };
    const onProviderNameChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_PROVIDER_NAME',
            payload: event.target.value
        });
    };
    const onNoteChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NOTE',
            payload: event.target.value
        });
    };
    const onCancelManualProduct = () => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_CANCEL' });
        onClose();
    };

    const onCheck = () => {
        setNameError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
        setGeolocationError(false);
        setDescriptionError(false);
        const styles = {
            ...RichEditorStyleMap,
            ...(quotation_code ? customColors[quotation_code] : null)
        };
        const description = renderRichEditorContentHTML(
            styles,
            descriptionEditorState.getCurrentContent()
        );
        const doc = new DOMParser().parseFromString(description, 'text/html');
        if (doc.body.innerText.length > 12000) {
            enqueueSnackbar(t('cart-material.manual-product-description-too-big'), {
                variant: 'warning'
            });
            pass_control = false;
            setDescriptionError(true);
        }
        let pass_control = true;
        if (purchase_currency === null) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-purchase-currency'),
                {
                    variant: 'warning'
                }
            );
            pass_control = false;
        }
        if (resort_fees !== null && resort_fees_currency === null) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-resort-fees-currency'),
                { variant: 'warning' }
            );
            pass_control = false;
        }
        if (name === null || name === '') {
            enqueueSnackbar(t('cart-material.manual-product-no-name'), {
                variant: 'warning'
            });
            pass_control = false;
            setNameError(true);
        }
        if (
            product_type === 61 &&
            (flight_paste === null || flight_paste === '')
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-flight-paste'), {
                variant: 'warning'
            });
            pass_control = false;
        }
        if (
            [0, 12, 1, 3].includes(product_type) &&
            destination_associated === -1
        ) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-destination-associated'),
                { variant: 'warning' }
            );
            pass_control = false;
            setStepError(true);
        }
        if (
            ![18].includes(product_type) &&
            provider_id === null &&
            provider_name === ''
        ) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-provider-selected'),
                {
                    variant: 'warning'
                }
            );
            pass_control = false;
            setProviderError(true);
        }
        if (
            [0, 1, 2, 3, 4, 5, 7, 9, 10, 12, 13].includes(product_type) &&
            (start_date === null || end_date === null)
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-dates'), {
                variant: 'warning'
            });
            pass_control = false;
            setDatesError(true);
        }
        if (product_type === 0) {
            rooms.map((room) => {
                if (room.name === '') {
                    enqueueSnackbar(
                        t('cart-material.manual-product-no-room-name'),
                        {
                            variant: 'warning'
                        }
                    );
                    pass_control = false;
                    setRoomNameError(true);
                }
                if (!!room.group && room.group.length === 0) {
                    enqueueSnackbar(
                        t('cart-material.manual-product-no-travelers'),
                        {
                            variant: 'warning'
                        }
                    );
                    pass_control = false;
                    setTravelersError(true);
                }
            });
        } else if (product_type !== 18 && groups.length === 0) {
            enqueueSnackbar(t('cart-material.manual-product-no-travelers'), {
                variant: 'warning'
            });
            pass_control = false;
            setTravelersError(true);
        }
        if (
            quotation_code === 'cercledesvoyages' &&
            [0, 1, 12].includes(product_type) &&
            (
                geolocation.address.trim().length === 0 ||
                geolocation.position.latitude === null ||
                geolocation.position.longitude === null
            )
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-geolocation'), { variant: 'warning' });
            pass_control = false;
            setGeolocationError(true);
        }
        return pass_control;
    };

    const onCloseModal = () => {
        if (!loading) {
            onClose();
        }
    };

    const onSave = async (choice) => {
        const { headers } = CheckBeforeRequest();
        setLoading(true);
        if (product_type === 0) {
            let group_request = 0;
            await Promise.all(
                rooms.map(async (room) => {
                    try {
                        const response = await axios({
                            method: 'POST',
                            headers: headers,
                            url: `${API_HREF}client/${
                                window.id_owner
                            }/trip/${GetCookie('trip_id')}/versions/${GetCookie(
                                'trip_id_version'
                            )}/travelers-group/?token=${GetCookie('trip_token')}`,
                            data: {
                                travelers: room.group,
                                default: false
                            }
                        });
                        room.group = undefined;
                        room.group_passenger = undefined;
                        room.group_passenger_id = response.data.id;
                        group_request++;
                        if (group_request === rooms.length) {
                            await onCreateManualProduct(null, choice);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                })
            );
        } else if (product_type === 18) {
            await onCreateManualProduct(null, choice);
        } else if (groups.length > 0) {
            try {
                await axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie(
                        'trip_id'
                    )}/versions/${GetCookie(
                        'trip_id_version'
                    )}/travelers-group/?token=${GetCookie('trip_token')}`,
                    data: {
                        travelers: groups,
                        default: false
                    }
                });
                await onCreateManualProduct(null, choice);
            } catch (error) {
                console.error(error);
            }
        }
        setLoading(false);
    };

    const onCreateManualProduct = (group_passenger, choice) => {
        const { headers } = CheckBeforeRequest();
        let flight_segment = [];
        let tmp_rooms = rooms.map((room) => {
            let tmp_room = { ...room };
            tmp_room.group = undefined;
            return tmp_room;
        });
        const styles = {
            ...RichEditorStyleMap,
            ...(quotation_code ? customColors[quotation_code] : null)
        };
        return axios({
            method: manual_product_id !== null ? 'PATCH' : 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie(
                'trip_id'
            )}/versions/${GetCookie('trip_id_version')}/manual-products/${
                manual_product_id !== null ? `${manual_product_id}/` : ''
            }`,
            data: {
                name: name,
                address: geolocation.address.trim().length > 0 ?
                    geolocation.address :
                    null,
                latitude: geolocation.position.latitude?.toString().slice(0, 12),
                longitude: geolocation.position.longitude?.toString().slice(0, 12),
                product_type: product_type === 61 ? 6 : product_type,
                step:
                    destination_associated === -1
                        ? null
                        : destination_associated,
                provider_name: provider_name === '' ? null : provider_name,
                provider_id: !provider_id || provider_id === -1 ? null : provider_id.provider.id,
                start_date:
                    start_date !== null
                        ? moment
                            .utc(start_date)
                            .set('hours', start_hour.split(':')[0])
                            .set('minutes', start_hour.split(':')[1])
                            .format()
                        : null,
                end_date:
                    end_date !== null
                        ? moment
                            .utc(end_date)
                            .set('hours', end_hour.split(':')[0])
                            .set('minutes', end_hour.split(':')[1])
                            .format()
                        : null,
                is_optional: choice === 'option',
                stars: stars,
                breakfast_included: breakfast_included,
                lunch_included: lunch_included,
                diner_included: diner_included,
                rooms: product_type === 0 ? tmp_rooms : null,
                resort_fees: resort_fees,
                resort_fees_currency:
                    resort_fees_currency !== null
                        ? resort_fees_currency === 47
                            ? 47
                            : resort_fees_currency.id
                        : null,
                flight_paste: flight_paste,
                flight_segment:
                    flight_segment === null || flight_segment.length === 0
                        ? null
                        : flight_segment,
                cgos_reduction: cgos_reduction,
                description: renderRichEditorContentHTML(
                    styles,
                    descriptionEditorState.getCurrentContent()
                ),
                custom_information: note !== '' ? note : null,
                group_passenger: group_passenger === null ? undefined : group_passenger,
                taxes: flight_taxes,
                nb_baggages: baggages,
                picture_id: picture !== null ? picture.id : null,
                prices: [
                    {
                        purchase_price: purchase_price,
                        purchase_currency:
                            purchase_currency !== null
                                ? purchase_currency === 47
                                    ? 47
                                    : purchase_currency.id
                                : null,
                        custom_rate: true,
                        custom_rate_type: margin_type,
                        custom_value: margin_value,
                        id: price_id === null ? undefined : price_id
                    }
                ]
            }
        })
            .then((response) => {
                console.log('response.data : ', response.data);
                if (manual_product_id !== null) {
                    dispatch({
                        type: 'CART_EDIT_MANUAL_ITEM',
                        payload: response.data
                    });
                    enqueueSnackbar(t('cart-material.manual-product-edited'), {
                        variant: 'success'
                    });
                } else {
                    dispatch({
                        type: 'CART_ADD_MANUAL_ITEM',
                        payload: response.data
                    });
                    enqueueSnackbar(t('accommodation.request.post_success_2'), {
                        variant: 'success'
                    });
                }
                standaloneSuccess();
                //onclose();
            })
            .catch((error) => {
                showError(error);
            });
    };
    const onSetStartHour = (value) => () => {
        dispatch({
            type: 'CART_SET_MANUAL_PRODUCT_START_HOUR',
            payload: value
        });
    };
    const onSetEndHour = (value) => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_END_HOUR', payload: value });
    };
    let start_time = [];
    let end_time = [];
    hours.map((hour) => {
        minutes.map((minute) => {
            let tmp_hour = hour;
            let tmp_minute = minute;
            if (tmp_hour < 10) {
                tmp_hour = `0${tmp_hour}`;
            }
            if (tmp_minute < 10) {
                tmp_minute = `0${tmp_minute}`;
            }
            start_time.push(
                <MenuItem
                    key={`manual-product-start-hour-${tmp_hour}:${tmp_minute}`}
                    onClick={onSetStartHour(`${tmp_hour}:${tmp_minute}`)}
                >
                    {tmp_hour}:{tmp_minute}
                </MenuItem>
            );
            end_time.push(
                <MenuItem
                    key={`manual-product-end-hour-${tmp_hour}:${tmp_minute}`}
                    onClick={onSetEndHour(`${tmp_hour}:${tmp_minute}`)}
                >
                    {tmp_hour}:{tmp_minute}
                </MenuItem>
            );
        });
    });
    useEffect(() => {
        setGroups(manual_product_groups);
    }, [manual_product_groups]);
    useEffect(() => {
        const element = document.getElementById('cart-manual-product-form');
        if (element !== undefined && element !== null) {
            let headerOffset = 70;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
        let tmp_groups = [];
        travelers.map((traveler) => tmp_groups.push(traveler.id));
        let option_provider = [];
        providers.map(provider => {
            if (provider.enabled_content_types.includes(product_type === 61 ? 6 : product_type)) {
                option_provider.push(provider);
            }
        });
        manual_providers.map(provider => {
            if (provider.available_content_types.includes(product_type === 61 ? 6 : product_type) && (provider.is_xml === undefined || ['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code)) && (!provider.activation_countries || provider.activation_countries.length === 0 || provider.activation_countries.map(country => country.id).includes(data_trip?.destination?.data?.country?.id))) {
                option_provider.push(provider);
            }
        });
        if (provider_id !== null && option_provider.find(provider => provider.provider.id === provider_id.provider.id) !== undefined) {
            setProductProvider(option_provider.find(provider => provider.provider.id === provider_id.provider.id));
        }
        setProvidersOption(option_provider.sort((a, b) => a.provider.name < b.provider.name));
        setGroups(tmp_groups);
    }, []);

    useEffect(() => {
        if (description) {
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            setDescriptionEditorState(
                restoreRichEditorStateFromHtml(
                    styles,
                    description
                )
            );
        }
    }, [description]);

    useEffect(() => {
        setGeolocation({
            address: detail ?
                [
                    detail.house_number,
                    detail.street,
                    detail.zip_code,
                    detail.city_name,
                    detail.destination_name,
                    detail.country_code
                ].filter((item) => {
                    return item;
                }).join(' - ') :
                '',
            position: {
                latitude: detail?.latitude ?? null,
                longitude: detail?.longitude ?? null
            }
        });
    }, [detail]);

    return (
        <OverlappingAccommodationsModal
            onConfirm={onSave}
        >
            {
                (checkOverlappingAccommodation) => (
                    <>
                        <Dialog
                            open={open}
                            maxWidth="md"
                            onClose={onCloseModal}
                            fullWidth
                        >
                            <DialogTitle>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography fontWeight="bold">
                                        {t("accommodation.standalone_hotel")}
                                    </Typography>
                                    <IconButton onClick={onCloseModal}>
                                        <Close />
                                    </IconButton>
                                </Stack>
                            </DialogTitle>
                            <DialogContent>
                                <Grid
                                    id={'cart-manual-product-form'}
                                    className={classes.formContainer}
                                    container
                                >
                                    <Grid className={classes.gridContent} container item xs spacing={2}>
                                        {/*NAME */}
                                        <Grid item xs={12}>
                                            <FormControl
                                                fullWidth
                                                variant={'outlined'}
                                                error={nameError}
                                            >
                                                <InputLabel htmlFor={'custom-product-name'}>
                                                    {t('cart-material.manual-product-name')}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id={'custom-product-name'}
                                                    type={'text'}
                                                    onChange={onNameChange}
                                                    label={t('cart-material.manual-product-name')}
                                                    value={name}
                                                    disabled={true}
                                                />
                                                {nameError && (
                                                    <FormHelperText>
                                                        {t('cart-material.manual-product-no-name')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        {/*HOTEL */}
                                        <Grid item xs={6}>
                                            <Select
                                                className={classes.select}
                                                variant={'outlined'}
                                                value={product_type}
                                                onChange={onProductTypeChange}
                                                disabled={true}
                                            >
                                                <MenuItem value={12}>
                                                    {t('providers.activities')}
                                                </MenuItem>
                                                <MenuItem value={8}>
                                                    {t('providers.insurances')}
                                                </MenuItem>
                                                {quotation_code !== 'verdie' && (
                                                    <MenuItem value={11}>
                                                        {t('providers.other_product')}
                                                    </MenuItem>
                                                )}
                                                <MenuItem value={7}>
                                                    {t('providers.accommodations')}
                                                </MenuItem>
                                                <MenuItem value={17}>
                                                    {t('providers.travel_book')}
                                                </MenuItem>
                                                <MenuItem value={9}>{t('providers.cruises')}</MenuItem>
                                                <MenuItem value={1}>
                                                    {t('providers.excursions')}
                                                </MenuItem>
                                                <MenuItem value={13}>{t('providers.ferries')}</MenuItem>
                                                <MenuItem value={16}>
                                                    {t('providers.administration_fees')}
                                                </MenuItem>
                                                <MenuItem value={10}>
                                                    {t('providers.touristic_guide')}
                                                </MenuItem>
                                                <MenuItem value={0}>{t('providers.hotels')}</MenuItem>
                                                <MenuItem value={18}>
                                                    {t('providers.reduction')}
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    {t('providers.restaurant')}
                                                </MenuItem>
                                                <MenuItem value={5}>{t('providers.trains')}</MenuItem>
                                                <MenuItem value={4}>
                                                    {t('providers.transfers')}
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    {t('providers.rental_car')}
                                                </MenuItem>
                                                {/*<MenuItem value={ 6 }>{ t('providers.flight') }</MenuItem>*/}
                                                <MenuItem value={61}>
                                                    {t('providers.manual_flight')}
                                                </MenuItem>
                                                {
                                                    (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'touristforum', 'terreslointaines'].includes(quotation_code) &&
                                                        manual_product_id === null
                                                        ? manual_item_list.find(
                                                            (item) => item.product_type === 18
                                                        ) === undefined
                                                        : manual_item_list.find(
                                                            (item) => item.id === manual_product_id
                                                        ) !== undefined) && (
                                                        <MenuItem value={18}>
                                                            {t('header.adjustment_product_name')}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </Grid>
                                        {/*DESTINATION */}
                                        <Grid item xs={6}>
                                            <FormControl fullWidth error={stepError}>
                                                <Select
                                                    variant={'outlined'}
                                                    value={destination_associated}
                                                    onChange={onDestinationAssociatedChange}
                                                    disabled={true}
                                                >
                                                    <MenuItem value={-1}>
                                                        {t(
                                                            'cart-material.manual-product-choose-destination'
                                                        )}
                                                    </MenuItem>
                                                    {
                                                        itinerary_list.map(
                                                            (step) =>
                                                                step.step_type === 'STEP' && (
                                                                    <MenuItem
                                                                        key={`manual-product-step-${step.id}`}
                                                                        value={step.id}
                                                                    >
                                                                        {
                                                                            step.destination.data.name !==
                                                                                undefined
                                                                                ? step.destination.data.name
                                                                                : step.destination.data
                                                                                    .international_name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                        )
                                                    }
                                                </Select>
                                                {stepError && (
                                                    <FormHelperText>
                                                        {t(
                                                            'cart-material.manual-product-no-destination-associated'
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        {/*CHOOSE PROVIDER */}
                                        <Grid item xs={12}>
                                            <CartManualProductFormAddressPicker
                                                {...geolocation}
                                                errors={
                                                    geolocationError ?
                                                        {
                                                            address: geolocation.address.trim().length === 0,
                                                            position: {
                                                                latitude: !isNumber(geolocation.position.latitude),
                                                                longitude: !isNumber(geolocation.position.longitude)
                                                            }
                                                        } :
                                                        undefined
                                                }
                                                onChangeLocation={onChangeGeolocation}
                                            />
                                        </Grid>
                                        <Fragment>
                                            {
                                                (
                                                    quotation_code !== 'visiteurs' ||
                                                    user?.client_full?.type !== 2
                                                ) &&
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        variant={'outlined'}
                                                        error={providerError}
                                                    >
                                                        <Autocomplete
                                                            id={"manual-product-choose-provider-label"}
                                                            options={providersOption}
                                                            value={productProvider}
                                                            getOptionLabel={(option) => `${option.provider.name}`}
                                                            getOptionSelected={(option, value) => option.provider.id === value.provider.id}
                                                            onChange={onProviderChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('cart-material.manual-product-choose-provider')}
                                                                    margin={"normal"}
                                                                    variant={"outlined"}
                                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                                />
                                                            )}
                                                        />
                                                        {providerError && (
                                                            <FormHelperText>
                                                                {t(
                                                                    'cart-material.manual-product-no-provider-selected'
                                                                )}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                {
                                                    ((quotation_code !== "cercledesvoyages" && user?.client_full?.type !== 2) || quotation_code === "visiteurs") && (
                                                        <FormControl fullWidth variant={'outlined'}>
                                                            <InputLabel htmlFor={'manual-product-provider'}>
                                                                {t(
                                                                    'cart-material.manual-product-provider-name'
                                                                )}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                id={'manual-product-provider'}
                                                                type={'text'}
                                                                onChange={onProviderNameChange}
                                                                label={t(
                                                                    'cart-material.manual-product-provider-name'
                                                                )}
                                                                value={provider_name}
                                                            />
                                                        </FormControl>
                                                    )
                                                }
                                            </Grid>
                                        </Fragment>
                                        {/*TRAVELERS */}
                                        <AccommodationStandaloneProductFormAccommodation
                                            roomNameError={roomNameError}
                                            datesError={datesError}
                                            travelersError={travelersError}
                                            detail={detail}
                                            current_groups={current_groups}
                                            start_date={start_date_init}
                                            end_date={end_date_init}
                                        />
                                        <AccommodationStandaloneProductFormPrice />
                                        <Grid item xs={12} style={{ border: descriptionError ? '1px solid red' : undefined }}>
                                            <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
                                                {
                                                    !['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'touristforum', 'terreslointaines'].includes(quotation_code) ?
                                                        t('cart-material.manual-product-description') :
                                                        t('cart-material.manual-product-client-description')
                                                } :
                                            </Typography>
                                            <RichEditor
                                                values={{}}
                                                pictures={null}
                                                editorState={descriptionEditorState}
                                                setEditorState={setDescriptionEditorState}
                                                controlledState
                                            />
                                        </Grid>
                                        {/*FRAIS - DEVISE - NOTE */}
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant={'outlined'}>
                                                <InputLabel htmlFor={'manual-product-note'}>
                                                    {
                                                        !['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'touristforum', 'terreslointaines'].includes(quotation_code) ?
                                                            t('cart-material.manual-product-note') :
                                                            t('cart-material.manual-product-intern-note')
                                                    }
                                                </InputLabel>
                                                <OutlinedInput
                                                    id={'manual-product-note'}
                                                    type={'text'}
                                                    rowsMax={Infinity}
                                                    rows={3}
                                                    multiline
                                                    onChange={onNoteChange}
                                                    label={
                                                        !['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'touristforum', 'terreslointaines'].includes(quotation_code) ?
                                                            t('cart-material.manual-product-note') :
                                                            t('cart-material.manual-product-intern-note')
                                                    }
                                                    value={note}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                                            <Typography fontWeight="bold" component="div" sx={{ marginBottom: 2 }}>
                                                {t('shared.circuit-step-image')}
                                            </Typography>
                                            {
                                                picture &&
                                                <Card sx={{ maxWidth: 300 }}>
                                                    <CardMedia
                                                        component={'img'}
                                                        alt={picture.alt}
                                                        height={200}
                                                        image={picture.url ?? ''}
                                                    />
                                                    <CardContent>
                                                        <Typography>{picture.name}</Typography>
                                                    </CardContent>
                                                </Card>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onCancelManualProduct} disabled={loading}>
                                    {t('global.cancel')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (onCheck()) {
                                            checkOverlappingAccommodation(
                                                window.moment
                                                    .utc(start_date)
                                                    .set('hours', start_hour.split(':')[0])
                                                    .set('minutes', start_hour.split(':')[1])
                                                    .toISOString(),
                                                window.moment
                                                    .utc(end_date)
                                                    .set('hours', end_hour.split(':')[0])
                                                    .set('minutes', end_hour.split(':')[1])
                                                    .toISOString()
                                            );
                                        } else {
                                            setLoading(false);
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {
                                        loading &&
                                        <CircularProgress size="20px" />
                                    }
                                    {
                                        !loading &&
                                        t('global.validate')
                                    }
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
        </OverlappingAccommodationsModal>
    );
};

export default AccommodationStandaloneProductForm;
