import axios from "axios";
import memoize from "memoizee";
import { cloneDeep, flatten, isString, keyBy, uniqBy } from "lodash";
import tzlookup from "@photostructure/tz-lookup";
import { selectStepTransport, transportVehicles } from "./selectStepTransport";
import { filterFlightAlternatives } from "./filterFlightAlternatives";
import { takeBestTransport } from "./takeBestTransport";
import { itineraryToItineraryInput } from "./itineraryToItineraryInput";
import { createSortTransports } from "./createSortTransport";
import { sortPlaneAlternatives } from "./sortPlaneAlternatives";
import { getFlashDestination } from "./getFlashDestination";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Transport } from "../objects/transport";
import { ItineraryInput } from "../objects/itineraryState";
import { FlashDestination } from "../objects/flashDestination";
import { FlashGetDestination } from "../objects/flashGetDestination";
import { Itinerary, VehicleKind } from "../objects/itinerary";
import { IataAirport } from "../objects/iataAirport";
import { Place, Route } from "../objects/r2rSearchResponse";

let instance: StepsDirectionsManager | null = null;

export class StepsDirectionsManager {
    private directionsService: google.maps.DirectionsService;
    private geocoder: google.maps.Geocoder;
    private places: google.maps.places.PlacesService;
    private overQueryLimitTimeout = 1000;

    private constructor() {
        this.directionsService = new google.maps.DirectionsService();
        this.geocoder = new google.maps.Geocoder();
        const map = new google.maps.Map(document.createElement('div'));
        this.places = new google.maps.places.PlacesService(map);
        this.transformPlaceIdToCoordinates = memoize(
            this.transformPlaceIdToCoordinates,
            {
                promise: true,
                primitive: true
            }
        );
        this.findAirports = memoize(
            this.findAirports,
            {
                promise: true,
                normalizer(args) {
                    return args[0].destination?.destination_id.toString() ?? args[0].places_id ?? '';
                }
            }
        );
    }

    private transformToLocation(location: FlashGetDestination): google.maps.LatLng;
    private transformToLocation(location: string): { placeId: string };
    private transformToLocation(location: google.maps.LatLng): google.maps.LatLng;
    private transformToLocation(location: FlashGetDestination | string | google.maps.LatLng):
        google.maps.LatLngLiteral |
        { placeId: string } |
        google.maps.LatLng;
    private transformToLocation(location: FlashGetDestination | string | google.maps.LatLng) {
        if (isString(location)) {
            return {
                placeId: location
            };
        } else if (location instanceof google.maps.LatLng) {
            return location;
        }
        return new google.maps.LatLng({
            lng: location.longitude ?? 0,
            lat: location.latitude ?? 0
        });
    }

    private async findAirports(step: ItineraryInput): Promise<IataAirport[]> {
        const { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            const data = step.destination?.destination_id ?
                await getFlashDestination(step.destination?.destination_id) :
                null;
            const location = !step.destination ?
                await this.transformPlaceIdToCoordinates(step.places_id) :
                new google.maps.LatLng({
                    lat: data?.latitude ?? 0,
                    lng: data?.longitude ?? 0
                });
            const response = await axios.get<IataAirport[]>(
                `${API_HREF}iata-airports/get_airports_by_radius/`,
                {
                    headers,
                    params: {
                        latitude: location.lat(),
                        longitude: location.lng(),
                        radius: 100000
                    }
                }
            );
            return response.data.filter((airport) => {
                return [1, 2].includes(airport.weight);
            });
        }

        return [];
    }

    private async findFlights(
        a: ItineraryInput,
        b: ItineraryInput
    ): Promise<Transport[]> {
        const aData = a.destination?.destination_id ?
            await getFlashDestination(a.destination?.destination_id) :
            null;
        const bData = b.destination?.destination_id ?
            await getFlashDestination(b.destination?.destination_id) :
            null;
        const aLocation = !a.destination ?
            await this.transformPlaceIdToCoordinates(a.places_id) :
            new google.maps.LatLng({
                lat: aData?.latitude ?? 0,
                lng: aData?.longitude ?? 0
            });
        const bLocation = !b.destination ?
            await this.transformPlaceIdToCoordinates(b.places_id) :
            new google.maps.LatLng({
                lat: bData?.latitude ?? 0,
                lng: bData?.longitude ?? 0
            });

        const aAirports = await this.findAirports(a);
        const bAirports = await this.findAirports(b);
        const leadingCarSegments = keyBy(
            await Promise.all(
                aAirports.map(async (airport) => {
                    const result = await this.findRoutesBetween({
                        mode: google.maps.TravelMode.DRIVING,
                        a: aLocation,
                        b: new google.maps.LatLng({
                            lat: parseFloat(airport.latitude ?? '0'),
                            lng: parseFloat(airport.longitude ?? '0')
                        }),
                        avoidFerries: false,
                        provideRouteAlternatives: false,
                        service: 'google-maps'
                    });
                    return {
                        airport: airport.airport_code,
                        route: {
                            operatingDays: [],
                            steps: result?.routes[0]?.legs[0]?.steps.map((item): Transport['alternatives'][number]['steps'][number] => ({
                                path: item.path,
                                distance: item.distance?.value ?? 0,
                                duration: item.duration?.value ?? 0,
                                arrivalTime: 0,
                                departureTime: 0,
                                depPlace: -1,
                                arrPlace: -1,
                                vehicle: {
                                    kind: item.maneuver === 'ferry' ? 'ferry' : 'car',
                                    name: item.maneuver === 'ferry' ? 'Ferry' : 'Voiture'
                                }
                            })) ?? [],
                            disabled: false
                        }
                    };
                })
            ),
            (item) => item.airport
        );
        const trailingCarSegments = keyBy(
            await Promise.all(
                bAirports.map(async (airport) => {
                    const result = await this.findRoutesBetween({
                        mode: google.maps.TravelMode.DRIVING,
                        a: new google.maps.LatLng({
                            lat: parseFloat(airport.latitude ?? '0'),
                            lng: parseFloat(airport.longitude ?? '0')
                        }),
                        b: bLocation,
                        avoidFerries: false,
                        provideRouteAlternatives: false,
                        service: 'google-maps'
                    });
                    return {
                        airport: airport.airport_code,
                        route: {
                            operatingDays: [],
                            steps: result?.routes[0]?.legs[0]?.steps.map((item): Transport['alternatives'][number]['steps'][number] => ({
                                path: item.path,
                                distance: item.distance?.value ?? 0,
                                duration: item.duration?.value ?? 0,
                                arrivalTime: 0,
                                departureTime: 0,
                                depPlace: -1,
                                arrPlace: -1,
                                vehicle: {
                                    kind: item.maneuver === 'ferry' ? 'ferry' : 'car',
                                    name: item.maneuver === 'ferry' ? 'Ferry' : 'Voiture'
                                }
                            })) ?? [],
                            disabled: false
                        }
                    };
                })
            ),
            (item) => item.airport
        );
        const places: Place[] = aAirports.map((airport) => {
            return {
                code: airport.airport_code,
                countryCode: airport.iata_city.iata_country.country_code,
                kind: 'airport',
                lat: parseFloat(airport.latitude ?? '0'),
                lng: parseFloat(airport.longitude ?? '0'),
                regionCode: airport.iata_city.city_code,
                shortName: airport.international_name,
                timeZone: 'UNKNOWN'
            };
        }).concat(
            bAirports.map((airport) => {
                return {
                    code: airport.airport_code,
                    countryCode: airport.iata_city.iata_country.country_code,
                    kind: 'airport',
                    lat: parseFloat(airport.latitude ?? '0'),
                    lng: parseFloat(airport.longitude ?? '0'),
                    regionCode: airport.iata_city.city_code,
                    shortName: airport.international_name,
                    timeZone: 'UNKNOWN'
                };
            })
        );
        const airRoutes = flatten(
            aAirports.map((aAirport) => {
                return bAirports.map((bAirport) => {
                    return { from: aAirport, to: bAirport };
                });
            })
        );
        try {
            const result = airRoutes.map((route): Transport => {
                const fromLocation = new google.maps.LatLng({
                    lat: parseFloat(route.from.latitude ?? '0'),
                    lng: parseFloat(route.from.longitude ?? '0')
                });
                const toLocation = new google.maps.LatLng({
                    lat: parseFloat(route.to.latitude ?? '0'),
                    lng: parseFloat(route.to.longitude ?? '0')
                });
                const originAirport = route.from;
                const destinationAirport = route.to;
                const departureTimezone = tzlookup(fromLocation.lat(), fromLocation.lng());
                const arrivalTimezone = tzlookup(toLocation.lat(), toLocation.lng());

                const distance = google.maps.geometry.spherical.computeDistanceBetween(
                    fromLocation,
                    toLocation
                );
                const duration = distance * 3600 / 903000;

                let departureTime = 0;

                const startDate = window.moment.tz(a.start_date.replace('Z', ''), departureTimezone);
                const endDate = window.moment.tz(a.end_date.replace('Z', ''), departureTimezone);

                departureTime = endDate.get('hours') * 3600 +
                    endDate.get('minutes') * 60 +
                    endDate.get('seconds');

                if (
                    !startDate.isSame(endDate, 'day') ||
                    (
                        startDate.get('hours') * 3600 +
                        startDate.get('minutes') * 60 +
                        startDate.get('seconds')
                    ) < 9 * 3600
                ) {
                    departureTime = 9 * 3600;
                }

                const leadingCarSegmentsDuration = leadingCarSegments[
                    originAirport.airport_code
                ]?.route.steps.reduce((prev, current) => {
                    return prev + current.duration;
                }, 0) ?? 0;
                departureTime += leadingCarSegmentsDuration;

                const departure = endDate.clone();
                departure.set('hours', Math.trunc(departureTime / 3600));
                departure.set('minutes', Math.trunc(departureTime % 3600 / 60));
                departure.set('seconds', Math.trunc(departureTime % 3600 % 60));

                const arrival = departure.clone().add(duration, 'seconds').tz(arrivalTimezone);
                let arrivalTime = arrival.get('hours') * 3600 +
                    arrival.get('minute') * 60 +
                    arrival.get('seconds');

                //only one case needs this for now : Bryce Canyon, Utah -> San Francisco, California
                if (
                    window.moment.utc(arrival.format('DD/MM/YYYY HH[h]mm[s]ss'), 'DD/MM/YYYY HH[h]mm[s]ss').isBefore(
                        window.moment.utc(departure.format('DD/MM/YYYY HH[h]mm[s]ss'), 'DD/MM/YYYY HH[h]mm[s]ss')
                    )
                ) {
                    arrivalTime = departureTime + 1;
                }

                const daysCount = window.moment.utc(arrival.format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(
                    window.moment.utc(departure.format('DD/MM/YYYY'), 'DD/MM/YYYY'),
                    'days'
                ) + 1;

                const originAirportPlaceIndex = aAirports.findIndex((item) => {
                    return item.id === originAirport.id;
                });
                const destinationAirportPlaceIndex = bAirports.findIndex((item) => {
                    return item.id === destinationAirport.id;
                });
                return {
                    types: ['plane'],
                    origin: {
                        identifier: originAirport?.airport_code ?? '',
                        name: originAirport?.international_name ?? '',
                        weight: originAirport.weight
                    },
                    destination: {
                        identifier: destinationAirport?.airport_code ?? '',
                        name: destinationAirport?.international_name ?? '',
                        weight: destinationAirport.weight
                    },
                    r2rPlaces: places,
                    r2rRoutes: [],
                    alternatives: [
                        {
                            operatingDays: [
                                0,
                                1,
                                2,
                                3,
                                4,
                                5,
                                6
                            ],
                            steps: [
                                ...(leadingCarSegments[originAirport.airport_code]?.route.steps ?? []),
                                ...(new Array(daysCount).fill(null).map((_, index, array) => ({
                                    vehicle: {
                                        kind: 'plane' as VehicleKind,
                                        name: 'avion'
                                    },
                                    departureTime: index === 0 ? departureTime : 1,
                                    arrivalTime: index === array.length - 1 ? arrivalTime : 23 * 60 * 60 + 59 * 60,
                                    arrPlace: destinationAirportPlaceIndex >= 0 ?
                                        destinationAirportPlaceIndex + aAirports.length :
                                        -1,
                                    depPlace: originAirportPlaceIndex,
                                    distance: index === 0 ? distance : 0,
                                    duration: index === 0 ? duration : 0,
                                    path: index === 0 ?
                                        [
                                            fromLocation,
                                            toLocation
                                        ] :
                                        []
                                }))),
                                ...(trailingCarSegments[destinationAirport.airport_code]?.route.steps ?? [])
                            ],
                            disabled: false
                        }
                    ].sort((a, b) => {
                        return sortPlaneAlternatives({
                            a,
                            b,
                            aLocation,
                            bLocation
                        });
                    }),
                    reduced: true
                };
            });
            const routes: Route[] = result.map((route) => {
                const airLeg = route.alternatives[0]?.steps.find((item) => {
                    return item.vehicle.kind === 'plane';
                });
                return {
                    name: `${route.origin.name} - ${route.destination.name}`,
                    depPlace: airLeg?.depPlace ?? -1,
                    arrPlace: airLeg?.arrPlace ?? -1,
                    distance: (
                        route.alternatives[0]?.steps.reduce((prev, current) => {
                            return prev + current.distance;
                        }, 0) ?? 0
                    ) / 1000,
                    totalDuration: (
                        route.alternatives[0]?.steps.reduce((prev, current) => {
                            return prev + current.duration;
                        }, 0) ?? 0
                    ) / 60,
                    totalTransferDuration: 0,
                    totalTransitDuration: 0,
                    segments: route.alternatives[0]?.steps.map((item): Route['segments'][number] => ({
                        arrPlace: item.arrPlace,
                        depPlace: item.depPlace,
                        distance: item.distance,
                        indicativePrices: [],
                        outbound: [],
                        path: google.maps.geometry.encoding.encodePath(item.path),
                        segmentKind: item.vehicle.kind === 'plane' ? 'air' : 'surface',
                        vehicle: -1,
                        transitDuration: 0,
                        transferDuration: 0
                    })) ?? [],
                    alternatives: [],
                    indicativePrices: []
                };
            });
            return result.map((item) => ({
                ...item,
                r2rRoutes: routes
            }));
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    private isDestinationInJapan(position: google.maps.LatLng): boolean {
        const coordinates = [
            {
                lat: 44.381830,
                lng: 138.483520
            },
            {
                lat: 45.761051,
                lng: 140.289605
            },
            {
                lat: 45.965794,
                lng: 141.717673
            },
            {
                lat: 45.746398,
                lng: 142.410705
            },
            {
                lat: 45.614345,
                lng: 145.791865
            },
            {
                lat: 44.456828,
                lng: 146.757911
            },
            {
                lat: 43.367573,
                lng: 147.807961
            },
            {
                lat: 34.510586,
                lng: 145.297527
            },
            {
                lat: 28.531354,
                lng: 133.666988
            },
            {
                lat: 28.735971,
                lng: 127.994187
            },
            {
                lat: 31.579907,
                lng: 125.896027
            },
            {
                lat: 34.893905,
                lng: 129.651992
            },
            {
                lat: 40.109654,
                lng: 134.729020
            },
            {
                lat: 44.770550,
                lng: 137.267534
            }
        ];
        const boundaries = new google.maps.Polygon({
            paths: coordinates,
            geodesic: true
        });
        return google.maps.geometry.poly.containsLocation(position, boundaries);
    }

    public static getInstance(): StepsDirectionsManager {
        if (!instance) {
            instance = new StepsDirectionsManager();
        }
        return instance;
    }

    public async isTransportBetweenInvalid(a: ItineraryInput, b: ItineraryInput, force: boolean): Promise<boolean> {
        if (
            (
                a.step_type === 'START' &&
                !a.city_name &&
                (
                    a.distance_transport_km ||
                    a.r2r_json?.duration
                )
            ) ||
            (
                b.step_type === 'END' &&
                !b.city_name &&
                (
                    a.distance_transport_km ||
                    a.r2r_json?.duration
                )
            )
        ) {
            return true;
        }

        const bPosition = await this.transformStepToCoordinates(b);

        if (
            (
                a.r2r_json?.noTransport &&
                a.r2r_json?.nextStepCoordinates &&
                bPosition.equals(
                    new google.maps.LatLng({
                        lat: a.r2r_json?.nextStepCoordinates.latitude,
                        lng: a.r2r_json?.nextStepCoordinates.longitude
                    })
                )
            ) ||
            (
                a.step_type === 'START' &&
                !a.city_name &&
                !a.distance_transport_km &&
                !a.r2r_json?.duration &&
                !a.r2r_json?.noTransport
            ) ||
            (
                b.step_type === 'END' &&
                !b.city_name &&
                !a.distance_transport_km &&
                !a.r2r_json?.duration &&
                !a.r2r_json?.noTransport
            )
        ) {
            return false;
        }

        const pathStart = (a.r2r_json?.segments ?? [])[0]?.path;
        const pathStartCoordinates = pathStart ? google.maps.geometry.encoding.decodePath(pathStart) : null;
        const pathEnd = (a.r2r_json?.segments ?? [])[(a.r2r_json?.segments?.length ?? 0) - 1]?.path;
        const pathEndCoordinates = pathEnd ? google.maps.geometry.encoding.decodePath(pathEnd) : null;
        const aPosition = await this.transformStepToCoordinates(a);
        return (
            (!a.r2r_json?.selected || force) &&
            (!a.r2r_json?.isCustom || force) &&
            (
                !pathStartCoordinates ||
                !pathStartCoordinates[0] ||
                !pathEndCoordinates ||
                !pathEndCoordinates[pathEndCoordinates.length - 1] ||
                !new google.maps.LatLng({
                    lat: toFixed(pathStartCoordinates[0].lat(), 1),
                    lng: toFixed(pathStartCoordinates[0].lng(), 1)
                }).equals(
                    new google.maps.LatLng({
                        lat: toFixed(aPosition.lat(), 1),
                        lng: toFixed(aPosition.lng(), 1)
                    })
                ) ||
                !new google.maps.LatLng({
                    lat: toFixed(pathEndCoordinates[pathEndCoordinates.length - 1]!.lat(), 1),
                    lng: toFixed(pathEndCoordinates[pathEndCoordinates.length - 1]!.lng(), 1)
                }).equals(
                    new google.maps.LatLng({
                        lat: toFixed(bPosition.lat(), 1),
                        lng: toFixed(bPosition.lng(), 1)
                    })
                )
            ) &&
            (
                !a.r2r_json?.nextStepCoordinates ||
                !bPosition.equals(
                    new google.maps.LatLng({
                        lat: a.r2r_json?.nextStepCoordinates.latitude,
                        lng: a.r2r_json?.nextStepCoordinates.longitude
                    })
                )
            )
        ) ||
            (
                (!a.r2r_json?.selected || force) &&
                (!a.r2r_json?.isCustom || force) &&
                a.r2r_json?.vehicle?.kind === 'plane' &&
                !!a.r2r_json?.operatingDays &&
                !a.r2r_json?.operatingDays.includes(
                    parseInt(window.moment.utc(a.end_date).format('d'))
                )
            );
    }

    public async findRoutesBetween<S extends 'google-maps'>(
        options: {
            mode: google.maps.TravelMode,
            a: FlashDestination | string | google.maps.LatLng,
            b: FlashDestination | string | google.maps.LatLng,
            departureTime?: Date,
            avoidFerries: boolean,
            provideRouteAlternatives: boolean,
            service: S
        }
    ): Promise<
        S extends 'google-maps' ?
        google.maps.DirectionsResult | null :
        null
    > {
        if (options.service === 'google-maps') {
            return await new Promise(async (resolve) => {
                this.directionsService.route(
                    {
                        origin: this.transformToLocation(
                            !isString(options.a) && 'destination_id' in options.a ?
                                (await getFlashDestination(options.a.destination_id))! :
                                options.a
                        ),
                        destination: this.transformToLocation(
                            !isString(options.b) && 'destination_id' in options.b ?
                                (await getFlashDestination(options.b.destination_id))! :
                                options.b
                        ),
                        avoidFerries: options.avoidFerries,
                        provideRouteAlternatives: options.provideRouteAlternatives,
                        travelMode: options.mode,
                        drivingOptions: options.mode === google.maps.TravelMode.DRIVING && options.departureTime ?
                            {
                                departureTime: options.departureTime
                            } :
                            undefined
                    },
                    (result, status) => {
                        if (
                            status === google.maps.DirectionsStatus.OK &&
                            result
                        ) {
                            resolve(result as Parameters<typeof resolve>[0]);
                        } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                            setTimeout(async () => {
                                const response = await this.findRoutesBetween(options);
                                resolve(response);
                            }, this.overQueryLimitTimeout);
                            this.overQueryLimitTimeout += 1000;
                        } else {
                            resolve(null);
                        }
                    }
                );
            });
        }
        return null;
    }

    public async findAvailableTransports(
        a: ItineraryInput,
        b: ItineraryInput
    ): Promise<Transport[]> {
        if (a.destination?.destination_id === b.destination?.destination_id) {
            return [
                {
                    types: ['foot'],
                    alternatives: [
                        {
                            operatingDays: [],
                            steps: [
                                {
                                    vehicle: transportVehicles.find((item) => {
                                        return item.kind === 'foot';
                                    }) ?? {
                                        name: 'Foot',
                                        kind: 'foot'
                                    },
                                    arrivalTime: 0,
                                    departureTime: 0,
                                    arrPlace: -1,
                                    depPlace: -1,
                                    distance: 0,
                                    duration: 0,
                                    path: []
                                }
                            ],
                            disabled: false
                        }
                    ],
                    r2rPlaces: [],
                    r2rRoutes: [],
                    origin: {
                        name: a.destination?.international_name ?? '',
                        identifier: a.destination?.international_name ?? ''
                    },
                    destination: {
                        name: b.destination?.international_name ?? '',
                        identifier: b.destination?.international_name ?? ''
                    },
                    reduced: false
                }
            ];
        }
        const flights = await this.findFlights(a, b);

        const departureTime = window.moment.utc(a.end_date);

        const drivingRoutes = await this.findRoutesBetween({
            mode: google.maps.TravelMode.DRIVING,
            a: !a.destination ?
                a.places_id :
                a.destination,
            b: !b.destination ?
                b.places_id :
                b.destination,
            departureTime: !departureTime.isBefore(new Date(), 'day') ?
                departureTime.toDate() :
                undefined,
            avoidFerries: false,
            provideRouteAlternatives: true,
            service: 'google-maps'
        });

        const drivingResults = drivingRoutes?.routes.map((route): Transport => {
            const result: Transport = {
                types: [],
                origin: {
                    identifier: drivingRoutes.geocoded_waypoints ?
                        (drivingRoutes.geocoded_waypoints[0]?.place_id ?? '') :
                        a.id.toString(),
                    name: a.destination?.international_name ?? ''
                },
                destination: {
                    identifier: drivingRoutes.geocoded_waypoints ?
                        (drivingRoutes.geocoded_waypoints[1]?.place_id ?? '') :
                        b.id.toString(),
                    name: a.destination?.international_name ?? ''
                },
                r2rPlaces: [],
                r2rRoutes: [],
                alternatives: [
                    {
                        operatingDays: [],
                        steps: route.legs[0]?.steps.map((item): Transport['alternatives'][number]['steps'][number] => ({
                            path: item.path,
                            distance: item.distance?.value ?? 0,
                            duration: item.duration?.value ?? 0,
                            arrivalTime: 0,
                            departureTime: 0,
                            depPlace: -1,
                            arrPlace: -1,
                            vehicle: {
                                kind: item.maneuver === 'ferry' ? 'ferry' : 'car',
                                name: item.maneuver === 'ferry' ? 'Ferry' : 'Voiture'
                            }
                        })) ?? [],
                        disabled: false
                    }
                ],
                reduced: false
            };
            const sortedSteps = uniqBy(
                [...(result.alternatives[0]?.steps ?? [])].sort((a, b) => {
                    const aIndex = transportVehicles.findIndex((item) => {
                        return item.kind === a.vehicle.kind;
                    });
                    const bIndex = transportVehicles.findIndex((item) => {
                        return item.kind === b.vehicle.kind;
                    });
                    return aIndex < bIndex ? -1 : 1;
                }),
                (item) => item.vehicle.kind
            ).filter((item) => item.vehicle.kind !== 'unknown');
            result.types = sortedSteps.map((step) => {
                return step.vehicle.kind;
            });
            return result;
        }) ?? [];

        const transitRoutes = await this.findRoutesBetween({
            mode: google.maps.TravelMode.TRANSIT,
            a: !a.destination ?
                a.places_id :
                a.destination,
            b: !b.destination ?
                b.places_id :
                b.destination,
            avoidFerries: false,
            provideRouteAlternatives: true,
            service: 'google-maps'
        });

        let transitResults: Transport[] = [];
        if (transitRoutes) {
            transitResults = transitRoutes.routes.map((route): Transport => {
                const result: Transport = {
                    types: [],
                    origin: {
                        identifier: transitRoutes.geocoded_waypoints ?
                            (transitRoutes.geocoded_waypoints[0]?.place_id ?? '') :
                            a.id.toString(),
                        name: a.destination?.international_name ?? ''
                    },
                    destination: {
                        identifier: transitRoutes.geocoded_waypoints ?
                            (transitRoutes.geocoded_waypoints[1]?.place_id ?? '') :
                            b.id.toString(),
                        name: b.destination?.international_name ?? ''
                    },
                    r2rPlaces: [],
                    r2rRoutes: [],
                    alternatives: [
                        {
                            operatingDays: [],
                            steps: route.legs[0]?.steps.map((item): Transport['alternatives'][number]['steps'][number] => {
                                let type: VehicleKind = 'unknown';

                                if (item.travel_mode === google.maps.TravelMode.TRANSIT) {
                                    switch (item.transit?.line.vehicle.type) {
                                        case google.maps.VehicleType.INTERCITY_BUS:
                                        case google.maps.VehicleType.TROLLEYBUS:
                                        case google.maps.VehicleType.SHARE_TAXI:
                                        case google.maps.VehicleType.BUS: type = 'bus'; break;
                                        case google.maps.VehicleType.FERRY: type = 'ferry'; break;
                                        case google.maps.VehicleType.FUNICULAR:
                                        case google.maps.VehicleType.GONDOLA_LIFT:
                                        case google.maps.VehicleType.CABLE_CAR: type = 'cablecar'; break;
                                        case google.maps.VehicleType.COMMUTER_TRAIN:
                                        case google.maps.VehicleType.HEAVY_RAIL:
                                        case google.maps.VehicleType.RAIL:
                                        case google.maps.VehicleType.MONORAIL:
                                        case google.maps.VehicleType.METRO_RAIL:
                                        case google.maps.VehicleType.HIGH_SPEED_TRAIN: type = 'train'; break;
                                        case google.maps.VehicleType.SUBWAY: type = 'subway'; break;
                                        case google.maps.VehicleType.TRAM: type = 'tram'; break;
                                    }
                                }

                                return {
                                    path: item.path,
                                    departureTime: 0,
                                    arrivalTime: 0,
                                    depPlace: -1,
                                    arrPlace: -1,
                                    distance: item.distance?.value ?? 0,
                                    duration: item.duration?.value ?? 0,
                                    vehicle: transportVehicles.find((vehicle) => {
                                        return vehicle.kind === type;
                                    }) ?? { kind: 'unknown', name: 'Unknown' }
                                };
                            }) ?? [],
                            disabled: false
                        }
                    ],
                    reduced: false
                };
                const sortedSteps = uniqBy(
                    [...(result.alternatives[0]?.steps ?? [])].sort((a, b) => {
                        const aIndex = transportVehicles.findIndex((item) => {
                            return item.kind === a.vehicle.kind;
                        });
                        const bIndex = transportVehicles.findIndex((item) => {
                            return item.kind === b.vehicle.kind;
                        });
                        return aIndex < bIndex ? -1 : 1;
                    }),
                    (item) => item.vehicle.kind
                ).filter((item) => item.vehicle.kind !== 'unknown');
                result.types = sortedSteps.map((step) => {
                    return step.vehicle.kind;
                });
                return result;
            }) ?? [];
        }

        const aData = a.destination?.destination_id ?
            await getFlashDestination(a.destination?.destination_id) :
            null;
        const bData = b.destination?.destination_id ?
            await getFlashDestination(b.destination?.destination_id) :
            null;
        const aLocation = !a.destination ?
            await this.transformPlaceIdToCoordinates(a.places_id) :
            new google.maps.LatLng({
                lat: aData?.latitude ?? 0,
                lng: aData?.longitude ?? 0
            });
        const bLocation = !b.destination ?
            await this.transformPlaceIdToCoordinates(b.places_id) :
            new google.maps.LatLng({
                lat: bData?.latitude ?? 0,
                lng: bData?.longitude ?? 0
            });

        return [
            ...flights.concat(drivingResults).concat(transitResults).map((transport) => {
                return {
                    ...transport,
                    alternatives: transport.alternatives.map((alternative) => {
                        return {
                            ...alternative,
                            steps: alternative.steps.reduce((prev, current) => {
                                const lastItems = prev[prev.length - 1] ?? [];
                                const lastItem = lastItems ? lastItems[lastItems.length - 1] : null;
                                if (
                                    lastItem?.vehicle.kind !== 'plane' &&
                                    current.vehicle.kind !== 'plane' &&
                                    lastItem?.vehicle.kind === current.vehicle.kind
                                ) {
                                    return prev.slice(0, prev.length - 1).concat([lastItems.concat([current])]);
                                }
                                return prev.concat([[current]]);
                            }, [] as Transport['alternatives'][number]['steps'][]).map((items) => {
                                return items.reduce((prev, current) => {
                                    return {
                                        ...prev,
                                        path: prev.path.concat(current.path),
                                        duration: prev.duration + current.duration,
                                        distance: prev.distance + current.distance
                                    };
                                });
                            })
                        };
                    })
                };
            })
        ].sort(createSortTransports(aLocation, bLocation));
    }

    public async recomputeTransportsBetween(
        a: ItineraryInput,
        b: ItineraryInput,
        force?: boolean
    ): Promise<[ItineraryInput, ItineraryInput]> {
        if (await this.isTransportBetweenInvalid(a, b, !!force)) {
            const aPosition = await this.transformStepToCoordinates(a);
            const bPosition = await this.transformStepToCoordinates(b);
            const availableTransports = filterFlightAlternatives(a, await this.findAvailableTransports(a, b)).map((item) => {
                return {
                    ...item,
                    alternatives: item.alternatives.filter((item) => {
                        return !item.disabled;
                    })
                };
            });

            const bestTransport = takeBestTransport(a, b, availableTransports);

            if (bestTransport) {
                const result = cloneDeep(a);
                selectStepTransport({
                    step: result,
                    nextStep: b,
                    fromTimezone: tzlookup(aPosition.lat(), aPosition.lng()),
                    toTimezone: tzlookup(bPosition.lat(), bPosition.lng()),
                    nextStepCoordinates: {
                        latitude: bPosition.lat(),
                        longitude: bPosition.lng()
                    },
                    transport: bestTransport.alternative,
                    r2rPlaces: bestTransport.transport.r2rPlaces,
                    r2rRoutes: bestTransport.transport.r2rRoutes,
                    isCustom: false
                });
                if (
                    bestTransport.transport.types.includes('plane') &&
                    !bestTransport.alternative.operatingDays.includes(
                        parseInt(window.moment.utc(a.end_date).format('d'))
                    )
                ) {
                    if (!result.r2r_json) {
                        result.r2r_json = {};
                    }
                    result.r2r_json.noTransport = true;
                }
                return [result, b];
            }

            //remove current transport if there is no available transport
            const aResult = cloneDeep(a);
            const bResult = cloneDeep(b);
            aResult.r2r_json = {
                noTransport: true,
                nextStepCoordinates: {
                    latitude: bPosition.lat(),
                    longitude: bPosition.lng()
                }
            };
            aResult.distance_transport_km = 0;
            return [aResult, bResult];
        }

        return [a, b];
    }

    public async transformPlaceIdToCoordinates(
        placeId: string
    ): Promise<google.maps.LatLng> {
        const location = await new Promise<google.maps.LatLng | null>((resolve) => {
            this.geocoder.geocode(
                {
                    placeId
                },
                (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK && results) {
                        resolve(results[0]?.geometry.location ?? null);
                    } else {
                        resolve(null);
                    }
                }
            );
        });
        return location ?? new google.maps.LatLng(0, 0);
    }

    public async transformStepToCoordinates(step: ItineraryInput | Itinerary): Promise<google.maps.LatLng> {
        const isInput = (item: typeof step): item is ItineraryInput => {
            return !(item as Itinerary).destination?.data;
        };
        const destination = isInput(step) ?
            step.destination :
            itineraryToItineraryInput(1, step).destination;
        return destination ?
            this.transformToLocation(
                (await getFlashDestination(destination.destination_id))!
            ) :
            await this.transformPlaceIdToCoordinates(step.places_id);
    }

    public async areTransportsAllValid(steps: ItineraryInput[]): Promise<boolean> {
        for (let i = 0; i < steps.length - 1; i++) {
            const a = steps[i]!;
            const b = steps[i + 1]!;
            if (await this.isTransportBetweenInvalid(a, b, false)) {
                return false;
            }
        }
        return true;
    }
}

//@see https://stackoverflow.com/a/11818658
function toFixed(num: number, fixed: number): number {
    const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return parseFloat((num.toString().match(re) ?? [])[0] ?? '0');
}
