import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import BookingPackageFlightAirport from "./BookingPackageFlightAirport";
import moment from "moment";

const BookingPackageFlightInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const flight_info = useSelector(store => store.booking.flight_info)
    const language = useSelector(store => store.header.tmp_language);
    const trip_start_date = useSelector(store => store.trip.start_date);
    const trip_end_date = useSelector(store => store.trip.end_date);
    const arrival_flight_error = useSelector(store => store.booking.arrival_flight_error);
    const return_flight_error = useSelector(store => store.booking.return_flight_error);
    const timeArray: string[] = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j <= 1; j++) {
            if (i === 0 && j === 0) {
                timeArray.push(t("global.midnight"));
            } else if (i === 12 && j === 0) {
                timeArray.push(t("global.noon"));
            } else {
                timeArray.push((i < 10 ? '0' + i : i) + 'h' + (j === 0 ? "00" : "30"));
            }
        }
    }
    useEffect(() => {
        console.log('init_date');
        dispatch({type: 'BOOKING_SET_INIT_FLIGHT_DATE', payload: {start_date: trip_start_date, end_date: trip_end_date}});
    }, []);
    const changeAirport = (value: string, type: string, airport_from: boolean) => {
        dispatch({type: "BOOKING_SET_FLIGHT_AIRPORT", payload: {
            airport: value,
            type: type,
            airport_from: airport_from
        }});
    }
    const checkFlightNumber = (event: any, type:string) => {
        let pattern = /^[A-Za-z]{2}-?\d{4}$/;
        let check_value = pattern.test(event.target.value) || event.target.value === '';
        if (type === 'arrival_flights') {
            dispatch({type: "BOOKING_SET_ARRIVAL_FLIGHT_ERROR", payload: !check_value});
        } else if (type === 'return_flights') {
            dispatch({type: "BOOKING_SET_RETURN_FLIGHT_ERROR", payload: !check_value});
        }
    }
    return (
        <Grid container direction={'column'} spacing={ 2 }>
                <Grid item>
                    {t('cart-material.cart-construction-flight-arrival')}
                </Grid>
                {
                    flight_info.arrival_flights.map((arrival_flight, arrival_flight_index) => {
                        return (
                            <Grid item container direction={'column'} key={`arrival-flight-${arrival_flight_index}`} spacing={ 2 }>
                                <Grid item container spacing={ 2 }>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                label={t('accommodation.start_date_placeholder_manual_cart')}
                                                sx={{width: '100%'}}
                                                value={arrival_flight.date_from !== '' ? moment(arrival_flight.date_from) : moment(trip_start_date)}
                                                onChange={(newValue) => {
                                                    dispatch({type: "BOOKING_SET_FLIGHT_DATE", payload: {
                                                        date: newValue,
                                                        type: 'arrival_flights',
                                                        date_from: true
                                                    }});
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant={"outlined"}
                                            label={t("global.hour")}
                                            value={arrival_flight.time_from}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_TIME", payload: {
                                                time: e.target.value,
                                                type: 'arrival_flights',
                                                time_from: true
                                            }})}
                                        >
                                            {
                                                timeArray.map((time, time_index) => {
                                                    return (
                                                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <BookingPackageFlightAirport type={'arrival_flights'} airport_from={true} airport_value={arrival_flight.airport_from} label={t('manual-flight.segment-origin-airport')} changeAirport={changeAirport}/>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={ 2 }>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                label={t('accommodation.end_date_placeholder_manual_cart')}
                                                sx={{width: '100%'}}
                                                value={arrival_flight.date_to !== '' ? moment(arrival_flight.date_to) : moment(trip_start_date)}
                                                onChange={(newValue) => {
                                                    dispatch({type: "BOOKING_SET_FLIGHT_DATE", payload: {
                                                        date: newValue,
                                                        type: 'arrival_flights',
                                                        date_from: false
                                                    }});
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant={"outlined"}
                                            label={t("global.hour")}
                                            value={arrival_flight.time_to}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_TIME", payload: {
                                                time: e.target.value,
                                                type: 'arrival_flights',
                                                time_from: false
                                            }})}
                                        >
                                            {
                                                timeArray.map((time, time_index) => {
                                                    return (
                                                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <BookingPackageFlightAirport type={'arrival_flights'} airport_from={false} airport_value={arrival_flight.airport_to} label={t('manual-flight.segment-destination-airport')} changeAirport={changeAirport}/>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={9}>
                                        <TextField
                                            required
                                            fullWidth
                                            onBlur={(e) => checkFlightNumber(e, 'arrival_flights')}
                                            variant={"outlined"}
                                            label={t("manual-flight.segment-flight-number")}
                                            value={arrival_flight.number}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_NUMBER", payload: {number: e.target.value, type: 'arrival_flights'}})}
                                            error={arrival_flight_error}
                                            helperText={t('booking.flight_helper')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                <Grid item>
                    {t('cart-material.cart-construction-flight-departure')}
                </Grid>
                {
                    flight_info.return_flights.map((return_flight, return_flight_index) => {
                        return (
                            <Grid item container direction={'column'} key={`arrival-flight-${return_flight_index}`} spacing={ 2 }>
                                <Grid item container spacing={ 2 }>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                label={t('accommodation.start_date_placeholder_manual_cart')}
                                                sx={{width: '100%'}}
                                                value={return_flight.date_from !== '' ? moment(return_flight.date_from) : moment(trip_end_date)}
                                                onChange={(newValue) => {
                                                    dispatch({type: "BOOKING_SET_FLIGHT_DATE", payload: {
                                                        date: newValue,
                                                        type: 'return_flights',
                                                        date_from: true
                                                    }});
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant={"outlined"}
                                            label={t("global.hour")}
                                            value={return_flight.time_from}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_TIME", payload: {
                                                time: e.target.value,
                                                type: 'return_flights',
                                                time_from: true
                                            }})}
                                        >
                                            {
                                                timeArray.map((time, time_index) => {
                                                    return (
                                                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <BookingPackageFlightAirport type={'return_flights'} airport_from={true} airport_value={return_flight.airport_from} label={t('manual-flight.segment-origin-airport')} changeAirport={changeAirport}/>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={ 2 }>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                label={t('accommodation.end_date_placeholder_manual_cart')}
                                                sx={{width: '100%'}}
                                                value={return_flight.date_to !== '' ? moment(return_flight.date_to) : moment(trip_end_date)}
                                                onChange={(newValue) => {
                                                    dispatch({type: "BOOKING_SET_FLIGHT_DATE", payload: {
                                                        date: newValue,
                                                        type: 'return_flights',
                                                        date_from: false
                                                    }});
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant={"outlined"}
                                            label={t("global.hour")}
                                            value={return_flight.time_to}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_TIME", payload: {
                                                time: e.target.value,
                                                type: 'return_flights',
                                                time_from: false
                                            }})}
                                        >
                                            {
                                                timeArray.map((time, time_index) => {
                                                    return (
                                                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <BookingPackageFlightAirport type={'return_flights'} airport_from={false} airport_value={return_flight.airport_to} label={t('manual-flight.segment-destination-airport')} changeAirport={changeAirport}/>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={9}>
                                        <TextField
                                            required
                                            fullWidth
                                            onBlur={(e) => checkFlightNumber(e, 'return_flights')}
                                            variant={"outlined"}
                                            label={t("manual-flight.segment-flight-number")}
                                            value={return_flight.number}
                                            onChange={(e) => dispatch({type: "BOOKING_SET_FLIGHT_NUMBER", payload: {number: e.target.value, type: 'return_flights'}})}
                                            error={return_flight_error}
                                            helperText={t('booking.flight_helper')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                <Grid item></Grid>
        </Grid>
    );
};
export default React.memo(BookingPackageFlightInfo);