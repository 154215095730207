import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBoolean, isObject } from "lodash";
import { useItineraryBlockAdd } from "./itineraryBlockAdd";
import {
    markIndexAsCalculatingTransport,
    setAdalteModalAddOptions,
    setTextsCheckModalOptions,
    setTravelExchangeModalAddOptions
} from "../redux/reducer";
import { getTrip } from "../utils/getTrip";
import { AppState } from "../../../Reducers/Reducers";

type Options = Parameters<ReturnType<typeof useItineraryBlockAdd>>[0] & {
    skipTextsImport?: boolean
}

type Callback = (options: Options) => Promise<boolean>

export function useItineraryLaunchBlockAdd(): Callback {
    const dispatch = useDispatch();
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const addBlock = useItineraryBlockAdd();

    return useCallback(async (options: Options) => {
        if (options.type === 'circuit') {
            const trip = options.tripData ?? tripAllData;

            dispatch(
                markIndexAsCalculatingTransport({
                    index: options.index - 1,
                    isCalculating: true
                })
            );
            const circuitTrip = await getTrip(options.tripId);
            dispatch(
                markIndexAsCalculatingTransport({
                    index: options.index - 1,
                    isCalculating: false
                })
            );
            if (
                isBoolean(circuitTrip?.is_by_day_view) &&
                !options.skipTextsImport &&
                circuitTrip.is_by_day_view !== trip?.is_by_day_view
            ) {
                dispatch(setTextsCheckModalOptions({...options, tripByDay: !!trip?.is_by_day_view}));
                return false;
            }

            if (options.circuitSource === 'ADALTE') {
                const result = await addBlock({
                    ...options,
                    dryRun: true
                });
                if (isObject(result)) {
                    dispatch(
                        setAdalteModalAddOptions({
                            ...options,
                            startDate: result.startDate
                        })
                    );
                    return true;
                }
            } else if (options.circuitSource === 'TRAVEL_EXCHANGE') {
                const result = await addBlock({
                    ...options,
                    dryRun: true
                });
                if (isObject(result)) {
                    dispatch(
                        setTravelExchangeModalAddOptions({
                            ...options,
                            startDate: result.startDate
                        })
                    );
                    return true;
                }
            } else {
                return !!(await addBlock(options));
            }
        } else {
            return !!(await addBlock(options));
        }
        return false;
    }, [addBlock, dispatch]);
}
