import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

// import { SetCurrentGroup } from "../../../Actions/Poi";

import CheckEqualGroups from "../Functions/CheckEqualGroups";

import axios from "axios";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline-block"
    },
    groupModal: {
        width: "100%",
        padding: theme.spacing(2),
        zIndex: 1
    },
    floatRight: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    adornment: {
        position: "absolute",
        right: -45
    },
    formControl: {
        width: "calc(100% - 28px)"
    },
    alignLeft: {
        textAlign: "left"
    },
    nbRoomButton: {
        padding: 0,
        margin: 16
    },
    matchingPadding: {
        padding: 15
    }
}));

const PoiCardGroups = ({ current_travelers, setCurrentGroups, current_groups, openGroupModal, setOpenGroupModal }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const travelers = useSelector(state => state.trip.travelers);
    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    // const current_groups = useSelector(state => state.poi.current_groups);
    const end_date = useSelector((store) => store.trip.end_date);

    const [groups, setGroups] = useState(null);
    const [openChildActivity, setOpenChildActivity] = useState(false);
    useEffect(() => {
        if (current_groups.length !== 0){
            // let temp_groups = current_groups.slice();
            setGroups([...current_groups]);
            // setGroups(temp_groups);
        }
    }, [current_groups]);

    const handleChildActivity = (event, reason) => {
        if (reason !== 'backdropClick')
            setOpenChildActivity(!openChildActivity);
    }
    const saveNewGroup = () => {
        const { headers } = CheckBeforeRequest();
        let new_current_groups = [];
        groups.map((group) => {
            let already_exists = false;
            group.travelers = [];
            group.travelers_list.map((traveler) => {
                group.travelers.push(traveler.id);
            });
            traveler_groups.map((traveler_group) => {
                if (!already_exists && CheckEqualGroups(group.travelers, traveler_group.travelers)) {
                    already_exists = true;
                    new_current_groups.push(traveler_group);
                    newGroupCallback(new_current_groups, groups.length, setGroups);
                }
            });
            if (!already_exists) {
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                    data: {
                        travelers: group.travelers,
                        default: false
                    },
                }).then(function (response) {
                    new_current_groups.push(response.data);
                    newGroupCallback(new_current_groups, groups.length, setGroups);
                }).catch(function (error) {
                    //TODO: show snackbar error
                });
            }
        });
    }
    const newGroupCallback = (new_current_groups, groups_length, callback) => {
        if (new_current_groups.length === groups_length) {
            setCurrentGroups([...new_current_groups]);
            // dispatch(SetCurrentGroup([...new_current_groups]));
            setOpenChildActivity(false);
            setOpenGroupModal(false);
            callback(new_current_groups.slice());
        }
    };
    const setNewGroups = (groups, callback) => {
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].travelers.length === 0) {
                groups.splice(i, 1);
                i = 0;
            }
        }
        let same = groups.length === current_groups.length;
        let exists_group = [];
        if (same) {
            groups.map((group) => {
                let already_exists = false;
                traveler_groups.map((current_group) => {
                    if (!already_exists && CheckEqualGroups(group.travelers_list, current_group.travelers_list)) {
                        already_exists = true;
                        // exists_group.push(current_group);
                        exists_group.push(current_group)
                        newGroupCallback(exists_group, groups.length, setGroups);

                    }
                });
                if (!already_exists) {
                    same = false;
                }
            });
        }
        if (!same) {
            // let has_adult = true;
            // groups.map((group) => {
            //     let has_group_adult = false;
            //     group.travelers_list.map((traveler) => {
            //         if (moment().diff(traveler.birth_date, "y") > 18) {
            //             has_group_adult = true;
            //         }
            //     });
            //     if (!has_group_adult) {
            //         has_adult = false;
            //     }
            // });
            // if (!has_adult) {
            //     setOpenChildActivity(true);
                // enqueueSnackbar(t("poi.group_without_adult"), {variant: "warning", autoHideDuration: 4000, disableWindowBlurListener: true });
            // } else {
                saveNewGroup();
            // }
        }
        // else {
        //     console.log('exists_group:', exists_group);
        //     newGroupCallback(exists_group, groups.length, setGroups);
        // }
    };

    let travelers_without_group = [];
    if (groups !== null) {
        travelers.map((traveler) => {
            let has_group = false;
            for (let i = 0; i < groups.length; i++) {
                for (let j = 0; j < groups[i].travelers_list.length; j++) {
                    if (groups[i].travelers_list[j].id === traveler.id) {
                        has_group = true;
                        break;
                    }
                }
            }
            if (!has_group) {
                travelers_without_group.push(traveler);
            }
        });
    }

    return (
        <div style={{ position: "relative"}}>
            <Button className={ classes.matchingPadding } variant={ "outlined" } color={ "default" } fullWidth={ true } startIcon={<Person/>} onClick={ setOpenGroupModal }>{ current_groups.length } { (current_groups.length > 1 ? t("poi.groups") : t("poi.group")) }, { current_travelers } { (current_travelers > 1 ? t("accommodation.travelers") : t("accommodation.traveler")) }</Button>
            {
                openGroupModal && (
                    <Paper className={ classes.groupModal }>
                        <Typography variant={ "h6" }>{ t("accommodation.travelers") }</Typography>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                            <Grid item>
                                <Typography>{ t("poi.groups_number") }</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length <= 1 } onClick={() => {
                                    if (groups.length > 1) {
                                        let groups_cpy = [];
                                        for (let i = 0; i < groups.length - 1; i++) {
                                            groups_cpy.push({
                                                travelers: [],
                                                travelers_list: []
                                            });
                                            setGroups(groups_cpy);
                                        }
                                    }
                                }}>
                                    <RemoveCircleOutline/>
                                </IconButton>
                                <Typography className={ classes.inline }>{ groups.length }</Typography>
                                <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length >= travelers.length } onClick={() => {
                                    if (groups.length < travelers.length) {
                                        let groups_cpy = groups.slice();
                                        groups_cpy.push({
                                            travelers: [],
                                            travelers_list: []
                                        });
                                        setGroups(groups_cpy);
                                    }
                                }}>
                                    <AddCircleOutline/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <br/>
                        {
                            travelers_without_group.length > 0 && (
                                <Fragment>
                                    <Typography>{ t("global.there_are") + " " + travelers_without_group.length + " " + t("header.unassigned travelers") }</Typography>
                                    {
                                        travelers_without_group.map((traveler, index_traveler) => {
                                            let label_name = "";
                                            let default_name = false;
                                            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                                            if (traveler.first_name === t('global.first_name') && traveler.last_name === t('global.last_name')) {
                                                default_name = true;
                                            }
                                            if (default_name) {
                                                if (age >= 18) {
                                                    label_name = `${t("trip.traveler")} ${t("flight_search.adult")}`;
                                                }
                                                else {
                                                    label_name = `${t("trip.traveler")} ${t("flight_search.child")} (${age})`;
                                                }
                                            } else {
                                                label_name = `${traveler.first_name} ${traveler.last_name} ${age < 18 ? `(${age})` : ''}`
                                            }
                                            return (
                                                <Typography key={ index_traveler }>{ `${label_name}` }</Typography>
                                            )
                                        })
                                    }
                                    <br/>
                                </Fragment>
                            )
                        }
                        {
                            groups.map((group, index_group) => {
                                return (
                                    <Fragment key={ index_group }>
                                        <Typography>{ t("poi.group") } { index_group + 1 } :</Typography>
                                        {
                                            travelers.map((traveler, index_traveler) => {
                                                let label_name = "";
                                                let default_name = false;
                                                let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                                                if (traveler.first_name === t('global.first_name') && traveler.last_name === t('global.last_name')) {
                                                    default_name = true;
                                                }
                                                if (default_name) {
                                                    if (age >= 18) {
                                                        label_name = `${t("trip.traveler")} ${t("flight_search.adult")}`;
                                                    }
                                                    else {
                                                        label_name = `${t("trip.traveler")} ${t("flight_search.child")} (${age})`;
                                                    }
                                                } else {
                                                    label_name = `${traveler.first_name} ${traveler.last_name} ${age < 18 ? `(${age})` : ''}`
                                                }
                                                return (
                                                    <div key={ index_traveler } className={ classes.alignLeft }>
                                                        <FormControlLabel control={ <Checkbox checked={ group.travelers.includes(traveler.id) } onChange={(e) => {
                                                            let groups_cpy = groups.slice();
                                                            let temp_group = Object.assign({}, group)

                                                            if (e.target.checked) {
                                                                for (let i = 0; i < groups_cpy.length; i++) {
                                                                    const index = groups_cpy[i].travelers.indexOf(traveler.id);
                                                                    if (index > -1) {
                                                                        let temp_travelers = groups_cpy[i].travelers.slice();
                                                                        let temp_travelers_list = groups_cpy[i].travelers_list.slice();
                                                                        temp_travelers.splice(index, 1);
                                                                        temp_travelers_list.splice(index, 1);
                                                                        groups_cpy[i].travelers = temp_travelers;
                                                                        groups_cpy[i].travelers_list = temp_travelers_list
                                                                    }
                                                                }
                                                                temp_group.travelers = [...temp_group.travelers, traveler.id];
                                                                temp_group.travelers_list = [...temp_group.travelers_list, traveler];
                                                            } else {
                                                                const index = temp_group.travelers.indexOf(traveler.id);
                                                                if (index > -1) {
                                                                    let temp_travelers = temp_group.travelers.slice();
                                                                    let temp_travelers_list = temp_group.travelers_list.slice();
                                                                    temp_travelers.splice(index, 1);
                                                                    temp_travelers_list.splice(index, 1);
                                                                    temp_group.travelers = temp_travelers;
                                                                    temp_group.travelers_list = temp_travelers_list
                                                                }
                                                            }
                                                            groups_cpy[index_group] = temp_group;
                                                            setGroups(groups_cpy);
                                                        }}/> } label={ `${label_name}` }/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <br/>
                                    </Fragment>
                                );
                            })
                        }
                        <Button variant={ "contained" } className={ `${classes.orangeButton} ${classes.marginTop}` } fullWidth={ true } onClick={() => { setNewGroups(groups, setGroups); }}>{ t("global.finish") }</Button>
                    </Paper>
                )
            }
        <Dialog open={openChildActivity} onClose={handleChildActivity} disableEscapeKeyDown>
            <DialogContent>
                {t("poi.solo_child_warning")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleChildActivity}>{t('global.cancel')}</Button>
                <Button onClick={saveNewGroup}>{t('global.validate')}</Button>
            </DialogActions>
        </Dialog>
        </div>
    )
};

export default React.memo(PoiCardGroups);