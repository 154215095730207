import i18n from "../../../../i18n.jsx";
import moment from 'moment';
import GetProductType from "./GetProductType.js";

export default (record, t, trip_info, travelers, iti_list, user) => {
    let version_number = 0;
    let old_version_number = 0;
    const currency = trip_info.currency;
    moment.locale(i18n.language);
    // retrieve version index in data
    for(let version_index=0; version_index<trip_info.data.length ; version_index++ ){
        if(trip_info.data[version_index].id === record.trip_version){
            version_number = version_index + 1;
        }
        if(record.action === 2 && trip_info.data[version_index].id === record.target_object_id){
            old_version_number = version_index + 1;
        }

    }
    switch(record.action){
        case 0:
            return record.user.first_name + " " + t("menu.history.create_trip") + trip_info.trip_reference;
        case 1:
            let duplication_info = record.additional_info.split(",");
            if (duplication_info.length === 1){
                return record.user.first_name + " " + t("menu.history.duplicate_trip") + record.additional_info + ". " + t("menu.history.new_file") + trip_info.trip_reference + " " + t("menu.history.created");
            }else{
                return record.user.first_name + " " + t(`menu.history.${duplication_info[0]}`) + " " + duplication_info[1] + ". " + t("menu.history.new_file") + trip_info.trip_reference + " " + t("menu.history.created");
            }
        case 2:
            return record.user.first_name + " " + t("menu.history.new_version") + version_number + " " + t("menu.history.from_version") + old_version_number;
        case 3:
            return record.user.first_name + " " + t("menu.history.activate_version") + version_number + " " + t("menu.history.of_trip") + trip_info.trip_reference;
            // return record.user.first_name + " " + t("menu.history.activate_version") + record.additional_info + " " + t("menu.history.of_trip") + trip_info.trip_reference;
        case 4:
            return record.user.first_name + " " + t("menu.history.rename_trip") + trip_info.trip_reference + " " + t("global.in") + " " + record.additional_info;
        case 5:
            let additional_info = record.additional_info.split(',');
            if (additional_info.length === 1) {
                return `${record.user.first_name} ${t("menu.history.change_status")} ${version_number} ${t("global.in")} ${t(`menu.trip_list.${record.additional_info}${record.additional_info === "TOBOOK" && JSON.parse(localStorage.getItem("config")).quotation_code === "verdie" ? "_VERDIE" : ""}`)}`
            } else {
                let str = [];
                additional_info.map(item => str.push(t(`menu.trip_list.${item}`)));
                return `${record.user.first_name} ${t("menu.history.change_status")} ${version_number} ${t("global.in")} ${str.join(', ')}`
            }
        case 6:
            return record.user.first_name + " " + t("menu.history.rename_version") + version_number + " " + t("global.in") + " " + record.additional_info;
        case 7:
            return record.user.first_name + " " + t("menu.history.change_desti") + version_number + " " + t("global.in") + " " + record.additional_info;
        case 8:
            let date_info = record.additional_info.split(",");
            return record.user.first_name + " " + t(`menu.history.change_${date_info[0]}`) + version_number + " " + t("global.in") + " " + moment(date_info[1]).format("L") + (date_info[2] !== "" ? " & " + moment(date_info[2]).format("L") : "");
        case 9:
            return record.user.first_name + " " + t("menu.history.budget") + version_number + " " + t("global.in") + " " + record.additional_info + " " + trip_info.currency.symbol;
        case 10:
            return record.user.first_name + " " + t("menu.history.quotation") + version_number;
        case 11:
            return record.user.first_name + " " + t("menu.history.prices_flight") + version_number;
        case 12:
            return record.user.first_name + " " + t("menu.history.prices_terrestrial") + version_number;
        case 13:
            let old_travelers = record.additional_info.split(",");
            let new_travelers = [];
            for( travelers_index=0; travelers_index<travelers.length ; travelers_index++){
                if (!old_travelers.includes(travelers[travelers_index].id.toString())){
                    new_travelers.push(travelers[travelers_index]);
                }
            }
            let travelers_str = "";
            for ( travelers_index=0; travelers_index<new_travelers.length ; travelers_index++ ){
                travelers_str += new_travelers[travelers_index].title + " " + new_travelers[travelers_index].first_name + " " + new_travelers[travelers_index].last_name + " ";
            }
            return record.user.first_name + " " + t("menu.history.add") + " " + travelers_str + t("menu.history.of_version") + version_number;
        case 14:
            if (JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco"){
                return ""
            }else{
                return record.user.first_name + " " + t("menu.history.due_date") + version_number + " " + t("global.in") + " " + moment(record.additional_info).format("L");
            }
        case 15:
            return record.user.first_name + " " + t("menu.history.client_website") + version_number + " " + t("menu.history.email") + " " + record.additional_info;
        case 16:
            return record.user.first_name + " " + t("menu.history.mcto");
        case 17:
            return record.user.first_name + " " + t("menu.history.pgi");
        case 18:
            return record.user.first_name + " " + t("menu.history.contact_traveler") + version_number + ". " + t("menu.history.new_lead_traveler") + " " + record.additional_info;
        case 19:
            return record.user.first_name + " " + t("menu.history.add_payment") + record.additional_info;
        case 20:
            return record.user.first_name + " " + t("menu.history.del_step") + " " + record.additional_info + " " + t("menu.history.of_version_bis") + version_number;
        case 21:
            return record.user.first_name + " " + t("menu.history.add_step") + " " + record.additional_info + " " + t("menu.history.of_version") + version_number;
        case 22:
            return record.user.first_name + " " + t("menu.history.start_step") + version_number + " " + t("global.in") + " " + record.additional_info;
        case 23:
            return record.user.first_name + " " + t("menu.history.end_step") + version_number + " " + t("global.in") + " " + record.additional_info;
        case 24:
            let arrival_step_info = record.additional_info.split(",");
            return record.user.first_name + " " + t("menu.history.step_arrival") + " " + arrival_step_info[0] + " " + (t("global.at")) + " " + arrival_step_info[1] + " " + t("menu.history.of_version_bis") + version_number;
        case 25:
            let departure_step_info = record.additional_info.split(",");
            return record.user.first_name + " " + t("menu.history.step_departure") + " " + departure_step_info[0] + " " + (t("global.at")) + " " + departure_step_info[1] + " " + t("menu.history.of_version_bis") + version_number + t("menu.history.next_step");
        case 26:
            let this_step_name = "";
            let next_step_name = "";
            for(step_index=0 ; step_index<iti_list.length - 1 ; step_index++){
                if (iti_list[step_index].id === record.target_object_id){
                    this_step_name = iti_list[step_index].city_name !== null ? iti_list[step_index].city_name : iti_list[step_index].destination.data.international_name;
                    next_step_name = iti_list[step_index+1].city_name !== null ? iti_list[step_index+1].city_name : iti_list[step_index+1].destination.data.international_name;
                }
            }
            return record.user.first_name + " " + t("menu.history.transportation") + " " + this_step_name + " " + t("global.and") + " " + next_step_name + " " + t("menu.history.of_version_bis") + version_number;
        case 27:
            return record.user.first_name + " " + t("menu.history.move_step") + " " + record.additional_info + " " + t("menu.history.move_step_bis") + version_number;
        case 28:
            return record.user.first_name + " " + t("menu.history.reverse_iti") + version_number;
        case 30:
            return record.user.first_name + " " + t("menu.history.edit_traveler") + " " + record.additional_info + " " + t("menu.history.of_version_bis") + version_number;
        case 31:
            return record.user.first_name + " " + t("menu.history.change_comps") + version_number;
        case 32:
            if (record.additional_info !== null){
                let new_lead_info = record.additional_info.split(",");
                if (new_lead_info.length === 3 && user.client_full.type !== 2){
                    return record.user.first_name + " " + t("menu.history.change_lead_traveler") + " " + new_lead_info[0] + " " + t("menu.history.of_provider") + " " + new_lead_info[1] + ". " + t("menu.history.new_lead_traveler") + " " + new_lead_info[2];
                }else if(new_lead_info.length === 2 && new_lead_info[0] !== ""){
                    return record.user.first_name + " " + t("menu.history.change_lead_traveler") + " " + new_lead_info[0] + ". " + t("menu.history.new_lead_traveler") + " " + new_lead_info[1];
                }else if (user.client_full.type === 2 && new_lead_info.length === 3){
                    return record.user.first_name + " " + t("menu.history.change_lead_traveler") + " " + new_lead_info[0] + ". " + t("menu.history.new_lead_traveler") + " " + new_lead_info[2];

                }else{
                    return t("menu.history.new_lead_traveler") + " " + new_lead_info[1];
                }
            }
            return "";
        case 40:
            let add_product_info = record.additional_info.split(",");
            if (user.client_full.type !== 2){
                if (add_product_info.length === 2){
                    if(add_product_info[1].includes("is_optional")){
                        return record.user.first_name + " " + t("menu.history.add_product_option") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                    }else{
                        return record.user.first_name + " " + t("menu.history.add_product") + " " + add_product_info[0] + " " + t("menu.history.of_provider") + " " + add_product_info[1] + " " + t("menu.history.to_cart") + version_number;
                    }
                }else if(add_product_info.length === 3){
                    return record.user.first_name + " " + t("menu.history.add_product_option") + " " + add_product_info[0] + " " + t("menu.history.of_provider") + " " + add_product_info[1] + " " + t("menu.history.to_cart") + version_number;
                } else {
                    return record.user.first_name + " " + t("menu.history.add_product") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                }
            }else{
                if (add_product_info.length === 2){
                    if(add_product_info[1].includes("is_optional")){
                        return record.user.first_name + " " + t("menu.history.add_product_option") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                    }else{
                        return record.user.first_name + " " + t("menu.history.add_product") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                    }
                } else if(add_product_info.length === 3){
                    return record.user.first_name + " " + t("menu.history.add_product_option") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                } else {
                    return record.user.first_name + " " + t("menu.history.add_product") + " " + add_product_info[0] + " " + t("menu.history.to_cart") + version_number;
                }
            }
            return '';
        case 41:
            let del_product_info = record.additional_info.split(",");
            if (del_product_info.length > 1 && user.client_full.type !== 2){
                return record.user.first_name + " " + t("menu.history.del_product") + " " + del_product_info[0] + " " + t("menu.history.of_provider") + " " + del_product_info[1] + " " + t("menu.history.from_cart") + version_number;
            } else {
                return record.user.first_name + " " + t("menu.history.del_product") + " " + del_product_info[0] + " " + t("menu.history.from_cart") + version_number;
            }
        case 42:
            let product_info = record.additional_info.split(",");
            if (product_info.length > 1 && user.client_full.type !== 2){
               return record.user.first_name + " " + t("menu.history.info_product") + " " + product_info[0] + " " + t("menu.history.of_provider") + " " + product_info[1] + " " + t("menu.history.of_version_bis") + version_number;
            }else{
               return record.user.first_name + " " + t("menu.history.info_product") + " " + product_info[0] + " " + t("menu.history.of_version_bis") + version_number;
            }
        case 43:
            let margins_info = record.additional_info.split(",");
            if (margins_info.length > 3 && user.client_full.type !== 2){
                let margin_type_str = margins_info[2] === "FIX" ? trip_info.currency.symbol : "%"
                return record.user.first_name + " " + t("menu.history.margin") + " " + margins_info[0] + " " + t("menu.history.of_provider") + " " + margins_info[1] + " " + t("global.at") + " " + margins_info[3] + " " + margin_type_str;
            }else{
                let margin_type_str = margins_info[1] === "FIX" ? trip_info.currency.symbol : "%"
                return record.user.first_name + " " + t("menu.history.margin") + " " + margins_info[0] + " " + t("global.at") + " " + margins_info[2] + " " + margin_type_str;
            }
        case 44:
            let driver_info = record.additional_info.split(",");
            if (driver_info.length > 2 && user.client_full.type !== 2){
                return record.user.first_name + " " + t("menu.history.change_driver") + " " + driver_info[0] + " " + t("menu.history.of_provider") + " " + driver_info[1] + " " + t("menu.history.of_version_bis") + version_number + ". " + t("menu.history.new_driver") + " " + driver_info[2];
            }else{
                return record.user.first_name + " " + t("menu.history.change_driver") + " " + driver_info[0] + " " + t("menu.history.of_version_bis") + version_number + ". " + t("menu.history.new_driver") + " " + driver_info[1];
            }
        case 45:
            let transfer_info = record.additional_info.split(",");
            if (transfer_info.length > 1 && user.client_full.type !== 2){
                return record.user.first_name + " " + t("menu.history.transfer_transportation") + " " + transfer_info[0] + " " + t("menu.history.of_provider") + " " + transfer_info[1] + " " + t("menu.history.of_version_bis") + version_number;
            }else{
                return record.user.first_name + " " + t("menu.history.transfer_transportation") + " " + transfer_info[0] + " " + t("menu.history.of_version_bis") + version_number;
            }
        case 46:
            if (record.additional_info !== null) {
                let booking_status_info = record.additional_info.split(",");
                if (booking_status_info.length > 2 && user.client_full.type !== 2){
                    return record.user.first_name + " " + t("menu.history.booking_status") + " " + booking_status_info[0] + " " + t("menu.history.of_provider") + " " + booking_status_info[1] + " " + t("global.by") + " " + booking_status_info[2] + " " + t("menu.history.of_version_bis") + version_number;
                }else{
                    return record.user.first_name + " " + t("menu.history.booking_status") + " " + booking_status_info[0] + " " + t("global.in") + " " + t(`menu.history.${booking_status_info[1]}`) + " " + t("menu.history.of_version_bis") + version_number;
                }
            } else {
                return record.user.first_name + " " + t("menu.history.booking_status") + " " + t("menu.history.of_version_bis") + version_number;
            }
        case 47:
            if (record.additional_info !== null) {
                let booking_ref_info = record.additional_info.split(",");
                if (booking_ref_info.length > 2 && user.client_full.type !== 2){
                    return record.user.first_name + " " + t("menu.history.booking_ref") + " " + booking_ref_info[0] + " " + t("menu.history.of_provider") + " " + booking_ref_info[1] + " " + t("global.by") + " " + booking_ref_info[2];
                }else{
                    return record.user.first_name + " " + t("menu.history.booking_ref") + " " + booking_ref_info[0] + " " + t("global.by") + " " + booking_ref_info[1];
                }
            } else {
                return record.user.first_name + " " + t("menu.history.booking_ref");
            }
        case 48:
            if (record.additional_info !== null) {
                let book_info = record.additional_info.split(",");
                if (book_info.length > 1 && user.client_full.type !== 2){
                    return record.user.first_name + " " + t("menu.history.book") + " " + book_info[0] + " " + t("menu.history.of_provider") + " " + book_info[1] + " " + t("menu.history.of_version_bis") + version_number;
                }else{
                    return record.user.first_name + " " + t("menu.history.book") + " " + book_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                }
            } else {
                return record.user.first_name + " " + t("menu.history.book") + " " + t("menu.history.of_version_bis") + version_number;
            }
        case 49:
            if (record.additional_info !== null) {
                let cancel_info = record.additional_info.split(",");
                if (cancel_info.length > 1 && user.client_full.type !== 2){
                    return record.user.first_name + " " + t("menu.history.cancel") + " " + cancel_info[0] + " " + t("menu.history.of_provider") + " " + cancel_info[1] + " " + t("menu.history.of_version_bis") + version_number;
                }else{
                    return record.user.first_name + " " + t("menu.history.cancel") + " " + cancel_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                }
            } else {
                return record.user.first_name + " " + t("menu.history.cancel") + " " + t("menu.history.of_version_bis") + version_number;
            }
        case 50:
            return record.user.first_name + " " + t("menu.history.refresh_cart") + version_number;
        case 51:
            let is_optional_info = record.additional_info.split(",");
            if (is_optional_info.length === 3){
                if(user.client_full.type !== 2){
                    if(is_optional_info[2] === "True"){
                        return record.user.first_name + " " + t("menu.history.is_option") + " " + is_optional_info[0] + " " + t("menu.history.of_provider") + " " + is_optional_info[1] + " " + t("menu.history.of_version_bis") + version_number;
                    }else if(is_optional_info[2] === "False"){
                        return record.user.first_name + " " + t("menu.history.is_not_option") + " " + is_optional_info[0] + " " + t("menu.history.of_provider") + " " + is_optional_info[1] + " " + t("menu.history.of_version_bis") + version_number;
                    }
                }else{
                    if(is_optional_info[2] === "True"){
                        return record.user.first_name + " " + t("menu.history.is_option") + " " + is_optional_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                    }else if(is_optional_info[2] === "False"){
                        return record.user.first_name + " " + t("menu.history.is_not_option") + " " + is_optional_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                    }
                }
            }else if(is_optional_info.length === 2){
                if(is_optional_info[1] === "True"){
                    return record.user.first_name + " " + t("menu.history.is_option") + " " + is_optional_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                }else if(is_optional_info[1] === "False"){
                    return record.user.first_name + " " + t("menu.history.is_not_option") + " " + is_optional_info[0] + " " + t("menu.history.of_version_bis") + version_number;
                }
            }
        case 52:
            let issue_info = record.additional_info.split(",");
            return record.user.first_name + " " + t("menu.history.issue_flight") + " " + issue_info[0];
        case 53:
            return record.user.first_name + " " + t("menu.history.check_status") + " " + record.additional_info;
        case 60:
            const upload_additional_info = record.additional_info.split('\/');
            let other_objects_info = upload_additional_info[1].split(',');
            // let other_objects_info = record.additional_info.split(',');
            const upload_file_name = other_objects_info[other_objects_info.length === 2 ? 1 : 0].split('-');
            switch(upload_file_name[0]) {
                case "CARD":
                    if (other_objects_info.length > 1) {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.id_card") + " " + t("global.of") + " " + other_objects_info[1] + ".pdf";
                    } else {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.id_card") + ".pdf";
                    }
                case "PASSPORT":
                    if (other_objects_info.length > 1) {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.passport") + " " + t("global.of") + " " + other_objects_info[1] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.passport") + ".pdf";
                    }
                case "E-VISA":
                    if (other_objects_info.length > 1) {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + "e-Visa" + " " + t("global.of") + " " + other_objects_info[1] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + "e-Visa" + ".pdf";
                    }
                case "VOUCHER":
                    const file_name_voucher = other_objects_info[other_objects_info.length === 2 ? 1 : 0].split('NAME');
                    const split_name_voucher = file_name_voucher[1].split('-').filter(str => str !== "");
                    // if (other_objects_info.length > 1) {
                    //     return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("global.voucher") + " " + t("providers." + GetProductType(parseInt(other_objects_info[1]))) + " : " + other_objects_info[2] + "-" + other_objects_info[3] + ".pdf";
                    // } else  {
                    //     return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("global.voucher") + ".pdf";
                    // }
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("global.voucher").toLowerCase() + ", " + split_name_voucher[0] + " " + t("poi.from") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number;
                case "CUSTOM_VOUCHER":
                    const file_name_custom_voucher = other_objects_info[other_objects_info.length === 2 ? 1 : 0].split('NAME');
                    const split_name_custom_voucher = file_name_custom_voucher[1].split('-').filter(str => str !== "");
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("global.voucher").toLowerCase() + ", " + decodeURIComponent(split_name_custom_voucher[0]) + " " + t("poi.from") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number;
                case "VOUCHER_HOTELS":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("global.voucher").toLowerCase() + ", \"" + t('global.all-accommodations-vouchers') + "\" " + t("poi.from") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number;
                case "QUOTATION":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + ".pdf";
                case "CONDITIONS":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.conditions") + ".pdf";
                case "TRAVELBOOK":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.travelbook") + ".pdf";
                case "HOTELCARD":
                    if (other_objects_info.length > 1) {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.hotelcard") + " " + other_objects_info[2] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.hotelcard") + ".pdf";
                    }
                case "CONTRACT":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.contract") + ".pdf";
                case "INVOICE":
                    return record.user.first_name + " " + t("menu.history.upload_doc") + " " + t("menu.trip_list.invoice") + '-' + moment(record.trigger_date).format('DD-MM-YYYY') + ".pdf" +  " " + t('poi.from') + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number;
                default:
                    return `${record.user.first_name} ${t("menu.history.upload_doc")} ${t("menu.file_nb")} ${trip_info.trip_reference} ${t("menu.trip_list.nb_version")} ${version_number}.pdf`;
            }
        case 61:
            const download_additional_info = record.additional_info.split('\/');
            let other_objects_info_bis = download_additional_info[1].split(',');
            const download_file_name = download_additional_info[download_additional_info.length === 2 ? 1 : 0].split('-');
            switch(download_file_name[0]) {
                case "CARD":
                    if (other_objects_info_bis.length > 1) {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.id_card") + " " + t("global.of") + " " + other_objects_info_bis[1] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.id_card") + ".pdf";
                    }
                case "PASSPORT":
                    if (other_objects_info_bis.length > 1) {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.passport") + " " + t("global.of") + " " + other_objects_info_bis[1] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.passport") + ".pdf";
                    }
                case "E-VISA":
                    if (other_objects_info_bis.length > 1) {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + "e-Visa" + " " + t("global.of") + " " + other_objects_info_bis[1] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + "e-Visa" + ".pdf";
                    }
                case "VOUCHER":
                    if (other_objects_info_bis.length > 1) {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("global.voucher") + " " + t("providers." + GetProductType(parseInt(other_objects_info_bis[1]))) + " : " + other_objects_info_bis[2] + "-" + other_objects_info_bis[3] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("global.voucher") + ".pdf";
                    }
                case "QUOTATION":
                    return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + ".pdf";
                case "CONDITIONS":
                    return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.conditions") + ".pdf";
                case "TRAVELBOOK":
                    return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.travelbook") + ".pdf";
                case "HOTELCARD":
                    if (other_objects_info_bis.length > 1) {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.hotelcard") + " " + other_objects_info_bis[2] + ".pdf";
                    } else  {
                        return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.hotelcard") + ".pdf";
                    }
                case "CONTRACT":
                    return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number + " " + t("menu.trip_list.contract") + ".pdf" ;
                case "INVOICE":
                    return record.user.first_name + " " + t("menu.history.download_doc") + " " + t("menu.trip_list.invoice") + '-' + moment(record.trigger_date).format('DD-MM-YYYY') + ".pdf" +  " " + t('poi.from') + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + version_number;
                default:
                    return `${record.user.first_name} ${t("menu.history.download_doc")} ${t("menu.file_nb")} ${trip_info.trip_reference} ${t("menu.trip_list.nb_version")} ${version_number}.pdf`;
            }
        case 70:
            return record.user.first_name + " " + t("menu.history.delete_payment") + record.additional_info;
        case 80:
            return `${t('menu.history.price_change')} ${record.additional_info} ${t('menu.history.has_changed')}`
        case 81:
            let invoice_type = record.additional_info.split(',');
            return `${record.user.first_name} ${t("menu.history.add")} ${t("menu.history." + invoice_type[0].toLowerCase())}`
        case 82:
            let type_invoice = record.additional_info.split(',');
            return `${record.user.first_name} ${t("menu.history.updated")} ${t("menu.history." + type_invoice[0].toLowerCase())}`;
        case 83:
            return `${record.user.first_name} ${t("menu.history.reprice_flight")}`;
        case 84:
            return `${record.user.first_name} ${t("menu.history.send_custom_mail")}`;
        case 85:
            return `${record.user.first_name} ${t("menu.history.send_contract_mail")}`;
        case 86:
            return `${record.user.first_name} ${t("menu.history.add_product")} : ${record.additional_info} ${t("menu.history.to_cart")} ${version_number}`; 
        case 87:
            return `${record.user.first_name} ${t("menu.history.export_doc")} ${record.additional_info}`
        case 88:
            return `${record.user.first_name} ${t("menu.history.edit_cancel_condition_text")} ${t("menu.history.to_cart")} ${version_number}`
        case 89:
            let prices = record.additional_info.split(',');
            return `${t('menu.history.agreed_price_change')} (${prices[0]}${currency.symbol}). ${t('menu.history.agreed_margin_change')} (${prices[1]}${currency.symbol})`
        case 90:
            return record.user.first_name + " " + t("menu.history.mtrip");
    }
    return ""
};
