import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

export function makeAccountingChargesRequest(options) {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/accounting-book/?limit=50&offset=0&ordering=-created_date&action_type=charges&search=${options.id}`,
            {
                headers: headers
            }
        );
    }

    return null;
}