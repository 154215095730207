import React, { useEffect, useState, useRef, createRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PersonIcon from '@material-ui/icons/Person';

import i18n from "../../../i18n.jsx";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import GetHistoryText from "./Functions/GetHistoryText";

import TripListHistoryFilter from "./TripListHistoryFilter.js";

import axios from "axios";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize18: {
        fontSize: 18
    },
    fontSize24: {
        fontSize: 24
    },
    colorBlue: {
        color: '#4A75DA'
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    noHover: {
        '&:hover': {
            background: "none"
        }
    },
    containerBorder: {
        borderTop: "0.5px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "0.5px solid rgba(0, 0, 0, 0.1)",
        borderRight: "0.5px solid rgba(0, 0, 0, 0.1)"
    },
    actionBorder: {
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
        boxSizing: "border-box"
    },
    dateBorder: {
        border: "0.5px solid #DDDDDD",
        borderRadius: 28,
        background: "#FFFFFF"
    },
    drawer: {
        "& .mui-jss-MuiDrawer-paper": {
            zIndex: 11,
            paddingTop: 70
        }
    },
    mobileDrawer: {
        "& .mui-jss-MuiDrawer-paper": {
            zIndex: 11,
            width: "100%"
        }
    }
}));

const TripListHistory = (() => {
    const classes = useStyles();
    const { t } = useTranslation();

    const trip_info = useSelector(store => store.trip.all_data);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const data_trip = useSelector(store => store.trip.data_trip);
    const travelers = useSelector(store => store.trip.travelers);
    const iti_list = useSelector(store => store.itinerary.itinerary_list);
    const user = useSelector(store => store.user.user);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const [dates, setDates] = useState([]);
    const [history, setHistory] = useState([]);
    const [all_dates, setAllDates] = useState([]);
    const [all_history, setAllHistory] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [openExport, setOpenExport] = useState(null);
    const [openDateMenu, setDateMenu] = useState(null);
    const [datesRefs, setDatesRefs] = useState(null);
    const [usersColors, setUsersColors] = useState([]);

    const client_map = ["", "TO", t("menu.trip_list.agency"), "MASTER"];
    moment.locale(i18n.language);
    const today = moment().format("L");
    const yesterday = moment().subtract(1, "d").format("L");

    const historyEndRef = useRef(null);

    const handleExport = (event) => {
        setOpenExport(event.currentTarget);
    };

    const handleCloseExport = () => {
        setOpenExport(null);
    };

    const handleDateMenu = (event) => {
        setDateMenu(event.currentTarget);
    };

    const handleCloseDateMenu = () => {
        setDateMenu(null);
    };

    const handleDateClick = (index) => {
        setTimeout(() => {
            datesRefs[index].current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 200);
    };

    const exportHistoryPdf = () => {
        let export_history = history.slice();
        let new_history = [];
        export_history.map((action_by_day) => {
            let temp_action_by_day = [];
            action_by_day.map((action)=>{
                let temp_action = Object.assign({}, action);
                temp_action.description = GetHistoryText(action, t, trip_info, travelers, iti_list, user);
                action = temp_action;
                temp_action_by_day.push(action);
            });
            new_history.push(temp_action_by_day);
        });
        return (new_history);
    };

    const exportHistoryCsv = () => {
        let csv = t("menu.history.csv.day") + "," + t("menu.history.csv.time") + "," + t("menu.history.csv.user") + "," + t("menu.history.csv.client") + "," + t("menu.history.csv.action") + "\n";
        let csvFileData = [];
        history.map((action_by_day) =>{
            action_by_day.map((action)=>{
                let csvLine = [];
                csvLine.push(moment.utc(action.trigger_date).format("L"));
                csvLine.push(moment.utc(action.trigger_date).format("LT"));
                csvLine.push(`${action.user.first_name} ${action.user.last_name} ${action.user.trigram !== null ? "(" + action.user.trigram + ")" : ""}`);
                csvLine.push(`${action.user.client.name} ${client_map[action.user.client.type]}`);
                csvLine.push(`"${GetHistoryText(action, t, trip_info, travelers, iti_list, user)}"`);
                csvFileData.push(csvLine);
            });
        });
        csvFileData.forEach(function (row) {
            csv += row.join(',');
            csv += "\n";
        });
        return (csv);
    };

    useEffect(() => {
        if (trip_info !== null) {
            let version = to_show_version !== null ? to_show_version : trip_info.current_version;
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/history/`
                }).then(function (response) {
                    let temp_history = [];
                    let temp_dates = [];
                    for (const [date, actions] of Object.entries(response.data)) {
                        temp_history.push(actions);
                        temp_dates.push(date);
                    }
                    setHistory(temp_history);
                    setAllHistory(temp_history);
                    setDates(temp_dates);
                    setAllDates(temp_dates);
                });
            }
        }
    }, [trip_info, to_show_version, data_trip]);

    useEffect(() =>{
        if (history.length !== 0 && trip_info !== null) {
            let temp_users_colors = [];
            history.map((action_by_day) => {
                action_by_day.map((action) => {
                    if (!temp_users_colors.some(item => item.user_id === action.user.id)) {
                        let userColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
                        let temp_user_color = { user_id: action.user.id, color: userColor };
                        temp_users_colors.push(temp_user_color);
                    }
                });
            });
            setUsersColors(temp_users_colors);

            setTimeout(() => {
                historyEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            }, 200);
        }
    }, [history]);

    useEffect(() =>{
        if (dates.length !== 0) {
            let refs = dates.reduce((acc, value, index) => {
                acc[index] = createRef();
                return acc;
            }, {});
            setDatesRefs(refs);
        }
    }, [dates]);

    return (
        <Grid>
            <Grid container style={{ padding: "20px 0px" }} alignItems="center" justify={!mobile ? "space-between" : "center"}>
                {
                    !mobile && (<Grid item xs={4} />)
                }
                <Grid item xs={!mobile ? 4 : 12} className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize24 : classes.fontSize18} ${classes.letterSpacing} ${classes.upperCase}`}>
                    { t("menu.history.title") }
                </Grid>
                {
                    !mobile && (
                        <Grid item xs={4} className={classes.textRight}>
                            <IconButton
                                size="small"
                                className={`${classes.noHover} ${classes.genericText} ${classes.colorBlue} ${classes.fontWeight400} ${classes.fontSize14}`}
                                disableRipple
                                onClick={ () => {
                                    setOpenFilter(true);
                                }}>
                                <FilterListIcon />
                                { t("menu.filter.filters") }
                            </IconButton>
                            <IconButton
                                size="small"
                                className={`${classes.noHover} ${classes.genericText} ${classes.colorBlue} ${classes.fontWeight400} ${classes.fontSize14}`}
                                disableRipple
                                onClick={handleExport}>
                                <SaveAltIcon />
                                { t("menu.history.save") }
                            </IconButton>
                            <Menu id="export-menu" anchorEl={openExport} keepMounted open={Boolean(openExport)} onClose={handleCloseExport} disableScrollLock={true}>
                                <MenuItem onClick={ () => {
                                    handleCloseExport();
                                    let version = to_show_version !== null ? to_show_version : trip_info.current_version;
                                    let new_history = exportHistoryPdf();
                                    let request = {
                                        dates: dates,
                                        history: new_history
                                    };
                                    let { pass_check, headers } = CheckBeforeRequest();
                                    if (pass_check) {
                                        axios({
                                            method: "POST",
                                            headers: headers,
                                            data: JSON.stringify(request),
                                            responseType: 'arraybuffer',
                                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/history/generate_history_pdf/`
                                        }).then(function (response) {
                                            let blob = new Blob([response.data], { type: "application/pdf" });
                                            let filename = t("menu.trip_list.history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".pdf";

                                            let link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = filename;
                                            link.click();
                                        });
                                    }
                                }}>
                                    { t("menu.history.export_pdf") }
                                </MenuItem>
                                <MenuItem onClick={ () => {
                                    handleCloseExport();
                                    let version = to_show_version !== null ? to_show_version : trip_info.current_version;
                                    let csv_data = exportHistoryCsv();
                                    let link = document.createElement('a');
                                    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv_data);
                                    link.target = '_blank';
                                    link.download = t("menu.trip_list.history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".csv";
                                    link.click();
                                }}>
                                    { t("menu.history.export_csv") }
                                </MenuItem>
                            </Menu>
                        </Grid>
                    )
                }
            </Grid>
            {
                mobile && (
                    <Grid className={classes.textLeft}>
                        <Grid>
                            <IconButton
                                size="small"
                                className={`${classes.noHover} ${classes.genericText} ${classes.colorBlue} ${classes.fontWeight400} ${classes.fontSize14}`}
                                disableRipple
                                onClick={ () => {
                                    setOpenFilter(true);
                                }}>
                                <FilterListIcon />
                                <div style={{ paddingLeft: 8 }}>
                                    { t("menu.filter.filters") }
                                </div>
                            </IconButton>
                        </Grid>
                        <Grid style={{ paddingTop: 10 }}>
                            <IconButton
                                size="small"
                                className={`${classes.noHover} ${classes.genericText} ${classes.colorBlue} ${classes.fontWeight400} ${classes.fontSize14}`}
                                disableRipple
                                onClick={handleExport}>
                                <SaveAltIcon />
                                <div style={{ paddingLeft: 8 }}>
                                    { t("menu.history.save") }
                                </div>
                            </IconButton>
                            <Menu id="export-menu" anchorEl={openExport} keepMounted open={Boolean(openExport)} onClose={handleCloseExport} disableScrollLock={true}>
                                <MenuItem onClick={ () => {
                                    handleCloseExport();
                                    let version = to_show_version !== null ? to_show_version : trip_info.current_version;
                                    let new_history = exportHistoryPdf();
                                    let request = {
                                        dates: dates,
                                        history: new_history
                                    };
                                    let { pass_check, headers } = CheckBeforeRequest();
                                    if (pass_check) {
                                        axios({
                                            method: "POST",
                                            headers: headers,
                                            data: JSON.stringify(request),
                                            responseType: 'arraybuffer',
                                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/history/generate_history_pdf/`
                                        }).then(function (response) {
                                            let blob = new Blob([response.data], { type: "application/pdf" });
                                            let filename = t("menu.trip_list.history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".pdf";

                                            let link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = filename;
                                            link.click();
                                        });
                                    }
                                }}>
                                    { t("menu.history.export_pdf") }
                                </MenuItem>
                                <MenuItem
                                    onClick={ () => {
                                        handleCloseExport();
                                        let version = to_show_version !== null ? to_show_version : trip_info.current_version;
                                        let csv_data = exportHistoryCsv();
                                        let link = document.createElement('a');
                                        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv_data);
                                        link.target = '_blank';
                                        link.download = t("menu.trip_list.history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".csv";
                                        link.click();
                                    }}>
                                    { t("menu.history.export_csv") }
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                )
            }
            {
                trip_info !== null && history.length !== 0 && dates.length !== 0 && usersColors.length !== 0 && datesRefs !== null && (
                    <Grid className={!mobile ? classes.containerBorder : ""} ref={historyEndRef} id="history-container">
                        {
                            history.map((actions_by_day, day_index) =>{
                                let client = user.client;
                                return (
                                    <Grid key={day_index} ref={datesRefs[day_index]}>
                                        <Grid style={{ paddingTop: 8, textAlign: "center" }}>
                                            <IconButton
                                                className={`${classes.noHover} ${classes.dateBorder} ${classes.genericText} ${classes.fontSize14} ${classes.fontWeight700} ${classes.letterSpacing}`}
                                                style={ moment.utc(dates[day_index]).format("L") !== yesterday ? { padding: 10 } : { padding: "10px 38px" } }
                                                size="small"
                                                onClick={handleDateMenu}
                                                disableRipple>
                                                {moment.utc(dates[day_index]).format("L") === today ? t("global.today") : (moment.utc(dates[day_index]).format("L") === yesterday ? t("global.yesterday") : moment.utc(dates[day_index]).format("L"))}
                                                <KeyboardArrowDownIcon />
                                            </IconButton>
                                            <Menu id="date-menu" anchorEl={openDateMenu} keepMounted open={Boolean(openDateMenu)} onClose={handleCloseDateMenu} disableScrollLock={true}>
                                                <div className={`${classes.genericText} ${classes.fontSize14} ${classes.letterSpacing}`} style={{ paddingLeft: 18, paddingBottom: 10 }}>
                                                    {t("menu.history.date_menu_title")}
                                                </div>
                                                <MenuItem onClick={() => {
                                                    handleCloseDateMenu();
                                                    handleDateClick(0);
                                                }}>
                                                    {t("menu.history.first_date")}
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleCloseDateMenu();
                                                    handleDateClick(dates.length - 1);
                                                }}>
                                                    {t("menu.history.last_date")}
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleCloseDateMenu();
                                                    for (temp_index = 0; temp_index < dates.length; temp_index++) {
                                                        if (moment.utc(dates[temp_index]).format("L") === yesterday) {
                                                            handleDateClick(temp_index);
                                                        }
                                                    }
                                                }}>
                                                    {t("global.yesterday")}
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleCloseDateMenu();
                                                    for (temp_index = 0; temp_index < dates.length; temp_index++) {
                                                        if (moment.utc(dates[temp_index]).format("L") === today) {
                                                            handleDateClick(temp_index);
                                                        }
                                                    }
                                                }}>
                                                    {t("global.today")}
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                        <Grid key={`container-${dates[day_index]}`} className={classes.actionBorder} style={{ paddingBottom: 25 }}>
                                            {
                                                actions_by_day.map((action)=>{
                                                    let visibility = action.visibility;
                                                    let text = GetHistoryText(action, t, trip_info, travelers, iti_list, user);
                                                    let userColor = "";
                                                    for (user_index = 0; user_index < usersColors.length; user_index++) {
                                                        if (usersColors[user_index].user_id === action.user.id) {
                                                            userColor = usersColors[user_index].color;
                                                        }
                                                    }
                                                    if (text !== "" && (visibility === "PUBLIC" || (visibility === "TO" && (client === 1 || client === 3)) || (visibility === "AGENCY" && (client === 2 || client === 3)))) {
                                                        return (
                                                            <Grid container alignItems="center" justify="flex-start" key={`${action.id}`} style={{ paddingTop: 14 }}>
                                                                <Grid item xs={1} className={classes.textCenter} style={ !mobile ? { paddingLeft: 23 } : { paddingLeft: 0 } }>
                                                                    {
                                                                        action.user.avatar !== null && (
                                                                            <Avatar variant="square" src={ action.user.avatar.thumbnail_little !== null ? action.user.avatar.thumbnail_little : action.user.avatar.url } />
                                                                        )
                                                                    }
                                                                    {
                                                                        action.user.avatar === null && (
                                                                            <Avatar style={{ backgroundColor: userColor, color: "#FFFFFF" }} variant="square"> <PersonIcon style={{ fontSize: 46 }} /> </Avatar>
                                                                        )
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={11} className={classes.textLeft} style={{ paddingLeft: 7 }}>
                                                                    <span className={`${classes.genericText} ${classes.fontSize14} ${classes.fontWeight700}`} style={{ color: "#000000" }}>
                                                                        {`${action.user.first_name} ${action.user.last_name} ${action.user.trigram !== null ? "(" + action.user.trigram + ")" : ""} / ${action.user.client.name} ${client_map[action.user.client.type]} `}
                                                                    </span>
                                                                    <span className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12}`}>
                                                                        {moment(action.trigger_date).format("LT")}
                                                                    </span>
                                                                    <div className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14}`}>
                                                                        {text}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                )
            }
            <Drawer anchor={"right"} open={openFilter} onClose={ () => {
                setOpenFilter(!openFilter); 
            }} variant={ mobile ? "temporary" : "persistent" } className={mobile ? classes.mobileDrawer : classes.drawer}>
                <TripListHistoryFilter setOpenFilter={setOpenFilter} setHistory={setHistory} all_history={all_history} setDates={setDates} all_dates={all_dates} />
            </Drawer>
        </Grid>
    );
});

export default React.memo(TripListHistory);
