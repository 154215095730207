import { useCallback } from "react";
import { isString } from "lodash";
import { useGetPrice } from "./getPrice";
import { ProductPrice } from "../../Itinerary/objects/productPrice";

type Callback = (taxes: string | ProductPrice[]) => number

export function useGetTaxesPrice(): Callback {
    const getPrice = useGetPrice();
    return useCallback((taxes) => {
        return isString(taxes) ?
            parseFloat(taxes) :
            getPrice(taxes).cost;
    }, [getPrice]) as Callback;
}
