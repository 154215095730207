import { TFunction } from "i18next";
import { flattenDeep, uniq } from "lodash";
import { useGetPrice } from "./getPrice";
import { useGetTaxesPrice } from "./getTaxesPrice";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import { Flight } from "../../Itinerary/network/flight";
import { User } from "../../Menu/MaterialTripList/objects/user";

type Options = {
    language: string,
    flight: Flight,
    user: User | null,
    price: ReturnType<ReturnType<typeof useGetPrice>>,
    taxesPrice: ReturnType<ReturnType<typeof useGetTaxesPrice>>,
    tripEndDate: string | null,
    t: TFunction
}

export function getFlightDiffObject(options: Options) {
    return {
        passengers: [
            options.t(
                'roadbook.adults-count',
                {
                    count: options.flight.group_passenger?.travelers_list?.filter((traveler) => {
                        const result = window.moment.utc(options.tripEndDate).diff(
                            window.moment.utc(traveler.birth_date),
                            'years'
                        );
                        return result >= 18;
                    }).length ?? 0
                }
            ),
            options.t(
                'roadbook.children-count',
                {
                    count: options.flight.group_passenger?.travelers_list?.filter((traveler) => {
                        const result = window.moment.utc(options.tripEndDate).diff(
                            window.moment.utc(traveler.birth_date),
                            'years'
                        );
                        return result < 18;
                    }).length ?? 0
                }
            )
        ].join(', '),
        issuedTicket: options.flight.issued_ticket?.map((ticket) => {
            return options.t(
                'cart-material.cart-construction-ticket-item',
                {
                    type: ticket.ticket_type,
                    status: ticket.status,
                    ref: ticket.ticket_number
                }
            );
        }).join(', '),
        seats: uniq(
            flattenDeep(
                options.flight.outbounds.map((outbound) => {
                    return outbound.legs.map((leg) => {
                        return leg.seats?.map((seat) => {
                            return options.t(
                                'cart-material.cart-construction-flight-seat',
                                {
                                    no: seat.seat_number,
                                    price: new Intl.NumberFormat(
                                        options.language,
                                        {
                                            style: 'currency', 
                                            currency: options.price.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(parseFloat(seat.price ?? '0'))
                                }
                            );
                        }) ?? [];
                    });
                })
            )
        ).join(', '),
        lastTicketingDate: options.flight.estimated_last_ticketing_date ?
            window.moment(
                options.flight.estimated_last_ticketing_date ??
                options.flight.booking_status?.last_ticketing_date ??
                undefined
            ).format("L") :
            undefined,
        totalCost: computeTotalCost(options).map((currency_cost) => {
            return new Intl.NumberFormat(
                options.language,
                {
                    style: 'currency', 
                    currency: currency_cost.currency?.iso_code ?? 'EUR'
                }
            ).format(currency_cost.cost);
        }).join(' + '),
        outbounds: options.flight.outbounds.map((outbound, index) => {
            const lastIndex = outbound.legs.length - 1;
            let stopover_iata = [];
            for (let i = 1; i < outbound.legs.length; i++) {
                if (outbound.legs[i]?.origin) {
                    stopover_iata.push(outbound.legs[i]!.origin?.airport_code);
                } else {
                    stopover_iata.push(outbound.legs[i]!.origin_station?.station_code);
                }
            }
            const iata = outbound.legs.length > 1 ?
                ` (${stopover_iata.join(', ')})` :
                null;
            
            let temp_carry_on = 0;
            let temp_check = 0;
            let paid_carry_on = 0;
            let paid_check = 0;
            let paid_options = {};
            if (outbound.legs[0]?.baggage) {
                outbound.legs[0].baggage.map((luggage) => {
                    if (luggage.is_carry_on) {
                        temp_carry_on += luggage.quantity;
                    } else {
                        temp_check += luggage.quantity;
                    }
                });
            } else {
                temp_check = outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0;
            }
            if (outbound.legs[0]?.paid_options) {
                outbound.legs[0].paid_options.map((luggage) => {
                    if (luggage.option_type === "Baggage") {
                        if ((paid_options as any)[luggage.traveler] === undefined) {
                            (paid_options as any)[luggage.traveler] = [];
                        }
                        (paid_options as any)[luggage.traveler].push(luggage);
                    }
                });
                let paid_options_array = Object.keys(paid_options);
                if (paid_options_array.length > 0) {
                    (paid_options as any)[paid_options_array[0]!].map((luggage: any) => {
                        if (luggage.baggage_is_carry_on) {
                            paid_carry_on += luggage.quantity;
                        } else {
                            paid_check += luggage.quantity;
                        }
                    });
                }
            }
            const totalLuggages = temp_check + temp_carry_on + paid_check + paid_carry_on;

            return {
                airline: outbound.legs[0]?.marketing_airline?.commercial_name ?? 'Unknown',
                no: index + 1,
                flightNumber: `${outbound.legs[0]?.marketing_airline?.airline_code}-${outbound.legs[0]?.flight_number}`,
                departureTime: outbound.legs[0]?.departure_time,
                departurePlace: outbound.legs[0]?.origin ?
                    (outbound.legs[0].origin.iata_city.name !== undefined ? outbound.legs[0].origin.iata_city.name : outbound.legs[0].origin.iata_city.international_name) :
                    (outbound.legs[0]?.origin_station?.iata_city?.name !== undefined ? outbound.legs[0]?.origin_station?.iata_city?.name : outbound.legs[0]?.origin_station?.iata_city?.international_name),
                arrivalPlace: outbound.legs[lastIndex]?.destination ?
                    (outbound.legs[lastIndex]?.destination?.iata_city.name !== undefined ? outbound.legs[lastIndex]?.destination?.iata_city.name : outbound.legs[lastIndex]?.destination?.iata_city.international_name) :
                    (outbound.legs[lastIndex]?.destination_station?.iata_city?.name !== undefined ? outbound.legs[lastIndex]?.destination_station?.iata_city?.name : outbound.legs[lastIndex]?.destination_station?.iata_city?.international_name),
                arrivalTime: outbound.legs[lastIndex]?.arrival_time,
                stopOvers: outbound.legs.length - 1,
                stopOverIata: iata,
                luggages: totalLuggages !== 0 ?
                    totalLuggages :
                    outbound.legs[0]?.baggage?.length,
                classType: outbound.legs[0]?.class_name,
                classOfService: outbound.legs[0]?.class_of_service ?? ''
            };
        })
    };
}

function computeTotalCost(options: Options) {
    let total_cost = [];
    let matched = false;
    let option_cost = 0;
    let option_on_flight: any[] = [];
    options.flight.outbounds.map(outbound => {
        let option_on_group: any[] = [];
        outbound.legs.map(leg => {
            if (leg.paid_options && leg.paid_options.length > 0) {
                leg.paid_options.map(option => {
                    if (option.option_on_flight) {
                        if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                            option_cost += parseFloat(option.price);
                            option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                        }
                    } else if (option.option_on_group) {
                        if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                            option_cost += parseFloat(option.price);
                            option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                        }
                    } else {
                        option_cost += parseFloat(option.price);
                    }
                });
            }
        });
    });
    let master_price = options.flight.prices[GetPricesId(options.flight.prices, options.user?.client, options.user, true)];
    if (!matched) {
        total_cost.push({
            cost: options.price.cost + (options.taxesPrice * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost,
            currency: options.price.currency
        });
    }
    return total_cost;
}
