import moment from "moment";

export default (travelers) => {
    let pass_check = true;
    travelers.map((traveler) => {
        // default names in temporary travelers are represented with empty strings
        if (traveler.birth_date === "" || traveler.birth_date === null || moment(traveler.birth_date).year() < 1900 || moment(traveler.birth_date).year() > moment().year()){
            pass_check = false;
        }
    })
    return pass_check
}
