import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    status: React.ReactNode,
    buttons: React.ReactNode
}

export function CartConstructionProviderQuotationStatusLayout(props: Props): JSX.Element {
    const providerContext = useContext(ProviderContext);

    return (
        <Stack
            display="inline-flex"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={1}
            sx={{ width: '100%' }}
        >
            {props.status}
            {
                !providerContext.module &&
                props.buttons
            }
        </Stack>
    );
}