//---- Dependencies ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SetManualCart, SetCarsCart, SetFlightCart, SetHotelCart, SetIti, SetGuideList } from "../../../Actions/ItineraryType";
import { SetPoiCart } from "../../../Actions/Summary";

import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";

let allCart = 0;
let big_cart = [];
let iti = [];

function redirect(setProducts, setIsItiLoading, is_dup, quotation_code) {
    if (allCart === 7) {
        console.log('big_cart:', big_cart);
        let available_cart = [];
        for (let i = 0; i < big_cart.length; i++) {
            if (big_cart[i].is_available === undefined || big_cart[i].is_available === true || (quotation_code !== undefined && quotation_code !== null && quotation_code !== "" && quotation_code === 'verdie')) {
                if (big_cart[i].booking_status === null || (big_cart[i].booking_status !== undefined && big_cart[i].booking_status !== null && big_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    available_cart.push(big_cart[i]);
                }
                if (big_cart[i].booking_status === undefined) {
                    available_cart.push(big_cart[i]);
                }
            }
        }
        available_cart = available_cart.sort((a, b) => {
            let da = moment(a.start_date);
            let db = moment(b.start_date);
            return da - db;
        })
        // if (is_dup === true) {
        //     console.log('available_cart:', available_cart);
        //     component.getSimpleProductsBeforeDuplication(available_cart);
        // }
        // component.setState({
        //     cart: available_cart,
        //     itinerary: iti
        // })
        setProducts(available_cart);
        setIsItiLoading(false);
    }
}

export default (id, id_version, token, setProducts, setIsItiLoading, dispatch, is_dup, quotation_code) => {
    console.log('ici:');
    allCart = 0;
    big_cart = [];
    console.log('big_cart:', big_cart);
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/accommodation/?token=${token}`,
            // url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/accommodation/?token=${token}&full_content=true`,
            headers: headers,
            success: function (data) {
                let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                console.log('accommodation:', data);
                for (let i = 0; i < tmp_data.length; i++) {
                    tmp_data[i].is_manual = false;
                    tmp_data[i].is_custom = false;
                    tmp_data[i].hotel[0].dist = [];
                    tmp_data[i].hotel[0].sports = [];
                    tmp_data[i].hotel[0].meals = [];
                    tmp_data[i].hotel[0].room = [];
                    tmp_data[i].hotel[0].facility = [];
                    tmp_data[i].hotel[0].payment = [];
                    tmp_data[i].hotel[0].building = [];
                    // for (let j = 0; j < data[i].hotel[0].facts.length; j++) {
                    //     if (data[i].hotel[0].facts[j].codename.includes("hoteltype") || data[i].hotel[0].facts[j].codename === "located_on_main_road" || data[i].hotel[0].facts[j].codename === "year_construction" || data[i].hotel[0].facts[j].codename === "year_renovation" || data[i].hotel[0].facts[j].codename === "annexe_buildings" || data[i].hotel[0].facts[j].codename === "num_floors_main" || data[i].hotel[0].facts[j].codename === "num_floors_annexe" || data[i].hotel[0].facts[j].codename === "m2_garden" || data[i].hotel[0].facts[j].codename === "m2_terrace" || data[i].hotel[0].facts[j].codename === "num_rooms_total" || data[i].hotel[0].facts[j].codename === "num_rooms_single" || data[i].hotel[0].facts[j].codename === "num_rooms_double" || data[i].hotel[0].facts[j].codename === "num_suites" || data[i].hotel[0].facts[j].codename === "num_apartments" || data[i].hotel[0].facts[j].codename === "num_junior_suites" || data[i].hotel[0].facts[j].codename === "num_studios" || data[i].hotel[0].facts[j].codename === "num_bungalows" || data[i].hotel[0].facts[j].codename === "num_villas") {
                    //         data[i].hotel[0].building.push(data[i].hotel[0].facts[j]);
                    //     }
                    //     else if (data[i].hotel[0].facts[j].codename.includes("dist")) {
                    //         data[i].hotel[0].dist.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("sports") && data[i].hotel[0].facts[j].codename !== "sports_numberofpools" && data[i].hotel[0].facts[j].codename !== "sports_poolheated" && data[i].hotel[0].facts[j].codename !== "sports_poolfreshwater" && data[i].hotel[0].facts[j].codename !== "sports_poolsaltwater" && data[i].hotel[0].facts[j].codename !== "sports_poolbar") {
                    //         data[i].hotel[0].sports.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("meals") && data[i].hotel[0].facts[j].codename !== "meals_specialoffers" && data[i].hotel[0].facts[j].codename !== "meals_lunchcarte" && data[i].hotel[0].facts[j].codename !== "meals_dinnerchoice") {
                    //         data[i].hotel[0].meals.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("room") && data[i].hotel[0].facts[j].codename !== "facility_roomservice" && data[i].hotel[0].facts[j].codename !== "facility_conferenceroom") {
                    //         data[i].hotel[0].room.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("facility")) {
                    //         data[i].hotel[0].facility.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("beach")) {
                    //         data[i].hotel[0].facility.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("payment")) {
                    //         data[i].hotel[0].payment.push(data[i].hotel[0].facts[j]);
                    //     }
                    // }
                    big_cart.push(tmp_data[i]);
                }
                dispatch(SetHotelCart(tmp_data));
                allCart++;
                redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
         $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/manual-products/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = true;
                        data[i].is_custom = false;
                        big_cart.push(data[i]);
                    }
                }
                let products = [];
                data.map((product) => {
                    if (Object.keys(product).length > 0 && !product.is_hidden_for_traveler && !product.is_hidden_for_traveler_count_price) {
                        product.is_manual = true;
                        product.is_custom = false;
                        products.push(product);
                    }
                });
                dispatch(SetManualCart(products));
                allCart++;
                redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });     
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/custom-products/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = true;
                        big_cart.push(data[i]);
                    }
                }
                if (data.length > 0) {
                    let products = [];
                    data.map((product) => {
                        if (Object.keys(product).length > 0 && !product.is_hidden_for_traveler === false && !product.is_hidden_for_traveler_count_price === false) {
                            products.push(product);
                        }
                    });
                    dispatch(SetPoiCart(products));
                }
                allCart++;
                redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/transfers/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = false;
                        big_cart.push(data[i]);
                    }
                }
                allCart++;
                redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/car/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('cars:', data);
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = false;
                        big_cart.push(data[i]);
                    }
                }
                if (data.length > 0) {
                    let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                    tmp_data.map((item) => {
                        item.detail = false;
                        item.charges.sort((a, b) => {
                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                        });
                    });
                    dispatch(SetCarsCart(tmp_data));
                }
                allCart++;
                redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/flight/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('flight:', data);
                let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                for (let i = 0; i < tmp_data.length; i++) {
                    let arr = CheckResponse(tmp_data);
                    arr[i].is_manual = false;
                    arr[i].is_custom = false;
                    big_cart.push(arr[i]);
                    dispatch(SetFlightCart(arr));
                    // if (data.length > 0) {
                    // }
                }
               allCart++;
               redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });
        if (is_dup === false) {
            $.ajax({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/itinerary/?token=${token}`,
                headers: headers,
                success: function (data) {
                    console.log('data:', data);
                    let url = `${API_HREF}client/${window.id_owner}/guide/by_destinations/`;
                    let dest_id = [];
                    for (let i = 0; i < data.length; i++) {
                        data[i].description = null;
                        if (data[i].destination !== null) {
                            dest_id.push(data[i].destination.id);
                        }
                    }
                    url += "?destination_ids=" + dest_id.join(",");
                    $.ajax({
                        method: "GET",
                        url: url,
                        headers: headers,
                        success: function (data_rubric) {
                            dispatch(SetGuideList(data_rubric));
                        },
                        error: function (error) {
                            console.log(error);
                        }
                    });
                    let departure_destination = {
                        city: null,
                        country: null,
                        place_id: null,
                        lat: null,
                        lng: null,
                        start_date: null,
                        end_date: null,
                        retrieve: true
                    };
                    let return_destination = {
                        city: null,
                        country: null,
                        place_id: null,
                        lat: null,
                        lng: null,
                        start_date: null,
                        end_date: null,
                        retrieve: true
                    };
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].step_type === "START") {
                            departure_destination.place_id = data[i].places_id;
                            departure_destination.start_date = data[i].start_date;
                            departure_destination.end_date = data[i].end_date;
                        } else if (data[i].step_type === "END") {
                            return_destination.place_id = data[i].places_id;
                            return_destination.start_date = data[i].start_date;
                            return_destination.end_date = data[i].end_date;
                        }
                    }
                    dispatch(SetIti(data));
                    iti = data;
                    allCart++;
                    redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
                },
                error: function (error) {
                    console.log(error);
                    allCart++;
                    redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
                }
            });            
        } else {            
            allCart++;
            redirect(setProducts, setIsItiLoading, is_dup, quotation_code);
        }
    }
};
