import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    DirectionsBoatOutlined,
    FlightLand,
    FlightTakeoff,
    Hotel,
    LocationCity,
    TrainOutlined
} from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { debounce, isString } from "lodash";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetAccommodationSegments from "../Transfers/Material/GetAccommodationSegments";
import { FlashDestination } from "../Itinerary/objects/flashDestination";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    value: unknown,
    onChange: React.Dispatch<React.SetStateAction<unknown>>
}

export function CartConstructionReplaceTransferInputs(props: Props): JSX.Element {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const context = useContext(CartConstructionReplaceProductContext);

    return (
        <>
            <Typography sx={{ marginBottom: 1.5 }}>
                {t('cart-material.cart-construction-dates-dot')}
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={(props.value as any)?.is_return} />}
                onChange={(_, checked) => {
                    props.onChange((state: any) => ({
                        ...state,
                        is_return: checked
                    }));
                }}
                label={t("transfers.return_transfer")}
                disabled={context.mode === 'edit'}
            />
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DateTimePicker
                        label={t('cart-material.cart-construction-departure')}
                        value={window.moment.utc((props.value as any)?.start_date ?? undefined)}
                        onChange={(value) => {
                            props.onChange((state: any) => ({
                                ...state,
                                start_date: value?.toISOString() ?? null,
                                origin_hour: value?.format('HH[h]mm') ?? null
                            }));
                        }}
                        sx={{ width: '100%', maxWidth: '50%' }}
                    />
                </LocalizationProvider>
                {
                    (props.value as any)?.is_return &&
                    <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                        <DateTimePicker
                            label={t('cart-material.cart-construction-return')}
                            value={window.moment.utc((props.value as any)?.end_date ?? undefined)}
                            onChange={(value) => {
                                props.onChange((state: any) => ({
                                    ...state,
                                    end_date: value?.toISOString() ?? null,
                                    return_hour: value?.format('HH[h]mm') ?? null
                                }));
                            }}
                            sx={{ width: '100%' }}
                            disabled={context.mode === 'edit'}
                        />
                    </LocalizationProvider>
                }
            </Stack>
            <Typography sx={{ marginBottom: 1.5 }}>
                {t('cart-material.cart-construction-places-dot')}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <PlacePicker
                    type="origin"
                    label={t('cart-material.cart-construction-pickup')}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={context.mode === 'edit'}
                />
                <PlacePicker
                    type="dest"
                    label={t('cart-material.cart-construction-arrival')}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={context.mode === 'edit'}
                />
            </Stack>
        </>
    );
}


type PlacePickerProps = {
    type: 'origin' | 'dest',
    label: string,
    value: unknown,
    disabled?: boolean,
    onChange: (value: unknown) => void
}

const PlacePicker = (props: PlacePickerProps) => {
    const { i18n } = useTranslation();

    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const current_locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        });
    });
    const accommodation_cart = useSelector((state: AppState) => state.accommodation.cart);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const list_ports = useSelector((state: AppState) => state.transfers.list_ports);
    const list_stations = useSelector((state: AppState) => state.transfers.list_stations);

    const [search, setSearch] = useState('');
  	const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<unknown[]>([]);
    const cancelToken = useRef<CancelTokenSource | null>(null);

    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string, isUserTO: boolean) => {
                try {
                    setLoading(true);
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await makeDestinationsQuickSearch({
                        search,
                        isUserTO,
                        cancelToken: cancelToken.current
                    });
                    if (response.data.length > 0) {
                        const options = GetAccommodationSegments(
                            accommodation_cart,
                            providers,
                            manual_providers,
                            response.data,
                            list_ports,
                            list_stations,
                            current_locale
                        );
                        setOptions(options);
                    }
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
            500
        ),
        [
            accommodation_cart,
            providers,
            manual_providers,
            list_ports,
            list_stations,
            current_locale
        ]
    );

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search, isUserTO);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search, isUserTO]);

    return (
	    <Autocomplete
            value={
                props.type === "origin" ?
                    (props.value as any)?.origin_name ?? null :
                    (props.value as any)?.dest_name ?? null
            }
            loading={loading}
            options={options}
            filterOptions={(options) => options}
	        renderOption={(params, option) => {
               	return (
                   	<li {...params}>
                        <Box sx={{ marginRight: 1 }}>
                            {
                                option.type === "accommodation" && (
                                    <Hotel />
                                )
                            }
                            {
                                option.type === "airport" && props.type === "origin" && (
                                    <FlightLand />
                                )
                            }
                            {
                                option.type === "airport" && props.type !== "origin" && (
                                    <FlightTakeoff />
                                )
                            }
                            {
                                option.type === "city" && (
                                    <LocationCity />
                                )
                            }
                            {
                                option.type === "port" && (
                                    <DirectionsBoatOutlined />
                                )
                            }
                            {
                                option.type === "station" && (
                                    <TrainOutlined />
                                )
                            }
                        </Box>
                       	{option.origin_name}
                   	</li>
                );
           	}}
	        renderInput={(params) => (
	            <TextField
	            	{...params}
	            	label={props.label}
	            />
	        )}
	        onChange={(event, newValue) => {
	        	if (newValue !== "" && newValue) {
                    const result: any = props.value ? { ...props.value } : {};
		            if (props.type === "origin") {
			            result.origin_name = newValue.origin_name;
                        result.origin_type = newValue.type;
                        result.origin_id = newValue.start_destination ? newValue.start_destination[0] : newValue.origin_dest.id;
                        result.country_code = newValue.country_code;
                        if (newValue.location_code_pickup) {
                            result.origin_location_code = newValue.location_code_pickup;
                        }
		            } else {
		            	result.dest_name = newValue.origin_name;
                        result.dest_type = newValue.type;
                        result.dest_id = newValue.start_destination ? newValue.start_destination[0] : newValue.origin_dest.id;
                        result.dest_country_code = newValue.country_code;
                        if (newValue.location_code_pickup) {
                            result.dest_location_code = newValue.location_code_pickup;
                        }
		            }
                    props.onChange(result);
	        	}
	        }}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            disabled={props.disabled}
            fullWidth
	        freeSolo
	    />
    );
};

type DestinationsRequestOptions = {
    isUserTO: boolean,
    search: string,
    cancelToken: CancelTokenSource
}

function makeDestinationsQuickSearch(options: DestinationsRequestOptions): Promise<AxiosResponse<FlashDestination[]>> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            `${API_HREF}client/${window.id_owner}/destinations/flash_search/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    limit: 5,
                    ordering: 'current_version__type',
                    search: options.search,
                    vehicle: true,
                    transfert: true,
                    reduced: true,
                    visibility__in: options.isUserTO ?
                        'PUBLIC,PRIVATE_TO' :
                        'PUBLIC'
                }
            }
        );
    }

    throw new Error('Please login.');
}
