//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Fab, Paper, Stack, styled } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';

import RenderDestination from './RenderDestination';
import HotelCard from '../PackagesMaterial/HotelCard';
import RenderTransport from '../CircuitsMaterial/RenderTransport';
import ActivitiesCard from '../PackagesMaterial/ActivitiesCard';
import HotelCardManual from '../PackagesMaterial/HotelCardManual';
import ActivitiesCardManual from '../PackagesMaterial/ActivitiesCardManual';
import TransfersCard from '../PackagesMaterial/TransfersCard';
import TransfersCardManual from '../PackagesMaterial/TransfersCardManual';
import CarsCard from '../PackagesMaterial/CarsCard';
import CarsCardManual from '../PackagesMaterial/CarsCardManual';
import CircuitStepper from '../CircuitsMaterial/CircuitStepper';
import FlightsCardStep from '../PackagesMaterial/FlightsCardStep.tsx';

import localeText from '../../Functions/localeText.js';

import i18n from '../../../i18n.jsx';
import moment from 'moment';
import ByDayPictures from '../CircuitsMaterial/ByDayPicture';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    bold: {
        fontWeight: "bold"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
    }, 
    fabColor: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
        backgroundColor: `${main_color} !important`,
        color: 'white !important',
        "&:hover": {
            backgroundColor: `${main_color}CA !important`
        }
    },
    itinerarySticky: {
        position: 'sticky',
        top: 10,
        zIndex: 999,
        width: '100%',
        marginBottom: 10,
        marginLeft: 7,
    }
}));

type detailStepProps = {
    itiRef: React.Ref<HTMLInputElement>,
    contentRef: React.Ref<HTMLIFrameElement>,
    itinerary: any,
    hotel_cart: any,
    cars_cart: any,
    poi_cart: any,
    transfers_cart: any,
    manual_cart: any,
    guide_list: any,
    variantIsChecked: any,
    onToggleVariant: any,
    products: any,
    travelFlight: any
}

const DetailStep = (props: detailStepProps) => {
    const {itiRef, contentRef, itinerary, hotel_cart, cars_cart, poi_cart, transfers_cart, manual_cart, guide_list, variantIsChecked, onToggleVariant, products, travelFlight} = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const locales = useSelector((state:any) => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});


    const [transportArr, setTransportArr] = useState<any>([]);
    const [printIti, setPrintIti] = useState<any>([]);
    const [step, setStep] = useState<number>(0);
    const printItiRef = useRef(new Array());

    useEffect(() => {
        if (printItiRef.current.length > 0 && step !== 0) {
            printItiRef.current[step - 1].scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
        }
    }, [step]);
    useEffect(() => {
        setAllStepInfo();
    }, [itinerary]);
    const setAllStepInfo = () => {
        let tmp_transport_arr:any = [];
        let tmp_iti = [...itinerary];
        let end_day_before = 0;
        tmp_iti.map((step, index_step) => {
            let days = [];
            let dates = [];
            let tmp_obj = {
                id: step.id,
                has_more_details: false,
                more_details: null,
                type_details: null,
                segment: null,
                segment_index: 0
            };
            if (step.step_type !== "START") {
                let end = moment.utc(step.end_date, "YYYY-MM-DD");
                let start = moment.utc(step.start_date, "YYYY-MM-DD");
                let nb = Math.abs(moment.duration(start.diff(end)).asDays());
                let last_nb = end_day_before;
                
                if (nb === 0) {                    
                    days.push((last_nb + 1));
                }

                for (let i = 0; i < nb; i++) {
                    let new_day = 0;
                    if (i === 0) {
                        new_day = ((i + 1) + last_nb);
                    } else {
                         new_day = last_nb + 1;
                    }
                    days.push(new_day);
                    last_nb = new_day;
                }
                end_day_before = last_nb;
            }
            
            for (let i = 0; i < days.length; i++) {
                if (i === 0) {
                    dates.push(moment(step.start_date));
                } else {
                    let date = new Date(step.start_date);
                    // date.setDate(date.getDate() + i);
                    dates.push(moment(date).add(i, 'day'));
                }
            }
            step.days = days;
            step.dates = dates;
            if (step.r2r_json.vehicle !== undefined) {
                if (step.r2r_json.vehicle.kind === "car") {
                    cars_cart.map((car) => {
                        if ((moment.utc(car.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(car.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(car.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(car.end_date).isSame(moment.utc(step.end_date), "d"))) {
                            if (car.is_displayed === true) {
                                tmp_obj.type_details = "CAR";
                                tmp_obj.more_details = car;
                                tmp_obj.has_more_details = true;
                            }
                        } else if ((moment.utc(step.start_date).isAfter(moment.utc(car.start_date)) || moment.utc(step.start_date).isSame(moment.utc(car.start_date), "d")) && (moment.utc(step.end_date).isBefore(moment.utc(car.end_date)) || moment.utc(step.end_date).isSame(moment.utc(car.end_date), "d"))) {
                            if (car.is_displayed === true) {
                               tmp_obj.type_details = "CAR";
                               tmp_obj.more_details = car;
                               tmp_obj.has_more_details = true;
                            }
                        }
                        if (tmp_obj.has_more_details === true) {
                            tmp_transport_arr.push(tmp_obj);
                            tmp_obj = {
                                id: step.id,
                                has_more_details: false,
                                more_details: null,
                                type_details: null,
                                segment: null,
                                segment_index: 0
                            };
                        }
                    });
                } else {
                    tmp_obj.type_details = "OTHER";
                    tmp_obj.more_details = step;
                    tmp_obj.has_more_details = false;
                    tmp_transport_arr.push(tmp_obj);
                    tmp_obj = {
                        id: step.id,
                        has_more_details: false,
                        more_details: null,
                        type_details: null,
                        segment: null,
                        segment_index: 0
                    };
                }
            }
        });
        tmp_iti = tmp_iti.filter((el) => el.step_type !== "START" && el.step_type !== "END");
        console.log('tmp_iti:', tmp_iti);
        setPrintIti(tmp_iti);
        setTransportArr(tmp_transport_arr);
    }
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    if (itinerary !== undefined && itinerary !== null) {
        let no_start = itinerary.find(iti => iti.step_type === "START");
        return (
            <Grid container direction={"column"} spacing={4} style={{marginTop: 20}}>
            <Box className={classes.itinerarySticky}>
                <Paper style={{borderRadius: 20, padding: 15, marginBottom: 10}}>
                    <Stack>
                        <Grid item ref={itiRef} style={{marginBottom: 30}}>
                            <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                <RoomIcon/>
                            </Fab>
                            <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t<string>('apps_navigation.itinerary')}</span>
                        </Grid>
                        <CircuitStepper filterDay={printIti} setStep={setStep} step={step}/>
                    </Stack>
                </Paper>
            </Box>
            {
                travelFlight !== null && (
                    <Grid item style={{marginLeft: 7}} className={classes.border}>
                        <FlightsCardStep outbound={travelFlight.outbounds[0]} index_outbound={0} flight={travelFlight} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                    </Grid>
                )
            }
            {
                printIti.map((iti:any, iti_index:number) => {
                    if (iti.step_type === "STEP") {
                        let iti_name = ((iti.destination.data.name !== undefined && iti.destination.data.name !== null) ? iti.destination.data.name : iti.destination.data.international_name).split(",")
                        let pictures = null;
                        let description = [];
                        if (iti !== null && iti.destination !== null) {
                            pictures = iti.destination.data.pictures;
                            // if (iti.destination.data.cover_picture !== null) {
                            //     pictures = iti.destination.data.cover_picture.url;
                            // } else if (iti.destination.data.pictures !== null && iti.destination.data.pictures.length > 0) {
                            //     pictures = iti.destination.data.pictures;
                            // }
                        }
                        for (let j = 0; j < guide_list.length; j++) {
                            if (iti.destination !== null) {
                                if(iti.destination.id === guide_list[j].destination.id) {
                                    if (guide_list[j].rubrics !== undefined && guide_list[j].rubrics !== null) {
                                        guide_list[j].rubrics.map((rubric) => {
                                            if (rubric.on_quotation) {
                                                description.push(rubric);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                        return (
                            <div key={iti_index} style={{marginLeft: 7}}>
                                <Grid item style={{width: "100%"}}>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item style={{width: "100%"}} ref={(element) => printItiRef.current.push(element)}>
                                            <Grid container direction={'row'} alignItems={'center'}>
                                                <Grid item>                                                    
                                                    <Fab className={classes.fabColor} style={{verticalAlign: 'bottom'}} size={'small'} disableRipple={true} disableFocusRipple={true}>{iti_index + 1}</Fab>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{paddingLeft: 10, fontSize: 24}}>{ iti.days.length > 1 ? capitalize(t("global.from")) + " " + t("global.day1") + " " + iti.days[0] + " " + t("global.to") + " " + t("global.day1") + " " + iti.days[iti.days.length - 1] + " : " : capitalize(t("global.day1")) + " " + iti.days[0] + " : " }</span>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{fontSize: 24}} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, iti.destination?.data?.localization, iti.destination?.data?.international_name , 'name')} }/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <RenderDestination step={step} nb_step={iti_index} day_by_day={false} from_summary={false} step_name={step_name} guide_list={guide_list}/> */}
                                    </Grid>
                                </Grid>
                                <Grid item style={{marginBottom: 20, marginTop: 10, width: "100%"}}>
                                        <Grid container direction={'column'}>
                                            {
                                                pictures.length !== 0 && (
                                                    <Grid item style={{width: '100%'}}>
                                                        <ByDayPictures pictures={pictures} is_step={true}/>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                description.length !== 0 &&
                                                <Grid item className={classes.border} style={{ width: '100%', marginLeft: 0, borderRadius: pictures.length !== 0 ? '0px 0px 20px 20px' : '20px' }}>
                                                    <div >
                                                        {
                                                            description.map((rubric, rubric_index) => {
                                                                if (rubric.localization !== undefined && rubric.localization !== null) {
                                                                    if (localeText(current_locale.id, rubric.localization, null , 'description') !== null && localeText(current_locale.id, rubric.localization, null , 'description') !== ""  && localeText(current_locale.id, rubric.localization, null , 'description') !== "<p></p>") {
                                                                        return (
                                                                            <Fragment key={rubric_index}>
                                                                                <Typography className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, rubric.localization, null , 'description')} }/>
                                                                            </Fragment>
                                                                        );
                                                                    } else if (rubric.localization[0].description !== null && rubric.localization[0].description !== "") {
                                                                        let current_localization = rubric.localization.find(localization => localization.description !== null && localization.description !== "" && localization.description !== "<p></p>");
                                                                        return (
                                                                            <Fragment key={rubric_index}>
                                                                                <Typography className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: current_localization.description} }/>
                                                                            </Fragment>
                                                                        );
                                                                    }                                                                     
                                                                }
                                                            })
                                                        }
                                                        {/* <span className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: description} }/> */}
                                                        {/* <span className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.long_description , 'long_description')} }/> */}
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                {
                                    products.map((product:any, product_index:number) => {
                                        if ((moment(product.start_date).isAfter(moment(iti.start_date)) || moment(product.start_date).isSame(moment(iti.start_date), "d")) && (moment(product.end_date).isBefore(moment(iti.end_date)) || moment(product.end_date).isSame(moment(iti.end_date), "d"))) {
                                            if (product.product_type === 0 || product.product_type === 7) {
                                                if (product.is_manual) {
                                                    return (
                                                        <Grid item key={product_index} className={classes.border}>
                                                            <HotelCardManual only_one_hotel={ true } hotel={product} index={product_index} products={products}/>
                                                        </Grid>
                                                    )

                                                } else {
                                                    return (
                                                        <Grid item key={product_index} className={classes.border}>
                                                            <HotelCard products={products} only_one_hotel={ product.rooms.length === 1 } hotel={product} index={product_index} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                        </Grid>
                                                    )
                                                }
                                            }
                                        }
                                        if (((moment.utc(product.start_date).isSame(moment.utc(iti.start_date), "day")) || (moment.utc(product.start_date).isSame(moment.utc(iti.end_date), "day")))) {
                                            if (product.product_type === 4) {
                                                if (product.is_manual) {
                                                    return (
                                                        <Grid item key={product_index} className={classes.border}>
                                                            <TransfersCardManual transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}  products={products}/>
                                                        </Grid>
                                                    )
                                                } else {
                                                    return (
                                                        <Grid item key={product_index} className={classes.border}>
                                                            <TransfersCard transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}  products={products}/>
                                                        </Grid>
                                                    )
                                                }
                                            }
                                        }
                                        if (product.product_type === 11 || product.product_type === 12) {
                                            let findDay = iti.dates.findIndex((date) => {
                                                return moment.utc(product.start_date).isSame(moment.utc(date), "day");
                                            });
                                            if (moment.utc(product.start_date).isSame(moment.utc(iti.dates[findDay]), "day")) {
                                                if (product.is_manual) {
                                                    return (
                                                        <Grid item key={product_index} className={classes.border}>
                                                            <ActivitiesCardManual activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                        </Grid>
                                                    )
                                                }
                                                return (
                                                    <Grid item key={product_index} className={classes.border}>
                                                        <ActivitiesCard activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                    </Grid>
                                                )
                                            }
                                        }
                                    })
                                }
                                {
                                    iti_index !== 0 && iti_index < itinerary.length - 2 && (
                                        <Grid item style={{marginTop: 30, marginBottom: 30}}>
                                            <RenderTransport iti={step} next_iti={itinerary[iti_index + 1]}/>
                                        </Grid>
                                    )
                                }
                            </div>
                        )
                    }
                })
            }
            {
                travelFlight !== null && travelFlight.outbounds.length > 1 && (
                    <Grid item style={{ marginTop: 20, marginLeft: 7 }} className={classes.border}>
                        <FlightsCardStep outbound={travelFlight.outbounds[travelFlight.outbounds.length - 1]} index_outbound={travelFlight.outbounds.length - 1} flight={travelFlight} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                    </Grid>
                )
            }
            </Grid>
        )
    }
}
export default DetailStep;