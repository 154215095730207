import { GeoFlashDestination } from "../objects/geoFlashDestination";

export function getDestinationPicture(
    destination: GeoFlashDestination | null | undefined
): string {
    if (destination?.cover_pic__url) {
        return destination.cover_pic__url;
    }
    return '/Img/destination_default.jpg';
}
